import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { ReactComponent as WalletSuccess } from "../../assets/img/card_cancel_success.svg";
import { primaryIconColor } from "../../style.scss";

const useStyles = makeStyles(themeStyle);

export function SuccessCreateVirtualCard(props) {
  const history = useHistory();
  const classes = useStyles();
  const createdWalletDetailData = useLocation().state?.createdWalletDetailData;

  const backToWalletDetail = () => {
    props.appData.isRefresh = true;
    props.successCreateVirtualCardHandler({ ...props?.appData });
    history.push({
      pathname: '/pages/wallets-detail',
      state: { walletDetailData: createdWalletDetailData }
    });
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.successCreateVirtualCardHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <WalletSuccess className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                             stroke={primaryIconColor}/>
              <p className={classes.title + ' ' + classes.textCenter}><b>Your card was created successfully.</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}>Next you can top-up your card and
                start making<br/> payments using your virtual card.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                      onClick={backToWalletDetail}>View my card</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successCreateVirtualCardHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessCreateVirtualCard)