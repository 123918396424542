import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { ReactComponent as AccountReady } from "../../assets/img/jounrney_create_walet_success.svg"
import { primaryColor, primaryIconColor } from "../../style.scss";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { WalletStatus } from "eclipse-react-sdk/services/models";
import { v4 as uuidv4 } from 'uuid';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles(themeStyle);

export function AssistedSignupAccountReady(props) {
  const assistedCustomer = props?.appData?.assistedCustomer;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const isCreateDefaultDigitalWallet = props?.appData?.template?.pwaAppOptions?.defaultCreateDigitalWallet;
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);
  const [checkboxState, setCheckboxState] = useState('0');
  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.AccountReadyHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [props, history])

  const onChangeRadio = (event) => {
    setCheckboxState(event.target.value);
  };

  const handleForm = () => {
    if (checkboxState === "1") {
      history.push('/pages/assisted-signup-success');
    } else {
      history.push({
        pathname:'/pages/save-user-information',
        state:{flow:'assisted-signup'}
      });
    }
  }

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome]);

  const showAlert = (alertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const createDefaultDigitalWallet = useCallback(() => {
    const customerWalletAPI = new DCustomerManagementApi();
    const createWalletBody = {
      externalUniqueId: uuidv4(),
      name: `My ${walletTitleText}`,
      description: `Default Created ${walletTitleText} Post KYC Success`,
      status: WalletStatus?.ACTIVE,
      walletTypeId: props?.appData?.template?.publicCloseLoopWalletTypeId
    }
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsPost(props?.appData?.auth?.tenantId, props?.appData?.assistedCustomer?.customerId, createWalletBody).then(() => {
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletTitleText]);

  const getWallets = useCallback(() => {
    setLoading(true);
    const customerWalletAPI = new DCustomerManagementApi();
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsGet(props?.appData?.auth?.tenantId, props?.appData?.assistedCustomer?.customerId).then((customerWalletAPIResponse) => {
      let digitalWalletList = [];
      if (customerWalletAPIResponse.data && customerWalletAPIResponse.data.length > 1) {
        const activeWallets = customerWalletAPIResponse?.data?.filter(wallet => wallet?.status !== WalletStatus?.CANCELLED) || [];
        digitalWalletList = activeWallets?.filter(wallet => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId);
      }
      if (digitalWalletList && digitalWalletList.length < 1 && isCreateDefaultDigitalWallet) {
        createDefaultDigitalWallet();
      } else {
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, createDefaultDigitalWallet, isCreateDefaultDigitalWallet]);

  React.useEffect(() => {
    if (assistedCustomer) {
      getWallets();
    }
  }, [getWallets, assistedCustomer]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <AccountReady className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                            stroke={primaryIconColor}/>
              <h1 className='auth-title' style={{ margin: '30px 0 0 0' }}>The customer's <br/> account is ready!</h1>
              <p className={classes.regularFontSize}>Check that the customer received an SMS with a link and a password
                to log in to their account. They can change their password in the app.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize}><b>Do you want to add a physical card to your customer's
                profile?</b></p>
              <RadioGroup aria-label="transferType" name="transferType" value={checkboxState} onChange={onChangeRadio}
                          className='identity-doc-radio'>
                <FormControlLabel value='0' control={<Radio className={classes.Radio}/>}
                                  label='Yes, add a physical card'/>
                <FormControlLabel value='1' control={<Radio className={classes.Radio}/>} label='No'/>
              </RadioGroup>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                      onClick={handleForm}>Next</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  AccountReadyHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedSignupAccountReady)