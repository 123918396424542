import {
  // drawerWidth,
  transition,
  container,
  whiteColor
} from "../../eclipse.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "4.8em",
    marginBottom: "68px",
    padding: "15px 0 70px",
    minHeight: "calc(100vh - 10.8em)",
    // backgroundColor: whiteColor,
    // border: "1px solid"
  },
  fullContent: {
    marginTop: "4.8em",
    padding: "0 0 70px",
    minHeight: "calc(100vh - 6.8em)",
    // backgroundColor: whiteColor,
    // border: "1px solid"
  },
  "@media (min-width: 1201px)": {
    content: {
      marginTop: "6em",
    },
    fullContent: {
      marginTop: "6em",

    }
  },
  "@media (max-width: 1201px)": {
    content: {
      padding: "15px 0",
    },
    fullContent: {
      padding: "0",

    }
  },
  container,
  map: {
    marginTop: "70px"
  },
  pageTitle:{
    color: whiteColor,
    marginTop: '0'
  }
});

export default appStyle;
