import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { primaryColor } from "../../style.scss";
import Danger from "../../components/Typography/Danger";
import { loginToApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { DCustomerManagementApi } from "eclipse-react-sdk/services/apis/dcustomer-management-api";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { AAuthenticationApi } from "eclipse-react-sdk/services/apis/aauthentication-api";
import { v4 as uuidv4 } from 'uuid';
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { EclipseTitle, Gender, MaritalStatus } from "../../eclipse-react-sdk/services/models";
import {
  AuthQueryParamWithTemplateId,
  AuthRouteWithoutTemplateId,
  AuthRouteWithTemplateId
} from "../../default-route.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function CreateUsername(props) {
  const phoneNumberAsUsername = props?.appData?.template?.pwaAppOptions?.phoneNumberAsUsername;
  const defaultUsernameRegEx = props?.appData?.template?.defaultUsernameRegEx;
  const [isLoading, setLoading] = React.useState(false);
  const mobileNumber = useLocation().state?.mobileNumber;
  const fromScreen = useLocation().state?.fromScreen;
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = useState({ userName: phoneNumberAsUsername ? mobileNumber : '' });
  const [errors, setErrors] = useState({});
  const [alertData, setAlert] = useState(null);
  const isRestrictRegisteredMobileNumber = props?.appData?.template?.pwaAppOptions?.restrictRegisteredMobileNumber;

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen }
    });
  }, [history]);

  const redirectToLogin = useCallback(() => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(fromScreen === "CHANGE_IDENTITY" ? 'signup' : 'login')}`,
      search: AuthQueryParamWithTemplateId()
    });
  }, [history, fromScreen]);

  const redirectToError = useCallback((isUserExist) => {
    if (phoneNumberAsUsername || isRestrictRegisteredMobileNumber) {
      if (isUserExist) {
        redirectToLogin();
      } else {
        redirectToErrorScreen(`${AuthRouteWithoutTemplateId('signup')}`, `error`);
      }
    }
  }, [phoneNumberAsUsername, redirectToErrorScreen, redirectToLogin, isRestrictRegisteredMobileNumber]);

  const showAlert = useCallback((isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
      redirectToError(isRestrictRegisteredMobileNumber);
    }, 3000);
  }, [redirectToError, isRestrictRegisteredMobileNumber]);

  const goToCreatePassword = useCallback((userId, tenantId, tenantTokenData) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('create-password')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { userId, userIdentity: inputs.userName, tenantId, tenantTokenData }
    });
  }, [history, inputs]);

  const createUser = useCallback((tenantId, tenantHeader) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    const registerUserBody = {
      externalUniqueId: uuidv4(),
      gender: Gender.M,
      title: EclipseTitle.MR,
      maritalStatus: MaritalStatus.M,
      dateOfBirth: '19900101',
      phone1: mobileNumber
    }
    customerAPI.tenantsTenantIdCustomersPost(tenantId, registerUserBody).then((customerAPIResponse) => {
      goToCreatePassword(customerAPIResponse?.data?.customerId, tenantId, tenantHeader);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToError();
      }
    })
  }, [redirectToError, goToCreatePassword, mobileNumber, showAlert]);

  const loginRedirect = useCallback(() => {
    localStorage.setItem("UID", mobileNumber);
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [history, mobileNumber])

  const getCustomerIdentity = useCallback((tenantId, tenantHeader, customer, checkMobileNumberRegistered) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId, customer?.customerId).then((identities) => {
      if (checkMobileNumberRegistered || phoneNumberAsUsername) {
        if (phoneNumberAsUsername) {
          loginRedirect();
        } else {
          showAlert(true, 'ERROR', `The mobile number has already been used!`);
        }
      } else {
        setLoading(false);
        showAlert(true, 'ERROR', `The username has already been used!`);
      }
    }).catch((error) => {
      if (error?.response?.status === 404) {
        if (fromScreen === "CHANGE_IDENTITY") {
          showAlert(true, 'ERROR', `This mobile number is not exists!`);
        } else if (phoneNumberAsUsername || !checkMobileNumberRegistered) {
          goToCreatePassword(customer?.customerId, tenantId, tenantHeader);
        } else {
          setLoading(false);
        }
      } else {
        redirectToErrorScreen(`login`, `error`);
      }
    });
  }, [redirectToErrorScreen, goToCreatePassword, phoneNumberAsUsername, showAlert, loginRedirect, fromScreen]);

  const checkUserExist = useCallback((tenantId, tenantHeader, checkMobileNumberRegistered) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersGet(tenantId, undefined, undefined, undefined, 'fields=customerId', undefined, undefined, undefined, undefined, undefined, undefined, undefined, mobileNumber).then((getCustomerAPIResponse) => {
      if (getCustomerAPIResponse.data.length === 0) {
        if (fromScreen === "CHANGE_IDENTITY" && !phoneNumberAsUsername) {
          showAlert(true, 'ERROR', `This mobile number is not exists!`);
        } else if (phoneNumberAsUsername || !checkMobileNumberRegistered) {
          createUser(tenantId, tenantHeader);
        } else {
          setLoading(false);
        }
      } else {
        const [customer] = getCustomerAPIResponse.data;
        getCustomerIdentity(tenantId, tenantHeader, customer, checkMobileNumberRegistered);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToError();
      }
    });
  }, [redirectToError, phoneNumberAsUsername, mobileNumber, showAlert, createUser, getCustomerIdentity, fromScreen]);

  const getTenantToken = useCallback((checkMobileNumberRegistered) => {
    setLoading(true);
    const loginAPI = new AAuthenticationApi();
    const tenant = {}
    tenant['identity'] = props?.appData?.template?.tenantUsername;
    tenant['password'] = props?.appData?.template?.tenantPassword;

    loginAPI.authenticationLoginPost(tenant).then((authAPIResponse) => {
      const headers = { Authorization: authAPIResponse?.data?.headerValue };
      checkUserExist(authAPIResponse?.data?.tenantId, headers, checkMobileNumberRegistered);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToError();
      }
    });
  }, [redirectToError, checkUserExist, showAlert, props]);

  React.useEffect(() => {
    if (phoneNumberAsUsername || isRestrictRegisteredMobileNumber) {
      getTenantToken(isRestrictRegisteredMobileNumber);
    }
  }, [getTenantToken, phoneNumberAsUsername, isRestrictRegisteredMobileNumber]);

  const handleUserNameFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateUserNameForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      getTenantToken();
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateUserNameForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateUserNameForm(inputs);
    setErrors(validationErrors);
  }

  const validateUserNameForm = (inputs) => {
    const errors = {};
    const pattern = new RegExp(defaultUsernameRegEx);
    if (!inputs.userName) {
      errors.userName = 'Username required!'
    } else if (!pattern.test(inputs.userName)) {
      errors.userName = 'Username is invalid!'
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      {!isLoading ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form onSubmit={handleUserNameFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h1>Set up your account</h1>
                  <p className={classes.regularFontSize}><strong>Create your username.</strong></p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ValidationTextField
                    fullWidth
                    label="Create your username."
                    variant="filled"
                    id="userName"
                    type="text"
                    error={!!(errors.userName)}
                    name="userName"
                    value={inputs.userName}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp} />
                  {errors.userName && <Danger><small>{errors.userName}</small></Danger>}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} m={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                    disabled={!!(errors.userName)}>Next</Button> {/*onClick={goToCreatePassword}*/}
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
        : null}
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  createUsernameHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUsername)
