import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { grayColor } from "../../../assets/jss/eclipse.js";
import { loginToApp, logoutFromApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as DeleteIcon } from "../../../assets/img/delete_icon.svg"

import { DCustomerManagementApi, GDocumentManagementApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import { controlLabelColor, primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { DocumentType } from "eclipse-react-sdk/services/models";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HttpRequestConfiguration from "../../../http-request-configuration.js";

const useStyles = makeStyles(themeStyle);

export function UploadPhoto(props) {
  const selectedDocument = useLocation().state?.selectedDoc;
  const document = useLocation().state?.document;
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  const idDocument = useLocation().state?.idDocument;
  const idDocumentBack = useLocation().state?.idDocumentBack;
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const idDocumentBackImageData = useLocation().state?.idDocumentBackImageData;
  const [selfieDocument, setSelfieDocument] = useState(useLocation().state?.selfieDocument);
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);
  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen, isAddressAdded }
    });
  }

  const backToHome = useCallback(() => {
    history.push({
      pathname: `/pages/selfie-initial`,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData
      },
    });
  }, [history, isAddressAdded, fromScreen, selectedDocument, document, idDocument, idDocumentBack, selfieDocument, idDocumentImageData, idDocumentBackImageData, selfieDocumentImageData]);

  React.useEffect(() => {
    window.onpopstate = () => {
      backToHome();
    };
  }, [backToHome])

  const goToPersonalInformation = () => {
    history.push({
      pathname: `/pages/personal-information`,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData
      },
    });
  }
  const backToScanSelfie = () => {
    history.push({
      pathname: `/pages/scan-selfie`,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData: null
      },
    });
  }

  const updateSelfieDocument = (selfieDocument) => {
    const httpRequestConfiguration = HttpRequestConfiguration((fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const documentUpdateAPI = new GDocumentManagementApi(httpRequestConfiguration);
    documentUpdateAPI.tenantsTenantIdDocumentsDocumentIdPut(props?.appData?.auth?.tenantId, selfieDocument.documentId, selfieDocument, true).then((documentUpdateAPIResponse) => {
      const updatedDoc = {...selfieDocument,...documentUpdateAPIResponse.data}
      setSelfieDocument(documentUpdateAPIResponse.data);
      setLoading(false);
      if (documentUpdateAPIResponse.data) {
        props.appData.documents = props?.appData.documents?.map((document)=>{
          return documentUpdateAPIResponse.data.documentId === document.documentId ? updatedDoc : document
        });
        const appData = {
          ...props?.appData
        }
        props.uploadPhotoHandler(appData);
      }
      goToPersonalInformation();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        if(fromScreen === 'ASSISTED_SIGNUP'){
          redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
        } else {
          redirectToErrorScreen('/pages/identity-document', '/pages/error');
        }
      }
    });
  }

  const uploadNewSelfieDocument = (selfieDocument) => {
    const httpRequestConfiguration = HttpRequestConfiguration((fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const newDocumentAPI = new DCustomerManagementApi(httpRequestConfiguration);
    newDocumentAPI.tenantsTenantIdCustomersCustomerIdDocumentsPost(props?.appData?.auth?.tenantId, (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.customerId : props?.appData?.customer?.customerId), selfieDocument, true).then((newDocumentAPIResponse) => {
      const newDoc = {...selfieDocument,...newDocumentAPIResponse.data}
      setSelfieDocument(newDocumentAPIResponse.data);
      setLoading(false);
      if (newDocumentAPIResponse.data && props?.appData?.documents) {
        props.appData.documents.push(newDoc);
        const appData = {
          ...props?.appData
        }
        props.uploadPhotoHandler(appData);
      }
      goToPersonalInformation();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        if(fromScreen === 'ASSISTED_SIGNUP'){
          redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
        } else {
          redirectToErrorScreen('/pages/identity-document', '/pages/error');
        }
      }
    });
  }


  const goToUploadSelfieDocument = () => {
    setLoading(true);
    const dataImagePart1 = selfieDocumentImageData.split(';')[0];
    const dataImagePart2 = selfieDocumentImageData.split(';')[1];
    if (selfieDocument && selfieDocument?.documentId > 0 && fromScreen !== 'ASSISTED_SIGNUP') {
      selfieDocument.base64EncodedDocument = dataImagePart2.split(',')[1];
      selfieDocument.documentType = DocumentType.FACIALPHOTO;
      selfieDocument.mediaType = dataImagePart1.split(':')[1];
      updateSelfieDocument(selfieDocument);
    } else {
      const newSelfieDocument = {
        base64EncodedDocument: dataImagePart2.split(',')[1],
        documentType: DocumentType.FACIALPHOTO,
        mediaType: dataImagePart1.split(':')[1]
      }
      uploadNewSelfieDocument(newSelfieDocument);
    }
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p onClick={() => {
                backToHome()
              }} style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                <ArrowBackIosIcon/>
                <span>{"Back"}</span>
              </p>
              <small style={{
                color: controlLabelColor,
                marginLeft: "auto"
              }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 4 : 3} of 5</small>
            </div>
            <strong><small style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '80%' } : { width: '60%' }}>&nbsp;</small></strong>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' m-b-0'}>
                    <strong>{fromScreen === 'ASSISTED_SIGNUP' ? 'Upload their photo.' : 'Upload your selfie.'}</strong>
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ maxWidth: "163px", maxHeight: "253px", margin: "auto" }}>
                    <img src={selfieDocumentImageData} alt="" style={{
                      padding: '0px',
                      border: '1px solid',
                      borderColor: grayColor[12],
                      boxSizing: 'border-box',
                      float: 'left',
                      width: '100%',
                      borderRadius: '5px'
                    }}/>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="external-links"
                       style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span onClick={backToScanSelfie} style={{ display: 'flex', margin: '10px 0' }}
                          className={classes.linkColor + ' external-svglink ' + classes.regularFontSize}><DeleteIcon/>&nbsp;
                      <b>Delete and upload another image</b></span>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToUploadSelfieDocument}>{fromScreen === 'ASSISTED_SIGNUP' ? 'Upload their photo' : 'Upload my selfie'}</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  uploadPhotoHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto)
