import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { AuthRouteWithTemplateId } from "../../default-route";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions.js";
import contactSupportIcon from '../../assets/img/contactSupport.gif'

const useStyles = makeStyles(themeStyle);

export function ContactSupport(props) {
  const history = useHistory();
  const classes = useStyles();
  const fromScreen = useLocation().state?.fromScreen;
  const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
  const ratifySupportEmail = props?.appData?.template?.ratifySupportEmail;

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.contactSupportHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [props, history]);

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        (fromScreen !== 'ASSISTED_SIGNUP') ? logout() : backToHome();
      }
    };
  }, [history,backToHome, logout, fromScreen])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span style={{ float: 'left', width: '100%', textAlign: 'center', margin: '-40px 0 0 0' }}><img src={contactSupportIcon} width="200" alt="" /></span>
              <p className={classes.title + ' ' + classes.textCenter + ' m-b-0'}><b>We need to chat!</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>We've had a problem verifying your identity and would like to do it through our support agents.</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Call us on : {ratifySupportContact}</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Email us on : {ratifySupportEmail}</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={(fromScreen !== 'ASSISTED_SIGNUP') ? logout : backToHome}>{fromScreen !== 'ASSISTED_SIGNUP' ? 'Logout' : 'Back to dashboard'}</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  contactSupportHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactSupport)