/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Possible types of QRCode that can be created. ZA_MASTERPASS:for South Africa Masterpass QR
 * @export
 * @enum {string}
 */
export enum QRCodeType {
    GLOBALEMVCO = 'GLOBAL_EMVCO',
    ZAMASTERPASS = 'ZA_MASTERPASS'
}

