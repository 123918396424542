import React from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { ReactComponent as SuccessTransactionIcon } from '../../../assets/img/success_transfer_wallet.svg'
import { loginToApp } from "../../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function WithdrawalATMToken(props) {
  //console.log("WithdrawalATMToken Props :: ", props?.appData);
  const walletDetailData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const withdrawalATMData = useLocation().state?.withdrawalATMData;
  const withdrawalATMTokenData = useLocation().state?.withdrawalATMTokenData;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const backToWalletWithdrawal = () => {
    props.appData.isRefresh = true;
    props.withdrawalATMTokenHandler({ ...props?.appData });
    history.push({
      pathname: `/pages/withdrawal`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }

  const formattedDate = (expDate)=> {
    const date = new Date(expDate);
    const month = months[date.getUTCMonth()];
    return `${date.getUTCDate()} ${month} ${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}` ;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletWithdrawal}/>
              <span className={classes.title}>Withdrawal at a ATM</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
              <SuccessTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5 className={classes.textCenter + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}><b>Your token to the value of {getSymbolFromCurrency(walletDetailData?.currency)}{(+withdrawalATMData?.amount).toFixed(2)} <b style={{ color: primaryColor }} >{withdrawalATMTokenData?.token}</b>.</b></h5>
              <p className={classes.regularFontSize} style={{ textAlign: 'left' }}>
                <b>Remember to take your valid ID or Passport to confirm your identity.</b>
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                <i>The token expires on <b>{formattedDate(withdrawalATMTokenData?.expires)}</b>. If not claimed the amount will return to your {walletTitleText?.toLowerCase()}.</i>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5 className={classes.textCenter + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}><b>Follow these steps to withdraw money at a Capitec ATM</b></h5>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                1. From the menu select 'Cardless cash withdrawal'
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                2. Select {getSymbolFromCurrency(walletDetailData?.currency)}{(+withdrawalATMData?.amount).toFixed(2)}
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                3. Enter the token number <b style={{ color: primaryColor }} >{withdrawalATMTokenData?.token}</b> and your mobile number <b style={{ color: primaryColor }} >{props?.appData?.customer?.phone1}</b>.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToWalletWithdrawal}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  withdrawalATMTokenHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalATMToken)