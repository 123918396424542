/*!

=========================================================
* Eclipse PWA - v1.9.0
=========================================================

* Product Page: https://www.ukheshe.com/product/eclipse
* Copyright 2020 Ukheshe Technology (https://www.ukheshe.com)
* Licensed under MIT (https://ukheshe.comtimofficial/eclipse/blob/master/LICENSE.md)

* Coded by Ukheshe Technology

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Pages from "./layouts/Pages.js";
import Auth from "./layouts/Auth.js";

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from './redux-services/reducers/index';
import ServiceWorker from './service-worker'
import HttpInterceptor from "./http-interceptor.js";
import { DefaultAuthRoute } from "./default-route.js";

const hist = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
const store = createStore(rootReducer);

HttpInterceptor(store);

const defaultRoute = DefaultAuthRoute();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/pages" component={Pages} />
        <Route path="/auth" component={Auth} />
        <Redirect from="/" to={defaultRoute} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

//Call Service Worker to cached the data.
ServiceWorker();