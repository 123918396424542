import React, { useCallback } from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { ReactComponent as NoCardIcon } from "../../../assets/img/icon_add_a_card.svg"
import { SpinnerComponent } from "react-element-spinner";
import { KWalletTopupsApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { WalletTopupType } from "eclipse-react-sdk/services/models";
import { v4 as uuidv4 } from 'uuid';
import { AuthQueryParamWithTemplateId } from "../../../default-route.js";

const useStyles = makeStyles(themeStyle);

export function ConfirmViaCard(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const walletDetailData = useLocation().state?.walletDetailData;
  const confirmTopupData = useLocation().state.topupData;
  const fromScreen = useLocation().state.fromScreen;
  const selectedBankCard = useLocation().state.selectedBankCard;
  const linkedBankCards = useLocation().state.linkedBankCards;

  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = React.useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const backToTopup = () => {
    history.push({
      pathname: '/pages/via-card',
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen, linkedBankCards: linkedBankCards }
    });
  };

  const doCardsPayment = useCallback((cardAlias) => {
    setLoading(true);
    const walletTopupsAPI = new KWalletTopupsApi();
    const cardRequestBody = {
      cardOnFileId: confirmTopupData?.bankCard,
      externalUniqueId: uuidv4(),
      description: confirmTopupData?.reference,
      amount: confirmTopupData?.amount,
      type: WalletTopupType.ZAMASTERPASSCARD,
      landingUrl: `${window.location.origin}${process.env.PUBLIC_URL}/pages/success-via-card${AuthQueryParamWithTemplateId() ? AuthQueryParamWithTemplateId() : ''}`
    }
    walletTopupsAPI.tenantsTenantIdWalletsWalletIdTopupsPost(props?.appData?.auth?.tenantId, confirmTopupData?.toWallet, cardRequestBody).then((walletTopupsAPIResponse) => {
      setLoading(false);
      if (walletTopupsAPIResponse.data && walletTopupsAPIResponse.data?.topupId && walletTopupsAPIResponse.data?.completionUrl) {
        const topupData = {
          ...confirmTopupData,
          topupId: walletTopupsAPIResponse.data?.topupId,
          status: walletTopupsAPIResponse.data?.status
        }
        window.localStorage.setItem('appstate', btoa(JSON.stringify({ ...props?.appData, walletDetailData: walletDetailData, fromScreen: fromScreen, topupData: topupData })));
        window.location.replace(walletTopupsAPIResponse.data?.completionUrl);
      } else {
        showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletDetailData, fromScreen, confirmTopupData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    doCardsPayment();
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToTopup}></ArrowBackIosIcon>
              <span className={classes.title}>Top-up with a linked bank card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <NoCardIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Your about to top-up your {walletTitleText?.toLowerCase()} with</b></p>
              <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>R{confirmTopupData.amount}</b></h2>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>using your ({selectedBankCard?.alias + ' - **' + selectedBankCard?.last4Digits}) card.</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>You will be redirected to confirm this <br />transaction with 3D secure.</b></p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={backToTopup} >Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ConfirmViaCard)