const CreateVASInputControls = (inputControls, existedInputControls) => {
    const mapData = new Map();
    const ignoredControls = (existedInputControls && existedInputControls.length > 0) ? existedInputControls : [];
    inputControls.forEach(control => {
        if (ignoredControls.indexOf(control?.id) < 0) {
            mapData.set(`${control?.id}`, control?.defaultValue ? control?.defaultValue : '');
        }
    });
    return Object.fromEntries(mapData);
};

const ReturnVASAirtimeDataInputControls = (existedInputControls) => {
    var result = [];
    existedInputControls.forEach(control => {
        result.push(control?.id);
    });
    const amountIndex = result.indexOf('amount');
    if (amountIndex < 0) {
        result.push('amount');
    }
    return result;
};

const ValidateVASInputControls = (inputs, inputControls, existedInputControls) => {
    const errors = {};
    const ignoredControls = (existedInputControls && existedInputControls.length > 0) ? existedInputControls : [];
    inputControls.forEach((control) => {
        if (ignoredControls.indexOf(control?.id) < 0) {
            const pattern = new RegExp(control?.regex);
            if (!pattern.test(inputs[control?.id])) {
                errors[control?.id] = `${control?.regexErrorMessage ? control?.regexErrorMessage : `Invalid ${control?.title}!`}!`
                return errors;
            }
        }
    });
    return errors;
};

const ReturnPartialPaymentAmountAllowed = (isPaymentAmountAllowed) => {
    if (isPaymentAmountAllowed) {
        return isPaymentAmountAllowed;
    }
    return null;
};

export {
    CreateVASInputControls,
    ValidateVASInputControls,
    ReturnVASAirtimeDataInputControls,
    ReturnPartialPaymentAmountAllowed
};