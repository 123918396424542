import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as ResetPasswordIcon } from '../../assets/img/icon_reset_passcode.svg'
import { primaryIconColor } from "../../style.scss";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(themeStyle);

export default function SessionTimeOutDialog(props) {
  const classes = useStyles();
  const handleLogoutClick = props?.handleLogoutClick;
  return (
    <>
      <Dialog open={props?.openTimeoutDialog}>
        <DialogContent>
          <p className={classes.regularFontSize + ' ' + classes.textCenter}>
            <ResetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textCenter}>
            <b>You have been Idle!</b>
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textCenter}>
            You have been logged out!<br />Do log-in again.
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textRight}>
            <strong className={classes.linkColor} onClick={() => { handleLogoutClick() }}>Go to login</strong>
          </p>
        </DialogContent>
      </Dialog >
    </>
  )
}