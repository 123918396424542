import React, { useCallback, useEffect } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CardContent from '@material-ui/core/CardContent';
import Card from "../../components/Card/Card.js";
import 'react-multi-carousel/lib/styles.css';
import { Link, useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import { primaryColor } from "../../style.scss";
import VirtualCard from '../VirtualCard/VirtualCard.js';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Switch from '@material-ui/core/Switch';
import WalletTypeMode from "../../wallet-type-mode.js";
import {
  DCustomerManagementApi,
  HWalletsApi,
  ICardManagementApi,
  ZGlobalFunctionalityApi
} from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import { loginToApp } from "../../redux-services/actions/actions.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { CardOnFileStatus, CardStatus, WalletMode, WalletStatus } from "eclipse-react-sdk/services/models";
import { ReactComponent as CardIcon } from "../../assets/img/card.svg";
import { ReactComponent as WalletIcon } from "../../assets/img/wallet.svg";
import { ReactComponent as TransferIcon } from "../../assets/img/transfer.svg";
import { ReactComponent as TopupIcon } from "../../assets/img/topup.svg";
import { ReactComponent as WithdrawalIcon } from "../../assets/img/withdrawal.svg";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HttpRequestConfiguration from "../../http-request-configuration";
import { load } from "recaptcha-v3";
import { EnvConfig } from "../../environment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { whiteColor } from "../../assets/jss/eclipse";

const useStyles = makeStyles(themeStyle);


export function WalletDetail(props) {
  let customer = props?.appData?.customer;
  let mobileNumber = customer?.phone1;
  const [walletDetailData, setWalletDetailData] = React.useState(useLocation()?.state?.walletDetailData);
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const walletOptions = props?.appData?.template?.pwaAppOptions?.walletOptions;
  const tenantId = props?.appData?.template?.tenantId;
  const history = useHistory();
  const classes = useStyles();
  const [isRefreshState, setStateForRefresh] = React.useState(false);
  const [walletTypeMode, setWalletTypeMode] = React.useState(WalletMode.CLOSEDLOOPDIGITAL);
  const [isLoading, setLoading] = React.useState(false);
  const [isPhysicalCard, setPhysicalCard] = React.useState(false);
  const [alertData, setAlert] = React.useState(null);
  const [walletCards, setWalletCards] = React.useState([]);
  const [cardOnFileCard, setCardOnFileCard] = React.useState(null);
  const [viewWalletCardData, setViewWalletCardData] = React.useState(false);
  const [isActive, setActive] = React.useState(walletDetailData?.status === WalletStatus.ACTIVE);

  const toggleChecked = (event) => {
    event.persist();
    setActive((prev) => !prev);
    const walletStatus = walletTypeMode === WalletMode.CLOSEDLOOPDIGITAL ? WalletStatus.LOCKED : CardStatus.STOPPED;
    if (walletTypeMode === WalletMode.CLOSEDLOOPDIGITAL) {
      updateDigitalWallet(walletStatus);
    } else {
      updatePrepaidWalletCard(walletStatus);
    }
  }

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const goToTopup = () => {
    history.push({
      pathname: '/pages/topup',
      state: { walletDetailData, fromScreen: 'TOPUP' }
    });
  }

  const goToWithdrawal = () => {
    history.push({
      pathname: '/pages/withdrawal',
      state: { walletDetailData, fromScreen: 'WITHDRAWAL' }
    });
  }

  const walletTransfer = (action) => {
    if (walletDetailData?.availableBalance >= 1) {
      history.push({
        pathname: action==='TRANSFER'?'/pages/transfer':'/pages/send-money',
        state: { walletDetailData, fromScreen: 'WALLETDETAIL', action }
      });
    } else {
      showAlert(true, 'ERROR', `Sorry! You don't have enough money in ${walletTitleText?.toLowerCase()}. Please try with other ${walletTitleText?.toLowerCase()}.`);
    }
  }

  const getWalletCardsOnFile = useCallback(() => {
    const walletCardsAPI = new DCustomerManagementApi();
    return walletCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, customer?.customerId).then((walletCardsAPIResponse) => {
      return walletCardsAPIResponse.data;
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  },[props, customer, redirectToErrorScreen]);

  const getWalletCards = useCallback(() => {
    setLoading(true);
    const walletCardsAPI = new HWalletsApi();
    walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(props?.appData?.auth?.tenantId, walletDetailData?.walletId).then(async (walletCardsAPIResponse) => {
      if (walletCardsAPIResponse.data && walletCardsAPIResponse.data.length < 1) {
        showAlert(true, 'ERROR', `${walletTitleText} card(s) not found. Please add your first card.`);
      } else {
        const physicalCards = walletCardsAPIResponse.data.find(card => card.cardType === "PHYSICAL");
        if (physicalCards) {
          setPhysicalCard(true);
        }
        const lastFour = walletCardsAPIResponse.data[0].pan.substr(walletCardsAPIResponse.data[0].pan.length - 4);
        const cardOnfileList = await getWalletCardsOnFile();
        const cardOnFile = cardOnfileList.find(card=>card.last4Digits===lastFour);
        setCardOnFileCard(cardOnFile);
        setWalletCards(walletCardsAPIResponse.data);
        setActive(walletCardsAPIResponse.data[0]?.status === CardStatus?.ACTIVE);
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletDetailData, walletTitleText,getWalletCardsOnFile]);

  const updateCardOnFile = useCallback(() => {
    // Added this condition to overwrite the null CoF due to the mismatch of Card's PAN's last4digits and CoF's last4digits while card enabled/disabled
    // This CoF will be displayed as it is in Manage Card as well Payment Options drop-down.
    if(!cardOnFileCard) {
      props.appData.isRefresh = true;
      props.walletDetailHandler({ ...props?.appData });
      showAlert(true, 'SUCCESS', `${walletTitleText} status has been updated successfully.`);
      return;
    }
    setLoading(true);
    const walletCardUpdateAPI = new DCustomerManagementApi();
    const walletCardUpdateRequestBody = {
      alias:cardOnFileCard.alias,
      status: walletCards[0]?.status === CardStatus?.ACTIVE ? CardOnFileStatus.SUSPENDED : CardStatus?.ACTIVE
    }
    walletCardUpdateAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(props?.appData?.auth?.tenantId, customer.customerId , cardOnFileCard?.cardOnFileId, walletCardUpdateRequestBody).then((walletCardUpdateAPIResponse) => {
      setLoading(false);
      if (walletCardUpdateAPIResponse.data) {
        props.appData.isRefresh = true;
        props.walletDetailHandler({ ...props?.appData });
        showAlert(true, 'SUCCESS', `${walletTitleText} status has been updated successfully.`);
      } else {
        setActive((prev) => !prev);
        showAlert(true, 'ERROR', `${walletTitleText} status has been updated failed.`);
      }
    }).catch((error) => {
      setLoading(false);
      setActive((prev) => !prev);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletTitleText, walletCards, customer, cardOnFileCard]);

  const updatePrepaidWalletCard = useCallback((status) => {
    setLoading(true);
    const walletCardUpdateAPI = new ICardManagementApi();
    const walletCardUpdateRequestBody = {
      status: walletCards[0]?.status === CardStatus?.ACTIVE ? status : CardStatus?.ACTIVE
    }
    walletCardUpdateAPI.tenantsTenantIdCardsCardIdPut(props?.appData?.auth?.tenantId, walletCards[0]?.cardId, walletCardUpdateRequestBody).then((walletCardUpdateAPIResponse) => {
      setLoading(false);
      if (walletCardUpdateAPIResponse.data) {
        updateCardOnFile()
      } else {
        setActive((prev) => !prev);
        showAlert(true, 'ERROR', `${walletTitleText} status has been updated failed.`);
      }
    }).catch((error) => {
      setLoading(false);
      setActive((prev) => !prev);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletTitleText, walletCards, updateCardOnFile]);

  const updateDigitalWallet = useCallback((status) => {
    setLoading(true);
    const walletUpdateAPI = new HWalletsApi();
    const walletUpdateRequestBody = {
      name: walletDetailData?.name ? walletDetailData?.name : `${walletTitleText}#${walletDetailData?.walletId}`,
      status: walletDetailData?.status === WalletStatus?.ACTIVE ? status : WalletStatus?.ACTIVE
    }
    walletUpdateAPI.tenantsTenantIdWalletsWalletIdPut(props?.appData?.auth?.tenantId, walletDetailData?.walletId, walletUpdateRequestBody).then((walletUpdateAPIResponse) => {
      setLoading(false);
      if (walletUpdateAPIResponse.data) {
        props.appData.isRefresh = true;
        props.walletDetailHandler({ ...props?.appData });
        setWalletDetailData(walletUpdateAPIResponse.data);
        showAlert(true, 'SUCCESS', `${walletTitleText} status has been updated successfully.`);
      } else {
        setActive((prev) => !prev);
        showAlert(true, 'ERROR', `${walletTitleText} status has been updated failed.`);
      }
    }).catch((error) => {
      setLoading(false);
      setActive((prev) => !prev);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletDetailData, walletTitleText]);

  React.useEffect(() => {
    setWalletTypeMode(WalletTypeMode(props?.appData?.template, walletDetailData?.walletTypeId));
    if (walletTypeMode === WalletMode.PREPAIDCARD) {
      getWalletCards();
    }
    if (!isRefreshState) {
      setStateForRefresh(true);
      const appData = {
        ...props?.appData,
        isRefresh: true
      }
      props.walletDetailHandler(appData);
    }

  }, [props, getWalletCards, walletDetailData, walletTypeMode, setStateForRefresh, isRefreshState]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleCardMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCardMenuClose = () => {
    setAnchorEl(null);
  };

  const generateOTP = () => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration();
    const generateOTPAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
    load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
      reCaptcha.execute('OTP_VERIFICATION').then((token) => {
        if (token) {
          const headers = { gCaptcha: token };
          httpRequestConfiguration.baseOptions = { headers };
          const generateOTPData = {
            phone: mobileNumber,
            tenantId
          }
          generateOTPAPI.globalVerificationsPost(generateOTPData).then(() => {
            history.push({
              pathname: '/pages/reset-pin-verify-otp',
              state: { cardDetails:walletCards[0], gCaptcha: token, mobileNumber,walletDetailData }
            });
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
          });
        } else {
          showAlert(true, 'ERROR', `Please try again!`);
        }
      })
    });
  }

  const goToResetPin = () => {
    generateOTP();
  };

  const goToCancelCard = () => {
    history.push({
      pathname: '/pages/cancel-card',
      state: { walletDetailData: { ...walletDetailData, walletCards }, cardOnFileCard }
    })
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.walletDetailHandler(appData);
    history.push('/pages/dashboard');
  }, [history, props])

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      backToHome();
    };
  }, [history, backToHome])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', alignItems: 'center', paddingBottom:15}}>
              <ArrowBackIosIcon className={classes.linkColor} style={{color:whiteColor}} onClick={backToHome}/>
              <h4 className={"page-title"} style={{margin:0,color:whiteColor}}><b>
                {walletTypeMode===WalletMode.CLOSEDLOOPDIGITAL?`My ${walletTitleText}`:isPhysicalCard?`My Physical Card`:`My Virtual Card`}
              </b></h4>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ width: '100%', float: 'left' }}>
                  <h5 style={{
                    display: 'flex',
                    marginTop: '0px',
                    marginBottom: '0px'
                  }}>{(walletTypeMode === WalletMode.PREPAIDCARD) ? <CardIcon/> :
                    <WalletIcon/>}&nbsp;&nbsp;<b>{walletDetailData?.name}</b></h5>
                  <div className="bottom-content">
                    <ul>
                      <li>
                        <span>Unique Ref. ID</span>
                        <strong>{walletDetailData?.friendlyId}</strong>
                      </li>
                      <li>
                        <span>Balance</span>
                        <strong>{getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.currentBalance.toFixed(2)}</strong>
                      </li>
                      <li>
                        <span>Available</span>
                        <strong>{getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.availableBalance.toFixed(2)}</strong>
                      </li>
                    </ul>
                  </div>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      {walletDetailData?.status === WalletStatus?.ACTIVE && walletTypeMode === WalletMode.PREPAIDCARD && walletCards && walletCards.length > 0 && walletCards[0]?.status === CardStatus?.ACTIVE ?
        <div className="card-list">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {walletCards.map((walletCardData) => {
                return (
                  walletCardData?.status === CardStatus?.ACTIVE ?
                    <div className="card-holder" key={walletCardData.walletId}>
                      <VirtualCard walletCardData={walletCardData}
                                   viewWalletCardData={viewWalletCardData}/>
                    </div>
                    : null
                )
              })}
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
              <Link to="" onClick={(event) => {
                event.preventDefault();
                setViewWalletCardData(!viewWalletCardData)
              }} style={{ display: 'flex', justifyContent: 'center' }} className={classes.linkColor}>
                {viewWalletCardData ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                View card detail
              </Link>
            </GridItem>
          </GridContainer>
        </div>
        : null
      }
      {(walletTypeMode === WalletMode.PREPAIDCARD && walletCards && walletCards.length > 0 && (walletCards[0]?.status === CardStatus?.ACTIVE || walletCards[0]?.status === CardStatus?.STOPPED)) ?
        <GridContainer>
          <GridItem xs={11} sm={11} md={11}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <b style={{ paddingTop: '7px' }}>{isActive ? 'Disable' : 'Activate'}</b>
              <Switch
                onChange={toggleChecked}
                name="isActive"
                checked={isActive}
                value={isActive}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleCardMenuClick}
              >
                <MoreVertIcon/>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCardMenuClose}>
                  <MenuItem
                    key={'cancelCard'}
                    onClick={goToCancelCard}>
                    {"Cancel Card"}
                </MenuItem>
                {isPhysicalCard ?
                  <MenuItem
                    key={'changePin'}
                    cardid={walletCards[0].cardId}
                    onClick={goToResetPin}>
                    {"Change PIN"}
                  </MenuItem> : null}
              </Menu>
            </div>
          </GridItem>
        </GridContainer>
        : null}
      {(walletTypeMode === WalletMode.CLOSEDLOOPDIGITAL && walletDetailData?.status === WalletStatus?.ACTIVE) || (walletTypeMode === WalletMode.PREPAIDCARD && walletCards && walletCards.length > 0 && walletCards[0]?.status === CardStatus?.ACTIVE) ?
        <GridContainer>
          {walletOptions?.topUp?.allow ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  goToTopup()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                      <TopupIcon/>&nbsp;&nbsp;<b>Topup</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {walletOptions?.transfer?.allow ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  walletTransfer('TRANSFER');
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                      <TransferIcon/>&nbsp;&nbsp;<b>{`Transfer ${walletTitleText} to ${walletTitleText}`}</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {walletOptions?.withdrawal?.allow ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  goToWithdrawal()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                      <WithdrawalIcon/>&nbsp;&nbsp;<b>Withdrawal</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
        </GridContainer>
        : null}
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  walletDetailHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetail)