import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions.js";
import Button from "../../components/CustomButtons/Button.js";
import { SpinnerComponent } from "react-element-spinner";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import { primaryColor, buttonDisabledColor } from "../../style.scss";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { EnvConfig } from "../../environment.js";
import { ReactComponent as Info } from "../../assets/img/info.svg";
import { ReactComponent as RightArrow } from "../../assets/img/arrow_right.svg";
import { ReturnStoredTemplateId } from "../../get-template-hostname.js";
import { AuthRouteWithTemplateId } from "../../default-route.js";
import Dialog from '@material-ui/core/Dialog';
import { EclipseCustomerStatus, EclipseTitle } from "eclipse-react-sdk/services/models";
import TextField from "@material-ui/core/TextField";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle";
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from "@material-ui/icons/Close";
import { whiteColor } from "../../assets/jss/eclipse.js";
import IconButton from "@material-ui/core/IconButton";

const ValidationTextField = withStyles(formStyle)(TextField);

const useStyles = makeStyles(themeStyle);

export function UserProfile(props) {
  const appData = props?.appData;
  const tenantId = appData?.auth?.tenantId;
  const customer = appData?.customer;
  const template = appData?.template;
  const pwaAppOptions = template?.pwaAppOptions;
  const isKYCRequired = pwaAppOptions?.kycRequired;
  const postSuccessKYCProcessEnable = pwaAppOptions?.postSuccessKYCProcessEnable;
  const passwordPINText = pwaAppOptions?.passwordPINText;
  const history = useHistory();
  const classes = useStyles();

  const titleEnumList = Object.values(EclipseTitle);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [editTitle, setEditTitle] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  const [inputs, setInputs] = useState({ title: customer?.title, email: customer?.email });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateEditTitleEmailForm(inputs, event.target.name);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateEditTitleEmailForm(inputs, event.target.name);
    setErrors(validationErrors);
  }

  const validateEditTitleEmailForm = (inputs, controlName) => {
    const errors = {}
    if (controlName === 'title') {
      if (!inputs.title) {
        errors.title = `Title required!`;
      }
    }

    if (controlName === 'email') {
      if (!inputs.email) {
        errors.email = `Email required!`;
      } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(inputs.email)) {
        errors.email = `Invalid Email!`;
      }
    }

    return errors;
  }

  const cancelChanges = (controlName) => {
    setInputs({ title: customer?.title, email: customer?.email });
    setErrors({});
    if (controlName === 'title') {
      setEditTitle(false);
    }
    if (controlName === 'email') {
      setEditEmail(false);
    }
  }

  const handlePopoverClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  const [documents, setDocuments] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [isRatifiedSuccess, setRatify] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, alertMessage) => {
    setAlert({ alertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.userProfileHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  const logout = () => {
    let templateId = appData?.templateId ? appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    EnvConfig().BASE_API_URL = template?.defaultBaseURL;
    props.logoutFromAppHandler({ templateId });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }

  const redirectToChangePasswordPIN = () => {
    history.push({
      pathname: '/pages/change-password',
    });
  }

  const redirectToChangePaymentPIN = () =>{
    history.push({
      pathname: '/pages/change-payment-pin',
      state:{isForPaymentPinChange:true}
    });
  }

  const redirectToPayAtContactUs = ()=>{
    window.open("https://payat.co.za/contact-us/", "_blank");
  }

  const deactivateAccount = () => {
    setConfirmOpen(true);
  }

  const handleDeactivate = () => {
    setConfirmOpen(false);
    setLoading(true);
    const customerAPI = new DCustomerManagementApi();
    const customerBody = {
      ...customer,
      status: EclipseCustomerStatus.INACTIVE
    };
    customerAPI.tenantsTenantIdCustomersCustomerIdPut(tenantId, customer?.customerId, customerBody).then((customerApiResponse) => {
      props.appData.customer = customerApiResponse.data;
      props.appData.isRefresh = true;
      props.userProfileHandler({ ...props.appData });
      history.push({
        pathname: "/pages/success-account-deactivate"
      })
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });
  }

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const getAddress = useCallback(() => {
    setLoading(true);
    const addressAPI = new DCustomerManagementApi();
    addressAPI.tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId, customer?.customerId).then((addressAPIResponse) => {
      setAddresses(addressAPIResponse.data && addressAPIResponse.data.length > 0 ? addressAPIResponse.data : [])
      setLoading(false);
      if (addressAPIResponse.data && addressAPIResponse.data.length < 1) {
        showAlert(true, 'ERROR', `Address(s) not found!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });
  }, [tenantId, customer, redirectToErrorScreen]);

  const getDocuments = useCallback(() => {
    setLoading(true);
    const documentAPI = new DCustomerManagementApi();
    documentAPI.tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId, customer?.customerId).then((documentAPIResponse) => {
      setDocuments(documentAPIResponse.data && documentAPIResponse.data.length > 0 ? documentAPIResponse.data : [])
      setLoading(false);
      if (documentAPIResponse.data && documentAPIResponse.data.length < 1) {
        showAlert(true, 'ERROR', `Document(s) not found!`);
      }
      getAddress();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });
  }, [tenantId, customer, redirectToErrorScreen, getAddress]);

  const getRatify = useCallback(() => {
    setLoading(true);
    const ratifyAPI = new DCustomerManagementApi();
    ratifyAPI.tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId, customer?.customerId, 1, 0).then((ratifyAPIResponse) => {
      if (ratifyAPIResponse.data && ratifyAPIResponse.data.length > 0) {
        let isRatifyFailed = 0;
        ratifyAPIResponse.data.forEach((ratifyAPIResponseData) => {
          if (ratifyAPIResponseData.walletTypeId === template?.publicCardWalletTypeId && !ratifyAPIResponseData?.allowed) {
            isRatifyFailed = isRatifyFailed + 1;
          }
          if (ratifyAPIResponseData.walletTypeId === template?.publicCloseLoopWalletTypeId && !ratifyAPIResponseData?.allowed) {
            isRatifyFailed = isRatifyFailed + 1;
          }
        });

        setRatify(isRatifyFailed <= 0);
      }
      setLoading(false);
      if (ratifyAPIResponse.data && ratifyAPIResponse.data.length < 1) {
        showAlert(true, 'ERROR', `Ratify details not found!`);
      }
      getDocuments();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });
  }, [tenantId, customer, template, redirectToErrorScreen, getDocuments]);

  const doRatifyKYC = () => {
    if (template?.ratifyTrialCount > 2) {
      history.push({
        pathname: `/pages/contact-support`
      });
    } else {
      history.push({
        pathname: `/pages/identity-document`,
        state: { fromScreen: "USER_PROFILE", isAddressAdded: addresses.length > 0 }
      });
    }
  }

 const formatPhoneNumber=(phone)=>{
    var formatedPhoneNumber = '';
    if(phone.length>0){
      if(phone.substr(0,2)==="27"){      
         formatedPhoneNumber = "0" + phone.slice(2,4)+" "+phone.slice(4,7)+ " "+phone.slice(7,11);
      }
    }
    return formatedPhoneNumber;
  }
  const handleEditTitleEmailFormSubmit = useCallback((controlName) => {
    const validationErrors = validateEditTitleEmailForm(inputs, controlName);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      if ((inputs?.email !== customer?.email) || (inputs?.title !== customer?.title)) {
        setLoading(true);
        const customerAPI = new DCustomerManagementApi();
        const customerBody = {
          ...customer,
          email: inputs?.email,
          title: inputs?.title
        };
        customerAPI.tenantsTenantIdCustomersCustomerIdPut(tenantId, customer?.customerId, customerBody).then((customerApiResponse) => {
          setLoading(false);
          setEditTitle(false);
          setEditEmail(false);
          props.appData.customer = customerApiResponse?.data;
          props.appData.isRefresh = true;
          props.userProfileHandler({ ...props.appData });
        }).catch((error) => {
          setLoading(false);
          if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
            showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
          } else {
            redirectToErrorScreen('/pages/dashboard', '/pages/error');
          }
        });
      }
    }
  }, [customer, inputs, props, tenantId, redirectToErrorScreen]);

  React.useEffect(() => {
    if (isKYCRequired) {
      getRatify();
    }
  }, [getRatify, isKYCRequired]);

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
              <span className={classes.title}>{isKYCRequired ? 'Profile' : 'Settings'}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer> */}
      {isKYCRequired ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className='m-b-0'><b>Hi {customer?.firstName}, <br/> here's your personal details.</b></h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h6 className='m-b-0 m-t-0' style={{ textTransform: 'none' }}><b>Your identity verification status</b></h6>
            <span style={{ display: 'flex' }}>
              {isRatifiedSuccess ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess}/> :
                <CancelOutlinedIcon className={classes.iconDanger}/>}
              {isRatifiedSuccess ?
                <p className={classes.textLeft} style={{ margin: '10px 0 10px 15px' }}>KYC completed</p>
                : <p className={classes.textLeft} style={{ margin: '10px 0 10px 15px' }}>KYC Not completed</p>
              }
            </span>
          </GridItem>
          {!isRatifiedSuccess || (isRatifiedSuccess && postSuccessKYCProcessEnable) ?
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={doRatifyKYC}>Do
                Ratify</Button>
            </GridItem>
            : null}
          <GridItem xs={12} sm={12} md={12}>
            <h6 className='m-b-0 m-t-0' style={{ textTransform: 'none' }}><b>Your personal details</b></h6>
            <ul style={{ padding: '0', listStyle: 'none' }}>
              {editTitle ?
                <li style={{ margin: '0 0 10px 0' }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <ValidationTextField
                            fullWidth
                            label="Title"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            select
                            variant="filled"
                            id="title"
                            type="text"
                            error={!!(errors?.title)}
                            name="title"
                            value={inputs?.title || ''}
                            onChange={handleInputChange}
                            onKeyUp={handleInputKeyUp}>
                            {titleEnumList?.map((data, index) => {
                              return (<MenuItem key={index} value={data}>{data}</MenuItem>)
                            })}
                          </ValidationTextField>
                          {errors?.title && <Danger><small>{errors?.title}</small></Danger>}
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Button className={classes.buttonRadius} size="sm" block color="transparent"
                                  onClick={() => {
                                    cancelChanges('title');
                                  }}>Cancel</Button>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Button className={classes.buttonRadius} size="sm" block color="primary" type="button"
                                  onClick={() => {
                                    handleEditTitleEmailFormSubmit('title')
                                  }}
                                  disabled={!!errors?.title || inputs?.title === customer?.title}>Save</Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </li> : <li style={{ margin: '0 0 10px 0' }}>
                  <span className={classes.labelText}>Title<EditIcon style={{ float: 'right' }}
                                                                     className={classes.linkColor} onClick={() => {
                    setEditTitle(true)
                  }}/></span>
                  <p className='m-t-0 m-b-0'>{customer?.title}</p>
                </li>}
              <li style={{ margin: '0 0 10px 0' }}>
                <span className={classes.labelText}>Full name</span>
                <p className='m-t-0 m-b-0'>
                  {customer?.firstName} {customer?.middleName} {customer?.lastName}
                </p>
              </li>
              <li style={{ margin: '0 0 10px 0' }}>
                <span className={classes.labelText}>Contact number</span>
                <p className='m-t-0 m-b-0'>{customer?.phone1}</p>
              </li>
              {editEmail ?
                <li style={{ margin: '0 0 10px 0' }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <ValidationTextField
                            fullWidth
                            label="Email address"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="filled"
                            id="email"
                            type="text"
                            error={!!(errors?.email)}
                            name="email"
                            value={inputs?.email || ''}
                            onChange={handleInputChange}
                            onKeyUp={handleInputKeyUp}/>
                          {errors?.email && <Danger><small>{errors?.email}</small></Danger>}
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Button className={classes.buttonRadius} size="sm" block color="transparent"
                                  onClick={() => {
                                    cancelChanges('email');
                                  }}>Cancel</Button>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                          <Button className={classes.buttonRadius} size="sm" block color="primary" type="button"
                                  onClick={() => {
                                    handleEditTitleEmailFormSubmit('email')
                                  }}
                                  disabled={!!errors?.email || inputs?.email === customer?.email}>Save</Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </li>
                : <li style={{ margin: '0 0 10px 0' }}>
                  <span className={classes.labelText}>Email Address<EditIcon style={{ float: 'right' }}
                                                                             className={classes.linkColor}
                                                                             onClick={() => {
                                                                               setEditEmail(true)
                                                                             }}/></span>
                  <p className='m-t-0 m-b-0'>{customer?.email}</p>
                </li>}
              <li style={{ margin: '0 0 10px 0' }}>
                <span className={classes.labelText}>ID/Passport number</span>
                <p
                  className='m-t-0 m-b-0'>{customer?.nationalIdentityNumber ? customer?.nationalIdentityNumber : customer?.passportNumber}</p>
              </li>
              <li style={{ margin: '0 0 10px 0' }}>
                <span className={classes.labelText}>Gender</span>
                <p className='m-t-0 m-b-0'>{customer?.gender}</p>
              </li>
              <li style={{ margin: '0 0 10px 0' }}>
                <span className={classes.labelText}>Date of birth</span>
                <p
                  className='m-t-0 m-b-0'>{customer?.dateOfBirth ? customer?.dateOfBirth.substr(6, 2) + '-' + customer?.dateOfBirth.substr(4, 2) + '-' + customer?.dateOfBirth.substr(0, 4) : null}</p>
              </li>
              {addresses && addresses.length > 0 ? addresses.map((address) => {
                return (<li style={{ margin: '0 0 10px 0' }} key={address?.addressId}>
                  <span className={classes.labelText}>Address({address?.addressType}) #{address?.addressId}</span>
                  <p className='m-t-0 m-b-0'>{address?.line1}</p>
                  <p className='m-t-0 m-b-0'>{address?.line2}</p>
                  <p className='m-t-0 m-b-0'>{address?.line3}</p>
                  <p className='m-t-0 m-b-0'>{address?.city} - {address?.state}</p>
                  <p className='m-t-0 m-b-0'>{address?.country} - {address?.code}</p>
                </li>)
              }) : null}
            </ul>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h6 className='m-b-0 m-t-0' style={{ textTransform: 'none' }}><b>Your KYC Documents</b></h6>
            <GridContainer>
              {documents && documents.length > 0 ? documents.map((document) => {
                if (!document?.documentType.includes('COMPARISON')) {
                  return (<GridItem xs={6} sm={6} md={6} key={document?.documentId}>
                    <div className='docBox'>
                      <img src={'data:' + document?.mediaType + ';base64,' + document?.base64EncodedDocument} alt=''/>
                    </div>
                  </GridItem>)
                }
                return ("");
              }) : null}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="transparent"
                        onClick={redirectToChangePasswordPIN}>Change {passwordPINText}</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="transparent"
                        onClick={deactivateAccount}>Deactivate Account</Button>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={logout}>Logout</Button>
          </GridItem>
        </GridContainer>
        :
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{  borderBottomWidth: '1px',
            borderBottomStyle: 'solid ',borderBottomColor: buttonDisabledColor}}>
            <h6 className='m-b-0 m-t-0' style={{ textTransform: 'none' }}><b>Your account details</b></h6>
            <ul style={{ padding: '0', listStyle: 'none' }}>
              <li style={{ margin: '0 0 10px 0' }}>
                <p style={{fontWeight:400, margin:0}}>
                  Your number</p>
                <span className='m-t-0 m-b-0' style={{fontWeight:400}}>
                  {customer?.phone1?formatPhoneNumber(customer?.phone1):""}
                  <Button
                    style={{ boxShadow: 'none', borderStyle: 'none', padding: '0', margin: '0', marginLeft: '0.5em' }}
                    aria-describedby={popoverId} variant="contained" color="transparent" onClick={handlePopoverClick}>
                    <Info/>
                  </Button>
                  
                </span>
              </li>
            </ul>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{
            paddingTop: '0px',
            marginBottom: '0px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid ',
            borderBottomColor: buttonDisabledColor,
            display:'flex'
          }}>
            
            <p className={classes.regularFontSize} style={{ color: { primaryColor }}}>
              <span className={'link-color'} onClick={logout}>Sign out</span>
            </p>
            <p style={{ display: 'flex', marginInlineStart: 'auto'}}><RightArrow/></p> 
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{
            paddingTop: '0px',
            marginBottom: '0px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid ',
            borderBottomColor: buttonDisabledColor,
            display:'flex'
          }}>
            <p className={classes.regularFontSize} style={{ color: { primaryColor } }}><span
              className={'link-color'} onClick={redirectToChangePaymentPIN}>Change payment PIN </span></p>
            <p style={{ display: 'flex', marginInlineStart: 'auto'}}><RightArrow/></p> 
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{
            paddingTop: '0px',
            marginBottom: '0px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid ',
            borderBottomColor: buttonDisabledColor,
            display:'flex'
          }}>
            <p className={classes.regularFontSize} style={{ color: { primaryColor } }}><span
              className={'link-color'} onClick={redirectToChangePasswordPIN}>Change sign in {passwordPINText}</span></p>
            <p style={{ display: 'flex', marginInlineStart: 'auto'}}><RightArrow/></p> 
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{
            paddingTop: '0px',
            marginBottom: '0px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid ',
            borderBottomColor: buttonDisabledColor,
            display:'flex'
          }}>
            <p className={classes.regularFontSize} style={{ color: { primaryColor } }}><span
              className={'link-color'} onClick={redirectToPayAtContactUs}>Contact support</span></p>
            <p style={{ display: 'flex', marginInlineStart: 'auto'}}><RightArrow/></p> 
          </GridItem>
        </GridContainer>
      }

      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
      <Dialog open={confirmOpen}>
        <div style={{ float: 'left', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-t-0'}><b>You are about to deactivate your
            account.</b></p>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-t-0'}>Your account will be deactivated
            and can be reactivated at any time.</p>
          <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0' }}>
            <strong className={classes.linkColor} onClick={handleClose}>Cancel</strong> &nbsp;&nbsp;
            <strong className={classes.linkColor} onClick={handleDeactivate}>Deactivate account</strong>
          </span>
        </div>
      </Dialog>
      <Dialog open={popoverOpen} style={{ overflow: 'inherit' }}>
          <IconButton aria-label="close"
                      style={{ position: "absolute", top: 5, right: 4, background: whiteColor, padding: 5 }}
                      className='crossIcon'
                      onClick={handlePopoverClose}>
            <CloseIcon style={{height:16,width:16}}/>
          </IconButton>
          <div style={{ float: 'left', width: '100%', paddingTop: 30,paddingRight:20,paddingBottom:20,paddingLeft:20, boxSizing: 'border-box' }}>
          Your account is associated with your mobile number and can therefore not be edited. If you number change, please register a new account.
          </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  userProfileHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)