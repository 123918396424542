import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { PersonalInformation } from "../../components/PersonalInformation/PersonalInformation";

const useStyles = makeStyles(themeStyle);

export function AddPhysicalCardPersonalInformation(props) {
  const history = useHistory();
  const classes = useStyles();
  const physicalCardData = useLocation()?.state?.physicalCardData;
  const isPhysicalCardAdded = useLocation()?.state?.isPhysicalCardAdded;
  const flow = useLocation()?.state?.flow;
  const goToAddPhysicalCard = () => {
    history.push({
      pathname: '/pages/register-my-physical-card',
      state: { physicalCardData, isPhysicalCardAdded, flow }
    });
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.addPhysicalCardPersonalInformationHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);

  React.useEffect(() => {
    return () => {
        window.onpopstate = () => {
          backToHome();
        };
    };
  }, [history, backToHome]);

  const handleSavePersonalDetails = (result, props) => {
    props.addPhysicalCardPersonalInformationHandler({ ...props.appData });
    if (result) {
      goToAddPhysicalCard();
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
              <span className={classes.title}>Add a physical card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <PersonalInformation {...props} onComplete={handleSavePersonalDetails} flow={flow}/>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  addPhysicalCardPersonalInformationHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPhysicalCardPersonalInformation)