import Payment from "payment";
import cardPlaceHolder from '../../assets/img/master_card_default.svg';
import masterCardPlaceHolder from '../../assets/img/master_card.svg';
import visaCardPlaceHolder from '../../assets/img/visa_card.svg'
import amexCardPlaceHolder from '../../assets/img/amex_card.svg'
import ReturnCardType from "../../components/PaymentOptions/ReturnCardType";

function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
}
export function luhn(cardNumber) {
    if (/[^0-9-\s]+/.test(cardNumber))
        return false;

    let nCheck = 0, bEven = false;
    cardNumber = cardNumber.replace(/\D/g, "");

    for (var n = cardNumber.length - 1; n >= 0; n--) {
        var cDigit = cardNumber.charAt(n),
                nDigit = parseInt(cDigit, 10);

        if (bEven && (nDigit *= 2) > 9)
            nDigit -= 9;

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) === 0;
}

export function formatCreditCardNumber(value) {
    console.log(luhn(clearNumber(value)));

    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10
                    )} ${clearValue.slice(10, 15)}`;
            break;
        case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    10
                    )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                    4,
                    8
                    )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export function formatCVV(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}



export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

export function formatDOB(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length === 8) {
        return `${clearValue.slice(0, 4)}/${clearValue.slice(4, 6)}/${clearValue.slice(6, 8)}`;
    }

    return clearValue;
}

export function formatName(value) {
    return value.toUpperCase();
}

export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export function urlQueryParamToObject() {
    var pairs = window.location.search.substring(1).split("&"), obj = {}, pair, i;

    for (i in pairs) {
        if (pairs[i] === "")
            continue;
        pair = pairs[i].split("=");
        // obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );

        const key = pair[0];
        pair.splice(0, 1);

        obj[ decodeURIComponent(key) ] = decodeURIComponent(pair.join("="));
    }
    return obj;
}

export function mergeQueryParamAndLocationState(_props, _state) {

    if (!_state) {
        _state = {};
    }

    if (_props.data) {
        _state = {..._state, ..._props.data};
    }

    if (_props.location && _props.location.state) {
        _state = {..._state, ..._props.location.state, posted: true};
    }
    return _state;
}

export function getButtonStyle(_state) {
    return {
        vcButton: {
            backgroundColor: _state.tenantProperties ? _state.tenantProperties.primaryColour : "",
            color: _state.tenantProperties ? _state.tenantProperties.primaryTextColour : "",
            borderColor: _state.tenantProperties ? _state.tenantProperties.primaryColour : ""
        },

        vcButtonLink: {
            color: _state.tenantProperties ? _state.tenantProperties.primaryColour : "",
        },

        vcButtonOutline: {
            color: _state.tenantProperties ? _state.tenantProperties.primaryColour : "",
            borderColor: _state.tenantProperties ? _state.tenantProperties.primaryColour : ""
        },
    };
}

export function redirectToLandingURL(e, landingUrl) {
    e.preventDefault();
    window.location = landingUrl;
}

export class PostBody {
    constructor(expiry, cvv, pan, name, dob, tenantId) {
        if(expiry) {
        	this.expiry = expiry.split("/")[0] + expiry.split("/")[1];
        }
        if(cvv) {
        	this.cvv = cvv;
        }
        this.pan = pan.replace(/ /g,'');
        if(name) {
        	this.cardholderName = name;
        }
        this.accountType = "00";
        this.tenantId = tenantId;
    }
}

export class putBody {
    constructor(expiry, cvv, pan, name, dob, objlandingUrl) {

        expiry = expiry.split("/")[0] + expiry.split("/")[1];
        dob = dob.split("/")[0] + dob.split("/")[1] + dob.split("/")[2];
        let cardholderName = name;

        this.additionalFields = [];
        this.additionalFields.push({"id": "cardholderName", "value": cardholderName});
        this.additionalFields.push({"id": "cardNumber", "value": pan});
        this.additionalFields.push({"id": "dob", "value": dob});
        this.additionalFields.push({"id": "expiry", "value": expiry});
        this.additionalFields.push({"id": "cvv", "value": cvv});
        this.type = "ZA_PAYMENT_LINK";
        this.landingUrl = objlandingUrl;
        //this.accountType = "00";
    }
}


export function isNullOrEmpty(str) {
    //e.preventDefault();
     return (!str || str.length === 0 );
}

export class PaymentPostBody {
    constructor(type, amount, currency, externalUniqueId, landingUrl, phone, paymentData, paymentCardData,
    paymentMechanism, externalWalletId, externalWalletType) {
        this.type = type;
        this.amount = amount;
        this.currency = currency;
        this.externalUniqueId = externalUniqueId;
        this.landingUrl = landingUrl;

        if(!isNullOrEmpty(phone)) this.phone = phone;
       	if(!isNullOrEmpty(paymentData)) this.paymentData = paymentData;
		if(!isNullOrEmpty(paymentCardData)) this.paymentCardData = paymentCardData;
        if(!isNullOrEmpty(paymentMechanism)) this.paymentMechanism=paymentMechanism;
        if(!isNullOrEmpty(externalWalletId)) this.externalWalletId=externalWalletId;
        if(!isNullOrEmpty(externalWalletType)) this.externalWalletType = externalWalletType;
    }
}

export class ExpiryDateValidation {
  validate(expDate) {
    if (expDate.length < 5) {
      return false;
    }
    var month = expDate.slice(0, 2);
    var year = expDate.slice(3);
    var today = new Date();
    var someday = new Date();
    if (year.length < 3) {
      year = today.getFullYear().toString().slice(0, 2) + year;
    }
    someday.setFullYear(year, month - 1, someday.getDate());
    return !(someday < today || month > 12);

  }
}

export const returnCardType = (bankCardBin) => {
    const cardType = ReturnCardType(bankCardBin);
    switch (cardType) {
      case 'mastercard':
        return masterCardPlaceHolder;
      case 'visa':
        return visaCardPlaceHolder;
        case 'amex':
        return amexCardPlaceHolder;
      default:
        return cardPlaceHolder;
    }
  }
