import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { connect } from "react-redux";

const useStyles = makeStyles(themeStyle);


export function PAYATLanding(props) {
  const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h1>Welcome!</h1>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ paddingLeft: '0.5em' }}>
          <Button className={classes.buttonRadius} size="lg" block color="primary"
                  onClick={props.signupRedirect}>Continue</Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <p className={classes.regularFontSize}>Need more information? <Link className={classes.linkColor} to='/'>Contact
            support</Link> {ratifySupportContact}</p>
        </GridItem>
      </GridContainer>
    </div>
  );
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(PAYATLanding)
