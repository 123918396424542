import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { controlLabelColor, primaryIconColor } from "../../../style.scss";
import { loginToApp, logoutFromApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as CircleTickOutline } from "../../../assets/img/circle_tick_outline.svg"
import { ReactComponent as Thumbsup } from "../../../assets/img/thumbs_up.svg"
import ThumbsUpDemo from "../../../assets/img/Thumbs-up-selfie-image-in-app.jpg"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { DocumentType } from "../../../eclipse-react-sdk/services/models";


const useStyles = makeStyles(themeStyle);

export function PhotoInitial(props) {
  const classes = useStyles();
  const history = useHistory();

  const selectedDocument = useLocation().state?.selectedDoc;
  const document = useLocation().state?.document;
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  const idDocument = useLocation().state?.idDocument;
  const idDocumentBack = useLocation().state?.idDocumentBack;
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const idDocumentBackImageData = useLocation().state?.idDocumentBackImageData;
  const selfieDocument = props?.appData?.documents ? props?.appData?.documents.find(doc => doc?.documentType === DocumentType.FACIALPHOTO) : null;
  const [selfieDocumentImageData, setSelfieDocumentImageData] = useState(null);
  const goToScanPhoto = () => {
    let pathname= `/pages/scan-selfie`;
    if(selfieDocumentImageData){
      pathname= `/pages/upload-selfie`;
    }
    history.push({
      pathname,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData
      },
    });
  }

  const backToHome = useCallback(() => {
    history.push({
      pathname: `/pages/upload-document`,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData
      },
    });
  }, [history, isAddressAdded, fromScreen, selectedDocument, document, idDocument, idDocumentBack, selfieDocument, idDocumentImageData, selfieDocumentImageData, idDocumentBackImageData]);

  React.useEffect(() => {
    window.onpopstate=()=>{
      backToHome();
    };
  }, [history, backToHome, fromScreen])
  React.useEffect(()=>{
    if (selfieDocument && selfieDocument?.mediaType && selfieDocument?.base64EncodedDocument && fromScreen !== 'ASSISTED_SIGNUP') {
      setSelfieDocumentImageData(`data:${selfieDocument?.mediaType};base64,${selfieDocument?.base64EncodedDocument}`);
    }
  },[selfieDocument,setSelfieDocumentImageData, fromScreen]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p onClick={() => {
                backToHome()
              }} style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                <ArrowBackIosIcon/>
                <span>{"Back"}</span>
              </p>
              <small style={{
                color: controlLabelColor,
                marginLeft: "auto"
              }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 4 : 3} of 5</small>
            </div>
            <strong><small style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '80%' } : { width: '60%' }}>&nbsp;</small></strong>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h1 className='auth-title'
                      dangerouslySetInnerHTML={{ __html: fromScreen === 'ASSISTED_SIGNUP' ? 'Upload their <br /> photo.' : 'Upload your <br /> selfie.' }}/>
                  <p className={classes.regularFontSize + ' m-b-0'}>
                    <strong>{fromScreen === 'ASSISTED_SIGNUP' ? 'Take a close-up photo of their face with a thumbsup to verify their identity.' : 'Please take a selfie with a thumbsup close to your face.'}</strong>
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize}
                     style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'inherit' }}><strong
                    style={{ margin: '0 10px 0 0' }}><CircleTickOutline fill={primaryIconColor}
                                                                        stroke={primaryIconColor}/></strong> {fromScreen === 'ASSISTED_SIGNUP' ? 'Let them stand in front of a plain, light-coloured wall.' : 'Stand in front of a plain, light-coloured wall.'}
                  </p>
                  {fromScreen === 'ASSISTED_SIGNUP' ?
                    <p className={classes.regularFontSize} style={{ display: 'table' }}><strong
                      style={{ padding: '0 10px 0 0', display: 'table-cell', verticalAlign: 'top' }}><CircleTickOutline
                      fill={primaryIconColor} stroke={primaryIconColor}/></strong> Ask them to give a thumbs
                      up <Thumbsup style={{ margin: '-6px 0 0 0', verticalAlign: 'middle' }} fill={primaryIconColor}
                                   stroke={primaryIconColor}/> close to their face.</p>
                    :
                    <p className={classes.regularFontSize}
                       style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'inherit' }}><strong
                      style={{ margin: '0 10px 0 0' }}><CircleTickOutline fill={primaryIconColor}
                                                                          stroke={primaryIconColor}/></strong> Give a
                      thumbs up <Thumbsup style={{ margin: '-6px 0 0 0' }} fill={primaryIconColor}
                                          stroke={primaryIconColor}/> close to your face.</p>
                  }
                  <p className={classes.regularFontSize}
                     style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'inherit' }}><strong
                    style={{ margin: '0 10px 0 0' }}><CircleTickOutline fill={primaryIconColor}
                                                                        stroke={primaryIconColor}/></strong> {fromScreen === 'ASSISTED_SIGNUP' ? 'Check that their face and thumb are in focus and not blurry.' : 'Check that your face and thumb are in focus and not blurry.'}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}><strong>See the example
                    below.</strong></p>
                  <span style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}><img src={ThumbsUpDemo}
                                                                                                     alt=""/></span>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToScanPhoto}>Let's do it</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  photoInitialHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoInitial)
