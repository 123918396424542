/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Status of a withdrawal. PENDING:Still being processed, SUCCESSFUL: Successfully completed, ERROR:Failed and wont ever succeed, TIMEOUT:Failed as the process was not finalised in time, CANCELLED:Cancelled prior to SUCCESS or FAILURE, REVERSED:Reversed after success
 * @export
 * @enum {string}
 */
export enum WalletWithdrawalStatus {
    BUILDING = 'BUILDING',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    REVERSED = 'REVERSED',
    SUCCESSFUL = 'SUCCESSFUL',
    TIMEOUT = 'TIMEOUT'
}

