/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Status of a payment BUILDING: Not yet submitted as more data needs to be captured, PENDING:Being processed and can no longer be enhanced, SUCCESSFUL: Successfully completed, ERROR_PERM: Failed and wont ever succeed, ERROR_TEMP: Currently having an error but its being retried and may succeed or permanently fail, TIMEOUT: Failed as the process was not finalised in time, CANCELLED: Cancelled prior to SUCCESS or FAILURE, REVERSED: Reversed after success
 * @export
 * @enum {string}
 */
export enum EclipsePaymentStatus {
    BUILDING = 'BUILDING',
    CANCELLED = 'CANCELLED',
    ERRORPERM = 'ERROR_PERM',
    ERRORTEMP = 'ERROR_TEMP',
    PENDING = 'PENDING',
    REVERSED = 'REVERSED',
    SUCCESSFUL = 'SUCCESSFUL',
    TIMEOUT = 'TIMEOUT'
}

