import React, { useState } from "react";
// @material-ui/core
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import QrReader from 'react-qr-reader'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import qr_example from "../../assets/img/qr_example.svg"
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import Button from "../../components/CustomButtons/Button";

const useStyles = makeStyles(themeStyle);

export function ScanPhysicalCardQR() {
  const history = useHistory();
  const classes = useStyles();
  const fromScreen = useLocation().state?.fromScreen;
  const flow = useLocation().state?.flow;
  const journey = useLocation().state?.journey;
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const validateQRCode = (qrCode) => {
    //QR error
    return qrCode && /^[0-9]{10,}$/i.test(qrCode);

  }

  const backToRegisterCard = () => {
    history.push({
      pathname:`/pages/${fromScreen}`,
      state:{flow}
  });
  }

  const goToRegisterCard = (scannedQRData) => {
    history.push({
      pathname: `/pages/${fromScreen}`,
      state: { scannedQRData, flow }
    });
  }

  const handleScan = (qrCodeData) => {
    if (validateQRCode(qrCodeData)) {
      goToRegisterCard(qrCodeData);
    }
  }
  const handleError = () => {
    showAlert(true, 'ERROR', `QR Code is invalid or not found. Please try again!`);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToRegisterCard}/>
              <span className={classes.title}>{journey ? journey : "Register a physical card"}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{}} className={classes.textCenter}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0 m-b-0'} style={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}><img src={qr_example} alt=''/><b style={{ verticalAlign: 'middle' }}>Scan the QR code</b>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className="qr-scan-area">
                <QrReader
                  delay={600}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button onClick={backToRegisterCard}
                      className={classes.buttonRadius} size="lg" block
                      color="transparent">Back</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  )
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ScanPhysicalCardQR)
