import React from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {useHistory} from "react-router-dom";
import Button from "../../components/CustomButtons/Button.js";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import {primaryIconColor} from "../../style.scss"
import {logoutFromApp} from "../../redux-services/actions/actions.js";
import {ReactComponent as EnterPin} from "../../assets/img/icon_enter_password.svg";

const useStyles = makeStyles(themeStyle);

export function ResetPhysicalCardNewPin(props) {
    const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
    const history = useHistory();
    const classes = useStyles();

    const goToWalletDetails = () => {
        history.push({
            pathname: '/pages/wallet-details',
        });
    };
    return (
        <div>
            <div className="wallet-list">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <EnterPin className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                                          stroke={primaryIconColor}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                                   style={{marginBottom: '30px', fontSize: '20px'}}>
                                    <b>Request a new PIN</b>
                                </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                                    To reset your physical card's PIN you will need
                                    <br/>to contact support on {ratifySupportContact}.
                                </p>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button className={classes.buttonRadius} size="lg" block color="primary"
                                        onClick={goToWalletDetails}>Done</Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                            <b>Not your number?</b>
                        </p>
                        <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                            Contact support {ratifySupportContact}
                        </p>
                    </GridItem>
                </GridContainer>
            </div>

        </div>
    );
}

const mapStateToProps = state => ({
    appData: state.applicationData.appData
})
const mapDispatchToProps = dispatch => ({
    logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPhysicalCardNewPin)