import React, { useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { primaryIconColor } from "../../../style.scss";
import { loginToApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as CircleTickOutline } from "../../../assets/img/circle_tick_outline.svg"
import { ReactComponent as Thumbsup } from "../../../assets/img/thumbs_up.svg"
import ThumbsUpDemo from "../../../assets/img/Thumbs-up-selfie-image-in-app.jpg"


const useStyles = makeStyles(themeStyle);

export function PhotoInitial(props) {
  // console.log("Selfie Document Initial props :: ", props);
  const classes = useStyles();
  const history = useHistory();
  const selectedDocument = useLocation().state?.selectedDoc;
  const idDocument = useLocation().state?.idDocument;
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const selfieDocument = useLocation().state?.selfieDocument;
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const userIdentity = useLocation().state?.userIdentity;
  const userOTP = useLocation().state?.userOTP;

  const goToScanPhoto = () => {
    history.push({
      pathname: `/auth/scan-selfie`,
      state: {
        userIdentity,
        userOTP,
        selectedDoc: selectedDocument,
        idDocument,
        selfieDocument,
        idDocumentImageData,
        selfieDocumentImageData
      },
    });
  }

  const goBack = useCallback(() => {
    history.push({
      pathname: '/auth/scan-document',
      state: {
        userIdentity,
        userOTP,
      },
    });
  }, [history, userIdentity, userOTP]);

  React.useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        goBack();
      }
    };
  }, [history, goBack]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h1 className='auth-title'>Upload your selfie.</h1>
                  <p className={classes.regularFontSize + ' m-b-0'}><strong>Please take a selfie with a thumbsup close
                    to your face.</strong></p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize}
                     style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'inherit' }}><strong
                    style={{ margin: '0 10px 0 0' }}><CircleTickOutline fill={primaryIconColor}
                                                                        stroke={primaryIconColor}/></strong> Stand in
                    front of a plain, light-coloured wall.</p>
                  <p className={classes.regularFontSize}
                     style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'inherit' }}><strong
                    style={{ margin: '0 10px 0 0' }}><CircleTickOutline fill={primaryIconColor}
                                                                        stroke={primaryIconColor}/></strong> Give a
                    thumbs up <Thumbsup style={{ margin: '-6px 0 0 0' }} fill={primaryIconColor}
                                        stroke={primaryIconColor}/> close to your face.</p>
                  <p className={classes.regularFontSize}
                     style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'inherit' }}><strong
                    style={{ margin: '0 10px 0 0' }}><CircleTickOutline fill={primaryIconColor}
                                                                        stroke={primaryIconColor}/></strong> Check that
                    your face and thumb are in focus and not blurry.</p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' m-t-0'}><strong>See the example below.</strong></p>
                  <span style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}><img src={ThumbsUpDemo}
                                                                                                     alt=""/></span>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToScanPhoto}>Let's do it</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  photoInitialHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoInitial)
