/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EclipseDocument } from '../models';
import { ExceptionData } from '../models';
/**
 * GDocumentManagementApi - axios parameter creator
 * @export
 */
export const GDocumentManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an existing document
         * @summary Delete a document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdDocumentsDocumentIdDelete: async (tenantId: number, documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdDocumentsDocumentIdDelete.');
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling tenantsTenantIdDocumentsDocumentIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/documents/{documentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a document when the document id is known
         * @summary Get a certain customer or organisations document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdDocumentsDocumentIdGet: async (tenantId: number, documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdDocumentsDocumentIdGet.');
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling tenantsTenantIdDocumentsDocumentIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/documents/{documentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing document
         * @summary Update a document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {EclipseDocument} [body] 
         * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
         * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
         * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdDocumentsDocumentIdPut: async (tenantId: number, documentId: number, body?: EclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdDocumentsDocumentIdPut.');
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling tenantsTenantIdDocumentsDocumentIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/documents/{documentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (performOcr !== undefined) {
                localVarQueryParameter['performOcr'] = performOcr;
            }

            if (returnFullResult !== undefined) {
                localVarQueryParameter['returnFullResult'] = returnFullResult;
            }

            if (validateDocType !== undefined) {
                localVarQueryParameter['validateDocType'] = validateDocType;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GDocumentManagementApi - functional programming interface
 * @export
 */
export const GDocumentManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Delete an existing document
         * @summary Delete a document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdDocumentsDocumentIdDelete(tenantId: number, documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GDocumentManagementApiAxiosParamCreator(configuration).tenantsTenantIdDocumentsDocumentIdDelete(tenantId, documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets a document when the document id is known
         * @summary Get a certain customer or organisations document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdDocumentsDocumentIdGet(tenantId: number, documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseDocument>> {
            const localVarAxiosArgs = await GDocumentManagementApiAxiosParamCreator(configuration).tenantsTenantIdDocumentsDocumentIdGet(tenantId, documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update an existing document
         * @summary Update a document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {EclipseDocument} [body] 
         * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
         * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
         * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdDocumentsDocumentIdPut(tenantId: number, documentId: number, body?: EclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseDocument>> {
            const localVarAxiosArgs = await GDocumentManagementApiAxiosParamCreator(configuration).tenantsTenantIdDocumentsDocumentIdPut(tenantId, documentId, body, performOcr, returnFullResult, validateDocType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GDocumentManagementApi - factory interface
 * @export
 */
export const GDocumentManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Delete an existing document
         * @summary Delete a document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdDocumentsDocumentIdDelete(tenantId: number, documentId: number, options?: any): AxiosPromise<void> {
            return GDocumentManagementApiFp(configuration).tenantsTenantIdDocumentsDocumentIdDelete(tenantId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a document when the document id is known
         * @summary Get a certain customer or organisations document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdDocumentsDocumentIdGet(tenantId: number, documentId: number, options?: any): AxiosPromise<EclipseDocument> {
            return GDocumentManagementApiFp(configuration).tenantsTenantIdDocumentsDocumentIdGet(tenantId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing document
         * @summary Update a document by documentId
         * @param {number} tenantId 
         * @param {number} documentId 
         * @param {EclipseDocument} [body] 
         * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
         * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
         * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdDocumentsDocumentIdPut(tenantId: number, documentId: number, body?: EclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options?: any): AxiosPromise<EclipseDocument> {
            return GDocumentManagementApiFp(configuration).tenantsTenantIdDocumentsDocumentIdPut(tenantId, documentId, body, performOcr, returnFullResult, validateDocType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GDocumentManagementApi - object-oriented interface
 * @export
 * @class GDocumentManagementApi
 * @extends {BaseAPI}
 */
export class GDocumentManagementApi extends BaseAPI {
    /**
     * Delete an existing document
     * @summary Delete a document by documentId
     * @param {number} tenantId 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GDocumentManagementApi
     */
    public tenantsTenantIdDocumentsDocumentIdDelete(tenantId: number, documentId: number, options?: any) {
        return GDocumentManagementApiFp(this.configuration).tenantsTenantIdDocumentsDocumentIdDelete(tenantId, documentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets a document when the document id is known
     * @summary Get a certain customer or organisations document by documentId
     * @param {number} tenantId 
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GDocumentManagementApi
     */
    public tenantsTenantIdDocumentsDocumentIdGet(tenantId: number, documentId: number, options?: any) {
        return GDocumentManagementApiFp(this.configuration).tenantsTenantIdDocumentsDocumentIdGet(tenantId, documentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update an existing document
     * @summary Update a document by documentId
     * @param {number} tenantId 
     * @param {number} documentId 
     * @param {EclipseDocument} [body] 
     * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
     * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
     * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GDocumentManagementApi
     */
    public tenantsTenantIdDocumentsDocumentIdPut(tenantId: number, documentId: number, body?: EclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options?: any) {
        return GDocumentManagementApiFp(this.configuration).tenantsTenantIdDocumentsDocumentIdPut(tenantId, documentId, body, performOcr, returnFullResult, validateDocType, options).then((request) => request(this.axios, this.basePath));
    }
}
