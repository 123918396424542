/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "../../assets/jss/eclipse/components/footerStyle.js";
import Menu from "../Menu/Menu.js"

const useStyles = makeStyles(styles);

export default function Footer(props) {
  // console.log("Footer Props", props);
  const manifestSettings = props?.appData?.template?.manifestSettings
  const classes = useStyles();
  return (
    <footer className={classes.footer} style={{ padding: 0 }}>
      <div className={classes.container}>
        <div className="footer-menu">
          <Menu {...props}/>
        </div>
        {/* <div className="footer-part">
          <div className={classes.block}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              href="https://www.ukheshe.com?ref=mdr-footer"
              target="_blank"
              className={classes.a}
            >
              {manifestSettings?.brand.toUpperCase()} Technology
            </a>
            , made with love for a better web
          </span>
          </div>
        </div> */}
      </div>
    </footer>
  );
}
