/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import MenuArea from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as HomeIcon } from "../../assets/img/home.svg";
import { ReactComponent as TransactIcon } from "../../assets/img/transact.svg";
import { ReactComponent as HistoryIcon } from "../../assets/img/history.svg";
import { ReactComponent as MoreIcon } from "../../assets/img/more.svg";
import { ReactComponent as CardMenuIcon } from "../../assets/img/card_menu.svg";

import { ReactComponent as Settings } from "../../assets/img/settings.svg";

import { Link, useHistory } from 'react-router-dom'

// core components
import styles from "../../assets/jss/eclipse/components/footerStyle.js";
import { EclipseCustomerStatus, EclipseTenantPosition, OrganisationPosition } from "eclipse-react-sdk/services/models";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { AuthRouteWithTemplateId } from "../../default-route";

const useStyles = makeStyles(styles);

export default function Menu(props) {
  const isWalletsDisplay = props?.appData?.template?.pwaAppOptions?.walletsDisplay;
  const isKYCRequired = props?.appData?.template?.pwaAppOptions?.kycRequired;
  const accountStatus = props?.appData?.customer?.status;
  const userRole = props?.appData?.userRole;
  const classes = useStyles();
  const [menuState, setMenuState] = React.useState(null);
  const open = Boolean(menuState);
  const history = useHistory();

  const handleClose = () => {
    setMenuState(null);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setMenuState(event.currentTarget);
  };

  const logout = () => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }

  return (
    accountStatus === EclipseCustomerStatus.ACTIVE ?
      <List className={classes.list}>
        <ListItem className={classes.inlineBlock}>
          <Link className={classes.linkColor + ' ' + classes.block + ' ' + 'headerMenu' + (history.location.pathname === "/pages/dashboard" ? ' active' : '')} to='/pages/dashboard'>
            <HomeIcon />
            <span style={{ display: 'block' }}>Home</span>
          </Link>
        </ListItem>
        {isWalletsDisplay ?
          <ListItem className={classes.inlineBlock}>
            <Link className={classes.linkColor + ' ' + classes.block + ' ' + 'headerMenu' + (history.location.pathname === "/pages/transact" ? ' active' : '')} to='/pages/transact'>
              <TransactIcon />
              <span style={{ display: 'block' }}>Transact</span>
            </Link>
          </ListItem>
          : <ListItem className={classes.inlineBlock}>
            <Link className={classes.linkColor + ' ' + classes.block + ' ' + 'headerMenu' + ((history.location.pathname === "/pages/manage-linked-cards" || history.location.pathname === "/pages/no-card-linked") ? ' active' : '')} to='/pages/manage-linked-cards'>
              <CardMenuIcon />
              <span style={{ display: 'block' }}>Cards</span>
            </Link>
          </ListItem>}
        <ListItem className={classes.inlineBlock}>
          <Link className={classes.linkColor + ' ' + classes.block + ' ' + 'headerMenu' + (history.location.pathname === "/pages/transaction-history" ? ' active' : '')} to='/pages/transaction-history'>
            <HistoryIcon />
            <span style={{ display: 'block' }}>History</span>
          </Link>
        </ListItem>
        {isKYCRequired ?
          <ListItem className={classes.inlineBlock}>
            <Link className={classes.linkColor + ' ' + classes.block + ' ' + 'headerMenu'} onClick={handleClick}  to='#'>
              <MoreIcon />
              <span style={{ display: 'block' }}>More</span>
            </Link>
            <MenuArea
              anchorEl={menuState}
              open={open}
              getContentAnchorEl={null}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem>
                <Link className={classes.linkColor + ' ' + 'headerMenu'} to='/pages/user'>
                  My Profile
                </Link>
              </MenuItem>
              { userRole === EclipseTenantPosition.LEVEL10 || userRole === OrganisationPosition.HEAD ?
                <MenuItem>
                  <Link className={classes.linkColor + ' ' + 'headerMenu'} to='/pages/assisted-signup'>
                    Assisted Sign Up
                  </Link>
                </MenuItem>
                : null
              }
              {userRole === EclipseTenantPosition.LEVEL10 || userRole === OrganisationPosition.HEAD ?
                <MenuItem>
                  <Link className={classes.linkColor + ' ' + 'headerMenu'} to='/pages/assisted-card-register'>
                    Assisted Card registration
                  </Link>
                </MenuItem>
                : null
              }
              <MenuItem className={classes.linkColor} onClick={() => {
                logout()
              }}>
                Logout
              </MenuItem>
            </MenuArea>
          </ListItem>
          : <ListItem className={classes.inlineBlock}>
            <Link className={classes.linkColor + ' ' + classes.block + ' ' + 'headerMenu' + (history.location.pathname === "/pages/user" ? ' active' : '')} to='/pages/user'>
              <Settings />
              <span style={{ display: 'block' }}>Settings</span>
            </Link>
          </ListItem>
        }
      </List>
    : ''
  )
}
