import React, { useCallback } from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions";
import { PhysicalCardForm } from "../../components/PhysicalCard/PhysicalCardRegister";

const useStyles = makeStyles(themeStyle);

export function PhysicalCardRegister(props) {
  const history = useHistory();
  const classes = useStyles();
  const scannedQRData = useLocation().state?.scannedQRData || "";
  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.physicalCardRegisterHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);

  const goToScanCardQr = () => {
    history.push({
      pathname: '/pages/scan-card-qr',
      state: { fromScreen: 'register-my-physical-card' }
    });
  }

  const goToSuccessCreateCard = (cardDetails, walletDetailData) => {
    if (cardDetails) {
      history.push({
        pathname: '/pages/new-physical-card-pin',
        state: { fromScreen: 'register-my-physical-card', cardDetails, walletDetailData, successScreen: "success-card-registered" }
      });
    }
  };

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', marginTop: 30 }}>
          <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
          <span className={classes.title}>Register a physical card</span>
        </GridItem>
      </GridContainer>
      <PhysicalCardForm
        {...props}
        scannedQRData={scannedQRData}
        goToScanCardQr={goToScanCardQr}
        onComplete={goToSuccessCreateCard}
        flow={'register-my-physical-card'}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
});

const mapDispatchToProps = dispatch => ({
  physicalCardRegisterHandler: data => dispatch(loginToApp(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicalCardRegister)