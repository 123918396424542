import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { PersonalInformation } from "../../components/PersonalInformation/PersonalInformation";
import GridItem from "../../components/Grid/GridItem";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GridContainer from "../../components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle";
import { loginToApp } from "../../redux-services/actions/actions";

const useStyles = makeStyles(themeStyle);

export function SaveUserInformation(props) {
  const history = useHistory();
  const classes = useStyles();
  const flow= useLocation()?.state?.flow;
  const backToCreateVC = useCallback(() => {
    let pathname= '/pages/dashboard';
    if(flow==="assisted-signup"){
      pathname= '/pages/assisted-signup-account-ready';
    }
    history.replace({ pathname });

  }, [history,flow])

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        backToCreateVC();
      }
    };
  }, [history, backToCreateVC])

  const handleSavePersonalDetails = (result, props) => {
    props.personalInformationHandler({ ...props.appData });
    if (result) {
      history.push({ pathname:'/pages/assisted-physical-card' ,state:{flow}});
    }
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
          <ArrowBackIosIcon className={classes.linkColor} onClick={backToCreateVC} />
          <span className={classes.title}>{flow==="assisted-signup"?"Add a physical card":"Assisted Card Registration"}</span>
        </GridItem>
      </GridContainer>
      <PersonalInformation {...props} onComplete={handleSavePersonalDetails} flow={flow}/>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  personalInformationHandler: data => dispatch(loginToApp(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(SaveUserInformation)