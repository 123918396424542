/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Avp } from '../models';
import { EclipseBulkTransfer } from '../models';
import { EclipseNotification } from '../models';
import { EclipseTenant } from '../models';
import { EclipseTenantAdminUser } from '../models';
import { EclipseWallet } from '../models';
import { ExceptionData } from '../models';
import { NewEclipseOrganisationWallet } from '../models';
import { NewEclipseTenant } from '../models';
import { NewEclipseTenantAdminUser } from '../models';
import { TenantQRCodeProductionData } from '../models';
import { UpdatedEclipseTenant } from '../models';
import { UpdatedEclipseTenantAdminUser } from '../models';
/**
 * BTenantConfigurationOnboardingApi - axios parameter creator
 * @export
 */
export const BTenantConfigurationOnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of tenants
         * @param {string} [institution] 
         * @param {number} [limit] 
         * @param {string} [name] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsGet: async (institution?: string, limit?: number, name?: string, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (institution !== undefined) {
                localVarQueryParameter['institution'] = institution;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * A tenant is a client of the Eclipse platform. Only available to administrators
         * @summary Register a new tenant on the Eclipse Platform
         * @param {NewEclipseTenant} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsPost: async (body?: NewEclipseTenant, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the admin user
         * @summary Delete an admin user
         * @param {number} tenantId 
         * @param {number} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersAdminUserIdDelete: async (tenantId: number, adminUserId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdAdminUsersAdminUserIdDelete.');
            }
            // verify required parameter 'adminUserId' is not null or undefined
            if (adminUserId === null || adminUserId === undefined) {
                throw new RequiredError('adminUserId','Required parameter adminUserId was null or undefined when calling tenantsTenantIdAdminUsersAdminUserIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/admin-users/{adminUserId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"adminUserId"}}`, encodeURIComponent(String(adminUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the admin user
         * @summary Update an admin user
         * @param {number} tenantId 
         * @param {number} adminUserId 
         * @param {UpdatedEclipseTenantAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersAdminUserIdPut: async (tenantId: number, adminUserId: number, body?: UpdatedEclipseTenantAdminUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdAdminUsersAdminUserIdPut.');
            }
            // verify required parameter 'adminUserId' is not null or undefined
            if (adminUserId === null || adminUserId === undefined) {
                throw new RequiredError('adminUserId','Required parameter adminUserId was null or undefined when calling tenantsTenantIdAdminUsersAdminUserIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/admin-users/{adminUserId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"adminUserId"}}`, encodeURIComponent(String(adminUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the admin users in the tenant
         * @summary Get a list of admin users in the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersGet: async (tenantId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdAdminUsersGet.');
            }
            const localVarPath = `/tenants/{tenantId}/admin-users`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
         * @summary Create a user which can administer the tenant
         * @param {number} tenantId 
         * @param {NewEclipseTenantAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersPost: async (tenantId: number, body?: NewEclipseTenantAdminUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdAdminUsersPost.');
            }
            const localVarPath = `/tenants/{tenantId}/admin-users`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Do a bulk config updates for tenant. Will update the config if the config already exists and the value has changed. If the config is not present it will be added as a new config
         * @param {number} tenantId 
         * @param {Array&lt;Avp&gt;} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsBulkUpdatesPost: async (tenantId: number, body?: Array<Avp>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdConfigItemsBulkUpdatesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/config-items/bulk-updates`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Get the config for the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsGet: async (tenantId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdConfigItemsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/config-items`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the configuration elements for the tenant
         * @summary Delete the config for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsItemDelete: async (tenantId: number, item: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdConfigItemsItemDelete.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling tenantsTenantIdConfigItemsItemDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/config-items/{item}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Get the config for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsItemGet: async (tenantId: number, item: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdConfigItemsItemGet.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling tenantsTenantIdConfigItemsItemGet.');
            }
            const localVarPath = `/tenants/{tenantId}/config-items/{item}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Update the config entry for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {Avp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsItemPut: async (tenantId: number, item: string, body?: Avp, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdConfigItemsItemPut.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling tenantsTenantIdConfigItemsItemPut.');
            }
            const localVarPath = `/tenants/{tenantId}/config-items/{item}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a  configuration element for the tenant
         * @summary Add a config item for the tenant
         * @param {number} tenantId 
         * @param {Avp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsPost: async (tenantId: number, body?: Avp, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdConfigItemsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/config-items`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the tenant
         * @summary Get a tenant by tenant id
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdGet: async (tenantId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns JSON for generic requirements where Eclipse needs to do something and return a token or similar for a specific use case
         * @summary Get some generic data in a weakly typed manner for some open ended use-case
         * @param {number} tenantId 
         * @param {string} integrationIdentifier 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdIntegrationsIntegrationIdentifierPost: async (tenantId: number, integrationIdentifier: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdIntegrationsIntegrationIdentifierPost.');
            }
            // verify required parameter 'integrationIdentifier' is not null or undefined
            if (integrationIdentifier === null || integrationIdentifier === undefined) {
                throw new RequiredError('integrationIdentifier','Required parameter integrationIdentifier was null or undefined when calling tenantsTenantIdIntegrationsIntegrationIdentifierPost.');
            }
            const localVarPath = `/tenants/{tenantId}/integrations/{integrationIdentifier}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"integrationIdentifier"}}`, encodeURIComponent(String(integrationIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to send notification.
         * @summary Send notification to the customer by SMS, Email etc. In order to use this API, we must first need to set the Mustache template in the property.
         * @param {number} tenantId 
         * @param {EclipseNotification} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdNotificationsPost: async (tenantId: number, body?: EclipseNotification, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdNotificationsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/notifications`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates and then returns the tenant
         * @summary Update the details of an existing tenant
         * @param {number} tenantId 
         * @param {UpdatedEclipseTenant} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPut: async (tenantId: number, body?: UpdatedEclipseTenant, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates one or more QRCodes and holds them for later allocation to a wallet. The creation happens in the background as its a long running process. The API returns indicating the request has been kicked off.
         * @summary Generate and hold one or more QRCodes
         * @param {number} tenantId 
         * @param {TenantQRCodeProductionData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdQrCodesPost: async (tenantId: number, body?: TenantQRCodeProductionData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdQrCodesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/qr-codes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Runs a pre-configured report for a tenant and returns the results. The format can be json, csv, xls or xml.
         * @summary Run a tenant report and return the result
         * @param {number} tenantId 
         * @param {string} reportId 
         * @param {string} [d1] 
         * @param {string} [d2] 
         * @param {string} [d3] 
         * @param {string} [d4] 
         * @param {string} [d5] 
         * @param {string} [d6] 
         * @param {string} [d7] 
         * @param {string} [d8] 
         * @param {string} [format] 
         * @param {number} [n1] 
         * @param {number} [n2] 
         * @param {number} [n3] 
         * @param {number} [n4] 
         * @param {number} [n5] 
         * @param {number} [n6] 
         * @param {number} [n7] 
         * @param {number} [n8] 
         * @param {string} [s1] 
         * @param {string} [s2] 
         * @param {string} [s3] 
         * @param {string} [s4] 
         * @param {string} [s5] 
         * @param {string} [s6] 
         * @param {string} [s7] 
         * @param {string} [s8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdReportsReportIdGet: async (tenantId: number, reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, format?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdReportsReportIdGet.');
            }
            // verify required parameter 'reportId' is not null or undefined
            if (reportId === null || reportId === undefined) {
                throw new RequiredError('reportId','Required parameter reportId was null or undefined when calling tenantsTenantIdReportsReportIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/reports/{reportId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (d1 !== undefined) {
                localVarQueryParameter['d1'] = d1;
            }

            if (d2 !== undefined) {
                localVarQueryParameter['d2'] = d2;
            }

            if (d3 !== undefined) {
                localVarQueryParameter['d3'] = d3;
            }

            if (d4 !== undefined) {
                localVarQueryParameter['d4'] = d4;
            }

            if (d5 !== undefined) {
                localVarQueryParameter['d5'] = d5;
            }

            if (d6 !== undefined) {
                localVarQueryParameter['d6'] = d6;
            }

            if (d7 !== undefined) {
                localVarQueryParameter['d7'] = d7;
            }

            if (d8 !== undefined) {
                localVarQueryParameter['d8'] = d8;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (n1 !== undefined) {
                localVarQueryParameter['n1'] = n1;
            }

            if (n2 !== undefined) {
                localVarQueryParameter['n2'] = n2;
            }

            if (n3 !== undefined) {
                localVarQueryParameter['n3'] = n3;
            }

            if (n4 !== undefined) {
                localVarQueryParameter['n4'] = n4;
            }

            if (n5 !== undefined) {
                localVarQueryParameter['n5'] = n5;
            }

            if (n6 !== undefined) {
                localVarQueryParameter['n6'] = n6;
            }

            if (n7 !== undefined) {
                localVarQueryParameter['n7'] = n7;
            }

            if (n8 !== undefined) {
                localVarQueryParameter['n8'] = n8;
            }

            if (s1 !== undefined) {
                localVarQueryParameter['s1'] = s1;
            }

            if (s2 !== undefined) {
                localVarQueryParameter['s2'] = s2;
            }

            if (s3 !== undefined) {
                localVarQueryParameter['s3'] = s3;
            }

            if (s4 !== undefined) {
                localVarQueryParameter['s4'] = s4;
            }

            if (s5 !== undefined) {
                localVarQueryParameter['s5'] = s5;
            }

            if (s6 !== undefined) {
                localVarQueryParameter['s6'] = s6;
            }

            if (s7 !== undefined) {
                localVarQueryParameter['s7'] = s7;
            }

            if (s8 !== undefined) {
                localVarQueryParameter['s8'] = s8;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all the wallets directly linked to the tenant These are typically for system purposes or suspense wallets.
         * @summary Get list of wallets directly attached to the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdTenantWalletsGet: async (tenantId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdTenantWalletsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/tenant-wallets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a wallet linked to the tenant directly for system or suspense purposes. These wallets cannot have an associated physical card hence primaryPhysicalCardIdentifier must not be provided
         * @summary Create a wallet directly attached to the tenant
         * @param {number} tenantId 
         * @param {NewEclipseOrganisationWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdTenantWalletsPost: async (tenantId: number, body?: NewEclipseOrganisationWallet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdTenantWalletsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/tenant-wallets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list.
         * @summary Do a set of inter account transfers only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost: async (tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost.');
            }
            const localVarPath = `/tenants/{tenantId}/tenant-wallets/{walletId}/bulk-transfers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (atomic !== undefined) {
                localVarQueryParameter['atomic'] = atomic;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BTenantConfigurationOnboardingApi - functional programming interface
 * @export
 */
export const BTenantConfigurationOnboardingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of tenants
         * @param {string} [institution] 
         * @param {number} [limit] 
         * @param {string} [name] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsGet(institution?: string, limit?: number, name?: string, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseTenant>>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsGet(institution, limit, name, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * A tenant is a client of the Eclipse platform. Only available to administrators
         * @summary Register a new tenant on the Eclipse Platform
         * @param {NewEclipseTenant} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsPost(body?: NewEclipseTenant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseTenant>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deletes the admin user
         * @summary Delete an admin user
         * @param {number} tenantId 
         * @param {number} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdAdminUsersAdminUserIdDelete(tenantId: number, adminUserId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdAdminUsersAdminUserIdDelete(tenantId, adminUserId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates the admin user
         * @summary Update an admin user
         * @param {number} tenantId 
         * @param {number} adminUserId 
         * @param {UpdatedEclipseTenantAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdAdminUsersAdminUserIdPut(tenantId: number, adminUserId: number, body?: UpdatedEclipseTenantAdminUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseTenantAdminUser>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdAdminUsersAdminUserIdPut(tenantId, adminUserId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all the admin users in the tenant
         * @summary Get a list of admin users in the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdAdminUsersGet(tenantId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseTenantAdminUser>>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdAdminUsersGet(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
         * @summary Create a user which can administer the tenant
         * @param {number} tenantId 
         * @param {NewEclipseTenantAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdAdminUsersPost(tenantId: number, body?: NewEclipseTenantAdminUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseTenantAdminUser>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdAdminUsersPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Do a bulk config updates for tenant. Will update the config if the config already exists and the value has changed. If the config is not present it will be added as a new config
         * @param {number} tenantId 
         * @param {Array&lt;Avp&gt;} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdConfigItemsBulkUpdatesPost(tenantId: number, body?: Array<Avp>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Avp>>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdConfigItemsBulkUpdatesPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Get the config for the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdConfigItemsGet(tenantId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Avp>>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdConfigItemsGet(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deletes the configuration elements for the tenant
         * @summary Delete the config for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdConfigItemsItemDelete(tenantId: number, item: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdConfigItemsItemDelete(tenantId, item, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Get the config for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdConfigItemsItemGet(tenantId: number, item: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Avp>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdConfigItemsItemGet(tenantId, item, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Update the config entry for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {Avp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdConfigItemsItemPut(tenantId: number, item: string, body?: Avp, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Avp>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdConfigItemsItemPut(tenantId, item, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Adds a  configuration element for the tenant
         * @summary Add a config item for the tenant
         * @param {number} tenantId 
         * @param {Avp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdConfigItemsPost(tenantId: number, body?: Avp, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Avp>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdConfigItemsPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the tenant
         * @summary Get a tenant by tenant id
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdGet(tenantId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseTenant>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdGet(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns JSON for generic requirements where Eclipse needs to do something and return a token or similar for a specific use case
         * @summary Get some generic data in a weakly typed manner for some open ended use-case
         * @param {number} tenantId 
         * @param {string} integrationIdentifier 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdIntegrationsIntegrationIdentifierPost(tenantId: number, integrationIdentifier: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdIntegrationsIntegrationIdentifierPost(tenantId, integrationIdentifier, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Use to send notification.
         * @summary Send notification to the customer by SMS, Email etc. In order to use this API, we must first need to set the Mustache template in the property.
         * @param {number} tenantId 
         * @param {EclipseNotification} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdNotificationsPost(tenantId: number, body?: EclipseNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdNotificationsPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates and then returns the tenant
         * @summary Update the details of an existing tenant
         * @param {number} tenantId 
         * @param {UpdatedEclipseTenant} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPut(tenantId: number, body?: UpdatedEclipseTenant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseTenant>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdPut(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates one or more QRCodes and holds them for later allocation to a wallet. The creation happens in the background as its a long running process. The API returns indicating the request has been kicked off.
         * @summary Generate and hold one or more QRCodes
         * @param {number} tenantId 
         * @param {TenantQRCodeProductionData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdQrCodesPost(tenantId: number, body?: TenantQRCodeProductionData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdQrCodesPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Runs a pre-configured report for a tenant and returns the results. The format can be json, csv, xls or xml.
         * @summary Run a tenant report and return the result
         * @param {number} tenantId 
         * @param {string} reportId 
         * @param {string} [d1] 
         * @param {string} [d2] 
         * @param {string} [d3] 
         * @param {string} [d4] 
         * @param {string} [d5] 
         * @param {string} [d6] 
         * @param {string} [d7] 
         * @param {string} [d8] 
         * @param {string} [format] 
         * @param {number} [n1] 
         * @param {number} [n2] 
         * @param {number} [n3] 
         * @param {number} [n4] 
         * @param {number} [n5] 
         * @param {number} [n6] 
         * @param {number} [n7] 
         * @param {number} [n8] 
         * @param {string} [s1] 
         * @param {string} [s2] 
         * @param {string} [s3] 
         * @param {string} [s4] 
         * @param {string} [s5] 
         * @param {string} [s6] 
         * @param {string} [s7] 
         * @param {string} [s8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdReportsReportIdGet(tenantId: number, reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, format?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdReportsReportIdGet(tenantId, reportId, d1, d2, d3, d4, d5, d6, d7, d8, format, n1, n2, n3, n4, n5, n6, n7, n8, s1, s2, s3, s4, s5, s6, s7, s8, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Lists all the wallets directly linked to the tenant These are typically for system purposes or suspense wallets.
         * @summary Get list of wallets directly attached to the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdTenantWalletsGet(tenantId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseWallet>>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdTenantWalletsGet(tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a wallet linked to the tenant directly for system or suspense purposes. These wallets cannot have an associated physical card hence primaryPhysicalCardIdentifier must not be provided
         * @summary Create a wallet directly attached to the tenant
         * @param {number} tenantId 
         * @param {NewEclipseOrganisationWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdTenantWalletsPost(tenantId: number, body?: NewEclipseOrganisationWallet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWallet>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdTenantWalletsPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list.
         * @summary Do a set of inter account transfers only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost(tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BTenantConfigurationOnboardingApiAxiosParamCreator(configuration).tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost(tenantId, walletId, body, atomic, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BTenantConfigurationOnboardingApi - factory interface
 * @export
 */
export const BTenantConfigurationOnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get list of tenants
         * @param {string} [institution] 
         * @param {number} [limit] 
         * @param {string} [name] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsGet(institution?: string, limit?: number, name?: string, offset?: number, options?: any): AxiosPromise<Array<EclipseTenant>> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsGet(institution, limit, name, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * A tenant is a client of the Eclipse platform. Only available to administrators
         * @summary Register a new tenant on the Eclipse Platform
         * @param {NewEclipseTenant} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsPost(body?: NewEclipseTenant, options?: any): AxiosPromise<EclipseTenant> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the admin user
         * @summary Delete an admin user
         * @param {number} tenantId 
         * @param {number} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersAdminUserIdDelete(tenantId: number, adminUserId: number, options?: any): AxiosPromise<void> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdAdminUsersAdminUserIdDelete(tenantId, adminUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the admin user
         * @summary Update an admin user
         * @param {number} tenantId 
         * @param {number} adminUserId 
         * @param {UpdatedEclipseTenantAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersAdminUserIdPut(tenantId: number, adminUserId: number, body?: UpdatedEclipseTenantAdminUser, options?: any): AxiosPromise<EclipseTenantAdminUser> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdAdminUsersAdminUserIdPut(tenantId, adminUserId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the admin users in the tenant
         * @summary Get a list of admin users in the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersGet(tenantId: number, options?: any): AxiosPromise<Array<EclipseTenantAdminUser>> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdAdminUsersGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
         * @summary Create a user which can administer the tenant
         * @param {number} tenantId 
         * @param {NewEclipseTenantAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdAdminUsersPost(tenantId: number, body?: NewEclipseTenantAdminUser, options?: any): AxiosPromise<EclipseTenantAdminUser> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdAdminUsersPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Do a bulk config updates for tenant. Will update the config if the config already exists and the value has changed. If the config is not present it will be added as a new config
         * @param {number} tenantId 
         * @param {Array&lt;Avp&gt;} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsBulkUpdatesPost(tenantId: number, body?: Array<Avp>, options?: any): AxiosPromise<Array<Avp>> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdConfigItemsBulkUpdatesPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Get the config for the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsGet(tenantId: number, options?: any): AxiosPromise<Array<Avp>> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdConfigItemsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the configuration elements for the tenant
         * @summary Delete the config for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsItemDelete(tenantId: number, item: string, options?: any): AxiosPromise<void> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdConfigItemsItemDelete(tenantId, item, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Get the config for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsItemGet(tenantId: number, item: string, options?: any): AxiosPromise<Avp> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdConfigItemsItemGet(tenantId, item, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the configuration elements for the tenant
         * @summary Update the config entry for the tenant
         * @param {number} tenantId 
         * @param {string} item 
         * @param {Avp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsItemPut(tenantId: number, item: string, body?: Avp, options?: any): AxiosPromise<Avp> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdConfigItemsItemPut(tenantId, item, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a  configuration element for the tenant
         * @summary Add a config item for the tenant
         * @param {number} tenantId 
         * @param {Avp} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdConfigItemsPost(tenantId: number, body?: Avp, options?: any): AxiosPromise<Avp> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdConfigItemsPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the tenant
         * @summary Get a tenant by tenant id
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdGet(tenantId: number, options?: any): AxiosPromise<EclipseTenant> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns JSON for generic requirements where Eclipse needs to do something and return a token or similar for a specific use case
         * @summary Get some generic data in a weakly typed manner for some open ended use-case
         * @param {number} tenantId 
         * @param {string} integrationIdentifier 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdIntegrationsIntegrationIdentifierPost(tenantId: number, integrationIdentifier: string, body?: string, options?: any): AxiosPromise<string> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdIntegrationsIntegrationIdentifierPost(tenantId, integrationIdentifier, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to send notification.
         * @summary Send notification to the customer by SMS, Email etc. In order to use this API, we must first need to set the Mustache template in the property.
         * @param {number} tenantId 
         * @param {EclipseNotification} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdNotificationsPost(tenantId: number, body?: EclipseNotification, options?: any): AxiosPromise<void> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdNotificationsPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates and then returns the tenant
         * @summary Update the details of an existing tenant
         * @param {number} tenantId 
         * @param {UpdatedEclipseTenant} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPut(tenantId: number, body?: UpdatedEclipseTenant, options?: any): AxiosPromise<EclipseTenant> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdPut(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates one or more QRCodes and holds them for later allocation to a wallet. The creation happens in the background as its a long running process. The API returns indicating the request has been kicked off.
         * @summary Generate and hold one or more QRCodes
         * @param {number} tenantId 
         * @param {TenantQRCodeProductionData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdQrCodesPost(tenantId: number, body?: TenantQRCodeProductionData, options?: any): AxiosPromise<void> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdQrCodesPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Runs a pre-configured report for a tenant and returns the results. The format can be json, csv, xls or xml.
         * @summary Run a tenant report and return the result
         * @param {number} tenantId 
         * @param {string} reportId 
         * @param {string} [d1] 
         * @param {string} [d2] 
         * @param {string} [d3] 
         * @param {string} [d4] 
         * @param {string} [d5] 
         * @param {string} [d6] 
         * @param {string} [d7] 
         * @param {string} [d8] 
         * @param {string} [format] 
         * @param {number} [n1] 
         * @param {number} [n2] 
         * @param {number} [n3] 
         * @param {number} [n4] 
         * @param {number} [n5] 
         * @param {number} [n6] 
         * @param {number} [n7] 
         * @param {number} [n8] 
         * @param {string} [s1] 
         * @param {string} [s2] 
         * @param {string} [s3] 
         * @param {string} [s4] 
         * @param {string} [s5] 
         * @param {string} [s6] 
         * @param {string} [s7] 
         * @param {string} [s8] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdReportsReportIdGet(tenantId: number, reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, format?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, options?: any): AxiosPromise<void> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdReportsReportIdGet(tenantId, reportId, d1, d2, d3, d4, d5, d6, d7, d8, format, n1, n2, n3, n4, n5, n6, n7, n8, s1, s2, s3, s4, s5, s6, s7, s8, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all the wallets directly linked to the tenant These are typically for system purposes or suspense wallets.
         * @summary Get list of wallets directly attached to the tenant
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdTenantWalletsGet(tenantId: number, options?: any): AxiosPromise<Array<EclipseWallet>> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdTenantWalletsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a wallet linked to the tenant directly for system or suspense purposes. These wallets cannot have an associated physical card hence primaryPhysicalCardIdentifier must not be provided
         * @summary Create a wallet directly attached to the tenant
         * @param {number} tenantId 
         * @param {NewEclipseOrganisationWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdTenantWalletsPost(tenantId: number, body?: NewEclipseOrganisationWallet, options?: any): AxiosPromise<EclipseWallet> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdTenantWalletsPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list.
         * @summary Do a set of inter account transfers only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost(tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options?: any): AxiosPromise<void> {
            return BTenantConfigurationOnboardingApiFp(configuration).tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost(tenantId, walletId, body, atomic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BTenantConfigurationOnboardingApi - object-oriented interface
 * @export
 * @class BTenantConfigurationOnboardingApi
 * @extends {BaseAPI}
 */
export class BTenantConfigurationOnboardingApi extends BaseAPI {
    /**
     * 
     * @summary Get list of tenants
     * @param {string} [institution] 
     * @param {number} [limit] 
     * @param {string} [name] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsGet(institution?: string, limit?: number, name?: string, offset?: number, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsGet(institution, limit, name, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * A tenant is a client of the Eclipse platform. Only available to administrators
     * @summary Register a new tenant on the Eclipse Platform
     * @param {NewEclipseTenant} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsPost(body?: NewEclipseTenant, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Deletes the admin user
     * @summary Delete an admin user
     * @param {number} tenantId 
     * @param {number} adminUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdAdminUsersAdminUserIdDelete(tenantId: number, adminUserId: number, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdAdminUsersAdminUserIdDelete(tenantId, adminUserId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates the admin user
     * @summary Update an admin user
     * @param {number} tenantId 
     * @param {number} adminUserId 
     * @param {UpdatedEclipseTenantAdminUser} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdAdminUsersAdminUserIdPut(tenantId: number, adminUserId: number, body?: UpdatedEclipseTenantAdminUser, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdAdminUsersAdminUserIdPut(tenantId, adminUserId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all the admin users in the tenant
     * @summary Get a list of admin users in the tenant
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdAdminUsersGet(tenantId: number, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdAdminUsersGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
     * @summary Create a user which can administer the tenant
     * @param {number} tenantId 
     * @param {NewEclipseTenantAdminUser} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdAdminUsersPost(tenantId: number, body?: NewEclipseTenantAdminUser, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdAdminUsersPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Do a bulk config updates for tenant. Will update the config if the config already exists and the value has changed. If the config is not present it will be added as a new config
     * @param {number} tenantId 
     * @param {Array&lt;Avp&gt;} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdConfigItemsBulkUpdatesPost(tenantId: number, body?: Array<Avp>, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdConfigItemsBulkUpdatesPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the configuration elements for the tenant
     * @summary Get the config for the tenant
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdConfigItemsGet(tenantId: number, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdConfigItemsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Deletes the configuration elements for the tenant
     * @summary Delete the config for the tenant
     * @param {number} tenantId 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdConfigItemsItemDelete(tenantId: number, item: string, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdConfigItemsItemDelete(tenantId, item, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the configuration elements for the tenant
     * @summary Get the config for the tenant
     * @param {number} tenantId 
     * @param {string} item 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdConfigItemsItemGet(tenantId: number, item: string, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdConfigItemsItemGet(tenantId, item, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the configuration elements for the tenant
     * @summary Update the config entry for the tenant
     * @param {number} tenantId 
     * @param {string} item 
     * @param {Avp} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdConfigItemsItemPut(tenantId: number, item: string, body?: Avp, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdConfigItemsItemPut(tenantId, item, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Adds a  configuration element for the tenant
     * @summary Add a config item for the tenant
     * @param {number} tenantId 
     * @param {Avp} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdConfigItemsPost(tenantId: number, body?: Avp, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdConfigItemsPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the tenant
     * @summary Get a tenant by tenant id
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdGet(tenantId: number, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns JSON for generic requirements where Eclipse needs to do something and return a token or similar for a specific use case
     * @summary Get some generic data in a weakly typed manner for some open ended use-case
     * @param {number} tenantId 
     * @param {string} integrationIdentifier 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdIntegrationsIntegrationIdentifierPost(tenantId: number, integrationIdentifier: string, body?: string, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdIntegrationsIntegrationIdentifierPost(tenantId, integrationIdentifier, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use to send notification.
     * @summary Send notification to the customer by SMS, Email etc. In order to use this API, we must first need to set the Mustache template in the property.
     * @param {number} tenantId 
     * @param {EclipseNotification} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdNotificationsPost(tenantId: number, body?: EclipseNotification, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdNotificationsPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates and then returns the tenant
     * @summary Update the details of an existing tenant
     * @param {number} tenantId 
     * @param {UpdatedEclipseTenant} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdPut(tenantId: number, body?: UpdatedEclipseTenant, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdPut(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates one or more QRCodes and holds them for later allocation to a wallet. The creation happens in the background as its a long running process. The API returns indicating the request has been kicked off.
     * @summary Generate and hold one or more QRCodes
     * @param {number} tenantId 
     * @param {TenantQRCodeProductionData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdQrCodesPost(tenantId: number, body?: TenantQRCodeProductionData, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdQrCodesPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Runs a pre-configured report for a tenant and returns the results. The format can be json, csv, xls or xml.
     * @summary Run a tenant report and return the result
     * @param {number} tenantId 
     * @param {string} reportId 
     * @param {string} [d1] 
     * @param {string} [d2] 
     * @param {string} [d3] 
     * @param {string} [d4] 
     * @param {string} [d5] 
     * @param {string} [d6] 
     * @param {string} [d7] 
     * @param {string} [d8] 
     * @param {string} [format] 
     * @param {number} [n1] 
     * @param {number} [n2] 
     * @param {number} [n3] 
     * @param {number} [n4] 
     * @param {number} [n5] 
     * @param {number} [n6] 
     * @param {number} [n7] 
     * @param {number} [n8] 
     * @param {string} [s1] 
     * @param {string} [s2] 
     * @param {string} [s3] 
     * @param {string} [s4] 
     * @param {string} [s5] 
     * @param {string} [s6] 
     * @param {string} [s7] 
     * @param {string} [s8] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdReportsReportIdGet(tenantId: number, reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, format?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdReportsReportIdGet(tenantId, reportId, d1, d2, d3, d4, d5, d6, d7, d8, format, n1, n2, n3, n4, n5, n6, n7, n8, s1, s2, s3, s4, s5, s6, s7, s8, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Lists all the wallets directly linked to the tenant These are typically for system purposes or suspense wallets.
     * @summary Get list of wallets directly attached to the tenant
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdTenantWalletsGet(tenantId: number, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdTenantWalletsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a wallet linked to the tenant directly for system or suspense purposes. These wallets cannot have an associated physical card hence primaryPhysicalCardIdentifier must not be provided
     * @summary Create a wallet directly attached to the tenant
     * @param {number} tenantId 
     * @param {NewEclipseOrganisationWallet} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdTenantWalletsPost(tenantId: number, body?: NewEclipseOrganisationWallet, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdTenantWalletsPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list.
     * @summary Do a set of inter account transfers only applicable for close loop wallets
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
     * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BTenantConfigurationOnboardingApi
     */
    public tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost(tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options?: any) {
        return BTenantConfigurationOnboardingApiFp(this.configuration).tenantsTenantIdTenantWalletsWalletIdBulkTransfersPost(tenantId, walletId, body, atomic, options).then((request) => request(this.axios, this.basePath));
    }
}
