/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EclipseAttachment } from '../models';
import { EclipseAuditEvent } from '../models';
import { EclipseBulkTransfer } from '../models';
import { EclipseBulkTransferFromAnySource } from '../models';
import { EclipseCard } from '../models';
import { EclipsePublicWalletInfo } from '../models';
import { EclipseWallet } from '../models';
import { EclipseWalletQRCode } from '../models';
import { ExceptionData } from '../models';
import { NewEclipseAttachment } from '../models';
import { NewEclipseWalletCard } from '../models';
import { NewEclipseWalletQRCode } from '../models';
import { PaymentResult } from '../models';
import { Transaction } from '../models';
import { UpdatedEclipseWallet } from '../models';
/**
 * HWalletsApi - axios parameter creator
 * @export
 */
export const HWalletsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId and toWalletId should be populated.
         * @summary Do a set of inter account transfers only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {Array&lt;EclipseBulkTransferFromAnySource&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsBulkTransfersPost: async (tenantId: number, body?: Array<EclipseBulkTransferFromAnySource>, atomic?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsBulkTransfersPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/bulk-transfers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (atomic !== undefined) {
                localVarQueryParameter['atomic'] = atomic;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Search for wallets within a tenant
         * @summary Search for a wallet within tenant
         * @param {number} tenantId 
         * @param {string} [accountNumber] Account number
         * @param {string} [lastName] Customers last (family) name of max length 50
         * @param {string} [nationalIdentityNumber] Max length 20
         * @param {string} [passportNumber] Max length 20
         * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
         * @param {string} [walletFriendlyId] Will be length 8 if provided
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsGet: async (tenantId: number, accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, walletFriendlyId?: string, walletId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (nationalIdentityNumber !== undefined) {
                localVarQueryParameter['nationalIdentityNumber'] = nationalIdentityNumber;
            }

            if (passportNumber !== undefined) {
                localVarQueryParameter['passportNumber'] = passportNumber;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (walletFriendlyId !== undefined) {
                localVarQueryParameter['walletFriendlyId'] = walletFriendlyId;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will return all attachments linked to the wallet
         * @summary Get a list of the wallets attachments
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdAttachmentsGet: async (tenantId: number, walletId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdAttachmentsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdAttachmentsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/attachments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This can attached extra information to a wallet in form of  sticky notes, docs or images. One can add multiple attachments to the wallet. Supported media types are application/pdf,image/png or image/jpeg,application/json,text/plain.
         * @summary Add a new attachment on the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseAttachment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdAttachmentsPost: async (tenantId: number, walletId: number, body?: NewEclipseAttachment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdAttachmentsPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdAttachmentsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/attachments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Wallet Audit detail
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
         * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdAuditEventsGet: async (tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdAuditEventsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdAuditEventsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/audit-events`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list. If the source and destination may vary, then use the bulk-transfer API at wallets/bulk-transfers
         * @summary Do a set of inter account transfers from the wallet. Only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdBulkTransfersPost: async (tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdBulkTransfersPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdBulkTransfersPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/bulk-transfers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (atomic !== undefined) {
                localVarQueryParameter['atomic'] = atomic;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all cards linked to the wallet
         * @summary Get cards linked to the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdCardsGet: async (tenantId: number, walletId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdCardsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdCardsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/cards`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * For prepaid programs: Create a new supplementary card for existing primary card.  For debit programs: Create a card under the wallet even if there is no existing card.
         * @summary Create a card linked to the existing wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdCardsPost: async (tenantId: number, walletId: number, body?: NewEclipseWalletCard, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdCardsPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdCardsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/cards`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Note that this can only be done if the wallet has no card, transactions, QRCodes or any other activity. If a wallet has any cards etc then do a PUT and set the status of the wallet to CANCELLED
         * @summary Delete a wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdDelete: async (tenantId: number, walletId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdDelete.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a wallet when the walletId is known. This will retrieve any wallet in the tenant irrespective of it being tenant-level, customer or organisation
         * @summary Get wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdGet: async (tenantId: number, walletId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all payments where the wallet was used to make the payment or the payment was received into the wallet
         * @summary Get payments made by or accepted into the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [paymentData] The payment data used in the payment. E.g. QRCcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdPaymentsGet: async (tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentData?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdPaymentsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdPaymentsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/payments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paymentData !== undefined) {
                localVarQueryParameter['paymentData'] = paymentData;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing wallet. This will update any wallet in the tenant irrespective of it being tenant-level, customer or organisation. Null fields are ignored so it acts like a PATCH. Wallet type can be changed so long as: (1) The currency attribute is the same on both types (2) The wallet mode is the same on both types (3) The customer or organisation owning the wallet passes the KYC ruleset of the new wallet type.
         * @summary Update a wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {UpdatedEclipseWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdPut: async (tenantId: number, walletId: number, body?: UpdatedEclipseWallet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdPut.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlinks the QRCode and makes it available for linking to another wallet. The underlying QRCode is not deleted unless if delete=true. Just the link is removed if delete=false or is not passed in
         * @summary Unlink the QRCode from the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} code 
         * @param {boolean} [_delete] When this value is passed as true then it will delete QrCode from Masterpass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdQrCodesCodeDelete: async (tenantId: number, walletId: number, code: string, _delete?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesCodeDelete.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesCodeDelete.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesCodeDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/qr-codes/{code}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (_delete !== undefined) {
                localVarQueryParameter['delete'] = _delete;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all QRCodes linked to the wallet
         * @summary Get QRCodes linked to the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdQrCodesGet: async (tenantId: number, walletId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/qr-codes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new QRCode if code is not suppled . If code is supplied and merchantId and useReservedQrCode is false then all other fields are ignored and the existing code is looked up and linked to the wallet. Note a code can only be linked to one wallet at a time. Once created/linked it will appear under the wallets mappings. The behaviour of the QRCode depends on the type. Typical behaviour is when a compatible App pays the QRCode, the value will appear in the mapped wallet. If code is supplied and merchantId, useReservedQrCode is true then code will be linked to wallet for supplied merchantId
         * @summary Create a new QRCode linked to the wallet or link an existing unlinked QRCode to the wallet or link existing reserved qrCode to wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletQRCode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdQrCodesPost: async (tenantId: number, walletId: number, body?: NewEclipseWalletQRCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdQrCodesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/qr-codes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the transaction history for a wallet
         * @summary Get wallets transactions
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
         * @param {string} [externalId] The externalId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed
         * @param {string} [externalUniqueId] The externalUniqueId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed. NB: Prefix with CR- or DB- for the credit or debit leg
         * @param {string} [fields] Additional field data to include. Comma separated list. Supports payments,withdrawals,remittances.
         * @param {string} [filter] Filter string to search in fields of the transaction to further filter results. Not case sensitive.
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTransactionsGet: async (tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalId?: string, externalUniqueId?: string, fields?: string, filter?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdTransactionsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdTransactionsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/transactions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (externalUniqueId !== undefined) {
                localVarQueryParameter['externalUniqueId'] = externalUniqueId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HWalletsApi - functional programming interface
 * @export
 */
export const HWalletsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId and toWalletId should be populated.
         * @summary Do a set of inter account transfers only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {Array&lt;EclipseBulkTransferFromAnySource&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsBulkTransfersPost(tenantId: number, body?: Array<EclipseBulkTransferFromAnySource>, atomic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsBulkTransfersPost(tenantId, body, atomic, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Search for wallets within a tenant
         * @summary Search for a wallet within tenant
         * @param {number} tenantId 
         * @param {string} [accountNumber] Account number
         * @param {string} [lastName] Customers last (family) name of max length 50
         * @param {string} [nationalIdentityNumber] Max length 20
         * @param {string} [passportNumber] Max length 20
         * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
         * @param {string} [walletFriendlyId] Will be length 8 if provided
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsGet(tenantId: number, accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, walletFriendlyId?: string, walletId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipsePublicWalletInfo>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsGet(tenantId, accountNumber, lastName, nationalIdentityNumber, passportNumber, phone, walletFriendlyId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will return all attachments linked to the wallet
         * @summary Get a list of the wallets attachments
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdAttachmentsGet(tenantId: number, walletId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseAttachment>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdAttachmentsGet(tenantId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This can attached extra information to a wallet in form of  sticky notes, docs or images. One can add multiple attachments to the wallet. Supported media types are application/pdf,image/png or image/jpeg,application/json,text/plain.
         * @summary Add a new attachment on the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseAttachment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdAttachmentsPost(tenantId: number, walletId: number, body?: NewEclipseAttachment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseAttachment>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdAttachmentsPost(tenantId, walletId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Wallet Audit detail
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
         * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdAuditEventsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseAuditEvent>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdAuditEventsGet(tenantId, walletId, dateFromIncl, dateToExcl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list. If the source and destination may vary, then use the bulk-transfer API at wallets/bulk-transfers
         * @summary Do a set of inter account transfers from the wallet. Only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdBulkTransfersPost(tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdBulkTransfersPost(tenantId, walletId, body, atomic, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all cards linked to the wallet
         * @summary Get cards linked to the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdCardsGet(tenantId: number, walletId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCard>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdCardsGet(tenantId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * For prepaid programs: Create a new supplementary card for existing primary card.  For debit programs: Create a card under the wallet even if there is no existing card.
         * @summary Create a card linked to the existing wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdCardsPost(tenantId: number, walletId: number, body?: NewEclipseWalletCard, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCard>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdCardsPost(tenantId, walletId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Note that this can only be done if the wallet has no card, transactions, QRCodes or any other activity. If a wallet has any cards etc then do a PUT and set the status of the wallet to CANCELLED
         * @summary Delete a wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdDelete(tenantId: number, walletId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdDelete(tenantId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a wallet when the walletId is known. This will retrieve any wallet in the tenant irrespective of it being tenant-level, customer or organisation
         * @summary Get wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdGet(tenantId: number, walletId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWallet>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdGet(tenantId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all payments where the wallet was used to make the payment or the payment was received into the wallet
         * @summary Get payments made by or accepted into the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [paymentData] The payment data used in the payment. E.g. QRCcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdPaymentsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentData?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentResult>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdPaymentsGet(tenantId, walletId, dateFromIncl, dateToExcl, limit, offset, paymentData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update an existing wallet. This will update any wallet in the tenant irrespective of it being tenant-level, customer or organisation. Null fields are ignored so it acts like a PATCH. Wallet type can be changed so long as: (1) The currency attribute is the same on both types (2) The wallet mode is the same on both types (3) The customer or organisation owning the wallet passes the KYC ruleset of the new wallet type.
         * @summary Update a wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {UpdatedEclipseWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdPut(tenantId: number, walletId: number, body?: UpdatedEclipseWallet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWallet>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdPut(tenantId, walletId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Unlinks the QRCode and makes it available for linking to another wallet. The underlying QRCode is not deleted unless if delete=true. Just the link is removed if delete=false or is not passed in
         * @summary Unlink the QRCode from the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} code 
         * @param {boolean} [_delete] When this value is passed as true then it will delete QrCode from Masterpass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdQrCodesCodeDelete(tenantId: number, walletId: number, code: string, _delete?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdQrCodesCodeDelete(tenantId, walletId, code, _delete, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all QRCodes linked to the wallet
         * @summary Get QRCodes linked to the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdQrCodesGet(tenantId: number, walletId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseWalletQRCode>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdQrCodesGet(tenantId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a new QRCode if code is not suppled . If code is supplied and merchantId and useReservedQrCode is false then all other fields are ignored and the existing code is looked up and linked to the wallet. Note a code can only be linked to one wallet at a time. Once created/linked it will appear under the wallets mappings. The behaviour of the QRCode depends on the type. Typical behaviour is when a compatible App pays the QRCode, the value will appear in the mapped wallet. If code is supplied and merchantId, useReservedQrCode is true then code will be linked to wallet for supplied merchantId
         * @summary Create a new QRCode linked to the wallet or link an existing unlinked QRCode to the wallet or link existing reserved qrCode to wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletQRCode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdQrCodesPost(tenantId: number, walletId: number, body?: NewEclipseWalletQRCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWalletQRCode>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdQrCodesPost(tenantId, walletId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the transaction history for a wallet
         * @summary Get wallets transactions
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
         * @param {string} [externalId] The externalId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed
         * @param {string} [externalUniqueId] The externalUniqueId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed. NB: Prefix with CR- or DB- for the credit or debit leg
         * @param {string} [fields] Additional field data to include. Comma separated list. Supports payments,withdrawals,remittances.
         * @param {string} [filter] Filter string to search in fields of the transaction to further filter results. Not case sensitive.
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalId?: string, externalUniqueId?: string, fields?: string, filter?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Transaction>>> {
            const localVarAxiosArgs = await HWalletsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId, walletId, dateFromIncl, dateToExcl, externalId, externalUniqueId, fields, filter, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HWalletsApi - factory interface
 * @export
 */
export const HWalletsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId and toWalletId should be populated.
         * @summary Do a set of inter account transfers only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {Array&lt;EclipseBulkTransferFromAnySource&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsBulkTransfersPost(tenantId: number, body?: Array<EclipseBulkTransferFromAnySource>, atomic?: boolean, options?: any): AxiosPromise<void> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsBulkTransfersPost(tenantId, body, atomic, options).then((request) => request(axios, basePath));
        },
        /**
         * Search for wallets within a tenant
         * @summary Search for a wallet within tenant
         * @param {number} tenantId 
         * @param {string} [accountNumber] Account number
         * @param {string} [lastName] Customers last (family) name of max length 50
         * @param {string} [nationalIdentityNumber] Max length 20
         * @param {string} [passportNumber] Max length 20
         * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
         * @param {string} [walletFriendlyId] Will be length 8 if provided
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsGet(tenantId: number, accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, walletFriendlyId?: string, walletId?: number, options?: any): AxiosPromise<Array<EclipsePublicWalletInfo>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsGet(tenantId, accountNumber, lastName, nationalIdentityNumber, passportNumber, phone, walletFriendlyId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * This will return all attachments linked to the wallet
         * @summary Get a list of the wallets attachments
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdAttachmentsGet(tenantId: number, walletId: number, options?: any): AxiosPromise<Array<EclipseAttachment>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdAttachmentsGet(tenantId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * This can attached extra information to a wallet in form of  sticky notes, docs or images. One can add multiple attachments to the wallet. Supported media types are application/pdf,image/png or image/jpeg,application/json,text/plain.
         * @summary Add a new attachment on the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseAttachment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdAttachmentsPost(tenantId: number, walletId: number, body?: NewEclipseAttachment, options?: any): AxiosPromise<EclipseAttachment> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdAttachmentsPost(tenantId, walletId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Wallet Audit detail
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
         * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdAuditEventsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, options?: any): AxiosPromise<Array<EclipseAuditEvent>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdAuditEventsGet(tenantId, walletId, dateFromIncl, dateToExcl, options).then((request) => request(axios, basePath));
        },
        /**
         * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list. If the source and destination may vary, then use the bulk-transfer API at wallets/bulk-transfers
         * @summary Do a set of inter account transfers from the wallet. Only applicable for close loop wallets
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
         * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdBulkTransfersPost(tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options?: any): AxiosPromise<void> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdBulkTransfersPost(tenantId, walletId, body, atomic, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all cards linked to the wallet
         * @summary Get cards linked to the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdCardsGet(tenantId: number, walletId: number, options?: any): AxiosPromise<Array<EclipseCard>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdCardsGet(tenantId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * For prepaid programs: Create a new supplementary card for existing primary card.  For debit programs: Create a card under the wallet even if there is no existing card.
         * @summary Create a card linked to the existing wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdCardsPost(tenantId: number, walletId: number, body?: NewEclipseWalletCard, options?: any): AxiosPromise<EclipseCard> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdCardsPost(tenantId, walletId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Note that this can only be done if the wallet has no card, transactions, QRCodes or any other activity. If a wallet has any cards etc then do a PUT and set the status of the wallet to CANCELLED
         * @summary Delete a wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdDelete(tenantId: number, walletId: number, options?: any): AxiosPromise<void> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdDelete(tenantId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a wallet when the walletId is known. This will retrieve any wallet in the tenant irrespective of it being tenant-level, customer or organisation
         * @summary Get wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdGet(tenantId: number, walletId: number, options?: any): AxiosPromise<EclipseWallet> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdGet(tenantId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all payments where the wallet was used to make the payment or the payment was received into the wallet
         * @summary Get payments made by or accepted into the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [paymentData] The payment data used in the payment. E.g. QRCcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdPaymentsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentData?: string, options?: any): AxiosPromise<Array<PaymentResult>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdPaymentsGet(tenantId, walletId, dateFromIncl, dateToExcl, limit, offset, paymentData, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing wallet. This will update any wallet in the tenant irrespective of it being tenant-level, customer or organisation. Null fields are ignored so it acts like a PATCH. Wallet type can be changed so long as: (1) The currency attribute is the same on both types (2) The wallet mode is the same on both types (3) The customer or organisation owning the wallet passes the KYC ruleset of the new wallet type.
         * @summary Update a wallet by walletId
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {UpdatedEclipseWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdPut(tenantId: number, walletId: number, body?: UpdatedEclipseWallet, options?: any): AxiosPromise<EclipseWallet> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdPut(tenantId, walletId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlinks the QRCode and makes it available for linking to another wallet. The underlying QRCode is not deleted unless if delete=true. Just the link is removed if delete=false or is not passed in
         * @summary Unlink the QRCode from the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} code 
         * @param {boolean} [_delete] When this value is passed as true then it will delete QrCode from Masterpass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdQrCodesCodeDelete(tenantId: number, walletId: number, code: string, _delete?: boolean, options?: any): AxiosPromise<void> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdQrCodesCodeDelete(tenantId, walletId, code, _delete, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all QRCodes linked to the wallet
         * @summary Get QRCodes linked to the wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdQrCodesGet(tenantId: number, walletId: number, options?: any): AxiosPromise<Array<EclipseWalletQRCode>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdQrCodesGet(tenantId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new QRCode if code is not suppled . If code is supplied and merchantId and useReservedQrCode is false then all other fields are ignored and the existing code is looked up and linked to the wallet. Note a code can only be linked to one wallet at a time. Once created/linked it will appear under the wallets mappings. The behaviour of the QRCode depends on the type. Typical behaviour is when a compatible App pays the QRCode, the value will appear in the mapped wallet. If code is supplied and merchantId, useReservedQrCode is true then code will be linked to wallet for supplied merchantId
         * @summary Create a new QRCode linked to the wallet or link an existing unlinked QRCode to the wallet or link existing reserved qrCode to wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletQRCode} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdQrCodesPost(tenantId: number, walletId: number, body?: NewEclipseWalletQRCode, options?: any): AxiosPromise<EclipseWalletQRCode> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdQrCodesPost(tenantId, walletId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the transaction history for a wallet
         * @summary Get wallets transactions
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
         * @param {string} [externalId] The externalId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed
         * @param {string} [externalUniqueId] The externalUniqueId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed. NB: Prefix with CR- or DB- for the credit or debit leg
         * @param {string} [fields] Additional field data to include. Comma separated list. Supports payments,withdrawals,remittances.
         * @param {string} [filter] Filter string to search in fields of the transaction to further filter results. Not case sensitive.
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalId?: string, externalUniqueId?: string, fields?: string, filter?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Array<Transaction>> {
            return HWalletsApiFp(configuration).tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId, walletId, dateFromIncl, dateToExcl, externalId, externalUniqueId, fields, filter, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HWalletsApi - object-oriented interface
 * @export
 * @class HWalletsApi
 * @extends {BaseAPI}
 */
export class HWalletsApi extends BaseAPI {
    /**
     * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId and toWalletId should be populated.
     * @summary Do a set of inter account transfers only applicable for close loop wallets
     * @param {number} tenantId 
     * @param {Array&lt;EclipseBulkTransferFromAnySource&gt;} [body] 
     * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsBulkTransfersPost(tenantId: number, body?: Array<EclipseBulkTransferFromAnySource>, atomic?: boolean, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsBulkTransfersPost(tenantId, body, atomic, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Search for wallets within a tenant
     * @summary Search for a wallet within tenant
     * @param {number} tenantId 
     * @param {string} [accountNumber] Account number
     * @param {string} [lastName] Customers last (family) name of max length 50
     * @param {string} [nationalIdentityNumber] Max length 20
     * @param {string} [passportNumber] Max length 20
     * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
     * @param {string} [walletFriendlyId] Will be length 8 if provided
     * @param {number} [walletId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsGet(tenantId: number, accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, walletFriendlyId?: string, walletId?: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsGet(tenantId, accountNumber, lastName, nationalIdentityNumber, passportNumber, phone, walletFriendlyId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will return all attachments linked to the wallet
     * @summary Get a list of the wallets attachments
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdAttachmentsGet(tenantId: number, walletId: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdAttachmentsGet(tenantId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This can attached extra information to a wallet in form of  sticky notes, docs or images. One can add multiple attachments to the wallet. Supported media types are application/pdf,image/png or image/jpeg,application/json,text/plain.
     * @summary Add a new attachment on the wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {NewEclipseAttachment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdAttachmentsPost(tenantId: number, walletId: number, body?: NewEclipseAttachment, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdAttachmentsPost(tenantId, walletId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Wallet Audit detail
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
     * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdAuditEventsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdAuditEventsGet(tenantId, walletId, dateFromIncl, dateToExcl, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The transfers are processed atomically (synchronously) or one by one in the background. For atomic, all fail or all succeed. For non atomic, they are processed one by one in the background and some may fail and some may succeed. The results for non atomic are sent by email to the caller.  toWalletId and amount are required. Unique Id can optionally be used as a means of ensuring transactions cannot be duplicated. The unique Id must be globally unique in the system. If not provided the backend will assign a GUID for you. The fromWalletId is the source wallet for all the transfers in the list. If the source and destination may vary, then use the bulk-transfer API at wallets/bulk-transfers
     * @summary Do a set of inter account transfers from the wallet. Only applicable for close loop wallets
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {Array&lt;EclipseBulkTransfer&gt;} [body] 
     * @param {boolean} [atomic] Do all transfers in one atomic transaction (default). Atomic max batch size 500. Non atomic 500000
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdBulkTransfersPost(tenantId: number, walletId: number, body?: Array<EclipseBulkTransfer>, atomic?: boolean, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdBulkTransfersPost(tenantId, walletId, body, atomic, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all cards linked to the wallet
     * @summary Get cards linked to the wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdCardsGet(tenantId: number, walletId: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdCardsGet(tenantId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * For prepaid programs: Create a new supplementary card for existing primary card.  For debit programs: Create a card under the wallet even if there is no existing card.
     * @summary Create a card linked to the existing wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {NewEclipseWalletCard} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdCardsPost(tenantId: number, walletId: number, body?: NewEclipseWalletCard, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdCardsPost(tenantId, walletId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Note that this can only be done if the wallet has no card, transactions, QRCodes or any other activity. If a wallet has any cards etc then do a PUT and set the status of the wallet to CANCELLED
     * @summary Delete a wallet by walletId
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdDelete(tenantId: number, walletId: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdDelete(tenantId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a wallet when the walletId is known. This will retrieve any wallet in the tenant irrespective of it being tenant-level, customer or organisation
     * @summary Get wallet by walletId
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdGet(tenantId: number, walletId: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdGet(tenantId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all payments where the wallet was used to make the payment or the payment was received into the wallet
     * @summary Get payments made by or accepted into the wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [paymentData] The payment data used in the payment. E.g. QRCcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdPaymentsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentData?: string, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdPaymentsGet(tenantId, walletId, dateFromIncl, dateToExcl, limit, offset, paymentData, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update an existing wallet. This will update any wallet in the tenant irrespective of it being tenant-level, customer or organisation. Null fields are ignored so it acts like a PATCH. Wallet type can be changed so long as: (1) The currency attribute is the same on both types (2) The wallet mode is the same on both types (3) The customer or organisation owning the wallet passes the KYC ruleset of the new wallet type.
     * @summary Update a wallet by walletId
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {UpdatedEclipseWallet} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdPut(tenantId: number, walletId: number, body?: UpdatedEclipseWallet, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdPut(tenantId, walletId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Unlinks the QRCode and makes it available for linking to another wallet. The underlying QRCode is not deleted unless if delete=true. Just the link is removed if delete=false or is not passed in
     * @summary Unlink the QRCode from the wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {string} code 
     * @param {boolean} [_delete] When this value is passed as true then it will delete QrCode from Masterpass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdQrCodesCodeDelete(tenantId: number, walletId: number, code: string, _delete?: boolean, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdQrCodesCodeDelete(tenantId, walletId, code, _delete, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all QRCodes linked to the wallet
     * @summary Get QRCodes linked to the wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdQrCodesGet(tenantId: number, walletId: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdQrCodesGet(tenantId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new QRCode if code is not suppled . If code is supplied and merchantId and useReservedQrCode is false then all other fields are ignored and the existing code is looked up and linked to the wallet. Note a code can only be linked to one wallet at a time. Once created/linked it will appear under the wallets mappings. The behaviour of the QRCode depends on the type. Typical behaviour is when a compatible App pays the QRCode, the value will appear in the mapped wallet. If code is supplied and merchantId, useReservedQrCode is true then code will be linked to wallet for supplied merchantId
     * @summary Create a new QRCode linked to the wallet or link an existing unlinked QRCode to the wallet or link existing reserved qrCode to wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {NewEclipseWalletQRCode} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdQrCodesPost(tenantId: number, walletId: number, body?: NewEclipseWalletQRCode, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdQrCodesPost(tenantId, walletId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieves the transaction history for a wallet
     * @summary Get wallets transactions
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
     * @param {string} [externalId] The externalId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed
     * @param {string} [externalUniqueId] The externalUniqueId used when the transaction was initiated. Other parameters other than walletId are ignored if this is passed. NB: Prefix with CR- or DB- for the credit or debit leg
     * @param {string} [fields] Additional field data to include. Comma separated list. Supports payments,withdrawals,remittances.
     * @param {string} [filter] Filter string to search in fields of the transaction to further filter results. Not case sensitive.
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HWalletsApi
     */
    public tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalId?: string, externalUniqueId?: string, fields?: string, filter?: string, limit?: number, offset?: number, options?: any) {
        return HWalletsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId, walletId, dateFromIncl, dateToExcl, externalId, externalUniqueId, fields, filter, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}
