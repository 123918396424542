import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { VerifyOTP } from "../../components/VerifyOTP/VerifyOTP";

const useStyles = makeStyles(themeStyle);

export function AssistedSignupVerifyOTP(props) {
  const mobileNumber = useLocation().state?.mobileNumber;
  const gCaptchaToken = useLocation().state?.gCaptcha;
  const tenantToken = useLocation().state?.tenantToken;

  const history = useHistory();
  const classes = useStyles();

  const backToSignup = useCallback(() => {
    history.push({
      pathname: '/pages/assisted-signup',
    });
  }, [history]);

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        backToSignup();
      }
    };
  }, [backToSignup])

  const goToCreateUserName = () => {
    history.push({
      pathname: '/pages/assisted-signup-create-username',
      state: { mobileNumber, tenantToken }
    });
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToSignup}/>
              <span className={classes.title}>Sign up a customer</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <VerifyOTP {...props} mobileNumber={mobileNumber} gCaptcha={gCaptchaToken} onComplete={goToCreateUserName}/>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  verifyOTPHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedSignupVerifyOTP)