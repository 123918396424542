import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { controlLabelColor, primaryColor } from "../../style.scss";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { SpinnerComponent } from 'react-element-spinner';
import { AuthRouteWithTemplateId } from "../../default-route";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(themeStyle);

export function IdentityDocument(props) {
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  const kycDocuments = props?.appData?.template?.kycDocuments;
  const defaultDoc = kycDocuments?.find(document => document.id === "0")
  const classes = useStyles();
  const history = useHistory();
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);
  const [linkedDocuments, setLinkedDocuments] = useState(null);
  const [checkboxState, setCheckboxState] = useState('0');
  const [selectedDocument, setSelectedDocument] = useState({ ...defaultDoc });
  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }
  const onChangeRadio = (event) => {
    const selectedDoc = kycDocuments.find(document => document.id === event.target.value);
    setCheckboxState(event.target.value);
    setSelectedDocument(selectedDoc);
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.identityDocumentHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [props, history]);

  React.useEffect(() => {
      window.onpopstate = () => {
        setIsBackClicked(true);
        (fromScreen !== 'ASSISTED_SIGNUP') ? logout() : backToHome();
      };
  }, [history, logout, backToHome, fromScreen])

  const goToDocumentInitial = () => {
    history.push({
      pathname: `/pages/document-initial`,
      state: { selectedDoc: checkboxState, document: selectedDocument, fromScreen, isAddressAdded }
    });
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen, isAddressAdded }
    });
  }, [history, isAddressAdded]);

  const getDocuments = useCallback(() => {
    setLoading(true);
    const documentAPI = new DCustomerManagementApi();
    documentAPI.tenantsTenantIdCustomersCustomerIdDocumentsGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((documentAPIResponse) => {
      if (documentAPIResponse.data) {
        const appData = {
          ...props?.appData,
          documents: documentAPIResponse.data
        }
        props.identityDocumentHandler({ ...appData });
        setLinkedDocuments(documentAPIResponse.data);
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        if(fromScreen === 'ASSISTED_SIGNUP'){
          redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
        } else {
          redirectToErrorScreen('/auth/login', '/auth/error');
        }
      }
    });
  }, [props, redirectToErrorScreen, fromScreen]);

  React.useEffect(() => {
    if (!linkedDocuments && fromScreen !== 'ASSISTED_SIGNUP' && !isBackClicked) {
      getDocuments();
    }
  }, [getDocuments, linkedDocuments, fromScreen, isBackClicked]);


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p onClick={() => {
                fromScreen !== 'ASSISTED_SIGNUP' ? logout() : backToHome()
              }} style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                <ArrowBackIosIcon/>
                <span>{"Back"}</span>
              </p>
              <small style={{
                color: controlLabelColor,
                marginLeft: "auto"
              }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 2 : 1} of 5</small>
            </div>
            <strong>
              <small style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '40%' } : { width: '20%' }}>&nbsp;</small>
            </strong>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className='auth-title'
                  dangerouslySetInnerHTML={{ __html: fromScreen === 'ASSISTED_SIGNUP' ? 'Authenticate <br /> their identity.' : 'We need to <br /> authenticate <br /> your identity.' }}/>
              <p className={classes.regularFontSize + ' m-b-0'}>
                <strong>{fromScreen === 'ASSISTED_SIGNUP' ? 'Which document will the customer be using to verify their identity?' : 'Which document would you like to use to verify your identity?'}</strong>
              </p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <RadioGroup aria-label="transferType" name="transferType" value={checkboxState} onChange={onChangeRadio}
                          className='identity-doc-radio'>
                {
                  kycDocuments?.map((document) => {
                    if (document.isActive) {
                      return (<FormControlLabel key={document.id} value={document.id}
                                                control={<Radio className={classes.Radio}/>}
                                                label={document.title}/>)
                    }
                    return ("");
                  })
                }
              </RadioGroup>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToDocumentInitial}>Next</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  identityDocumentHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDocument)
