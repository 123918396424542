import React, { useCallback, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { primaryColor } from "../../style.scss";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Danger from "../../components/Typography/Danger";
import { loginToApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { load } from 'recaptcha-v3'
import { EnvConfig } from "../../environment.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { AAuthenticationApi, DCustomerManagementApi } from "eclipse-react-sdk/services";
import ReturnMobileNumberWithISD from "../../views-auth/returnMobileNumberWithISD.js";
import { WalletStatus } from "../../eclipse-react-sdk/services/models";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function AssistedCardRegister(props) {
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = useState({ mobileNumber: '' });
  const [errors, setErrors] = useState({});
  const [alertData, setAlert] = useState(null);
  const [tenantToken, setTenantToken] = useState({});
  const defaultMobileNumberRegEx = props?.appData?.template?.defaultMobileNumberRegEx;
  const whatsAppDefaultISDCode = props?.appData?.template?.whatsAppDefaultISDCode;
  const tenantIdentity = props?.appData?.template?.tenantUsername;
  const tenantPassword = props?.appData?.template?.tenantPassword;
  const tenantId = props?.appData?.template?.tenantId;

  const returnMobileNumberWithISD = useCallback(() => {
    return ReturnMobileNumberWithISD(whatsAppDefaultISDCode, inputs?.mobileNumber);
  }, [whatsAppDefaultISDCode, inputs]);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const generateOTP = useCallback(() => {
    const httpRequestConfiguration = HttpRequestConfiguration();
    const generateOTPAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
    const mobileNumber = returnMobileNumberWithISD();
    load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
      reCaptcha.execute('OTP_VERIFICATION').then((gCaptcha) => {
        if (gCaptcha) {
          const headers = { gCaptcha };
          httpRequestConfiguration.baseOptions = { headers };
          generateOTPAPI.globalVerificationsPost({ phone: mobileNumber, tenantId }).then(() => {
            history.push({
              pathname: '/pages/assisted-card-verify-otp',
              state: { gCaptcha, mobileNumber }
            });
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
          });
        } else {
          showAlert(true, 'ERROR', `Please try again!`);
        }
      })
    });
  }, [history, redirectToErrorScreen, returnMobileNumberWithISD, tenantId]);

  const getWallets = useCallback((assistedCustomer) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.Authorization);
    const customerWalletAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsGet(props?.appData?.auth?.tenantId, assistedCustomer?.customerId).then((customerWalletAPIResponse) => {
      let cardWalletList = [];
      if (customerWalletAPIResponse.data && customerWalletAPIResponse.data.length > 0) {
        const activeWallets = customerWalletAPIResponse?.data?.filter(wallet => wallet?.status !== WalletStatus?.CANCELLED) || [];
        cardWalletList = activeWallets?.filter(wallet => wallet?.walletTypeId === props?.appData?.template?.publicCardWalletTypeId);
      }
      if (cardWalletList && cardWalletList.length < 1) {
        generateOTP();
      } else {
        setLoading(false);
        errors.mobileNumber = `Card added already!`;
        setErrors(errors);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, generateOTP, errors, setErrors, tenantToken]);

  const getRatify = useCallback((assistedCustomer) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.Authorization);
    const ratifyAPI = new DCustomerManagementApi(httpRequestConfiguration);
    ratifyAPI.tenantsTenantIdCustomersCustomerIdWalletTypesGet(props?.appData?.auth?.tenantId, assistedCustomer?.customerId, 1).then((ratifyAPIResponse) => {
      if (ratifyAPIResponse.data && ratifyAPIResponse.data.length > 0) {
        let isRatifyFailed = 0;
        ratifyAPIResponse.data.forEach((ratifyAPIResponseData) => {
          if (ratifyAPIResponseData.walletTypeId === props?.appData?.template?.publicCardWalletTypeId && !ratifyAPIResponseData?.allowed) {
            isRatifyFailed = isRatifyFailed + 1;
          }
          if (ratifyAPIResponseData.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId && !ratifyAPIResponseData?.allowed) {
            isRatifyFailed = isRatifyFailed + 1;
          }
        });
        if (isRatifyFailed > 0) {
          errors.mobileNumber = `KYC is not completed!`;
          setErrors(errors);
          setLoading(false);
        } else {
          getWallets(assistedCustomer);
        }
      } else {
        errors.mobileNumber = `KYC is not completed!`;
        setErrors(errors);
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, getWallets, errors, setErrors, tenantToken]);

  const getAssistedUserIdentity = useCallback((appData) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesGet(props?.appData?.auth?.tenantId, appData.assistedCustomer?.customerId).then((identities) => {
      if (identities && identities.data.length > 0) {
        const [user] = identities.data;
        appData.assistedCustomer.identity = user.identity;
        props.physicalCardRegisterHandler(appData);
        getRatify(appData.assistedCustomer);
      }
    }).catch(() => {
      redirectToErrorScreen(`/pages/dashboard`, `error`);
    });
  }, [props, redirectToErrorScreen, getRatify, tenantToken]);

  const handleSignupFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateSignupForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.Authorization);
      const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
      customerAPI.tenantsTenantIdCustomersGet(props?.appData?.auth?.tenantId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, returnMobileNumberWithISD()).then((getCustomerAPIResponse) => {
        if (getCustomerAPIResponse?.data?.length > 0) {
          const [assistedCustomer] = getCustomerAPIResponse?.data;
          const appData = {
            ...props?.appData,
            assistedCustomer
          }
          getAssistedUserIdentity(appData);
        } else {
          errors.mobileNumber = `The mobile number is not registered.`;
          setErrors(errors);
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });

    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateSignupForm(inputs);
    setErrors(validationErrors);
  }
  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateSignupForm(inputs);
    setErrors(validationErrors);
  }

  const validateSignupForm = (inputs) => {
    //Email errors
    const errors = {};
    const pattern = new RegExp(defaultMobileNumberRegEx);
    if (!inputs.mobileNumber) {
      errors.mobileNumber = 'Mobile number required!'
    } else if (!pattern.test(inputs.mobileNumber)) {
      errors.mobileNumber = 'Mobile number is invalid!'
    }
    return errors
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.physicalCardRegisterHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        backToHome();
      }
    };
  }, [backToHome])

  const getTenantToken = useCallback(() => {
    const loginAPI = new AAuthenticationApi(HttpRequestConfiguration(" "));
    const loginBody = {
      identity: tenantIdentity,
      password: tenantPassword
    }
    loginAPI.authenticationLoginPost(loginBody).then((authAPIResponse) => {
      const auth = { Authorization: authAPIResponse?.data?.headerValue };
      setTenantToken(auth);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/pages/dashboard`, `error`);
      }
    });
  }, [redirectToErrorScreen, tenantIdentity, tenantPassword]);

  useEffect(() => {
    getTenantToken();
  }, [getTenantToken])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
              <span className={classes.title}>Assisted Card Registration</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          <form autoComplete="off" onSubmit={handleSignupFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1>Register a card for a customer</h1>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  inputProps={{ inputMode: 'tel' }}
                  fullWidth
                  label="What is the  customer's mobile number?"
                  variant="filled"
                  id="mobileNumber"
                  type="text"
                  error={!!(errors.mobileNumber)}
                  name="mobileNumber"
                  value={inputs.mobileNumber}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.mobileNumber && <Danger><small>{errors.mobileNumber}</small></Danger>}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} m={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                        disabled={!!(errors.mobileNumber)}>Next</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  physicalCardRegisterHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedCardRegister)
