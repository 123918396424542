import React from "react";
import { connect } from "react-redux";
import { primaryColor } from "../../style.scss";
import { FormatCurrency } from "../../currency-formatter";

export function AmountDisplay(props) {
  const displayAmount = props?.amount;
  const walletDetailData = props?.walletDetailData;
  const padding = props?.padding;
  return (
    <div style={{
      width: '100%',
      height: '56px',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '40px',
      lineHeight: '56px',
      alignItems: 'center',
      textAlign: 'center',
      padding: padding ? padding : '0.5em 0',
      color: primaryColor
    }}>
      {`${FormatCurrency(displayAmount, walletDetailData?.currency)}`}
    </div >
  )
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(AmountDisplay)