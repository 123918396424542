import React from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'

import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import CollectionsIcon from "../../../assets/img/collections.svg";


const useStyles = makeStyles(themeStyle);

export function VASViewProducts(props) {
  const vasDetail = useLocation().state?.vasDetail;
  const vasProducts = useLocation().state?.vasProducts;
  const selectedNetworkPartner = useLocation().state?.selectedNetworkPartner;

  const history = useHistory();
  const classes = useStyles();

  const backToVASEnterIDNumber = () => {
    history.push({
      pathname: '/pages/vas-enter-idnumber',
      state: { vasDetail: vasDetail, vasProducts: vasProducts, selectedNetworkPartner: selectedNetworkPartner }
    });
  }

  const redirectToBuyKganyaProducts = (selectedProductData) => {
    history.push({
      pathname: '/pages/vas-buy-kganya-products',
      state: { vasDetail: vasDetail, vasProducts: vasProducts, selectedVASProducts: selectedProductData, selectedNetworkPartner: selectedNetworkPartner }
    });
  }

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToVASEnterIDNumber}></ArrowBackIosIcon>
                <span className={classes.title}>Collections</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.textCenter + ' m-b-0'}><b>Select a product</b></h4>
        </GridItem>
        {vasProducts?.products.map((product) => {
          return (<GridItem xs={12} sm={12} md={12} key={product?.title}>
            <Card className={product?.data[0]?.isProductPurchaseable === false ? classes.disabledCard : null} style={{ margin: '0' }}>
              <ButtonBase onClick={product?.data[0]?.isProductPurchaseable === true ? event => { redirectToBuyKganyaProducts({ title: product?.title, data: product?.data }) } : null} style={{ justifyContent: 'flex-start', cursor: product?.data[0]?.isProductPurchaseable === false ? "not-allowed" : null }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }} >
                    <div style={{ backgroundImage: `url(${product?.data[0]?.productImageUrl ? product?.data[0]?.productImageUrl : CollectionsIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', width: '2.5em' }}></div>
                    <b style={{ textAlign: 'left', margin: '0.5em 0.75em', lineHeight: '1.5em' }}>{product?.title} - {getSymbolFromCurrency(product?.data[0]?.currency || 'ZAR')}{product?.data[0]?.fixedPriceIncl.toFixed(2)}</b>
                  </h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>)
        })}
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(VASViewProducts)