/*eslint-disable*/
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/eclipse/components/headerStyle.js";


const useStyles = makeStyles(styles);

export default function WebFooter(props) {
  const classes = useStyles();

    return (
        <div className="web-footer">
            <div className={classes.container}>
                <div className="webfooter-content">
                <p style={{margin: '2px 0 0 0'}}>All rights reserved. &copy; 2023</p>
                <ul>
                    <li><a href="https://payat.io/qr/assets/files/210204%20-%20Processing%20Policy.pdf" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://payat.co.za/contact-us/">Contact Support</a></li>
                </ul>
                </div>
            </div>
        </div>
    );
}