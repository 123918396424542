import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { controlLabelColor, primaryColor } from "../../../style.scss";
import Danger from "../../../components/Typography/Danger";
import { loginToApp, logoutFromApp } from "../../../redux-services/actions/actions";
import { AuthRouteWithTemplateId } from "../../../default-route";
import { ReturnStoredTemplateId } from "../../../get-template-hostname";
import { EnvConfig } from "../../../environment";
import { connect } from "react-redux";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { AddressType } from "eclipse-react-sdk/services/models";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MenuItem from "@material-ui/core/MenuItem";
import { DCustomerManagementApi } from "../../../eclipse-react-sdk/services";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function PermanentAddress(props) {
  const saProvincesList = props?.appData?.template?.saProvincesList;
  const classes = useStyles();
  const history = useHistory();
  const fromScreen = useLocation().state?.fromScreen;
  const addressInfo = {
    addressType: AddressType.PHYSICAL,
    line1: "",
    line2: "",
    line3: "",
    city: "",
    state: "",
    code: "",
    country: "South Africa",
  }
  const [inputs, setInputs] = useState({ ...addressInfo });
  const [errors, setErrors] = useState({});
  const [isBlockOrEstate, setIsBlockOrEstate] = useState("1");
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);
  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }
  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.permanentAddressHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [props, history]);

  React.useEffect(() => {
    window.onpopstate = () => {
      (fromScreen !== 'ASSISTED_SIGNUP' ) ? logout() : backToHome();
    };
  }, [history, logout, backToHome, fromScreen])

  const goToSuccessRatifyKYC = () => {
    history.push({
      pathname: `/pages/ratify-kyc-success`
    });
  }
  const handleAddressFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateAddressForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const addAddressAPI = new DCustomerManagementApi();
      const requestBody = { ...inputs };
      if(isBlockOrEstate==="0"){
        requestBody.line3="   ";
      }
      addAddressAPI.tenantsTenantIdCustomersCustomerIdAddressesPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, requestBody).then(() => {
        setLoading(false);
        goToSuccessRatifyKYC();
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          if(fromScreen === 'ASSISTED_SIGNUP'){
            redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
          } else {
            redirectToErrorScreen('/pages/identity-document', '/pages/error');
          }
        }
      });
    }
  }
  const onChangeRadio = (event) => {
    setIsBlockOrEstate(event.target.value);
    setErrors({});
    setInputs(addressInfo);
  };
  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    if (event.target.name === "city") {
      inputs.state = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateAddressField(event);
    setErrors(validationErrors);
  }


  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateAddressField(event);
    setErrors(validationErrors);
  }

  const validateAddressForm = (inputs) => {
    const validationErrors = { ...errors }
    for (const [key, value] of Object.entries(inputs)) {
      const field = document.getElementById(key);
      let errorMessage = ""
      if (value === "") {
        errorMessage = `${field ? field.getAttribute("errormessage") : "This field"} is required`;
        if (key === "city") {
          errorMessage = `Province is required`;
        }
        validationErrors[key] = errorMessage;
      } else if (!/^[A-Za-z0-9]+[ a-zA-Z0-9-]{3,}$/i.test(value)) {
        errorMessage = `${field ? field.getAttribute("errormessage") : "Field value"} is invalid!`;
        if (key === "city") {
          errorMessage = `Province is invalid`;
        }
        validationErrors[key] = errorMessage;
      } else {
        delete validationErrors[key];
        if (key === "city") {
          delete validationErrors.state;
        }
      }
      if (isBlockOrEstate === "0" && key === "line3") {
        delete validationErrors[key];
      }
    }
    return validationErrors
  }

  const validateAddressField = (event) => {
    const validationErrors = { ...errors }
    if (event.target.value === "") {
      validationErrors[event.target.name] = `${event.target.getAttribute('errormessage')} required`;
    } else if (!/^[A-Za-z0-9]+[ a-zA-Z0-9-]{3,}$/i.test(inputs.firstname)) {
      validationErrors[event.target.name] = `${event.target.getAttribute('errormessage')} is invalid`;
    } else {
      delete validationErrors[event.target.name];
      if (event.target.name === "city") {
        delete validationErrors.state;
      }
    }
    if (isBlockOrEstate === "0" && event.target.name === "line3") {
      delete validationErrors[event.target.name];
    }
    return validationErrors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{display: 'flex',alignItems: 'center'}}>
              <p onClick={() => { fromScreen !== 'ASSISTED_SIGNUP' ? logout() : backToHome() }} style={{display: 'flex',alignItems: 'center', fontSize:18}}>
                <ArrowBackIosIcon />
                <span >{"Back"}</span>
              </p>
              <small style={{ color: controlLabelColor,marginLeft:"auto" }}>Step 5 of 5</small>
            </div>
            <strong><small style={{ width: '100%' }}>&nbsp;</small></strong>
          </div>
          <form onSubmit={handleAddressFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 className='auth-title'>Last one!</h1>
                <p className={classes.regularFontSize}><strong>Please complete your address.</strong></p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <label>Is your address in a block/estate?</label>
                <RadioGroup row aria-label="isBlockOrEstate" name="isBlockOrEstate" value={isBlockOrEstate}
                            onChange={onChangeRadio}
                            className='identity-doc-radio'>
                  <FormControlLabel key={"yes"} value={"1"} control={<Radio className={classes.Radio}/>}
                                    label={"Yes"}/>
                  <FormControlLabel key={"no"} value={"0"} control={<Radio className={classes.Radio}/>}
                                    label={"No"}/>
                </RadioGroup>
              </GridItem>
              {isBlockOrEstate === "1" ?
                <GridItem xs={12} sm={12} md={12}>
                  <ValidationTextField
                    fullWidth
                    label="Name and Unit Number"
                    inputProps={{ errormessage: "Name and Unit Number" }}
                    variant="filled"
                    id="line1"
                    type="text"
                    error={!!(errors.line1)}
                    name="line1"
                    value={inputs.line1}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp}/>
                  {errors.line1 && <Danger><small>{errors.line1}</small></Danger>}
                </GridItem> : null}
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="Street name and number"
                  inputProps={{ errormessage: "Street name and number" }}
                  variant="filled"
                  id={isBlockOrEstate === "1" ? "line2" : "line1"}
                  type="text"
                  error={!!(errors.line2)}
                  name={isBlockOrEstate === "1" ? "line2" : "line1"}
                  value={isBlockOrEstate === "1" ? inputs.line2 : inputs.line1}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {isBlockOrEstate === "1" ?
                  errors.line2 && <Danger><small>{errors.line2}</small></Danger> :
                  errors.line1 && <Danger><small>{errors.line1}</small></Danger>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="Suburb"
                  inputProps={{ errormessage: "Suburb" }}
                  variant="filled"
                  id={isBlockOrEstate === "1" ? "line3" : "line2"}
                  type="text"
                  error={!!(errors.line3)}
                  name={isBlockOrEstate === "1" ? "line3" : "line2"}
                  value={isBlockOrEstate === "1" ? inputs.line3 : inputs.line2}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {isBlockOrEstate === "1" ?
                  errors.line3 && <Danger><small>{errors.line3}</small></Danger> :
                  errors.line2 && <Danger><small>{errors.line2}</small></Danger>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Province"}
                  inputProps={{ errormessage: "Province" }}
                  select
                  variant="filled"
                  id="city"
                  type="text"
                  name="city"
                  value={inputs.city}
                  onChange={handleInputChange}>
                  {saProvincesList.map((province) => {
                    return (<MenuItem key={province} value={province}>{province}</MenuItem>)
                  })}
                </ValidationTextField>
                {errors.city && <Danger><small>{errors.city}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="Code"
                  inputProps={{ errormessage: "Code" }}
                  variant="filled"
                  id="code"
                  type="number"
                  error={!!(errors.code)}
                  name="code"
                  defaultValue={inputs.code}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.code && <Danger><small>{errors.code}</small></Danger>}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                        disabled={(Object.keys(errors).length > 0)}>Done</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  permanentAddressHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PermanentAddress)
