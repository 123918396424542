import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor } from "../../style.scss";
import Danger from "../../components/Typography/Danger.js";
import { SpinnerComponent } from "react-element-spinner";
import { AAuthenticationApi, DCustomerManagementApi, HWalletsApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions";
import Button from "../../components/CustomButtons/Button";
import formStyle from "../../assets/jss/eclipse/components/formStyle";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { v4 as uuidv4 } from "uuid";
import HttpRequestConfiguration from "../../http-request-configuration";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function PhysicalCardForm(props) {
  const [assistedCustomer, setAssistedCustomer] = useState(props?.appData?.assistedCustomer);
  const flow = props?.flow;
  let customer = props?.appData?.customer;
  if (flow === "assisted-physical-card" && assistedCustomer) {
    customer = { ...assistedCustomer }
  }
  const onComplete = props?.onComplete;
  const goToScanCardQr = props?.goToScanCardQr;
  const isPhysicalCardQRScanAllow = props?.appData?.template?.pwaAppOptions?.physicalCardQRScanAllow;
  const termsAndConditionsLink = props?.appData?.template?.defaultPWATandCLink;
  const history = useHistory();
  const classes = useStyles();
  const scannedQRData = useLocation().state?.scannedQRData || "";
  const [alertData, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ card: scannedQRData, acceptTC: false });
  const [errors, setErrors] = useState({});

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.physicalCardRegisterHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateCreateCardForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      createWallet()
    }
  }
  const createWallet = () => {
    setLoading(true);
    let httpRequestConfiguration = "";
    if (flow === "assisted-physical-card" && assistedCustomer) {
      httpRequestConfiguration = HttpRequestConfiguration(assistedCustomer?.assistedUserToken);
    }
    const customerWalletAPI = new DCustomerManagementApi(httpRequestConfiguration);
    const createWalletBody = {
      externalUniqueId: uuidv4(),
      name: "My Physical Card",
      description: '',
      status: 'ACTIVE',
      walletTypeId: props?.appData?.template?.publicCardWalletTypeId,
      primaryPhysicalCardIdentifier: {},
    }
    const qrCode = {qrCode: inputs.card}
    const panNumber = {pan: inputs.card}
    if(props?.appData?.template?.manifestSettings?.appName === 'Vleissentraal'){
      createWalletBody.primaryPhysicalCardIdentifier = panNumber;
    } else {
      createWalletBody.primaryPhysicalCardIdentifier = qrCode;
    }
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsPost(props?.appData?.auth?.tenantId, customer?.customerId, createWalletBody).then((customerWalletAPIResponse) => {
      getWalletCards(customerWalletAPIResponse.data);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }
  const getWalletCards = (walletDetailData) => {
    let httpRequestConfiguration = "";
    if (flow === "assisted-physical-card" && assistedCustomer) {
      customer = { ...assistedCustomer }
      httpRequestConfiguration = HttpRequestConfiguration(assistedCustomer?.assistedUserToken);
    }
    let walletCardsAPI = new HWalletsApi(httpRequestConfiguration);
    walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(props?.appData?.auth?.tenantId, walletDetailData?.walletId).then((walletCardsAPIResponse) => {
      if (walletCardsAPIResponse.data && walletCardsAPIResponse.data.length > 0) {
        registerWalletCardOnCardOnFile(walletDetailData, walletCardsAPIResponse.data[0]);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const registerWalletCardOnCardOnFile = (walletDetailData, walletCardDetailData) => {
    let httpRequestConfiguration = "";
    if (flow === "assisted-physical-card" && assistedCustomer) {
      httpRequestConfiguration = HttpRequestConfiguration(assistedCustomer?.assistedUserToken);
    }
    const registerWalletCardsAPI = new DCustomerManagementApi(httpRequestConfiguration);
    const expiryMonth = `${new Date(walletCardDetailData?.expires).getMonth()}`;
    const expiryYear = `${new Date(walletCardDetailData?.expires).getFullYear()}`;
    const expiryDate = `${expiryMonth.padStart(2, 0)}${expiryYear.substr(2, 2)}`;
    const cardOnFileRequestBody = {
      alias: `${walletDetailData?.name}`,
      cardData: {
        accountType: '',
        cardOnFileId: uuidv4(),
        cardholderName: `${customer?.title} ${customer?.firstName} ${customer?.middleName || ""} ${customer?.lastName}`,
        cvv: walletCardDetailData?.cvv,
        dob: customer?.dateOfBirth,
        expiry: expiryDate,
        pan: walletCardDetailData?.pan
      },
      cardId: walletCardDetailData?.cardId,
    }
    registerWalletCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFilePost(props?.appData?.auth?.tenantId, customer?.customerId, cardOnFileRequestBody).then(() => {
      onComplete(walletCardDetailData, walletDetailData);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }
  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateCreateCardForm(inputs);
    setErrors(validationErrors);
  }

  const changeAcceptTC = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.checked;
    setInputs(inputs);
    const validationErrors = validateCreateCardForm(inputs);
    setErrors(validationErrors);
  }

  const validateCreateCardForm = (inputs) => {
    const errors = {}
    if (!inputs.card) {
      errors.card = 'Card required!'
    }
    if (!inputs.acceptTC) {
      errors.acceptTC = 'Please accept terms and conditions!'
    }
    return errors
  }

  const getAssistedUserToken = useCallback((loginBody) => {
    const loginAPI = new AAuthenticationApi(HttpRequestConfiguration(" "));
    loginAPI.authenticationLoginPost(loginBody).then((authAPIResponse) => {
      const auth = { assistedUserToken: authAPIResponse?.data?.headerValue };
      setAssistedCustomer({ ...assistedCustomer, ...auth });
    }).catch(() => {
      redirectToErrorScreen(`/pages/dashboard`, `/pages/error`);
    });
  }, [redirectToErrorScreen, assistedCustomer]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  React.useEffect(() => {
    if (assistedCustomer && flow === "assisted-physical-card") {
      if (!(assistedCustomer?.assistedUserToken) || (assistedCustomer?.assistedUserToken)) {
        const token = JSON.parse(atob(assistedCustomer?.assistedUserToken?.split(".")[1]));
        if (token?.exp * 1000 < Date.now()) {
          getAssistedUserToken({ identity: assistedCustomer?.identity, password: assistedCustomer?.password });
        }
      }
    }
  }, [getAssistedUserToken, assistedCustomer, flow])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'left' }}>
              {props?.appData?.template?.manifestSettings?.appName !== 'Vleissentraal' ?
                <p><b>Please complete the following information to register your card
                </b></p>
                :
                <p><b>Enter the card's 16 digit PAN number</b></p>
              }
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              {isPhysicalCardQRScanAllow ?
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary"
                    onClick={goToScanCardQr} style={{ marginBottom: '15px' }}>Scan the card's QR
                    code</Button>

                  <p style={{ textAlign: 'center' }}><b>OR</b></p>
                </GridItem>
                : null}
              <GridItem xs={12} sm={12} md={12} style={{marginTop: props?.appData?.template?.manifestSettings?.appName === 'Vleissentraal' ? '-30px' : null}}>
                <ValidationTextField
                  fullWidth
                  label={props?.appData?.template?.manifestSettings?.appName === 'Vleissentraal' ? "Card PAN number" : "Enter the code below the QR"}
                  variant="filled"
                  id="card"
                  type="text"
                  error={!!(errors.card)}
                  name="card"
                  value={inputs.card}
                  onChange={handleInputChange} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                {errors.card && <Danger><small>{errors.card}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    name="acceptTC"
                    checked={inputs.acceptTC}
                    onChange={changeAcceptTC}
                    style={{ paddingLeft: '0' }}
                  />
                  <p className={classes.regularFontSize + ' m-t-0 m-b-0'}
                    style={{ lineHeight: '20px' }}>
                    {flow === "assisted-physical-card" ? "Confirm your customer accept their card's " : "I accept my card's "}
                    <a className={classes.linkColor}
                      href={termsAndConditionsLink}
                      target={"_blank"}
                      rel={"noopener noreferrer"}>
                      terms and conditions.</a>
                  </p>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                {errors.acceptTC && <Danger><small>{errors.acceptTC}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary"
                  type="submit"
                  disabled={!!errors.card || !!errors.acceptTC}>Next</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
});

const mapDispatchToProps = dispatch => ({
  physicalCardRegisterHandler: data => dispatch(loginToApp(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicalCardForm)