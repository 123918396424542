export default function FormattedAmount(value) {
  const resetValue = (`${value}`.replace(/,/g, '')).replace(/\./g, '');

  if (+resetValue > 0) {
    if (+resetValue < 10)
      return `0.0${+resetValue}`;
    else if (+resetValue >= 10 && +resetValue <= 99)
      return `0.${+resetValue}`;
    else if (+resetValue >= 100 && +resetValue <= 999)
      return `${`${+resetValue}`.substr(0, 1)}.${`${+resetValue}`.substr(1, 2)}`;
    else
      return `${`${+resetValue}`.substr(0, resetValue.length - 2)}.${`${+resetValue}`.substr(resetValue.length - 2, 2)}`;
  } else {
    return `0.00`;
  }
}