/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Status of the wallet. ACTIVE:Can be used, LOCKED:Is blocked from use but can be customer controlled. CANCELED is deprecated. CANCELLED is in use, BARRED: Wallet cannot be used due to possible fraud or similar investigation
 * @export
 * @enum {string}
 */
export enum WalletStatus {
    ACTIVE = 'ACTIVE',
    BARRED = 'BARRED',
    CANCELED = 'CANCELED',
    CANCELLED = 'CANCELLED',
    LOCKED = 'LOCKED',
    PENDING = 'PENDING'
}

