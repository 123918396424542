import React, { useCallback, useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { primaryColor, primaryIconColor } from "../../style.scss";
import Danger from "../../components/Typography/Danger";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { AAuthenticationApi } from "eclipse-react-sdk/services/apis/aauthentication-api";
import { ZGlobalFunctionalityApi } from "../../eclipse-react-sdk/services";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";
import { ReactComponent as ResetPasswordIcon } from '../../assets/img/icon_reset_passcode.svg'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { ReturnStoredTemplateId } from "../../get-template-hostname.js";
import { EnvConfig } from "../../environment.js";
import { AuthRouteWithTemplateId } from "../../default-route.js";
import { load } from "recaptcha-v3";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function ChangePasswordPIN(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const passwordPINControlName = passwordPINText.toLowerCase();
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const passwordPINInputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINInputControlGridLength = 12 / passwordPINInputControls;
  const isForPaymentPinChange = useLocation().state?.isForPaymentPinChange
  const userIdentity = props?.appData?.identity;

  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const passwordClasses = passwordStyles();
  const history = useHistory();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(passwordPINControlName, passwordPINInputControls) });
  const [errors, setErrors] = useState({});
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToCreateNewPasswordPIN = () => {
    if (isForPaymentPinChange) {
      history.push({ pathname: '/pages/change-create-payment-pin', state: { isForPaymentPinChange } });
    } else {
      history.push({ pathname: '/pages/change-create-password', state: { isForPaymentPinChange } });
    }
  }

  const backToProfile = useCallback(() => {
    history.push({
      pathname: `/pages/user`
    });
  }, [history]);

  React.useEffect(() => {
    window.onpopstate = () => {
      backToProfile();
    }
  }, [history, backToProfile])

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const handleChangePasswordPINFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateChangePasswordPINForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    const identityPrefixToUse = isForPaymentPinChange ? "PAYATPAYMENTPIN" : identityPrefix;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const changePasswordPINAPI = new AAuthenticationApi(HttpRequestConfiguration(" "));
      const changePasswordPINBody = {
        identity: `${identityPrefixToUse}-${props?.appData?.identity}`,
        password: ReturnInputControlsConcatValues(inputs, passwordPINControlName, passwordPINInputControls)
      }

      changePasswordPINAPI.authenticationLoginPost(changePasswordPINBody).then((authAPIResponse) => {
        props.appData.auth = authAPIResponse.data;
        props.appData.isRefresh = true;
        props.changePasswordPINHandler({ ...props.appData });
        setLoading(false);
        redirectToCreateNewPasswordPIN();
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen(`/pages/user`, `/pages/error`);
        }
      });
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, passwordPINInputControls);
    setInputs(inputControlValue);
    const validationErrors = validateChangePasswordPINForm();
    setErrors(validationErrors);
  }


  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateChangePasswordPINForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, passwordPINInputControls);
        setInputs(inputControlValue);
        const validationErrors = validateChangePasswordPINForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateChangePasswordPINForm = () => {
    return ValidateInputControls(inputs, passwordPINControlName, passwordPINInputControls, passwordPINText, pinPasswordLength)
  }

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [props, history]);

  const redirectToResetPaymentPIN = (userIdentity) => {
    history.push({
      pathname: '/pages/reset-payment-pin',
      state: { userIdentity, fromScreen: 'USERPROFILE' }
    });
  }

  const generateOTP = (event) => {
    if (event) event.preventDefault();
    setLoading(true);
    load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
      reCaptcha.execute('OTP_VERIFICATION').then((token) => {
        if (token) {
          const headers = { gCaptcha: token };
          const httpRequestConfiguration = HttpRequestConfiguration();
          const resetPasswordAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
          httpRequestConfiguration.baseOptions = { headers };
          resetPasswordAPI.globalIdentitiesIdentityPasswordChangeInitPost(`${identityPrefix}PAYMENTPIN-${userIdentity}`).then(() => {
            redirectToResetPaymentPIN(userIdentity);
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen(`/pages/user`, `error`);
            }
          });
        } else {
          showAlert(true, 'ERROR', `Please try again!`);
        }
      })
    });
  }

  const actionReset = (event) => {
    if (!isForPaymentPinChange) {
      logout();
    } else {
      generateOTP(event);
    }
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToProfile} />
              <span className={classes.title}>{isForPaymentPinChange ? "Reset payment PIN" : "Reset my " + passwordPINText}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
          <p className={classes.textCenter + ' m-t-0 m-b-0'} style={{ fontSize: '20px' }}><b>Change your {isForPaymentPinChange ? "payment PIN" : passwordPINText}</b></p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginBottom: '-25px' }}>
          <ResetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleChangePasswordPINFormSubmit}>
            <GridContainer>
              {passwordPINInputControls > 1 ?
                <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                  <p className={classes.regularFontSize} style={{ marginBottom: '10px' }}><strong>{isForPaymentPinChange ? "Enter your old payment PIN" : "Enter your old " + passwordPINText}</strong></p>
                </GridItem>
                : null}

              {ReturnInputControlsMap(passwordPINControlName, passwordPINInputControls)?.map((control, index, array) => {
                return (
                  <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength} custom-padding={passwordPINInputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                    <ValidationTextField
                      inputProps={{ maxLength: passwordPINInputControls > 1 ? 1 : 50, inputMode: pinAsPassword ? 'numeric' : '' }}
                      className={passwordPINInputControls > 1 ? passwordClasses.oneDigitPassword : ''}
                      label={passwordPINInputControls > 1 ? '' : isForPaymentPinChange ? "Enter your old payment PIN" : 'Enter your old ' + passwordPINText}
                      autoComplete="new-password"
                      fullWidth
                      variant="filled"
                      id={control}
                      type="password"
                      error={!!(errors[passwordPINControlName])}
                      name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                      value={inputs[control] || ""}
                      onChange={handleInputChange}
                      onKeyDown={handleOnKeyDown}
                      onKeyUp={handleInputKeyUp} />
                  </GridItem>
                )
              })
              }
              <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                {errors[passwordPINControlName] && <Danger><small>{errors[passwordPINControlName]}</small></Danger>}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} m={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors.identity || errors[passwordPINControlName]) || !ReturnInputControlsConcatValues(inputs, passwordPINControlName, passwordPINInputControls)}>Next</Button>
                <p className={classes.textCenter}> {isForPaymentPinChange ? "Forgot your PIN?" : "Forgot your passcode?"} <span className={classes.linkColor} onClick={actionReset}>Reset it here.</span></p>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  changePasswordPINHandler: data => dispatch(loginToApp(data)),
  logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPIN)
