import { LOGIN, LOGOUT } from "../constants"

export const loginToApp = (data) => {
    // console.log('Action :: ', data);
    if(data.auth){
        const encodedString = btoa(JSON.stringify(data));
        localStorage.setItem('appstate', encodedString);
    }
    return {
        type: LOGIN,
        data: data
    }
}

export const logoutFromApp = (data) => {
    localStorage.removeItem('session-time');
    localStorage.removeItem('appstate');
    return {
        type: LOGOUT,
        data: data
    }
}