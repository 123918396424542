import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { ReactComponent as SuccessTransactionIcon } from '../../assets/img/success_transfer_wallet.svg'
import { ReactComponent as PaymentFailed } from '../../assets/img/payment_failed.svg'
import { loginToApp } from "../../redux-services/actions/actions.js";
import { SpinnerComponent } from "react-element-spinner";
import { QPaymentsApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles(themeStyle);

const delay = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function VASPaymentStatus(props) {
  const [paymentData, setPaymentData] = useState(props?.appData?.paymentData);
  const [requestCount, setRequestCount] = useState(0);
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [alertData, setAlert] = useState(null);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const handleSubmit = () => {
    props.appData.fromScreen = null;
    props.appData.paymentData = null;
    props.appData.isRefresh = true;
    props.vasPaymentStatusHandler({ ...props?.appData });
    history.push({
      pathname: '/pages/dashboard',
    });
  }

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const getPaymentStatus = useCallback((paymentData) => {
    if (requestCount > 9 || paymentData?.status !== 'PENDING') {
      if(requestCount > 9){
        const updatedPaymentData = {
          ...paymentData,
          status: 'TIMEDOUT'
        }
        setPaymentData(updatedPaymentData);
      }
      setLoading(false);
      return;
    }
    if (requestCount < 10 && paymentData && paymentData?.paymentId && paymentData?.status === 'PENDING') {
      const walletCardPaymentAPI = new QPaymentsApi();
      walletCardPaymentAPI.tenantsTenantIdPaymentsPaymentIdGet(props?.appData?.auth?.tenantId, paymentData?.paymentId).then(async (walletCardPaymentAPIResponse) => {
        if (walletCardPaymentAPIResponse.data && walletCardPaymentAPIResponse.data?.paymentId && walletCardPaymentAPIResponse.data?.status) {
          if (walletCardPaymentAPIResponse.data?.status === 'PENDING') {
            await delay(3000);
            setRequestCount(requestCount + 1);
          }else{
            setPaymentData(walletCardPaymentAPIResponse.data);
          }
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    } else {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    }
  }, [props, redirectToErrorScreen, setPaymentData, requestCount, setRequestCount]);

  React.useEffect(() => {
    getPaymentStatus(paymentData);
  }, [getPaymentStatus, paymentData]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              {paymentData?.status === 'SUCCESSFUL' ? <SuccessTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} /> : null}
              {paymentData?.status === 'PENDING' ? <HourglassEmptyIcon fill={primaryIconColor} stroke={primaryIconColor} /> : null}
              {paymentData?.status !== 'SUCCESSFUL' && paymentData?.status !== 'PENDING' ? <PaymentFailed fill={primaryIconColor} stroke={primaryIconColor} /> : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0'}>
                <b>
                  {paymentData?.status === 'SUCCESSFUL' ? 'Payment successful!' : null}
                  {paymentData?.status === 'PENDING' ? 'Pleas wait, we are processing...' : null}
                  {paymentData?.status === 'TIMEDOUT' ? 'We are experiencing an issue while processing your payment. Please try again later.' : null}
                  {paymentData?.status !== 'SUCCESSFUL' && paymentData?.status !== 'PENDING' && paymentData?.status !== 'TIMEDOUT' ? 'Oops! Payment failed!' : null}
                </b>
              </h4>
              {paymentData?.status !== 'SUCCESSFUL' && paymentData?.status !== 'PENDING' ?
                <p style={{ overflow: 'auto' }}>
                  {paymentData?.errorDescription}
                </p>
                : null
              }
              {paymentData?.status === 'SUCCESSFUL' ?
                <div>
                  <h2 style={{ color: primaryColor, margin: '0' }} className={classes.textCenter}><b>Thank you!</b></h2>
                </div>
                : null
              }
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>
                {paymentData?.status === 'SUCCESSFUL' ? 'Done' : null}
                {paymentData?.status === 'PENDING' ? 'Wait...' : null}
                {paymentData?.status !== 'SUCCESSFUL' && paymentData?.status !== 'PENDING' ? 'Back' : null}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasPaymentStatusHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASPaymentStatus)