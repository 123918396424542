import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { primaryColor, primaryIconColor, controlLabelColor } from "../../../style.scss";
import { loginToApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import cameraIcon from "../../../assets/img/camera.svg"
import { ReactComponent as PhotoFrame } from "../../../assets/img/photo_frame.svg"
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import galleryIcon from "../../../assets/img/gallery.svg"
import { Camera } from "react-camera-pro";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles(themeStyle);

export function ScanDocument() {
  const pageStates = useLocation().state;
  const selectedDocument = useLocation().state?.selectedDoc;
  const document = useLocation().state?.document;
  const allowedFiles = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"
  ];
  const idDocument = useLocation().state?.idDocument;

  const idDocumentBack = useLocation().state?.idDocumentBack;
  const isBack = useLocation().state?.isBack || false;
  const selfieDocument = useLocation().state?.selfieDocument;
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  const [idDocumentBackImageData, setIdDocumentBackImageData] = useState(useLocation().state?.idDocumentBackImageData);
  const [idDocumentImageData, setIdDocumentImageData] = useState(useLocation().state?.idDocumentImageData);
  const [alertData, setAlert] = useState(null);
  const [developModeEventClick, setDevelopModeEventClick] = useState(0);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const webcamRef = React.useRef(null);
  const showAlert = useCallback((alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }, [])

  const redirectToIdDocumentUpload = useCallback(() => {
    history.push({
      pathname: `/pages/upload-document`,
      state: { isAddressAdded, fromScreen, selectedDoc: selectedDocument, document, idDocument, idDocumentBack, selfieDocument, idDocumentImageData, idDocumentBackImageData, selfieDocumentImageData, isBack },
    });
  }, [history, isAddressAdded, fromScreen, selectedDocument, document, idDocument, idDocumentBack, selfieDocument, idDocumentImageData, selfieDocumentImageData, isBack, idDocumentBackImageData]);

  const capture = useCallback(() => {
    if (isBack) {
      setIdDocumentBackImageData(webcamRef.current.takePhoto());
    } else {
      setIdDocumentImageData(webcamRef.current.takePhoto());
    }
    if (idDocumentImageData || idDocumentBackImageData) {
      redirectToIdDocumentUpload();
    }
  }, [webcamRef, redirectToIdDocumentUpload, idDocumentImageData, idDocumentBackImageData, isBack]);

  const checkFileExtension = useCallback((fileType) => {
    let allowed = false;
    allowedFiles.forEach(extension => {
      if (extension === fileType) {
        allowed = true;
      }
    });
    return allowed;
  }, [allowedFiles])

  const readUrl = useCallback((event) => {
    const reader = new FileReader();
    if (!event?.target?.files[0].type) {
      showAlert(true, 'ERROR', `Error while upload image. Please try again.`);
      return;
    }
    const fileType = event.target.files[0].type;
    if (checkFileExtension(fileType)) {
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          if (isBack) {
            setIdDocumentBackImageData(reader.result.toString());
          } else {
            setIdDocumentImageData(reader.result.toString());
          }

          if ((idDocumentImageData && !isBack) || (idDocumentBackImageData && isBack)) {
            redirectToIdDocumentUpload();
          }
        }
      } else {
        showAlert(true, 'ERROR', `Something went wrong. Please try again.`);
      }
    } else {
      showAlert(true, 'ERROR', `Error while reading image type. Please try again.`);
    }
  }, [redirectToIdDocumentUpload, showAlert, checkFileExtension, idDocumentImageData, idDocumentBackImageData, isBack]);

  React.useEffect(() => {
    if ((idDocumentImageData && !isBack) || (idDocumentBackImageData && isBack)) {
      redirectToIdDocumentUpload();
    }
  }, [redirectToIdDocumentUpload, idDocumentImageData, idDocumentBackImageData, isBack]);

  const goBack = useCallback(() => {
    history.push({
      pathname: '/pages/identity-document',
      state: {
        fromScreen,
        isAddressAdded,
        ...pageStates
      }
    });
  }, [history, fromScreen, isAddressAdded, pageStates])

  React.useEffect(() => {
    window.onpopstate=()=>{
      goBack();
    }
  }, [history, goBack]);

  const developMode = useCallback(() => {
    if (window.REACT_APP_ENV !== 'prod') {
      if (developModeEventClick === 15) {
        setDevelopModeEventClick(0);
        setShowUploadDocument(true);
        return;
      }
      setDevelopModeEventClick(developModeEventClick + 1)
    }
  }, [developModeEventClick]);

  return (
    <div>
      {(!idDocumentImageData && !idDocumentBackImageData && history.action !== "POP" )?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={'progress-bar'}>
              <div style={{display: 'flex',alignItems: 'center'}}>
                <p onClick={goBack} style={{display: 'flex',alignItems: 'center', fontSize:18}}>
                  <ArrowBackIosIcon />
                  <span >{"Back"}</span>
                </p>
                <small style={{ color: controlLabelColor,marginLeft:"auto" }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 3 : 2} of 5</small>
              </div>
              <strong><small style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '60%' } : { width: '40%' }}>&nbsp;</small></strong>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ margin: '60px 0 30px 0' }}>
                <div onClick={developMode} style={{ position: 'relative', height: '200px', width: '325px', margin: "auto", maxWidth: '100%' }} className="document-area selfie-area">
                  <PhotoFrame fill={primaryIconColor} stroke={primaryIconColor} style={{ position: 'absolute', left: '0', zIndex: '1', top: '0', height: '100%', objectFit: 'cover', width: '100%' }} />
                  <Camera
                    ref={webcamRef}
                    facingMode={"environment"}
                    aspectRatio={16 / 9}
                    style={{ position: 'static' }}
                    errorMessages={""}/>

                </div>
              </GridItem>
            </GridContainer>
            {showUploadDocument ?
            <GridContainer>
              <GridItem xs={6} m={6} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="file-upload" className={classes.buttonRadius + ' roundButton'} style={{ display: 'flex', alignSelf: 'center', padding: '1.125rem 2.25rem', background: primaryColor, margin: '0.5em' }}>
                  <img style={{ height: '30px', width: '30px' }} src={galleryIcon} alt="" />
                </label>
                <input style={{ display: 'none' }} id="file-upload" type="file" onChange={readUrl} size="1" accept=".png,.bmp,.jpeg,.jpg" />
              </GridItem>
              <GridItem xs={6} m={6} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Button className={classes.buttonRadius + ' roundButton'} style={{ display: 'flex', alignSelf: 'center' }} size="lg" color="primary" onClick={capture}><img src={cameraIcon} alt="" /></Button>
              </GridItem>
            </GridContainer>
            :
            <GridContainer>
              <GridItem xs={12} m={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Button className={classes.buttonRadius + ' roundButton'} style={{ display: 'flex', alignSelf: 'center' }} size="lg" color="primary" onClick={capture}><img src={cameraIcon} alt="" /></Button>
              </GridItem>
            </GridContainer>
            }
          </GridItem>
        </GridContainer>
        : null}
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  scanDocumentHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScanDocument)
