import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { controlLabelColor, primaryColor } from "../../style.scss";
import Danger from "../../components/Typography/Danger";
import { loginToApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { DCustomerManagementApi } from "eclipse-react-sdk/services/apis/dcustomer-management-api";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { v4 as uuidv4 } from 'uuid';
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { EclipseTitle, Gender, MaritalStatus, OrganisationPosition } from "../../eclipse-react-sdk/services/models";
import { AAuthenticationApi, BTenantConfigurationOnboardingApi } from "../../eclipse-react-sdk/services"

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function AssistedSignupCreateUsername(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const phoneNumberAsUsername = props?.appData?.template?.pwaAppOptions?.phoneNumberAsUsername;
  const defaultUsernameRegEx = props?.appData?.template?.defaultUsernameRegEx;
  const [isLoading, setLoading] = React.useState(false);
  const mobileNumber = useLocation().state?.mobileNumber;
  const tenantToken = useLocation().state?.tenantToken;
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = useState({ userName: phoneNumberAsUsername ? mobileNumber : '' });
  const [errors, setErrors] = useState({});
  const [alertData, setAlert] = useState(null);
  const manifestSettings = props?.appData?.template?.manifestSettings;

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const backToSignup = useCallback(() => {
    history.push({
      pathname: '/pages/assisted-signup',
    });
  }, [history]);

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        backToSignup();
      }
    };
  }, [backToSignup])

  const goToRatify = useCallback(() => {
    history.push({
      pathname: '/pages/identity-document',
      state: { fromScreen: "ASSISTED_SIGNUP", isAddressAdded: false }
    });
  }, [history]);

  const showAlert = useCallback((alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
      if (alertType === 'SUCCESS') {
        goToRatify();
      }
    }, 3000);
  }, [goToRatify]);

  const sendCredentialSMS = useCallback((tenantId, username, password, tenantHeader) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const SMSNotificationAPI = new BTenantConfigurationOnboardingApi(httpRequestConfiguration);
    const contentData = {
      username, password,
      phone: mobileNumber,
      brand: manifestSettings?.brand,
      link: props?.appData?.template?.defaultPWALoginLink,
    }
    const SMSNotificationData = {
      data: JSON.stringify(contentData),
      templateId: 'auth',
      type: 'SMS'
    }

    SMSNotificationAPI.tenantsTenantIdNotificationsPost(tenantId, SMSNotificationData).then(() => {
      if(props?.appData?.reKYCCustomer.length > 0) {
        goToRatify();
      } else {
        setLoading(false)
        showAlert(true, 'SUCCESS', `Customer's profile has been successfully setup!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [mobileNumber, redirectToErrorScreen, showAlert, props, manifestSettings, goToRatify])

  const getAssistedUserToken = useCallback((assistedCustomer, loginBody) => {
    const loginAPI = new AAuthenticationApi(HttpRequestConfiguration());
    loginAPI.authenticationLoginPost(loginBody).then((authAPIResponse) => {
      const auth = { assistedUserToken: authAPIResponse?.data?.headerValue };
      assistedCustomer = { ...assistedCustomer, ...auth, ...loginBody };
      const appData = {
        ...props?.appData,
        assistedCustomer
      }
      props.createUsernameHandler({ ...appData });
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, showAlert, redirectToErrorScreen]);

  const goToCreatePassword = useCallback((assistedCustomer, tenantId, tenantHeader) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    const identity = `${identityPrefix}-${inputs?.userName}`;
    const password = Math.random().toString(36).slice(`-${props?.appData?.template?.pwaAppOptions?.pinPasswordLength}`);
    const changePasswordBody = { identity, password }
    customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId, assistedCustomer.customerId, changePasswordBody).then(() => {
      getAssistedUserToken(assistedCustomer, { identity });
      sendCredentialSMS(tenantId, `${inputs?.userName}`, changePasswordBody.password, tenantHeader);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [inputs, identityPrefix, props, redirectToErrorScreen, showAlert, sendCredentialSMS, getAssistedUserToken]);

  const createUser = useCallback((tenantId, tenantHeader, organisationId) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersGet(tenantId, undefined, undefined, undefined, undefined, `${identityPrefix}-${inputs?.userName}`).then((getCustomerAPIResponse) => {
      if (getCustomerAPIResponse.data.length === 0) {
        const registerUserBody = {
          externalUniqueId: uuidv4(),
          gender: Gender.M,
          title: EclipseTitle.MR,
          maritalStatus: MaritalStatus.M,
          dateOfBirth: '19900101',
          phone1: mobileNumber
        }
        customerAPI.tenantsTenantIdCustomersPost(tenantId, registerUserBody).then((customerAPIResponse) => {

          const registerUserPositionBody = {
            organisationId: organisationId,
            position: OrganisationPosition.MEMBER,
          }
          customerAPI.tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId, customerAPIResponse?.data?.customerId, registerUserPositionBody).then((customerPositionAPIResponse) => {
            goToCreatePassword(customerAPIResponse?.data, tenantId, tenantHeader);
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
          })
        }).catch((error) => {
          setLoading(false);
          if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
            showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
          } else {
            redirectToErrorScreen('/pages/dashboard', '/pages/error');
          }
        })
      } else {
        if (phoneNumberAsUsername) {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        } else {
          setLoading(false);
          showAlert(true, 'ERROR', `The username has already been used!`);
        }
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [redirectToErrorScreen, goToCreatePassword, phoneNumberAsUsername, inputs, mobileNumber, showAlert, identityPrefix]);

  const goToUpdatePassword = useCallback((assistedCustomer, tenantId, tenantHeader) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId, assistedCustomer?.customerId).then((identities) => {
      const identity = identities.data[0].identity;
      const password = Math.random().toString(36).slice(`-${props?.appData?.template?.pwaAppOptions?.pinPasswordLength}`);
      const changePasswordBody = { password }
      customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId, assistedCustomer?.customerId,identity, changePasswordBody).then(() => {
        getAssistedUserToken(assistedCustomer, { identity });
        sendCredentialSMS(tenantId, changePasswordBody.identity, changePasswordBody.password, tenantHeader);
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    }).catch(() => {
      redirectToErrorScreen(`/pages/dashboard`, '/pages/error');
    });
  }, [props, redirectToErrorScreen, showAlert, sendCredentialSMS, getAssistedUserToken]);


  React.useEffect(() => {
    if (phoneNumberAsUsername) {
      const headers = { Authorization: tenantToken?.Authorization };
      if(props?.appData?.reKYCCustomer.length > 0) {
        setLoading(true);
        goToUpdatePassword(props?.appData?.reKYCCustomer[0], props?.appData?.auth?.tenantId, headers)
      } else {
        createUser(props?.appData?.auth?.tenantId, headers, props?.appData?.organisationId);
      }
    }
  }, [createUser, phoneNumberAsUsername, props, goToUpdatePassword, tenantToken]);

  React.useEffect(() => {
    if(props?.appData?.reKYCCustomer.length > 0) {
      const headers = { Authorization: tenantToken?.Authorization };
      setLoading(true);
      goToUpdatePassword(props?.appData?.reKYCCustomer[0], props?.appData?.auth?.tenantId, headers)
    }
  }, [goToUpdatePassword, props, tenantToken])

  const handleUserNameFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateUserNameForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      const headers = { Authorization: tenantToken?.Authorization };
      createUser(props?.appData?.auth?.tenantId, headers, props?.appData?.organisationId);
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateUserNameForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateUserNameForm(inputs);
    setErrors(validationErrors);
  }

  const validateUserNameForm = (inputs) => {
    const errors = {};
    const pattern = new RegExp(defaultUsernameRegEx);
    if (!inputs.userName) {
      errors.userName = 'Username required!'
    } else if (!pattern.test(inputs.userName)) {
      errors.userName = 'Username is invalid!'
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      {!isLoading ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <div className={'progress-bar'}>
                  <small style={{ color: controlLabelColor }}>Step 1 of 5</small>
                  <strong><small style={{ width: '20%' }}>&nbsp;</small></strong>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <form onSubmit={handleUserNameFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h1>Set up the customer's account</h1>
                  <p className={classes.regularFontSize}><strong>Create a username for the customer.</strong></p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ValidationTextField
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    label="Create the customer's username."
                    variant="filled"
                    id="userName"
                    type="text"
                    error={!!(errors.userName)}
                    name="userName"
                    value={inputs.userName}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp}/>
                  {errors.userName && <Danger><small>{errors.userName}</small></Danger>}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} m={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                          disabled={!!(errors.userName)}>Next</Button>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter}><span className={classes.linkColor}
                                                                                          onClick={backToSignup}>Cancel</span>
                  </p>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
        : null}
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  createUsernameHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedSignupCreateUsername)
