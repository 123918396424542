import React, {useCallback} from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions.js";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import genericError from '../../assets/img/genericError.gif'
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { AuthRouteWithTemplateId } from "../../default-route";

const useStyles = makeStyles(themeStyle);

export function RatifyKYCError(props) {
  //console.log("Ratify KYC Error props :: ", props);
  const ratifyProcessData = useLocation().state?.ratifyProcessData;
  //console.log("Ratify Process Data :: ", ratifyProcessData);
  const selectedDocument = useLocation().state?.selectedDoc;
  //console.log("Selected Document :: ", selectedDocument);

  const history = useHistory();
  const classes = useStyles();

  // const ratifyTrialCount = props?.appData?.template?.ratifyTrialCount;
  const isDocumentUploaded = true;
  const isSelfieWithThumbUpUploaded = ratifyProcessData?.selfieIsASelfie;
  const isSelfieMatchedWithIdentity = (selectedDocument === '1' || selectedDocument === '3') ? ratifyProcessData?.selfieMatchesPassport : ratifyProcessData?.selfieMatchesNationalIdentity;
  const isInformationMatchedWithIdentity = (selectedDocument === '1' || selectedDocument === '3') ? (ratifyProcessData?.firstNameMatchesPassport && ratifyProcessData?.lastNameMatchesPassport && ratifyProcessData?.passportNumberMatchesPassport && ratifyProcessData?.selfieMatchesPassport) : (ratifyProcessData?.firstNameMatchesNationalIdentity && ratifyProcessData?.lastNameMatchesNationalIdentity && ratifyProcessData?.identityNumberMatchesNationalIdentity && ratifyProcessData?.selfieMatchesNationalIdentity);

  // const tryAgain = () => {
  //   history.push({
  //     pathname: `/auth/identity-document`
  //   });
  // };

  // const goToContactSupport = () => {
  //   history.push({
  //     pathname: `/pages/contact-support`
  //   });
  // }

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  },[props,history]);

  React.useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        logout();
      }
    };
  }, [history,logout])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span style={{ float: 'left', width: '100%', textAlign: 'center', margin: '-80px 0 0 0' }}><img src={genericError} width="200" alt="" /></span>
              <p className={classes.title + ' ' + classes.textCenter + ' m-b-0'}><b>Something doesn't look right.</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Please check your information and try again.</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isDocumentUploaded ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Identity document uploaded.
              </p>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isSelfieWithThumbUpUploaded ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Selfie with thumbs up uploaded.
              </p>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isSelfieMatchedWithIdentity ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Selfie match document's photo.
              </p>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isInformationMatchedWithIdentity ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Personal information match document.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={logout} >Try again</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  ratifyKYCErrorHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RatifyKYCError)