/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EclipsePaymentRefund } from '../models';
import { ExceptionData } from '../models';
import { NewEclipsePayment } from '../models';
import { PaymentRefundResult } from '../models';
import { PaymentResult } from '../models';
import { UpdatedPaymentTransactionData } from '../models';
/**
 * QPaymentsApi - axios parameter creator
 * @export
 */
export const QPaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The status details of a payment which may be in progress or complete
         * @summary Get the details of a payment
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdGet: async (tenantId: number, paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdGet.');
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/payments/{paymentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * If some fields are missing then a 400 will be returned.If all is ok to proceed then the data required to complete the payment will be returned. Depending on the payment type, only one update may be allowed so gather all required info before updating the payment. The status field can be used to cancel or reverse a payment (gateway dependent)
         * @summary Update necessary fields of a payment
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {UpdatedPaymentTransactionData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdPut: async (tenantId: number, paymentId: number, body?: UpdatedPaymentTransactionData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdPut.');
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/payments/{paymentId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a refunds info of payment by paymentId
         * @summary Get payment refund information.
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdRefundsGet: async (tenantId: number, paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsGet.');
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/payments/{paymentId}/refunds`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes a refund. The amount cannot exceed the original amount
         * @summary Refund a portion or entire payment amount
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {EclipsePaymentRefund} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdRefundsPost: async (tenantId: number, paymentId: number, body?: EclipsePaymentRefund, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsPost.');
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/payments/{paymentId}/refunds`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a refunds info by providing the refundId
         * @summary Get payment refund information.
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {number} refundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet: async (tenantId: number, paymentId: number, refundId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet.');
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet.');
            }
            // verify required parameter 'refundId' is not null or undefined
            if (refundId === null || refundId === undefined) {
                throw new RequiredError('refundId','Required parameter refundId was null or undefined when calling tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/payments/{paymentId}/refunds/{refundId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)))
                .replace(`{${"refundId"}}`, encodeURIComponent(String(refundId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts the process of making a payment. The return result and flow depend on the payment type. E.g. it could be a mult-step process with customer interaction or a simple fire and forget payment.
         * @summary Initiate a new payment
         * @param {number} tenantId 
         * @param {NewEclipsePayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPost: async (tenantId: number, body?: NewEclipsePayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdPaymentsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/payments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QPaymentsApi - functional programming interface
 * @export
 */
export const QPaymentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * The status details of a payment which may be in progress or complete
         * @summary Get the details of a payment
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPaymentsPaymentIdGet(tenantId: number, paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResult>> {
            const localVarAxiosArgs = await QPaymentsApiAxiosParamCreator(configuration).tenantsTenantIdPaymentsPaymentIdGet(tenantId, paymentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * If some fields are missing then a 400 will be returned.If all is ok to proceed then the data required to complete the payment will be returned. Depending on the payment type, only one update may be allowed so gather all required info before updating the payment. The status field can be used to cancel or reverse a payment (gateway dependent)
         * @summary Update necessary fields of a payment
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {UpdatedPaymentTransactionData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPaymentsPaymentIdPut(tenantId: number, paymentId: number, body?: UpdatedPaymentTransactionData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResult>> {
            const localVarAxiosArgs = await QPaymentsApiAxiosParamCreator(configuration).tenantsTenantIdPaymentsPaymentIdPut(tenantId, paymentId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a refunds info of payment by paymentId
         * @summary Get payment refund information.
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPaymentsPaymentIdRefundsGet(tenantId: number, paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRefundResult>> {
            const localVarAxiosArgs = await QPaymentsApiAxiosParamCreator(configuration).tenantsTenantIdPaymentsPaymentIdRefundsGet(tenantId, paymentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Processes a refund. The amount cannot exceed the original amount
         * @summary Refund a portion or entire payment amount
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {EclipsePaymentRefund} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPaymentsPaymentIdRefundsPost(tenantId: number, paymentId: number, body?: EclipsePaymentRefund, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRefundResult>> {
            const localVarAxiosArgs = await QPaymentsApiAxiosParamCreator(configuration).tenantsTenantIdPaymentsPaymentIdRefundsPost(tenantId, paymentId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a refunds info by providing the refundId
         * @summary Get payment refund information.
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {number} refundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet(tenantId: number, paymentId: number, refundId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRefundResult>> {
            const localVarAxiosArgs = await QPaymentsApiAxiosParamCreator(configuration).tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet(tenantId, paymentId, refundId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Starts the process of making a payment. The return result and flow depend on the payment type. E.g. it could be a mult-step process with customer interaction or a simple fire and forget payment.
         * @summary Initiate a new payment
         * @param {number} tenantId 
         * @param {NewEclipsePayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdPaymentsPost(tenantId: number, body?: NewEclipsePayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResult>> {
            const localVarAxiosArgs = await QPaymentsApiAxiosParamCreator(configuration).tenantsTenantIdPaymentsPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * QPaymentsApi - factory interface
 * @export
 */
export const QPaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * The status details of a payment which may be in progress or complete
         * @summary Get the details of a payment
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdGet(tenantId: number, paymentId: number, options?: any): AxiosPromise<PaymentResult> {
            return QPaymentsApiFp(configuration).tenantsTenantIdPaymentsPaymentIdGet(tenantId, paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * If some fields are missing then a 400 will be returned.If all is ok to proceed then the data required to complete the payment will be returned. Depending on the payment type, only one update may be allowed so gather all required info before updating the payment. The status field can be used to cancel or reverse a payment (gateway dependent)
         * @summary Update necessary fields of a payment
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {UpdatedPaymentTransactionData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdPut(tenantId: number, paymentId: number, body?: UpdatedPaymentTransactionData, options?: any): AxiosPromise<PaymentResult> {
            return QPaymentsApiFp(configuration).tenantsTenantIdPaymentsPaymentIdPut(tenantId, paymentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a refunds info of payment by paymentId
         * @summary Get payment refund information.
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdRefundsGet(tenantId: number, paymentId: number, options?: any): AxiosPromise<PaymentRefundResult> {
            return QPaymentsApiFp(configuration).tenantsTenantIdPaymentsPaymentIdRefundsGet(tenantId, paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes a refund. The amount cannot exceed the original amount
         * @summary Refund a portion or entire payment amount
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {EclipsePaymentRefund} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdRefundsPost(tenantId: number, paymentId: number, body?: EclipsePaymentRefund, options?: any): AxiosPromise<PaymentRefundResult> {
            return QPaymentsApiFp(configuration).tenantsTenantIdPaymentsPaymentIdRefundsPost(tenantId, paymentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a refunds info by providing the refundId
         * @summary Get payment refund information.
         * @param {number} tenantId 
         * @param {number} paymentId 
         * @param {number} refundId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet(tenantId: number, paymentId: number, refundId: number, options?: any): AxiosPromise<PaymentRefundResult> {
            return QPaymentsApiFp(configuration).tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet(tenantId, paymentId, refundId, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts the process of making a payment. The return result and flow depend on the payment type. E.g. it could be a mult-step process with customer interaction or a simple fire and forget payment.
         * @summary Initiate a new payment
         * @param {number} tenantId 
         * @param {NewEclipsePayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdPaymentsPost(tenantId: number, body?: NewEclipsePayment, options?: any): AxiosPromise<PaymentResult> {
            return QPaymentsApiFp(configuration).tenantsTenantIdPaymentsPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QPaymentsApi - object-oriented interface
 * @export
 * @class QPaymentsApi
 * @extends {BaseAPI}
 */
export class QPaymentsApi extends BaseAPI {
    /**
     * The status details of a payment which may be in progress or complete
     * @summary Get the details of a payment
     * @param {number} tenantId 
     * @param {number} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QPaymentsApi
     */
    public tenantsTenantIdPaymentsPaymentIdGet(tenantId: number, paymentId: number, options?: any) {
        return QPaymentsApiFp(this.configuration).tenantsTenantIdPaymentsPaymentIdGet(tenantId, paymentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * If some fields are missing then a 400 will be returned.If all is ok to proceed then the data required to complete the payment will be returned. Depending on the payment type, only one update may be allowed so gather all required info before updating the payment. The status field can be used to cancel or reverse a payment (gateway dependent)
     * @summary Update necessary fields of a payment
     * @param {number} tenantId 
     * @param {number} paymentId 
     * @param {UpdatedPaymentTransactionData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QPaymentsApi
     */
    public tenantsTenantIdPaymentsPaymentIdPut(tenantId: number, paymentId: number, body?: UpdatedPaymentTransactionData, options?: any) {
        return QPaymentsApiFp(this.configuration).tenantsTenantIdPaymentsPaymentIdPut(tenantId, paymentId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a refunds info of payment by paymentId
     * @summary Get payment refund information.
     * @param {number} tenantId 
     * @param {number} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QPaymentsApi
     */
    public tenantsTenantIdPaymentsPaymentIdRefundsGet(tenantId: number, paymentId: number, options?: any) {
        return QPaymentsApiFp(this.configuration).tenantsTenantIdPaymentsPaymentIdRefundsGet(tenantId, paymentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Processes a refund. The amount cannot exceed the original amount
     * @summary Refund a portion or entire payment amount
     * @param {number} tenantId 
     * @param {number} paymentId 
     * @param {EclipsePaymentRefund} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QPaymentsApi
     */
    public tenantsTenantIdPaymentsPaymentIdRefundsPost(tenantId: number, paymentId: number, body?: EclipsePaymentRefund, options?: any) {
        return QPaymentsApiFp(this.configuration).tenantsTenantIdPaymentsPaymentIdRefundsPost(tenantId, paymentId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a refunds info by providing the refundId
     * @summary Get payment refund information.
     * @param {number} tenantId 
     * @param {number} paymentId 
     * @param {number} refundId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QPaymentsApi
     */
    public tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet(tenantId: number, paymentId: number, refundId: number, options?: any) {
        return QPaymentsApiFp(this.configuration).tenantsTenantIdPaymentsPaymentIdRefundsRefundIdGet(tenantId, paymentId, refundId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Starts the process of making a payment. The return result and flow depend on the payment type. E.g. it could be a mult-step process with customer interaction or a simple fire and forget payment.
     * @summary Initiate a new payment
     * @param {number} tenantId 
     * @param {NewEclipsePayment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QPaymentsApi
     */
    public tenantsTenantIdPaymentsPost(tenantId: number, body?: NewEclipsePayment, options?: any) {
        return QPaymentsApiFp(this.configuration).tenantsTenantIdPaymentsPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
