import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SuccessResetPassword } from '../../assets/img/icon_add_a_card_success.svg';
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss"
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function SuccessPhysicalCardRegister(props) {
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.successPhysicalCardRegisterHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])
  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', margin: '50px 0 30px' }}>
                <SuccessResetPassword fill={primaryIconColor} stroke={primaryIconColor}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                   style={{ marginBottom: '30px', fontSize: '20px' }}>
                  <b>Your card was successfully registered!</b>
                </p>
                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                   style={{ fontSize: '16px' }}>
                  Next top up your card to start using it.
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary"
                        onClick={backToHome}>Done</Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successPhysicalCardRegisterHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPhysicalCardRegister)