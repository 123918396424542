import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as SuccessCardIcon } from "../../assets/img/visaSuccess.svg";
import { secondaryTextColor } from "../../style.scss"
import { loginToApp } from "../../redux-services/actions/actions.js";


const useStyles = makeStyles(themeStyle);

export function SuccessLinkCard(props) {
  const walletDetailData = props?.appData?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const pageState = useLocation().state;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const goBack = () => {
    if (fromScreen === 'AIRTIMEDATA' || fromScreen === 'BUYAIRTIMEDATA' || fromScreen === 'BUYELECTRICITY') {
      history.push({
        pathname: '/pages/vas-buy-airtime-data',
        state: { ...pageState }
      });
    } else if (fromScreen === 'COLLECTIONS') {
      history.push({
        pathname: '/pages/vas-buy-kganya-products',
        state: { ...pageState }
      });
    } else if (fromScreen === 'PAYBILL') {
      history.push({
        pathname: '/pages/vas-pay-bill',
        state: { ...pageState }
      });
    } else if (fromScreen === 'QRSCAN') {
      history.push({
        pathname: '/pages/pay-amount',
        state: { ...pageState }
      });
    } else if (fromScreen === 'TOPUP' && walletDetailData) {
      history.push({
        pathname: '/pages/topup',
        state: { walletDetailData }
      });
    } else {
      history.push({
        pathname: '/pages/manage-linked-cards',
        state: { walletDetailData, fromScreen }
      });
    }
  };

  const goToTopup = () => {
    props.appData.walletDetailData = null;
    props.successLinkCardHandler({ ...props?.appData });
    history.push({
      pathname: '/pages/topup',
      state: { walletDetailData, fromScreen }
    });
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.successLinkCardHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  React.useEffect(() => {
    return () => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToHome();
      }
    };
  }, [history,backToHome])

  return (
    <div>
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', marginBottom: 20, paddingTop: '30px' }}>
          <ArrowBackIcon className={classes.linkColor} onClick={goBack}/>
          <span className={classes.title}>Link a bank card</span>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <SuccessCardIcon/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0 m-b-0'} style={{ color:secondaryTextColor}}>
                <strong>Your card is now securely <br />linked and ready to use.</strong>
              </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              {walletDetailData ? <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={goToTopup}>Top-up my {walletTitleText.toLowerCase()}</Button> : null}
              <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={goBack}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successLinkCardHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessLinkCard)