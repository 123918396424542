import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { HWalletsApi, LWalletWithdrawalsApi, DCustomerManagementApi } from "eclipse-react-sdk/services";
import { successColor, dangerColor, grayColor } from "../../assets/jss/eclipse.js";
import { primaryColor, primaryTextColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { EclipsePaymentStatus, PaymentMechanism, WalletWithdrawalStatus } from "../../eclipse-react-sdk/services/models";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { ReactComponent as PaymentFailed } from "../../assets/img/payment_failed_history.svg";
import { ReactComponent as RightArrow } from "../../assets/img/arrow_right.svg";
import { ReactComponent as HistoryIcon } from "../../assets/img/history_icon.svg";
import { loginToApp } from "../../redux-services/actions/actions.js";
import moment from "moment";
import ReturnCardType from "../../components/PaymentOptions/ReturnCardType.js";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function TransactionHistory(props) {
    const wallets = props?.appData?.wallets;
    const tenantId = props?.appData?.auth?.tenantId;
    const customerId = props?.appData?.customer?.customerId;
    const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;

    const [value, setValue] = React.useState(wallets ? 0 : 1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            getCardTransactions();
        }
    };


    const currentDate = new Date();
    const toDate = new Date();
    const dateFromIncl = new Date(currentDate.setDate(new Date().getDate() - 16)).toISOString();
    const dateToExcl = new Date(toDate.setDate(new Date().getDate() + 2)).toISOString();

    const history = useHistory();
    const classes = useStyles();

    const [transactionsList, setTransactions] = useState([]);
    const [cardTransactionsList, setCardTransactions] = useState([]);
    const [isGetCardTransactionsList, setGetCardTransactionsList] = React.useState(false);
    const [eftWithdrawalTransactions, setEFTWithdrawalTransactions] = useState([]);
    const [inputs, setInputs] = useState({ fromWallet: '' });
    const [isLoading, setLoading] = React.useState(false);
    const [alertData, setAlert] = useState(null);

    const showAlert = (alertDisplay, alertType, message) => {
        setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const backToHome = useCallback( () => {
        const appData = {
            ...props?.appData,
            isRefresh: true
        }
        props.transactionHistoryHandler(appData);
        history.push({
            pathname: '/pages/dashboard',
        });
    },[history, props])

    const openReceipt = (transactionDetail) => {
        history.push({
            pathname: '/pages/transaction-receipt',
            state: { transactionDetail: transactionDetail }
        });
    }

    const returnEFTDetails = (jsonData) => {
        try {
            const data = JSON.parse(jsonData);
            return `Branch code::${data?.branchCode} A/c. no::${data?.accountNumber} A/c. name::${data?.accountName}`;
        } catch (error) {
            return '';
        }
    }

    const handleInputChange = (event) => {
        event.persist();
        inputs[event.target.name] = event.target.value;
        setInputs(inputs);
        getTransactions(event.target.value);
    }

    const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
        history.push({
            pathname: toScreen,
            state: { fromScreen: fromScreen }
        });
    }, [history]);

    const returnWholeMonth = useCallback((month) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        return months[month]
    }, []);

    const getTransactionsExtractData = useCallback((transactions, extractFiled) => {
        const transactionsGroups = {};
        const sectionGroups = [];
        const convertedTransactions = [];
        transactions.forEach((transaction) => {
            const convertDate = new Date(transaction[extractFiled]);
            convertedTransactions.push({
                ...transaction,
                monthYear: `${returnWholeMonth(convertDate.getUTCMonth())} ${convertDate.getUTCFullYear()}`
            })
        })

        convertedTransactions.forEach((transaction) => {
            const monthYear = transaction.monthYear;
            if (monthYear in transactionsGroups) {
                transactionsGroups[monthYear].push(transaction);
            } else {
                transactionsGroups[monthYear] = new Array(transaction);
            }
        });

        var transactionsGroupsKeys = Object.keys(transactionsGroups);
        transactionsGroupsKeys.forEach((transactionKey) => {
            sectionGroups.push(
                {
                    title: transactionKey,
                    data: transactionsGroups[transactionKey]
                }
            )
        });

        return sectionGroups;
    }, [returnWholeMonth]);

    const getEFTWithdrawalTransactions = useCallback((walletId) => {
        setLoading(true);
        const eftWithdrawalTransactionAPI = new LWalletWithdrawalsApi();
        eftWithdrawalTransactionAPI.tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId, walletId).then((eftWithdrawalTransactionAPIResponse) => {
            if (eftWithdrawalTransactionAPIResponse.data && eftWithdrawalTransactionAPIResponse.data.length > 0) {
                const pendingEFTData = eftWithdrawalTransactionAPIResponse.data.filter(data => data?.status === WalletWithdrawalStatus.PENDING);
                if (pendingEFTData && pendingEFTData.length > 0) {
                    setLoading(false);
                    setEFTWithdrawalTransactions(pendingEFTData);
                } else {
                    setLoading(false);
                    setEFTWithdrawalTransactions([]);
                }
            } else {
                setLoading(false);
                setEFTWithdrawalTransactions([]);
            }
        }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
                redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
        });
    }, [redirectToErrorScreen, tenantId]);

    const getTransactions = useCallback((walletId) => {
        setLoading(true);
        const customerWalletTransactionAPI = new HWalletsApi();
        customerWalletTransactionAPI.tenantsTenantIdWalletsWalletIdTransactionsGet(tenantId, walletId, dateFromIncl, dateToExcl).then((customerWalletTransactionAPIResponse) => {
            if (customerWalletTransactionAPIResponse.data && customerWalletTransactionAPIResponse.data.length > 0) {
                setLoading(false);
                const extractedData = getTransactionsExtractData(customerWalletTransactionAPIResponse.data, 'date');
                setTransactions(extractedData);
            } else {
                setLoading(false);
                setTransactions([]);
            }
            getEFTWithdrawalTransactions(walletId);
        }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
                redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
        });
    }, [tenantId, redirectToErrorScreen, dateFromIncl, dateToExcl, getTransactionsExtractData, getEFTWithdrawalTransactions]);

    const getCardTransactions = useCallback(() => {
        setGetCardTransactionsList(true);
        setLoading(true);
        const customerCardTransactionAPI = new DCustomerManagementApi();
        customerCardTransactionAPI.tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId, customerId, undefined, dateFromIncl, dateToExcl, 1000).then((customerCardTransactionAPIResponse) => {
            if (customerCardTransactionAPIResponse.data && customerCardTransactionAPIResponse.data.length > 0) {
                const extractedData = getTransactionsExtractData(customerCardTransactionAPIResponse.data.reverse(), 'created');
                setCardTransactions(extractedData);
                setLoading(false);
            } else {
                setCardTransactions([]);
                setLoading(false);
            }
        }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
                redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
        });
    }, [customerId, tenantId, redirectToErrorScreen, dateFromIncl, dateToExcl, getTransactionsExtractData]);

    React.useEffect(() => {
        if (!wallets && cardTransactionsList.length < 1 && !isGetCardTransactionsList) {
            getCardTransactions();
        }
    }, [getCardTransactions, cardTransactionsList, wallets, isGetCardTransactionsList]);

    React.useEffect(() => {
        return () => {
            window.onpopstate = () => {
                backToHome();
            }
        };
    }, [history,backToHome])

    return (
        <div>
            <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
            {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                            <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
                            <span className={classes.title}>Transaction History</span>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer> */}
            {wallets ?
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label={walletTitleText+"(s)"} {...a11yProps(0)} />
                            <Tab label="Card(s)" {...a11yProps(1)} />
                        </Tabs>

                        <TabPanel value={value} index={0}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <ValidationTextField
                                        fullWidth
                                        label={"Select "+walletTitleText.toLowerCase()}
                                        select
                                        variant="filled"
                                        id="fromWallet"
                                        type="number"
                                        name="fromWallet"
                                        value={inputs.fromWallet}
                                        onChange={handleInputChange}>
                                        {wallets?.map((wallet) => {
                                            return (<MenuItem key={wallet?.walletId} value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                                        })}
                                    </ValidationTextField>
                                </GridItem>
                                {eftWithdrawalTransactions.length > 0 ?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className='transaction-list'>
                                            <div className='list-title'>
                                                <b>EFT withdrawal pending transaction(s)</b>
                                            </div>
                                            <ul style={{ padding: '0', listStyle: 'none' }}>
                                                {eftWithdrawalTransactions?.map((transaction) => {
                                                    return (<li key={transaction?.externalUniqueId} style={{ display: 'flex' }}>
                                                        <div className='left-content'>
                                                            <span className={classes.regularFontSize} style={{ display: 'block' }}>
                                                                {transaction?.type === 'CASH' ? `Token::${transaction?.token}` : returnEFTDetails(transaction?.extraInfo)}
                                                            </span>
                                                            <span className={classes.regularFontSize} style={{ display: 'block' }}>{moment(transaction?.expires).format("MM/DD/yyyy HH:mm:ss")}</span>

                                                        </div>
                                                        <div className='right-content' style={{ display: 'flex', marginLeft: 'auto', alignSelf: 'center' }}>
                                                            <b style={{ color: dangerColor[0] }} className={classes.regularFontSize}>{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}</b>
                                                        </div>
                                                    </li>)
                                                })}
                                            </ul>
                                        </div>
                                    </GridItem>
                                    : null}
                                {transactionsList.length > 0 ?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className='transaction-list'>
                                            {transactionsList?.map((transactions) => {
                                                return (
                                                    <div key={transactions?.title}>
                                                        <div className='list-title'>
                                                            <b>{transactions?.title}</b>
                                                        </div>
                                                        <ul style={{ padding: '0', listStyle: 'none' }}>
                                                            {transactions?.data?.map((transaction) => {
                                                                return (<li key={transaction?.externalUniqueId} style={{ display: 'flex' }}>
                                                                    <div className='left-content'>
                                                                        <span className={classes.regularFontSize} style={{ display: 'block' }}>{transaction?.description}</span>
                                                                        <span className={classes.regularFontSize} style={{ display: 'block' }}>{ moment(transaction?.created).format("MM/DD/yyyy HH:mm:ss")}</span>

                                                                    </div>
                                                                    <div className='right-content' style={{ display: 'flex', marginLeft: 'auto', alignSelf: 'center' }}>
                                                                        {transaction?.type.startsWith('tfr.credit') || transaction?.type.startsWith('Cr') ? <b style={{ color: successColor[0] }} className={classes.regularFontSize}>{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}</b> : null}
                                                                        {transaction?.type.startsWith('tfr.debit') || transaction?.type.startsWith('Dr') ? <b style={{ color: dangerColor[0] }} className={classes.regularFontSize}>{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}</b> : null}
                                                                    </div>
                                                                </li>)
                                                            })}
                                                        </ul>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </GridItem>
                                    : null}
                                {!isLoading && inputs?.fromWallet !== '' && eftWithdrawalTransactions.length <= 0 && transactionsList.length <= 0 ?
                                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', margin: '2em' }}>
                                        <HistoryIcon stroke={primaryIconColor} />
                                        <p>
                                            <b>You haven't made any transactions yet.</b>
                                        </p>
                                    </GridItem>
                                    : null}
                            </GridContainer>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <GridContainer>
                                {cardTransactionsList.length > 0 ?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className='transaction-list'>
                                            {cardTransactionsList?.map((transactions) => {
                                                return (
                                                    <div key={transactions?.title}>
                                                        <div className='list-title'>
                                                            <b>{transactions?.title}</b>
                                                        </div>
                                                        <ul style={{ padding: '0', listStyle: 'none' }}>
                                                            {transactions?.data?.map((transaction) => {
                                                                return (
                                                                    transaction?.paymentType === PaymentMechanism?.CARD ?
                                                                        <li key={transaction?.externalUniqueId} style={{ display: 'flex' }}>
                                                                            <div className='left-content'>
                                                                                <span className={classes.regularFontSize} style={{ display: 'block', color: primaryTextColor, fontWeight: 400 }}>{transaction?.merchantName}</span>
                                                                                <span className={classes.regularFontSize} style={{ display: 'block', color: grayColor[1] }}>{moment(transaction?.created).format("MM/DD/yyyy HH:mm:ss")}</span>
                                                                            </div>
                                                                            <div className='right-content' style={{ display: 'flex', marginLeft: 'auto', alignSelf: 'center' }}>
                                                                                {transaction?.status === EclipsePaymentStatus?.SUCCESSFUL ?
                                                                                    <b className={classes.regularFontSize}>-{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}<span onClick={event => { openReceipt(transaction) }} style={{ float: 'right', marginLeft: '0.5em', lineHeight: '0.9em', cursor: 'pointer' }}><RightArrow /></span></b>
                                                                                    : <b style={{ color: grayColor[1] }} className={classes.regularFontSize}><span style={{ float: 'left', margin: '0.125em', lineHeight: '0.9em' }}><PaymentFailed /></span> -{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}<span onClick={event => { openReceipt(transaction) }} style={{ float: 'right', marginLeft: '0.5em', lineHeight: '0.9em', cursor: 'pointer' }}><RightArrow /></span></b>
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                        : null)
                                                            })}
                                                        </ul>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </GridItem>
                                    : 
                                    !isLoading && cardTransactionsList.length <= 0 ?
                                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', margin: '2em' }}>
                                        <HistoryIcon stroke={primaryIconColor} />
                                        <p>
                                            <b>You haven't made any transactions yet.</b>
                                        </p>
                                    </GridItem>
                                    : null}
                            </GridContainer>
                        </TabPanel>
                    </GridItem>
                </GridContainer> :
                <GridContainer>
                    {cardTransactionsList.length > 0 ?
                        <GridItem xs={12} sm={12} md={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className='transaction-list'>
                                <span className={classes.title} style={{ display: 'block', color: primaryTextColor, fontWeight: "bold",fontSize:"large",padding:"10px"}}>Latest transactions</span>
                                {cardTransactionsList?.map((transactions) => {
                                    return (
                                        <div key={transactions?.title}>
                                            <div className='list-title'>
                                                <b>{transactions?.title}</b>
                                            </div>
                                            <ul style={{ padding: '0', listStyle: 'none' }}>
                                                {transactions?.data?.map((transaction) => {
                                                    return (
                                                        transaction?.paymentType === PaymentMechanism?.CARD ?
                                                            <li key={transaction?.externalUniqueId} style={{ display: 'flex' }}>
                                                                <div className='left-content'>
                                                                    <span className={classes.regularFontSize} style={{ display: 'block', color: primaryTextColor, fontWeight: 400 }}>{transaction?.merchantName}</span>
                                                                    <span className={classes.regularFontSize} style={{ display: 'block', color: primaryTextColor, fontWeight: 400  }}>{moment(transaction?.created).format("MM/DD/yyyy HH:mm:ss")}</span>
                                                                    <span className={classes.regularFontSize} style={{ display: 'block', color: grayColor[1]  }}>Paid with a {ReturnCardType(transaction?.paymentInstrumentInfo?.cardBin).toUpperCase()} Card</span>
                                                                </div>
                                                                <div className='right-content' style={{ display: 'flex', marginLeft: 'auto', alignSelf: 'center' }}>
                                                                    {transaction?.status === EclipsePaymentStatus?.SUCCESSFUL ?
                                                                        <b className={classes.regularFontSize}>-{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}<span onClick={event => { openReceipt(transaction) }} style={{ float: 'right', marginLeft: '0.5em', lineHeight: '0.9em', cursor: 'pointer' }}><RightArrow /></span></b>
                                                                        : <b style={{ color: grayColor[1] }} className={classes.regularFontSize}><span style={{ float: 'left', margin: '0.125em', lineHeight: '0.9em' }}><PaymentFailed /></span> -{getSymbolFromCurrency(transaction?.currency)}{(Math.abs(transaction?.amount)).toFixed(2)}<span onClick={event => { openReceipt(transaction) }} style={{ float: 'right', marginLeft: '0.5em', lineHeight: '0.9em', cursor: 'pointer' }}><RightArrow /></span></b>
                                                                    }
                                                                </div>
                                                            </li>
                                                            : null)
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })}
                            </div>
                        </GridItem>
                        : 
                        !isLoading && cardTransactionsList.length <= 0 ?
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', margin: '2em' }}>
                            <HistoryIcon stroke={primaryIconColor} />
                            <p>
                                <b>You haven't made any transactions yet.</b>
                            </p>
                        </GridItem>
                        : null}
                </GridContainer>
            }
            {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
        </div>
    );
}

const mapStateToProps = state => ({
    appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
    transactionHistoryHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory)