import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { connect } from "react-redux";

const useStyles = makeStyles(themeStyle);


export function Landing(props) {
  const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
  const registrationLinkEnabled = props?.appData?.template?.pwaAppOptions?.registrationLinkEnabled;
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h1>Hey there!</h1>
          <p className={classes.regularFontSize}><strong>What would you like to do?</strong></p>
        </GridItem>
      </GridContainer>
      {registrationLinkEnabled ?
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} style={{ paddingRight: '0.5em' }}>
            <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={props.signupRedirect}>Sign
              up</Button>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} style={{ paddingLeft: '0.5em' }}>
            <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={props.loginRedirect}>Log
              in</Button>
          </GridItem>
        </GridContainer>
        :
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ paddingLeft: '0.5em' }}>
            <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={props.loginRedirect}>Log
              in</Button>
          </GridItem>
        </GridContainer>
      }
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p className={classes.regularFontSize}>Need more information? <Link className={classes.linkColor} to='/'>Contact
            support</Link> {ratifySupportContact}</p>
        </GridItem>
      </GridContainer>
    </div>
  );
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(Landing)
