import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as QrErrorIcon } from '../../assets/img/QRError.svg'
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function QrError(props) {
  const history = useHistory();
  const classes = useStyles();


  const handleSubmit = useCallback(() => {
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history]);


  React.useEffect(() => {
    return() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        handleSubmit();
      }
    }
  }, [history, handleSubmit])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '50px' }}>
              <QrErrorIcon />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-b-0 m-t-0'}>
                <b>The QR code can’t be paid</b>
              </h4>
              <p className={classes.textCenter + ' m-b-0'} style={{ fontWeight: 'normal' }}>
                The QR code your are trying to pay can’t be used.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                      onClick={handleSubmit}>Done
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successCardPaymentHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QrError)