/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CardOnFileHttpRequest } from '../models';
import { CardOnFileHttpResponse } from '../models';
import { EclipseAddress } from '../models';
import { EclipseAttachment } from '../models';
import { EclipseAuditEvent } from '../models';
import { EclipseBeneficiary } from '../models';
import { EclipseCard } from '../models';
import { EclipseCardOnFile } from '../models';
import { EclipseCardOnFileCreationData } from '../models';
import { EclipseCustomer } from '../models';
import { EclipseCustomerPosition } from '../models';
import { EclipseCustomerWalletType } from '../models';
import { EclipseDeviceToken } from '../models';
import { EclipseDocument } from '../models';
import { EclipseIdentity } from '../models';
import { EclipseKycRequest } from '../models';
import { EclipseKycResult } from '../models';
import { EclipseWallet } from '../models';
import { ExceptionData } from '../models';
import { NewEclipseAddress } from '../models';
import { NewEclipseAttachment } from '../models';
import { NewEclipseBeneficiary } from '../models';
import { NewEclipseCard } from '../models';
import { NewEclipseCardOnFile } from '../models';
import { NewEclipseCustomer } from '../models';
import { NewEclipseCustomerPayment } from '../models';
import { NewEclipseDeviceMessage } from '../models';
import { NewEclipseDeviceToken } from '../models';
import { NewEclipseDocument } from '../models';
import { NewEclipseIdentity } from '../models';
import { NewEclipseWallet } from '../models';
import { PaymentResult } from '../models';
import { UpdatedEclipseBeneficiary } from '../models';
import { UpdatedEclipseCardOnFile } from '../models';
import { UpdatedEclipseIdentity } from '../models';
/**
 * DCustomerManagementApi - axios parameter creator
 * @export
 */
export const DCustomerManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This will return all addresses linked to the customer
         * @summary Get a list of the customers addresses
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAddressesGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAddressesGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAddressesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/addresses`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new address linked to the customer. You can give a customer multiple addresses.
         * @summary Add a new address for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseAddress} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAddressesPost: async (tenantId: number, customerId: number, body?: NewEclipseAddress, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAddressesPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAddressesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/addresses`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will return all attachments linked to the customer
         * @summary Get a list of the customers attachments
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAttachmentsGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAttachmentsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAttachmentsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/attachments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * We can attached extra information of customer in form of  sticky notes, doc or images. We can add multiple attachment to the customers.Supported media types for application/pdf, image/png or image/jpeg,application/json,text/plain.
         * @summary Add a new attachment on the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseAttachment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAttachmentsPost: async (tenantId: number, customerId: number, body?: NewEclipseAttachment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAttachmentsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAttachmentsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/attachments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Customer Audit detail
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
         * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAuditEventsGet: async (tenantId: number, customerId: number, dateFromIncl?: string, dateToExcl?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAuditEventsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdAuditEventsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/audit-events`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a beneficiary
         * @summary Delete beneficiary
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete: async (tenantId: number, customerId: number, beneficiaryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete.');
            }
            // verify required parameter 'beneficiaryId' is not null or undefined
            if (beneficiaryId === null || beneficiaryId === undefined) {
                throw new RequiredError('beneficiaryId','Required parameter beneficiaryId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/beneficiaries/{beneficiaryId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"beneficiaryId"}}`, encodeURIComponent(String(beneficiaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific beneficiary
         * @summary Get a customers beneficiary by beneficiaryId
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet: async (tenantId: number, customerId: number, beneficiaryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet.');
            }
            // verify required parameter 'beneficiaryId' is not null or undefined
            if (beneficiaryId === null || beneficiaryId === undefined) {
                throw new RequiredError('beneficiaryId','Required parameter beneficiaryId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/beneficiaries/{beneficiaryId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"beneficiaryId"}}`, encodeURIComponent(String(beneficiaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a customers beneficiary
         * @summary Update a customers beneficiary
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {UpdatedEclipseBeneficiary} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut: async (tenantId: number, customerId: number, beneficiaryId: number, body?: UpdatedEclipseBeneficiary, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut.');
            }
            // verify required parameter 'beneficiaryId' is not null or undefined
            if (beneficiaryId === null || beneficiaryId === undefined) {
                throw new RequiredError('beneficiaryId','Required parameter beneficiaryId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/beneficiaries/{beneficiaryId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"beneficiaryId"}}`, encodeURIComponent(String(beneficiaryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all a customers beneficiaries
         * @summary Get a customers beneficiaries
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesGet: async (tenantId: number, customerId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/beneficiaries`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new beneficiary
         * @summary Add a new beneficiary for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseBeneficiary} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesPost: async (tenantId: number, customerId: number, body?: NewEclipseBeneficiary, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdBeneficiariesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/beneficiaries`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all cards held by the customer
         * @summary Get a customers cards
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a card on file
         * @summary Delete card on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete: async (tenantId: number, customerId: number, cardOnFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete.');
            }
            // verify required parameter 'cardOnFileId' is not null or undefined
            if (cardOnFileId === null || cardOnFileId === undefined) {
                throw new RequiredError('cardOnFileId','Required parameter cardOnFileId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards-on-file/{cardOnFileId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"cardOnFileId"}}`, encodeURIComponent(String(cardOnFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
         * @summary Get a customers cards on file by cardOnFileId
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet: async (tenantId: number, customerId: number, cardOnFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet.');
            }
            // verify required parameter 'cardOnFileId' is not null or undefined
            if (cardOnFileId === null || cardOnFileId === undefined) {
                throw new RequiredError('cardOnFileId','Required parameter cardOnFileId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards-on-file/{cardOnFileId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"cardOnFileId"}}`, encodeURIComponent(String(cardOnFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a customers card on file
         * @summary Update a customers cards on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {UpdatedEclipseCardOnFile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut: async (tenantId: number, customerId: number, cardOnFileId: string, body?: UpdatedEclipseCardOnFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut.');
            }
            // verify required parameter 'cardOnFileId' is not null or undefined
            if (cardOnFileId === null || cardOnFileId === undefined) {
                throw new RequiredError('cardOnFileId','Required parameter cardOnFileId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards-on-file/{cardOnFileId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"cardOnFileId"}}`, encodeURIComponent(String(cardOnFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a generic way of initiating a HTTP request to a permitted partner where Eclipse will replace cardholder data tokens (pan, expiry, cvv etc) with the actual card data. This allows finegrained control of calls to payment gateways without the Eclipse tenant needing to be PCI compliant as they will never be exposed to card holder data.
         * @summary Use a card on file in a HTTP request to a permitted partner API
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {CardOnFileHttpRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost: async (tenantId: number, customerId: number, cardOnFileId: string, body?: CardOnFileHttpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost.');
            }
            // verify required parameter 'cardOnFileId' is not null or undefined
            if (cardOnFileId === null || cardOnFileId === undefined) {
                throw new RequiredError('cardOnFileId','Required parameter cardOnFileId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards-on-file/{cardOnFileId}/sensitive-transactions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"cardOnFileId"}}`, encodeURIComponent(String(cardOnFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
         * @summary Get a customers cards on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileGet: async (tenantId: number, customerId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFileGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards-on-file`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * There are 3 scenarios. First is where a cardId and alias are provided. In this case the card must be added as a CoF on the card holder and the Eclipse issued card will be added to Card on file and will return the cardOnFileId. The second scenario is where cardId is not provided and nor is any card data. A completion URL will be returned which can be opened in an iFrame for the customer to add card details. Once completed, the CardOnFile will be POSTED to the callbackUrl. The third scenario is where the tenant is PCI-DSS compliant and directly sends the card data. The CoF will be created and returned immediately with no completion URL necessary.
         * @summary Initiate adding a card on file for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCardOnFile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFilePost: async (tenantId: number, customerId: number, body?: NewEclipseCardOnFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFilePost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsOnFilePost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards-on-file`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for addon and replacement cards. For a nett-new card, create a new wallet which supports cards. If physical card details are provided then an existing physical card will be used or else a virtual card will be issued
         * @summary Issue a replacement or addon card to the customer for an existing card on their profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsPost: async (tenantId: number, customerId: number, body?: NewEclipseCard, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdCardsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/cards`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will only be allowed if the customer has no wallets, positions, cards etc.
         * @summary Delete a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDelete: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a push message
         * @summary Send a message to the customers applicable device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDeviceMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceMessagesPost: async (tenantId: number, customerId: number, body?: NewEclipseDeviceMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceMessagesPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceMessagesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/device-messages`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all a customers device tokens
         * @summary Get a customers device tokens
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensGet: async (tenantId: number, customerId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/device-tokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new device token
         * @summary Add a new device token for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDeviceToken} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensPost: async (tenantId: number, customerId: number, body?: NewEclipseDeviceToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/device-tokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a specific device token
         * @summary Delete a specific device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete: async (tenantId: number, customerId: number, tokenId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete.');
            }
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId','Required parameter tokenId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/device-tokens/{tokenId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific device token
         * @summary Get a specific device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet: async (tenantId: number, customerId: number, tokenId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet.');
            }
            // verify required parameter 'tokenId' is not null or undefined
            if (tokenId === null || tokenId === undefined) {
                throw new RequiredError('tokenId','Required parameter tokenId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/device-tokens/{tokenId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will return all documents linked to the customer. If the includeHistoricalDocument=true, it will include the historical document of users
         * @summary Get list of the customers documents
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [fields] Fields to be included. By default returs all fields. Specify onlyMetaData to receive metadata without document
         * @param {boolean} [includeHistoricalDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDocumentsGet: async (tenantId: number, customerId: number, fields?: string, includeHistoricalDocument?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDocumentsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDocumentsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/documents`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (includeHistoricalDocument !== undefined) {
                localVarQueryParameter['includeHistoricalDocument'] = includeHistoricalDocument;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for KYC and other regulatory requirements
         * @summary Add a new document against the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDocument} [body] 
         * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
         * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
         * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDocumentsPost: async (tenantId: number, customerId: number, body?: NewEclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDocumentsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdDocumentsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/documents`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (performOcr !== undefined) {
                localVarQueryParameter['performOcr'] = performOcr;
            }

            if (returnFullResult !== undefined) {
                localVarQueryParameter['returnFullResult'] = returnFullResult;
            }

            if (validateDocType !== undefined) {
                localVarQueryParameter['validateDocType'] = validateDocType;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a specific customer
         * @summary Get a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the identities of the customer
         * @summary Get a list of the customers identities
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/identities`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if a customer has any identities without needing read permissions on the customer itself. A JWT is required though
         * @summary Check if a customer has any identities
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesHead: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesHead.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesHead.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/identities`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the identity so it cannot be used
         * @summary Delete an identity for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} identity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete: async (tenantId: number, customerId: number, identity: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete.');
            }
            // verify required parameter 'identity' is not null or undefined
            if (identity === null || identity === undefined) {
                throw new RequiredError('identity','Required parameter identity was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/identities/{identity}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"identity"}}`, encodeURIComponent(String(identity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * It is the responsibility of the tenant to ensure they have verified that the customer is the one setting the new password
         * @summary Update a password for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} identity 
         * @param {UpdatedEclipseIdentity} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut: async (tenantId: number, customerId: number, identity: string, body?: UpdatedEclipseIdentity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut.');
            }
            // verify required parameter 'identity' is not null or undefined
            if (identity === null || identity === undefined) {
                throw new RequiredError('identity','Required parameter identity was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/identities/{identity}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"identity"}}`, encodeURIComponent(String(identity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Customers can then get a JWT with these credentials and access their profile, wallets, cards etc. This is necessary when tenants want to give their customers direct access to the APIs or for customers using companion Apps. If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 For generating a special CAP (card access password) for a customer to view unmasked card details but without creating a proper identity on Eclipse, one can create an identity with identity \"CAP\".If this is done with a tenant JWT and no password then a random password is generated by Eclipse and SMS'd to the customers phone on profile. If CAP is passed with no JWT and no password then an SMS with an OTP is sent to the customer. This API can then be called passing CAP, a password to set and the OTP (again with no JWT).Note that a CAP cannot be used for getting a JWT. It can only be used to get card details. To create a WebAuthN identity, just pass an identity. Then proceed with getting a login-challenge to initiate the WebAuthN registration process.
         * @summary Create a username/password for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseIdentity} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesPost: async (tenantId: number, customerId: number, body?: NewEclipseIdentity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdIdentitiesPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/identities`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns payments that were initiated against the customer
         * @summary Get a list of payments for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [amount] 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [paymentReference] 
         * @param {string} [paymentType] 
         * @param {string} [status] 
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPaymentsGet: async (tenantId: number, customerId: number, amount?: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentReference?: string, paymentType?: string, status?: string, walletId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPaymentsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPaymentsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/payments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (paymentReference !== undefined) {
                localVarQueryParameter['paymentReference'] = paymentReference;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The first step for a customer to make a payment
         * @summary Initiate making a payment by a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCustomerPayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPaymentsPost: async (tenantId: number, customerId: number, body?: NewEclipseCustomerPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPaymentsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPaymentsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/payments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
         * @summary Get a list of positions held by the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPositionsGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/positions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, emplyee etc. Positions impact security access to wallets held by the organisation
         * @summary Delete a position held by a customer in an organisation
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} organisationId 
         * @param {string} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete: async (tenantId: number, customerId: number, organisationId: number, position: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete.');
            }
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new RequiredError('organisationId','Required parameter organisationId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete.');
            }
            // verify required parameter 'position' is not null or undefined
            if (position === null || position === undefined) {
                throw new RequiredError('position','Required parameter position was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/positions/{organisationId}/{position}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"position"}}`, encodeURIComponent(String(position)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
         * @summary Add a position held by the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseCustomerPosition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPositionsPost: async (tenantId: number, customerId: number, body?: EclipseCustomerPosition, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPositionsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/positions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update allowed fields on the customer profile
         * @summary Update a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPut: async (tenantId: number, customerId: number, body?: EclipseCustomer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPut.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The results are returned from most recent to oldest so pass offset=0 and limit=1 for the most recent one
         * @summary Get the ratify results for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [ratifyType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdRatifyGet: async (tenantId: number, customerId: number, limit?: number, offset?: number, ratifyType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdRatifyGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdRatifyGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/ratify`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ratifyType !== undefined) {
                localVarQueryParameter['ratify_type'] = ratifyType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will ratify the customers profile and attached documentation and provide an updated KYC status. Populate request body only if you want to do manual ratify or change from default ratify type of NORMAL, else left blank
         * @summary Run a KYC algorithm on the customers profile to update the KYC status
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseKycRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdRatifyPost: async (tenantId: number, customerId: number, body?: EclipseKycRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdRatifyPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdRatifyPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/ratify`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs out all JWT's issued to the customer
         * @summary Remove all sessions so the customer has to login again
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdSessionsDelete: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdSessionsDelete.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdSessionsDelete.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/sessions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Wallet type allowed is true that means  customer is enough ratified to use this wallet type for create wallet/card and wallet type is  false that  means customer is not enough ratified to use this wallet type.
         * @summary Get a list of allowed wallet types for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdWalletTypesGet: async (tenantId: number, customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdWalletTypesGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdWalletTypesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/wallet-types`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all the wallets directly owned by the customer
         * @summary Get list of wallets belonging to the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [accountNumber] 
         * @param {string} [friendlyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdWalletsGet: async (tenantId: number, customerId: number, accountNumber?: string, friendlyId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdWalletsGet.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdWalletsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/wallets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (friendlyId !== undefined) {
                localVarQueryParameter['friendlyId'] = friendlyId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new wallet linked to the customer. If this is a card wallet then it will be created along with the first linked card which can be retrieved via the cards endpoint
         * @summary Create a new Wallet for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdWalletsPost: async (tenantId: number, customerId: number, body?: NewEclipseWallet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersCustomerIdWalletsPost.');
            }
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling tenantsTenantIdCustomersCustomerIdWalletsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers/{customerId}/wallets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a general API for searching for customers based on various criteria. If fields=customerId is passed as a query parameter, then only the customerId of each matching customer would be returned and one does not need permissions to read the customers full profile. It then behaves the same as the HEAD method but returns the customerId of each customer as opposed to no body at all.
         * @summary Search for customers in the tenant
         * @param {number} tenantId 
         * @param {string} [asylumRefNumber] 
         * @param {string} [email] 
         * @param {string} [externalUniqueId] 
         * @param {string} [fields] Field data to include. Comma separated list. Supports customerId.
         * @param {string} [identity] 
         * @param {boolean} [includeAdminUsers] 
         * @param {string} [lastName] 
         * @param {number} [limit] 
         * @param {string} [nationalIdentityNumber] 
         * @param {number} [offset] 
         * @param {string} [passportNumber] 
         * @param {string} [phone1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersGet: async (tenantId: number, asylumRefNumber?: string, email?: string, externalUniqueId?: string, fields?: string, identity?: string, includeAdminUsers?: boolean, lastName?: string, limit?: number, nationalIdentityNumber?: string, offset?: number, passportNumber?: string, phone1?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersGet.');
            }
            const localVarPath = `/tenants/{tenantId}/customers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (asylumRefNumber !== undefined) {
                localVarQueryParameter['asylumRefNumber'] = asylumRefNumber;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (externalUniqueId !== undefined) {
                localVarQueryParameter['externalUniqueId'] = externalUniqueId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }

            if (includeAdminUsers !== undefined) {
                localVarQueryParameter['includeAdminUsers'] = includeAdminUsers;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (nationalIdentityNumber !== undefined) {
                localVarQueryParameter['nationalIdentityNumber'] = nationalIdentityNumber;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (passportNumber !== undefined) {
                localVarQueryParameter['passportNumber'] = passportNumber;
            }

            if (phone1 !== undefined) {
                localVarQueryParameter['phone1'] = phone1;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if a customer exists in the tenant without needing read permissions on the customer itself. A JWT is required though.
         * @summary Check if a customer exists
         * @param {number} tenantId 
         * @param {string} [asylumRefNumber] 
         * @param {string} [email] 
         * @param {string} [identity] 
         * @param {string} [nationalIdentityNumber] 
         * @param {string} [passportNumber] 
         * @param {string} [phone1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersHead: async (tenantId: number, asylumRefNumber?: string, email?: string, identity?: string, nationalIdentityNumber?: string, passportNumber?: string, phone1?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersHead.');
            }
            const localVarPath = `/tenants/{tenantId}/customers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (asylumRefNumber !== undefined) {
                localVarQueryParameter['asylumRefNumber'] = asylumRefNumber;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }

            if (nationalIdentityNumber !== undefined) {
                localVarQueryParameter['nationalIdentityNumber'] = nationalIdentityNumber;
            }

            if (passportNumber !== undefined) {
                localVarQueryParameter['passportNumber'] = passportNumber;
            }

            if (phone1 !== undefined) {
                localVarQueryParameter['phone1'] = phone1;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will create a new customer profile under the tenant
         * @summary Register a new Customer
         * @param {number} tenantId 
         * @param {NewEclipseCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersPost: async (tenantId: number, body?: NewEclipseCustomer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCustomersPost.');
            }
            const localVarPath = `/tenants/{tenantId}/customers`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DCustomerManagementApi - functional programming interface
 * @export
 */
export const DCustomerManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This will return all addresses linked to the customer
         * @summary Get a list of the customers addresses
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseAddress>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a new address linked to the customer. You can give a customer multiple addresses.
         * @summary Add a new address for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseAddress} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdAddressesPost(tenantId: number, customerId: number, body?: NewEclipseAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseAddress>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdAddressesPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will return all attachments linked to the customer
         * @summary Get a list of the customers attachments
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdAttachmentsGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseAttachment>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdAttachmentsGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * We can attached extra information of customer in form of  sticky notes, doc or images. We can add multiple attachment to the customers.Supported media types for application/pdf, image/png or image/jpeg,application/json,text/plain.
         * @summary Add a new attachment on the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseAttachment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdAttachmentsPost(tenantId: number, customerId: number, body?: NewEclipseAttachment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseAttachment>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdAttachmentsPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Customer Audit detail
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
         * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdAuditEventsGet(tenantId: number, customerId: number, dateFromIncl?: string, dateToExcl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseAuditEvent>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdAuditEventsGet(tenantId, customerId, dateFromIncl, dateToExcl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a beneficiary
         * @summary Delete beneficiary
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete(tenantId: number, customerId: number, beneficiaryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete(tenantId, customerId, beneficiaryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a specific beneficiary
         * @summary Get a customers beneficiary by beneficiaryId
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet(tenantId: number, customerId: number, beneficiaryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseBeneficiary>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet(tenantId, customerId, beneficiaryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a customers beneficiary
         * @summary Update a customers beneficiary
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {UpdatedEclipseBeneficiary} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut(tenantId: number, customerId: number, beneficiaryId: number, body?: UpdatedEclipseBeneficiary, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseBeneficiary>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut(tenantId, customerId, beneficiaryId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all a customers beneficiaries
         * @summary Get a customers beneficiaries
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdBeneficiariesGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseBeneficiary>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesGet(tenantId, customerId, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a new beneficiary
         * @summary Add a new beneficiary for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseBeneficiary} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdBeneficiariesPost(tenantId: number, customerId: number, body?: NewEclipseBeneficiary, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseBeneficiary>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all cards held by the customer
         * @summary Get a customers cards
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCard>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a card on file
         * @summary Delete card on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(tenantId: number, customerId: number, cardOnFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(tenantId, customerId, cardOnFileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns a cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
         * @summary Get a customers cards on file by cardOnFileId
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet(tenantId: number, customerId: number, cardOnFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCardOnFile>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet(tenantId, customerId, cardOnFileId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a customers card on file
         * @summary Update a customers cards on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {UpdatedEclipseCardOnFile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(tenantId: number, customerId: number, cardOnFileId: string, body?: UpdatedEclipseCardOnFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCardOnFile>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(tenantId, customerId, cardOnFileId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a generic way of initiating a HTTP request to a permitted partner where Eclipse will replace cardholder data tokens (pan, expiry, cvv etc) with the actual card data. This allows finegrained control of calls to payment gateways without the Eclipse tenant needing to be PCI compliant as they will never be exposed to card holder data.
         * @summary Use a card on file in a HTTP request to a permitted partner API
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {CardOnFileHttpRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost(tenantId: number, customerId: number, cardOnFileId: string, body?: CardOnFileHttpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardOnFileHttpResponse>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost(tenantId, customerId, cardOnFileId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
         * @summary Get a customers cards on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCardOnFile>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId, customerId, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * There are 3 scenarios. First is where a cardId and alias are provided. In this case the card must be added as a CoF on the card holder and the Eclipse issued card will be added to Card on file and will return the cardOnFileId. The second scenario is where cardId is not provided and nor is any card data. A completion URL will be returned which can be opened in an iFrame for the customer to add card details. Once completed, the CardOnFile will be POSTED to the callbackUrl. The third scenario is where the tenant is PCI-DSS compliant and directly sends the card data. The CoF will be created and returned immediately with no completion URL necessary.
         * @summary Initiate adding a card on file for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCardOnFile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsOnFilePost(tenantId: number, customerId: number, body?: NewEclipseCardOnFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCardOnFileCreationData>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFilePost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is for addon and replacement cards. For a nett-new card, create a new wallet which supports cards. If physical card details are provided then an existing physical card will be used or else a virtual card will be issued
         * @summary Issue a replacement or addon card to the customer for an existing card on their profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdCardsPost(tenantId: number, customerId: number, body?: NewEclipseCard, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCard>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdCardsPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will only be allowed if the customer has no wallets, positions, cards etc.
         * @summary Delete a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDelete(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDelete(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Sends a push message
         * @summary Send a message to the customers applicable device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDeviceMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDeviceMessagesPost(tenantId: number, customerId: number, body?: NewEclipseDeviceMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDeviceMessagesPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all a customers device tokens
         * @summary Get a customers device tokens
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDeviceTokensGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseDeviceToken>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensGet(tenantId, customerId, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a new device token
         * @summary Add a new device token for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDeviceToken} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDeviceTokensPost(tenantId: number, customerId: number, body?: NewEclipseDeviceToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseBeneficiary>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a specific device token
         * @summary Delete a specific device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete(tenantId: number, customerId: number, tokenId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete(tenantId, customerId, tokenId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a specific device token
         * @summary Get a specific device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet(tenantId: number, customerId: number, tokenId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseDeviceToken>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet(tenantId, customerId, tokenId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will return all documents linked to the customer. If the includeHistoricalDocument=true, it will include the historical document of users
         * @summary Get list of the customers documents
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [fields] Fields to be included. By default returs all fields. Specify onlyMetaData to receive metadata without document
         * @param {boolean} [includeHistoricalDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId: number, customerId: number, fields?: string, includeHistoricalDocument?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseDocument>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId, customerId, fields, includeHistoricalDocument, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is for KYC and other regulatory requirements
         * @summary Add a new document against the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDocument} [body] 
         * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
         * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
         * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdDocumentsPost(tenantId: number, customerId: number, body?: NewEclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseDocument>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdDocumentsPost(tenantId, customerId, body, performOcr, returnFullResult, validateDocType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves a specific customer
         * @summary Get a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCustomer>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the identities of the customer
         * @summary Get a list of the customers identities
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseIdentity>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Check if a customer has any identities without needing read permissions on the customer itself. A JWT is required though
         * @summary Check if a customer has any identities
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete the identity so it cannot be used
         * @summary Delete an identity for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} identity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete(tenantId: number, customerId: number, identity: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete(tenantId, customerId, identity, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * It is the responsibility of the tenant to ensure they have verified that the customer is the one setting the new password
         * @summary Update a password for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} identity 
         * @param {UpdatedEclipseIdentity} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId: number, customerId: number, identity: string, body?: UpdatedEclipseIdentity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseIdentity>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId, customerId, identity, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Customers can then get a JWT with these credentials and access their profile, wallets, cards etc. This is necessary when tenants want to give their customers direct access to the APIs or for customers using companion Apps. If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 For generating a special CAP (card access password) for a customer to view unmasked card details but without creating a proper identity on Eclipse, one can create an identity with identity \"CAP\".If this is done with a tenant JWT and no password then a random password is generated by Eclipse and SMS'd to the customers phone on profile. If CAP is passed with no JWT and no password then an SMS with an OTP is sent to the customer. This API can then be called passing CAP, a password to set and the OTP (again with no JWT).Note that a CAP cannot be used for getting a JWT. It can only be used to get card details. To create a WebAuthN identity, just pass an identity. Then proceed with getting a login-challenge to initiate the WebAuthN registration process.
         * @summary Create a username/password for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseIdentity} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId: number, customerId: number, body?: NewEclipseIdentity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseIdentity>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns payments that were initiated against the customer
         * @summary Get a list of payments for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [amount] 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [paymentReference] 
         * @param {string} [paymentType] 
         * @param {string} [status] 
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId: number, customerId: number, amount?: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentReference?: string, paymentType?: string, status?: string, walletId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentResult>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId, customerId, amount, dateFromIncl, dateToExcl, limit, offset, paymentReference, paymentType, status, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The first step for a customer to make a payment
         * @summary Initiate making a payment by a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCustomerPayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId: number, customerId: number, body?: NewEclipseCustomerPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResult>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
         * @summary Get a list of positions held by the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdPositionsGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCustomerPosition>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdPositionsGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, emplyee etc. Positions impact security access to wallets held by the organisation
         * @summary Delete a position held by a customer in an organisation
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} organisationId 
         * @param {string} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete(tenantId: number, customerId: number, organisationId: number, position: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete(tenantId, customerId, organisationId, position, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
         * @summary Add a position held by the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseCustomerPosition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId: number, customerId: number, body?: EclipseCustomerPosition, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCustomerPosition>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update allowed fields on the customer profile
         * @summary Update a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdPut(tenantId: number, customerId: number, body?: EclipseCustomer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCustomer>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdPut(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The results are returned from most recent to oldest so pass offset=0 and limit=1 for the most recent one
         * @summary Get the ratify results for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [ratifyType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdRatifyGet(tenantId: number, customerId: number, limit?: number, offset?: number, ratifyType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseKycResult>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdRatifyGet(tenantId, customerId, limit, offset, ratifyType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will ratify the customers profile and attached documentation and provide an updated KYC status. Populate request body only if you want to do manual ratify or change from default ratify type of NORMAL, else left blank
         * @summary Run a KYC algorithm on the customers profile to update the KYC status
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseKycRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdRatifyPost(tenantId: number, customerId: number, body?: EclipseKycRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseKycResult>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdRatifyPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Logs out all JWT's issued to the customer
         * @summary Remove all sessions so the customer has to login again
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdSessionsDelete(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdSessionsDelete(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Wallet type allowed is true that means  customer is enough ratified to use this wallet type for create wallet/card and wallet type is  false that  means customer is not enough ratified to use this wallet type.
         * @summary Get a list of allowed wallet types for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId: number, customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCustomerWalletType>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId, customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Lists all the wallets directly owned by the customer
         * @summary Get list of wallets belonging to the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [accountNumber] 
         * @param {string} [friendlyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdWalletsGet(tenantId: number, customerId: number, accountNumber?: string, friendlyId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseWallet>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdWalletsGet(tenantId, customerId, accountNumber, friendlyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Creates a new wallet linked to the customer. If this is a card wallet then it will be created along with the first linked card which can be retrieved via the cards endpoint
         * @summary Create a new Wallet for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersCustomerIdWalletsPost(tenantId: number, customerId: number, body?: NewEclipseWallet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWallet>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersCustomerIdWalletsPost(tenantId, customerId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a general API for searching for customers based on various criteria. If fields=customerId is passed as a query parameter, then only the customerId of each matching customer would be returned and one does not need permissions to read the customers full profile. It then behaves the same as the HEAD method but returns the customerId of each customer as opposed to no body at all.
         * @summary Search for customers in the tenant
         * @param {number} tenantId 
         * @param {string} [asylumRefNumber] 
         * @param {string} [email] 
         * @param {string} [externalUniqueId] 
         * @param {string} [fields] Field data to include. Comma separated list. Supports customerId.
         * @param {string} [identity] 
         * @param {boolean} [includeAdminUsers] 
         * @param {string} [lastName] 
         * @param {number} [limit] 
         * @param {string} [nationalIdentityNumber] 
         * @param {number} [offset] 
         * @param {string} [passportNumber] 
         * @param {string} [phone1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersGet(tenantId: number, asylumRefNumber?: string, email?: string, externalUniqueId?: string, fields?: string, identity?: string, includeAdminUsers?: boolean, lastName?: string, limit?: number, nationalIdentityNumber?: string, offset?: number, passportNumber?: string, phone1?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCustomer>>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersGet(tenantId, asylumRefNumber, email, externalUniqueId, fields, identity, includeAdminUsers, lastName, limit, nationalIdentityNumber, offset, passportNumber, phone1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Check if a customer exists in the tenant without needing read permissions on the customer itself. A JWT is required though.
         * @summary Check if a customer exists
         * @param {number} tenantId 
         * @param {string} [asylumRefNumber] 
         * @param {string} [email] 
         * @param {string} [identity] 
         * @param {string} [nationalIdentityNumber] 
         * @param {string} [passportNumber] 
         * @param {string} [phone1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersHead(tenantId: number, asylumRefNumber?: string, email?: string, identity?: string, nationalIdentityNumber?: string, passportNumber?: string, phone1?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersHead(tenantId, asylumRefNumber, email, identity, nationalIdentityNumber, passportNumber, phone1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will create a new customer profile under the tenant
         * @summary Register a new Customer
         * @param {number} tenantId 
         * @param {NewEclipseCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCustomersPost(tenantId: number, body?: NewEclipseCustomer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCustomer>> {
            const localVarAxiosArgs = await DCustomerManagementApiAxiosParamCreator(configuration).tenantsTenantIdCustomersPost(tenantId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DCustomerManagementApi - factory interface
 * @export
 */
export const DCustomerManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This will return all addresses linked to the customer
         * @summary Get a list of the customers addresses
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId: number, customerId: number, options?: any): AxiosPromise<Array<EclipseAddress>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new address linked to the customer. You can give a customer multiple addresses.
         * @summary Add a new address for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseAddress} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAddressesPost(tenantId: number, customerId: number, body?: NewEclipseAddress, options?: any): AxiosPromise<EclipseAddress> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdAddressesPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This will return all attachments linked to the customer
         * @summary Get a list of the customers attachments
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAttachmentsGet(tenantId: number, customerId: number, options?: any): AxiosPromise<Array<EclipseAttachment>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdAttachmentsGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * We can attached extra information of customer in form of  sticky notes, doc or images. We can add multiple attachment to the customers.Supported media types for application/pdf, image/png or image/jpeg,application/json,text/plain.
         * @summary Add a new attachment on the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseAttachment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAttachmentsPost(tenantId: number, customerId: number, body?: NewEclipseAttachment, options?: any): AxiosPromise<EclipseAttachment> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdAttachmentsPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Customer Audit detail
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
         * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdAuditEventsGet(tenantId: number, customerId: number, dateFromIncl?: string, dateToExcl?: string, options?: any): AxiosPromise<Array<EclipseAuditEvent>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdAuditEventsGet(tenantId, customerId, dateFromIncl, dateToExcl, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a beneficiary
         * @summary Delete beneficiary
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete(tenantId: number, customerId: number, beneficiaryId: number, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete(tenantId, customerId, beneficiaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific beneficiary
         * @summary Get a customers beneficiary by beneficiaryId
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet(tenantId: number, customerId: number, beneficiaryId: number, options?: any): AxiosPromise<EclipseBeneficiary> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet(tenantId, customerId, beneficiaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a customers beneficiary
         * @summary Update a customers beneficiary
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} beneficiaryId 
         * @param {UpdatedEclipseBeneficiary} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut(tenantId: number, customerId: number, beneficiaryId: number, body?: UpdatedEclipseBeneficiary, options?: any): AxiosPromise<EclipseBeneficiary> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut(tenantId, customerId, beneficiaryId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all a customers beneficiaries
         * @summary Get a customers beneficiaries
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any): AxiosPromise<Array<EclipseBeneficiary>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesGet(tenantId, customerId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new beneficiary
         * @summary Add a new beneficiary for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseBeneficiary} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdBeneficiariesPost(tenantId: number, customerId: number, body?: NewEclipseBeneficiary, options?: any): AxiosPromise<EclipseBeneficiary> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all cards held by the customer
         * @summary Get a customers cards
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsGet(tenantId: number, customerId: number, options?: any): AxiosPromise<Array<EclipseCard>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a card on file
         * @summary Delete card on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(tenantId: number, customerId: number, cardOnFileId: string, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(tenantId, customerId, cardOnFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
         * @summary Get a customers cards on file by cardOnFileId
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet(tenantId: number, customerId: number, cardOnFileId: string, options?: any): AxiosPromise<EclipseCardOnFile> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet(tenantId, customerId, cardOnFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a customers card on file
         * @summary Update a customers cards on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {UpdatedEclipseCardOnFile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(tenantId: number, customerId: number, cardOnFileId: string, body?: UpdatedEclipseCardOnFile, options?: any): AxiosPromise<EclipseCardOnFile> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(tenantId, customerId, cardOnFileId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a generic way of initiating a HTTP request to a permitted partner where Eclipse will replace cardholder data tokens (pan, expiry, cvv etc) with the actual card data. This allows finegrained control of calls to payment gateways without the Eclipse tenant needing to be PCI compliant as they will never be exposed to card holder data.
         * @summary Use a card on file in a HTTP request to a permitted partner API
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} cardOnFileId 
         * @param {CardOnFileHttpRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost(tenantId: number, customerId: number, cardOnFileId: string, body?: CardOnFileHttpRequest, options?: any): AxiosPromise<CardOnFileHttpResponse> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost(tenantId, customerId, cardOnFileId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
         * @summary Get a customers cards on file
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any): AxiosPromise<Array<EclipseCardOnFile>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId, customerId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * There are 3 scenarios. First is where a cardId and alias are provided. In this case the card must be added as a CoF on the card holder and the Eclipse issued card will be added to Card on file and will return the cardOnFileId. The second scenario is where cardId is not provided and nor is any card data. A completion URL will be returned which can be opened in an iFrame for the customer to add card details. Once completed, the CardOnFile will be POSTED to the callbackUrl. The third scenario is where the tenant is PCI-DSS compliant and directly sends the card data. The CoF will be created and returned immediately with no completion URL necessary.
         * @summary Initiate adding a card on file for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCardOnFile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsOnFilePost(tenantId: number, customerId: number, body?: NewEclipseCardOnFile, options?: any): AxiosPromise<EclipseCardOnFileCreationData> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsOnFilePost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for addon and replacement cards. For a nett-new card, create a new wallet which supports cards. If physical card details are provided then an existing physical card will be used or else a virtual card will be issued
         * @summary Issue a replacement or addon card to the customer for an existing card on their profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdCardsPost(tenantId: number, customerId: number, body?: NewEclipseCard, options?: any): AxiosPromise<EclipseCard> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdCardsPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This will only be allowed if the customer has no wallets, positions, cards etc.
         * @summary Delete a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDelete(tenantId: number, customerId: number, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDelete(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a push message
         * @summary Send a message to the customers applicable device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDeviceMessage} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceMessagesPost(tenantId: number, customerId: number, body?: NewEclipseDeviceMessage, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDeviceMessagesPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all a customers device tokens
         * @summary Get a customers device tokens
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any): AxiosPromise<Array<EclipseDeviceToken>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensGet(tenantId, customerId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new device token
         * @summary Add a new device token for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDeviceToken} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensPost(tenantId: number, customerId: number, body?: NewEclipseDeviceToken, options?: any): AxiosPromise<EclipseBeneficiary> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a specific device token
         * @summary Delete a specific device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete(tenantId: number, customerId: number, tokenId: number, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete(tenantId, customerId, tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific device token
         * @summary Get a specific device token
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet(tenantId: number, customerId: number, tokenId: number, options?: any): AxiosPromise<EclipseDeviceToken> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet(tenantId, customerId, tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * This will return all documents linked to the customer. If the includeHistoricalDocument=true, it will include the historical document of users
         * @summary Get list of the customers documents
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [fields] Fields to be included. By default returs all fields. Specify onlyMetaData to receive metadata without document
         * @param {boolean} [includeHistoricalDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId: number, customerId: number, fields?: string, includeHistoricalDocument?: boolean, options?: any): AxiosPromise<Array<EclipseDocument>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId, customerId, fields, includeHistoricalDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for KYC and other regulatory requirements
         * @summary Add a new document against the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseDocument} [body] 
         * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
         * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
         * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdDocumentsPost(tenantId: number, customerId: number, body?: NewEclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options?: any): AxiosPromise<EclipseDocument> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdDocumentsPost(tenantId, customerId, body, performOcr, returnFullResult, validateDocType, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a specific customer
         * @summary Get a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdGet(tenantId: number, customerId: number, options?: any): AxiosPromise<EclipseCustomer> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the identities of the customer
         * @summary Get a list of the customers identities
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId: number, customerId: number, options?: any): AxiosPromise<Array<EclipseIdentity>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if a customer has any identities without needing read permissions on the customer itself. A JWT is required though
         * @summary Check if a customer has any identities
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId: number, customerId: number, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the identity so it cannot be used
         * @summary Delete an identity for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} identity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete(tenantId: number, customerId: number, identity: string, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete(tenantId, customerId, identity, options).then((request) => request(axios, basePath));
        },
        /**
         * It is the responsibility of the tenant to ensure they have verified that the customer is the one setting the new password
         * @summary Update a password for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} identity 
         * @param {UpdatedEclipseIdentity} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId: number, customerId: number, identity: string, body?: UpdatedEclipseIdentity, options?: any): AxiosPromise<EclipseIdentity> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId, customerId, identity, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Customers can then get a JWT with these credentials and access their profile, wallets, cards etc. This is necessary when tenants want to give their customers direct access to the APIs or for customers using companion Apps. If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 For generating a special CAP (card access password) for a customer to view unmasked card details but without creating a proper identity on Eclipse, one can create an identity with identity \"CAP\".If this is done with a tenant JWT and no password then a random password is generated by Eclipse and SMS'd to the customers phone on profile. If CAP is passed with no JWT and no password then an SMS with an OTP is sent to the customer. This API can then be called passing CAP, a password to set and the OTP (again with no JWT).Note that a CAP cannot be used for getting a JWT. It can only be used to get card details. To create a WebAuthN identity, just pass an identity. Then proceed with getting a login-challenge to initiate the WebAuthN registration process.
         * @summary Create a username/password for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseIdentity} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId: number, customerId: number, body?: NewEclipseIdentity, options?: any): AxiosPromise<EclipseIdentity> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns payments that were initiated against the customer
         * @summary Get a list of payments for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [amount] 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [paymentReference] 
         * @param {string} [paymentType] 
         * @param {string} [status] 
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId: number, customerId: number, amount?: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentReference?: string, paymentType?: string, status?: string, walletId?: number, options?: any): AxiosPromise<Array<PaymentResult>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId, customerId, amount, dateFromIncl, dateToExcl, limit, offset, paymentReference, paymentType, status, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * The first step for a customer to make a payment
         * @summary Initiate making a payment by a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseCustomerPayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId: number, customerId: number, body?: NewEclipseCustomerPayment, options?: any): AxiosPromise<PaymentResult> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
         * @summary Get a list of positions held by the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPositionsGet(tenantId: number, customerId: number, options?: any): AxiosPromise<Array<EclipseCustomerPosition>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdPositionsGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, emplyee etc. Positions impact security access to wallets held by the organisation
         * @summary Delete a position held by a customer in an organisation
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} organisationId 
         * @param {string} position 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete(tenantId: number, customerId: number, organisationId: number, position: string, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete(tenantId, customerId, organisationId, position, options).then((request) => request(axios, basePath));
        },
        /**
         * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
         * @summary Add a position held by the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseCustomerPosition} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId: number, customerId: number, body?: EclipseCustomerPosition, options?: any): AxiosPromise<EclipseCustomerPosition> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update allowed fields on the customer profile
         * @summary Update a customer profile
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdPut(tenantId: number, customerId: number, body?: EclipseCustomer, options?: any): AxiosPromise<EclipseCustomer> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdPut(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * The results are returned from most recent to oldest so pass offset=0 and limit=1 for the most recent one
         * @summary Get the ratify results for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [ratifyType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdRatifyGet(tenantId: number, customerId: number, limit?: number, offset?: number, ratifyType?: string, options?: any): AxiosPromise<Array<EclipseKycResult>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdRatifyGet(tenantId, customerId, limit, offset, ratifyType, options).then((request) => request(axios, basePath));
        },
        /**
         * This will ratify the customers profile and attached documentation and provide an updated KYC status. Populate request body only if you want to do manual ratify or change from default ratify type of NORMAL, else left blank
         * @summary Run a KYC algorithm on the customers profile to update the KYC status
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {EclipseKycRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdRatifyPost(tenantId: number, customerId: number, body?: EclipseKycRequest, options?: any): AxiosPromise<EclipseKycResult> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdRatifyPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs out all JWT's issued to the customer
         * @summary Remove all sessions so the customer has to login again
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdSessionsDelete(tenantId: number, customerId: number, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdSessionsDelete(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Wallet type allowed is true that means  customer is enough ratified to use this wallet type for create wallet/card and wallet type is  false that  means customer is not enough ratified to use this wallet type.
         * @summary Get a list of allowed wallet types for a customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId: number, customerId: number, options?: any): AxiosPromise<Array<EclipseCustomerWalletType>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all the wallets directly owned by the customer
         * @summary Get list of wallets belonging to the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {string} [accountNumber] 
         * @param {string} [friendlyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdWalletsGet(tenantId: number, customerId: number, accountNumber?: string, friendlyId?: string, options?: any): AxiosPromise<Array<EclipseWallet>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdWalletsGet(tenantId, customerId, accountNumber, friendlyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new wallet linked to the customer. If this is a card wallet then it will be created along with the first linked card which can be retrieved via the cards endpoint
         * @summary Create a new Wallet for the customer
         * @param {number} tenantId 
         * @param {number} customerId 
         * @param {NewEclipseWallet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersCustomerIdWalletsPost(tenantId: number, customerId: number, body?: NewEclipseWallet, options?: any): AxiosPromise<EclipseWallet> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersCustomerIdWalletsPost(tenantId, customerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a general API for searching for customers based on various criteria. If fields=customerId is passed as a query parameter, then only the customerId of each matching customer would be returned and one does not need permissions to read the customers full profile. It then behaves the same as the HEAD method but returns the customerId of each customer as opposed to no body at all.
         * @summary Search for customers in the tenant
         * @param {number} tenantId 
         * @param {string} [asylumRefNumber] 
         * @param {string} [email] 
         * @param {string} [externalUniqueId] 
         * @param {string} [fields] Field data to include. Comma separated list. Supports customerId.
         * @param {string} [identity] 
         * @param {boolean} [includeAdminUsers] 
         * @param {string} [lastName] 
         * @param {number} [limit] 
         * @param {string} [nationalIdentityNumber] 
         * @param {number} [offset] 
         * @param {string} [passportNumber] 
         * @param {string} [phone1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersGet(tenantId: number, asylumRefNumber?: string, email?: string, externalUniqueId?: string, fields?: string, identity?: string, includeAdminUsers?: boolean, lastName?: string, limit?: number, nationalIdentityNumber?: string, offset?: number, passportNumber?: string, phone1?: string, options?: any): AxiosPromise<Array<EclipseCustomer>> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersGet(tenantId, asylumRefNumber, email, externalUniqueId, fields, identity, includeAdminUsers, lastName, limit, nationalIdentityNumber, offset, passportNumber, phone1, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if a customer exists in the tenant without needing read permissions on the customer itself. A JWT is required though.
         * @summary Check if a customer exists
         * @param {number} tenantId 
         * @param {string} [asylumRefNumber] 
         * @param {string} [email] 
         * @param {string} [identity] 
         * @param {string} [nationalIdentityNumber] 
         * @param {string} [passportNumber] 
         * @param {string} [phone1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersHead(tenantId: number, asylumRefNumber?: string, email?: string, identity?: string, nationalIdentityNumber?: string, passportNumber?: string, phone1?: string, options?: any): AxiosPromise<void> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersHead(tenantId, asylumRefNumber, email, identity, nationalIdentityNumber, passportNumber, phone1, options).then((request) => request(axios, basePath));
        },
        /**
         * This will create a new customer profile under the tenant
         * @summary Register a new Customer
         * @param {number} tenantId 
         * @param {NewEclipseCustomer} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCustomersPost(tenantId: number, body?: NewEclipseCustomer, options?: any): AxiosPromise<EclipseCustomer> {
            return DCustomerManagementApiFp(configuration).tenantsTenantIdCustomersPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DCustomerManagementApi - object-oriented interface
 * @export
 * @class DCustomerManagementApi
 * @extends {BaseAPI}
 */
export class DCustomerManagementApi extends BaseAPI {
    /**
     * This will return all addresses linked to the customer
     * @summary Get a list of the customers addresses
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdAddressesGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new address linked to the customer. You can give a customer multiple addresses.
     * @summary Add a new address for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseAddress} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdAddressesPost(tenantId: number, customerId: number, body?: NewEclipseAddress, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdAddressesPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will return all attachments linked to the customer
     * @summary Get a list of the customers attachments
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdAttachmentsGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdAttachmentsGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * We can attached extra information of customer in form of  sticky notes, doc or images. We can add multiple attachment to the customers.Supported media types for application/pdf, image/png or image/jpeg,application/json,text/plain.
     * @summary Add a new attachment on the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseAttachment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdAttachmentsPost(tenantId: number, customerId: number, body?: NewEclipseAttachment, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdAttachmentsPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Customer Audit detail
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} [dateFromIncl] dateFromIncl, the date from where we want to get the Customer Audit Event inclusive.
     * @param {string} [dateToExcl] dateToExcl, the date upto when we want to get the Customer Audit Events exclusive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdAuditEventsGet(tenantId: number, customerId: number, dateFromIncl?: string, dateToExcl?: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdAuditEventsGet(tenantId, customerId, dateFromIncl, dateToExcl, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete a beneficiary
     * @summary Delete beneficiary
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} beneficiaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete(tenantId: number, customerId: number, beneficiaryId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdDelete(tenantId, customerId, beneficiaryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a specific beneficiary
     * @summary Get a customers beneficiary by beneficiaryId
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} beneficiaryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet(tenantId: number, customerId: number, beneficiaryId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdGet(tenantId, customerId, beneficiaryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update a customers beneficiary
     * @summary Update a customers beneficiary
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} beneficiaryId 
     * @param {UpdatedEclipseBeneficiary} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut(tenantId: number, customerId: number, beneficiaryId: number, body?: UpdatedEclipseBeneficiary, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesBeneficiaryIdPut(tenantId, customerId, beneficiaryId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all a customers beneficiaries
     * @summary Get a customers beneficiaries
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdBeneficiariesGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesGet(tenantId, customerId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new beneficiary
     * @summary Add a new beneficiary for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseBeneficiary} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdBeneficiariesPost(tenantId: number, customerId: number, body?: NewEclipseBeneficiary, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdBeneficiariesPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all cards held by the customer
     * @summary Get a customers cards
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete a card on file
     * @summary Delete card on file
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} cardOnFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(tenantId: number, customerId: number, cardOnFileId: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(tenantId, customerId, cardOnFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
     * @summary Get a customers cards on file by cardOnFileId
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} cardOnFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet(tenantId: number, customerId: number, cardOnFileId: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdGet(tenantId, customerId, cardOnFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update a customers card on file
     * @summary Update a customers cards on file
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} cardOnFileId 
     * @param {UpdatedEclipseCardOnFile} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(tenantId: number, customerId: number, cardOnFileId: string, body?: UpdatedEclipseCardOnFile, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(tenantId, customerId, cardOnFileId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is a generic way of initiating a HTTP request to a permitted partner where Eclipse will replace cardholder data tokens (pan, expiry, cvv etc) with the actual card data. This allows finegrained control of calls to payment gateways without the Eclipse tenant needing to be PCI compliant as they will never be exposed to card holder data.
     * @summary Use a card on file in a HTTP request to a permitted partner API
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} cardOnFileId 
     * @param {CardOnFileHttpRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost(tenantId: number, customerId: number, cardOnFileId: string, body?: CardOnFileHttpRequest, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdSensitiveTransactionsPost(tenantId, customerId, cardOnFileId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all cards on file held by the customer. This has nothing to do with Eclipse issued cards. This is for any card the customer wants to store on file with Eclipse
     * @summary Get a customers cards on file
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId, customerId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * There are 3 scenarios. First is where a cardId and alias are provided. In this case the card must be added as a CoF on the card holder and the Eclipse issued card will be added to Card on file and will return the cardOnFileId. The second scenario is where cardId is not provided and nor is any card data. A completion URL will be returned which can be opened in an iFrame for the customer to add card details. Once completed, the CardOnFile will be POSTED to the callbackUrl. The third scenario is where the tenant is PCI-DSS compliant and directly sends the card data. The CoF will be created and returned immediately with no completion URL necessary.
     * @summary Initiate adding a card on file for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseCardOnFile} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsOnFilePost(tenantId: number, customerId: number, body?: NewEclipseCardOnFile, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsOnFilePost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is for addon and replacement cards. For a nett-new card, create a new wallet which supports cards. If physical card details are provided then an existing physical card will be used or else a virtual card will be issued
     * @summary Issue a replacement or addon card to the customer for an existing card on their profile
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseCard} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdCardsPost(tenantId: number, customerId: number, body?: NewEclipseCard, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdCardsPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will only be allowed if the customer has no wallets, positions, cards etc.
     * @summary Delete a customer profile
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDelete(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDelete(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Sends a push message
     * @summary Send a message to the customers applicable device token
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseDeviceMessage} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDeviceMessagesPost(tenantId: number, customerId: number, body?: NewEclipseDeviceMessage, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDeviceMessagesPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all a customers device tokens
     * @summary Get a customers device tokens
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDeviceTokensGet(tenantId: number, customerId: number, limit?: number, offset?: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensGet(tenantId, customerId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new device token
     * @summary Add a new device token for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseDeviceToken} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDeviceTokensPost(tenantId: number, customerId: number, body?: NewEclipseDeviceToken, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete a specific device token
     * @summary Delete a specific device token
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete(tenantId: number, customerId: number, tokenId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdDelete(tenantId, customerId, tokenId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a specific device token
     * @summary Get a specific device token
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet(tenantId: number, customerId: number, tokenId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDeviceTokensTokenIdGet(tenantId, customerId, tokenId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will return all documents linked to the customer. If the includeHistoricalDocument=true, it will include the historical document of users
     * @summary Get list of the customers documents
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} [fields] Fields to be included. By default returs all fields. Specify onlyMetaData to receive metadata without document
     * @param {boolean} [includeHistoricalDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId: number, customerId: number, fields?: string, includeHistoricalDocument?: boolean, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId, customerId, fields, includeHistoricalDocument, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is for KYC and other regulatory requirements
     * @summary Add a new document against the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseDocument} [body] 
     * @param {boolean} [performOcr] If true, will perform the OCR on uploaded document
     * @param {boolean} [returnFullResult] If true then the document will be returned in the result. If false (default) then only the metadata will be returned
     * @param {boolean} [validateDocType] If true, verify that the document type matches the uploaded document. This will only work if the performOcr is true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdDocumentsPost(tenantId: number, customerId: number, body?: NewEclipseDocument, performOcr?: boolean, returnFullResult?: boolean, validateDocType?: boolean, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdDocumentsPost(tenantId, customerId, body, performOcr, returnFullResult, validateDocType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieves a specific customer
     * @summary Get a customer profile
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the identities of the customer
     * @summary Get a list of the customers identities
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdIdentitiesGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Check if a customer has any identities without needing read permissions on the customer itself. A JWT is required though
     * @summary Check if a customer has any identities
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdIdentitiesHead(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete the identity so it cannot be used
     * @summary Delete an identity for a customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} identity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete(tenantId: number, customerId: number, identity: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdIdentitiesIdentityDelete(tenantId, customerId, identity, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * It is the responsibility of the tenant to ensure they have verified that the customer is the one setting the new password
     * @summary Update a password for a customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} identity 
     * @param {UpdatedEclipseIdentity} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId: number, customerId: number, identity: string, body?: UpdatedEclipseIdentity, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdIdentitiesIdentityPut(tenantId, customerId, identity, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Customers can then get a JWT with these credentials and access their profile, wallets, cards etc. This is necessary when tenants want to give their customers direct access to the APIs or for customers using companion Apps. If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 For generating a special CAP (card access password) for a customer to view unmasked card details but without creating a proper identity on Eclipse, one can create an identity with identity \"CAP\".If this is done with a tenant JWT and no password then a random password is generated by Eclipse and SMS'd to the customers phone on profile. If CAP is passed with no JWT and no password then an SMS with an OTP is sent to the customer. This API can then be called passing CAP, a password to set and the OTP (again with no JWT).Note that a CAP cannot be used for getting a JWT. It can only be used to get card details. To create a WebAuthN identity, just pass an identity. Then proceed with getting a login-challenge to initiate the WebAuthN registration process.
     * @summary Create a username/password for a customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseIdentity} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId: number, customerId: number, body?: NewEclipseIdentity, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns payments that were initiated against the customer
     * @summary Get a list of payments for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} [amount] 
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [paymentReference] 
     * @param {string} [paymentType] 
     * @param {string} [status] 
     * @param {number} [walletId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId: number, customerId: number, amount?: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, paymentReference?: string, paymentType?: string, status?: string, walletId?: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdPaymentsGet(tenantId, customerId, amount, dateFromIncl, dateToExcl, limit, offset, paymentReference, paymentType, status, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The first step for a customer to make a payment
     * @summary Initiate making a payment by a customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseCustomerPayment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId: number, customerId: number, body?: NewEclipseCustomerPayment, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
     * @summary Get a list of positions held by the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdPositionsGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdPositionsGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Positions are held by customers in an organisation. E.g. CEO, emplyee etc. Positions impact security access to wallets held by the organisation
     * @summary Delete a position held by a customer in an organisation
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} organisationId 
     * @param {string} position 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete(tenantId: number, customerId: number, organisationId: number, position: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdPositionsOrganisationIdPositionDelete(tenantId, customerId, organisationId, position, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Positions are held by customers in an organisation. E.g. CEO, employee etc. Positions impact security access to wallets held by the organisation
     * @summary Add a position held by the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {EclipseCustomerPosition} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId: number, customerId: number, body?: EclipseCustomerPosition, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdPositionsPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update allowed fields on the customer profile
     * @summary Update a customer profile
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {EclipseCustomer} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdPut(tenantId: number, customerId: number, body?: EclipseCustomer, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdPut(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The results are returned from most recent to oldest so pass offset=0 and limit=1 for the most recent one
     * @summary Get the ratify results for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [ratifyType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdRatifyGet(tenantId: number, customerId: number, limit?: number, offset?: number, ratifyType?: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdRatifyGet(tenantId, customerId, limit, offset, ratifyType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will ratify the customers profile and attached documentation and provide an updated KYC status. Populate request body only if you want to do manual ratify or change from default ratify type of NORMAL, else left blank
     * @summary Run a KYC algorithm on the customers profile to update the KYC status
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {EclipseKycRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdRatifyPost(tenantId: number, customerId: number, body?: EclipseKycRequest, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdRatifyPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Logs out all JWT's issued to the customer
     * @summary Remove all sessions so the customer has to login again
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdSessionsDelete(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdSessionsDelete(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Wallet type allowed is true that means  customer is enough ratified to use this wallet type for create wallet/card and wallet type is  false that  means customer is not enough ratified to use this wallet type.
     * @summary Get a list of allowed wallet types for a customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId: number, customerId: number, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Lists all the wallets directly owned by the customer
     * @summary Get list of wallets belonging to the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {string} [accountNumber] 
     * @param {string} [friendlyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdWalletsGet(tenantId: number, customerId: number, accountNumber?: string, friendlyId?: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdWalletsGet(tenantId, customerId, accountNumber, friendlyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Creates a new wallet linked to the customer. If this is a card wallet then it will be created along with the first linked card which can be retrieved via the cards endpoint
     * @summary Create a new Wallet for the customer
     * @param {number} tenantId 
     * @param {number} customerId 
     * @param {NewEclipseWallet} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersCustomerIdWalletsPost(tenantId: number, customerId: number, body?: NewEclipseWallet, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersCustomerIdWalletsPost(tenantId, customerId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is a general API for searching for customers based on various criteria. If fields=customerId is passed as a query parameter, then only the customerId of each matching customer would be returned and one does not need permissions to read the customers full profile. It then behaves the same as the HEAD method but returns the customerId of each customer as opposed to no body at all.
     * @summary Search for customers in the tenant
     * @param {number} tenantId 
     * @param {string} [asylumRefNumber] 
     * @param {string} [email] 
     * @param {string} [externalUniqueId] 
     * @param {string} [fields] Field data to include. Comma separated list. Supports customerId.
     * @param {string} [identity] 
     * @param {boolean} [includeAdminUsers] 
     * @param {string} [lastName] 
     * @param {number} [limit] 
     * @param {string} [nationalIdentityNumber] 
     * @param {number} [offset] 
     * @param {string} [passportNumber] 
     * @param {string} [phone1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersGet(tenantId: number, asylumRefNumber?: string, email?: string, externalUniqueId?: string, fields?: string, identity?: string, includeAdminUsers?: boolean, lastName?: string, limit?: number, nationalIdentityNumber?: string, offset?: number, passportNumber?: string, phone1?: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersGet(tenantId, asylumRefNumber, email, externalUniqueId, fields, identity, includeAdminUsers, lastName, limit, nationalIdentityNumber, offset, passportNumber, phone1, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Check if a customer exists in the tenant without needing read permissions on the customer itself. A JWT is required though.
     * @summary Check if a customer exists
     * @param {number} tenantId 
     * @param {string} [asylumRefNumber] 
     * @param {string} [email] 
     * @param {string} [identity] 
     * @param {string} [nationalIdentityNumber] 
     * @param {string} [passportNumber] 
     * @param {string} [phone1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersHead(tenantId: number, asylumRefNumber?: string, email?: string, identity?: string, nationalIdentityNumber?: string, passportNumber?: string, phone1?: string, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersHead(tenantId, asylumRefNumber, email, identity, nationalIdentityNumber, passportNumber, phone1, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will create a new customer profile under the tenant
     * @summary Register a new Customer
     * @param {number} tenantId 
     * @param {NewEclipseCustomer} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DCustomerManagementApi
     */
    public tenantsTenantIdCustomersPost(tenantId: number, body?: NewEclipseCustomer, options?: any) {
        return DCustomerManagementApiFp(this.configuration).tenantsTenantIdCustomersPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
