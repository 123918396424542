import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { controlLabelColor } from "../../../style.scss";
import { loginToApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { DocumentType } from "eclipse-react-sdk/services/models";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
const useStyles = makeStyles(themeStyle);

export function DocumentInitial(props) {
  const selectedDocument = useLocation().state?.selectedDoc;
  const document = useLocation().state?.document;
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  const classes = useStyles();
  const history = useHistory();
  const idDocument = props?.appData?.documents ? props?.appData?.documents.find(doc => doc?.documentType === document?.type) : null;
  const idDocumentBack = props?.appData?.documents ? props?.appData?.documents.find(doc => doc?.documentType === DocumentType.BACKOFNATIONALIDENTITY) : null;
  const [idDocumentImageData, setIdDocumentImageData] = useState(null);
  const [idDocumentBackImageData, setIdDocumentBackImageData] = useState(null);
  const goToScanDocument = () => {
    let pathname= `/pages/scan-document`;
    if(idDocumentImageData){
      pathname= `/pages/upload-document`;
    }
    history.push({
      pathname,
      state: {
        selectedDoc: selectedDocument,
        fromScreen,
        isAddressAdded,
        document,
        idDocument,
        idDocumentBack,
        idDocumentImageData,
        idDocumentBackImageData
      },
    });
  }

  const backToHome = useCallback(() => {
    history.push({
      pathname: '/pages/identity-document',
      state: {
        fromScreen,
        isAddressAdded
      }
    });
  }, [history,fromScreen,isAddressAdded])

  React.useEffect(() => {
    window.onpopstate=()=>{
      backToHome();
    }
  }, [history, backToHome])

  React.useEffect(() => {
    if (idDocument && idDocument?.mediaType && idDocument?.base64EncodedDocument && fromScreen !== 'ASSISTED_SIGNUP') {
      setIdDocumentImageData(`data:${idDocument?.mediaType};base64,${idDocument?.base64EncodedDocument}`);
    }
    if (idDocumentBack && idDocumentBack?.mediaType && idDocumentBack?.base64EncodedDocument) {
      setIdDocumentBackImageData(`data:${idDocumentBack?.mediaType};base64,${idDocumentBack?.base64EncodedDocument}`);
    }
  }, [ idDocument, setIdDocumentImageData, setIdDocumentBackImageData, idDocumentBack, fromScreen]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{display: 'flex',alignItems: 'center'}}>
            <p onClick={backToHome} style={{display: 'flex',alignItems: 'center', fontSize:18}}>
              <ArrowBackIosIcon />
              <span >{"Back"}</span>
            </p>
            <small style={{ color: controlLabelColor,marginLeft:"auto" }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 3 : 2} of 5</small>
            </div>
            <strong><small style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '60%' } : { width: '40%' }}>&nbsp;</small></strong>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h1 className='auth-title' dangerouslySetInnerHTML={{ __html: fromScreen === 'ASSISTED_SIGNUP' ? document?.assistedSignupDescription : document?.description }} />
                  <p className={classes.regularFontSize + ' m-b-0'}><strong>{fromScreen === 'ASSISTED_SIGNUP' ? 'Make sure the name, all numbers and photo are clear and visible.' : 'Make sure your name, all numbers and photo are clear and visible.'}</strong></p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                onClick={goToScanDocument}>Let's go</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  documentInitialHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInitial)
