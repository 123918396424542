import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { primaryColor } from "../../style.scss";
import Danger from "../../components/Typography/Danger";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { AAuthenticationApi } from "eclipse-react-sdk/services/apis/aauthentication-api";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import { DCustomerManagementApi } from "eclipse-react-sdk/services/apis/dcustomer-management-api";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import JWTDecode from "../../jwt-decode.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { EnvConfig } from "../../environment.js";
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { load } from 'recaptcha-v3'
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ResetInputControlsValues,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";
import {
  AuthQueryParamWithTemplateId,
  AuthRouteWithoutTemplateId,
  AuthRouteWithTemplateId
} from "../../default-route.js";
import { ReturnDomainHostName, ReturnQueryParamTemplateId, ReturnStoredTemplateId } from "../../get-template-hostname.js";
import WhatsAppUserInstructionDialog from "./WhatsAppUserInstructionDialog.js";
import { ReactComponent as WhatsAppIcon } from '../../assets/img/whatsapp_icon.svg'
import ReturnMobileNumberWithISD from "../returnMobileNumberWithISD.js";
import { EclipseTenantPosition, OrganisationPosition } from "../../eclipse-react-sdk/services/models";

const axios = require('axios');

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function Login(props) {
  const template = props?.appData?.template;
  const pwaAppOptions = template?.pwaAppOptions;
  const identityPrefix = template?.identityPrefix;
  const whatsAppChannelEnabled = template?.whatsAppChannelEnabled;
  const whatsAppDefaultISDCode = template?.whatsAppDefaultISDCode;
  const defaultMobileNumberRegEx = template?.defaultMobileNumberRegEx;
  const defaultUsernameRegEx = template?.defaultUsernameRegEx;
  // const registrationLinkEnabled = pwaAppOptions?.registrationLinkEnabled;
  const phoneNumberAsUsername = pwaAppOptions?.phoneNumberAsUsername;
  const pinAsPassword = pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = pwaAppOptions?.pinPasswordLength;
  const storeUsername = pwaAppOptions?.storeUsername;
  const kycRequired = pwaAppOptions?.kycRequired;
  const walletsDisplay = pwaAppOptions?.walletsDisplay;
  const usernameText = phoneNumberAsUsername ? 'Phone number' : 'Username' + (whatsAppChannelEnabled ? '/WhatsApp phone number' : '');
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const passwordPINControlName = passwordPINText.toLowerCase();
  const passwordPINInputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINInputControlGridLength = 12 / passwordPINInputControls;
  const [openWhatsAppUserInstructionDialog, setOpenWhatsAppUserInstructionDialog] = React.useState(false);
  const storedUserExisted = storeUsername && localStorage.getItem('UID') ? window.localStorage.getItem('UID') : '';
  const [defaultIdentity] = useState(storeUsername && storedUserExisted && storedUserExisted.trim() !== '' ? storedUserExisted.trim() : null);
  const loginTitleText = defaultIdentity ? `Your about to sign in to your account with your ${usernameText}` : `Sign in with your ${usernameText} and ${passwordPINText}.`;
  const landingTemplate = template?.landingTemplate || "Default";
  const [clicks, setClicks] = useState(0);
  const [openBackDraftDialog, setBackDraftDialog] = useState(false);
  const [backDraftURLs, setBackDraftURLs] = useState([]);
  const [backDraftInputs, setBackDraftInputs] = useState({ backDraft: '' });
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const passwordClasses = passwordStyles();
  const history = useHistory();

  const [inputs, setInputs] = useState({ identity: defaultIdentity ? defaultIdentity : '', ...CreateNewInputControls(passwordPINControlName, passwordPINInputControls) });
  const [errors, setErrors] = useState({});
  const [backDraftErrors, setBackDraftErrors] = useState({});
  const [alertData, setAlert] = useState(null);
  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const returnUserName = () => {
    if ((whatsAppChannelEnabled || phoneNumberAsUsername) && inputs?.identity.length > 8 && !isNaN(inputs?.identity)) {
      return ReturnMobileNumberWithISD(whatsAppDefaultISDCode, inputs?.identity);
    }
    return inputs?.identity;
  }

  const loginSuccess = (status, kycFlag) => {
    localStorage.setItem('session-time', `${(new Date()).getTime()}`);
    localStorage.removeItem("UID");
    if (storeUsername) {
      localStorage.setItem("UID", returnUserName());
    }
    if (status === "ACTIVE") {
      if (!kycFlag && kycRequired) {
        doRatifyKYC();
      } else {
        history.push('/pages/dashboard');
      }
    } else {
      history.push('/pages/success-account-deactivate');
    }
  }

  const appLogout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = ReturnQueryParamTemplateId();
      if (!templateId) {
        templateId = ReturnDomainHostName();
      }
    }
    const appData = {
      templateId: templateId
    }
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [history, props]);


  React.useEffect(() => {
    window.onpopstate = () => {
      appLogout();
    }
    // if (!defaultIdentity && landingTemplate === "PAYAT") {
    //   history.push(AuthRouteWithTemplateId('login'));
    // }
  }, [history, defaultIdentity, landingTemplate, appLogout])

  const redirectToResetPassword = (userIdentity) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('reset-password')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { userIdentity }
    });
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen },
    });
  }, [history]);

  const redirectToSignup = (fromScreen = "LOGIN") => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('signup')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen },
    });
  }

  // const resetDefaultIdentity = () => {
  //   setDefaultIdentity(null);
  //   inputs.identity = '';
  //   setInputs({ ...inputs });
  // }

  const generateOTP = (event) => {
    if (event) event.preventDefault();
    const validationErrors = validateIdentity();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
        reCaptcha.execute('OTP_VERIFICATION').then((token) => {
          if (token) {
            const headers = { gCaptcha: token };
            const httpRequestConfiguration = HttpRequestConfiguration();
            const resetPasswordAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
            httpRequestConfiguration.baseOptions = { headers };
            resetPasswordAPI.globalIdentitiesIdentityPasswordChangeInitPost(`${identityPrefix}-${returnUserName()}`).then(() => {
              redirectToResetPassword(returnUserName());
            }).catch((error) => {
              setLoading(false);
              if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
              } else {
                redirectToErrorScreen(`/auth/login`, `error`);
              }
            });
          } else {
            showAlert(true, 'ERROR', `Please try again!`);
          }
        })
      });
    }
  }

  const getCustomerDetails = (authAPIResponse, authDecoder, httpRequestConfiguration) => {
    let role = authDecoder?.pos.find((pos) => pos?.p === EclipseTenantPosition.LEVEL10 || OrganisationPosition.HEAD);
    setLoading(true);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersCustomerIdGet(authAPIResponse.data.tenantId, authDecoder.uid).then(async (customerAPIResponse) => {
      const userRole = authDecoder?.pos.length > 0 ? role?.p : null;

      setLoading(false);
      const isRatified = await getRatify(authAPIResponse.data.tenantId, customerAPIResponse.data.customerId, httpRequestConfiguration);

      if (userRole === EclipseTenantPosition.LEVEL10 || userRole === OrganisationPosition.HEAD) {
        let organisationId = authDecoder?.pos.find((pos) => pos?.o);
        await updateState(customerAPIResponse, role, authAPIResponse, authDecoder, organisationId?.o, isRatified);
      } else {
        await updateState(customerAPIResponse, role, authAPIResponse, authDecoder, null, isRatified);
      }

    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/auth/login`, `error`);
      }
    });
  }

  const updateState = async (customerAPIResponse, role, authAPIResponse, authDecoder, organisationId, isRatified) => {
    const appData = {
      ...props?.appData,
      customer: customerAPIResponse.data,
      identity: returnUserName(),
      auth: authAPIResponse.data,
      userRole: authDecoder?.pos.length > 0 ? role?.p : null,
      organisationId: organisationId,
      isRefresh: true,
    }
    props.loginHandler({ ...appData });

    if (appData?.userRole !== OrganisationPosition.HEAD && !isRatified && appData?.template?.manifestSettings?.appName === 'Vleissentraal') {
      showAlert(true, 'ERROR', 'Your KYC is pending, please contact your nearest agent!');
      const appData = {
        ...props?.appData,
        customer: null,
        identity: returnUserName(),
        auth: null,
        userRole: null,
        organisationId: null,
        isRefresh: true,
      }
      props.loginHandler({ ...appData });
    } else {
      loginSuccess(customerAPIResponse?.data?.status, isRatified);
    }
  }

  const getWalletTypesFromConfig = (authAPIResponse, authDecoder, httpRequestConfiguration) => {
    setLoading(true);
    const configAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
    let templateId = ReturnStoredTemplateId();
    if (!templateId) {
      templateId = ReturnDomainHostName();
    }
    const publicPWATenantConfig = `public.tenant.pwa.${templateId}`
    configAPI.globalConfigConfigNameGet(publicPWATenantConfig).then((configAPIResponse) => {

      if ((kycRequired && (!configAPIResponse.data?.ratifyRules || (configAPIResponse.data?.ratifyRules && ((!configAPIResponse.data?.ratifyRules?.NATIONAL_IDENTITY || (configAPIResponse.data?.ratifyRules?.NATIONAL_IDENTITY && configAPIResponse.data?.ratifyRules?.NATIONAL_IDENTITY.length < 1)) || (!configAPIResponse.data?.ratifyRules?.PASSPORT || (configAPIResponse.data?.ratifyRules?.PASSPORT && configAPIResponse.data?.ratifyRules?.PASSPORT.length < 1)))))) || (walletsDisplay && (!configAPIResponse.data['public.card.walletTypeId'] || !configAPIResponse.data['public.close_loop.walletTypeId']))) {
        setLoading(false);
        redirectToErrorScreen('/auth/login', 'error');
        return;
      }
      props.appData.template.publicCardWalletTypeId = configAPIResponse.data['public.card.walletTypeId'];
      props.appData.template.publicCloseLoopWalletTypeId = configAPIResponse.data['public.close_loop.walletTypeId'];
      const appData = {
        ...props?.appData,
        identity: returnUserName(),
        auth: authAPIResponse.data,
        isRefresh: true
      }
      props.loginHandler({ ...appData });
      setLoading(false);
      getCustomerDetails(authAPIResponse, authDecoder, httpRequestConfiguration);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/auth/login', 'error');
      }
    });
  }

  const handleLoginFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateLoginForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const loginAPI = new AAuthenticationApi(HttpRequestConfiguration(" "));
      const loginBody = {
        identity: `${identityPrefix}-${returnUserName()}`,
        password: ReturnInputControlsConcatValues(inputs, passwordPINControlName, passwordPINInputControls)
      }
      loginAPI.authenticationLoginPost(loginBody).then((authAPIResponse) => {
        let authDecoder = JWTDecode(authAPIResponse.data.headerValue.split(' ')[1]);
        setLoading(false);
        const httpRequestConfiguration = HttpRequestConfiguration(authAPIResponse?.data?.headerValue);
        getWalletTypesFromConfig(authAPIResponse, authDecoder, httpRequestConfiguration);
      }).catch((error) => {
        setLoading(false);
        ResetInputControlsValues(inputs,passwordPINControlName, passwordPINInputControls);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen(`/auth/login`, `error`);
        }
      });
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, passwordPINInputControls);
    setInputs(inputControlValue);
    const validationErrors = validateLoginForm();
    setErrors(validationErrors);
  }

  const handleBackDraftInputChange = (event) => {
    event.persist();
    backDraftInputs[event.target.name] = event.target.value;
    setBackDraftInputs(backDraftInputs);
    const backDraftValidationErrors = validateBackDraftForm(backDraftInputs);
    setBackDraftErrors(backDraftValidationErrors);
  }

  const validateBackDraftForm = (backDraftInputs) => {
    const errors = {}
    if (!backDraftInputs.backDraft) {
      errors.backDraft = 'Back-draft url required!'
    } else if (!/^[a-zA-Z0-9:/.-]{3,}$/i.test(backDraftInputs.backDraft)) {
      errors.backDraft = 'Invalid Back-draft!'
    }
    return errors
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateLoginForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, passwordPINInputControls);
        setInputs(inputControlValue);
        const validationErrors = validateLoginForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateLoginForm = () => {
    let errors = {}
    const pattern = phoneNumberAsUsername ? new RegExp(defaultMobileNumberRegEx) : new RegExp(defaultUsernameRegEx);
    if (!inputs?.identity) {
      errors.identity = `${usernameText} required!`;
      return errors;
    } else if (!pattern.test(inputs?.identity)) {
      errors.identity = `Invalid ${usernameText}!`;
      return errors;
    }
    errors = ValidateInputControls(inputs, passwordPINControlName, passwordPINInputControls, passwordPINText, pinPasswordLength);
    return errors
  }

  const validateIdentity = () => {
    const errors = {}
    const pattern = phoneNumberAsUsername ? new RegExp(defaultMobileNumberRegEx) : new RegExp(defaultUsernameRegEx);
    if (!inputs?.identity) {
      errors.identity = `${usernameText} is required to reset ${passwordPINText}. Please enter ${usernameText}.`
    } else if (!pattern.test(inputs?.identity)) {
      errors.identity = `Invalid ${usernameText}!`
    }
    return errors
  }

  const closeDialog = () => {
    setBackDraftDialog(false);
    setClicks(0);
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
  };

  const onClosePopup = () => {
    setBackDraftDialog(false);
    EnvConfig().BASE_API_URL = `${backDraftInputs?.backDraft}/eclipse-conductor/rest/v1`;
    setClicks(0);
  }

  async function getBackDraftURLs() {
    setLoading(true);
    try {
      const backDraftUrls = await axios.get(EnvConfig().BACKDRAFT_URL);
      setLoading(false);
      if (backDraftUrls?.data && backDraftUrls?.data.length > 0) {
        setBackDraftDialog(true);
        setBackDraftURLs(backDraftUrls?.data);
      } else {
        EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
        showAlert(true, 'ERROR', `Back-draft URL(s) not found!`);
      }
    } catch (error) {
      setLoading(false);
      EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/auth/login`, `error`);
      }
    }
  }

  const callBackDraft = () => {
    setClicks(clicks + 1);
    if (clicks >= 15) {
      getBackDraftURLs();
    }
  }

  const handleWhatsAppSetPasswordRequest = () => {
    setOpenWhatsAppUserInstructionDialog(false);
    generateOTP();
  }

  const handleWhatsAppInstructionDialogClose = () => {
    setOpenWhatsAppUserInstructionDialog(false);
  }

  const doRatifyKYC = () => {
    if (props?.appData?.template?.ratifyTrialCount > 2) {
      history.push({
        pathname: `/pages/contact-support`
      });
    } else {
      history.push({
        pathname: `/pages/identity-document`,
        state: { fromScreen: "LOGIN" }
      });
    }
  };

  const getRatify = async (tenantId, customerId, httpRequestConfiguration) => {
    const ratifyAPI = new DCustomerManagementApi(httpRequestConfiguration);
    return ratifyAPI.tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId, customerId, 1, 0).then((ratifyAPIResponse) => {
      if (ratifyAPIResponse.data && ratifyAPIResponse.data.length > 0) {
        let isRatifyFailed = 0;
        ratifyAPIResponse.data.forEach((ratifyAPIResponseData) => {
          if (!ratifyAPIResponseData?.allowed) {
            isRatifyFailed = isRatifyFailed + 1;
          }
        });
        if (isRatifyFailed <= 0) {
          return true;
        } else {
          return false;
        }
      }
    }).catch((error) => {
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });
  };

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form autoComplete="off" onSubmit={handleLoginFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 onClick={callBackDraft}>Welcome Back!</h1>
                <p className={classes.regularFontSize} style={{ paddingTop: '0px', marginBottom: '0px' }}>
                  <strong>{loginTitleText}</strong></p>
              </GridItem>
              {defaultIdentity ?
                <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px', marginBottom: '0px' }}>
                  <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '0px' }}><b>***
                    *** {defaultIdentity.substring(defaultIdentity.length - 4, defaultIdentity.length)}</b></p>
                </GridItem>
                : <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                  <ValidationTextField
                    fullWidth
                    inputProps={{
                      maxLength: phoneNumberAsUsername ? 16 : 20,
                      inputMode: phoneNumberAsUsername ? 'tel' : ''
                    }}
                    label={usernameText}
                    variant="filled"
                    id="identity"
                    type="text"
                    error={!!(errors.identity)}
                    name="identity"
                    value={inputs?.identity || ""}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp} />
                  {errors.identity && <Danger><small>{errors.identity}</small></Danger>}
                </GridItem>}
            </GridContainer>
            <GridContainer>
              {passwordPINInputControls > 1 ?
                <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px', paddingBottom: '10px' }}>
                  <p className={classes.regularFontSize} style={{ marginBottom: '0px' }}><strong><b>Enter
                    your {passwordPINText}</b></strong></p>
                </GridItem>
                : null}

              {ReturnInputControlsMap(passwordPINControlName, passwordPINInputControls)?.map((control, index, array) => {
                return (
                  <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength}
                    md={passwordPINInputControlGridLength}
                    custom-padding={passwordPINInputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                    <ValidationTextField
                      inputProps={{
                        maxLength: passwordPINInputControls > 1 ? 1 : 50,
                        inputMode: pinAsPassword ? 'numeric' : ''
                      }}
                      autoComplete='new-password'
                      className={[passwordPINInputControls > 1 ? passwordClasses.oneDigitPassword : '', passwordPINInputControls === 4 ? ' fourDigitInput' : ''].join(' ')}
                      label={passwordPINInputControls > 1 ? '' : passwordPINText}
                      fullWidth
                      variant="filled"
                      id={control}
                      type="password"
                      error={!!(errors[passwordPINControlName])}
                      name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                      value={inputs[control] || ""}
                      onChange={handleInputChange}
                      onKeyDown={handleOnKeyDown}
                      onKeyUp={handleInputKeyUp} />
                  </GridItem>
                )
              })
              }
              <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                {errors[passwordPINControlName] && <Danger><small>{errors[passwordPINControlName]}</small></Danger>}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} m={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                  disabled={!!(errors.identity || errors[passwordPINControlName]) || !ReturnInputControlsConcatValues(inputs, passwordPINControlName, passwordPINInputControls)}>Let's go</Button>
                {/* {defaultIdentity && !phoneNumberAsUsername ?
                  <p className={classes.regularFontSize}>Sign-in as a different user? <span
                    className={classes.linkColor} onClick={resetDefaultIdentity}>Change {usernameText}</span></p>
                  : null}
                   {phoneNumberAsUsername ?
                    <p style={{paddingTop:'20px'}} className={classes.regularFontSize + " "+classes.textCenter}>
                      <span className={classes.linkColor} onClick={() => {
                        redirectToSignup("CHANGE_IDENTITY")
                      }}>
                        Change the {usernameText?usernameText.toLocaleLowerCase():""}
                      </span>
                    </p>
                    : null} */}
                <p className={classes.regularFontSize  + " "+classes.textCenter}>Forgot your {passwordPINText}? <span
                  className={classes.linkColor} onClick={generateOTP}>Reset it here</span></p>
                {/* {registrationLinkEnabled && !phoneNumberAsUsername ? */}
                  <p className={classes.regularFontSize + " "+classes.textCenter}>Not registered yet? <span
                    className={classes.linkColor} onClick={() => {
                      redirectToSignup()
                    }}>Sign up</span></p>
                  {/* : null} */}
                {whatsAppChannelEnabled ?
                  <p className={classes.regularFontSize}><WhatsAppIcon /> <span
                    style={{ top: '-0.8em', position: 'relative' }}>Signed up with WhatsApp? <span
                      className={classes.linkColor} onClick={() => {
                        setOpenWhatsAppUserInstructionDialog(true)
                      }}>Set your password</span></span></p>
                  : null}
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      <Dialog open={openBackDraftDialog}>
        <div style={{ float: 'left', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-t-0'}><b>Back-end URL(s)!</b></p>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-t-0'}>Which would you like to
            connect?</p>
          <RadioGroup name="backDraft" id="backDraft" value={backDraftInputs.backDraft}
            onChange={handleBackDraftInputChange}>
            {(backDraftURLs && backDraftURLs.length > 0) ?
              backDraftURLs.map((backDraftUrl) => {
                return (
                  <FormControlLabel value={backDraftUrl} control={<Radio />} label={backDraftUrl}
                    onKeyUp={handleBackDraftInputChange} key={backDraftUrl} />
                )
              }) : null}
          </RadioGroup>
          <ValidationTextField
            fullWidth
            label="Custom back-draft url"
            variant="filled"
            id="backDraft"
            type="backDraft"
            error={!!(backDraftErrors.backDraft)}
            name="backDraft"
            value={backDraftInputs.backDraft}
            onChange={handleBackDraftInputChange}
            onKeyUp={handleBackDraftInputChange} />
          {backDraftErrors?.backDraft && <Danger><small>{backDraftErrors?.backDraft}</small></Danger>}
          <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0' }}>
            <Button className={classes.buttonRadius + ' m-r-4'} size="sm" block color="transparent"
              onClick={closeDialog}>Cancel</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className={classes.buttonRadius} size="sm" block color="primary" onClick={onClosePopup}
              disabled={!!(!backDraftInputs?.backDraft || backDraftInputs?.backDraft === '' || backDraftErrors?.backDraft)}>Next</Button>
          </span>
        </div>
      </Dialog>
      <WhatsAppUserInstructionDialog openWhatsAppUserInstructionDialog={openWhatsAppUserInstructionDialog}
        handleWhatsAppSetPasswordRequest={handleWhatsAppSetPasswordRequest}
        handleWhatsAppInstructionDialogClose={handleWhatsAppInstructionDialogClose} />
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  loginHandler: data => dispatch(loginToApp(data)),
  logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
