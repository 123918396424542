import React, { useState, useCallback } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import QRCode from "react-qr-code";
import ScanToPayLogos from '../../assets/img/scan_to_pay_logos@3x.png'
// import HttpRequestConfiguration from "../../http-request-configuration.js";
import { HWalletsApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions.js";


const useStyles = makeStyles(themeStyle);

export function DisplayQR(props) {
  //console.log("DisplayQR Props :: ", props?.appData);
  const walletDetailData = useLocation().state?.walletDetailData;
  //console.log("WalletDetailData :: ", walletDetailData);
  const generatedQRData = useLocation().state?.generatedQRData;
  //console.log("GeneratedQRData :: ", generatedQRData);

  const currentDate = new Date();
  currentDate.setUTCHours(0);
  currentDate.setUTCMinutes(0);
  currentDate.setUTCSeconds(0);
  currentDate.setUTCMilliseconds(0);
  const toDate = new Date();
  toDate.setUTCHours(23);
  toDate.setUTCMinutes(59);
  toDate.setUTCSeconds(59);
  toDate.setUTCMilliseconds(999);
  const dateFromIncl = new Date(currentDate).toISOString();
  const dateToExcl = new Date(toDate).toISOString();

  const history = useHistory();
  const classes = useStyles();

  const [alertData, setAlert] = useState(null);
  const [clearTimeOut, setClearTimeOut] = useState(null);
  const [isGetQRTransactions, setQRTransactions] = useState(true);

  const showAlert = useCallback((alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }, [setAlert]);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const getQRPaidStatus = useCallback((successQRPaidData) => {
    clearTimeout(clearTimeOut);
    props.appData.isRefresh = true;
    props.displayQRHandler({...props?.appData});
    history.push({
      pathname: '/pages/qr-paid-status',
      state: { walletDetailData: walletDetailData, successQRPaidData: successQRPaidData }
    });
  }, [history, walletDetailData, clearTimeOut, props]);

  const backToQRGenerate = useCallback(() => {
    clearTimeout(clearTimeOut);
    props.appData.isRefresh = true;
    props.displayQRHandler({...props?.appData});
    history.push({
      pathname: '/pages/qr-generate'
    });
  }, [clearTimeOut, history, props]);

  const getWalletQRTransactions = useCallback(() => {
    // const httpRequestConfiguration = HttpRequestConfiguration(props?.appData?.auth?.headerValue);
    const walletQRPaidTransactionAPI = new HWalletsApi();//httpRequestConfiguration
    walletQRPaidTransactionAPI.tenantsTenantIdWalletsWalletIdTransactionsGet(props?.appData?.auth?.tenantId, walletDetailData?.walletId, dateFromIncl, dateToExcl).then((walletQRPaidTransactionAPIResponse) => {
      let findQRPaidSuccessData = [];
      findQRPaidSuccessData = walletQRPaidTransactionAPIResponse?.data.filter((transactionData) => transactionData?.description === generatedQRData?.description);
      if (findQRPaidSuccessData.length > 0) {
        //console.log("FindQRPaidSuccessData :: ", findQRPaidSuccessData[0]);
        getQRPaidStatus(findQRPaidSuccessData[0]);
      } else {
        setClearTimeOut(
          setTimeout(() => {
            getWalletQRTransactions();
          }, 3000)
        );
      }
    }).catch((error) => {
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/qr-generate', '/pages/error');
      }
    });
  }, [props, showAlert, setClearTimeOut, redirectToErrorScreen, dateFromIncl, dateToExcl, getQRPaidStatus, generatedQRData, walletDetailData]);

  React.useEffect(() => {
    if (isGetQRTransactions) {
      setQRTransactions(false);
      getWalletQRTransactions();
    }
  }, [getWalletQRTransactions, setQRTransactions, isGetQRTransactions]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToQRGenerate}/>
              <span className={classes.title}>Scan QR code</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {generatedQRData?.code ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.title + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Scan to pay</b></p>
                {generatedQRData?.amount > 0 ?
                  <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+generatedQRData?.amount).toFixed(2)}</b></h2>
                  : null
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + ' m-b-0 m-t-0'}>
                  <QRCode value={generatedQRData?.code} size={164} />
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>{generatedQRData?.code}</b></p>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <div style={{ border: `1px solid ${primaryColor}`, margin: '0.7em 0', width: '100%' }} ></div>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <p style={{ color: primaryColor, margin: '0' }} className={classes.textCenter}><b>Pay with</b></p>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <div style={{ border: `1px solid ${primaryColor}`, margin: '0.7em 0', width: '100%' }} ></div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <img alt="" src={ScanToPayLogos} style={{ width: '100%' }} />
          </GridItem>
        </GridContainer>
        : null
      }
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  displayQRHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DisplayQR)