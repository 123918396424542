/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Status of the eclipse customer. ACTIVE:Customer is Active, INACTIVE customer is INACTIVE
 * @export
 * @enum {string}
 */
export enum EclipseCustomerStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

