import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { TextField } from "@material-ui/core";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger.js";
import { AddressType } from "eclipse-react-sdk/services/models";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function AddPersonalInformation(props) {
  const saProvincesList = props?.appData?.template?.saProvincesList;
  const termsAndConditionsLink = props?.appData?.template?.defaultPWATandCLink;
  const history = useHistory();
  const classes = useStyles();
  const [isBlockEstate, setBlockEstateToggle] = React.useState(true);
  const isVirtualCardAdded = useLocation().state?.isVirtualCardAdded;
  const pageState = useLocation().state;
  const createWalletData = useLocation().state?.inputs;
  const personalInfo = { dateOfBirth: "", gender: "", maritalStatus: "" };
  const addressInfo = {
    addressType: AddressType.PHYSICAL,
    line1: "",
    line2: "",
    line3: "",
    city: "",
    state: "",
    code: ""
  };
  const [inputs, setInputs] = useState({ personalInfo: personalInfo, addressInfo: addressInfo });
  const [errors, setErrors] = useState({});
  const [acceptTC, setAcceptTC] = React.useState(false);
  const goToConfirmCreateWallet = () => {
    const data = { ...pageState, ...inputs, ...createWalletData, fromScreen: "PI", isVirtualCardAdded: isVirtualCardAdded };
    history.push({
      pathname: '/pages/confirm-create-virtual-card',
      state: data
    });
  };
  const backToCreateVC = useCallback(() => {
    history.replace({
      pathname: '/pages/create-virtual-card',
      state: { isVirtualCardAdded: isVirtualCardAdded }
    });

  }, [history, isVirtualCardAdded])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToCreateVC();
      }
    };
  }, [history, backToCreateVC])

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateCreateWalletForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      goToConfirmCreateWallet();
    }
  }

  const handleInputChange = (event) => {
    if (event.target.name === "dateOfBirth" || event.target.name === "gender" || event.target.name === "maritalStatus") {
      inputs.personalInfo[event.target.name] = event.target.value;
    } else {
      inputs.addressInfo[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateCreateWalletForm();
    setErrors(validationErrors);
  }

  const validateCreateWalletForm = () => {
    const errors = {}
    for (const [keys] of Object.entries(inputs)) {
      for (const [key, value] of Object.entries(inputs[keys])) {
        if (isBlockEstate && key === 'line1' && value === "") {
          errors[key] = "This field is required.";
        }
        if (key !== 'line1' && key !== 'line3' && value === "") {
          errors[key] = "This field is required.";
        }
      }
    }
    return errors
  }

  const changeAcceptTC = (event) => {
    event.persist();
    setAcceptTC((prev) => !prev);
  }
  const toggleBlockEstate = (event) => {
    event.persist();
    setBlockEstateToggle((prev) => !prev);    
    inputs.addressInfo.line1 = '';
    inputs.addressInfo.line2 = '';
    setInputs(inputs);
    const validationErrors = validateCreateWalletForm();
    setErrors(validationErrors);
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToCreateVC} />
              <span className={classes.title}>Create virtual card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p><b>Please complete the following information. </b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={`Date of birth`}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                  id="dateOfBirth"
                  type="date"
                  error={!!(errors.dateOfBirth)}
                  name="dateOfBirth"
                  value={inputs.personalInfo.dateOfBirth}
                  onChange={handleInputChange}
                />
                {errors.dateOfBirth && <Danger><small>{errors.dateOfBirth}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Gender"}
                  select
                  variant="filled"
                  id="fromWallet"
                  type="text"
                  name="gender"
                  value={inputs.personalInfo.gender}
                  onChange={handleInputChange}>
                  <MenuItem key={"male"} value={"M"}>{"Male"}</MenuItem>
                  <MenuItem key={"female"} value={"F"}>{"Female"}</MenuItem>
                  <MenuItem key={"other"} value={"O"}>{"Other"}</MenuItem>
                </ValidationTextField>
                {errors.gender && <Danger><small>{errors.gender}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Marital status"}
                  select
                  variant="filled"
                  id="maritalStatus"
                  type="text"
                  name="maritalStatus"
                  value={inputs.personalInfo.maritalStatus}
                  onChange={handleInputChange}>
                  <MenuItem key={"divorced"} value={"D"}>{"Divorced"}</MenuItem>
                  <MenuItem key={"married"} value={"M"}>{"Married"}</MenuItem>
                  <MenuItem key={"single"} value={"S"}>{"Single"}</MenuItem>
                  <MenuItem key={"widowed"} value={"W"}>{"Widowed"}</MenuItem>
                </ValidationTextField>
                {errors.maritalStatus && <Danger><small>{errors.maritalStatus}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p><b>Please complete your address details. </b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px', paddingBottom: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p>Is your address in a block/estate?</p>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      onChange={toggleBlockEstate}
                      name="isBlockEstate"
                      checked={isBlockEstate}
                      value={isBlockEstate}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    /></div>
                </div>
              </GridItem>
              {isBlockEstate ?
                <GridItem xs={12} sm={12} md={12}>
                  <ValidationTextField
                    fullWidth
                    label={`Block/Estate name and unit number`}
                    variant="filled"
                    id="line1"
                    type="text"
                    error={!!(errors.line1)}
                    name="line1"
                    value={inputs.addressInfo.line1}
                    onChange={handleInputChange}
                  />
                  {errors.line1 && <Danger><small>{errors.line1}</small></Danger>}
                </GridItem>
                : null}
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={`Street name and number`}
                  variant="filled"
                  id="line2"
                  type="text"
                  error={!!(errors.line2)}
                  name="line2"
                  value={inputs.addressInfo.line2}
                  onChange={handleInputChange}
                />
                {errors.line2 && <Danger><small>{errors.line2}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={`City or Town`}
                  variant="filled"
                  id="city"
                  type="text"
                  error={!!(errors.city)}
                  name="city"
                  value={inputs.addressInfo.city}
                  onChange={handleInputChange}
                />
                {errors.city && <Danger><small>{errors.city}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Province"}
                  select
                  variant="filled"
                  id="state"
                  type="text"
                  name="state"
                  value={inputs.addressInfo.state}
                  onChange={handleInputChange}>
                  {saProvincesList.map((province) => {
                    return (<MenuItem key={province} value={province}>{province}</MenuItem>)
                  })}
                </ValidationTextField>
                {errors.state && <Danger><small>{errors.state}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={`Street code`}
                  variant="filled"
                  id="walletName"
                  type="number"
                  error={!!(errors.code)}
                  name="code"
                  value={inputs.addressInfo.code}
                  onChange={handleInputChange}
                />
                {errors.code && <Danger><small>{errors.code}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    name="acceptTC"
                    checked={acceptTC}
                    onChange={changeAcceptTC}
                    style={{ paddingLeft: '0' }}
                  />
                  <p className={classes.regularFontSize + ' m-t-0 m-b-0'}
                    style={{ lineHeight: '20px' }}>I accept my card's <a className={classes.linkColor} href={termsAndConditionsLink} target={"_blank"} rel={"noopener noreferrer"}>terms and conditions.</a>
                  </p>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                  disabled={!!Object.keys(errors).length > 0 || !acceptTC}>Next</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonalInformation)