/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Currently supported withdrawal types. ZA_PNP_CASH:Cash out at any Pick n Pay in South Africa, ZA_PNP_TENDER:Pay for goods at any Pick n Pay in South Africa, ZA_NEDBANK_EFT:EFT to any South African bank account via Nedbank CPS, ZA_PAYACCSYS_ATM:Withdraw cash at a Standard bank ATM with a token/pin, ZA_SHOPRITE_CASH:Cash out at any Checkers/Shoprite in South Africa, ZA_PAYCORP_ATM:Withdraw at any CashExpress ATM, GLOBAL_MFS: MFS Africa send to mobile money or account of cashout point, ZA_NEDBANK_EFT_IMMEDIATE:EFT to any South African bank account immediately via Nedbank CPS
 * @export
 * @enum {string}
 */
export enum WalletWithdrawalType {
    GLOBALCRYPTO = 'GLOBAL_CRYPTO',
    GLOBALMFS = 'GLOBAL_MFS',
    KEDTBEFT = 'KE_DTB_EFT',
    KEDTBMPESA = 'KE_DTB_MPESA',
    KEDTBPESALINK = 'KE_DTB_PESALINK',
    KEIPSLPESALINK = 'KE_IPSL_PESALINK',
    KETANDAMPESA = 'KE_TANDA_MPESA',
    KETANDAPESALINK = 'KE_TANDA_PESALINK',
    ZAMTNMOMOWALLET = 'ZA_MTN_MOMO_WALLET',
    ZANEDBANKEFT = 'ZA_NEDBANK_EFT',
    ZANEDBANKEFTIMMEDIATE = 'ZA_NEDBANK_EFT_IMMEDIATE',
    ZAPAYACCSYSATM = 'ZA_PAYACCSYS_ATM',
    ZAPAYCORPATM = 'ZA_PAYCORP_ATM',
    ZAPNPCASH = 'ZA_PNP_CASH',
    ZAPNPTENDER = 'ZA_PNP_TENDER',
    ZASTANDARDBANKEFT = 'ZA_STANDARDBANK_EFT',
    ZMMNOWALLET = 'ZM_MNO_WALLET'
}

