import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import {primaryColor, secondaryTextColor } from "../../style.scss"
import DeleteIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as SuccessCardIcon } from "../../assets/img/icon_add_a_card_success.svg"
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { whiteColor } from "../../assets/jss/eclipse.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { CardStatus } from "../../eclipse-react-sdk/services/models";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SpinnerComponent } from "react-element-spinner";
import { returnCardType } from "./CardUtils.js";

const useStyles = makeStyles(themeStyle);

export function ManageLinkedCard(props) {
  const pageState = useLocation().state;
  const walletDetailData = pageState?.walletDetailData;
  const fromScreen = pageState?.fromScreen;

  const [linkedBankCards, setLinkedBankCards] = useState([]);
  const [cardOnFileId, setCardOnFileId] = useState(null);
  const [openConfirmDialog, setOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccess] = useState(false);
  const [isGetLinkBankCard, setGetLinkBankCard] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const history = useHistory();
  const classes = useStyles();

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const linkCard = () => {
    history.push({
      pathname: '/pages/card-on-file',
      state: { ...pageState,fromScreen:'manage-linked-cards'}
    });
  }


  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.manageLinkedCardHandler(appData);
    if(fromScreen==="QRSCAN"){
        history.push({
          pathname: '/pages/pay-amount',
          state: { ...pageState}
        });
    }else{
      history.push({
        pathname: '/pages/dashboard'
      });
    }
  }, [history, fromScreen,pageState,props])

  React.useEffect(() => {
    return () => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToHome();
      }
    };
  }, [history, backToHome])

  const goForDelete = (data) => {
    setCardOnFileId(data)
    setOpen(true);
  }

  const closeDialog = () => {
    setOpen(false)
  };


  const bankCardNoLinked = useCallback(() => {
    history.replace({
      pathname: '/pages/no-card-linked',
      state: { fromScreen: linkedBankCards.length > 1 ? 'MANAGE-CARDS' : null, walletDetailData: walletDetailData }
    });
  }, [history, walletDetailData, linkedBankCards]);


  const getLinkedBankCards = useCallback(() => {
    setLoading(true);
    const linkedBankCardsAPI = new DCustomerManagementApi();
    linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((linkedBankCardsAPIResponse) => {
      if (linkedBankCardsAPIResponse.data && linkedBankCardsAPIResponse.data.length > 0) {
        linkedBankCardsAPIResponse.data = linkedBankCardsAPIResponse.data.filter((card => card.status === CardStatus?.ACTIVE))
        setLinkedBankCards(linkedBankCardsAPIResponse.data);
        setGetLinkBankCard(true);
        setLoading(false);
      } else {
        setLoading(false);
        bankCardNoLinked();
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, bankCardNoLinked]);

  React.useEffect(() => {
    if (linkedBankCards.length < 1 && !isGetLinkBankCard) {
      getLinkedBankCards();
    }
  }, [linkedBankCards, getLinkedBankCards, isGetLinkBankCard]);

  const deleteLinkedBankCards = useCallback(() => {
    closeDialog();
    setLoading(true);
    const deleteLinkedBankCardsAPI = new DCustomerManagementApi();
    deleteLinkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdDelete(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, cardOnFileId).then((deleteLinkedBankCardsAPIResponse) => {
      if (deleteLinkedBankCardsAPIResponse) {
        getLinkedBankCards()
      } else {
        closeDialog();
      }
    }).catch((error) => {
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, cardOnFileId, redirectToErrorScreen, getLinkedBankCards]);

  const successDelete = () => {
    setOpenSuccess(false)
  };

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIcon className={classes.linkColor} onClick={backToViaCard}/>
              <span className={classes.title}>My linked bank cards</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={"m-t-0 m-b-0"}><b>Manage your linked cards</b></h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ul className={'linked-card-list ' + (props?.appData?.template?.landingTemplate === "PAYZEECOMMUTER" ? 'clist-inner' : '')} style={{
                padding: '0',
                marginBottom: '0',
                listStyle: 'none',
                overflow: 'auto'
              }}>
                {linkedBankCards && linkedBankCards.length > 0 ? linkedBankCards.map((card) => {
                  return (
                    <li key={card?.cardOnFileId}>
                      <div>
                        <div style={{ display: 'flex', alignItems:"center"}}>
                          <div style={{ marginRight: 10 }}>
                            <span>
                              <img style={{ width: '5.25em', padding: '0.46em 0.18em' }}
                                   src={returnCardType(card?.bin)} alt=""/>
                            </span>
                          </div>
                          <div>
                            <p className=' m-b-0 m-t-0'>{card?.alias}</p>
                            <strong
                              style={{ color: secondaryTextColor }}>{'**** **** **** ' + card?.last4Digits}</strong>
                          </div>
                          <div style={{ marginLeft: "auto"}}>
                            {props?.appData?.physicalCards?.find(e => e.pan.slice(-4) === card?.last4Digits) ?
                              null :
                              <span style={{ display: 'flex', background: whiteColor, borderRadius: 8, padding: 5 }}
                                    onClick={goForDelete.bind(null, card.cardOnFileId)}>
                                <DeleteIcon style={{cursor: "pointer"}} fill={secondaryTextColor} stroke={secondaryTextColor} />
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </li>)
                }) : null
                }
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                      onClick={linkCard} style={{marginBottom:70}}>Add a card</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
      <Dialog open={openConfirmDialog} style={{ overflow: 'inherit' }}>
        <IconButton aria-label="close"
                    style={{ position: "absolute", top: -40, right: 0, background: whiteColor, padding: 5 }}
                    onClick={closeDialog}>
          <CloseIcon/>
        </IconButton>
        <div style={{ float: 'left', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
          <p className={classes.textLeft + ' m-t-0'}>
            <b>Are you sure you want to delete your linked bank card?</b></p>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-t-0'} style={{fontWeight:"normal"}}>
            Your card will be permanently deleted, but you can link it again at any time.
          </p>
          <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0' }}>
            <strong className={classes.linkColor} onClick={closeDialog}
                    style={{ marginRight: 10 }}>Cancel</strong> &nbsp;&nbsp;
            <strong className={classes.linkColor} onClick={deleteLinkedBankCards}>Delete</strong>
          </span>
        </div>
      </Dialog>
      <Dialog open={openSuccessDialog}>
        <div style={{ float: 'left', width: '100%', padding: '20px', textAlign: 'center', boxSizing: 'border-box' }}>
          <SuccessCardIcon />
          <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}><b>Your card was successfully
            removed.</b></p>
          <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0' }}>
            <strong className={classes.linkColor} onClick={successDelete}>Done</strong>
          </span>
        </div>
      </Dialog>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  manageLinkedCardHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageLinkedCard)