import React from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { primaryColor } from "../../style.scss";
import { SpinnerComponent } from 'react-element-spinner';
import { DCustomerManagementApi } from "eclipse-react-sdk/services/apis/dcustomer-management-api";
import { AAuthenticationApi } from "eclipse-react-sdk/services/apis/aauthentication-api";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import JWTDecode from "../../jwt-decode.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { CreateNewInputControls, FocusInNextControl, FocusInPrevControl, ReturnChangedControlValue, ReturnInputControlsConcatValues, ReturnInputControlsMap, ReturnKeyDownControlValue, ValidateInputControls } from "../../password-pin.js";
import { AuthQueryParamWithTemplateId, AuthRouteWithoutTemplateId } from "../../default-route.js";
import { ReturnDomainHostName, ReturnStoredTemplateId } from "../../get-template-hostname.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ConfirmPaymentPin(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const storeUsername = props?.appData?.template?.pwaAppOptions?.storeUsername;
  const kycRequired = props?.appData?.template?.pwaAppOptions?.kycRequired;
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.paymentPINText;
  const passwordPINInputControlGridLength = 12 / inputControls;
  const confirmPasswordPINControlName = `confirm${passwordPINText?.replace(/ /g, '')}`;
  const walletsDisplay = props?.appData?.template?.pwaAppOptions?.walletsDisplay;

  const newPasswordPIN = useLocation().state?.newPaymentPIN;
  const changePasswordBody = useLocation().state?.changePasswordBody;
  const userIdentity = useLocation().state?.userIdentity;
  const userId = useLocation().state?.userId;
  const tenantId = useLocation().state?.tenantId;
  const tenantTokenData = useLocation().state?.tenantTokenData;

  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(confirmPasswordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const doRatifyKYC = () => {
    localStorage.setItem('session-time', `${(new Date()).getTime()}`);
    localStorage.removeItem("UID");
    if (storeUsername) {
      localStorage.setItem("UID", userIdentity);
    }
    history.push({
      pathname: `/pages/${!kycRequired ? 'ratify-kyc-success' : 'identity-document'}`,
      state: { fromScreen: "SIGN_UP_PAGE", isAddressAdded: false }
    });
  }

  const backToCreatePassword = () => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('create-payment-pin')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { userId: userId, userIdentity: userIdentity, tenantId: tenantId, tenantTokenData: tenantTokenData, changePasswordBody: changePasswordBody }
    });
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen: fromScreen }
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateConfirmPasswordForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateConfirmPasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateConfirmPasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateConfirmPasswordForm = () => {
    //Email errors
    let errors = ValidateInputControls(inputs, confirmPasswordPINControlName, inputControls, `Confirm ${passwordPINText}`, pinPasswordLength);
    if (errors[confirmPasswordPINControlName]) {
      return errors;
    }
    if (!errors[confirmPasswordPINControlName] && newPasswordPIN !== ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)) {
      errors[confirmPasswordPINControlName] = `New ${passwordPINText} and Confirm ${passwordPINText} are not matching!`;
      return errors;
    }
    return errors
  }

  const handleConfirmPasswordFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateConfirmPasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      if (userIdentity) {
        setLoading(true);
        const httpRequestConfiguration = HttpRequestConfiguration(tenantTokenData?.Authorization);
        const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
        const paymentPinBody = {
          identity: `${identityPrefix}PAYMENTPIN-${userIdentity}`,
          password: ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)
        }
        customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId, userId, paymentPinBody).then(() => {
          successPassword();
        }).catch((error) => {
          setLoading(false);
          if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
            showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
          } else {
            redirectToErrorScreen(`/auth/signup`, `error`);
          }
        });
      } else {
        redirectToErrorScreen(`/auth/signup`, `error`);
      }
    }
  }

  const successPassword = () => {
    const loginAPI = new AAuthenticationApi();
    loginAPI.authenticationLoginPost(changePasswordBody).then((authAPIResponse) => {
      let authDecoder = JWTDecode(authAPIResponse.data.headerValue.split(' ')[1]);
      const headerConfigurationRequest = HttpRequestConfiguration(authAPIResponse?.data?.headerValue);
      setLoading(false);
      getWalletTypesFromConfig(headerConfigurationRequest, authAPIResponse, authDecoder);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/auth/signup`, `error`);
      }
    });
  }

  const getWalletTypesFromConfig = (httpRequestConfiguration, authAPIResponse, authDecoder) => {
    setLoading(true);
    const configAPI = new ZGlobalFunctionalityApi();
    let templateId = ReturnStoredTemplateId();
    if (!templateId) {
      templateId = ReturnDomainHostName();
    }

    const publicPWATenantConfig = `public.tenant.pwa.${templateId}`

    configAPI.globalConfigConfigNameGet(publicPWATenantConfig).then((configAPIResponse) => {
      if ((kycRequired && (!configAPIResponse.data?.ratifyRules || (configAPIResponse.data?.ratifyRules && ((!configAPIResponse.data?.ratifyRules?.NATIONAL_IDENTITY || (configAPIResponse.data?.ratifyRules?.NATIONAL_IDENTITY && configAPIResponse.data?.ratifyRules?.NATIONAL_IDENTITY.length < 1)) || (!configAPIResponse.data?.ratifyRules?.PASSPORT || (configAPIResponse.data?.ratifyRules?.PASSPORT && configAPIResponse.data?.ratifyRules?.PASSPORT.length < 1)))))) || (walletsDisplay && (!configAPIResponse.data['public.card.walletTypeId'] || !configAPIResponse.data['public.close_loop.walletTypeId']))) {
        setLoading(false);
        redirectToErrorScreen('/auth/signup', 'error');
        return;
      }

      props.appData.template.publicCardWalletTypeId = configAPIResponse.data['public.card.walletTypeId'];
      props.appData.template.publicCloseLoopWalletTypeId = configAPIResponse.data['public.close_loop.walletTypeId'];
      const appData = {
        ...props?.appData
      }
      props.confirmPaymentPinHandler({ ...appData });
      setLoading(false);
      // addDefaultAddress(httpRequestConfiguration, authAPIResponse, authDecoder);
      getCustomerDetails(httpRequestConfiguration, authAPIResponse, authDecoder);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/auth/signup', 'error');
      }
    });
  }

  const getCustomerDetails = (httpRequestConfiguration, authAPIResponse, authDecoder) => {
    setLoading(true);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersCustomerIdGet(authAPIResponse.data.tenantId, authDecoder.uid).then((customerAPIResponse) => {
      const appData = {
        ...props?.appData,
        customer: customerAPIResponse.data,
        identity: userIdentity,
        auth: authAPIResponse.data,
        isRefresh: true
      }
      props.confirmPaymentPinHandler({ ...appData });
      setLoading(false);
      doRatifyKYC();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/auth/signup`, `error`);
      }
    });
  }


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1>Confirm your<br /> payment PIN</h1>
              </GridItem>
            </GridContainer>
            <form onSubmit={handleConfirmPasswordFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '16px' }}><strong>Confirm your {passwordPINText}</strong></p>
                </GridItem>
                {ReturnInputControlsMap(confirmPasswordPINControlName, inputControls)?.map((control, index, array) => {
                  return (
                    <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength} custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                      <ValidationTextField
                        inputProps={{ maxLength: inputControls > 1 ? 1 : 50, inputMode: pinAsPassword ? 'numeric' : '' }}
                        autoComplete="new-password"
                        className={[inputControls > 1 ? passwordClasses.oneDigitPassword : '', inputControls === 4 ? ' fourDigitInput' : ''].join(' ')}
                        label={inputControls > 1 ? '' : 'Confirm your ' + passwordPINText}
                        fullWidth
                        variant="filled"
                        id={control}
                        type="password"
                        error={!!(errors[confirmPasswordPINControlName])}
                        name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                        value={inputs[control] || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleOnKeyDown}
                        onKeyUp={handleInputKeyUp} />
                    </GridItem>
                  )
                })
                }
                <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                  {errors[confirmPasswordPINControlName] && <Danger><small>{errors[confirmPasswordPINControlName]}</small></Danger>}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors[confirmPasswordPINControlName]) || !ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)}>Next</Button>
                  <p className={classes.regularFontSize}>Can't remember your {passwordPINText}? <span className={classes.linkColor} onClick={backToCreatePassword} >Go back</span> </p>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  confirmPaymentPinHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentPin)