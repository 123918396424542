/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Currently supported payment types. ZA_QRCODE: Paying a QRCode in South Africa, GLOBAL_PAYMENT_LINK: A generic payment link which can be used to request a payment and the payer who opens the link can select a payment mechanism, GLOBAL_VAS: The paymentData must consist of the partnerId and productId of a product in the VAS catalog separated with an _ e.g. 2_ABC123, ZA_HALO: Initiate a tap on glass payment for Halo SDK, GLOBAL_WALLET: Initiate paying into an Eclipse wallet, GLOBAL_TOG: Initiate a generic tap on glass payment, KE_DTB_STK_PUSH:Get paid into wallet via a push payment request with STK
 * @export
 * @enum {string}
 */
export enum PaymentType {
    GLOBALBATCH = 'GLOBAL_BATCH',
    GLOBALEMVQRCODE = 'GLOBAL_EMVQRCODE',
    GLOBALHALOACQ = 'GLOBAL_HALO_ACQ',
    GLOBALPAYMENTLINK = 'GLOBAL_PAYMENT_LINK',
    GLOBALTOG = 'GLOBAL_TOG',
    GLOBALTOGVAC = 'GLOBAL_TOG_VAC',
    GLOBALVAS = 'GLOBAL_VAS',
    GLOBALWALLET = 'GLOBAL_WALLET',
    KEDTBSTKPUSH = 'KE_DTB_STK_PUSH',
    ZAHALO = 'ZA_HALO',
    ZAOZOW = 'ZA_OZOW',
    ZAQRCODE = 'ZA_QRCODE'
}

