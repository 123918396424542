import React, { useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import WalletTypeMode from "../../wallet-type-mode.js";
import { WalletMode } from "eclipse-react-sdk/services/models";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { ReactComponent as CardIcon } from "../../assets/img/card.svg";
import { ReactComponent as WalletIcon } from "../../assets/img/wallet.svg";
import { ReactComponent as EFTIcon } from "../../assets/img/eft.svg";
import { ReactComponent as RetailerIcon } from "../../assets/img/retailer.svg";
import { ReactComponent as ATMIcon } from "../../assets/img/atm.svg";


const useStyles = makeStyles(themeStyle);

export function Withdrawal(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const walletDetailData = useLocation().state?.walletDetailData;
  const walletWithdrawalOptions = props?.appData?.template?.pwaAppOptions?.walletOptions?.withdrawal?.options;

  const history = useHistory();
  const classes = useStyles();

  const [walletTypeMode, setWalletTypeMode] = React.useState(WalletMode.CLOSEDLOOPDIGITAL);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToWallet = () => {
    history.push({
      pathname: '/pages/wallets-detail',
      state: { walletDetailData }
    });
  }

  const eftOut = () => {
    history.push({
      pathname: '/pages/eft-out',
      state: { walletDetailData, fromScreen: 'wallets-detail' }
    });
  }

  const walletWithdrawalRetailStore = () => {
    if (walletDetailData?.availableBalance >= 50) {
      history.push({
        pathname: '/pages/withdrawal-retail-store',
        state: { walletDetailData, fromScreen: 'WITHDRAWAL' }
      });
    } else {
      showAlert(true, 'ERROR', `Sorry! You don't have enough money in ${walletTitleText.toLowerCase()}. Please try with other ${walletTitleText.toLowerCase()}.`);
    }
  }

  const walletWithdrawalATM = () => {
    if (walletDetailData?.availableBalance >= 50) {
      history.push({
        pathname: '/pages/withdrawal-atm',
        state: { walletDetailData, fromScreen: 'WITHDRAWAL' }
      });
    } else {
      showAlert(true, 'ERROR', `Sorry! You don't have enough money in ${walletTitleText.toLowerCase()}. Please try with other ${walletTitleText.toLowerCase()}.`);
    }
  }

  React.useEffect(() => {
    setWalletTypeMode(WalletTypeMode(props?.appData?.template, walletDetailData?.walletTypeId));
  }, [props, walletDetailData, walletTypeMode]);

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToWallet}/>
                <span className={classes.title}>Withdrawal</span>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card style={{ margin: '0' }}>
                  <ButtonBase style={{ justifyContent: 'flex-start' }}>
                    <CardContent style={{ width: '100%', float: 'left' }}>
                      <h5 style={{
                        display: 'flex',
                        marginTop: '0px',
                        marginBottom: '0px'
                      }}>{(walletTypeMode === WalletMode.PREPAIDCARD) ? <CardIcon/> : <WalletIcon/>}&nbsp;&nbsp;
                        <b>{walletDetailData?.name}</b></h5>
                      <div className="bottom-content">
                        <ul>
                          <li>
                            <span>Unique Ref. ID</span>
                            <strong>{walletDetailData?.friendlyId}</strong>
                          </li>
                          <li>
                            <span>Balance</span>
                            <strong>{getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.currentBalance.toFixed(2)}</strong>
                          </li>
                          <li>
                            <span>Available</span>
                            <strong>{getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.availableBalance.toFixed(2)}</strong>
                          </li>
                        </ul>
                      </div>
                    </CardContent>
                  </ButtonBase>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>How would you like to make a
            withdrawal?</b></p>
        </GridItem>
        {walletWithdrawalOptions?.eft ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                eftOut()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><EFTIcon/>&nbsp;&nbsp;
                    <b>EFT</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
        {walletWithdrawalOptions?.cashRetailer ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                walletWithdrawalRetailStore()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><RetailerIcon/>&nbsp;&nbsp;
                    <b>Cash at retailer</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
        {walletWithdrawalOptions?.atm ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                walletWithdrawalATM()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><ATMIcon/>&nbsp;&nbsp;
                    <b>ATM</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(Withdrawal)