import React, { useCallback } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";

import styles from "../assets/jss/eclipse/layouts/pagesStyle.js";

import dashboardRoutes from "../routes.js";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem.js";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import themeStyle from "../assets/jss/eclipse/components/themeStyle.js";
import { logoutFromApp, loginToApp } from "../redux-services/actions/actions.js";
import SetTemplateTheme from "../set-template-theme.js";
import { ReturnDomainHostName, ReturnStoredTemplateId } from "../get-template-hostname.js";
import { AuthRouteWithTemplateId } from "../default-route.js";
import SessionTimeOutDialog from "../views/UserProfile/SessionTimeOutDialog.js";
import WebFooter from "../components/Footer/WebFooter.js";

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.layout === "/pages") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/pages" to="/pages/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(themeStyle);

export function Pages({ ...props }) {
  const [openTimeoutDialog, setOpenTimeoutDialog] = React.useState(false);
  let appStateData = window.localStorage.getItem('appstate');
  if (appStateData && !props.appData) {
    appStateData = atob(appStateData);
    appStateData = JSON.parse(appStateData);
    appStateData.isRefresh = true;
    props.appData = { ...appStateData }
    props.pageHandler({ ...appStateData });
    let templateId = ReturnStoredTemplateId();
    if (!templateId) {
      templateId = ReturnDomainHostName()
    }
    SetTemplateTheme(appStateData, templateId);
  }
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const currentLocation = useLocation().pathname;


  const appLogout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [history, props]);

  const [isMainScreen, enabledTitleFooter] = React.useState(true);
  const [noTitle, enabledTitle] = React.useState(false);
  const classes = useStyles();
  const classes2 = useStyles2();

  const mainPanel = React.createRef();

  const screenType = () => {
    let dashboardRoutesData = dashboardRoutes.filter(prop => window.location.href.indexOf(prop.layout + prop.path) >= 0);
    if (dashboardRoutesData.length > 0) {
      enabledTitleFooter(dashboardRoutesData[0].mainScreen);
      enabledTitle(dashboardRoutesData[0]?.noTitle);
    }
  }

  const makeBrand = () => {
    var name;
    dashboardRoutes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = prop.name;
      }
      return null;
    });
    if (name === 'Home') {
      name = "What would you like to do?";
    }
    return name.replace('Wallet', walletTitleText);
  }
  const getPageId = () => {
    var id;
    dashboardRoutes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        id = prop.id;
      }
      return null;
    });
    return id;
  }

  const getSessionTime = () => {
    const timeInMilliseconds = (new Date()).getTime();//in milliseconds;
    setSessionTimeout(timeInMilliseconds);
  }

  const setSessionTimeout = (currentStateTimeStamp) => {
    if (localStorage.getItem('session-time')) {
      const lastStateTimeStamp = +localStorage.getItem('session-time');
      if ((currentStateTimeStamp > 0) && ((currentStateTimeStamp - lastStateTimeStamp) < props?.appData?.template?.defaultIdleSessionTimeout)) {
        localStorage.setItem('session-time', `${currentStateTimeStamp}`);
      }
    } else {
      setOpenTimeoutDialog(true);
    }
  }

  const doForceLogout = () => {
    setOpenTimeoutDialog(false);
    appLogout();
  }

  const checkedSessionTimeOut = useCallback((sessionInterval) => {
    sessionInterval = setInterval(() => {
      if (localStorage.getItem('session-time')) {
        const lastStateTimeStamp = +localStorage.getItem('session-time');
        const currentStateTimeStamp = (new Date()).getTime();
        if ((currentStateTimeStamp - lastStateTimeStamp) >= +props?.appData?.template?.defaultIdleSessionTimeout && !openTimeoutDialog) {
          localStorage.removeItem('session-time');
          clearInterval(sessionInterval);
          setOpenTimeoutDialog(true);
        }
      } else {
        localStorage.removeItem('session-time');
        clearInterval(sessionInterval);
        setOpenTimeoutDialog(true);
      }
    }, 1000);
  }, [openTimeoutDialog, props]);

  React.useEffect(() => {
    if (!props.appData?.auth) {
      appLogout();
      return;
    }
    screenType();
    let sessionInterval;//Track Active Session post login
    checkedSessionTimeOut(sessionInterval);
  }, [mainPanel, props, appLogout, checkedSessionTimeOut]);
  

  return (
    <div className={classes.wrapper} onMouseMove={getSessionTime} onKeyDown={getSessionTime} style={{ pointerEvents: openTimeoutDialog ? 'none' : 'auto', zIndex: openTimeoutDialog ? '-1' : 'auto' }}>
      <div className={'header-top ' + classes.mainPanel} ref={mainPanel}>
        <Navbar
          isMenuEnabled={isMainScreen}
          routes={dashboardRoutes}
          {...props}
        />

        <div className={[isMainScreen ? classes.content : (currentLocation === "/pages/ratify-kyc-success") ? classes.content : classes.fullContent, (currentLocation === "/pages/ratify-kyc-success") ? " auth-parent" : ''].join(' ')} id={getPageId()}>
          <div className="curve-area">
            <div className={classes2.gradientCurve + " curve"}>&nbsp;</div>
          </div>
          <div className={(currentLocation === "/pages/ratify-kyc-success") ? "internal-wrap auth-internal-wrap" : 'internal-wrap'}>
            <div className={(currentLocation === "/pages/ratify-kyc-success") ? "internal-card ratify-success-card" : ''}>
            {isMainScreen && !noTitle ?
              <div className={classes.container+" "+getPageId()}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className={classes.pageTitle + ' page-title'}><b>{makeBrand()}</b></h4>
                  </GridItem>
                </GridContainer>
              </div> : null
            }
            <div className={classes.container}>{switchRoutes}</div>
            </div>
          </div>
        </div>
        <WebFooter />
        {isMainScreen ? <Footer {...props} /> : null}
      </div>
      <SessionTimeOutDialog openTimeoutDialog={openTimeoutDialog} handleLogoutClick={() => { doForceLogout(); }} />
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  pageHandler: data => dispatch(loginToApp(data)),
  logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Pages)