import React from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { primaryColor } from "../../style.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { CreateNewInputControls, FocusInNextControl, FocusInPrevControl, ReturnChangedControlValue, ReturnInputControlsConcatValues, ReturnInputControlsMap, ReturnKeyDownControlValue, ValidateInputControls } from "../../password-pin.js";
import { AuthQueryParamWithTemplateId, AuthRouteWithoutTemplateId } from "../../default-route.js";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { DCustomerManagementApi } from "eclipse-react-sdk/services/apis/dcustomer-management-api";
import { AAuthenticationApi } from "eclipse-react-sdk/services/apis/aauthentication-api";

import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { SpinnerComponent } from "react-element-spinner";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ConfirmPassword(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const passwordPINInputControlGridLength = 12 / inputControls;
  const confirmPasswordPINControlName = `confirm${passwordPINText}`;

  const newPasswordPIN = useLocation().state?.newPasswordPIN;
  const userIdentity = useLocation().state?.userIdentity;
  const userId = useLocation().state?.userId;
  const tenantId = useLocation().state?.tenantId;
  const tenantTokenData = useLocation().state?.tenantTokenData;

  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(confirmPasswordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToCreatePassword = () => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('create-password')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { userId: userId, userIdentity: userIdentity, tenantId: tenantId, tenantTokenData: tenantTokenData }
    });
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen: fromScreen }
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateConfirmPasswordForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateConfirmPasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateConfirmPasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateConfirmPasswordForm = () => {
    //Email errors
    let errors = ValidateInputControls(inputs, confirmPasswordPINControlName, inputControls, `Confirm ${passwordPINText}`, pinPasswordLength);
    if (errors[confirmPasswordPINControlName]) {
      return errors;
    }
    if (!errors[confirmPasswordPINControlName] && newPasswordPIN !== ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)) {
      errors[confirmPasswordPINControlName] = `New ${passwordPINText} and Confirm ${passwordPINText} are not matching!`;
      return errors;
    }
    return errors
  }

  const handleConfirmPasswordFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateConfirmPasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      if (userIdentity) {
        const changePasswordBody = {
          identity: `${identityPrefix}-${userIdentity}`,
          password: ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)
        }
        setLoading(true);
        const httpRequestConfiguration = HttpRequestConfiguration(tenantTokenData?.Authorization);
        const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
        customerAPI.tenantsTenantIdCustomersCustomerIdIdentitiesPost(tenantId, userId, changePasswordBody).then(() => {
          successPassword(changePasswordBody);
          
        }).catch((error) => {
          setLoading(false);
          if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
            showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
          } else {
            redirectToErrorScreen(`/auth/signup`, `error`);
          }
        });


      } else {
        redirectToErrorScreen(`/auth/signup`, `error`);
      }
    }
  }

  const successPassword = (changePasswordBody) => {
    const loginAPI = new AAuthenticationApi();
    const loginBody = {
      identity: `${identityPrefix}-${userIdentity}`,
      password: ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)
    }
    loginAPI.authenticationLoginPost(loginBody).then((authAPIResponse) => {
      const headers = { Authorization: authAPIResponse?.data?.headerValue };
      history.push({
        pathname: `${AuthRouteWithoutTemplateId('create-payment-pin')}`,
        search: AuthQueryParamWithTemplateId(),
        state: { userId: userId, userIdentity: userIdentity, tenantId: tenantId, tenantTokenData: headers, newPasswordPIN: newPasswordPIN, changePasswordBody: changePasswordBody }
      });
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/auth/signup`, `error`);
      }
    });
  }


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 style={{ marginTop: '40px' }}>And confirm</h1>
              </GridItem>
            </GridContainer>
            <form onSubmit={handleConfirmPasswordFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '16px' }}><strong>Confirm your {passwordPINText}.</strong></p>
                </GridItem>
                {ReturnInputControlsMap(confirmPasswordPINControlName, inputControls)?.map((control, index, array) => {
                  return (
                    <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength} custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                      <ValidationTextField
                        inputProps={{ maxLength: inputControls > 1 ? 1 : 50, inputMode: pinAsPassword ? 'numeric' : '' }}
                        autoComplete="new-password"
                        className={[inputControls > 1 ? passwordClasses.oneDigitPassword : '', inputControls === 4 ? ' fourDigitInput' : ''].join(' ')}
                        label={inputControls > 1 ? '' : 'Confirm your ' + passwordPINText}
                        fullWidth
                        variant="filled"
                        id={control}
                        type="password"
                        error={!!(errors[confirmPasswordPINControlName])}
                        name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                        value={inputs[control] || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleOnKeyDown}
                        onKeyUp={handleInputKeyUp} />
                    </GridItem>
                  )
                })
                }
                <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                  {errors[confirmPasswordPINControlName] && <Danger><small>{errors[confirmPasswordPINControlName]}</small></Danger>}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors[confirmPasswordPINControlName]) || !ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)}>Next</Button>
                  <p className={classes.regularFontSize}>Can't remember your {passwordPINText}? <span className={classes.linkColor} onClick={backToCreatePassword} >Go back</span> </p>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  confirmPasswordHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword)