import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SuccessResetPassword } from '../../assets/img/card_cancel_success.svg';
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss"
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(themeStyle);

export function AssistedCardSuccess(props) {
  const history = useHistory();
  const classes = useStyles();
  const flow = useLocation()?.state?.flow;
  const cardDetailData = useLocation()?.state?.cardDetailData;
  console.log(flow);
  console.log(cardDetailData);
  console.log(props);
  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.successPhysicalCardRegisterHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  const goToNextScreen = () => {
    if (flow === "assisted-signup") {
      history.push('/pages/assisted-signup-success');
    } else {
      history.push('/pages/assisted-card-register');
    }
  }
  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])
  return (
    <div>
      <div className={flow === "assisted-signup" ? classes.textCenter : null + " wallet-list"}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', margin: '50px 0 30px' }}>
                <SuccessResetPassword fill={primaryIconColor} stroke={primaryIconColor}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {flow === "assisted-signup" ?
                  <h5 className={classes.textCenter}><b>Your customer's card was successfully registered!</b></h5> :
                  <h1>Your customer's card was successfully registered!</h1>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize + ' m-t-0'}>
                  Check that customer received an SMS with their card's PIN. They can change their PIN in the app
                </p>
                <p className={classes.regularFontSize + ' m-t-0'}>
                  Remind them to log in to their account and top up their card to start using it.
                </p>
                {flow !== "assisted-signup" ?
                  <p className={classes.regularFontSize + ' m-t-0'}>
                    Would you like to assist another customer to register a card?
                  </p> : null}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={goToNextScreen}
                        type="button">
                  {flow === "assisted-signup" ? "Done" : "Assist another customer"}
                </Button>
                {flow === "assisted-signup" ?
                  null :
                  <p className={classes.textCenter + " " + classes.regularFontSize}><Link onClick={() => {
                    history.push('/pages/dashboard');
                  }} className={classes.linkColor}>Not now, take me to my home page</Link></p>
                }
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successPhysicalCardRegisterHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedCardSuccess)