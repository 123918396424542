import React, { useState, useCallback } from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { SpinnerComponent } from "react-element-spinner";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import { CreateVASInputControls, ReturnPartialPaymentAmountAllowed, ReturnVASAirtimeDataInputControls, ValidateVASInputControls } from "../VASControls.js";
import FormattedAmount from "../../../formattedAmount.js";
import { CardStatus } from "../../../eclipse-react-sdk/services/models";
import { AmountTextField } from "../../../components/Amount/AmountTextField.js";
import { checkAmount } from "../../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function VASPayBill(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const vasType = useLocation().state?.vasType;
  const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
  const amountOptions = pwaAppOptions?.cashInOutLimits?.vas;
 
  const payWithWallets = vasType === 'BUY' ? props?.appData?.template?.vas?.payElectricityWithWallets : props?.appData?.template?.vas?.payBillWithWallets;
  const pageState = useLocation().state;
  const initiatePaymentData = useLocation().state?.initiatePaymentData;
  const wallets = props?.appData?.wallets?.filter((wallet) => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId);
  const isPartialPaymentAmountAllowed = ReturnPartialPaymentAmountAllowed(initiatePaymentData?.partialPaymentAllowed);
  const [linkedBankCards, setLinkedBankCards] = React.useState([]);
  const [isGetLinkBankCard, setGetLinkBankCard] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const defaultPaymentProcess = wallets && payWithWallets ? 'WALLETS' : 'CARDS';
  const [paymentProcess, setPaymentProcess] = React.useState(defaultPaymentProcess);

  const history = useHistory();
  const classes = useStyles();

  const defaultAmount = vasType === 'BUY' ? '' : initiatePaymentData?.amount;
  const existedControls = ReturnVASAirtimeDataInputControls(pageState?.selectedProduct?.additionalFields);
  const [inputs, setInputs] = useState({ fromWalletCard: '', amount: defaultAmount, ...CreateVASInputControls(initiatePaymentData?.additionalFields, existedControls) });
  const [errors, setErrors] = useState({});
  const [alertData, setAlert] = React.useState(null);
  const [pastedAmount, setPastedAmount] = useState(0);

  const onChangeRadio = (event) => {
    setPaymentProcess(event.target.value);
    setInputs({ fromWalletCard: '', amount: inputs?.amount, ...CreateVASInputControls(initiatePaymentData?.additionalFields, existedControls) });
    setErrors({});
  };

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData, 'PASTE');
    inputs['amount'] = formattedData;
    if (formattedData > 0) {
      setPastedAmount(inputs?.amount);
    }
    const validationErrors = validateVASForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if(pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      }else{
        inputs['amount'] = FormattedAmount(event.target.value);
      }
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateVASForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    if(event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateVASForm();
    setErrors(validationErrors);
  }

  const validateVASForm = () => {
    let errors = {};
    if (isPartialPaymentAmountAllowed) {
      const amountValidations = JSON.parse(initiatePaymentData?.amountOption);
      if (amountValidations) {
        if (inputs.amount < +amountValidations?.min) {
          errors.amount = `Invalid Amount! It must be minimum R${amountValidations?.min}!`;
          return errors;
        }
        if (inputs.amount > +amountValidations?.max) {
          errors.amount = `Invalid Amount! It must be maximum R${amountValidations?.max}!`;
          return errors;
        }
      }
    }

    if (errors && !errors.amount) {
      if (!inputs.amount) {
        errors.amount = 'Amount required!';
        return errors;
      } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
        errors.amount = 'Invalid amount!'
        return errors;
      } else if (checkAmount(amountOptions?.payBill, +inputs.amount)) {
        errors.amount = `${amountOptions?.payBill?.error}`;
        return errors;
      }
    }

    errors = ValidateVASInputControls(inputs, initiatePaymentData?.additionalFields, existedControls);
    if (Object.keys(errors).length > 0) {
      return errors;
    }

    if (!inputs.fromWalletCard) {
      errors.fromWalletCard = paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()} required!` : 'From card required!'
      return errors;
    } else if (!/^[0-9a-zA-Z-]{1,}$/i.test(inputs.fromWalletCard)) {
      errors.fromWalletCard = paymentProcess === 'WALLETS' ? `Invalid from ${walletTitleText?.toLowerCase()}!` : 'Invalid from card!'
      return errors;
    } else {
      if(inputs?.fromWalletCard && paymentProcess === 'WALLETS'){
        const selectedWallet = props?.appData?.wallets?.find(wallet => wallet?.walletId === inputs?.fromWalletCard);
        if(selectedWallet && (selectedWallet?.availableBalance < 1 || selectedWallet?.availableBalance < inputs?.amount)){
          errors.fromWalletCard = "Insufficient balance!";
          return errors;
        }
      }
    }

    return errors;
  }

  const backToEnterAccount = () => {
    history.push({
      pathname: '/pages/vas-enter-account',
      state: { vasType: vasType, vasProducts: pageState?.vasProducts, selectedVASProducts: pageState?.selectedVASProducts, selectedProduct: pageState?.selectedProduct }
    });
  }

  const confirmToVASPay = (inputs) => {
    const selectedWallet = props?.appData?.wallets?.filter(wallet => wallet?.walletId === inputs.fromWalletCard)[0];
    const selectedCard = linkedBankCards?.filter(card => card?.cardOnFileId === inputs.fromWalletCard)[0];
    let selectedProduct = {
      ...pageState?.selectedProduct,
      fixedPriceIncl: inputs?.amount
    };
    history.push({
      pathname: '/pages/vas-payment-confirmation',
      state: {
        ...pageState,
        selectedProduct: selectedProduct,
        walletDetailData: selectedWallet,
        cardDetailData: selectedCard,
        paymentProcess: paymentProcess,
        vasType: vasType,
        paymentDetails: inputs
      }
    });
  }

  const handleVASFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateVASForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      confirmToVASPay(inputs);
    }
  }

  const getLinkedBankCards = useCallback(() => {
    setLoading(true);
    const linkedBankCardsAPI = new DCustomerManagementApi();
    linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((linkedBankCardsAPIResponse) => {
      setLoading(false);
      if (linkedBankCardsAPIResponse?.data && linkedBankCardsAPIResponse?.data.length > 0) {
        linkedBankCardsAPIResponse.data = linkedBankCardsAPIResponse.data.filter((card=>card.status ===CardStatus?.ACTIVE))
        setLinkedBankCards(linkedBankCardsAPIResponse.data);
      } else {
        setLinkedBankCards([{ cardOnFileId: '', alias: '', last4Digits: '' }]);
      }
      setGetLinkBankCard(true);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen]);

  const goToManageBankCards = () => {
    history.push({
      pathname: '/pages/manage-linked-cards',
      state: { fromScreen: 'PAYBILL', linkedBankCards: linkedBankCards }
    });
  };

  const linkNewBankCard = () => {
    history.push({
      pathname: '/pages/card-on-file',
      state: { ...pageState, fromScreen: 'PAYBILL' }
    });
  };

  React.useEffect(() => {
    if (linkedBankCards.length < 1 && !isGetLinkBankCard) {
      getLinkedBankCards();
    }
    if (paymentProcess === 'CARDS' && linkedBankCards.length === 1) {
      inputs.fromWalletCard = linkedBankCards[0]?.cardOnFileId;
      setInputs(inputs);
    }

  }, [inputs, paymentProcess, linkedBankCards, getLinkedBankCards, isGetLinkBankCard]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToEnterAccount} />
              <span className={classes.title}>{vasType === 'BUY' ? 'Buy' : ''} {pageState?.selectedProduct?.productName} {vasType === 'BUY' ? '' : 'Bill'}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleVASFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>{initiatePaymentData?.description}</b></p>
              {vasType !== 'BUY' ? <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Amount due</b></p> : null}
              {vasType !== 'BUY' ? <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>R{(+initiatePaymentData?.amount).toFixed(2)}</b></h2> : null}
            </GridItem>
            {initiatePaymentData?.amount === 0 || (initiatePaymentData?.amount > 0 && isPartialPaymentAmountAllowed) ?
              <GridItem xs={12} sm={12} md={12}>
                <h6 className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ margin: '10px 0', textTransform: 'inherit' }}><b>How much would you like to {vasType === 'BUY' ? 'buy' : 'pay'}?</b></h6>
                <AmountTextField
                  {...props?.appData}
                  inputs={inputs}
                  errors={errors}
                  onPaste={handleInputAmountPaste}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp} />
              </GridItem>
              : null}

            {initiatePaymentData?.additionalFields?.map((control, index) => {
              return (
                existedControls && existedControls.indexOf(control?.id) < 0 ?
                  <GridItem xs={12} sm={12} md={12} key={index}>
                    <ValidationTextField
                      fullWidth
                      label={control?.title}
                      variant="filled"
                      id={control?.id}
                      type="text"
                      error={!!(errors[control?.id])}
                      name={control?.id}
                      value={inputs[control?.id]}
                      onChange={handleInputChange}
                      onKeyUp={handleInputKeyUp} />
                    {errors[control?.id] && <Danger><small>{errors[control?.id]}</small></Danger>}
                  </GridItem>
                  : null)
            })}

            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0 m-t-0'}><b>How would you like to {vasType === 'BUY' ? 'buy' : 'pay'}?</b></p>
            </GridItem>
            {wallets && wallets.length > 0 && payWithWallets && linkedBankCards && linkedBankCards.length > 0 ?
              <GridItem xs={12} sm={12} md={12}>
                <RadioGroup aria-label="transferType" name="transferType" value={paymentProcess} onChange={onChangeRadio}>
                  <FormControlLabel value="WALLETS" control={<Radio />} label={`My ${walletTitleText?.toLowerCase()}`} />
                  <FormControlLabel value="CARDS" color={primaryColor} control={<Radio className={classes.Radio} />} label="Linked card" />
                </RadioGroup>
              </GridItem>
              : null}
            <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
              <ValidationTextField
                fullWidth
                label={paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()}` : "From card"}
                select
                variant="filled"
                id="fromWalletCard"
                type={paymentProcess === 'WALLETS' ? "number" : "text"}
                error={!!(errors.fromWalletCard)}
                name="fromWalletCard"
                value={inputs.fromWalletCard}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}>
                {paymentProcess === 'WALLETS' ?
                  wallets && wallets.length > 0 ?
                    wallets?.map((wallet) => {
                      return (<MenuItem key={wallet?.walletId}
                        value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                    }) : <MenuItem key={""} value={""}>{`No ${walletTitleText?.toLowerCase()} found`}</MenuItem> :
                  linkedBankCards?.map((bankCard) => {
                    return (<MenuItem key={bankCard?.cardOnFileId} value={bankCard?.cardOnFileId}>{bankCard?.alias !== '' && bankCard?.last4Digits !== '' ? bankCard?.alias + ' - **' + bankCard?.last4Digits : 'No card found'}</MenuItem>)
                  })
                }
              </ValidationTextField>
              {errors.fromWalletCard && <Danger><small>{errors.fromWalletCard}</small></Danger>}
              <div className="external-links" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px' }}>
                <span onClick={linkNewBankCard} style={{ display: 'flex', margin: '0 10px 0 0' }} className={classes.linkColor + ' ' + classes.regularFontSize}><AddCircleOutlineOutlined />&nbsp;<b>Add a card</b></span>
                <span onClick={goToManageBankCards} style={{ display: 'flex', margin: '0 0 0 10px' }} className={classes.linkColor + ' ' + classes.regularFontSize}><PaymentIcon />&nbsp;<b>Manage my cards</b></span>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={Object.keys(errors).length !== 0}>{vasType === 'BUY' ? 'Buy' : 'Pay'} now</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(VASPayBill)
