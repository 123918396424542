import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import progressStepsStyles from "../../assets/jss/eclipse/components/progressStepsStyle.js";
import { ReactComponent as SetStepsCarIcon } from '../../assets/img/steps_car_icon.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(progressStepsStyles);


export default function ProgressSteps(props) {
  const classes = useStyles();
  const currentStep = props?.currentStep || 1;
  const steps = new Array(+props?.totalSteps).fill(0);
  const isBack = props?.isBack || false;
  const onBackClick = props?.onBackClick;
  const backLinkTitle = props?.backLinkTitle || 'Back';
  const isShowStepper = props?.isShowStepper || false;

  const handleBackClick = (event) => {
    onBackClick(event);
  }

  return (
    <div className={classes.progressSteps}>
      <div className={classes.stepsTitle}>
        {isBack ?
          <div className={classes.backLinkColor} onClick={handleBackClick}>
            <div className={classes.backIcon}><ArrowBackIcon /></div>
            <div className={classes.backText}>{backLinkTitle}</div>
          </div>
          : null}
        {isShowStepper ?
          <>
            <div className={classes.taxiIcon}><SetStepsCarIcon /></div>
            <div className={classes.stepsText}>Step {currentStep} of {steps.length}</div>
          </>
          : null}
      </div>
      {isShowStepper ?
        <div className={classes.steps + ' stepper'}>
          <div className="stepHandle" style={{ width: (100) / steps.length * currentStep + "%", height: '4px' }}/>
        </div>
        : null}
    </div>
  )
}