/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Currently supported wallet topup types. ZA_PEACH_CARD:Card topup with Peach payments, ZA_PEACH_EFT:Instant EFT topup with Peach payments, ZA_PNP_CASH:Deposit cash at Pick n Pay teller, ZA_MASTERPASS_CARD:Card topup using masterpass, KE_DTB_STK_PUSH:Topup via a push request with STK
 * @export
 * @enum {string}
 */
export enum WalletTopupType {
    KEDTBSTKPUSH = 'KE_DTB_STK_PUSH',
    ZAMASTERPASSCARD = 'ZA_MASTERPASS_CARD',
    ZAOZOW = 'ZA_OZOW',
    ZAPEACHCARD = 'ZA_PEACH_CARD',
    ZAPEACHEFT = 'ZA_PEACH_EFT',
    ZAPNPCASH = 'ZA_PNP_CASH'
}

