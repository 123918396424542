/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EclipseWalletQRCode } from '../models';
/**
 * JWalletQRCodesApi - axios parameter creator
 * @export
 */
export const JWalletQRCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the details of a QRCode using a path parameter
         * @summary Get details for a specific QRCode
         * @param {number} tenantId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsQrCodesCodeGet: async (tenantId: number, code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsQrCodesCodeGet.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling tenantsTenantIdWalletsQrCodesCodeGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/qr-codes/{code}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of a QRCode using a query parameter
         * @summary Get details for a specific QRCode
         * @param {number} tenantId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsQrCodesGet: async (tenantId: number, code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsQrCodesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/qr-codes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JWalletQRCodesApi - functional programming interface
 * @export
 */
export const JWalletQRCodesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get the details of a QRCode using a path parameter
         * @summary Get details for a specific QRCode
         * @param {number} tenantId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsQrCodesCodeGet(tenantId: number, code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWalletQRCode>> {
            const localVarAxiosArgs = await JWalletQRCodesApiAxiosParamCreator(configuration).tenantsTenantIdWalletsQrCodesCodeGet(tenantId, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of a QRCode using a query parameter
         * @summary Get details for a specific QRCode
         * @param {number} tenantId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsQrCodesGet(tenantId: number, code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseWalletQRCode>> {
            const localVarAxiosArgs = await JWalletQRCodesApiAxiosParamCreator(configuration).tenantsTenantIdWalletsQrCodesGet(tenantId, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JWalletQRCodesApi - factory interface
 * @export
 */
export const JWalletQRCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get the details of a QRCode using a path parameter
         * @summary Get details for a specific QRCode
         * @param {number} tenantId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsQrCodesCodeGet(tenantId: number, code: string, options?: any): AxiosPromise<EclipseWalletQRCode> {
            return JWalletQRCodesApiFp(configuration).tenantsTenantIdWalletsQrCodesCodeGet(tenantId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of a QRCode using a query parameter
         * @summary Get details for a specific QRCode
         * @param {number} tenantId 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsQrCodesGet(tenantId: number, code?: string, options?: any): AxiosPromise<EclipseWalletQRCode> {
            return JWalletQRCodesApiFp(configuration).tenantsTenantIdWalletsQrCodesGet(tenantId, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JWalletQRCodesApi - object-oriented interface
 * @export
 * @class JWalletQRCodesApi
 * @extends {BaseAPI}
 */
export class JWalletQRCodesApi extends BaseAPI {
    /**
     * Get the details of a QRCode using a path parameter
     * @summary Get details for a specific QRCode
     * @param {number} tenantId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JWalletQRCodesApi
     */
    public tenantsTenantIdWalletsQrCodesCodeGet(tenantId: number, code: string, options?: any) {
        return JWalletQRCodesApiFp(this.configuration).tenantsTenantIdWalletsQrCodesCodeGet(tenantId, code, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get the details of a QRCode using a query parameter
     * @summary Get details for a specific QRCode
     * @param {number} tenantId 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JWalletQRCodesApi
     */
    public tenantsTenantIdWalletsQrCodesGet(tenantId: number, code?: string, options?: any) {
        return JWalletQRCodesApiFp(this.configuration).tenantsTenantIdWalletsQrCodesGet(tenantId, code, options).then((request) => request(this.axios, this.basePath));
    }
}
