import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ReactComponent as WhatsAppSetPasswordIcon } from '../../assets/img/whatsapp_set_password.svg'
import { primaryIconColor } from "../../style.scss";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { makeStyles } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button.js";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(themeStyle);

export default function WhatsAppUserInstructionDialog(props) {
  const classes = useStyles();
  const handleWhatsAppInstructionDialogClose = props?.handleWhatsAppInstructionDialogClose;
  const handleWhatsAppSetPasswordRequest = props?.handleWhatsAppSetPasswordRequest;
  return (
    <>
      <Dialog open={props?.openWhatsAppUserInstructionDialog}>
        <DialogContent>
          <p className={classes.regularFontSize + ' m-t-0 m-b-0 ' + classes.textRight}>
            <CloseIcon className={classes.linkColor} onClick={() => { handleWhatsAppInstructionDialogClose() }}/>
          </p>
          <p className={classes.regularFontSize + ' m-t-0 ' + classes.textCenter}>
            <WhatsAppSetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textCenter}>
            <b>Set your password</b>
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textCenter}>
            If you have registered with WhatsApp <br /> your cell phone number will be your <br /> username, and you can set your <br /> password here.
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textRight}>
            <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={() => { handleWhatsAppSetPasswordRequest() }}>Set my password</Button>
          </p>
        </DialogContent>
      </Dialog >
    </>
  )
}