import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { ReactComponent as ConfirmTransactionIcon } from '../../assets/img/journey_transfer.svg'
import { v4 as uuidv4 } from 'uuid';
import { DCustomerManagementApi, QPaymentsApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { PaymentType } from "../../eclipse-react-sdk/services/models";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { AuthQueryParamWithTemplateId } from "../../default-route.js";


const useStyles = makeStyles(themeStyle);

export function VASConfirmPayAmount(props) {
  const vasConfig = props?.appData?.template?.vas;
  const pageState = useLocation().state;
  const vasDetail = useLocation().state?.vasDetail;
  const vasProducts = useLocation().state?.vasProducts;
  const selectedVASProducts = useLocation().state?.selectedVASProducts;
  const paymentProcess = useLocation().state?.paymentProcess;
  const selectedProduct = useLocation().state?.selectedProduct;
  const walletDetailData = useLocation().state?.walletDetailData;
  const cardDetailData = useLocation().state?.cardDetailData;
  let initiatePaymentData = useLocation().state?.initiatePaymentData;
  const vasType = useLocation().state?.vasType;
  const paymentDetails = useLocation().state?.paymentDetails;
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const goToPaymentStatusPage = (paymentData) => {
    history.push({
      pathname: '/pages/vas-payment-status',
      state: { ...pageState, paymentData: paymentData }
    });
  };

  const backToVASBuy = useCallback(() => {
    if (vasType === 'AIRTIMEDATA' || vasType === 'BUYAIRTIMEDATA' || vasType === 'BUYELECTRICITY') {
      history.push({
        pathname: '/pages/vas-buy-airtime-data',
        state: { ...pageState }
      });
    } else if (vasType === 'COLLECTIONS') {
      history.push({
        pathname: '/pages/vas-buy-kganya-products',
        state: { ...pageState }
      });
    } else if (vasType === 'BUY' || vasType === 'PAY') {
      history.push({
        pathname: '/pages/vas-pay-bill',
        state: { ...pageState }
      });
    }
  }, [history, pageState, vasType]);

  const backToDashboard = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.vasConfirmPayAmountHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToVASBuy();
      }
    };
  }, [history, backToVASBuy])

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const updatePaymentAmount = (paymentData) => {
    const paymentsAPI = new QPaymentsApi();

    if (!initiatePaymentData) {
      initiatePaymentData = paymentData;
    }

    const additionalFields = [];
    initiatePaymentData.additionalFields.forEach(control => {
      if (paymentDetails[control?.id]) {
        control = { ...control, value: paymentDetails[control?.id] };
      }
      additionalFields.push(control);
    });

    let paymentRequestBody = {
      amount: selectedProduct?.fixedPriceIncl ? selectedProduct?.fixedPriceIncl : initiatePaymentData?.amount,
      additionalFields: additionalFields
    }

    if (paymentProcess === 'WALLETS') {
      paymentRequestBody = {
        ...paymentRequestBody,
        walletId: walletDetailData?.walletId
      }
    } else {
      paymentRequestBody = {
        ...paymentRequestBody,
        cardOnFileId: cardDetailData?.cardOnFileId,
        landingUrl: `${window.location.origin}${process.env.PUBLIC_URL}/pages/vas-payment-status${AuthQueryParamWithTemplateId() ? AuthQueryParamWithTemplateId() : ''}`
      }
    }

    paymentsAPI.tenantsTenantIdPaymentsPaymentIdPut(props?.appData?.auth?.tenantId, paymentData?.paymentId, paymentRequestBody).then((paymentsAPIResponse) => {
      if (paymentsAPIResponse.data && paymentsAPIResponse.data?.paymentId && paymentsAPIResponse.data?.paymentId > 0) {
        let paymentData = {
          paymentId: paymentsAPIResponse.data?.paymentId,
          status: paymentsAPIResponse.data?.status
        }
        if (paymentProcess === 'WALLETS') {
          const appData = {
            ...props?.appData,
            paymentData: paymentData
          }
          props.vasConfirmPayAmountHandler({ ...appData });
          goToPaymentStatusPage(paymentData);
        } else {
          if (paymentsAPIResponse.data?.completionUrl) {
            window.localStorage.setItem('appstate', btoa(JSON.stringify({ ...props?.appData, paymentData: paymentData })));
            window.location.replace(paymentsAPIResponse.data?.completionUrl);
          } else {
            setLoading(false);
            showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
          }
        }
      } else {
        setLoading(false);
        showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const doPaymentWithCard = () => {
    setLoading(true);
    if (initiatePaymentData && initiatePaymentData?.paymentId && initiatePaymentData?.paymentId > 0) {
      updatePaymentAmount(initiatePaymentData);
      return;
    }
    const paymentsAPI = new DCustomerManagementApi();
    let paymentData = `${vasProducts?.partnerId}_${selectedProduct?.productId}`;
    if (selectedVASProducts?.title.toLowerCase() === vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase()) {
      if (vasProducts?.partnerId === vasConfig?.partners?.masterpass?.id) {
        if (selectedVASProducts?.data[0]?.productId.includes('MENU')) {
          paymentData = `${vasProducts?.partnerId}_${selectedVASProducts?.data[0]?.productId}`;
        } else {
          paymentData = `${vasProducts?.partnerId}_MENU-${selectedVASProducts?.data[0]?.productId}`;
        }
      } else {
        paymentData = `${vasProducts?.partnerId}_${vasConfig?.buyAirtimeDataMenu?.airtime.toUpperCase()}`;
      }
    }else if(vasType === 'COLLECTIONS'){
      paymentData = `${vasProducts?.partnerId}_${selectedVASProducts?.data[0]?.productId}`;
    }

    let paymentRequestBody = {
      externalUniqueId: uuidv4(),
      description: selectedProduct?.productDescription,
      type: PaymentType.GLOBALVAS,
      currency: 'ZAR',
      paymentData: paymentData,
      additionalFields: selectedProduct.additionalFields
    }

    paymentsAPI.tenantsTenantIdCustomersCustomerIdPaymentsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, paymentRequestBody).then((paymentsAPIResponse) => {
      if (paymentsAPIResponse.data && paymentsAPIResponse.data?.paymentId && paymentsAPIResponse.data?.paymentId > 0) {
        updatePaymentAmount(paymentsAPIResponse.data);
      } else {
        setLoading(false);
        showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    doPaymentWithCard();
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToVASBuy}></ArrowBackIosIcon>
              {vasType === 'AIRTIMEDATA' || vasType === 'BUYAIRTIMEDATA' || vasType === 'BUYELECTRICITY' ?
                <span className={classes.title}>
                  Buy {vasProducts?.partnerName} {vasType === 'BUYELECTRICITY' ? '' : selectedVASProducts?.title}
                </span>
                :
                <span className={classes.title}>
                  {vasType === 'BUY' 
                    ? 'Buy' 
                    : <>
                      {vasType === 'COLLECTIONS' ? 'Collections' : 'Pay'}
                      </>
                  } {selectedProduct?.productName}
                </span>
              }
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <ConfirmTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>You are about to {vasType === 'BUY' || vasType === 'AIRTIMEDATA' || vasType === 'BUYAIRTIMEDATA' || vasType === 'BUYELECTRICITY' || vasType === 'COLLECTIONS' ? 'buy' : 'pay'} {vasType === 'COLLECTIONS' ? 'a product' : ''}</b></p>
              {selectedProduct?.fixedPriceIncl ?
                <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+selectedProduct?.fixedPriceIncl).toFixed(2)}</b></h2>
                : null}
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>
                {vasType === 'AIRTIMEDATA' || vasType === 'BUYAIRTIMEDATA' || vasType === 'BUYELECTRICITY' ?
                  <b>{vasProducts?.partnerName} {vasType === 'BUYELECTRICITY' ? '' : selectedVASProducts?.title} {selectedProduct?.productDescription} for {vasDetail?.mobileNumber}</b>
                  : 
                  <>
                    {vasType === 'COLLECTIONS' ? 
                      <b>{selectedVASProducts?.title}</b>
                      :
                      <b>{selectedVASProducts?.title} for {initiatePaymentData?.description}</b>
                    }
                  </>
                  }
              </p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={backToDashboard} >Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasConfirmPayAmountHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASConfirmPayAmount)