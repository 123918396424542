import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../Typography/Danger.js";
import { connect } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { InputAdornment } from "@material-ui/core";


const ValidationTextField = withStyles(formStyle)(TextField);

export function AmountTextField(props) {
  const disabled = props?.disabled || false;
  const amountFieldTextAlign = props?.template?.pwaAppOptions?.amountFieldTextAlign || 'right';
  const onChange = props?.onChange;
  const onPaste = props?.onPaste;
  const onKeyUp = props?.onKeyUp;
  const inputs = props?.inputs;
  const errors = props?.errors;
  const walletDetailData = props?.walletDetailData;
  const styleType = props?.styleType;
  const controlName = props?.controlName || 'amount';
  const controlLable = props?.controlLable || 'Amount';

  const handleInputChange = (event) => {
    onChange(event);
  }

  const handleInputAmountPaste = (event) => {
    onPaste(event);
  }

  const handleInputKeyUp = (event) => {
    onKeyUp(event);
  }

  return (
    <>
      <ValidationTextField
        autoComplete="new-amount"
        inputProps={{
          inputMode: 'decimal',
          step: "any",
          maxLength: '10',
          style: { textAlign: amountFieldTextAlign, fontSize: styleType === 'adjustable' ? '2.4em' : null, fontWeight: styleType === 'adjustable' ? '700' : null, padding: styleType === 'adjustable' ? '18px 12px 16px 0' : null, minWidth: styleType === 'adjustable' ? inputs[controlName].length + 'ch' : null, width: styleType === 'adjustable' ? '4ch' : null },
        }}
        InputProps={{
          startAdornment: <InputAdornment className={"currencyPrefix" + (styleType === 'adjustable' ? ' customStyled' : null)}
            position="start">{getSymbolFromCurrency(walletDetailData?.currency || "ZAR")}</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="0.00"
        disabled={disabled}
        fullWidth={styleType !== 'adjustable'}
        className={styleType === 'adjustable' ? 'custom-styled-amount' : null}
        label={styleType === 'adjustable' ? "" : controlLable}
        variant="filled"
        id={controlName}
        type="amount"
        error={!!(errors[controlName])}
        name={controlName}
        value={inputs[controlName]}
        onPaste={handleInputAmountPaste}
        onChange={handleInputChange}
        onKeyUp={handleInputKeyUp} />
        {errors[controlName] && <Danger><small>{errors[controlName]}</small></Danger>}
    </>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(AmountTextField)