/*!

=========================================================
* Eclipse PWA - v1.9.0
=========================================================

* Product Page: https://www.ukheshe.com/product/eclipse
* Copyright 2020 Ukheshe Technology (https://www.ukheshe.com)
* Licensed under MIT (https://ukheshe.comtimofficial/eclipse/blob/master/LICENSE.md)

* Coded by Ukheshe Technology

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// core components/views for Auth layout
import { Person, WifiOffOutlined, Error } from "@material-ui/icons";
import Landing from "./views-auth/Landing/Landing";
import Login from "./views-auth/Login/Login";
import GenericErrorPage from "./components/ErrorScreens/GenericError.js";
import OfflineErrorPage from "./components/ErrorScreens/OfflineError.js";
import ResetPasswordPage from "./views-auth/ResetPassword/ResetPassword.js";
import CreateNewPasswordPage from "./views-auth/ResetPassword/CreateNewPassword.js";
import ConfirmNewPasswordPage from "./views-auth/ResetPassword/ConfirmNewPassword.js";
import SuccessPasswordPage from "./views-auth/ResetPassword/SuccessPassword.js";
import SignUpPage from "./views-auth/SignUp/SignUp.js";
import CreateUsernamePage from "./views-auth/SignUp/CreateUsername.js";
import VerifyOTPPage from "./views-auth/SignUp/VerifyOTP.js";
import CreatePasswordPage from "./views-auth/SignUp/CreatePassword.js";
import ConfirmPasswordPage from "./views-auth/SignUp/ConfirmPassword.js";
import IdentityDocumentsPage from "./views-auth/ResetPassword/IdentityDocuments";
import ScanDocumentPage from "./views-auth/ResetPassword/DocumentSection/ScanDocument";
import UploadDocumentPage from "./views-auth/ResetPassword/DocumentSection/UploadDocument";
import PhotoInitialPage from "./views-auth/ResetPassword/PhotoSection/PhotoInitial";
import ScanPhotoPage from "./views-auth/ResetPassword/PhotoSection/ScanPhoto";
import UploadPhotoPage from "./views-auth/ResetPassword/PhotoSection/UploadPhoto";
import RatifyKYCErrorPage from "./views-auth/ResetPassword/RatifyKYCError";
import ConfirmPaymentPinPage from "./views-auth/SignUp/ConfirmPaymentPin";
import CreatePaymentPinPage from "./views-auth/SignUp/CreatePaymentPin";

const routesAuth = [
  {
    path: "/login",
    name: "Login",
    icon: Person,
    component: Login,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/landing",
    name: "Landing",
    icon: Person,
    component: Landing,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: Person,
    component: ResetPasswordPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/identity-document",
    name: "Identity Document",
    icon: Person,
    component: IdentityDocumentsPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/scan-document",
    name: "Scan Document",
    icon: Person,
    component: ScanDocumentPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/upload-document",
    name: "Upload Document",
    icon: Person,
    component: UploadDocumentPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/selfie-initial",
    name: "Selfie Initial",
    icon: Person,
    component: PhotoInitialPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/scan-selfie",
    name: "Scan Selfie",
    icon: Person,
    component: ScanPhotoPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/ratify-kyc-error",
    name: "Ratify KYC Error",
    icon: Person,
    component: RatifyKYCErrorPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/upload-selfie",
    name: "Upload Selfie",
    icon: Person,
    component: UploadPhotoPage,
    layout: "/auth",
    mainScreen: false
  },

  {
    path: "/create-new-password",
    name: "Create New Password",
    icon: Person,
    component: CreateNewPasswordPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/confirm-new-password",
    name: "Confirm New Password",
    icon: Person,
    component: ConfirmNewPasswordPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/success-password",
    name: "Success Password",
    icon: Person,
    component: SuccessPasswordPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/error",
    name: "Error",
    icon: Error,
    component: GenericErrorPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/offline-error",
    name: "Offline Error",
    icon: WifiOffOutlined,
    component: OfflineErrorPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/signup",
    name: "SignUp",
    icon: Person,
    component: SignUpPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/verify-otp",
    name: "Verify OTP",
    icon: Person,
    component: VerifyOTPPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/create-username",
    name: "Create Username",
    icon: Person,
    component: CreateUsernamePage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/create-password",
    name: "Create Password",
    icon: Person,
    component: CreatePasswordPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/confirm-password",
    name: "Confirm Password",
    icon: Person,
    component: ConfirmPasswordPage,
    layout: "/auth",
    mainScreen: false
  },
  /**
   * Parth Bhatti
   * Date:09/08/2022
   * Routes added for create payment pin flow
   *Starts=========
   **/
   {
    path: "/create-payment-pin",
    name: "Create Payment Pin",
    icon: Person,
    component: CreatePaymentPinPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/confirm-payment-pin",
    name: "Confirm Payment Pin",
    icon: Person,
    component: ConfirmPaymentPinPage,
    layout: "/auth",
    mainScreen: false
  },
   /**
   * Parth Bhatti
   * Date:09/08/2022
   * Routes added for create payment pin flow
   *Ends=========
   **/
];

export default routesAuth;
