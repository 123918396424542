/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Type of Eclipse card status reason
 * @export
 * @enum {string}
 */
export enum EclipseStatusReason {
    BANK = 'BANK',
    CANCELREPLACEMENT = 'CANCELREPLACEMENT',
    CAPTURE = 'CAPTURE',
    COUNTERFEIT = 'COUNTERFEIT',
    DAMAGED = 'DAMAGED',
    EMERGENCY = 'EMERGENCY',
    ERRONEOUS = 'ERRONEOUS',
    EXPIRED = 'EXPIRED',
    FOUND = 'FOUND',
    FRAUD = 'FRAUD',
    LOST = 'LOST',
    MISCELLANEOUS = 'MISCELLANEOUS',
    OVERDUE = 'OVERDUE',
    RETURNED = 'RETURNED',
    STOLEN = 'STOLEN',
    STOPLIST = 'STOPLIST',
    UNBLOCKED = 'UNBLOCKED'
}

