import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import { DCustomerManagementApi, HWalletsApi } from "eclipse-react-sdk/services";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Wallet } from "../../assets/img/icon_add_a_card.svg"
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import moment from 'moment';

const useStyles = makeStyles(themeStyle);

export function ConfirmCreateVirtualCardWallet(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const customer = props?.appData?.customer;
  const history = useHistory();
  const classes = useStyles();
  const createWalletData = useLocation().state;
  const personalInfo = createWalletData?.personalInfo;
  const addressInfo = createWalletData?.addressInfo;
  const fromScreen = createWalletData?.fromScreen;
  const walletName = createWalletData?.walletName;

  const backToCreateWallet = () => {
    if (fromScreen === "PI") {
      history.push({ pathname: '/pages/add-personal-information', state:{ ...createWalletData }});
    } else {
      history.push('/pages/create-virtual-card');
    }
  };

  const goToSuccessCreateWallet = (createdWalletDetailData) => {
    history.push({
      pathname: '/pages/success-create-virtual-card',
      state: { createdWalletDetailData: createdWalletDetailData }
    });
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.confirmCreateVirtualCardWalletHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    props.appData.isRefresh = true;
    props.confirmCreateVirtualCardWalletHandler({ ...props?.appData });
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const registerWalletCardOnCardOnFile = (walletDetailData, walletCardDetailData) => {
    const expiryMonth = `${new Date(walletCardDetailData?.expires).getMonth()}`;
    const expiryYear = `${new Date(walletCardDetailData?.expires).getFullYear()}`;
    const expiryDate = `${expiryMonth.padStart(2, 0)}${expiryYear.substr(2, 2)}`;
    const registerWalletCardsAPI = new DCustomerManagementApi();
    const cardOnFileRequestBody = {
      alias: `${walletDetailData?.name}`,
      cardData: {
        accountType: '',
        cardOnFileId: uuidv4(),
        cardholderName: `${props?.appData?.customer?.title} ${props?.appData?.customer?.firstName} ${props?.appData?.customer?.middleName} ${props?.appData?.customer?.lastName}`,
        cvv: walletCardDetailData?.cvv,
        dob: personalInfo?.dateOfBirth,
        expiry: expiryDate,
        pan: walletCardDetailData?.pan
      },
      cardId: walletCardDetailData?.cardId,
    }
    registerWalletCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFilePost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, cardOnFileRequestBody).then((walletCardsAPIResponse) => {
      goToSuccessCreateWallet(walletDetailData);
    }).catch((error) => {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    });
  }

  const getWalletCards = (walletDetailData) => {
    const walletCardsAPI = new HWalletsApi();
    walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(props?.appData?.auth?.tenantId, walletDetailData?.walletId).then((walletCardsAPIResponse) => {
      if (walletCardsAPIResponse.data && walletCardsAPIResponse.data.length > 0) {
        registerWalletCardOnCardOnFile(walletDetailData, walletCardsAPIResponse.data[0]);
      } else {
        goToSuccessCreateWallet(walletDetailData);
      }
    }).catch((error) => {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    });
  }

  const createWallet = () => {
    setLoading(true);
    const customerWalletAPI = new DCustomerManagementApi();
    const createWalletBody = {
      externalUniqueId: uuidv4(),
      name: walletName,
      description: '',
      status: 'ACTIVE',
      walletTypeId: props?.appData?.template?.publicCardWalletTypeId
    }
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, createWalletBody).then((customerWalletAPIResponse) => {
      getWalletCards(customerWalletAPIResponse.data);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const savePersonalDetails = () => {
    setLoading(true);
    personalInfo.dateOfBirth = moment(personalInfo?.dateOfBirth).format("YYYYMMDD")
    const customerData = { 
      ...customer,
      dateOfBirth: personalInfo?.dateOfBirth,
      gender: personalInfo?.gender,
      maritalStatus: personalInfo?.maritalStatus
    }
    const customerAPI = new DCustomerManagementApi();
    customerAPI.tenantsTenantIdCustomersCustomerIdPut(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, customerData).then((customerApiResponse) => {
      props.appData.customer = customerApiResponse.data;
      props.appData.isRefresh = true;
      props.confirmCreateVirtualCardWalletHandler({ ...props.appData });
      saveAddressDetails();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const saveAddressDetails = () => {
    setLoading(true);
    const customerAPI = new DCustomerManagementApi();
    if(addressInfo.line1.trim() === '' ){
      addressInfo.line1 = addressInfo.line2;
      addressInfo.line2 = addressInfo.city;
    }
    addressInfo.line3 = null;
    addressInfo.country = "South Africa";
    
    customerAPI.tenantsTenantIdCustomersCustomerIdAddressesPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, addressInfo).then((customerAddressAPIResponse) => {
      createWallet();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/pages/dashboard`, `/pages/error`);
      }
    });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (fromScreen === "PI") {
      savePersonalDetails();
    } else {
      createWallet();
    }

  }


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToCreateWallet}/>
              <span className={classes.title}>Confirm create a virtual card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Wallet className={classes.screenCenterPrimaryIcon} fill={primaryIconColor} stroke={primaryIconColor}/>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}><b>Your about to create a new virtual
                card {walletTitleText?.toLowerCase()} named : {createWalletData?.walletName}</b></p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent"
                      onClick={backToHome}>Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                      onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  confirmCreateVirtualCardWalletHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCreateVirtualCardWallet)