import React, { useCallback, useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { primaryColor, secondaryTextColor } from "../../style.scss";

import { connect } from "react-redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";

import QrReader from 'react-qr-reader'
import { JWalletQRCodesApi } from "eclipse-react-sdk/services";
import scan_frame from "../../assets/img/scan_frame.svg"
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import Button from "../../components/CustomButtons/Button";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle";
import { TextField } from "@material-ui/core";
import { loginToApp } from "../../redux-services/actions/actions";
import { SpinnerComponent } from "react-element-spinner";
import LogoSlider from "../../components/LogoSlider/LogoSlider.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function ScanPayQR(props) {
  const history = useHistory();
  const classes = useStyles();
  const fromScreen = useLocation().state?.fromScreen;
  const tenantId = props?.appData?.auth?.tenantId;
  const qrLogoList = props?.appData?.template?.qrLogoList;
  const acceptedCardsForQRPay = props?.appData?.template?.pwaAppOptions?.acceptedCardsForQRPay;
  const [isLoading, setLoading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [errors] = useState({});
  const [alertData, setAlert] = useState(null);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const backToTransact = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.scanPayHandler(appData);
    history.push({
      pathname: `/pages/${fromScreen}`
    });
  },[history, props, fromScreen])

  const goToPayAmount = (scannedQRData) => {
    history.push({
      pathname: '/pages/pay-amount',
      state: { scannedQRData, fromScreen }
    });
  }

  const handleScan = (qrCodeData) => {
    if(qrCodeData){
      doValidateQR(qrCodeData);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(qrCodeData){
      doValidateQR(qrCodeData);
    }
  }
  const handleInputChange = (event) => {
    event.persist();
    if(event.target.value){
      setQrCodeData(event.target.value);
    }
  }

  const goToQrErrorScreen = (qrPaymentData) => {
    history.push({
      pathname: "/pages/qr-error",
    });
  }

  const doValidateQR = (qrCodeData) => {
    setLoading(true);
    const qrCodesApi = new JWalletQRCodesApi();
    qrCodesApi.tenantsTenantIdWalletsQrCodesCodeGet(tenantId, qrCodeData).then((qrAPIResponse) => {
      setLoading(false);
      const isQRAllowAcceptedCards = acceptedCardsForQRPay?.map(element => {
        return qrAPIResponse?.data?.acceptedCardSchemes?.includes(element)
      });
      if((isQRAllowAcceptedCards?.filter(Boolean).length > 0)) {
        goToPayAmount(qrAPIResponse?.data);
      } else {
        goToQrErrorScreen();
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error?.response && error?.response?.data && error?.response?.data?.length > 0 && error?.response?.data[0]?.description) {
        showAlert(true, 'ERROR', `${error?.response?.data[0]?.description}`);
      } else {
        redirectToErrorScreen('/pages/pay-qr', '/pages/error');
      }
    });
  }

  const handleError = (error) => {
    showAlert(true, 'ERROR',"Camera" + error?.toString());
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.scanPayHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);


  React.useEffect(() => {
    return() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToHome();
      }
    }
  }, [history, backToHome])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIcon className={classes.linkColor} onClick={backToTransact}/>
              <span className={classes.title}>Pay a QR code</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="qr-scan-area">
                <img src={scan_frame} alt=''/>
                <QrReader
                  delay={600}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ paddingBottom: '0.5em'}}>
              <p style={{ textAlign: 'center', color: secondaryTextColor }}><b>OR</b></p>
              <form onSubmit={handleSubmit}>
                  <ValidationTextField
                    fullWidth
                    label="Enter the QR code"
                    variant="filled"
                    id="qrcode"
                    type="text"
                    error={!!(errors.qrcode)}
                    name="card"
                    value={qrCodeData}
                    onChange={handleInputChange}/>
                {errors.qrcode && <Danger><small>{errors.qrcode}</small></Danger>}
                <Button className={classes.buttonRadius} style={{marginTop: "1.2em"}} size="lg" block color="primary" type="submit" disabled={!!errors.qrcode || (qrCodeData === '')}>Next</Button>
              </form>
            </GridItem>
            {qrLogoList && qrLogoList?.length !== 0 ? <GridItem xs={12} sm={12} md={12} style={{marginBottom: '1em', padding: "15px 40px 0" }}> 
              <div style={{display: "flex", justifyContent: "center", paddingBottom: "0.3em"}}>
               <p>Scan any of the following QR codes</p>
              </div>
              <LogoSlider logoList={qrLogoList} options={{ autoplay: true }}/>
            </GridItem> : null}
          </GridContainer>
        </GridItem>
      </GridContainer> 

      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  scanPayHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScanPayQR)
