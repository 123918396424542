/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentType {
    ASYLUMPAPERS = 'ASYLUM_PAPERS',
    AUTO = 'AUTO',
    BACKOFNATIONALIDENTITY = 'BACK_OF_NATIONAL_IDENTITY',
    BANKSTATEMENT = 'BANK_STATEMENT',
    BIRTHCERTIFICATE = 'BIRTH_CERTIFICATE',
    BUSINESSLICENSE = 'BUSINESS_LICENSE',
    CERTOFINCORP = 'CERT_OF_INCORP',
    COMPARISONFACIALPHOTO = 'COMPARISON_FACIAL_PHOTO',
    COMPARISONNATIONALIDENTITY = 'COMPARISON_NATIONAL_IDENTITY',
    COMPARISONPASSPORT = 'COMPARISON_PASSPORT',
    FACIALPHOTO = 'FACIAL_PHOTO',
    FOREIGNCERTIFICATE = 'FOREIGN_CERTIFICATE',
    LOGO = 'LOGO',
    NATIONALIDENTITY = 'NATIONAL_IDENTITY',
    PARTNERSHIPDEED = 'PARTNERSHIP_DEED',
    PASSPORT = 'PASSPORT',
    PROOFOFADDRESS = 'PROOF_OF_ADDRESS',
    TAX = 'TAX',
    TEMPRESIDENCYPERMIT = 'TEMP_RESIDENCY_PERMIT',
    TRUSTDEED = 'TRUST_DEED',
    USAW8 = 'USA_W_8',
    USAW9 = 'USA_W_9',
    VISA = 'VISA',
    WORKPERMIT = 'WORK_PERMIT'
}

