/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { NewEclipseWalletTopup } from '../models';
import { UpdatedWalletTopup } from '../models';
import { WalletTopupCompletionData } from '../models';
import { WalletTopupResult } from '../models';
/**
 * KWalletTopupsApi - axios parameter creator
 * @export
 */
export const KWalletTopupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the wallets topups based on certain criteria.
         * @summary Get a wallets topups
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the topups inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the topups exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsGet: async (tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/topups`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate a new topup for a wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletTopup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsPost: async (tenantId: number, walletId: number, body?: NewEclipseWalletTopup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/topups`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get by topup id
         * @summary Get the details for a topup
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} topupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsTopupIdGet: async (tenantId: number, walletId: number, topupId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsTopupIdGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsTopupIdGet.');
            }
            // verify required parameter 'topupId' is not null or undefined
            if (topupId === null || topupId === undefined) {
                throw new RequiredError('topupId','Required parameter topupId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsTopupIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/topups/{topupId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"topupId"}}`, encodeURIComponent(String(topupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the Topup status. It can only be used on a sandbox when the property 'topup.allow.simulation' is true
         * @summary Update Topup Status to SUCCESSFUL or ERROR_PERM if Topup is in PENDING / BUILDING Status.
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} topupId 
         * @param {UpdatedWalletTopup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsTopupIdPut: async (tenantId: number, walletId: number, topupId: number, body?: UpdatedWalletTopup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsTopupIdPut.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsTopupIdPut.');
            }
            // verify required parameter 'topupId' is not null or undefined
            if (topupId === null || topupId === undefined) {
                throw new RequiredError('topupId','Required parameter topupId was null or undefined when calling tenantsTenantIdWalletsWalletIdTopupsTopupIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/topups/{topupId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"topupId"}}`, encodeURIComponent(String(topupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KWalletTopupsApi - functional programming interface
 * @export
 */
export const KWalletTopupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Gets the wallets topups based on certain criteria.
         * @summary Get a wallets topups
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the topups inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the topups exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdTopupsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletTopupResult>>> {
            const localVarAxiosArgs = await KWalletTopupsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdTopupsGet(tenantId, walletId, dateFromIncl, dateToExcl, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Initiate a new topup for a wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletTopup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdTopupsPost(tenantId: number, walletId: number, body?: NewEclipseWalletTopup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTopupCompletionData>> {
            const localVarAxiosArgs = await KWalletTopupsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdTopupsPost(tenantId, walletId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get by topup id
         * @summary Get the details for a topup
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} topupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(tenantId: number, walletId: number, topupId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTopupResult>> {
            const localVarAxiosArgs = await KWalletTopupsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(tenantId, walletId, topupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the Topup status. It can only be used on a sandbox when the property 'topup.allow.simulation' is true
         * @summary Update Topup Status to SUCCESSFUL or ERROR_PERM if Topup is in PENDING / BUILDING Status.
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} topupId 
         * @param {UpdatedWalletTopup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdTopupsTopupIdPut(tenantId: number, walletId: number, topupId: number, body?: UpdatedWalletTopup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTopupResult>> {
            const localVarAxiosArgs = await KWalletTopupsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdTopupsTopupIdPut(tenantId, walletId, topupId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KWalletTopupsApi - factory interface
 * @export
 */
export const KWalletTopupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Gets the wallets topups based on certain criteria.
         * @summary Get a wallets topups
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the topups inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the topups exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Array<WalletTopupResult>> {
            return KWalletTopupsApiFp(configuration).tenantsTenantIdWalletsWalletIdTopupsGet(tenantId, walletId, dateFromIncl, dateToExcl, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate a new topup for a wallet
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletTopup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsPost(tenantId: number, walletId: number, body?: NewEclipseWalletTopup, options?: any): AxiosPromise<WalletTopupCompletionData> {
            return KWalletTopupsApiFp(configuration).tenantsTenantIdWalletsWalletIdTopupsPost(tenantId, walletId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get by topup id
         * @summary Get the details for a topup
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} topupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(tenantId: number, walletId: number, topupId: number, options?: any): AxiosPromise<WalletTopupResult> {
            return KWalletTopupsApiFp(configuration).tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(tenantId, walletId, topupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the Topup status. It can only be used on a sandbox when the property 'topup.allow.simulation' is true
         * @summary Update Topup Status to SUCCESSFUL or ERROR_PERM if Topup is in PENDING / BUILDING Status.
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} topupId 
         * @param {UpdatedWalletTopup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdTopupsTopupIdPut(tenantId: number, walletId: number, topupId: number, body?: UpdatedWalletTopup, options?: any): AxiosPromise<WalletTopupResult> {
            return KWalletTopupsApiFp(configuration).tenantsTenantIdWalletsWalletIdTopupsTopupIdPut(tenantId, walletId, topupId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KWalletTopupsApi - object-oriented interface
 * @export
 * @class KWalletTopupsApi
 * @extends {BaseAPI}
 */
export class KWalletTopupsApi extends BaseAPI {
    /**
     * Gets the wallets topups based on certain criteria.
     * @summary Get a wallets topups
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the topups inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the topups exclusive
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KWalletTopupsApi
     */
    public tenantsTenantIdWalletsWalletIdTopupsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, options?: any) {
        return KWalletTopupsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdTopupsGet(tenantId, walletId, dateFromIncl, dateToExcl, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Initiate a new topup for a wallet
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {NewEclipseWalletTopup} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KWalletTopupsApi
     */
    public tenantsTenantIdWalletsWalletIdTopupsPost(tenantId: number, walletId: number, body?: NewEclipseWalletTopup, options?: any) {
        return KWalletTopupsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdTopupsPost(tenantId, walletId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get by topup id
     * @summary Get the details for a topup
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {number} topupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KWalletTopupsApi
     */
    public tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(tenantId: number, walletId: number, topupId: number, options?: any) {
        return KWalletTopupsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(tenantId, walletId, topupId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the Topup status. It can only be used on a sandbox when the property 'topup.allow.simulation' is true
     * @summary Update Topup Status to SUCCESSFUL or ERROR_PERM if Topup is in PENDING / BUILDING Status.
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {number} topupId 
     * @param {UpdatedWalletTopup} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KWalletTopupsApi
     */
    public tenantsTenantIdWalletsWalletIdTopupsTopupIdPut(tenantId: number, walletId: number, topupId: number, body?: UpdatedWalletTopup, options?: any) {
        return KWalletTopupsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdTopupsTopupIdPut(tenantId, walletId, topupId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
