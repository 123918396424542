import React from "react";
import Snackbar from "./Snackbar.js";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export default function AlertNotification(props) {
  //console.log("AlertNotification Props :: ", props?.alertData);
  const [alertData, setAlertData] = React.useState(props?.alertData);
  const [alert, setAlert] = React.useState(alertData?.isAlertDisplay);

  return (
    <div>
      {alertData?.isAlertDisplay && alertData?.alertType === 'SUCCESS' ?
        <Snackbar
          place="bc"
          color={'success'}
          icon={CheckCircleOutlineIcon}
          message={alertData?.alertMessage}
          open={alert}
          closeNotification={() => {
            setAlert(false)
            setAlertData(null)
          }}
          close={!alert}
        />
        :
        null
      }

      {alertData?.isAlertDisplay && alertData?.alertType === 'ERROR' ?
        <Snackbar
          place="bc"
          color={'danger'}
          icon={ErrorOutline}
          message={alertData?.alertMessage}
          open={alert}
          closeNotification={() => {
            setAlert(false)
            setAlertData(null)
          }}
          close={!alert}
        />
        :
        null
      }
    </div>
  );
}