import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import Dialog from "@material-ui/core/Dialog";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { AuthRouteWithTemplateId } from "../../default-route";
import { DCustomerManagementApi } from "../../eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification";
import { EclipseCustomerStatus } from "eclipse-react-sdk/services/models";

const useStyles = makeStyles(themeStyle);

export function SuccessAccountDeactivate(props) {
  const customer = props?.appData?.customer;
  const history = useHistory();
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  },[props,history]);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        logout();
      }
    };
  }, [history,logout])

  const handleConfirm = () => {
    setConfirmOpen(true);
  }

  const handleActivate = () => {
    setConfirmOpen(false);
    setLoading(true);
    const customerAPI = new DCustomerManagementApi();
    const customerBody = {
      ...customer,
      status: EclipseCustomerStatus.ACTIVE
    };
    customerAPI.tenantsTenantIdCustomersCustomerIdPut(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, customerBody).then((customerAPIResponse) => {
      props.appData.customer = customerAPIResponse.data;
      props.appData.isRefresh = true;
      props.successAccountDeactivateHandler({ ...props.appData });
      history.push({
        pathname: "/pages/user"
      })
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });

  }

  const handleClose = () => {
    setConfirmOpen(false);
  };
  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <LockOutlinedIcon className={classes.screenCenterPrimaryIcon} fill={primaryIconColor} stroke={primaryIconColor} />
              <p className={classes.title + ' ' + classes.textCenter}><b>Your account is currently deactivated.</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary"
                onClick={handleConfirm}>Reactivate My Account</Button>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={logout}>Log out</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
      <Dialog open={confirmOpen}>
        <div style={{ float: 'left', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-t-0'}><b>You are about to activate your account.</b></p>
          <span style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0 0 0' }}>
            <strong className={classes.linkColor} onClick={handleClose}>Cancel</strong> &nbsp;&nbsp;
            <strong className={classes.linkColor} onClick={handleActivate}>Activate account</strong>
          </span>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  successAccountDeactivateHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessAccountDeactivate)