import React, { useCallback } from "react";
// core components
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AuthRouteWithTemplateId } from "../../default-route.js";
import { ReturnStoredUserId } from "../../get-template-hostname.js";


export function Landing(props) {
  const landingTemplate = props?.appData?.template?.landingTemplate || "Default";
  const history = useHistory();
  const LandingTemplate = require(`./${landingTemplate}Landing.js`).default

  const loginRedirect = useCallback(() => {
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [history])

  const signupRedirect = useCallback(() => {
    history.push(`${AuthRouteWithTemplateId('signup')}`);
  }, [history])

  React.useEffect(() => {
    const userId = ReturnStoredUserId();
    if (userId) {
      loginRedirect();
    } else {
      signupRedirect();
    }
  }, [loginRedirect, props, signupRedirect]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.go(1);
      }
    };
  }, [history])

  return (
    <LandingTemplate loginRedirect={loginRedirect} signupRedirect={signupRedirect}/>
  );
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(Landing)
