import React, { useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';

import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { primaryColor } from "../../style.scss";
import { SpinnerComponent } from 'react-element-spinner';
import { load } from 'recaptcha-v3'
import { EnvConfig } from "../../environment.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";
import { AuthQueryParamWithTemplateId, AuthRouteWithoutTemplateId } from "../../default-route.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function VerifyOTP(props) {
  const mobileNumber = props?.mobileNumber;
  const gCaptchaToken = props?.gCaptcha;
  const verifyOTPLength = props?.appData?.template?.pwaAppOptions?.verifyOTPLength;
  const tenantId = props?.appData?.template?.tenantId;
  const verifyOTPControlGridLength = 12 / verifyOTPLength;
  const otpControlName = 'otp';
  const otpControlText = 'OTP';
  const onComplete = props?.onComplete;
  const flow = props?.flow;
  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(otpControlName, verifyOTPLength) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen }
    });
  }

  const backToHome = () => {
    history.push({
      pathname: '/pages/dashboard/',
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, verifyOTPLength);
    setInputs(inputControlValue);
    const validationErrors = validateOTPForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateOTPForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, verifyOTPLength);
        setInputs(inputControlValue);
        const validationErrors = validateOTPForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateOTPForm = () => {
    //Email errors
    return ValidateInputControls(inputs, otpControlName, verifyOTPLength, otpControlText, verifyOTPLength)
  }

  const handleOTPFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateOTPForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const httpRequestConfiguration = HttpRequestConfiguration();
      const verifyOTPAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
      const headers = { gCaptcha: gCaptchaToken };
      httpRequestConfiguration.baseOptions = { headers };
      verifyOTPAPI.globalVerificationsPhoneGet(mobileNumber, `${ReturnInputControlsConcatValues(inputs, otpControlName, verifyOTPLength)}`).then((result) => {
        setLoading(false)
        if(flow==="assisted-physical-card"){
          onComplete(ReturnInputControlsConcatValues(inputs, otpControlName, verifyOTPLength), result);
        } else {
          onComplete(result)
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen(`/pages/dashboard`, `error`);
        }
      });
    } else {
      showAlert(true, 'ERROR', `Please try again!`);
    }
  }

  const generateOTP = () => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration();
    const generateOTPAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
    load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((recaptcha) => {
      recaptcha.execute('OTP_VERIFICATION').then((token) => {
        if (token) {
          const headers = { gCaptcha: token };
          httpRequestConfiguration.baseOptions = { headers };
          const generateOTPData = {
            phone: mobileNumber, 
            tenantId
          }
          generateOTPAPI.globalVerificationsPost(generateOTPData).then(() => {
            setInputs({ ...CreateNewInputControls(otpControlName, verifyOTPLength) });
            setLoading(false)
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen(`/pages/signup`, `error`);
            }
          });
        } else {
          showAlert(true, 'ERROR', `Please try again!`);
        }
      })
    });
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1>Just to <br/> verify</h1>
                <p className={classes.regularFontSize}><strong>Please enter the code sent to you by SMS
                  to <br/> {"+27 ** *** " + mobileNumber?.substr(mobileNumber?.length - 4)}.</strong></p>
              </GridItem>
            </GridContainer>
            <form onSubmit={handleOTPFormSubmit}>
              <GridContainer>
                {ReturnInputControlsMap(otpControlName, verifyOTPLength)?.map((control) => {
                  return (
                    <GridItem key={control} xs={verifyOTPControlGridLength} sm={verifyOTPControlGridLength}
                              md={verifyOTPControlGridLength} custom-padding={passwordClasses.customPadding}>
                      <ValidationTextField
                        inputProps={{ maxLength: verifyOTPLength > 1 ? 1 : 50, inputMode: 'numeric' }}
                        className={verifyOTPLength > 1 ? passwordClasses.oneDigitPassword : ''}
                        label={verifyOTPLength > 1 ? '' : otpControlText}
                        fullWidth
                        variant="filled"
                        id={control}
                        type="password"
                        error={!!(errors[otpControlName])}
                        name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                        value={inputs[control] || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleOnKeyDown}
                        onKeyUp={handleInputKeyUp}/>
                    </GridItem>
                  )
                })
                }
                <GridItem xs={12} sm={12} md={12} className='p-t-0' style={{ paddingTop: '0 !important' }}>
                  {errors[otpControlName] && <Danger><small>{errors[otpControlName]}</small></Danger>}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                          disabled={!!(errors[otpControlName])}>Next</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter}>Didn't receive the code? <span
                    className={classes.linkColor} onClick={generateOTP}>Resend code</span></p>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter}>Wrong phone number? <span
                    className={classes.linkColor} onClick={backToHome}>Go back</span></p>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  verifyOTPHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP)