export default function ServiceWorker() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
            navigator.serviceWorker.register(swUrl).then((regResponse) => {
                regResponse.update();
                console.warn("SW Success ::", regResponse.scope);
            }).catch((error) => {
                console.error("SW Failure ::", error);
            });
        });
    }
}