import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import { primaryColor } from "../../style.scss";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions.js";
import { DCustomerManagementApi, HWalletsApi } from "eclipse-react-sdk/services";
import WalletTypeMode from "../../wallet-type-mode.js";
import { SpinnerComponent } from "react-element-spinner";
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import { EclipseCardType, OrganisationPosition, WalletMode, WalletStatus } from "eclipse-react-sdk/services/models";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { ReactComponent as CardIcon } from "../../assets/img/card.svg";
import { ReactComponent as HistoryIcon } from "../../assets/img/history.svg";
import { ReactComponent as WalletIcon } from "../../assets/img/wallet.svg";
import { ReactComponent as BuyIcon } from "../../assets/img/buy.svg";
import { ReactComponent as PayBillsIcon } from "../../assets/img/pay_bills.svg";
import { ReactComponent as CollectionsIcon } from "../../assets/img/collections.svg";
import { ReactComponent as AirtimeDataIcon } from "../../assets/img/airtime_data.svg";
import { ReactComponent as QRIcon } from "../../assets/img/qr.svg";
import { AuthRouteWithTemplateId } from "../../default-route";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { ReactComponent as TransactIcon } from "../../assets/img/transact.svg";

export function Dashboard(props) {
  const vasConfig = props?.appData?.template?.vas;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const isVASPreCheckNetworkPartner = props?.appData?.template?.pwaAppOptions?.preCheckNetworkProvider;
  const vasNetworkPartners = Object.values(props?.appData?.template?.vas?.partners || "");
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const [isAllowCreateWallet, setAllowCreateWallet] = React.useState(false);
  const [isAllowCreatePhysicalCardWallet, setAllowCreatePhysicalCardWallet] = React.useState(false);
  const [isAllowCreateVirtualCardWallet, setAllowCreateVirtualCardWallet] = React.useState(false);
  const allowActiveWallet = props?.appData?.template?.pwaAppOptions?.createWallet?.allow || 0;
  const allowActivePhysicalCardWallet = props?.appData?.template?.pwaAppOptions?.createPhysicalCard?.allow || 0;
  const allowActiveVirtualCardWallet = props?.appData?.template?.pwaAppOptions?.createVirtualCard?.allow || 0;
  const isKycRequired = props?.appData?.template?.pwaAppOptions?.kycRequired;
  const isWalletsDisplay = props?.appData?.template?.pwaAppOptions?.walletsDisplay;
  const isScanQR = props?.appData?.template?.pwaAppOptions?.scanQR;
  const isMyCards = props?.appData?.template?.pwaAppOptions?.myCards;
  const isAirtimeData = props?.appData?.template?.pwaAppOptions?.airtimeData;
  const isAirtimeDataElectricity = props?.appData?.template?.pwaAppOptions?.buyAirtimeDataElectricity;
  const isElectricityWater = props?.appData?.template?.pwaAppOptions?.electricityWater;
  const isPayBill = props?.appData?.template?.pwaAppOptions?.payBill;
  const isCollections = props?.appData?.template?.pwaAppOptions?.collections;
  const isTransactInHome = props?.appData?.template?.pwaAppOptions?.transactInHome;

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const history = useHistory();

  const goToWallet = (walletDetailData) => {
    history.push(
      {
        pathname: '/pages/wallets-detail',
        state: { walletDetailData: walletDetailData }
      });
  };

  const payQR = () => {
    history.push({
      pathname: '/pages/pay-qr',
      state: { fromScreen: 'dashboard' }
    });
  }

  const manageCards = () => {
    history.push({
      pathname: '/pages/manage-linked-cards',
      state: { fromScreen: 'dashboard' }
    });
  }

  const transact = () => {
    history.push({
      pathname: '/pages/transact',
      state: { fromScreen: 'dashboard' }
    });
  }

  const transactionHistory = () => {
    history.push({
      pathname: '/pages/transaction-history',
      state: { fromScreen: 'dashboard' }
    });
  }

  const createWallet = () => {
    history.push('/pages/create-wallet');
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const getAddress = useCallback(() => {
    setLoading(true);
    const addressAPI = new DCustomerManagementApi();
    addressAPI.tenantsTenantIdCustomersCustomerIdAddressesGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((addressAPIResponse) => {
      if (addressAPIResponse.data && addressAPIResponse.data.length > 0) {
        history.push({
          pathname: '/pages/register-my-physical-card',
          state: { physicalCardData: [], isPhysicalCardAdded: !isAllowCreatePhysicalCardWallet }
        });
      } else {
        history.push({
          pathname: '/pages/add-physical-card-personal-info',
          state: {
            physicalCardData: [],
            isPhysicalCardAdded: !isAllowCreatePhysicalCardWallet,
            fromScreen: 'dashboard',
            flow: 'add-physical-card'
          }
        });
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [history, props, redirectToErrorScreen, isAllowCreatePhysicalCardWallet]);

  const addPhysicalCard = () => {
    getAddress();
  }

  const addVirtualCard = () => {
    history.push({
      pathname: '/pages/create-virtual-card',
      state: { virtualCardData: [], isVirtualCardAdded: !isAllowCreateVirtualCardWallet }
    });
  }

  const redirectToVASEnterMobile = () => {
    if (isVASPreCheckNetworkPartner) {
      let networkPartners = vasNetworkPartners.filter((partner) => (partner?.id !== vasConfig?.partners?.masterpass?.id) && (partner?.id !== vasConfig?.partners?.kganya?.id) && (partner?.id !== vasConfig?.partners?.electricity?.id));
      networkPartners = networkPartners.sort((a, b) => {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      });

      history.push({
        pathname: '/pages/vas-network-partners',
        state: { networkPartners: networkPartners }
      });
    } else {
      history.push({
        pathname: '/pages/vas-enter-mobile',
        state: { vasType: 'AIRTIMEDATA' }
      });
    }
  }

  const redirectToVASCollections = (vasType) => {
    history.push({
      pathname: '/pages/vas-enter-idnumber',
      state: { vasType: vasType, fromScreen: 'dashboard' }
    });
  }

  const redirectToVAS = (vasType) => {
    window.open("https://payat.io/qr/home")
  }

  const redirectToVASBuyAirtimeDataElectricity = () => {
    history.push({
      pathname: '/pages/vas-airtime-data-electricity'
    });
  }

  const updateState = useCallback((activeWallets, defaultWallet, physicalCards) => {
    const appData = {
      ...props?.appData,
      wallets: activeWallets,
      defaultWallet,
      physicalCards,
      isRefresh: false
    }
    props.dashboardHandler(appData);
  }, [props]);

  const setPhysicalCardsData = useCallback((activeWallets, defaultWallet, physicalCards) => {
    if (physicalCards && physicalCards.length > 0) {
      updateState(activeWallets, defaultWallet, physicalCards);
    }
  }, [updateState]);

  const setActiveWalletsValue = useCallback((value) => {
    setAllowCreateWallet(value < allowActiveWallet);
  }, [setAllowCreateWallet, allowActiveWallet]);

  const setVirtualCardWalletsValue = useCallback((value) => {
    setAllowCreateVirtualCardWallet(value < allowActiveVirtualCardWallet);
  }, [setAllowCreateVirtualCardWallet, allowActiveVirtualCardWallet]);

  const setPhysicalCardWalletsValue = useCallback((value, activeWallets, defaultWallet, physicalCards) => {
    setAllowCreatePhysicalCardWallet(value < allowActivePhysicalCardWallet);
    setPhysicalCardsData(activeWallets, defaultWallet, physicalCards);
  }, [setAllowCreatePhysicalCardWallet, allowActivePhysicalCardWallet, setPhysicalCardsData]);

  const getWalletCards = useCallback((activeCardsWallets, appStateProps, activeWallets, defaultWallet) => {
    let activePhysicalCardWallets = 0;
    let activeVirtualCardWallets = 0;
    activeCardsWallets.forEach(walletDetailData => {
      const walletCardsAPI = new HWalletsApi();
      walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(appStateProps?.appData?.auth?.tenantId, walletDetailData?.walletId).then((walletCardsAPIResponse) => {
        const virtualCards = walletCardsAPIResponse.data.filter(cardData => cardData?.cardType === EclipseCardType?.VIRTUAL);
        if (virtualCards && virtualCards.length > 0 && allowActiveVirtualCardWallet > 0) {
          activeVirtualCardWallets = activeVirtualCardWallets + 1;
        }

        const physicalCards = walletCardsAPIResponse.data.filter(cardData => cardData?.cardType === EclipseCardType?.PHYSICAL);
        if (physicalCards && physicalCards.length > 0 && allowActivePhysicalCardWallet > 0) {
          activePhysicalCardWallets = activePhysicalCardWallets + 1;
        }

        if (allowActiveVirtualCardWallet > 0) {
          setVirtualCardWalletsValue(activeVirtualCardWallets > 0 ? activeVirtualCardWallets : 0);
        }

        if (allowActivePhysicalCardWallet > 0) {
          setPhysicalCardWalletsValue(activePhysicalCardWallets > 0 ? activePhysicalCardWallets : 0, activeWallets, defaultWallet, physicalCards);
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    });
  }, [redirectToErrorScreen, setPhysicalCardWalletsValue, setVirtualCardWalletsValue, allowActivePhysicalCardWallet, allowActiveVirtualCardWallet]);

  const getWallets = useCallback((appStateProps) => {
    setLoading(true);
    const customerWalletAPI = new DCustomerManagementApi();
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsGet(appStateProps?.appData?.auth?.tenantId, appStateProps?.appData?.customer?.customerId).then((customerWalletAPIResponse) => {
      if (customerWalletAPIResponse.data && customerWalletAPIResponse.data.length < 1) {
        setLoading(false);
        setActiveWalletsValue(0);
        setPhysicalCardWalletsValue(0, null, null, []);
        setVirtualCardWalletsValue(0);
        if (appStateProps?.appData?.template?.manifestSettings?.appName !== 'Vleissentraal') {
          showAlert(true, 'ERROR', `${walletTitleText}(s) not found. Please add your first ${walletTitleText}.`);
        }
      } else {
        const activeWallets = customerWalletAPIResponse?.data?.filter(wallet => wallet?.status !== WalletStatus?.CANCELLED) || [];
        const virtualCardsWalletsList = activeWallets?.filter(wallet => wallet?.walletTypeId === appStateProps?.appData?.template?.publicCardWalletTypeId) || [];
        const defaultWallet = activeWallets?.find(wallet => wallet?.walletTypeId === appStateProps?.appData?.template?.publicCloseLoopWalletTypeId);
        updateState(activeWallets, defaultWallet, []);
        setLoading(false);
        if (allowActiveWallet > 0) {
          if ((activeWallets.length - virtualCardsWalletsList.length) <= 0) {
            setActiveWalletsValue(0);
          } else {
            setActiveWalletsValue(activeWallets.length - virtualCardsWalletsList.length);
          }
        }
        if (virtualCardsWalletsList.length > 0) {
          getWalletCards(virtualCardsWalletsList, appStateProps, activeWallets, defaultWallet);
        } else {
          if (allowActivePhysicalCardWallet > 0) {
            setPhysicalCardWalletsValue(0, activeWallets, defaultWallet, []);
          }
          if (allowActiveVirtualCardWallet > 0) {
            setVirtualCardWalletsValue(0);
          }
        }
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [updateState, redirectToErrorScreen, walletTitleText, getWalletCards, setActiveWalletsValue, setPhysicalCardWalletsValue, setVirtualCardWalletsValue, allowActiveWallet, allowActivePhysicalCardWallet, allowActiveVirtualCardWallet]);


  React.useEffect(() => {
    if (props?.appData && props?.appData?.isRefresh && isWalletsDisplay) {
      getWallets(props);
    } else {
      const getState = localStorage.getItem('appstate');
      if (props?.appData && !getState) {
        updateState();
      }
    }
  }, [props, getWallets, isWalletsDisplay, updateState]);

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.replace(`${AuthRouteWithTemplateId('login')}`);
  }, [props, history]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        if (history.location.pathname !== "/pages/dashboard") {
          logout();
        }

      }
    };
  }, [history, logout])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <div className="wallet-list">
        <GridContainer>
          {(props?.appData && props?.appData?.wallets && isWalletsDisplay) ?
            props?.appData?.wallets.map((wallet) => {
              return (<GridItem xs={12} sm={12} md={12} key={wallet?.externalUniqueId}>
                <Card style={{ margin: '0' }}>
                  <ButtonBase onClick={() => {
                    goToWallet(wallet)
                  }} style={{ justifyContent: 'flex-start' }}>
                    <CardContent style={{ width: '100%', float: 'left' }}>
                      <h5 style={{
                        display: 'flex',
                        marginTop: '0px',
                        marginBottom: '0px'
                      }}>{(WalletTypeMode(props?.appData?.template, wallet?.walletTypeId) === WalletMode.PREPAIDCARD) ?
                        <CardIcon /> : <WalletIcon />}&nbsp;&nbsp;<b>{wallet?.name}</b></h5>
                      <div className="bottom-content">
                        <ul>
                          <li>
                            <span>Balance</span>
                            <strong>{getSymbolFromCurrency(wallet?.currency)} {wallet?.currentBalance.toFixed(2)}</strong>
                          </li>
                          <li>
                            <span>Available</span>
                            <strong>{getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)}</strong>
                          </li>
                        </ul>
                      </div>
                    </CardContent>
                  </ButtonBase>
                </Card>
              </GridItem>)
            }) : null}
          {isScanQR ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  payQR()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><QRIcon />&nbsp;&nbsp;<b>Pay
                      a
                      QR code</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isAirtimeData ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  redirectToVASEnterMobile()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                      <AirtimeDataIcon />&nbsp;&nbsp;<b>{vasConfig?.buyAirtimeDataMenu?.title}</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isAirtimeDataElectricity ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  redirectToVASBuyAirtimeDataElectricity()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                      <BuyIcon />&nbsp;&nbsp;<b>Buy Airtime, Data or Electricity</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isElectricityWater ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  redirectToVAS('BUY')
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><BuyIcon />&nbsp;&nbsp;
                      <b>{vasConfig?.buyElectricityWaterMenu?.title}</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isPayBill ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  redirectToVAS('PAY')
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><PayBillsIcon />&nbsp;&nbsp;
                      <b>{vasConfig?.payBillMenu?.title}</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isCollections ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  redirectToVASCollections('vas-enter-idnumber')
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><CollectionsIcon style={{ marginTop: '5px' }} />&nbsp;&nbsp;
                      <b>Collections</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isTransactInHome ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  transact()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><TransactIcon />&nbsp;&nbsp;<b>Transact</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isMyCards ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  manageCards()
                }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><CardIcon />&nbsp;&nbsp;<b>My linked bank
                      cards</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                transactionHistory()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><HistoryIcon />&nbsp;&nbsp;<b>Transaction History
                  </b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          {isAllowCreateVirtualCardWallet && isKycRequired ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  addVirtualCard()
                }}>
                  <CardContent>
                    <h5 style={{ display: 'flex', color: primaryColor, marginTop: '20px', marginBottom: '20px' }}>
                      <AddCircleOutlineOutlined />&nbsp;&nbsp;
                      <b>Add a Virtual Card</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isAllowCreatePhysicalCardWallet && isKycRequired && props?.appData?.userRole !== OrganisationPosition.MEMBER ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  addPhysicalCard()
                }}>
                  <CardContent>
                    <h5 style={{ display: 'flex', color: primaryColor, marginTop: '20px', marginBottom: '20px' }}>
                      <AddCircleOutlineOutlined />&nbsp;&nbsp;
                      <b>Add a Physical Card</b></h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
          {isAllowCreateWallet ?
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={() => {
                  createWallet()
                }}>
                  <CardContent>
                    <h5 style={{ display: 'flex', color: primaryColor, marginTop: '20px', marginBottom: '20px' }}>
                      <AddCircleOutlineOutlined />&nbsp;&nbsp;
                      <b>Add{(props?.appData && props?.appData?.wallets && props?.appData?.wallets?.length > 0) ? ' ' : ' your first '}{walletTitleText}</b>
                    </h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
            : null}
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  dashboardHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)