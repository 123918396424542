import React, { useCallback, useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from 'react-element-spinner';
import { AAuthenticationApi } from "eclipse-react-sdk/services/apis/aauthentication-api";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";
import { ReactComponent as PasswordIcon } from "../../assets/img/icon_enter_password.svg"
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { DCustomerManagementApi, ZGlobalFunctionalityApi } from "../../eclipse-react-sdk/services";
import { PaymentMechanism, PaymentType } from "../../eclipse-react-sdk/services/models";
import { v4 as uuidv4 } from 'uuid';
import { load } from "recaptcha-v3";
import { EnvConfig } from "../../environment.js";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { AuthQueryParamWithTemplateId } from "../../default-route.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ConfirmPaymentPin(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const passwordPINInputControlGridLength = 12 / inputControls;
  const confirmPasswordPINControlName = `confirm${passwordPINText}`;
  const tenantId = props?.appData?.auth?.tenantId;
  const customerId = props?.appData?.customer?.customerId;
  const scannedQRData = useLocation().state?.scannedQRData;
  const paymentDetails = useLocation().state?.paymentDetails;
  const cardDetailData = useLocation().state?.cardDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const userIdentity = props?.appData?.identity;
  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(confirmPasswordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);
  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }
  const redirectToSuccessScreen = (qrPaymentData) => {
    history.push({
      pathname: "/pages/success-card-payment",
      state: { qrPaymentData: { ...paymentDetails, ...scannedQRData, ...qrPaymentData } }
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateConfirmPasswordForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateConfirmPasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateConfirmPasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateConfirmPasswordForm = () => {
    //Email errors
    let errors = ValidateInputControls(inputs, confirmPasswordPINControlName, inputControls, `Payment PIN`, pinPasswordLength);
    if (errors[confirmPasswordPINControlName]) {
      return errors;
    }
    return errors
  }

  const doQRPaymentWithCard = () => {
    const cardPaymentsAPI = new DCustomerManagementApi();
    const additionalFields = [];
    scannedQRData.additionalFields.forEach(control => {
      if (paymentDetails[control?.id]) {
        control = { ...control, value: paymentDetails[control?.id] };
        additionalFields.push(control);
      }
    });
    const isVisaQR = scannedQRData?.acceptedPaymentMechanisms?.includes('VISAQR');

    const cardPaymentRequestBody = {
      additionalFields: additionalFields,
      cardOnFileId: paymentDetails?.fromWalletCard,
      externalUniqueId: uuidv4(),
      description: scannedQRData?.description ? scannedQRData?.description : `QR Payment to ${scannedQRData?.merchantName}`,
      type: isVisaQR ? PaymentType.GLOBALEMVQRCODE : PaymentType.ZAQRCODE,
      currency: 'ZAR',
      paymentData: scannedQRData?.code,
      paymentMechanism: PaymentMechanism.CARD,
      note: `QR Payment:${scannedQRData?.merchantName}`,
      amount: +paymentDetails?.amount + (paymentDetails?.tipAmount ? +paymentDetails?.tipAmount : 0),
      ...(!isVisaQR ? { landingUrl: `${window.location.origin}${process.env.PUBLIC_URL}/pages/success-card-payment${AuthQueryParamWithTemplateId() ? AuthQueryParamWithTemplateId() : ''}` } : {}) 
    }

    cardPaymentsAPI.tenantsTenantIdCustomersCustomerIdPaymentsPost(tenantId, customerId, cardPaymentRequestBody).then((cardPaymentsAPIResponse) => {
      if (cardPaymentsAPIResponse.data && cardPaymentsAPIResponse.data?.paymentId) {
        if (cardPaymentsAPIResponse.data?.completionUrl) {
          window.localStorage.setItem('appstate', btoa(JSON.stringify({ ...props?.appData, qrPaymentData: cardPaymentsAPIResponse.data })));
          window.location.replace(cardPaymentsAPIResponse.data?.completionUrl);
        } else {
          setLoading(false);
          redirectToSuccessScreen(cardPaymentsAPIResponse.data);
        }
      } else {
        setLoading(false);
        showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const successPassword = () => {
    setLoading(true);
    const loginAPI = new AAuthenticationApi();
    const loginBody = {
      identity: `${identityPrefix}PAYMENTPIN-${userIdentity}`,
      password: ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)
    }
    loginAPI.authenticationLoginPost(loginBody).then(() => {
      doQRPaymentWithCard();
    }).catch((error) => {
      setInputs({ ...CreateNewInputControls(confirmPasswordPINControlName, inputControls) })
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `Invalid Payment PIN`);
      } else {
        redirectToErrorScreen(`/pages/dashboard`, `/pages/error`);
      }
    });
  }

  const handleConfirmPasswordFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateConfirmPasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      successPassword();
    }
  }

  const backToQRPay = useCallback(() => {
    history.push({
      pathname: '/pages/confirm-pay-amount',
      state: { scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }, [history, fromScreen, scannedQRData, paymentDetails, cardDetailData])

  const redirectToResetPaymentPIN = (userIdentity) => {
    history.push({
      pathname: '/pages/reset-payment-pin',
      state: { userIdentity, scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }

  const generateOTP = (event) => {
    if (event) event.preventDefault();
    setLoading(true);
    load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
      reCaptcha.execute('OTP_VERIFICATION').then((token) => {
        if (token) {
          const headers = { gCaptcha: token };
          const httpRequestConfiguration = HttpRequestConfiguration();
          const resetPasswordAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
          httpRequestConfiguration.baseOptions = { headers };
          resetPasswordAPI.globalIdentitiesIdentityPasswordChangeInitPost(`${identityPrefix}PAYMENTPIN-${userIdentity}`).then(() => {
            redirectToResetPaymentPIN(userIdentity);
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen(`/pages/dashboard`, `error`);
            }
          });
        } else {
          showAlert(true, 'ERROR', `Please try again!`);
        }
      })
    });
  }

  React.useEffect(() => {
    return () => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToQRPay();
      }
    }
  }, [history, backToQRPay])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIcon className={classes.linkColor} onClick={backToQRPay} />
              <span className={classes.title}>Pay a QR Code</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <PasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
              </GridItem>
            </GridContainer>
            <form onSubmit={handleConfirmPasswordFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.textCenter} style={{ marginTop: '0px' }}>
                    <strong>Enter your payment PIN</strong>
                  </p>
                </GridItem>
                {ReturnInputControlsMap(confirmPasswordPINControlName, inputControls)?.map((control, index, array) => {
                  return (
                    <GridItem key={control} xs={passwordPINInputControlGridLength}
                      sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength}
                      custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                      <ValidationTextField
                        inputProps={{
                          maxLength: inputControls > 1 ? 1 : 50,
                          inputMode: pinAsPassword ? 'numeric' : ''
                        }}
                        autoComplete="new-password"
                        className={[inputControls > 1 ? passwordClasses.oneDigitPassword : '', inputControls === 4 ? ' fourDigitInput' : ''].join(' ')}
                        label={inputControls > 1 ? '' : 'Confirm your payment PIN '}
                        fullWidth
                        variant="filled"
                        id={control}
                        type="password"
                        error={!!(errors[confirmPasswordPINControlName])}
                        name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                        value={inputs[control] || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleOnKeyDown}
                        onKeyUp={handleInputKeyUp} />
                    </GridItem>
                  )
                })
                }
                {/*<GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>*/}
                {/*  {errors[confirmPasswordPINControlName] &&*/}
                {/*  <Danger><small>{errors[confirmPasswordPINControlName]}</small></Danger>}*/}
                {/*</GridItem>*/}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                    disabled={!!(errors[confirmPasswordPINControlName] || ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls).length < pinPasswordLength)}>Next</Button>
                  <p className={classes.regularFontSize + " " + classes.textCenter}>Can’t remember your PIN? <span
                    className={classes.linkColor} onClick={generateOTP}>Reset PIN</span></p>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  confirmPasswordHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentPin)