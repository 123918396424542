import {
  container,
  defaultFont,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor
} from "../../eclipse.js";
import { primaryColor, primaryHeaderBackgroundColor } from "../../../../style.scss"

const headerStyle = () => ({
  appBar: {
    backgroundColor: primaryHeaderBackgroundColor,
    boxShadow: "0 2px 4px 0 rgba(" + infoColor[0] + "0.15)",
    borderBottom: "0",
    marginBottom: "0",
    position: "fixed",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: primaryColor,
    border: "0",
    borderRadius: "0px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "4.5em",
    display: "block"
  },
  logoImg: {
    maxWidth: "9em",
    maxHeight: "3em"
  },
  container: {
    ...container
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  appResponsive: {
    top: "8px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  }
});

export default headerStyle;
