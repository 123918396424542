import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory,useLocation } from "react-router-dom";
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss"
import {ReactComponent as SuccessResetPassword} from '../../assets/img/icon_reset_passcode_sucess.svg'
import { logoutFromApp } from "../../redux-services/actions/actions.js";
import { EnvConfig } from "../../environment.js";
import { ReturnStoredTemplateId } from "../../get-template-hostname.js";
import { AuthRouteWithTemplateId } from "../../default-route.js";


const useStyles = makeStyles(themeStyle);

export function ChangeSuccessPasswordPIN(props) {
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const isForPaymentPinChange = useLocation().state?.isForPaymentPinChange
  const history = useHistory();
  const classes = useStyles();

  const logout = useCallback(() => {
    if(isForPaymentPinChange){
      history.push({pathname:"/pages/dashboard"})
    }else{
      let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
      if(!templateId){
        templateId = 'default';
      }
      const appData = {
        templateId: templateId
      }
      EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
      props.changeSuccessPasswordPINHandler({ ...appData });
      history.push(`${AuthRouteWithTemplateId('login')}`);
   }
  },[isForPaymentPinChange,history, props]);

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => { 
        if(isForPaymentPinChange){
          history.push({pathname:"/pages/dashboard"})
        }else{
          logout();
        }
      }
    };
  }, [isForPaymentPinChange,history, logout])

  return (
    <div>
      <div className="wallet-list">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center', margin: '50px 0 30px'}}>
                <SuccessResetPassword fill={primaryIconColor} stroke={primaryIconColor}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'} style={{marginBottom: '30px', fontSize: '20px'}}><b>Your new {isForPaymentPinChange?"payment PIN":passwordPINText} was set successfully.</b></p>
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={logout}>Done</Button>
              </GridItem>
            </GridContainer>
            </GridItem>
        </GridContainer>
      </div>
      
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  changeSuccessPasswordPINHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSuccessPasswordPIN)