const ReturnQueryParamTemplateId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamsTemplateId = queryParams.get("templateId");
    if (queryParamsTemplateId) {
        return queryParamsTemplateId;
    }
    return null;
};

const ReturnStoredTemplateId = () => {
    const templateId = window.localStorage.getItem('templateId');
    if (templateId && templateId !== '' && templateId !== 'undefined' && templateId !== 'null') {
        return templateId;
    }
    return null;
};

const ReturnStoredUserId = () => {
    const userId = window.localStorage.getItem('UID');
    if (userId && userId !== undefined && userId !== null && userId !== '' && userId !== 'undefined' && userId !== 'null') {
        return userId;
    }
    return null;
};

const ReturnDomainHostName = () => {
    if (window.REACT_APP_ENV !== "prod") {   
        return "payat-pwa-sandbox.ukheshe.rocks";
    }
    return window.location.hostname;
};

export {
    ReturnQueryParamTemplateId,
    ReturnStoredTemplateId,
    ReturnDomainHostName,
    ReturnStoredUserId
}