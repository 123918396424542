/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export enum AddressType {
    BRANCH = 'BRANCH',
    HEADQUARTERS = 'HEADQUARTERS',
    OPERATING = 'OPERATING',
    PHYSICAL = 'PHYSICAL',
    POSTAL = 'POSTAL',
    PRIMARY = 'PRIMARY',
    REGISTERED = 'REGISTERED'
}

