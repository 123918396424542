import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { AuthRouteWithTemplateId } from "../../default-route";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions.js";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import genericError from '../../assets/img/genericError.gif'

const useStyles = makeStyles(themeStyle);

export function RatifyKYCError(props) {
  const ratifyProcessData = useLocation().state?.ratifyProcessData;
  const selectedDocument = useLocation().state?.selectedDoc;
  const fromScreen = useLocation().state?.fromScreen;
  const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
  const kycDocuments = props?.appData?.template?.kycDocuments;
  const ratifyTrialCount = props?.appData?.template?.ratifyTrialCount;
  const history = useHistory();

  const classes = useStyles();
  const selectedDoc = kycDocuments.find(document => document.id === selectedDocument);
  const isDocumentUploaded = true;
  const isSelfieWithThumbUpUploaded = ratifyProcessData?.selfieIsASelfie;
  const passportIsAPassport = ratifyProcessData?.passportIsAPassport;
  const isSelfieMatchedWithIdentity = (selectedDocument === '1' || selectedDocument === '3') ? ratifyProcessData?.selfieMatchesPassport : ratifyProcessData?.selfieMatchesNationalIdentity;
  const isInformationMatchedWithIdentity = (selectedDocument === '1' || selectedDocument === '3') ? (ratifyProcessData?.firstNameMatchesPassport && ratifyProcessData?.lastNameMatchesPassport && ratifyProcessData?.passportNumberMatchesPassport && ratifyProcessData?.selfieMatchesPassport) : (ratifyProcessData?.firstNameMatchesNationalIdentity && ratifyProcessData?.lastNameMatchesNationalIdentity && ratifyProcessData?.identityNumberMatchesNationalIdentity && ratifyProcessData?.selfieMatchesNationalIdentity);

  const tryAgain = useCallback(() => {
    history.push({
      pathname: `/pages/identity-document`,
      state:{fromScreen}
    });
  }, [history, fromScreen]);

  const goToContactSupport = () => {
    history.push({
      pathname: `/pages/contact-support`,
      state:{fromScreen}
    });
  }

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }, [props, history]);

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        (fromScreen !== 'ASSISTED_SIGNUP' && ratifyTrialCount >= 3) ? logout() : tryAgain();
      };
    };
  }, [history, tryAgain, fromScreen, logout, ratifyTrialCount])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <span style={{ float: 'left', width: '100%', textAlign: 'center', margin: '-80px 0 0 0' }}><img src={genericError} width="200" alt="" /></span>
              <p className={classes.title + ' ' + classes.textCenter + ' m-b-0'}><b>Something doesn't look right.</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Please check your information and try again.</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isDocumentUploaded ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Identity document uploaded.
              </p>
              {selectedDoc.type==="PASSPORT"?
                <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                  <strong style={{ margin: '0 10px 0 0' }}>
                    {passportIsAPassport ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                  </strong>Uploaded identity document is passport.
                </p>
                :null}
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isSelfieWithThumbUpUploaded ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Selfie with thumbs up uploaded.
              </p>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isSelfieMatchedWithIdentity ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Selfie match document's photo.
              </p>
              <p className={classes.regularFontSize} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '5px 0' }} >
                <strong style={{ margin: '0 10px 0 0' }}>
                  {isInformationMatchedWithIdentity ? <CheckCircleOutlineOutlinedIcon className={classes.iconSuccess} /> : <CancelOutlinedIcon className={classes.iconDanger} />}
                </strong> Personal information match document.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {ratifyTrialCount < 3 ?
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={tryAgain}>Try again</Button>
                : null}
              <p className={classes.regularFontSize + ' ' + classes.textCenter} style={{ margin: '5px 0 0 0' }}><span className={classes.linkColor} onClick={goToContactSupport} >Contact support</span> {ratifySupportContact}</p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  ratifyKYCErrorHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RatifyKYCError)