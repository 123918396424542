
export default function ReturnCardType(binLookUp) {
    if (binLookUp.match('^(?:2131|1800|35)[0-9]{0,}$')) {
        return 'jcb';
    } else if (binLookUp.match('^3[47][0-9]{0,}$')) {
        return 'amex';
    } else if (binLookUp.match('^3(?:0[0-59]{1}|[689])[0-9]{0,}$')) {
        return 'diners_club';
    } else if (binLookUp.match('^4[0-9]{0,}$')) {
        return 'visa';
    } else if (binLookUp.match('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$')) {
        return 'mastercard';
    } else if (binLookUp.match('^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$')) {
        return 'discover';
    } else if (binLookUp.match('^(5[06789]|6)[0-9]{0,}$')) {
        if (binLookUp[0] === '5' && binLookUp in [5018, 5020, 5036, 5038, 5893, 6304, 6759, 6761, 6762, 6763,6759, 676770, 676774]) {
            return 'mastercard';
        } else {
            return 'maestro';
        }
    }
}