import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import { useLocation } from "react-router-dom";
import { DCustomerManagementApi, HWalletsApi } from "eclipse-react-sdk/services";
import { v4 as uuidv4 } from 'uuid';
import { WalletMode } from "eclipse-react-sdk/services/models";
import { ReactComponent as Wallet } from "../../assets/img/jounrney_create_walet.svg"
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import WalletTypeMode from "../../wallet-type-mode.js";

const useStyles = makeStyles(themeStyle);

export function ConfirmCreateWallet(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();
  const createWalletData = useLocation().state;
  const backToCreateWallet = () => {
    history.push('/pages/create-wallet');
  };

  const goToSuccessCreateWallet = (createdWalletDetailData) => {
    history.push({
      pathname: '/pages/success-create-wallet',
      state: { createdWalletDetailData: createdWalletDetailData }
    });
  };

  const backToHome = () => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.confirmCreateWalletHandler(appData);
    history.push('/pages/dashboard');
  };

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    props.appData.isRefresh = true;
    props.confirmCreateWalletHandler({ ...props?.appData });
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const registerWalletCardOnCardOnFile = (walletDetailData, walletCardDetailData) => {
    const expiryMonth = `${new Date(walletCardDetailData?.expires).getMonth()}`;
    const expiryYear = `${new Date(walletCardDetailData?.expires).getFullYear()}`;
    const expiryDate = `${expiryMonth.padStart(2, 0)}${expiryYear.substr(2, 2)}`;
    const registerWalletCardsAPI = new DCustomerManagementApi();
    const cardOnFileRequestBody = {
      alias: `${walletDetailData?.name}`,
      cardData: {
        accountType: '',
        cardOnFileId: uuidv4(),
        cardholderName: `${props?.appData?.customer?.title} ${props?.appData?.customer?.firstName} ${props?.appData?.customer?.middleName} ${props?.appData?.customer?.lastName}`,
        cvv: walletCardDetailData?.cvv,
        dob: props?.appData?.customer?.dateOfBirth,
        expiry: expiryDate,
        pan: walletCardDetailData?.pan
      },
      cardId: walletCardDetailData?.cardId,
    }
    registerWalletCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFilePost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, cardOnFileRequestBody).then((walletCardsAPIResponse) => {
      goToSuccessCreateWallet(walletDetailData);
    }).catch((error) => {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    });
  }

  const getWalletCards = (walletDetailData) => {
    const walletCardsAPI = new HWalletsApi();
    walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(props?.appData?.auth?.tenantId, walletDetailData?.walletId).then((walletCardsAPIResponse) => {
      if (walletCardsAPIResponse.data && walletCardsAPIResponse.data.length > 0) {
        registerWalletCardOnCardOnFile(walletDetailData, walletCardsAPIResponse.data[0]);
      } else {
        goToSuccessCreateWallet(walletDetailData);
      }
    }).catch((error) => {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    });
  }

  const  createWallet = () => {
    setLoading(true);
    const customerWalletAPI = new DCustomerManagementApi();
    const createWalletBody = {
      externalUniqueId: uuidv4(),
      name: createWalletData?.walletName,
      description: '',
      status: 'ACTIVE',
      walletTypeId: (createWalletData?.walletType === WalletMode.PREPAIDCARD) ? props?.appData?.template?.publicCardWalletTypeId : (createWalletData?.walletType === WalletMode.CLOSEDLOOPDIGITAL) ? props?.appData?.template?.publicCloseLoopWalletTypeId : 0
    }
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, createWalletBody).then((customerWalletAPIResponse) => {
      if (WalletTypeMode(props?.appData?.template, customerWalletAPIResponse?.data?.walletTypeId) === WalletMode.PREPAIDCARD) {
        getWalletCards(customerWalletAPIResponse.data);
      } else {
        goToSuccessCreateWallet(customerWalletAPIResponse.data);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/create-wallet', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    createWallet();
  }


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToCreateWallet}/>
              <span className={classes.title}>Confirm create a {walletTitleText?.toLowerCase()}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Wallet className={classes.screenCenterPrimaryIcon} fill={primaryIconColor} stroke={primaryIconColor} />
              <p className={classes.regularFontSize + ' ' + classes.textCenter}><b>Your about to create a new {createWalletData?.walletType === WalletMode.PREPAIDCARD ? 'virtual card' : ''} {walletTitleText?.toLowerCase()} named : {createWalletData?.walletName}</b></p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={backToHome} >Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  confirmCreateWalletHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCreateWallet)