/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EclipseBatch } from '../models';
import { EclipseBatchHistory } from '../models';
import { EclipseBinLookup } from '../models';
import { EclipseGlobalAdminUser } from '../models';
import { EclipseGlobalPayment } from '../models';
import { EclipseGlobalRatifyResult } from '../models';
import { EclipseInitiatePasswordChangeRequest } from '../models';
import { EclipseNonce } from '../models';
import { EclipseNonceVarificationRequest } from '../models';
import { EclipseNonceVarificationResponse } from '../models';
import { EclipsePasswordChangeRequest } from '../models';
import { EclipsePublicWalletInfo } from '../models';
import { EclipseVerification } from '../models';
import { ExceptionData } from '../models';
import { NewEclipseGlobalAdminUser } from '../models';
import { NewEclipseWatchList } from '../models';
import { TraceData } from '../models';
import { UpdatedEclipseGlobalAdminUser } from '../models';
import { UpdatedEclipseGlobalPayment } from '../models';
/**
 * ZGlobalFunctionalityApi - axios parameter creator
 * @export
 */
export const ZGlobalFunctionalityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the admin user
         * @summary Delete an admin user
         * @param {number} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersAdminUserIdDelete: async (adminUserId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserId' is not null or undefined
            if (adminUserId === null || adminUserId === undefined) {
                throw new RequiredError('adminUserId','Required parameter adminUserId was null or undefined when calling globalAdminUsersAdminUserIdDelete.');
            }
            const localVarPath = `/global/admin-users/{adminUserId}`
                .replace(`{${"adminUserId"}}`, encodeURIComponent(String(adminUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the admin user
         * @summary Update an admin user
         * @param {number} adminUserId 
         * @param {UpdatedEclipseGlobalAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersAdminUserIdPut: async (adminUserId: number, body?: UpdatedEclipseGlobalAdminUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserId' is not null or undefined
            if (adminUserId === null || adminUserId === undefined) {
                throw new RequiredError('adminUserId','Required parameter adminUserId was null or undefined when calling globalAdminUsersAdminUserIdPut.');
            }
            const localVarPath = `/global/admin-users/{adminUserId}`
                .replace(`{${"adminUserId"}}`, encodeURIComponent(String(adminUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the global admin users
         * @summary Get a list of global admin users
         * @param {string} [institution] institution
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersGet: async (institution?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/admin-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (institution !== undefined) {
                localVarQueryParameter['institution'] = institution;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
         * @summary Create a user which can work across all tenants
         * @param {NewEclipseGlobalAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersPost: async (body?: NewEclipseGlobalAdminUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/admin-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the nonce for the deviceId
         * @summary Get the nonce for deviceId
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttestationsDeviceIdGet: async (deviceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new RequiredError('deviceId','Required parameter deviceId was null or undefined when calling globalAttestationsDeviceIdGet.');
            }
            const localVarPath = `/global/attestations/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the verification status of nonce with info. If token is decrypted without error info field will contain decrypted json payload otherwise it will have json with errorDescription.
         * @summary Validate nonce
         * @param {string} deviceId 
         * @param {EclipseNonceVarificationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttestationsDeviceIdPost: async (deviceId: string, body?: EclipseNonceVarificationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new RequiredError('deviceId','Required parameter deviceId was null or undefined when calling globalAttestationsDeviceIdPost.');
            }
            const localVarPath = `/global/attestations/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove failed batches
         * @param {number} batchId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesBatchIdDelete: async (batchId: number, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling globalBatchesBatchIdDelete.');
            }
            const localVarPath = `/global/batches/{batchId}`
                .replace(`{${"batchId"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  Download a batch file in form of csv
         * @param {number} batchId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesBatchIdGet: async (batchId: number, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            if (batchId === null || batchId === undefined) {
                throw new RequiredError('batchId','Required parameter batchId was null or undefined when calling globalBatchesBatchIdGet.');
            }
            const localVarPath = `/global/batches/{batchId}`
                .replace(`{${"batchId"}}`, encodeURIComponent(String(batchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for batches
         * @param {string} type Type of Batch. i.e PTS_QRCODE, RATIFY_BATCH, RATIFY_SDN 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [subType] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesGet: async (type: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, subType?: string, tenantId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling globalBatchesGet.');
            }
            const localVarPath = `/global/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (subType !== undefined) {
                localVarQueryParameter['subType'] = subType;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Only support base64 encoded zip file which contain single csv file inside zip
         * @summary Upload batch
         * @param {EclipseBatch} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesPost: async (body?: EclipseBatch, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/batches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will returns response of specific BIN from masterpass 
         * @summary To get specific BIN from Masterpass
         * @param {string} bin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBinLookupBinGet: async (bin: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bin' is not null or undefined
            if (bin === null || bin === undefined) {
                throw new RequiredError('bin','Required parameter bin was null or undefined when calling globalBinLookupBinGet.');
            }
            const localVarPath = `/global/bin-lookup/{bin}`
                .replace(`{${"bin"}}`, encodeURIComponent(String(bin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the value based on the configName. Known examples being jwtVerificationPublicKey.<keyId>, pkiAuthPublicKey
         * @summary Get the value of a publically available global config value
         * @param {string} configName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalConfigConfigNameGet: async (configName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'configName' is not null or undefined
            if (configName === null || configName === undefined) {
                throw new RequiredError('configName','Required parameter configName was null or undefined when calling globalConfigConfigNameGet.');
            }
            const localVarPath = `/global/config/{configName}`
                .replace(`{${"configName"}}`, encodeURIComponent(String(configName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will initiate a password change OTP. The only required field is the identity (username). The backend will look up the associated phone number and SMS the OTP required to do a password change. This API does not need a JWT to be used.
         * @summary Initiate a password change by sending an OTP to the users phone which can be used for a POST to password-change
         * @param {string} identity 
         * @param {EclipseInitiatePasswordChangeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalIdentitiesIdentityPasswordChangeInitPost: async (identity: string, body?: EclipseInitiatePasswordChangeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'identity' is not null or undefined
            if (identity === null || identity === undefined) {
                throw new RequiredError('identity','Required parameter identity was null or undefined when calling globalIdentitiesIdentityPasswordChangeInitPost.');
            }
            const localVarPath = `/global/identities/{identity}/password-change-init`
                .replace(`{${"identity"}}`, encodeURIComponent(String(identity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The hash (OTP) must be valid and is typically provided by a call to password-change-init which will SMS the OTP to the customer. Identity is the customers username. Password is the new password to set. This API does not need a JWT to be used.
         * @summary Change a customers password
         * @param {string} identity 
         * @param {EclipsePasswordChangeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalIdentitiesIdentityPasswordChangePost: async (identity: string, body?: EclipsePasswordChangeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'identity' is not null or undefined
            if (identity === null || identity === undefined) {
                throw new RequiredError('identity','Required parameter identity was null or undefined when calling globalIdentitiesIdentityPasswordChangePost.');
            }
            const localVarPath = `/global/identities/{identity}/password-change`
                .replace(`{${"identity"}}`, encodeURIComponent(String(identity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of payments.
         * @summary Get payments
         * @param {string} [amount] amount, the Amount we want to get the payments exclusive
         * @param {string} [bankAccount] bankAccount, the Bank Account Number we want to get the payments exclusive
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [onlyUnmatched] Whether to only return unmatched payments.
         * @param {string} [paymentType] paymentType, the Payment Type we want to get the payments exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPaymentsGet: async (amount?: string, bankAccount?: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, onlyUnmatched?: boolean, paymentType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (bankAccount !== undefined) {
                localVarQueryParameter['bankAccount'] = bankAccount;
            }

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (onlyUnmatched !== undefined) {
                localVarQueryParameter['onlyUnmatched'] = onlyUnmatched;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the payment so it can be rematched and reprocessed
         * @summary update a payment
         * @param {number} paymentId 
         * @param {UpdatedEclipseGlobalPayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPaymentsPaymentIdPut: async (paymentId: number, body?: UpdatedEclipseGlobalPayment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling globalPaymentsPaymentIdPut.');
            }
            const localVarPath = `/global/payments/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to get all the ratify result either for users or organization that has been not manually verified yet.It can be filtered using the tenantId, ratifyResultId or verified status. It will return the last modified ratify(latest) result for either user or organization. If dateFromIncl and dateToExcl gave and the latest ratify result is in between the given date then it will return the result otherwise it will exclude that result
         * @summary Get the ratify results that have not been manually verified yet
         * @param {string} [dateFromIncl] 
         * @param {string} [dateToExcl] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [ratifyResultId] 
         * @param {number} [tenantId] 
         * @param {string} [verifiedStatus] Get the ratify result for customer or organisation by verified status. Supported values are PENDING, REJECTED, ACCEPTED and By default it will consider PENDING
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalRatifyGet: async (dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, ratifyResultId?: number, tenantId?: number, verifiedStatus?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/ratify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ratifyResultId !== undefined) {
                localVarQueryParameter['ratifyResultId'] = ratifyResultId;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (verifiedStatus !== undefined) {
                localVarQueryParameter['verifiedStatus'] = verifiedStatus;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * If verified status is REJECTED then it will send an email to compliance team with detail of ratify then it will get the all wallet of either organisation or customer and set each ones status to BARRED
         * @summary Update the individual  ratify result which is manually verified
         * @param {number} resultId 
         * @param {EclipseGlobalRatifyResult} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalRatifyResultIdPut: async (resultId: number, body?: EclipseGlobalRatifyResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resultId' is not null or undefined
            if (resultId === null || resultId === undefined) {
                throw new RequiredError('resultId','Required parameter resultId was null or undefined when calling globalRatifyResultIdPut.');
            }
            const localVarPath = `/global/ratify/{resultId}`
                .replace(`{${"resultId"}}`, encodeURIComponent(String(resultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will attach a log line to the current trace context of the request
         * @summary Add a log to the current trace
         * @param {TraceData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalTracesPost: async (body?: TraceData, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/traces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The path parameter (identity) should be the cell phone number used when the OTP was requested to be sent to. The code query parameter should be the OTP to check to see if its correct. Response code 200 indicates its correct. 400 indicates its incorrect. This API does not need a JWT to be used .
         * @summary Check if a OTP is correct
         * @param {string} phone 
         * @param {string} code The verification code to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalVerificationsPhoneGet: async (phone: string, code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            if (phone === null || phone === undefined) {
                throw new RequiredError('phone','Required parameter phone was null or undefined when calling globalVerificationsPhoneGet.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling globalVerificationsPhoneGet.');
            }
            const localVarPath = `/global/verifications/{phone}`
                .replace(`{${"phone"}}`, encodeURIComponent(String(phone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The method takes the cell phone number to send the OTP to. Backend will do its best to accept any format of number e.g. 083... 2783... etc. This API does not need a JWT to be used but it required google recaptcha verification code(gCaptcha) in a request header.
         * @summary Send an OTP sms to the provided Cell Phone number
         * @param {EclipseVerification} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalVerificationsPost: async (body?: EclipseVerification, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/verifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information of a wallet without disclosing personal details. Either the phone number or at least 2 of the supplied search fields must match the customer data in order for a record to be returned. This is to ensure the privacy of Eclipse customers and prevent generic searching for wallets. This API is rate limited to prevent bulk calling so callers should store the unique walletId when they find the wallet they are looking for as opposed to searching for it during a payroll run.
         * @summary Search for a wallet irrespective of tenant
         * @param {string} [accountNumber] Account number
         * @param {string} [lastName] Customers last (family) name of max length 50
         * @param {string} [nationalIdentityNumber] Max length 20
         * @param {string} [passportNumber] Max length 20
         * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
         * @param {boolean} [transferable] If transferable is true it will return the list of wallets from tenants which accept the cross tenant payments
         * @param {string} [walletFriendlyId] Will be length 8 if provided
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalWalletsGet: async (accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, transferable?: boolean, walletFriendlyId?: string, walletId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (nationalIdentityNumber !== undefined) {
                localVarQueryParameter['nationalIdentityNumber'] = nationalIdentityNumber;
            }

            if (passportNumber !== undefined) {
                localVarQueryParameter['passportNumber'] = passportNumber;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (transferable !== undefined) {
                localVarQueryParameter['transferable'] = transferable;
            }

            if (walletFriendlyId !== undefined) {
                localVarQueryParameter['walletFriendlyId'] = walletFriendlyId;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This will add the customers profile to watchlist to fraud agency
         * @summary Add Customer to Agency as WatchList
         * @param {NewEclipseWatchList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalWatchlistsPost: async (body?: NewEclipseWatchList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global/watchlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZGlobalFunctionalityApi - functional programming interface
 * @export
 */
export const ZGlobalFunctionalityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Deletes the admin user
         * @summary Delete an admin user
         * @param {number} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAdminUsersAdminUserIdDelete(adminUserId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalAdminUsersAdminUserIdDelete(adminUserId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates the admin user
         * @summary Update an admin user
         * @param {number} adminUserId 
         * @param {UpdatedEclipseGlobalAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAdminUsersAdminUserIdPut(adminUserId: number, body?: UpdatedEclipseGlobalAdminUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseGlobalAdminUser>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalAdminUsersAdminUserIdPut(adminUserId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns all the global admin users
         * @summary Get a list of global admin users
         * @param {string} [institution] institution
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAdminUsersGet(institution?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseGlobalAdminUser>>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalAdminUsersGet(institution, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
         * @summary Create a user which can work across all tenants
         * @param {NewEclipseGlobalAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAdminUsersPost(body?: NewEclipseGlobalAdminUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseGlobalAdminUser>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalAdminUsersPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the nonce for the deviceId
         * @summary Get the nonce for deviceId
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAttestationsDeviceIdGet(deviceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseNonce>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalAttestationsDeviceIdGet(deviceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the verification status of nonce with info. If token is decrypted without error info field will contain decrypted json payload otherwise it will have json with errorDescription.
         * @summary Validate nonce
         * @param {string} deviceId 
         * @param {EclipseNonceVarificationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalAttestationsDeviceIdPost(deviceId: string, body?: EclipseNonceVarificationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseNonceVarificationResponse>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalAttestationsDeviceIdPost(deviceId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove failed batches
         * @param {number} batchId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalBatchesBatchIdDelete(batchId: number, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalBatchesBatchIdDelete(batchId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary  Download a batch file in form of csv
         * @param {number} batchId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalBatchesBatchIdGet(batchId: number, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalBatchesBatchIdGet(batchId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search for batches
         * @param {string} type Type of Batch. i.e PTS_QRCODE, RATIFY_BATCH, RATIFY_SDN 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [subType] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalBatchesGet(type: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, subType?: string, tenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseBatchHistory>>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalBatchesGet(type, dateFromIncl, dateToExcl, limit, offset, subType, tenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Only support base64 encoded zip file which contain single csv file inside zip
         * @summary Upload batch
         * @param {EclipseBatch} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalBatchesPost(body?: EclipseBatch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalBatchesPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This API will returns response of specific BIN from masterpass 
         * @summary To get specific BIN from Masterpass
         * @param {string} bin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalBinLookupBinGet(bin: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseBinLookup>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalBinLookupBinGet(bin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the value based on the configName. Known examples being jwtVerificationPublicKey.<keyId>, pkiAuthPublicKey
         * @summary Get the value of a publically available global config value
         * @param {string} configName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalConfigConfigNameGet(configName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalConfigConfigNameGet(configName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This API will initiate a password change OTP. The only required field is the identity (username). The backend will look up the associated phone number and SMS the OTP required to do a password change. This API does not need a JWT to be used.
         * @summary Initiate a password change by sending an OTP to the users phone which can be used for a POST to password-change
         * @param {string} identity 
         * @param {EclipseInitiatePasswordChangeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalIdentitiesIdentityPasswordChangeInitPost(identity: string, body?: EclipseInitiatePasswordChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalIdentitiesIdentityPasswordChangeInitPost(identity, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The hash (OTP) must be valid and is typically provided by a call to password-change-init which will SMS the OTP to the customer. Identity is the customers username. Password is the new password to set. This API does not need a JWT to be used.
         * @summary Change a customers password
         * @param {string} identity 
         * @param {EclipsePasswordChangeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalIdentitiesIdentityPasswordChangePost(identity: string, body?: EclipsePasswordChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalIdentitiesIdentityPasswordChangePost(identity, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get a list of payments.
         * @summary Get payments
         * @param {string} [amount] amount, the Amount we want to get the payments exclusive
         * @param {string} [bankAccount] bankAccount, the Bank Account Number we want to get the payments exclusive
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [onlyUnmatched] Whether to only return unmatched payments.
         * @param {string} [paymentType] paymentType, the Payment Type we want to get the payments exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPaymentsGet(amount?: string, bankAccount?: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, onlyUnmatched?: boolean, paymentType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseGlobalPayment>>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalPaymentsGet(amount, bankAccount, dateFromIncl, dateToExcl, limit, offset, onlyUnmatched, paymentType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the payment so it can be rematched and reprocessed
         * @summary update a payment
         * @param {number} paymentId 
         * @param {UpdatedEclipseGlobalPayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalPaymentsPaymentIdPut(paymentId: number, body?: UpdatedEclipseGlobalPayment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalPaymentsPaymentIdPut(paymentId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Used to get all the ratify result either for users or organization that has been not manually verified yet.It can be filtered using the tenantId, ratifyResultId or verified status. It will return the last modified ratify(latest) result for either user or organization. If dateFromIncl and dateToExcl gave and the latest ratify result is in between the given date then it will return the result otherwise it will exclude that result
         * @summary Get the ratify results that have not been manually verified yet
         * @param {string} [dateFromIncl] 
         * @param {string} [dateToExcl] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [ratifyResultId] 
         * @param {number} [tenantId] 
         * @param {string} [verifiedStatus] Get the ratify result for customer or organisation by verified status. Supported values are PENDING, REJECTED, ACCEPTED and By default it will consider PENDING
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalRatifyGet(dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, ratifyResultId?: number, tenantId?: number, verifiedStatus?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseGlobalRatifyResult>>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalRatifyGet(dateFromIncl, dateToExcl, limit, offset, ratifyResultId, tenantId, verifiedStatus, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * If verified status is REJECTED then it will send an email to compliance team with detail of ratify then it will get the all wallet of either organisation or customer and set each ones status to BARRED
         * @summary Update the individual  ratify result which is manually verified
         * @param {number} resultId 
         * @param {EclipseGlobalRatifyResult} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalRatifyResultIdPut(resultId: number, body?: EclipseGlobalRatifyResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseGlobalRatifyResult>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalRatifyResultIdPut(resultId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This API will attach a log line to the current trace context of the request
         * @summary Add a log to the current trace
         * @param {TraceData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalTracesPost(body?: TraceData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TraceData>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalTracesPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The path parameter (identity) should be the cell phone number used when the OTP was requested to be sent to. The code query parameter should be the OTP to check to see if its correct. Response code 200 indicates its correct. 400 indicates its incorrect. This API does not need a JWT to be used .
         * @summary Check if a OTP is correct
         * @param {string} phone 
         * @param {string} code The verification code to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalVerificationsPhoneGet(phone: string, code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalVerificationsPhoneGet(phone, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The method takes the cell phone number to send the OTP to. Backend will do its best to accept any format of number e.g. 083... 2783... etc. This API does not need a JWT to be used but it required google recaptcha verification code(gCaptcha) in a request header.
         * @summary Send an OTP sms to the provided Cell Phone number
         * @param {EclipseVerification} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalVerificationsPost(body?: EclipseVerification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalVerificationsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns information of a wallet without disclosing personal details. Either the phone number or at least 2 of the supplied search fields must match the customer data in order for a record to be returned. This is to ensure the privacy of Eclipse customers and prevent generic searching for wallets. This API is rate limited to prevent bulk calling so callers should store the unique walletId when they find the wallet they are looking for as opposed to searching for it during a payroll run.
         * @summary Search for a wallet irrespective of tenant
         * @param {string} [accountNumber] Account number
         * @param {string} [lastName] Customers last (family) name of max length 50
         * @param {string} [nationalIdentityNumber] Max length 20
         * @param {string} [passportNumber] Max length 20
         * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
         * @param {boolean} [transferable] If transferable is true it will return the list of wallets from tenants which accept the cross tenant payments
         * @param {string} [walletFriendlyId] Will be length 8 if provided
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalWalletsGet(accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, transferable?: boolean, walletFriendlyId?: string, walletId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipsePublicWalletInfo>>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalWalletsGet(accountNumber, lastName, nationalIdentityNumber, passportNumber, phone, transferable, walletFriendlyId, walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This will add the customers profile to watchlist to fraud agency
         * @summary Add Customer to Agency as WatchList
         * @param {NewEclipseWatchList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalWatchlistsPost(body?: NewEclipseWatchList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ZGlobalFunctionalityApiAxiosParamCreator(configuration).globalWatchlistsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ZGlobalFunctionalityApi - factory interface
 * @export
 */
export const ZGlobalFunctionalityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Deletes the admin user
         * @summary Delete an admin user
         * @param {number} adminUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersAdminUserIdDelete(adminUserId: number, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalAdminUsersAdminUserIdDelete(adminUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the admin user
         * @summary Update an admin user
         * @param {number} adminUserId 
         * @param {UpdatedEclipseGlobalAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersAdminUserIdPut(adminUserId: number, body?: UpdatedEclipseGlobalAdminUser, options?: any): AxiosPromise<EclipseGlobalAdminUser> {
            return ZGlobalFunctionalityApiFp(configuration).globalAdminUsersAdminUserIdPut(adminUserId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the global admin users
         * @summary Get a list of global admin users
         * @param {string} [institution] institution
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersGet(institution?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Array<EclipseGlobalAdminUser>> {
            return ZGlobalFunctionalityApiFp(configuration).globalAdminUsersGet(institution, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
         * @summary Create a user which can work across all tenants
         * @param {NewEclipseGlobalAdminUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAdminUsersPost(body?: NewEclipseGlobalAdminUser, options?: any): AxiosPromise<EclipseGlobalAdminUser> {
            return ZGlobalFunctionalityApiFp(configuration).globalAdminUsersPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the nonce for the deviceId
         * @summary Get the nonce for deviceId
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttestationsDeviceIdGet(deviceId: string, options?: any): AxiosPromise<EclipseNonce> {
            return ZGlobalFunctionalityApiFp(configuration).globalAttestationsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the verification status of nonce with info. If token is decrypted without error info field will contain decrypted json payload otherwise it will have json with errorDescription.
         * @summary Validate nonce
         * @param {string} deviceId 
         * @param {EclipseNonceVarificationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalAttestationsDeviceIdPost(deviceId: string, body?: EclipseNonceVarificationRequest, options?: any): AxiosPromise<EclipseNonceVarificationResponse> {
            return ZGlobalFunctionalityApiFp(configuration).globalAttestationsDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove failed batches
         * @param {number} batchId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesBatchIdDelete(batchId: number, type?: string, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalBatchesBatchIdDelete(batchId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  Download a batch file in form of csv
         * @param {number} batchId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesBatchIdGet(batchId: number, type?: string, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalBatchesBatchIdGet(batchId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for batches
         * @param {string} type Type of Batch. i.e PTS_QRCODE, RATIFY_BATCH, RATIFY_SDN 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [subType] 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesGet(type: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, subType?: string, tenantId?: string, options?: any): AxiosPromise<Array<EclipseBatchHistory>> {
            return ZGlobalFunctionalityApiFp(configuration).globalBatchesGet(type, dateFromIncl, dateToExcl, limit, offset, subType, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Only support base64 encoded zip file which contain single csv file inside zip
         * @summary Upload batch
         * @param {EclipseBatch} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBatchesPost(body?: EclipseBatch, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalBatchesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will returns response of specific BIN from masterpass 
         * @summary To get specific BIN from Masterpass
         * @param {string} bin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalBinLookupBinGet(bin: string, options?: any): AxiosPromise<EclipseBinLookup> {
            return ZGlobalFunctionalityApiFp(configuration).globalBinLookupBinGet(bin, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the value based on the configName. Known examples being jwtVerificationPublicKey.<keyId>, pkiAuthPublicKey
         * @summary Get the value of a publically available global config value
         * @param {string} configName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalConfigConfigNameGet(configName: string, options?: any): AxiosPromise<string> {
            return ZGlobalFunctionalityApiFp(configuration).globalConfigConfigNameGet(configName, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will initiate a password change OTP. The only required field is the identity (username). The backend will look up the associated phone number and SMS the OTP required to do a password change. This API does not need a JWT to be used.
         * @summary Initiate a password change by sending an OTP to the users phone which can be used for a POST to password-change
         * @param {string} identity 
         * @param {EclipseInitiatePasswordChangeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalIdentitiesIdentityPasswordChangeInitPost(identity: string, body?: EclipseInitiatePasswordChangeRequest, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalIdentitiesIdentityPasswordChangeInitPost(identity, body, options).then((request) => request(axios, basePath));
        },
        /**
         * The hash (OTP) must be valid and is typically provided by a call to password-change-init which will SMS the OTP to the customer. Identity is the customers username. Password is the new password to set. This API does not need a JWT to be used.
         * @summary Change a customers password
         * @param {string} identity 
         * @param {EclipsePasswordChangeRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalIdentitiesIdentityPasswordChangePost(identity: string, body?: EclipsePasswordChangeRequest, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalIdentitiesIdentityPasswordChangePost(identity, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of payments.
         * @summary Get payments
         * @param {string} [amount] amount, the Amount we want to get the payments exclusive
         * @param {string} [bankAccount] bankAccount, the Bank Account Number we want to get the payments exclusive
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {boolean} [onlyUnmatched] Whether to only return unmatched payments.
         * @param {string} [paymentType] paymentType, the Payment Type we want to get the payments exclusive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPaymentsGet(amount?: string, bankAccount?: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, onlyUnmatched?: boolean, paymentType?: string, options?: any): AxiosPromise<Array<EclipseGlobalPayment>> {
            return ZGlobalFunctionalityApiFp(configuration).globalPaymentsGet(amount, bankAccount, dateFromIncl, dateToExcl, limit, offset, onlyUnmatched, paymentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the payment so it can be rematched and reprocessed
         * @summary update a payment
         * @param {number} paymentId 
         * @param {UpdatedEclipseGlobalPayment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalPaymentsPaymentIdPut(paymentId: number, body?: UpdatedEclipseGlobalPayment, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalPaymentsPaymentIdPut(paymentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to get all the ratify result either for users or organization that has been not manually verified yet.It can be filtered using the tenantId, ratifyResultId or verified status. It will return the last modified ratify(latest) result for either user or organization. If dateFromIncl and dateToExcl gave and the latest ratify result is in between the given date then it will return the result otherwise it will exclude that result
         * @summary Get the ratify results that have not been manually verified yet
         * @param {string} [dateFromIncl] 
         * @param {string} [dateToExcl] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {number} [ratifyResultId] 
         * @param {number} [tenantId] 
         * @param {string} [verifiedStatus] Get the ratify result for customer or organisation by verified status. Supported values are PENDING, REJECTED, ACCEPTED and By default it will consider PENDING
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalRatifyGet(dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, ratifyResultId?: number, tenantId?: number, verifiedStatus?: string, options?: any): AxiosPromise<Array<EclipseGlobalRatifyResult>> {
            return ZGlobalFunctionalityApiFp(configuration).globalRatifyGet(dateFromIncl, dateToExcl, limit, offset, ratifyResultId, tenantId, verifiedStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * If verified status is REJECTED then it will send an email to compliance team with detail of ratify then it will get the all wallet of either organisation or customer and set each ones status to BARRED
         * @summary Update the individual  ratify result which is manually verified
         * @param {number} resultId 
         * @param {EclipseGlobalRatifyResult} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalRatifyResultIdPut(resultId: number, body?: EclipseGlobalRatifyResult, options?: any): AxiosPromise<EclipseGlobalRatifyResult> {
            return ZGlobalFunctionalityApiFp(configuration).globalRatifyResultIdPut(resultId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will attach a log line to the current trace context of the request
         * @summary Add a log to the current trace
         * @param {TraceData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalTracesPost(body?: TraceData, options?: any): AxiosPromise<TraceData> {
            return ZGlobalFunctionalityApiFp(configuration).globalTracesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * The path parameter (identity) should be the cell phone number used when the OTP was requested to be sent to. The code query parameter should be the OTP to check to see if its correct. Response code 200 indicates its correct. 400 indicates its incorrect. This API does not need a JWT to be used .
         * @summary Check if a OTP is correct
         * @param {string} phone 
         * @param {string} code The verification code to verify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalVerificationsPhoneGet(phone: string, code: string, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalVerificationsPhoneGet(phone, code, options).then((request) => request(axios, basePath));
        },
        /**
         * The method takes the cell phone number to send the OTP to. Backend will do its best to accept any format of number e.g. 083... 2783... etc. This API does not need a JWT to be used but it required google recaptcha verification code(gCaptcha) in a request header.
         * @summary Send an OTP sms to the provided Cell Phone number
         * @param {EclipseVerification} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalVerificationsPost(body?: EclipseVerification, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalVerificationsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information of a wallet without disclosing personal details. Either the phone number or at least 2 of the supplied search fields must match the customer data in order for a record to be returned. This is to ensure the privacy of Eclipse customers and prevent generic searching for wallets. This API is rate limited to prevent bulk calling so callers should store the unique walletId when they find the wallet they are looking for as opposed to searching for it during a payroll run.
         * @summary Search for a wallet irrespective of tenant
         * @param {string} [accountNumber] Account number
         * @param {string} [lastName] Customers last (family) name of max length 50
         * @param {string} [nationalIdentityNumber] Max length 20
         * @param {string} [passportNumber] Max length 20
         * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
         * @param {boolean} [transferable] If transferable is true it will return the list of wallets from tenants which accept the cross tenant payments
         * @param {string} [walletFriendlyId] Will be length 8 if provided
         * @param {number} [walletId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalWalletsGet(accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, transferable?: boolean, walletFriendlyId?: string, walletId?: number, options?: any): AxiosPromise<Array<EclipsePublicWalletInfo>> {
            return ZGlobalFunctionalityApiFp(configuration).globalWalletsGet(accountNumber, lastName, nationalIdentityNumber, passportNumber, phone, transferable, walletFriendlyId, walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * This will add the customers profile to watchlist to fraud agency
         * @summary Add Customer to Agency as WatchList
         * @param {NewEclipseWatchList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalWatchlistsPost(body?: NewEclipseWatchList, options?: any): AxiosPromise<void> {
            return ZGlobalFunctionalityApiFp(configuration).globalWatchlistsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZGlobalFunctionalityApi - object-oriented interface
 * @export
 * @class ZGlobalFunctionalityApi
 * @extends {BaseAPI}
 */
export class ZGlobalFunctionalityApi extends BaseAPI {
    /**
     * Deletes the admin user
     * @summary Delete an admin user
     * @param {number} adminUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalAdminUsersAdminUserIdDelete(adminUserId: number, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalAdminUsersAdminUserIdDelete(adminUserId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates the admin user
     * @summary Update an admin user
     * @param {number} adminUserId 
     * @param {UpdatedEclipseGlobalAdminUser} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalAdminUsersAdminUserIdPut(adminUserId: number, body?: UpdatedEclipseGlobalAdminUser, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalAdminUsersAdminUserIdPut(adminUserId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns all the global admin users
     * @summary Get a list of global admin users
     * @param {string} [institution] institution
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalAdminUsersGet(institution?: string, limit?: number, offset?: number, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalAdminUsersGet(institution, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * If using PKI, to generate a public/private key combination use openssl: openssl req -nodes -x509 -sha256 -newkey rsa:4096 -keyout \"PrivateKey.key\" -out \"PublicKey.crt\" -days 99999 Then extract the base64 public key from the certificate as follows: openssl x509  -in PublicKey.crt -pubkey -noout | grep -v \"\\-\\-\\-\\-\\-\" | base64 -d| base64 -w0 
     * @summary Create a user which can work across all tenants
     * @param {NewEclipseGlobalAdminUser} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalAdminUsersPost(body?: NewEclipseGlobalAdminUser, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalAdminUsersPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the nonce for the deviceId
     * @summary Get the nonce for deviceId
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalAttestationsDeviceIdGet(deviceId: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalAttestationsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the verification status of nonce with info. If token is decrypted without error info field will contain decrypted json payload otherwise it will have json with errorDescription.
     * @summary Validate nonce
     * @param {string} deviceId 
     * @param {EclipseNonceVarificationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalAttestationsDeviceIdPost(deviceId: string, body?: EclipseNonceVarificationRequest, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalAttestationsDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Remove failed batches
     * @param {number} batchId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalBatchesBatchIdDelete(batchId: number, type?: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalBatchesBatchIdDelete(batchId, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary  Download a batch file in form of csv
     * @param {number} batchId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalBatchesBatchIdGet(batchId: number, type?: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalBatchesBatchIdGet(batchId, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Search for batches
     * @param {string} type Type of Batch. i.e PTS_QRCODE, RATIFY_BATCH, RATIFY_SDN 
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the transactions inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the transactions exclusive
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [subType] 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalBatchesGet(type: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, subType?: string, tenantId?: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalBatchesGet(type, dateFromIncl, dateToExcl, limit, offset, subType, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Only support base64 encoded zip file which contain single csv file inside zip
     * @summary Upload batch
     * @param {EclipseBatch} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalBatchesPost(body?: EclipseBatch, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalBatchesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This API will returns response of specific BIN from masterpass 
     * @summary To get specific BIN from Masterpass
     * @param {string} bin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalBinLookupBinGet(bin: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalBinLookupBinGet(bin, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns the value based on the configName. Known examples being jwtVerificationPublicKey.<keyId>, pkiAuthPublicKey
     * @summary Get the value of a publically available global config value
     * @param {string} configName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalConfigConfigNameGet(configName: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalConfigConfigNameGet(configName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This API will initiate a password change OTP. The only required field is the identity (username). The backend will look up the associated phone number and SMS the OTP required to do a password change. This API does not need a JWT to be used.
     * @summary Initiate a password change by sending an OTP to the users phone which can be used for a POST to password-change
     * @param {string} identity 
     * @param {EclipseInitiatePasswordChangeRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalIdentitiesIdentityPasswordChangeInitPost(identity: string, body?: EclipseInitiatePasswordChangeRequest, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalIdentitiesIdentityPasswordChangeInitPost(identity, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The hash (OTP) must be valid and is typically provided by a call to password-change-init which will SMS the OTP to the customer. Identity is the customers username. Password is the new password to set. This API does not need a JWT to be used.
     * @summary Change a customers password
     * @param {string} identity 
     * @param {EclipsePasswordChangeRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalIdentitiesIdentityPasswordChangePost(identity: string, body?: EclipsePasswordChangeRequest, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalIdentitiesIdentityPasswordChangePost(identity, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a list of payments.
     * @summary Get payments
     * @param {string} [amount] amount, the Amount we want to get the payments exclusive
     * @param {string} [bankAccount] bankAccount, the Bank Account Number we want to get the payments exclusive
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the payments inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the payments exclusive
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {boolean} [onlyUnmatched] Whether to only return unmatched payments.
     * @param {string} [paymentType] paymentType, the Payment Type we want to get the payments exclusive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalPaymentsGet(amount?: string, bankAccount?: string, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, onlyUnmatched?: boolean, paymentType?: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalPaymentsGet(amount, bankAccount, dateFromIncl, dateToExcl, limit, offset, onlyUnmatched, paymentType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the payment so it can be rematched and reprocessed
     * @summary update a payment
     * @param {number} paymentId 
     * @param {UpdatedEclipseGlobalPayment} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalPaymentsPaymentIdPut(paymentId: number, body?: UpdatedEclipseGlobalPayment, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalPaymentsPaymentIdPut(paymentId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Used to get all the ratify result either for users or organization that has been not manually verified yet.It can be filtered using the tenantId, ratifyResultId or verified status. It will return the last modified ratify(latest) result for either user or organization. If dateFromIncl and dateToExcl gave and the latest ratify result is in between the given date then it will return the result otherwise it will exclude that result
     * @summary Get the ratify results that have not been manually verified yet
     * @param {string} [dateFromIncl] 
     * @param {string} [dateToExcl] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [ratifyResultId] 
     * @param {number} [tenantId] 
     * @param {string} [verifiedStatus] Get the ratify result for customer or organisation by verified status. Supported values are PENDING, REJECTED, ACCEPTED and By default it will consider PENDING
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalRatifyGet(dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, ratifyResultId?: number, tenantId?: number, verifiedStatus?: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalRatifyGet(dateFromIncl, dateToExcl, limit, offset, ratifyResultId, tenantId, verifiedStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * If verified status is REJECTED then it will send an email to compliance team with detail of ratify then it will get the all wallet of either organisation or customer and set each ones status to BARRED
     * @summary Update the individual  ratify result which is manually verified
     * @param {number} resultId 
     * @param {EclipseGlobalRatifyResult} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalRatifyResultIdPut(resultId: number, body?: EclipseGlobalRatifyResult, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalRatifyResultIdPut(resultId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This API will attach a log line to the current trace context of the request
     * @summary Add a log to the current trace
     * @param {TraceData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalTracesPost(body?: TraceData, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalTracesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The path parameter (identity) should be the cell phone number used when the OTP was requested to be sent to. The code query parameter should be the OTP to check to see if its correct. Response code 200 indicates its correct. 400 indicates its incorrect. This API does not need a JWT to be used .
     * @summary Check if a OTP is correct
     * @param {string} phone 
     * @param {string} code The verification code to verify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalVerificationsPhoneGet(phone: string, code: string, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalVerificationsPhoneGet(phone, code, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The method takes the cell phone number to send the OTP to. Backend will do its best to accept any format of number e.g. 083... 2783... etc. This API does not need a JWT to be used but it required google recaptcha verification code(gCaptcha) in a request header.
     * @summary Send an OTP sms to the provided Cell Phone number
     * @param {EclipseVerification} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalVerificationsPost(body?: EclipseVerification, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalVerificationsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns information of a wallet without disclosing personal details. Either the phone number or at least 2 of the supplied search fields must match the customer data in order for a record to be returned. This is to ensure the privacy of Eclipse customers and prevent generic searching for wallets. This API is rate limited to prevent bulk calling so callers should store the unique walletId when they find the wallet they are looking for as opposed to searching for it during a payroll run.
     * @summary Search for a wallet irrespective of tenant
     * @param {string} [accountNumber] Account number
     * @param {string} [lastName] Customers last (family) name of max length 50
     * @param {string} [nationalIdentityNumber] Max length 20
     * @param {string} [passportNumber] Max length 20
     * @param {string} [phone] Either with or without the country code. E.g. 0821112222 or 27821112222
     * @param {boolean} [transferable] If transferable is true it will return the list of wallets from tenants which accept the cross tenant payments
     * @param {string} [walletFriendlyId] Will be length 8 if provided
     * @param {number} [walletId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalWalletsGet(accountNumber?: string, lastName?: string, nationalIdentityNumber?: string, passportNumber?: string, phone?: string, transferable?: boolean, walletFriendlyId?: string, walletId?: number, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalWalletsGet(accountNumber, lastName, nationalIdentityNumber, passportNumber, phone, transferable, walletFriendlyId, walletId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This will add the customers profile to watchlist to fraud agency
     * @summary Add Customer to Agency as WatchList
     * @param {NewEclipseWatchList} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZGlobalFunctionalityApi
     */
    public globalWatchlistsPost(body?: NewEclipseWatchList, options?: any) {
        return ZGlobalFunctionalityApiFp(this.configuration).globalWatchlistsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
