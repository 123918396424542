import React from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { ReactComponent as Wallet } from "../../../assets/img/jounrney_create_walet.svg"

const useStyles = makeStyles(themeStyle);

export function TopupRetailerToken(props) {
  //console.log("TopupRetailerToken Props :: ", props?.appData);
  const walletDetailData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const topupRetailerData = useLocation().state?.topupRetailerData;
  const topupRetailerTokenData = useLocation().state?.topupRetailerTokenData;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const backToWalletTopUp = () => {
    history.push({
      pathname: `/pages/topup`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletTopUp}/>
              <span className={classes.title}>Top-up at a retail store</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
              <Wallet fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5 className={classes.textCenter + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}><b>Please give {getSymbolFromCurrency(walletDetailData?.currency)}{(+topupRetailerData?.amount).toFixed(2)} to a Pick n Pay teller with reference number <b style={{ color: primaryColor }} >{topupRetailerTokenData?.completionToken}</b>.</b></h5>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                The <i>amount</i> will immediately reflect in your {walletTitleText.toLowerCase()}.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5 className={classes.textCenter + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}><b>Follow these steps to deposit money at any Pick n Pay teller.</b></h5>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                1. From the menu select 'Value Added Services'
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                2. Select 'Mobile'
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                3. Select top-up
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                4. Select {getSymbolFromCurrency(walletDetailData?.currency)}{(+topupRetailerData?.amount).toFixed(2)}
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                5. Insert your ID/Passport number and your reference number <b style={{ color: primaryColor }} >{topupRetailerTokenData?.completionToken}</b>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToWalletTopUp}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TopupRetailerToken)