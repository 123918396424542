/**
 * Created by: Masud Shaikh
 * Date: 29/09/2021
 **/

import React, {useState, useCallback} from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {useHistory, useLocation} from "react-router-dom";

import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import {SpinnerComponent} from "react-element-spinner";
import {primaryColor, primaryIconColor} from "../../style.scss";
import {ReactComponent as EnterPin} from "../../assets/img/icon_enter_password.svg";
import {ICardManagementApi} from "../../eclipse-react-sdk/services";

const useStyles = makeStyles(themeStyle);

export function ResetPhysicalCardPin(props) {
    // console.log("ResetPhysicalCardPin props :: ", props?.appData);
    const phone1 = props?.appData?.customer?.phone1;
    const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
    const cardId = useLocation().state?.cardId;
    const history = useHistory();
    const classes = useStyles();

    const goToSuccessResetPin = () => {
        history.push({
            pathname: '/pages/success-card-reset-pin',
        });
    };
    const goToWallet = (event) => {
        event.preventDefault();
        history.push({
            pathname: '/pages/wallet-details',
        });
    };

    const [isLoading, setLoading] = useState(false);
    const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
        history.push({
            pathname: toScreen,
            state: {fromScreen: fromScreen}
        });
    }, [history]);
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const cardManger = new ICardManagementApi();
        cardManger.tenantsTenantIdCardsCardIdPinSetsPost(props?.appData?.auth?.tenantId, cardId, {
            pinDeliveryMechanism: "SMS"
        }).then(() => {
            setLoading(false);
            goToSuccessResetPin();
        }).catch((error) => {
            redirectToErrorScreen('/pages/dashboard', '/pages/error');
        });
    }
    return (
        <div>
            <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <EnterPin className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                                      stroke={primaryIconColor}/>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={handleSubmit}>
                        <GridItem xs={12} sm={12} md={12}>
                            <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                               style={{marginBottom: '30px', fontSize: '20px'}}>
                                <b>You are about to request a new PIN</b>
                            </p>
                            <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                               style={{fontSize: '16px'}}>
                                You will receive an SMS with your PIN on your phone with the number ****
                                *** {phone1?.substr(phone1.length - 3)}
                            </p>
                        </GridItem>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit">SMS My New PIN</Button>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} >
                                <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={goToWallet}>Cancel</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                        <b>Not your number?</b>
                    </p>
                    <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                        Contact support {ratifySupportContact}
                    </p>
                </GridItem>
            </GridContainer>
        </div>
    );
}

const mapStateToProps = state => ({
    appData: state.applicationData.appData
})
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPhysicalCardPin)