export default function SetTemplateTheme(appStateData, templateId) {
  document.documentElement.style.setProperty('--primaryColor', appStateData?.template['primaryColour'])
  document.documentElement.style.setProperty('--primaryIconColor', appStateData?.template['primaryIconColour'])
  document.documentElement.style.setProperty('--primaryTextColor', appStateData?.template['primaryTextColour'])
  document.documentElement.style.setProperty('--primaryBackgroundColor', appStateData?.template['primaryBackgroundColour'])
  document.documentElement.style.setProperty('--cardBackgroundColor', appStateData?.template['cardBackgroundColour'])
  document.documentElement.style.setProperty('--cardTextColor', appStateData?.template['cardTextColour'])
  document.documentElement.style.setProperty('--bankCardTextColor', appStateData?.template['bankCardTextColour'])
  document.documentElement.style.setProperty('--primaryHeaderBackgroundColor', appStateData?.template['primaryHeaderBackgroundColour'])
  document.documentElement.style.setProperty('--primaryFooterBackgroundColor', appStateData?.template['primaryFooterBackgroundColour'])
  document.documentElement.style.setProperty('--gradientFromColor', appStateData?.template['gradientFromColor'])
  document.documentElement.style.setProperty('--gradientToColor', appStateData?.template['gradientToColor'])
  document.documentElement.style.setProperty('--buttonTextColor', appStateData?.template['buttonTextColor'])
  document.documentElement.style.setProperty('--buttonDisabledColor', appStateData?.template['buttonDisabledColor'])
  document.documentElement.style.setProperty('--buttonHoverFocusTextColor', appStateData?.template['buttonHoverFocusTextColor'])
  document.documentElement.style.setProperty('--linkHoverFocusTextColor', appStateData?.template['linkHoverFocusTextColor'])
  document.documentElement.style.setProperty('--controlLabelColor', appStateData?.template['controlLabelColor'])
  document.documentElement.style.setProperty('--controlBackgroundColor', appStateData?.template['controlBackgroundColor'])
  document.documentElement.style.setProperty('--disabledCardBackgroundColor', appStateData?.template['disabledCardBackgroundColor'])

  // if (templateId && appStateData?.template?.manifestSettings) {
  //   document.getElementById("payat-pwa-title").innerHTML = `${appStateData?.template['manifestSettings']['appDescription']}`;
  //   document.querySelector('#payat-pwa-icon').setAttribute('href', `${process.env.PUBLIC_URL}/${appStateData?.template['manifestSettings']['brand']}.ico`);
  //   document.querySelector('#payat-pwa-apple-touch-icon').setAttribute('href', `${process.env.PUBLIC_URL}/${appStateData?.template['manifestSettings']['brand']}72.png`);

  //   var myDynamicManifest = {
  //     "short_name": `${appStateData?.template['manifestSettings']['appName']}`,
  //     "name": `${appStateData?.template['manifestSettings']['appDescription']}`,
  //     "icons": [
  //       {
  //         "src": `${window.location.origin}${process.env.PUBLIC_URL}/${appStateData?.template['manifestSettings']['brand']}.ico`,
  //         "sizes": "64x64 32x32 24x24 16x16",
  //         "type": "image/x-icon"
  //       },
  //       {
  //         "src": `${window.location.origin}${process.env.PUBLIC_URL}/${appStateData?.template['manifestSettings']['brand']}192.png`,
  //         "type": "image/png",
  //         "sizes": "192x192"
  //       },
  //       {
  //         "src": `${window.location.origin}${process.env.PUBLIC_URL}/${appStateData?.template['manifestSettings']['brand']}512.png`,
  //         "type": "image/png",
  //         "sizes": "512x512"
  //       }
  //     ],
  //     "start_url": `${window.location.origin}${process.env.PUBLIC_URL}/index.html`,
  //     "display": "standalone",
  //     "theme_color": `${appStateData?.template['manifestSettings']['themeColor']}`,
  //     "background_color": `${appStateData?.template['manifestSettings']['backgroundColor']}`
  //   }

  //   const stringManifest = JSON.stringify(myDynamicManifest);
  //   const blob = new Blob([stringManifest], { type: 'application/json' });
  //   const manifestURL = URL.createObjectURL(blob);

  //   if(!document.getElementById('payat-pwa-manifest')) {
  //     const link = document.createElement('link');
  //     link.id = 'payat-pwa-manifest';
  //     link.rel = 'manifest';
  //     link.href = manifestURL;
  //     document.head.appendChild(link);
  //   }
  // }
}