import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as WithdrawalIcon } from "../../assets/img/withdrawal.svg";
import { ReactComponent as QRIcon } from "../../assets/img/qr.svg";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { ReactComponent as PayBillsIcon } from "../../assets/img/pay_bills.svg";
import { ReactComponent as SendMoneyIcon } from "../../assets/img/sendMoney.svg";
import AlertNotification from "../../components/Snackbar/AlertNotification";

const useStyles = makeStyles(themeStyle);

export function Transact(props) {
  const walletDetailData = props?.appData?.defaultWallet;
  const vasConfig = props?.appData?.template?.vas;
  const isPayBill = props?.appData?.template?.pwaAppOptions?.payBillInTransact;
  const isSendMoney = props?.appData?.template?.pwaAppOptions?.sendMoneyInTransact;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const [alertData, setAlert] = React.useState(null);
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.transactHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  const payQR = () => {
    history.push({
      pathname: '/pages/pay-qr',
      state: { fromScreen: 'transact' }
    });
  }

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }
  const sendMoney = () => {
    if (walletDetailData?.availableBalance >= 1) {
      history.push({
        pathname: '/pages/send-money',
        state: { fromScreen: 'transact', walletDetailData }
      });
    }else {
      showAlert(true, 'ERROR', `Sorry! You don't have enough money in ${walletTitleText?.toLowerCase()}. Please try with other ${walletTitleText?.toLowerCase()}.`);
    }
  }

  const eftOut = () => {
    history.push('/pages/eft-out');
  }

  const generateQR = () => {
    history.push({
      pathname: '/pages/qr-generate'
    });
  };

  const redirectToVAS = (vasType) => {
    history.push({
      pathname: '/pages/vas-pay-bill-categories',
      state: { vasType: vasType, fromScreen: 'transact' }
    });
  }

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history,backToHome])
  return (
    <div>
      <div className="wallet-list">
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{display:'flex'}}>
        <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
        <span className={classes.title}>Transact</span>
        </GridItem>
        </GridContainer>
        </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <h4 className={classes.textCenter + ' m-b-0'}><b>Make a payment</b></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>  
          <Card style={{margin:'0' }}>
          <ButtonBase onClick={() => { payQR() }} style={{justifyContent: 'flex-start'}}>
            <CardContent style={{paddingBottom: '10px', paddingTop:'10px'}}>
            <h5 style={{display:'flex', marginTop:'10px', marginBottom: '10px'}} ><QRIcon/>&nbsp;&nbsp;<b>Pay a QR code</b></h5>
            </CardContent>
          </ButtonBase>
          </Card>
        </GridItem>
        {isPayBill ?
          <GridItem xs={12} sm={12} md={12}>  
            <Card style={{margin:'0' }}>
            <ButtonBase onClick={() => { redirectToVAS('PAY') }} style={{justifyContent: 'flex-start'}}>
              <CardContent style={{paddingBottom: '10px', paddingTop:'10px'}}>
              <h5 style={{display:'flex', marginTop:'10px', marginBottom: '10px'}} ><PayBillsIcon/>&nbsp;&nbsp;<b>{vasConfig?.payBillMenu?.title}</b></h5>
              </CardContent>
            </ButtonBase>
            </Card>
          </GridItem>
        : null }
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{margin:'0' }}>
          <ButtonBase onClick={() => { eftOut() }} style={{justifyContent: 'flex-start'}}>
            <CardContent style={{paddingBottom: '10px', paddingTop:'10px'}}>
            <h5 style={{display:'flex', marginTop:'10px', marginBottom: '10px'}} ><WithdrawalIcon/>&nbsp;&nbsp;<b>Make a withdrawal or EFT</b></h5>
            </CardContent>
          </ButtonBase>
          </Card>
        </GridItem>
        {(isSendMoney && walletDetailData)?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{margin:'0' }}>
              <ButtonBase onClick={sendMoney} style={{justifyContent: 'flex-start'}}>
                <CardContent style={{paddingBottom: '10px', paddingTop:'10px'}}>
                  <h5 style={{display:'flex', marginTop:'10px', marginBottom: '10px'}} ><SendMoneyIcon/>&nbsp;&nbsp;<b>{"Send Money"}</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null }
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <h4 className={classes.textCenter + ' m-b-0'}><b>Get paid</b></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>  
          <Card style={{margin:'0' }}>
          <ButtonBase onClick={() => { generateQR() }} style={{justifyContent: 'flex-start'}}>
            <CardContent style={{paddingBottom: '10px', paddingTop:'10px'}}>
            <h5 style={{display:'flex', marginTop:'10px', marginBottom: '10px'}} ><QRIcon/>&nbsp;&nbsp;<b>Generate a QR code</b></h5>
            </CardContent>
          </ButtonBase>
          </Card>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div> 
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  transactHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Transact)