import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as PaymentFailed } from '../../assets/img/payment_failed.svg'
import { loginToApp } from "../../redux-services/actions/actions.js";
import { QPaymentsApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import { ReactComponent as SuccessTransactionIcon } from "../../assets/img/success_transfer_wallet.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import { grayColor } from "../../assets/jss/eclipse";
import ReturnCardType from "../../components/PaymentOptions/ReturnCardType";
import moment from "moment";

const useStyles = makeStyles(themeStyle);

const delay = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function SuccessCardPayment(props) {
  const stateData = useLocation()?.state?.qrPaymentData;
  const [qrPaymentData, setQRPaymentData] = useState(stateData ? stateData : props?.appData?.qrPaymentData);
  const [requestCount, setRequestCount] = useState(0);
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [alertData, setAlert] = useState(null);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const handleSubmit = useCallback(() => {
    props.appData.fromScreen = null;
    props.appData.qrPaymentData = null;
    props.appData.isRefresh = true;
    props.successCardPaymentHandler({ ...props?.appData });
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [props, history]);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const getQRPaymentStatus = useCallback((qrPaymentData) => {
    if (requestCount > 89 || qrPaymentData?.status !== 'PENDING') {
      if (requestCount > 89) {
        const updatedQRPaymentData = {
          ...qrPaymentData,
          status: 'TIMEDOUT'
        }
        setQRPaymentData(updatedQRPaymentData);
      }
      setLoading(false);
      return;
    }
    if (requestCount < 90 && qrPaymentData && qrPaymentData?.paymentId && qrPaymentData?.status === 'PENDING') {
      const walletCardPaymentAPI = new QPaymentsApi();
      walletCardPaymentAPI.tenantsTenantIdPaymentsPaymentIdGet(props?.appData?.auth?.tenantId, qrPaymentData?.paymentId).then(async (walletCardPaymentAPIResponse) => {
        if (walletCardPaymentAPIResponse.data && walletCardPaymentAPIResponse.data?.paymentId && walletCardPaymentAPIResponse.data?.status) {
          if (walletCardPaymentAPIResponse.data?.status === 'PENDING') {
            await delay(1000);
            setRequestCount(requestCount + 1);
          } else {
            setQRPaymentData(walletCardPaymentAPIResponse.data);
          }
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    } else {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    }
  }, [props, redirectToErrorScreen, requestCount]);

  React.useEffect(() => {
    if (qrPaymentData?.status !== 'TIMEDOUT') {
      getQRPaymentStatus(qrPaymentData);
    }
  }, [getQRPaymentStatus, qrPaymentData]);

  React.useEffect(() => {
    window.onpopstate = () => {
      handleSubmit();
    }
  }, [history, handleSubmit])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      {qrPaymentData?.status === 'PENDING' ?
        <></>
        :
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
                  {qrPaymentData?.status === 'SUCCESSFUL' ?
                    <SuccessTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} /> :
                    <PaymentFailed fill={primaryIconColor} stroke={primaryIconColor} />
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.textCenter + ' m-t-0'}>
                    <b>
                      {qrPaymentData?.status === 'SUCCESSFUL' ?
                        'Payment successful!' :
                        'Payment failed!'
                      }
                    </b>
                  </p>
                  <p className={classes.textCenter + ' m-b-0 m-t-0'}>
                    <b>{qrPaymentData?.merchantName}</b>
                  </p>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                    style={{ fontWeight: '400' }}>
                    {moment(qrPaymentData?.created).format("MM/DD/yyyy HH:mm:ss")}
                  </p>
                  {qrPaymentData?.retrievalReferenceNumber ?
                    <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0 m-b-0'}
                      style={{ fontWeight: '400' }}>
                      Reference no. <b>{qrPaymentData?.retrievalReferenceNumber}</b>
                    </p> : null}
                  <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}>
                    <strong>Amount</strong>
                  </p>
                  <p className={classes.textCenter + ' m-b-0 m-t-0'}>
                    <b style={{
                      fontSize: '35px',
                      color: primaryColor
                    }}>{getSymbolFromCurrency(qrPaymentData?.currency)}{(Math.abs(qrPaymentData?.amount)).toFixed(2)}</b>
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{
                  paddingTop: '0px',
                  borderBottomWidth: 'thin',
                  borderBottomStyle: 'solid',
                  borderBottomColor: grayColor[1]
                }}>
                  <p className={classes.regularFontSize + " " + classes.textCenter}><b>Transaction Details</b></p>
                </GridItem>
              </GridContainer>
              {qrPaymentData?.merchantId ?
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                    <p className={classes.regularFontSize + ' m-b-0 m-t-0'}
                      style={{ textAlign: 'left', fontWeight: '400' }}>
                      Merchant ID
                    </p>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                    <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right', wordBreak: 'break-all' }}>
                      <b>{qrPaymentData?.merchantId}</b>
                    </p>
                  </GridItem>
                </GridContainer>
                : null}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                    Card Number
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>**** **** {qrPaymentData?.paymentInstrumentInfo?.cardLast4}</b>
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                    Card Type
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>{qrPaymentData?.paymentInstrumentInfo?.cardBin ? ReturnCardType(qrPaymentData?.paymentInstrumentInfo?.cardBin).toUpperCase() : "VISA"}</b>
                  </p>
                </GridItem>
              </GridContainer>
              {qrPaymentData?.authCode ?
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                    <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                      Auth Code
                    </p>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                    <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                      <b>{qrPaymentData?.authCode}</b>
                    </p>
                  </GridItem>
                </GridContainer> : null
              }
              {qrPaymentData?.authMethod ?
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                    <p className={classes.regularFontSize + ' m-b-0 m-t-0'}
                      style={{ textAlign: 'left', fontWeight: '400' }}>
                      Auth Method
                    </p>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                    <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                      <b>{qrPaymentData?.authMethod}</b>
                    </p>
                  </GridItem>
                </GridContainer>
                : null}
              {qrPaymentData?.ica ? <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                    ICA
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>{qrPaymentData?.ica}</b>
                  </p>
                </GridItem>
              </GridContainer> : null}
              {qrPaymentData?.meterNumber ? <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                    Meter Number
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>{qrPaymentData?.meterNumber}</b>
                  </p>
                </GridItem>
              </GridContainer> : null}
              {qrPaymentData?.token ? <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                    You will receive token on
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>{qrPaymentData?.token}</b>
                  </p>
                </GridItem>
              </GridContainer> : null}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                    onClick={handleSubmit}>
                    {qrPaymentData?.status === 'SUCCESSFUL' ? 'Done' : null}
                    {qrPaymentData?.status === 'PENDING' ? 'Wait...' : null}
                    {qrPaymentData?.status !== 'SUCCESSFUL' && qrPaymentData?.status !== 'PENDING' ? 'Back' : null}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      }
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successCardPaymentHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessCardPayment)