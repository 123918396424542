import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { TextField } from "@material-ui/core";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger.js";
import { AddressType } from "eclipse-react-sdk/services/models";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { DCustomerManagementApi } from "../../eclipse-react-sdk/services";
import AlertNotification from "../Snackbar/AlertNotification";
import { primaryColor } from "../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import Switch from "@material-ui/core/Switch";
import HttpRequestConfiguration from "../../http-request-configuration.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function PersonalInformation(props) {
  const assistedCustomer = props?.appData?.assistedCustomer;
  let customer = props?.appData?.customer;
  const flow = props?.flow;
  if (assistedCustomer && (flow === "assisted-physical-card" || flow === "assisted-signup")) {
    customer = { ...assistedCustomer }
  }
  const onComplete = props?.onComplete;
  const saProvincesList = props?.appData?.template?.saProvincesList;
  const termsAndConditionsLink = props?.appData?.template?.defaultPWATandCLink;
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);
  const [isBlockOrEstate, setIsBlockOrEstate] = React.useState(true);
  const personalInfo = { dateOfBirth: "", gender: "", maritalStatus: "" };
  const addressInfo = {
    line1: "",
    line2: "",
    line3: "",
    city: "",
    state: "",
    code: ""
  };
  const [inputs, setInputs] = useState({ personalInfo, addressInfo });
  const [errors, setErrors] = useState({});
  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }
  const saveAddressDetails = (customer,data) => {
    setLoading(true);
    const addressInfo = inputs.addressInfo
    const httpRequestConfiguration = HttpRequestConfiguration(((flow === "assisted-physical-card" || flow === "assisted-signup") ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    if (addressInfo.line1.trim() === '') {
      addressInfo.line1 = addressInfo.line2;
      addressInfo.line2 = addressInfo.city;
    }
    addressInfo.country = "South Africa";
    addressInfo.addressType = AddressType.PHYSICAL;
    if(!isBlockOrEstate){
      delete addressInfo.line3;
    }
    customerAPI.tenantsTenantIdCustomersCustomerIdAddressesPost(props?.appData?.auth?.tenantId, customer?.customerId, addressInfo).then(() => {
      onComplete(customer, data);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/pages/dashboard`, `/pages/error`);
      }
    });
  }

  const savePersonalDetails = () => {
    setLoading(true);
    const personalInfo = inputs.personalInfo
    personalInfo.dateOfBirth = moment(personalInfo?.dateOfBirth).format("YYYYMMDD")
    const customerData = {
      ...customer,
      dateOfBirth: personalInfo?.dateOfBirth,
      gender: personalInfo?.gender,
      maritalStatus: personalInfo?.maritalStatus
    }
    const httpRequestConfiguration = HttpRequestConfiguration(((flow === "assisted-physical-card" || flow === "assisted-signup") ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersCustomerIdPut(props?.appData?.auth?.tenantId, customer?.customerId, customerData).then((customerApiResponse) => {
      if ((flow === "assisted-physical-card" || flow === "assisted-signup") && assistedCustomer) {
        props.appData.assistedCustomer = { ...customerApiResponse.data, ...assistedCustomer };
      } else {
        props.appData.customer = customerApiResponse.data;
      }
      props.appData.isRefresh = true;
      saveAddressDetails(customerApiResponse.data, props);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      savePersonalDetails()
    }
  }

  const handleInputChange = (event) => {
    if (event.target.name === "dateOfBirth" || event.target.name === "gender" || event.target.name === "maritalStatus") {
      inputs.personalInfo[event.target.name] = event.target.value;
    } else {
      inputs.addressInfo[event.target.name] = event.target.value;
      if (event.target.name === "city") {
        inputs.addressInfo['state'] = event.target.value;
      }
    }
    setInputs(inputs);
    const validationErrors = validateField(event);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateField(event);
    setErrors(validationErrors);
  }
  const validateField = (event) => {
    const field = document.getElementById(event.target.name);
    let regex = field?.getAttribute("regex") || "";
    regex = new RegExp(regex);
    const validationErrors = { ...errors }
    if (event.target.value === "") {
      validationErrors[event.target.name] = `${field.getAttribute('errormessage')} required`;
    } else if (!event.target.value.match(regex)) {
      validationErrors[event.target.name] = `${field ? field.getAttribute("errormessage") : "Field value"} is invalid!`;
    } else {
      delete validationErrors[event.target.name];
      if (event.target.name === "city") {
        delete validationErrors.state;
      }
    }
    if (!isBlockOrEstate && event.target.name === "line3") {
      delete validationErrors[event.target.name];
    }
    return validationErrors
  }
  const onChangeRadio = () => {
    setIsBlockOrEstate((prev) => !prev);
    setErrors({});
    inputs.addressInfo = addressInfo
    setInputs(inputs);
  };
  const validateForm = () => {
    const errors = {}
    for (const [keys] of Object.entries(inputs)) {
      for (const [key, value] of Object.entries(inputs[keys])) {
        const field = document.getElementById(key);
        let regex = field?.getAttribute("regex") || "";
        regex = new RegExp(regex);
        if (value === "") {
          errors[key] = `${field ? field.getAttribute("errormessage") : "This field"} is required`;
        } else if (!value.match(regex)) {
          errors[key] = `${field ? field.getAttribute("errormessage") : "Field value"} is invalid!`;
        } else {
          delete errors[key];
          if (key === "city") {
            delete errors.state;
          }
        }
        if (!isBlockOrEstate && key === "line3") {
          delete errors[key];
        }
      }
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p><b>
                  {
                    (flow === "assisted-physical-card" || flow === "assisted-signup") ? "Complete your customer's information" : "Please complete the following information. "
                  }
                </b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  inputProps={{ max: moment().format('YYYY-MM-DD'), errormessage: "Date of birth" }}
                  fullWidth
                  label={`Date of birth`}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                  id="dateOfBirth"
                  type="date"
                  error={!!(errors.dateOfBirth)}
                  name="dateOfBirth"
                  value={inputs.personalInfo.dateOfBirth}
                  onChange={handleInputChange}
                />
                {errors.dateOfBirth && <Danger><small>{errors.dateOfBirth}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Gender"}
                  inputProps={{ errormessage: "Gender", id: "gender" }}
                  select
                  variant="filled"
                  id="Gender"
                  type="text"
                  name="gender"
                  value={inputs.personalInfo.gender}
                  onChange={handleInputChange}>
                  <MenuItem key={"male"} value={"M"}>{"Male"}</MenuItem>
                  <MenuItem key={"female"} value={"F"}>{"Female"}</MenuItem>
                  <MenuItem key={"other"} value={"O"}>{"Other"}</MenuItem>
                </ValidationTextField>
                {errors.gender && <Danger><small>{errors.gender}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Marital status"}
                  inputProps={{ errormessage: "Marital status", id: "maritalStatus" }}
                  select
                  variant="filled"
                  id="MaritalStatus"
                  type="text"
                  name="maritalStatus"
                  value={inputs.personalInfo.maritalStatus}
                  onChange={handleInputChange}>
                  <MenuItem key={"divorced"} value={"D"}>{"Divorced"}</MenuItem>
                  <MenuItem key={"married"} value={"M"}>{"Married"}</MenuItem>
                  <MenuItem key={"single"} value={"S"}>{"Single"}</MenuItem>
                  <MenuItem key={"widowed"} value={"W"}>{"Widowed"}</MenuItem>
                </ValidationTextField>
                {errors.maritalStatus && <Danger><small>{errors.maritalStatus}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p><b>
                  {
                    (flow === "assisted-physical-card" || flow === "assisted-signup") ? "Please complete their address details." : "Please complete your address details."
                  }
                </b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize}><strong>Is your address in a block/estate?</strong>
                  <Switch
                    onChange={onChangeRadio}
                    name="isBlockEstate"
                    checked={isBlockOrEstate}
                    value={isBlockOrEstate}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </p>
              </GridItem>
              {isBlockOrEstate ?
                <GridItem xs={12} sm={12} md={12}>
                  <ValidationTextField
                    fullWidth
                    label="Block/Estate name and Unit Number"
                    inputProps={{
                      errormessage: "Block/Estate name and Unit Number",
                      regex: "^[A-Za-z0-9]+[ a-zA-Z0-9-]{3,}$"
                    }}
                    variant="filled"
                    id="line1"
                    type="text"
                    error={!!(errors.line1)}
                    name="line1"
                    value={inputs.addressInfo.line1}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp}/>
                  {errors.line1 && <Danger><small>{errors.line1}</small></Danger>}
                </GridItem> : null}
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="Street name and number"
                  inputProps={{ errormessage: "Street name and number", regex: "^[A-Za-z0-9]+[ a-zA-Z0-9-]{3,}$" }}
                  variant="filled"
                  id={isBlockOrEstate ? "line2" : "line1"}
                  type="text"
                  error={!!(errors.line2)}
                  name={isBlockOrEstate ? "line2" : "line1"}
                  value={isBlockOrEstate ? inputs.addressInfo.line2 : inputs.addressInfo.line1}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {isBlockOrEstate ?
                  errors.line2 && <Danger><small>{errors.line2}</small></Danger> :
                  errors.line1 && <Danger><small>{errors.line1}</small></Danger>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="City"
                  inputProps={{ errormessage: "City", regex: "^[A-Za-z0-9]+[ a-zA-Z0-9-]{3,}$" }}
                  variant="filled"
                  id={isBlockOrEstate ? "line3" : "line2"}
                  type="text"
                  error={!!(errors.line3)}
                  name={isBlockOrEstate ? "line3" : "line2"}
                  value={isBlockOrEstate ? inputs?.addressInfo?.line3 : inputs?.addressInfo?.line2}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {isBlockOrEstate ?
                  errors.line3 && <Danger><small>{errors.line3}</small></Danger> :
                  errors.line2 && <Danger><small>{errors.line2}</small></Danger>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={"Province"}
                  inputProps={{ errormessage: "Province", id: "city" }}
                  select
                  variant="filled"
                  id="province"
                  type="text"
                  name="city"
                  value={inputs?.addressInfo.city}
                  onChange={handleInputChange}>
                  {saProvincesList.map((province) => {
                    return (<MenuItem key={province} value={province}>{province}</MenuItem>)
                  })}
                </ValidationTextField>
                {errors.city && <Danger><small>{errors.city}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="Street Code"
                  inputProps={{
                    errormessage: "Street Code",
                    inputMode: 'decimal',
                    regex: "^[0-9-]{4,}$"
                  }}
                  variant="filled"
                  id="code"
                  type="number"
                  error={!!(errors.code)}
                  name="code"
                  value={inputs?.addressInfo?.code}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.code && <Danger><small>{errors.code}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                        disabled={!!Object.keys(errors).length > 0}>Next</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + " " + classes.regularFontSize + ' m-t-0'}
                   style={{ lineHeight: '20px' }}>
                  <a className={classes.linkColor}
                     href={termsAndConditionsLink}
                     target={"_blank"}
                     rel={"noopener noreferrer"}>
                    terms and conditions.
                  </a>
                </p>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})
export default connect(mapStateToProps)(PersonalInformation)