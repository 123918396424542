import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import styles from "../../assets/jss/eclipse/components/headerStyle.js";
import logo from '../../assets/img/ukheshelogolight.png';
import Menu from "../Menu/Menu.js"


const useStyles = makeStyles(styles);

export default function Header(props) {
  const brandLogo = props?.appData?.template?.logo ? props?.appData?.template?.logo : logo;
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
      <div className={classes.flex}>
      <img src={brandLogo} className={classes.logoImg} alt=""/>
      </div>
      <div className="header-menu">
        { props.isMenuEnabled ? <Menu {...props}/> : null }
          {/*
          <IconButton className="headerMenu"
            // color="#222"
            aria-label="open drawer"
            // onClick={props.handleDrawerToggle}
          >
            <MoreVertOutlinedIcon />
            <small>More</small>
          </IconButton>
           */}
          </div>
        {/* <div className={classes.flex}> */}
          {/* Here we create navbar brand, based on route name */}
          {/* <Button color="transparent" href="/pages/dashboard" className={classes.title}>
            <b>Eclipse PWA</b>
          </Button> */}
          {/* <img src={logo}/> */}
        {/* </div> */}
        {/* <Hidden smDown implementation="css">
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
