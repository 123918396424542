import React, { useCallback, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { primaryIconColor } from "../../style.scss";
import { ReactComponent as ResetPasswordIcon } from '../../assets/img/icon_reset_passcode.svg'
import { loginToApp, logoutFromApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { AAuthenticationApi, DCustomerManagementApi } from "../../eclipse-react-sdk/services";
import HttpRequestConfiguration from "../../http-request-configuration";
import { SpinnerComponent } from "react-element-spinner";
import { primaryColor } from "../../assets/jss/eclipse";
import { AuthQueryParamWithTemplateId, AuthRouteWithoutTemplateId, AuthRouteWithTemplateId } from "../../default-route";
import AlertNotification from "../../components/Snackbar/AlertNotification";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";

const useStyles = makeStyles(themeStyle);

export function IdentityDocument(props) {
  console.log("Identity Document props :: ", props);
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const classes = useStyles();
  const history = useHistory();
  const userIdentity = useLocation().state?.userIdentity;
  console.log("userIdentity :: ", userIdentity);
  console.log("userIdentity :: ", userIdentity);
  const userOTP = useLocation().state?.userOTP;
  const [isLoading, setLoading] = React.useState(false);
  const [customer, setCustomer] = React.useState(false);
  const [tenantHeader, setTenantHeader] = React.useState(null);
  const [alertData, setAlert] = useState(null);


  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  },[props,history]);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const redirectToError = useCallback(() => {
    redirectToErrorScreen(`/auth/login`, `error`);
  }, [redirectToErrorScreen]);

  const showAlert = useCallback((alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
      redirectToError();
    }, 3000);
  }, [redirectToError]);

  const getUser = useCallback((tenantId, tenantHeader) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
    customerAPI.tenantsTenantIdCustomersGet(tenantId, undefined, undefined, undefined, 'fields=customerId', `${identityPrefix}-${userIdentity}`).then((getCustomerAPIResponse) => {
      console.log('getCustomerAPIResponse', getCustomerAPIResponse.data)
      if (getCustomerAPIResponse.data.length > 0) {
        const [customer] = getCustomerAPIResponse.data;
        setCustomer(customer);
        // customerAPI.tenantsTenantIdCustomersCustomerIdDocumentsGet(tenantId, customer?.customerId).then((documentAPIResponse) => {
        //   console.log("documentAPIResponse.data :: ", documentAPIResponse.data);
        //   setLoading(false);
        // }).catch((error) => {
        //   setLoading(false);
        //   if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        //     showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        //   } else {
        //     redirectToError();
        //   }
        // });
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToError();
      }
    });
  }, [redirectToError, showAlert, identityPrefix, userIdentity]);

  const getTenantToken = useCallback(() => {
    setLoading(true);
    const loginAPI = new AAuthenticationApi();//httpRequestConfiguration
    const tenant = {}
    tenant['identity'] = props?.appData?.template?.tenantUsername;
    tenant['password'] = props?.appData?.template?.tenantPassword;
    loginAPI.authenticationLoginPost(tenant).then((authAPIResponse) => {
      const headers = { Authorization: authAPIResponse?.data?.headerValue };
      setTenantHeader(authAPIResponse.data);
      setLoading(false);
      getUser(authAPIResponse?.data?.tenantId, headers);
      setLoading(false);
      // console.log('tenantToken', appData);
    }).catch((error) => {
      // console.error("error :: ",error);
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToError();
      }
    });
  }, [redirectToError, getUser, showAlert, props]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        logout();
      }
    };
  }, [history, logout])

  React.useEffect(() => {
    getTenantToken();
  }, [getTenantToken]);

  const goToDocumentInitial = () => {
    const appData = {
      ...props?.appData,
      identity: userIdentity,
      customer,
      auth: tenantHeader,
    }
    props.identityDocumentHandler({ ...appData });
    history.push({
      pathname: `/auth/scan-document`,
      state: { userIdentity, userOTP, customer, tenantHeader }
    });
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
              <ResetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
              <h4><strong>We need to authenticate <br/> your identity.</strong></h4>
              <p className={classes.regularFontSize + ' m-b-0'}>Please take a clear photo of your valid SA<br/>ID,
                passport or driver's license</p>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToDocumentInitial}>Next</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  identityDocumentHandler: data => dispatch(loginToApp(data)),
  logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDocument)
