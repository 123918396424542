/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Payment mechanism. CARD: Credit/Debit/Prepaid card will be used with eitherthe card data provided, card on file ID, or by a lightbox the customer can capture the card details in, CARD_PRESENT: specifica use case of card present acquiring, typically used with pos estate or tap on glass integrations, WALLET: The debit should be done on an Eclipse walletId provided, EXTERNAL_WALLET: Pay with the externalWalletId and externalWalletIdType
 * @export
 * @enum {string}
 */
export enum PaymentMechanism {
    CARD = 'CARD',
    CARDPRESENT = 'CARD_PRESENT',
    EXTERNALWALLET = 'EXTERNAL_WALLET',
    WALLET = 'WALLET'
}

