import React from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { ReactComponent as Wallet } from "../../../assets/img/jounrney_create_walet.svg"

const useStyles = makeStyles(themeStyle);

export function TopupEFT(props) {
  //console.log("TopupEFT Props :: ", props?.appData);
  const nedbankEFTDepositInstruction = props?.appData?.template?.nedbankEFTDepositInstruction;
  const walletDetailData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const backToWalletTopUp = () => {
    history.push({
      pathname: `/pages/topup`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletTopUp}/>
              <span className={classes.title}>EFT {walletTitleText.toLowerCase()} top-up</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
              <Wallet fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0'}><b>Follow these steps to top up your {walletTitleText.toLowerCase()}</b></h4>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                1. Log in to your online or mobile banking app.
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                2. Create a beneficiary using the account details below.
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                3. <b style={{ color: primaryColor }} >IMPORTANT: use your unique {walletTitleText.toLowerCase()} reference ID {walletDetailData?.friendlyId} as the beneficiary reference.</b>
                <br />
                Your number will always be the same.
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                4. The money will reflect in your {walletTitleText.toLowerCase()} with in 48 hours on weekdays.
              </p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}>
                <b>Use the following bank details:</b>
              </p>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Bank name
                  </p>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontSize: '1.2em' }}>
                    <b>{nedbankEFTDepositInstruction?.bank}</b>
                  </p>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <Button className={classes.buttonRadius} style={{ padding: '0.75em' }} size="lg" block color="transparent" onClick={() => { navigator.clipboard.writeText(nedbankEFTDepositInstruction?.bank) }} >Copy</Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Branch code
                  </p>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontSize: '1.2em' }}>
                    <b>{nedbankEFTDepositInstruction?.branch}</b>
                  </p>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <Button className={classes.buttonRadius} style={{ padding: '0.75em' }} size="lg" block color="transparent" onClick={() => { navigator.clipboard.writeText(nedbankEFTDepositInstruction?.branch) }} >Copy</Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Account number
                  </p>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontSize: '1.2em' }}>
                    <b>{nedbankEFTDepositInstruction?.accNumber}</b>
                  </p>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <Button className={classes.buttonRadius} style={{ padding: '0.75em' }} size="lg" block color="transparent" onClick={() => { navigator.clipboard.writeText(nedbankEFTDepositInstruction?.accNumber) }} >Copy</Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Account name
                  </p>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontSize: '1.2em' }}>
                    <b>{nedbankEFTDepositInstruction?.accName}</b>
                  </p>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <Button className={classes.buttonRadius} style={{ padding: '0.75em' }} size="lg" block color="transparent" onClick={() => { navigator.clipboard.writeText(nedbankEFTDepositInstruction?.accName) }} >Copy</Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Reference number
                  </p>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontSize: '1.2em' }}>
                    <b style={{ color: primaryColor }}>{walletDetailData?.friendlyId}</b>
                  </p>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <Button className={classes.buttonRadius} style={{ padding: '0.75em' }} size="lg" block color="transparent" onClick={() => { navigator.clipboard.writeText(walletDetailData?.friendlyId) }} >Copy</Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToWalletTopUp}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TopupEFT)