import React from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { ReactComponent as Wallet } from "../../../assets/img/jounrney_create_walet.svg"

const useStyles = makeStyles(themeStyle);

export function TopupATM(props) {
  //console.log("TopupATM Props :: ", props?.appData);
  const nedbankATMDepositInstruction = props?.appData?.template?.nedbankATMDepositInstruction;
  const walletDetailData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const backToWalletTopUp = () => {
    history.push({
      pathname: `/pages/topup`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletTopUp}/>
              <span className={classes.title}>Top-up at a ATM</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
              <Wallet fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0'}><b>Follow these steps to top up your {walletTitleText.toLowerCase()}</b></h4>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                1. Find a deposit taking ATM
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                2. Select 'Make a deposit' from the main menu
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                3. Enter the bank account details below
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                4. <b style={{ color: primaryColor }} >IMPORTANT: use your unique {walletTitleText.toLowerCase()} reference ID {walletDetailData?.referenceId} as the beneficiary reference.</b>
                <br />
                Your number will always be the same.
              </p>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                5. The money will reflect in your {walletTitleText.toLowerCase()} with in 48 hours on weekdays.
              </p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}>
                <b>Use the following bank details:</b>
              </p>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Bank name
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>{nedbankATMDepositInstruction?.bank}</b>
                  </p>
                </GridItem>
              </GridContainer>
              {/* <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Branch code
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>1111</b>
                  </p>
                </GridItem>
              </GridContainer> */}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Account number
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>{nedbankATMDepositInstruction?.accNumber}</b>
                  </p>
                </GridItem>
              </GridContainer>
              {/* <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Account name
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b>PWA Account</b>
                  </p>
                </GridItem>
              </GridContainer> */}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left' }}>
                    Reference number
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
                  <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                    <b style={{ color: primaryColor }}>{walletDetailData?.friendlyId}</b>
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToWalletTopUp}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TopupATM)