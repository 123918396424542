import React, { useState, useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "../../../components/CustomButtons/Button.js";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import { TextField } from "@material-ui/core";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { SpinnerComponent } from "react-element-spinner";
import { PVASFunctionalityApi } from "eclipse-react-sdk/services";
import { primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../../redux-services/actions/actions.js";
import ReturnMobileNumberWithISD from "../../../views-auth/returnMobileNumberWithISD.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function VASEnterMobileNumber(props) {
  const vasConfig = props?.appData?.template?.vas;
  const defaultMobileNumberRegEx = props?.appData?.template?.defaultMobileNumberRegEx;
  const history = useHistory();
  const classes = useStyles();
  const vasType = useLocation().state?.vasType;

  const selectedNetworkPartner = useLocation().state?.selectedNetworkPartner;
  const isVASPreCheckNetworkPartner = props?.appData?.template?.pwaAppOptions?.preCheckNetworkProvider;
  const vasNetworkPartners = Object.values(props?.appData?.template?.vas?.partners);


  const [alertData, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ mobileNumber: isVASPreCheckNetworkPartner && selectedNetworkPartner ? '' : props?.appData?.customer?.phone1, mobileType: isVASPreCheckNetworkPartner && selectedNetworkPartner ? '' : 'SELF' });
  const [errors, setErrors] = useState({});
  const whatsAppDefaultISDCode = props?.appData?.template?.whatsAppDefaultISDCode;

  const returnMobileNumberWithISD = () => {
    return ReturnMobileNumberWithISD(whatsAppDefaultISDCode, inputs?.mobileNumber);
  }

  const goToSelectVAS = (vasProducts) => {
    if(vasType === 'BUYELECTRICITY'){
      history.push({
        pathname: '/pages/vas-buy-airtime-data',
        state: { vasType, vasDetail: inputs, vasProducts, selectedVASProducts: { title: vasProducts?.products[0]?.title, data: vasProducts?.products[0]?.data }, selectedNetworkPartner }
      });
    }else{
      history.push({
        pathname: '/pages/vas-view-categories',
        state: { vasType, vasDetail: inputs, vasProducts, selectedNetworkPartner }
      });
    }
  };

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = useCallback(() => {
    if (isVASPreCheckNetworkPartner) {
      let networkPartners = vasNetworkPartners.filter((partner) => (partner?.id !== vasConfig?.partners?.masterpass?.id) && (partner?.id !== vasConfig?.partners?.kganya?.id) && (partner?.id !== vasConfig?.partners?.electricity?.id));
      networkPartners = networkPartners.sort((a, b) => {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      });

      history.push({
        pathname: '/pages/vas-network-partners',
        state: { networkPartners: networkPartners }
      });
    } else {
      const appData = {
        ...props?.appData,
        isRefresh: true
      }
      props.vasEnterMobileNumberHandler(appData);
      history.push(`/pages/${ (vasType === 'BUYAIRTIMEDATA' || vasType === 'BUYELECTRICITY') ? 'vas-airtime-data-electricity' : 'dashboard'}`);
    }
  }, [history, props, vasNetworkPartners, isVASPreCheckNetworkPartner, vasConfig, vasType])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])
  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const getProductsDataCategoryWise = (products) => {
    const productsGroups = {};
    const sectionGroups = [];
    const convertedProducts = [];
    products.forEach((product) => {
      convertedProducts.push({
        ...product,
        productCategory: product?.productTypeName,
        productImageUrl: product?.productImageUrl ? product?.productImageUrl : null,
        productSubCategory: product?.productSubCategory ? product?.productSubCategory : 'ALL'
      })
    })

    convertedProducts.forEach((product) => {
      const productCategory = product.productCategory;
      if (productCategory in productsGroups) {
        productsGroups[productCategory].push(product);
      } else {
        productsGroups[productCategory] = new Array(product);
      }
    });

    var productsGroupsKeys = Object.keys(productsGroups);
    productsGroupsKeys.forEach((productKey) => {
      sectionGroups.push(
        {
          title: productKey,
          data: productsGroups[productKey]
        }
      )
    });

    return sectionGroups;
  }

  const setBuyElectricityProducts = (products)=>{
    const newProducts = [];
    products.forEach((product)=>{
      const selectedProduct = vasConfig?.buyElectricityProducts.find((buyProduct) => buyProduct?.productId === product?.productId);
      if(selectedProduct && product?.fixedPriceIncl && product?.fixedPriceIncl > 0){
        product.productName = product.productDescription = selectedProduct?.productName;
        newProducts.push(product);
      }
    });
    return newProducts;
  }

  const getCatalogs = (partnerData) => {
    setLoading(true);
    const vasAPI = new PVASFunctionalityApi();
    vasAPI.tenantsTenantIdVasCatalogsGet(props?.appData.auth.tenantId).then((vasAPIResponse) => {
      if (vasAPIResponse?.data && vasAPIResponse?.data.length > 0) {
        let partnerId = partnerData?.partnerId;

        //For PAYAT, Vodacom Airtime Data bundles should be display from Masterpass catalog
        if (partnerData?.partnerId === vasConfig?.partners?.vodacom?.id && props?.appData?.template?.manifestSettings?.appName === 'PAYAT') {
          const masterpassPartnerDetail = vasAPIResponse?.data.filter(partner => partner?.partnerId === vasConfig?.partners?.masterpass?.id);
          if (masterpassPartnerDetail.length > 0 && masterpassPartnerDetail[0].products && masterpassPartnerDetail[0].products.length > 0) {
            const products = masterpassPartnerDetail[0].products.filter(masterpassProduct => masterpassProduct?.productCategory.includes(vasConfig?.buyAirtimeDataMenu?.category));
            if (products.length > 0) {
              masterpassPartnerDetail[0].partnerName = vasConfig?.partners?.vodacom?.name;
              masterpassPartnerDetail[0].products = getProductsDataCategoryWise(products);
              goToSelectVAS(masterpassPartnerDetail[0]);
              return;
            }
          }
        } else {
          const partnerDetail = vasAPIResponse?.data.filter(partner => partner?.partnerId === partnerId);
          if (partnerDetail.length > 0 && partnerDetail[0].products && partnerDetail[0].products.length > 0) {
            if(vasType === 'BUYELECTRICITY'){
              partnerDetail[0].products = setBuyElectricityProducts(partnerDetail[0].products);
            }
            partnerDetail[0].products = getProductsDataCategoryWise(partnerDetail[0].products);
            if(partnerDetail[0].products.length <= 0){
              setLoading(false);
              showAlert(true, 'ERROR', `Service products not found! Please try again.`);
              return;
            }
            goToSelectVAS(partnerDetail[0]);
            return;
          }
        }

        setLoading(false);
        showAlert(true, 'ERROR', `Service products not found! Please try again.`);
      } else {
        setLoading(false);
        showAlert(true, 'ERROR', `Service not found! Please try again.`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateVASEnterMobileNumberForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const vasAPI = new PVASFunctionalityApi();
      vasAPI.tenantsTenantIdVasMsisdnGet(props?.appData.auth.tenantId, returnMobileNumberWithISD()).then((vasAPIResponse) => {
        if (vasAPIResponse?.data && vasAPIResponse?.data?.partnerId && vasAPIResponse?.data?.partnerName) {
          if (isVASPreCheckNetworkPartner && selectedNetworkPartner?.id !== vasAPIResponse?.data?.partnerId) {
            setLoading(false);
            showAlert(true, 'ERROR', `Entered mobile number invalid! Please enter valid ${selectedNetworkPartner?.name} mobile number.`);
            return;
          }
          if(vasType === 'BUYELECTRICITY'){
            const electricityData = {
              partnerId: vasConfig?.partners?.electricity?.id,
              partnerName: vasConfig?.partners?.electricity?.name
            }
            getCatalogs(electricityData);
          }else{
            getCatalogs(vasAPIResponse?.data);
          }
        } else {
          setLoading(false);
          showAlert(true, 'ERROR', `Service not found! Please try again.`);
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    }
  }

  const onChangeRadio = (event) => {
    setInputs({ mobileNumber: event.target.value === 'SELF' ? props?.appData?.customer?.phone1 : '', mobileType: event.target.value });
    setErrors({});
  };

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateVASEnterMobileNumberForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateVASEnterMobileNumberForm(inputs);
    setErrors(validationErrors);
  }

  const validateVASEnterMobileNumberForm = (inputs) => {
    const errors = {};
    const pattern = new RegExp(defaultMobileNumberRegEx);
    if (!inputs.mobileNumber) {
      errors.mobileNumber = 'Mobile number required!'
    } else if (!pattern.test(inputs.mobileNumber)) {
      errors.mobileNumber = 'Mobile number is invalid!'
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}></ArrowBackIosIcon>
              <span className={classes.title}>{ vasType === 'BUYELECTRICITY' ? vasConfig?.buyElectricityWaterMenu?.title : vasConfig?.buyAirtimeDataMenu?.title}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <b className={classes.regularFontSize}>Who are you buying for?</b>
                {errors.mobileType && <Danger><small>{errors.mobileType}</small></Danger>}
              </GridItem>
              {!isVASPreCheckNetworkPartner && !selectedNetworkPartner ?
                <GridItem xs={12} sm={12} md={12}>
                  <RadioGroup name="mobileType" id="mobileType" value={inputs.mobileType} onChange={onChangeRadio}>
                    <FormControlLabel value="SELF" control={<Radio />} label="My own number" />
                    <FormControlLabel value="OTHER" control={<Radio />} label="Someone else number" />
                  </RadioGroup>
                </GridItem>
                : null}
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  inputProps={{ inputMode: 'tel' }}
                  disabled={inputs?.mobileType === 'SELF' ? true : false}
                  fullWidth
                  label={isVASPreCheckNetworkPartner && selectedNetworkPartner ? "Enter " + selectedNetworkPartner?.name + " mobile number" : "Enter mobile number"}
                  variant="filled"
                  id="mobileNumber"
                  type="text"
                  error={(errors.mobileNumber) ? true : false}
                  name="mobileNumber"
                  value={inputs.mobileNumber || ""}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp} />
                {errors.mobileNumber && <Danger><small>{errors.mobileNumber}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={errors.mobileNumber ? true : false}>Next</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasEnterMobileNumberHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASEnterMobileNumber)