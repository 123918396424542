/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CardPinDeliveryData } from '../models';
import { CardPinDeliveryResponse } from '../models';
import { EclipseCard } from '../models';
import { ExceptionData } from '../models';
import { UpdatedEclipseCard } from '../models';
/**
 * ICardManagementApi - axios parameter creator
 * @export
 */
export const ICardManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to get a card when the cardId is known. This is the only API that can be used to get the PAN, CVV of a card and in order to do so, the API enforces that it must be called by the cardholder. Either a JWT issued to the customer can be used from the IP address that logged in, or a valid card access password should be passed with no JWT. A card access password can be created by a POST to a customers identities. This API must only be called from the customers device if masked=false is passed.
         * @summary Get a specific card by cardId
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {string} [cap] The card access password of the customer. Required if masked&#x3D;false and no JWT is provided
         * @param {boolean} [masked] If false then the full PAN and CVV will be returned. An error will be returned if masked&#x3D;false and the caller is not the card owner or a valid CAP is not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsCardIdGet: async (tenantId: number, cardId: number, cap?: string, masked?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCardsCardIdGet.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling tenantsTenantIdCardsCardIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/cards/{cardId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (cap !== undefined) {
                localVarQueryParameter['cap'] = cap;
            }

            if (masked !== undefined) {
                localVarQueryParameter['masked'] = masked;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * SMS will SMS the PIN to the customer. If the tenant is PCI compliant then a PIN can be passed and will be set and SMS'd. If not PCI compliant, Eclipse will set the PIN to a new random 4 digit number and deliver the PIN to the customer. LINK is for non PCI compliant tenants to allow their customers to set their own PIN. This will return a URL the customer should open in a browser and enter their CAP and can set their PIN. LINK needs the customer to have a CAP
         * @summary Set and send new card PIN to customer
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {CardPinDeliveryData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsCardIdPinSetsPost: async (tenantId: number, cardId: number, body?: CardPinDeliveryData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCardsCardIdPinSetsPost.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling tenantsTenantIdCardsCardIdPinSetsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/cards/{cardId}/pin-sets`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing card. Updates can include pin sets, pin retry reset, status of the card and rules on the card. Note that the card PIN can only be set if the caller is PCI compliant or a Card Access Password (CAP) is passed. Non PCI compliant tenants backends must never see the PIN
         * @summary Update a card
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {UpdatedEclipseCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsCardIdPut: async (tenantId: number, cardId: number, body?: UpdatedEclipseCard, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCardsCardIdPut.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling tenantsTenantIdCardsCardIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/cards/{cardId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is a general API for searching for cards based on various criteria
         * @summary Search for cards in the tenant
         * @param {number} tenantId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [packId] 
         * @param {string} [pan] 
         * @param {string} [qrCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsGet: async (tenantId: number, limit?: number, offset?: number, packId?: string, pan?: string, qrCode?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdCardsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/cards`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (packId !== undefined) {
                localVarQueryParameter['packId'] = packId;
            }

            if (pan !== undefined) {
                localVarQueryParameter['pan'] = pan;
            }

            if (qrCode !== undefined) {
                localVarQueryParameter['qrCode'] = qrCode;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ICardManagementApi - functional programming interface
 * @export
 */
export const ICardManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Used to get a card when the cardId is known. This is the only API that can be used to get the PAN, CVV of a card and in order to do so, the API enforces that it must be called by the cardholder. Either a JWT issued to the customer can be used from the IP address that logged in, or a valid card access password should be passed with no JWT. A card access password can be created by a POST to a customers identities. This API must only be called from the customers device if masked=false is passed.
         * @summary Get a specific card by cardId
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {string} [cap] The card access password of the customer. Required if masked&#x3D;false and no JWT is provided
         * @param {boolean} [masked] If false then the full PAN and CVV will be returned. An error will be returned if masked&#x3D;false and the caller is not the card owner or a valid CAP is not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCardsCardIdGet(tenantId: number, cardId: number, cap?: string, masked?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCard>> {
            const localVarAxiosArgs = await ICardManagementApiAxiosParamCreator(configuration).tenantsTenantIdCardsCardIdGet(tenantId, cardId, cap, masked, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * SMS will SMS the PIN to the customer. If the tenant is PCI compliant then a PIN can be passed and will be set and SMS'd. If not PCI compliant, Eclipse will set the PIN to a new random 4 digit number and deliver the PIN to the customer. LINK is for non PCI compliant tenants to allow their customers to set their own PIN. This will return a URL the customer should open in a browser and enter their CAP and can set their PIN. LINK needs the customer to have a CAP
         * @summary Set and send new card PIN to customer
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {CardPinDeliveryData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCardsCardIdPinSetsPost(tenantId: number, cardId: number, body?: CardPinDeliveryData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardPinDeliveryResponse>> {
            const localVarAxiosArgs = await ICardManagementApiAxiosParamCreator(configuration).tenantsTenantIdCardsCardIdPinSetsPost(tenantId, cardId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update an existing card. Updates can include pin sets, pin retry reset, status of the card and rules on the card. Note that the card PIN can only be set if the caller is PCI compliant or a Card Access Password (CAP) is passed. Non PCI compliant tenants backends must never see the PIN
         * @summary Update a card
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {UpdatedEclipseCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCardsCardIdPut(tenantId: number, cardId: number, body?: UpdatedEclipseCard, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EclipseCard>> {
            const localVarAxiosArgs = await ICardManagementApiAxiosParamCreator(configuration).tenantsTenantIdCardsCardIdPut(tenantId, cardId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is a general API for searching for cards based on various criteria
         * @summary Search for cards in the tenant
         * @param {number} tenantId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [packId] 
         * @param {string} [pan] 
         * @param {string} [qrCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdCardsGet(tenantId: number, limit?: number, offset?: number, packId?: string, pan?: string, qrCode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseCard>>> {
            const localVarAxiosArgs = await ICardManagementApiAxiosParamCreator(configuration).tenantsTenantIdCardsGet(tenantId, limit, offset, packId, pan, qrCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ICardManagementApi - factory interface
 * @export
 */
export const ICardManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Used to get a card when the cardId is known. This is the only API that can be used to get the PAN, CVV of a card and in order to do so, the API enforces that it must be called by the cardholder. Either a JWT issued to the customer can be used from the IP address that logged in, or a valid card access password should be passed with no JWT. A card access password can be created by a POST to a customers identities. This API must only be called from the customers device if masked=false is passed.
         * @summary Get a specific card by cardId
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {string} [cap] The card access password of the customer. Required if masked&#x3D;false and no JWT is provided
         * @param {boolean} [masked] If false then the full PAN and CVV will be returned. An error will be returned if masked&#x3D;false and the caller is not the card owner or a valid CAP is not provided
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsCardIdGet(tenantId: number, cardId: number, cap?: string, masked?: boolean, options?: any): AxiosPromise<EclipseCard> {
            return ICardManagementApiFp(configuration).tenantsTenantIdCardsCardIdGet(tenantId, cardId, cap, masked, options).then((request) => request(axios, basePath));
        },
        /**
         * SMS will SMS the PIN to the customer. If the tenant is PCI compliant then a PIN can be passed and will be set and SMS'd. If not PCI compliant, Eclipse will set the PIN to a new random 4 digit number and deliver the PIN to the customer. LINK is for non PCI compliant tenants to allow their customers to set their own PIN. This will return a URL the customer should open in a browser and enter their CAP and can set their PIN. LINK needs the customer to have a CAP
         * @summary Set and send new card PIN to customer
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {CardPinDeliveryData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsCardIdPinSetsPost(tenantId: number, cardId: number, body?: CardPinDeliveryData, options?: any): AxiosPromise<CardPinDeliveryResponse> {
            return ICardManagementApiFp(configuration).tenantsTenantIdCardsCardIdPinSetsPost(tenantId, cardId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing card. Updates can include pin sets, pin retry reset, status of the card and rules on the card. Note that the card PIN can only be set if the caller is PCI compliant or a Card Access Password (CAP) is passed. Non PCI compliant tenants backends must never see the PIN
         * @summary Update a card
         * @param {number} tenantId 
         * @param {number} cardId 
         * @param {UpdatedEclipseCard} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsCardIdPut(tenantId: number, cardId: number, body?: UpdatedEclipseCard, options?: any): AxiosPromise<EclipseCard> {
            return ICardManagementApiFp(configuration).tenantsTenantIdCardsCardIdPut(tenantId, cardId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This is a general API for searching for cards based on various criteria
         * @summary Search for cards in the tenant
         * @param {number} tenantId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [packId] 
         * @param {string} [pan] 
         * @param {string} [qrCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdCardsGet(tenantId: number, limit?: number, offset?: number, packId?: string, pan?: string, qrCode?: string, options?: any): AxiosPromise<Array<EclipseCard>> {
            return ICardManagementApiFp(configuration).tenantsTenantIdCardsGet(tenantId, limit, offset, packId, pan, qrCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ICardManagementApi - object-oriented interface
 * @export
 * @class ICardManagementApi
 * @extends {BaseAPI}
 */
export class ICardManagementApi extends BaseAPI {
    /**
     * Used to get a card when the cardId is known. This is the only API that can be used to get the PAN, CVV of a card and in order to do so, the API enforces that it must be called by the cardholder. Either a JWT issued to the customer can be used from the IP address that logged in, or a valid card access password should be passed with no JWT. A card access password can be created by a POST to a customers identities. This API must only be called from the customers device if masked=false is passed.
     * @summary Get a specific card by cardId
     * @param {number} tenantId 
     * @param {number} cardId 
     * @param {string} [cap] The card access password of the customer. Required if masked&#x3D;false and no JWT is provided
     * @param {boolean} [masked] If false then the full PAN and CVV will be returned. An error will be returned if masked&#x3D;false and the caller is not the card owner or a valid CAP is not provided
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ICardManagementApi
     */
    public tenantsTenantIdCardsCardIdGet(tenantId: number, cardId: number, cap?: string, masked?: boolean, options?: any) {
        return ICardManagementApiFp(this.configuration).tenantsTenantIdCardsCardIdGet(tenantId, cardId, cap, masked, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * SMS will SMS the PIN to the customer. If the tenant is PCI compliant then a PIN can be passed and will be set and SMS'd. If not PCI compliant, Eclipse will set the PIN to a new random 4 digit number and deliver the PIN to the customer. LINK is for non PCI compliant tenants to allow their customers to set their own PIN. This will return a URL the customer should open in a browser and enter their CAP and can set their PIN. LINK needs the customer to have a CAP
     * @summary Set and send new card PIN to customer
     * @param {number} tenantId 
     * @param {number} cardId 
     * @param {CardPinDeliveryData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ICardManagementApi
     */
    public tenantsTenantIdCardsCardIdPinSetsPost(tenantId: number, cardId: number, body?: CardPinDeliveryData, options?: any) {
        return ICardManagementApiFp(this.configuration).tenantsTenantIdCardsCardIdPinSetsPost(tenantId, cardId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update an existing card. Updates can include pin sets, pin retry reset, status of the card and rules on the card. Note that the card PIN can only be set if the caller is PCI compliant or a Card Access Password (CAP) is passed. Non PCI compliant tenants backends must never see the PIN
     * @summary Update a card
     * @param {number} tenantId 
     * @param {number} cardId 
     * @param {UpdatedEclipseCard} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ICardManagementApi
     */
    public tenantsTenantIdCardsCardIdPut(tenantId: number, cardId: number, body?: UpdatedEclipseCard, options?: any) {
        return ICardManagementApiFp(this.configuration).tenantsTenantIdCardsCardIdPut(tenantId, cardId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is a general API for searching for cards based on various criteria
     * @summary Search for cards in the tenant
     * @param {number} tenantId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [packId] 
     * @param {string} [pan] 
     * @param {string} [qrCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ICardManagementApi
     */
    public tenantsTenantIdCardsGet(tenantId: number, limit?: number, offset?: number, packId?: string, pan?: string, qrCode?: string, options?: any) {
        return ICardManagementApiFp(this.configuration).tenantsTenantIdCardsGet(tenantId, limit, offset, packId, pan, qrCode, options).then((request) => request(this.axios, this.basePath));
    }
}
