import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { loginToApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import cameraIcon from "../../../assets/img/camera.svg"
import { ReactComponent as PhotoFrame } from "../../../assets/img/face_guide.svg"
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import galleryIcon from "../../../assets/img/gallery.svg"
import { Camera } from "react-camera-pro";

const useStyles = makeStyles(themeStyle);

export function ScanPhoto(props) {
  const history = useHistory();
  const classes = useStyles();
  const userIdentity = useLocation().state?.userIdentity;
  const userOTP = useLocation().state?.userOTP;
  
  const allowedFiles = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"
  ];

  const selectedDocument = useLocation().state?.selectedDoc;
  const idDocument = useLocation().state?.idDocument;
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const selfieDocument = useLocation().state?.selfieDocument;
  const [selfieDocumentImageData, setSelfieDocumentImageData] = useState(useLocation().state?.selfieDocumentImageData);
  const [alertData, setAlert] = useState(null);
  const [developModeEventClick, setDevelopModeEventClick] = useState(0);
  const [showUploadSelfie, setShowUploadSelfie] = useState(false);

  const showAlert = useCallback((alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }, [])

  const webcamRef = React.useRef(null);

  const redirectToSelfieDocumentUpload = useCallback(() => {
    history.push({
      pathname: `/auth/upload-selfie`,
      state: {
        userIdentity,
        userOTP,
        selectedDoc: selectedDocument,
        idDocument,
        selfieDocument,
        idDocumentImageData,
        selfieDocumentImageData
      },
    });
  }, [history, userOTP, userIdentity, selectedDocument, idDocument, selfieDocument, idDocumentImageData, selfieDocumentImageData]);

  const capture = useCallback(() => {
    setSelfieDocumentImageData(webcamRef.current.takePhoto());
    if (selfieDocumentImageData) {
      redirectToSelfieDocumentUpload();
    }
  }, [webcamRef, redirectToSelfieDocumentUpload, selfieDocumentImageData]);

  const checkFileExtension = useCallback((fileType) => {
    let allowed = false;
    allowedFiles.forEach(extension => {
      if (extension === fileType) {
        allowed = true;
      }
    });
    return allowed;
  }, [allowedFiles])

  const readUrl = useCallback((event) => {
    const reader = new FileReader();
    if (!event?.target?.files[0].type) {
      showAlert(true, 'ERROR', `Error while upload image. Please try again.`);
      return;
    }
    const fileType = event.target.files[0].type;
    if (checkFileExtension(fileType)) {
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          setSelfieDocumentImageData(reader.result.toString());
          if (selfieDocumentImageData) {
            redirectToSelfieDocumentUpload();
          }
        }
      } else {
        showAlert(true, 'ERROR', `Something went wrong. Please try again.`);
      }
    } else {
      showAlert(true, 'ERROR', `Error while reading image type. Please try again.`);
    }
  }, [redirectToSelfieDocumentUpload, showAlert, checkFileExtension, selfieDocumentImageData]);

  React.useEffect(() => {
    if (selfieDocumentImageData) {
      redirectToSelfieDocumentUpload();
    }
  }, [redirectToSelfieDocumentUpload, selfieDocumentImageData]);

  const goBack = useCallback(() => {
    history.push({
      pathname: '/auth/scan-document',
      state: {
        userIdentity,
        userOTP,
        selectedDoc: selectedDocument,
        idDocument,
        selfieDocument,
        idDocumentImageData,
        selfieDocumentImageData
      },
    });
  }, [history, userIdentity, userOTP, selectedDocument, selfieDocumentImageData, idDocumentImageData, selfieDocument, idDocument]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        goBack();
      }
    };
  }, [history, goBack]);

  const developMode = useCallback(() => {
    if (window.REACT_APP_ENV !== 'prod') {
      if (developModeEventClick === 15) {
        setDevelopModeEventClick(0);
        setShowUploadSelfie(true);
        return;
      }
      setDevelopModeEventClick(developModeEventClick + 1)
    }
  }, [developModeEventClick]);

  return (
    <div>
      {!selfieDocumentImageData ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ margin: '60px 0 30px 0' }}>
                <div onClick={developMode} style={{ position: 'relative', height: '320px', width: '320px', margin: "auto", maxWidth: '100%' }}
                     className="selfie-area">
                  <PhotoFrame fill={primaryIconColor} stroke={primaryIconColor} style={{
                    position: 'absolute',
                    zIndex: '1',
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}/>
                  <Camera
                    ref={webcamRef}
                    aspectRatio={1}/>
                </div>
              </GridItem>
            </GridContainer>
            {!showUploadSelfie ?
              <GridContainer>
                <GridItem xs={12} m={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button className={classes.buttonRadius + ' roundButton'}
                          style={{ display: 'flex', alignSelf: 'center' }} size="lg" color="primary"
                          onClick={capture}><img src={cameraIcon} alt=""/></Button>
                </GridItem>
              </GridContainer>
            :
              <GridContainer>
                <GridItem xs={6} m={6} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="file-upload" className={classes.buttonRadius + ' roundButton'} style={{
                    display: 'flex',
                    alignSelf: 'center',
                    padding: '1.125rem 2.25rem',
                    background: primaryColor,
                    margin: '0.5em'
                  }}>
                    <img style={{ height: '30px', width: '30px' }} src={galleryIcon} alt=""/>
                  </label>
                  <input style={{ display: 'none' }} id="file-upload" type="file" onChange={readUrl} size="1"
                        accept=".png,.bmp,.jpeg,.jpg"/>
                </GridItem>
                <GridItem xs={6} m={6} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button className={classes.buttonRadius + ' roundButton'}
                          style={{ display: 'flex', alignSelf: 'center' }} size="lg" color="primary"
                          onClick={capture}><img src={cameraIcon} alt=""/></Button>
                </GridItem>
              </GridContainer>
            }
          </GridItem>
        </GridContainer>
        : null}
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  scanPhotoHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScanPhoto)
