import React, { useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { ReactComponent as SetPasswordIcon } from '../../assets/img/icon_enter_password.svg'
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from 'react-element-spinner';
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";
import { ICardManagementApi } from "../../eclipse-react-sdk/services";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ConfirmNewPin(props) {
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = 4;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const confirmPasswordPINControlName = `confirm${passwordPINText}`;
  const passwordPINInputControlGridLength = 12 / inputControls;
  const newPasswordPIN = useLocation().state?.newPasswordPIN;
  const cardDetails = useLocation().state?.cardDetails;
  const successScreen = useLocation().state?.successScreen
  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(confirmPasswordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const backToCreateNewPassword = () => {
    history.push({
      pathname: `/pages/new-physical-card-pin`,
    });
  }

  const successPassword = () => {
    history.push({
      pathname: `/pages/${successScreen}`
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateConfirmNewPasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateConfirmNewPasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateConfirmNewPasswordForm();
    setErrors(validationErrors);
  }

  const validateConfirmNewPasswordForm = () => {
    //Email errors
    let errors = ValidateInputControls(inputs, confirmPasswordPINControlName, inputControls, `Confirm ${passwordPINText}`, pinPasswordLength);
    if (errors[confirmPasswordPINControlName]) {
      return errors;
    }

    if (!errors[confirmPasswordPINControlName] && newPasswordPIN !== ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)) {
      errors[confirmPasswordPINControlName] = `New ${passwordPINText} and Confirm ${passwordPINText} are not matching!`;
      return errors;
    }
    return errors
  }


  const handleConfirmNewPasswordFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateConfirmNewPasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const requestBody = { ...cardDetails }
      requestBody.pin = newPasswordPIN;
      const cardManger = new ICardManagementApi();
      cardManger.tenantsTenantIdCardsCardIdPut(props?.appData?.auth?.tenantId, cardDetails.cardId, { pin: newPasswordPIN }).then((result) => {
        setLoading(false);
        console.log('result: ' + JSON.stringify(result));
        successPassword();
      }).catch(() => {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      });
    }
  }


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToCreateNewPassword}/>
                <span className={classes.title}>Add a physical card</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + ' m-t-0 m-b-0'} style={{ fontSize: '20px' }}><b>Confirm your PIN</b>
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <SetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor}/>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={handleConfirmNewPasswordFormSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                      <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '0px' }}><strong>Confirm
                        your 4-digit PIN.</strong></p>
                    </GridItem>
                    {ReturnInputControlsMap(confirmPasswordPINControlName, inputControls)?.map((control, index, array) => {
                      return (
                        <GridItem key={control} xs={passwordPINInputControlGridLength}
                                  sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength}
                                  custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                          <ValidationTextField
                            inputProps={{
                              maxLength: inputControls > 1 ? 1 : 50,
                              inputMode: pinAsPassword ? 'numeric' : ''
                            }}
                            autoComplete="new-password"
                            className={inputControls > 1 ? passwordClasses.oneDigitPassword : ''}
                            label={inputControls > 1 ? '' : 'Confirm your New ' + passwordPINText}
                            fullWidth
                            variant="filled"
                            id={control}
                            type="password"
                            error={!!(errors[confirmPasswordPINControlName])}
                            name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                            value={inputs[control] || ""}
                            onChange={handleInputChange}
                            onKeyDown={handleOnKeyDown}
                            onKeyUp={handleInputKeyUp}/>
                        </GridItem>
                      )
                    })
                    }
                    <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                      {errors[confirmPasswordPINControlName] &&
                      <Danger><small>{errors[confirmPasswordPINControlName]}</small></Danger>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                              disabled={!!(errors[confirmPasswordPINControlName])}>Next</Button>
                      <p className={classes.regularFontSize}>Can't remember your New {passwordPINText}? <span
                        className={classes.linkColor} onClick={backToCreateNewPassword}>Go back</span></p>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ConfirmNewPin)