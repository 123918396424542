const CreateNewInputControls = (controlName, inputControls) => {
    const mapData = new Map();
    for (let index = 0; index < inputControls; index++) {
        mapData.set(`${controlName}${index + 1}`, '');
    }
    return Object.fromEntries(mapData);
};

const ReturnInputControlsMap = (controlName, inputControls) => {
    var result = [];
    for (var i in CreateNewInputControls(controlName, inputControls)) {
        result.push(i);
    }
    return result;
};

const ValidateInputControls = (inputs, controlName, inputControls, controlText, controlLength) => {
    const errors = {};
    const passwordControls = ReturnInputControlsMap(controlName, inputControls);

    passwordControls.forEach((control) => {
        if (inputControls > 1) {
            if (!inputs[control]) {
                errors[controlName] = `${controlText} required!`
                return errors;
            } else {
                if (!/[0-9]{1,}/i.test(inputs[control])) {
                    errors[controlName] = `Invalid ${controlText}!`
                    return errors;
                }
            }
        } else {
            if (!inputs[control]) {
                errors[controlName] = `${controlText} required!`
                return errors;
            } else if (inputs[control] && inputs[control].trim().length < controlLength) {
                errors[controlName] = `Invalid ${controlText}!`;
                return errors;
            }
        }
    });
    return errors;
};

const ReturnInputControlsConcatValues = (inputs, controlName, inputControls) => {
    let concatValue = ''
    ReturnInputControlsMap(controlName, inputControls).forEach((control) => {
        concatValue = `${concatValue}${inputs[control]}`;
    });
    return concatValue;
};

const ResetInputControlsValues = (inputs, controlName,controlLength) => {
    range(controlLength).reverse().forEach(i=>{
         ReturnKeyDownControlValue(inputs,controlName+"-"+i,'',controlLength)
    })
 };

function range(size) {
    return [...Array(size).keys()].map(i => i + 1);
}

const ReturnChangedControlValue = (inputs, controlName, controlValue, inputControls) => {
    const fieldIndex = controlName.split("-")[1];
    const fieldName = controlName.split("-")[0];

    // Check if they hit the max character length
    if (inputControls > 1 && controlValue.length >= 1 && fieldIndex) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
                `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`
            );
            // If found, focus the next field
            if (nextSibling !== null) {
                nextSibling.focus();
            }
        }
    }
    inputs[`${fieldName}${fieldIndex ? fieldIndex : ''}`] = inputControls > 1 && fieldName === controlName && fieldIndex ? controlValue.slice(0, 1) : controlValue;
    return inputs;
};

const FocusInPrevControl = (controlName, inputControls) => {
    const fieldIndex = controlName.split("-")[1];
    const fieldName = controlName.split("-")[0];
    if (inputControls > 1 && fieldIndex) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) <= 6 && parseInt(fieldIndex, 10) > 0) {
            // Get the next input field
            const previousSibling = document.querySelector(
                `input[name=${fieldName}-${parseInt(fieldIndex, 10) - 1}]`
            );
            // If found, focus the previous field
            if (previousSibling !== null) {
                previousSibling.focus();
            }
        }
    }

}

const FocusInNextControl = (controlName, inputControls) => {
    const fieldIndex = controlName.split("-")[1];
    const fieldName = controlName.split("-")[0];
    if (inputControls > 0 && fieldIndex) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) <= 6 && parseInt(fieldIndex, 10) > 0) {
            // Get the next input field
            const previousSibling = document.querySelector(
                `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`
            );
            // If found, focus the previous field
            if (previousSibling !== null) {
                previousSibling.focus();
            }
        }
    }

}

const ReturnKeyDownControlValue = (inputs, controlName, controlValue, inputControls) => {
    const fieldIndex = controlName.split("-")[1];
    const fieldName = controlName.split("-")[0];

    // Check if they hit the max character length
    if (inputControls > 1 && fieldIndex) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) <= 6 && parseInt(fieldIndex, 10) > 0) {
            // Get the next input field
            const previousSibling = document.querySelector(
                `input[name=${fieldName}-${parseInt(fieldIndex, 10) - 1}]`
            );
            // If found, focus the previous field
            if (previousSibling !== null) {
                previousSibling.focus();
            }
        }
    }
    inputs[`${fieldName}${fieldIndex ? fieldIndex : ''}`] = '';
    return inputs;
};

export {
    CreateNewInputControls,
    ReturnInputControlsMap,
    ValidateInputControls,
    ReturnInputControlsConcatValues,
    ResetInputControlsValues,
    ReturnChangedControlValue,
    ReturnKeyDownControlValue,
    FocusInPrevControl,
    FocusInNextControl
};