/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * The permission level of the Tenant Admin User. Determines permissions the user has to access the tenant data if they log in. The permissions for each level are configured by the admin team and are provided out of band. TENANT_SYSTEM:Special level for the tenants system identity and has full access, LEVEL_XX:Increasing level of permissions from 01 to 10
 * @export
 * @enum {string}
 */
export enum EclipseTenantPosition {
    LEVEL01 = 'LEVEL_01',
    LEVEL02 = 'LEVEL_02',
    LEVEL03 = 'LEVEL_03',
    LEVEL04 = 'LEVEL_04',
    LEVEL05 = 'LEVEL_05',
    LEVEL06 = 'LEVEL_06',
    LEVEL07 = 'LEVEL_07',
    LEVEL08 = 'LEVEL_08',
    LEVEL09 = 'LEVEL_09',
    LEVEL10 = 'LEVEL_10',
    ONBOARDING = 'ONBOARDING',
    TENANTSYSTEM = 'TENANT_SYSTEM'
}

