import React from "react";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ErrorOutline } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { AuthRouteWithTemplateId } from "../../default-route.js";
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function GenericError(props) {
  const history = useHistory();
  const classes = useStyles();
  const appData = useLocation().state;
  const backToScreen = () => {
    const redirectToAuth = appData?.fromScreen.search('auth');
    if (appData?.fromScreen && redirectToAuth > 0) {
      const redirectToScreen = appData?.fromScreen.replace('/auth/', '');
      history.push(`${AuthRouteWithTemplateId(redirectToScreen)}`);
    } else {
      props.appData.isRefresh = true;
      props.genericErrorHandler({ ...props?.appData });
      history.push(appData?.fromScreen ? appData?.fromScreen : '/');
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <ErrorOutline className={classes.screenCenterDangerIcon} />
              <p className={classes.title + ' ' + classes.textCenter}><b>Oh No!</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}>Something went wrong.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToScreen}>Please try again</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  genericErrorHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GenericError)