import React, { useCallback } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss"
import {ReactComponent as SuccessResetPassword} from '../../assets/img/icon_reset_passcode_sucess.svg'
import { AuthRouteWithTemplateId } from "../../default-route.js";
import { ReturnStoredTemplateId } from "../../get-template-hostname";
import { EnvConfig } from "../../environment";
import { logoutFromApp } from "../../redux-services/actions/actions";


const useStyles = makeStyles(themeStyle);

export function SuccessPassword(props) {
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;

  const history = useHistory();
  const classes = useStyles();

  const logout = useCallback(() => {
    let templateId = props?.appData?.templateId ? props?.appData?.templateId : ReturnStoredTemplateId();
    if (!templateId) {
      templateId = 'default';
    }
    const appData = {
      templateId: templateId
    }
    EnvConfig().BASE_API_URL = props?.appData?.template?.defaultBaseURL;
    props.logoutFromAppHandler({ ...appData });
    history.push(`${AuthRouteWithTemplateId('login')}`);
  },[props,history]);

  React.useEffect(() => {
      window.onpopstate = () => {
        history.push(`${AuthRouteWithTemplateId('login')}`);
      }
  }, [history])

  return (
    <div>
      <div className="wallet-list">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{textAlign: 'center', margin: '60px 0 0px'}}>
                <SuccessResetPassword fill={primaryIconColor} stroke={primaryIconColor}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'} style={{marginBottom: '30px', fontSize: '20px'}}><b>Your new {passwordPINText} was set successfully.</b></p>
            </GridItem>
            </GridContainer>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={logout}>Done</Button>
              </GridItem>
            </GridContainer>
            </GridItem>
        </GridContainer>
      </div>
      
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPassword)