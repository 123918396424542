import React, { useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ReactComponent as AirtimeDataIcon } from "../../../assets/img/airtime_data.svg";
import { ReactComponent as ElectricityIcon } from "../../../assets/img/electricity.svg";

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function VASBuyAirtimeDataElectricity(props) {
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.vasBuyAirtimeDataElectricityHandler(appData);
    history.push({
      pathname: '/pages/dashboard'
    });
  }, [history, props])

  const redirectToBuyAirtimeElectricityData = (vasType) => {
    history.push({
      pathname: '/pages/vas-enter-mobile',
      state: { vasType: vasType, fromScreen: 'vas-airtime-data-electricity' }
    });
  }

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome} />
                <span className={classes.title}>Buy Airtime, Data or Electricity</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.textCenter + ' m-b-0'}><b>What would you like to buy?</b></h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: '0' }}>
            <ButtonBase onClick={() => {
              redirectToBuyAirtimeElectricityData('BUYAIRTIMEDATA')
            }} style={{ justifyContent: 'flex-start' }}>
              <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                  <AirtimeDataIcon />&nbsp;&nbsp;<b>Airtime or Data</b></h5>
              </CardContent>
            </ButtonBase>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: '0' }}>
            <ButtonBase onClick={() => {
              redirectToBuyAirtimeElectricityData('BUYELECTRICITY')
            }} style={{ justifyContent: 'flex-start' }}>
              <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                  <ElectricityIcon />&nbsp;&nbsp;<b>Electricity</b></h5>
              </CardContent>
            </ButtonBase>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasBuyAirtimeDataElectricityHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASBuyAirtimeDataElectricity)