import React, { useCallback, useEffect, useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss";
import { ReactComponent as CancelCard } from "../../assets/img/cancel_card.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EclipseStatusReason } from "eclipse-react-sdk/services/models";

const useStyles = makeStyles(themeStyle);

export function CancelCardReason(props) {
  const appName = props?.appData?.template?.manifestSettings?.appName;
  const history = useHistory();
  const pageState = useLocation().state;
  const walletDetailData = pageState?.walletDetailData;
  const classes = useStyles();
  const [inputs, setInputs] = useState({ reasonType: EclipseStatusReason?.FRAUD });

  const handleInputChange = (event) => {
    setInputs({ reasonType: event.target.value });
  }

  const goToConfirmCancelCard = () => {
    if(walletDetailData?.availableBalance > 0 && appName === 'Vleissentraal' ){
      const eftData = { eftWallet: walletDetailData?.walletId, amount: walletDetailData?.availableBalance}
      history.push({
        pathname: '/pages/eft-bank',
        state: { ...pageState, ...inputs, appName, walletDetailData, eftData, fromScreen:"cancel-card"}
      });
    }else {
      history.push({
        pathname: '/pages/confirm-cancel-card',
        state: { ...pageState, ...inputs }
      });
    }
  };

  const backToWalletDetail = useCallback(() => {
    history.push({
      pathname: '/pages/wallets-detail',
      state: pageState
    });
  }, [history, pageState])

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      backToWalletDetail();
    };
  }, [history, backToWalletDetail])

  const handleSubmit = (event) => {
    event.preventDefault();
    goToConfirmCancelCard();
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletDetail} />
              <span className={classes.title}>Cancel my card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <h4 style={{ margin: 0 }}>
                  <b className={classes.regularFontSize}>
                    You are about to cancel your card
                  </b>
                </h4>
                <p>
                  If your card has money on it you will have a choice to transfer it to a new card or your wallet.
                </p>
                <p>
                  If there was fraud on your card, our agents will contact you soon to report it.
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <CancelCard style={{ marginTop: 0 }} className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                  stroke={primaryIconColor} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4 style={{ margin: 0 }}>
                  <b className={classes.regularFontSize}>
                    Why would you like to cancel your card?
                  </b>
                </h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <RadioGroup name="reasonType" id="reasonType" value={inputs.reasonType} onChange={handleInputChange}>
                  <FormControlLabel value={EclipseStatusReason?.FRAUD} control={<Radio />} label="Suspected fraudulent activity" />
                  <FormControlLabel value={EclipseStatusReason?.LOST} control={<Radio />} label="Lost" />
                  <FormControlLabel value={EclipseStatusReason?.STOLEN} control={<Radio />} label="Stolen" />
                  <FormControlLabel value={EclipseStatusReason?.DAMAGED} control={<Radio />} label="Damaged card" />
                  <FormControlLabel value="NO_LONGER" control={<Radio />} label="I no longer need it" />
                </RadioGroup>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit">Cancel my card</Button>
                <p className={classes.textCenter}><span className={classes.linkColor} onClick={backToWalletDetail}>Cancel</span></p>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(CancelCardReason)