import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import 'react-multi-carousel/lib/styles.css';
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import {primaryColor} from "../../style.scss";
import {makeStyles} from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import {loginToApp} from "../../redux-services/actions/actions.js";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles(themeStyle);


export function AddPhysicalCard(props) {
  const ratifySupportContact = props?.appData?.template?.ratifySupportContact;
  const history = useHistory();
  const classes = useStyles();
  const goToRegisterCard = (event) => {
    event.preventDefault();
    history.push({
      pathname: '/pages/register-my-physical-card',
    });
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.physicalCardHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history,backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
          <span className={classes.title}>Add a physical card</span>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{paddingTop: '30px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{padding: '30px 80px'}}>
              <Link href="#"
                    className="add-card-placeholder"
                    style={{
                      width: '100%',
                      minHeight: '60vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                    onClick={goToRegisterCard}>

                <p>
                  <b>You haven't added a <br/>physical card yet.</b>
                </p>
                <p style={{
                  color: primaryColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <AddCircleOutlineOutlined style={{display: 'flex', alignSelf: 'center'}}/>
                  &nbsp;&nbsp;
                  <b style={{display: 'flex', alignSelf: 'center'}}>Add a physical card</b>
                </p>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                <b>Need a card?</b>
              </p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}>
                Contact support on {ratifySupportContact}<br/>to order your card.
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})
const mapDispatchToProps = dispatch => ({
  physicalCardHandler: data => dispatch(loginToApp(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(AddPhysicalCard)