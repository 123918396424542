import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { ReactComponent as AccountReady } from "../../assets/img/account_ready.svg"
import { primaryIconColor } from "../../style.scss";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(themeStyle);

export function AssistedSignupSuccess(props) {
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.ratifyKYCSuccessHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [props, history])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <AccountReady className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                            stroke={primaryIconColor}/>
              <h1 className='auth-title' style={{ margin: '50px 0 0 0' }}>You've successfully signed up a customer!</h1>
              <p className={classes.regularFontSize}><b>Would you like to sign up another customer?</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={() => {
                history.push('/pages/assisted-signup');
              }} type="button">Sign Up another customer</Button>
              <p className={classes.textCenter}><Link onClick={() => {
                history.push('/pages/dashboard');
              }} className={classes.linkColor}>Not now, take me to my home page</Link></p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  ratifyKYCSuccessHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedSignupSuccess)