import React, { useCallback, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { controlLabelColor, primaryColor } from "../../../style.scss";
import Danger from "../../../components/Typography/Danger";
import { loginToApp, logoutFromApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import { DCustomerManagementApi } from "eclipse-react-sdk/services/apis/dcustomer-management-api";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { EclipseTitle, RatifyType } from "eclipse-react-sdk/services/models";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { MenuItem } from "@material-ui/core";
import HttpRequestConfiguration from "../../../http-request-configuration.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function PersonalInformation(props) {
  const assistedCustomer = { ...props.appData.assistedCustomer };
  const ratifyRules = props?.appData?.template?.ratifyRules;
  const addressOnRegister = props?.appData?.template?.pwaAppOptions?.addressOnRegister;
  const selectedDocument = useLocation().state?.selectedDoc;
  const document = useLocation().state?.document;
  const idDocument = useLocation().state?.idDocument;
  const idDocumentBack = useLocation().state?.idDocumentBack || null;
  const idDocumentImageData = useLocation().state?.idDocumentImageData || null;
  const idDocumentBackImageData = useLocation().state?.idDocumentBackImageData;
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  let ratifyKeys = ratifyRules[document?.type];
  const isExpiryDateEnable = selectedDocument === '1' || selectedDocument === '3';
  const classes = useStyles();
  const history = useHistory();
  const currentDate = new Date().toISOString().split('T')[0];
  const [inputs, setInputs] = useState({
    title: '',
    firstname: '',
    surname: '',
    identityNumber: '',
    expiryDate: new Date().toISOString().split('T')[0]
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);
  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const backToHome = useCallback(() => {
    history.push({
      pathname: `/pages/selfie-initial`,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData
      },
    });
  }, [history, isAddressAdded, fromScreen, selectedDocument, document, idDocument, idDocumentBack, idDocumentImageData, idDocumentBackImageData, selfieDocumentImageData]);

  React.useEffect(() => {
    return()=>{
      window.onpopstate = () => {
        backToHome();
      }
    };
  }, [backToHome])

  const goToAddAddress = () => {
    history.push({
      pathname: `/pages/permanent-address`,
      state: { fromScreen }
    });
  }
  const goToSuccessRatifyKYC = () => {
    history.push({
      pathname: (fromScreen === 'ASSISTED_SIGNUP' ? `/pages/assisted-signup-account-ready` : `/pages/ratify-kyc-success`),
    });
  }

  const goToFailedRatifyKYC = (ratifyProcessData) => {
    history.push({
      pathname: `/pages/ratify-kyc-error`,
      state: { selectedDoc: selectedDocument, ratifyProcessData, fromScreen }
    });
  }

  const doRatifyProcess = () => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration((fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const doRatifyProcessAPI = new DCustomerManagementApi(httpRequestConfiguration);
    const requestBody = {
      type: RatifyType.NORMAL
    }
    doRatifyProcessAPI.tenantsTenantIdCustomersCustomerIdRatifyPost(props?.appData?.auth?.tenantId, (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.customerId : props?.appData?.customer?.customerId), requestBody).then((doRatifyProcessAPIResponse) => {
      let isRatifyFailed = 0;
      let ratifiedFailedResponse = {};
      ratifyKeys.forEach(ratifyKey => {
        ratifiedFailedResponse[ratifyKey] = doRatifyProcessAPIResponse.data[ratifyKey]['passed'];
        if (!doRatifyProcessAPIResponse.data[ratifyKey]['passed']) {
          isRatifyFailed = isRatifyFailed + 1;
        }
      });
      props.appData.template.ratifyTrialCount = (fromScreen === 'ASSISTED_SIGNUP' ? 0 : props?.appData?.template?.ratifyTrialCount + 1);
      const appData = {
        ...props?.appData
      }
      props.personalInformationHandler(appData);
      setLoading(false);
      if (isRatifyFailed > 0) {
        goToFailedRatifyKYC(ratifiedFailedResponse)
      } else {
        if (addressOnRegister && !isAddressAdded) {
          goToAddAddress();
        } else {
          goToSuccessRatifyKYC();
        }
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        if(fromScreen === 'ASSISTED_SIGNUP'){
          redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
        } else {
          redirectToErrorScreen('/pages/identity-document', '/pages/error');
        }
      }
    });
  }

  const handlePersonalInformationFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validatePersonalInformationForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const httpRequestConfiguration = HttpRequestConfiguration((fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.assistedUserToken : null));
      const updateCustomerDetailAPI = new DCustomerManagementApi(httpRequestConfiguration);
      const requestBody = {
        birthCity: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.birthCity : props?.appData?.customer?.birthCity),
        birthCountry: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.birthCountry : props?.appData?.customer?.birthCountry),
        created: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.created : props?.appData?.customer?.created),
        customerId: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.customerId : props?.appData?.customer?.customerId),
        dateOfBirth: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.dateOfBirth : props?.appData?.customer?.dateOfBirth),
        email: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.email : props?.appData?.customer?.email),
        externalUniqueId: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.externalUniqueId : props?.appData?.customer?.externalUniqueId),
        firstName: inputs?.firstname,
        gender: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.gender : props?.appData?.customer?.gender),
        lastModified: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.lastModified : props?.appData?.customer?.lastModified),
        lastName: inputs?.surname,
        locale: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.locale : props?.appData?.customer?.locale),
        maidenName: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.maidenName : props?.appData?.customer?.maidenName),
        maritalStatus: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.maritalStatus : props?.appData?.customer?.maritalStatus),
        middleName: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.middleName : props?.appData?.customer?.middleName),
        nationalIdentityNumber: isExpiryDateEnable ? null : inputs?.identityNumber,
        other: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.other : props?.appData?.customer?.other),
        passportExpiry: isExpiryDateEnable ? inputs?.expiryDate.replace(/-/g, '') : (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.passportExpiry : props?.appData?.customer?.passportExpiry),
        passportNumber: isExpiryDateEnable ? inputs?.identityNumber : null,
        passportPlaceOfIssuance: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.passportPlaceOfIssuance : props?.appData?.customer?.passportPlaceOfIssuance),
        phone1: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.phone1 : props?.appData?.customer?.phone1),
        phone2: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.phone2 : props?.appData?.customer?.phone2),
        status: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.status : props?.appData?.customer?.status),
        title: inputs?.title,
        version: (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.version : props?.appData?.customer?.version)
      }
      updateCustomerDetailAPI.tenantsTenantIdCustomersCustomerIdPut(props?.appData?.auth?.tenantId, (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.customerId : props?.appData?.customer?.customerId), requestBody).then((updateCustomerDetailAPIResponse) => {
        if (fromScreen === 'ASSISTED_SIGNUP') {
          props.appData.assistedCustomer = { ...assistedCustomer, ...updateCustomerDetailAPIResponse.data };
        } else {
          props.appData.customer = updateCustomerDetailAPIResponse.data;
        }
        const appData = {
          ...props?.appData
        }
        props.personalInformationHandler({ ...appData });
        setLoading(false);
        doRatifyProcess();
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          if(fromScreen === 'ASSISTED_SIGNUP'){
            redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
          } else {
            redirectToErrorScreen('/pages/identity-document', '/pages/error');
          }
        }
      });
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validatePersonalInformationForm(inputs);
    setErrors(validationErrors);
  }


  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validatePersonalInformationForm(inputs);
    setErrors(validationErrors);
  }

  const validatePersonalInformationForm = (inputs) => {
    const errors = {}

    if (!inputs.title) {
      errors.title = 'Title is required!'
    }

    if (!inputs.firstname) {
      errors.firstname = 'First name required!'
    } else if (!/^[A-Za-z0-9]+[ a-zA-Z0-9-]{1,}$/i.test(inputs.firstname)) {
      errors.firstname = 'First name is invalid!'
    }

    if (!inputs.surname) {
      errors.surname = 'Surname required!'
    } else if (!/^[a-zA-Z0-9][ a-zA-Z0-9-]{2,}$/i.test(inputs.surname)) {
      errors.surname = 'Surname is invalid!'
    }

    if (!inputs.identityNumber) {
      errors.identityNumber = 'Identity number required!'
    } else if (!/^[a-zA-Z0-9]{3,}$/i.test(inputs.identityNumber)) {
      errors.identityNumber = 'Identity number is invalid!'
    }

    if (!inputs.expiryDate) {
      errors.expiryDate = 'Expiry date required!'
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p onClick={() => {
                backToHome()
              }} style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                <ArrowBackIosIcon/>
                <span>{"Back"}</span>
              </p>
              <small style={{
                color: controlLabelColor,
                marginLeft: "auto"
              }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 5 : 4} of 5</small>
            </div>
            <strong><small
              style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '100%' } : { width: '80%' }}>&nbsp;</small></strong>
          </div>
          <form onSubmit={handlePersonalInformationFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1 className='auth-title'
                    dangerouslySetInnerHTML={{ __html: fromScreen === 'ASSISTED_SIGNUP' ? 'Almost done with <br />verification!' : 'Almost done with <br />your verification!' }}/>
                <p className={classes.regularFontSize}>
                  <strong>{fromScreen === 'ASSISTED_SIGNUP' ? "Please complete the customer's personal information." : "Please complete the following personal information."}</strong>
                </p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={fromScreen === 'ASSISTED_SIGNUP' ? "Title" : "Your title"}
                  select
                  variant="filled"
                  id="title"
                  type="number"
                  error={!!(errors.title)}
                  name="title"
                  value={inputs.title}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}>
                  {
                    Object.keys(EclipseTitle).map((title) => {
                      return (<MenuItem key={title}
                                        value={title}>{title}</MenuItem>)
                    })
                  }
                </ValidationTextField>
                {errors.title && <Danger><small>{errors.title}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={fromScreen === 'ASSISTED_SIGNUP' ? "Full name(s) as on their ID" : "Your first name(s) as on your ID"}
                  variant="filled"
                  id="firstname"
                  type="text"
                  error={!!(errors.firstname)}
                  name="firstname"
                  value={inputs.firstname}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.firstname && <Danger><small>{errors.firstname}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={fromScreen === 'ASSISTED_SIGNUP' ? "Surname as on their ID" : "Your surname as on your ID"}
                  variant="filled"
                  id="surname"
                  type="text"
                  error={!!(errors.surname)}
                  name="surname"
                  value={inputs.surname}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.surname && <Danger><small>{errors.surname}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={fromScreen === 'ASSISTED_SIGNUP' ? "Identity number" : "Your identity number"}
                  variant="filled"
                  id="identityNumber"
                  type="text"
                  error={!!(errors.identityNumber)}
                  name="identityNumber"
                  value={inputs.identityNumber}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.identityNumber && <Danger><small>{errors.identityNumber}</small></Danger>}
              </GridItem>
              {isExpiryDateEnable ?
                <GridItem xs={12} sm={12} md={12}>
                  <ValidationTextField
                    inputProps={{ min: currentDate }}
                    fullWidth
                    label="The expiry date of the document"
                    variant="filled"
                    id="expiryDate"
                    type="date"
                    error={!!(errors.expiryDate)}
                    name="expiryDate"
                    defaultValue={inputs.expiryDate}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp}/>
                  {errors.expiryDate && <Danger><small>{errors.expiryDate}</small></Danger>}
                </GridItem>
                : null}
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                        disabled={!!(errors.firstname || errors.surname || errors.identityNumber || errors.expiryDate)}>{fromScreen === 'ASSISTED_SIGNUP' ? 'Next' : 'Done'}</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  personalInformationHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation)
