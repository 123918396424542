export default function ReturnMobileNumberWithISD(whatsAppDefaultISDCode, identity) {

    if (identity.startsWith(`+${whatsAppDefaultISDCode}`)) {
        return identity.replace(`+${whatsAppDefaultISDCode}`, whatsAppDefaultISDCode);
    }
    if (identity.startsWith('0')) {
        return identity.replace('0', whatsAppDefaultISDCode);
    }
    if (identity.startsWith(`${whatsAppDefaultISDCode}`)) {
        return identity;
    }
    return `${whatsAppDefaultISDCode}${identity}`;
}