import React, { useCallback, useEffect, useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../components/Typography/Danger.js";
import { primaryIconColor } from "../../style.scss";
import getSymbolFromCurrency from "currency-symbol-map";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function EFTBank(props) {
  const pageState = useLocation().state;
  const bankList = (props?.appData?.template?.bankList && props?.appData?.template?.bankList.length > 0) ? props?.appData?.template?.bankList : [];
  const eftData = pageState?.eftData;
  const appName = pageState?.appName;
  const walletDetailData = pageState?.walletDetailData;
  const fromScreen = pageState?.fromScreen;

  const history = useHistory();
  const classes = useStyles();

  const [inputs, setInputs] = useState({ accountName: '', bankCode: '', accountNo: '', reference: '' });
  const [errors, setErrors] = useState({});

  const validateEFTBankForm = (inputs) => {
    const errors = {}
    if (!inputs.accountName) {
      errors.accountName = 'Account name required!'
    } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{3,}$/i.test(inputs.accountName)) {
      errors.accountName = 'Invalid account name!'
    }

    if (!inputs.bankCode) {
      errors.bankCode = 'Bank required!'
    } else if (!/^[0-9]{3,}$/i.test(inputs.bankCode)) {
      errors.bankCode = 'Invalid bank!'
    }

    if (!inputs.accountNo) {
      errors.accountNo = 'Account number required!'
    } else if (inputs.accountNo.length <10 || inputs.accountNo.length > 20 ) {
      errors.accountNo = 'Invalid account number! Its length should be minimum 10 and maximum 20.'
    }

    return errors
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateEFTBankForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateEFTBankForm(inputs);
    setErrors(validationErrors);
  }

  const backToEFTOut = useCallback(() => {
    if (appName === 'Vleissentraal' && fromScreen === "cancel-card") {
      history.push({
        pathname: `/pages/cancel-card`,
        state: { walletDetailData, fromScreen }
      });
    } else {
      history.push({
        pathname: `/pages/eft-out`,
        state: {
          walletDetailData: (fromScreen && fromScreen === 'wallets-detail') ? walletDetailData : null,
          fromScreen: (fromScreen && fromScreen === 'wallets-detail') ? fromScreen : 'transact'
        }
      });
    }
  },[history,walletDetailData, fromScreen, appName])

  const redirectToEFTConfirm = (bankData) => {
    const bankDetailData = bankList.filter(bank => bank.code === bankData?.bankCode)[0];
    history.push({
      pathname: (appName === 'Vleissentraal' && fromScreen === "cancel-card")?'/pages/confirm-cancel-card':'/pages/eft-confirm',
      state: {
        ...pageState, bankData, bankDetailData,
        fromScreen: (fromScreen && (fromScreen === 'wallets-detail' || fromScreen === 'cancel-card')) ? fromScreen : 'transact'
      }
    });
  }

  const handleEFTBankFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateEFTBankForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      redirectToEFTConfirm(inputs);
    }
  }

  useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      backToEFTOut();
    };
  }, [history, backToEFTOut])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToEFTOut}/>
              <span
                className={classes.title}>{fromScreen === "cancel-card" ? "Cancel my card" : "EFT bank details"}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {
            (appName === 'Vleissentraal' && fromScreen === "cancel-card") ?
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + ' m-b-0'}><b>Transfer your card balance of</b></p>
                <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}>
                  <b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+eftData?.amount).toFixed(2)}</b></h2>
                <p className={classes.textCenter + ' m-t-0'} style={{ marginBottom: '50px' }}><b>to your bank
                  account</b></p>
                <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>Enter your bank account
                  details</b></p>
              </GridItem> :
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>Enter the recipient's bank
                  details?</b></p>
              </GridItem>
          }
          <form onSubmit={handleEFTBankFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                inputProps={{ maxLength: 50 }}
                fullWidth
                label="Account name"
                variant="filled"
                id="accountName"
                type="text"
                error={!!(errors.accountName)}
                name="accountName"
                value={inputs.accountName}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}/>
              {errors.accountName && <Danger><small>{errors.accountName}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                fullWidth
                label="Select bank"
                select
                variant="filled"
                id="bankCode"
                type="number"
                error={!!(errors.bankCode)}
                name="bankCode"
                value={inputs.bankCode}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}>
                {bankList?.map((bank) => {
                  return (<MenuItem key={bank?.name + '=>#' + bank?.code}
                                    value={bank?.code}>{bank?.name + '=>#' + bank?.code}</MenuItem>)
                })}
              </ValidationTextField>
              {errors.bankCode && <Danger><small>{errors.bankCode}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                inputProps={{ maxLength: 20 }}
                fullWidth
                label="Account number"
                variant="filled"
                id="accountNo"
                type="number"
                error={!!(errors.accountNo)}
                name="accountNo"
                value={inputs.accountNo}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}/>
              {errors.accountNo && <Danger><small>{errors.accountNo}</small></Danger>}
            </GridItem>
            {(appName === 'Vleissentraal' && fromScreen === "cancel-card") ?
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  inputProps={{ maxLength: 20 }}
                  fullWidth
                  label="Reference (Optional)"
                  variant="filled"
                  id="reference"
                  type="text"
                  name="reference"
                  value={inputs.reference}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
              </GridItem> : null}
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      disabled={!!(errors.accountName || errors.bankCode || errors.accountNo)}>Continue</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(EFTBank)
