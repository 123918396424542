import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { grayColor } from "../../../assets/jss/eclipse.js";
import { loginToApp, logoutFromApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { DCustomerManagementApi, GDocumentManagementApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import { controlLabelColor, primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { DocumentType } from "eclipse-react-sdk/services/models";
import { ReactComponent as DeleteIcon } from "../../../assets/img/delete_icon.svg"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HttpRequestConfiguration from "../../../http-request-configuration.js";

const useStyles = makeStyles(themeStyle);

export function UploadDocument(props) {
  const pageStates = useLocation().state;
  const selectedDocument = useLocation().state?.selectedDoc;
  const document = useLocation().state?.document;
  const fromScreen = useLocation().state?.fromScreen;
  const isAddressAdded = useLocation().state?.isAddressAdded;
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const idDocumentBackImageData = useLocation().state?.idDocumentBackImageData || null;
  const selfieDocument = useLocation().state?.selfieDocument;
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const [idDocument, setIdDocument] = useState(useLocation().state?.idDocument);
  const [idDocumentBack, setIdDocumentBack] = useState(useLocation().state?.idDocumentBack || null);
  const [isLoading, setLoading] = useState(false);
  const isBack = useLocation().state?.isBack || false;
  const [alertData, setAlert] = useState(null);
  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const classes = useStyles();
  const history = useHistory();
  const updateIdDocument = (idDocument) => {
    const httpRequestConfiguration = HttpRequestConfiguration((fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const documentUpdateAPI = new GDocumentManagementApi(httpRequestConfiguration);
    documentUpdateAPI.tenantsTenantIdDocumentsDocumentIdPut(props?.appData?.auth?.tenantId, idDocument.documentId, idDocument, true).then((documentUpdateAPIResponse) => {
      setLoading(false);
      if (isBack) {
        setIdDocumentBack(documentUpdateAPIResponse.data);
      } else {
        setIdDocument(documentUpdateAPIResponse.data);
      }
      props.appData.documents = props?.appData.documents?.map((document)=>{
        return documentUpdateAPIResponse.data.documentId === document.documentId ? documentUpdateAPIResponse.data : document
      });
      const appData = {
        ...props?.appData
      }
      props.uploadDocumentHandler(appData);
      if (document.backRequired && !isBack) {
        goToDocumentBackUpload()
      } else {
        goToSelfieDocumentUpload(documentUpdateAPIResponse.data);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        if(fromScreen === 'ASSISTED_SIGNUP'){
          redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
        } else {
          redirectToErrorScreen('/pages/identity-document', '/pages/error');
        }
      }
    });
  }

  const uploadNewIdDocument = (idDocument) => {
    const httpRequestConfiguration = HttpRequestConfiguration((fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const newDocumentAPI = new DCustomerManagementApi(httpRequestConfiguration);
    newDocumentAPI.tenantsTenantIdCustomersCustomerIdDocumentsPost(props?.appData?.auth?.tenantId, (fromScreen === 'ASSISTED_SIGNUP' ? props?.appData?.assistedCustomer?.customerId : props?.appData?.customer?.customerId), idDocument, true).then((newDocumentAPIResponse) => {
      setIdDocument(newDocumentAPIResponse.data);
      setLoading(false);
      if (props?.appData?.documents) {
        props.appData.documents.push(newDocumentAPIResponse.data);
        const appData = {
          ...props?.appData
        }
        props.uploadDocumentHandler(appData);
      }
      if (document.backRequired && !isBack) {
        goToDocumentBackUpload()
      } else {
        goToSelfieDocumentUpload(newDocumentAPIResponse.data);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        if(fromScreen === 'ASSISTED_SIGNUP'){
          redirectToErrorScreen('/pages/assisted-signup', '/pages/error');
        } else {
          redirectToErrorScreen('/pages/identity-document', '/pages/error');
        }
      }
    });
  }


  const goToUploadIdDocument = () => {
    setLoading(true);
    let capturedDocImage = idDocumentImageData;
    let capturedDocImageData = { ...idDocument };
    if (isBack) {
      capturedDocImage = idDocumentBackImageData;
      capturedDocImageData = { ...idDocumentBack };
    }
    const dataImagePart1 = capturedDocImage.split(';')[0];
    const dataImagePart2 = capturedDocImage.split(';')[1];
    if (capturedDocImageData && capturedDocImageData?.documentId > 0 && fromScreen !== 'ASSISTED_SIGNUP') {
      capturedDocImageData.base64EncodedDocument = dataImagePart2.split(',')[1];
      capturedDocImageData.documentType = document?.type;
      capturedDocImageData.mediaType = dataImagePart1.split(':')[1];
      if (document?.type === DocumentType.NATIONALIDENTITY && isBack) {
        capturedDocImageData.documentType = "BACK_OF_NATIONAL_IDENTITY";
      }
      updateIdDocument(capturedDocImageData);
    } else {
      const newIdDocument = {
        base64EncodedDocument: dataImagePart2.split(',')[1],
        documentType: document?.type,
        mediaType: dataImagePart1.split(':')[1]
      }
      if (document?.type === DocumentType.NATIONALIDENTITY && isBack) {
        newIdDocument.documentType = "BACK_OF_NATIONAL_IDENTITY";
      }
      uploadNewIdDocument(newIdDocument);
    }
  }

  const goToSelfieDocumentUpload = (idDocument) => {
    history.push({
      pathname: `/pages/selfie-initial`,
      state: {
        isAddressAdded,
        fromScreen,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        idDocumentBackImageData,
        selfieDocumentImageData
      },
    });
  }
  const goToDocumentBackUpload = () => {
    history.push({
      pathname: `/pages/scan-document`,
      state: {
        isAddressAdded,
        selectedDoc: selectedDocument,
        fromScreen,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData,
        selfieDocumentImageData,
        idDocumentBackImageData,
        isBack: true
      },
    });
  }

  const backToScanDocument = () => {
    history.push({
      pathname: `/pages/scan-document`,
      state: {
        isAddressAdded,
        selectedDoc: selectedDocument,
        document,
        idDocument,
        idDocumentBack,
        selfieDocument,
        idDocumentImageData: null,
        selfieDocumentImageData,
        fromScreen
      },
    });
  }

  const backToHome = useCallback(() => {
    history.push({
      pathname: '/pages/identity-document',
      state: {
        fromScreen,
        isAddressAdded,
        ...pageStates
      }
    });
  }, [history, fromScreen, isAddressAdded, pageStates])

  React.useEffect(() => {
    window.onpopstate = () => {
      backToHome();
    };
  }, [history, backToHome])

  React.useEffect(() => {
    setIdDocument(pageStates.idDocument);
  }, [pageStates]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={'progress-bar'}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p onClick={() => {
                backToHome()
              }} style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                <ArrowBackIosIcon/>
                <span>{"Back"}</span>
              </p>
              <small style={{
                color: controlLabelColor,
                marginLeft: "auto"
              }}>Step {fromScreen === 'ASSISTED_SIGNUP' ? 3 : 2} of 5</small>
            </div>
            <strong><small style={fromScreen === 'ASSISTED_SIGNUP' ? { width: '60%' } : { width: '40%' }}>&nbsp;</small></strong>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' m-b-0'}>
                    <strong>{fromScreen === 'ASSISTED_SIGNUP' ? 'Upload the document.' : 'Upload your document.'}</strong>
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <img src={isBack ? idDocumentBackImageData : idDocumentImageData} alt="" style={{
                    padding: '10px',
                    border: '1px solid',
                    borderColor: grayColor[12],
                    boxSizing: 'border-box',
                    float: 'left',
                    width: '100%',
                    borderRadius: '5px'
                  }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="external-links"
                       style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span onClick={backToScanDocument} style={{ display: 'flex', margin: '10px 0' }}
                          className={classes.linkColor + ' external-svglink ' + classes.regularFontSize}><DeleteIcon/>&nbsp;
                      <b>Delete and upload another image</b></span>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToUploadIdDocument}>Upload the document</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data)),
  uploadDocumentHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument)
