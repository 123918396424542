import React, { useState, useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { loginToApp } from "../../redux-services/actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as ConfirmTransactionIcon } from '../../assets/img/journey_transfer.svg'
import { v4 as uuidv4 } from 'uuid';
import { MWalletTransfersApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
// import { PaymentType } from "../../eclipse-react-sdk/services/models";
// import { AuthQueryParamWithTemplateId } from "../../default-route.js";
import { AmountDisplay } from "../../components/Amount/AmountDisplay.js";
// import { ReactComponent as ThumbsUpIcon } from '../../assets/img/thumbs-up.svg'
// import { ReactComponent as ThumbsDownIcon } from '../../assets/img/thumbs-down.svg'
// import Loader from "../../components/Loader/Loader.js";
// import { IsIOSBrowser } from "../../isIOSBrowser.js";
import { primaryColor } from "../../style.scss";
import { SpinnerComponent } from "react-element-spinner";

const useStyles = makeStyles(themeStyle);

export function ConfirmPayAmount(props) {
  const manifestSettings = props?.appData?.template?.manifestSettings
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const walletDetailData = useLocation().state?.walletDetailData;
  const scannedQRData = useLocation().state?.scannedQRData;
  const paymentDetails = useLocation().state?.paymentDetails;
  const cardDetailData = useLocation().state?.cardDetailData;
  const paymentProcess = useLocation().state?.paymentProcess;
  const fromScreen = useLocation().state?.fromScreen;
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const goToSuccessPayAmount = (successPayData) => {
    history.push({
      pathname: '/pages/success-pay-amount',
      state: { walletDetailData, scannedQRData, successPayData }
    });
  };

  const backToQRPay = useCallback(() => {
    history.push({
      pathname: '/pages/pay-amount',
      state: { scannedQRData, fromScreen }
    });
  }, [history, scannedQRData, fromScreen])

  const backToDashboard = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.confirmPayAmountHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const doQRWalletTransfer = () => {
    setLoading(true);
    const qrWalletTransferAPI = new MWalletTransfersApi();
    const createQRTransferWalletBody = {
      amount: +paymentDetails.amount + (paymentDetails?.tipAmount ? +paymentDetails?.tipAmount : 0),
      description: scannedQRData?.description ? scannedQRData?.description : `QR Payment to ${scannedQRData?.code}`,//paymentDetails.reference,
      externalUniqueId: uuidv4(),
      fromWalletId: paymentDetails.fromWalletCard,
      toWalletId: paymentDetails.toWallet
    }
    qrWalletTransferAPI.tenantsTenantIdWalletsTransfersPost(props?.appData?.auth?.tenantId, createQRTransferWalletBody).then(() => {
      setLoading(false);
      goToSuccessPayAmount(paymentDetails);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/pay-qr', '/pages/error');
      }
    });
  }

  const confirmPaymentPin = () => {
    history.push({
      pathname: '/pages/confirm-payqr-pin',
      state: { scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentProcess === 'WALLETS') {
      doQRWalletTransfer();
    } else {
      confirmPaymentPin();
    }
  }

  React.useEffect(() => {
    return() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToQRPay();
      }
    }
  }, [history, backToQRPay])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIcon className={classes.linkColor} onClick={backToQRPay} />
              <span className={classes.title}>Pay a QR Code</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <ConfirmTransactionIcon />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Please confirm that you want to pay</b></p>
              <AmountDisplay padding={"0"} amount={(+paymentDetails.amount + (paymentDetails?.tipAmount ? +paymentDetails?.tipAmount : 0))} />
              {paymentProcess === 'WALLETS' ?
                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>To Merchant ({scannedQRData?.reference}) from your {manifestSettings?.brand.toUpperCase()} {walletTitleText?.toLowerCase()}-{paymentDetails.fromWalletCard}.</b></p>
                : <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>From your {`${cardDetailData?.alias}`} *** *** {`${cardDetailData?.last4Digits}`} card.</b></p>}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" style={{ padding: '1.125rem 0.25rem' }} block color="transparent" onClick={backToDashboard} >Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" style={{ padding: '1.125rem 0.25rem' }} block color="primary" type="button" onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  confirmPayAmountHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPayAmount)