import React, { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../components/Navbars/Navbar.js";

import styles from "../assets/jss/eclipse/layouts/authStyle.js";

import routesAuth from "../routes-auth.js";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import { loginToApp } from "../redux-services/actions/actions.js";
import { connect } from "react-redux";
import "../assets/css/eclipse.css?v=1.9.0";
import ThemeTemplate from "../theme-template.js";
import themeStyle from "../assets/jss/eclipse/components/themeStyle.js";
import SetTemplateTheme from "../set-template-theme.js";
import { AuthRouteWithTemplateId } from "../default-route.js";
import { ReturnDomainHostName, ReturnQueryParamTemplateId, ReturnStoredTemplateId } from "../get-template-hostname.js";
import WebFooter from "../components/Footer/WebFooter.js";

const switchRoutes = (
  <Switch>
    {routesAuth.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/auth" to={AuthRouteWithTemplateId('signup')} />
  </Switch>
);

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(themeStyle);

export function Auth({ ...rest }) {
  const classes2 = useStyles2();
  const [isLoading, setLoading] = React.useState(false);
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();

  //Load Configuration
  const loadConfiguration = useCallback((firebasePushNotificationToken) => {
    setLoading(true);
    let templateId = ReturnQueryParamTemplateId();
    if (!templateId) {
      templateId = ReturnStoredTemplateId();
      if (!templateId) {
        templateId = ReturnDomainHostName();
      }

    }
    const configAPI = new ZGlobalFunctionalityApi();
    const publicPWATenantConfig = `public.tenant.pwa.${templateId}`

    configAPI.globalConfigConfigNameGet(publicPWATenantConfig).then((configAPIResponse) => {
      const appData = {
        templateId: templateId,
        template: ThemeTemplate(JSON.stringify(configAPIResponse.data))
      }
      let appStateData = window.localStorage.getItem('appstate');
      if (appStateData && !rest.appData) {
        appStateData = atob(appStateData);
        appStateData = JSON.parse(appStateData);
        appStateData.isRefresh = true;
        rest.appData = { ...appStateData }
        rest.authConfigHandler({ ...appStateData });
        let templateId = ReturnStoredTemplateId();
        if (!templateId) {
          templateId = ReturnDomainHostName()
        }
        SetTemplateTheme(appStateData, templateId);
      } else {
        rest.authConfigHandler({ ...appData });
        SetTemplateTheme(appData, templateId);
      }

      setLoading(false);
    }).catch((error) => {
      const appData = {
        template: 'DEFAULT'
      }
      rest.authConfigHandler({ ...appData });
      setLoading(false);
    });
  }, [rest]);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    localStorage.removeItem('appstate');
    if (!rest?.appData?.template && !isLoading) {
      loadConfiguration();
    }

    if (navigator.platform.indexOf("Win") > -1) {
      document.body.style.overflow = "hidden";
    }
  }, [mainPanel, loadConfiguration, rest, isLoading]);
  return (
    <div className={classes.wrapper}>
      {(!isLoading && rest?.appData?.template) ?
        <div className={'header-top ' + classes.mainPanel} ref={mainPanel}>
          <Navbar
            isMenuEnabled={false}
            routes={routesAuth}
            {...rest}
          />
          <div className={classes.content + " auth-parent"}>
          <div className="curve-area">
            <div className={classes2.gradientCurve + " curve"}>&nbsp;</div>
          </div>
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          <div className="internal-wrap auth-internal-wrap">
            <div className="internal-card">
            <div className={classes.content} style={{paddingBottom: '15px'}}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
            </div>
          </div>
        </div>
        <WebFooter />
        </div>
         :
        'Loading...'
      }
    </div>
  );
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  authConfigHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)