import React from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { VerifyOTP } from "../../components/VerifyOTP/VerifyOTP";

const useStyles = makeStyles(themeStyle);


export function ResetPinVerifyOTP(props) {
  const history = useHistory();
  const classes = useStyles();

  const mobileNumber = useLocation().state?.mobileNumber;
  const gCaptchaToken = useLocation().state?.gCaptcha;
  const walletDetailData = useLocation().state?.walletDetailData;
  const cardDetails = useLocation().state?.cardDetails;
  const backToHome = () => {
    history.push({
      pathname: '/pages/wallets-detail',
      state: { walletDetailData }
    });
  }

  const goToCreateNewPin = () => {
    history.push({
      pathname: 'new-physical-card-pin',
      state: { cardDetails, walletDetailData, successScreen: "success-card-reset-pin" }
    });
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
              <span className={classes.title}>Change your PIN</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <VerifyOTP {...props} mobileNumber={mobileNumber} gCaptcha={gCaptchaToken} onComplete={goToCreateNewPin}/>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  verifyOTPHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPinVerifyOTP)