/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Fee } from '../models';
import { NewEclipseWalletWithdrawal } from '../models';
import { UpdatedWalletWithdrawal } from '../models';
import { WalletWithdrawalResult } from '../models';
import { WalletWithdrawalType } from '../models';
/**
 * LWalletWithdrawalsApi - axios parameter creator
 * @export
 */
export const LWalletWithdrawalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the fees that would be charged for a withdrawal
         * @summary Get the fees that would be charged for a withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} [amount] 
         * @param {WalletWithdrawalType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet: async (tenantId: number, walletId: number, amount?: number, type?: WalletWithdrawalType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/withdrawals/fees`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the wallets withdrawals based on certain criteria.
         * @summary Get a wallets withdrawals
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the withdrawals inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the withdrawals exclusive
         * @param {string} [externalUniqueId] The external unique id provided when the withdrawal was created. Passing this will result in 0 or 1 rows returned
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsGet: async (tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalUniqueId?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/withdrawals`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (dateFromIncl !== undefined) {
                localVarQueryParameter['dateFromIncl'] = dateFromIncl;
            }

            if (dateToExcl !== undefined) {
                localVarQueryParameter['dateToExcl'] = dateToExcl;
            }

            if (externalUniqueId !== undefined) {
                localVarQueryParameter['externalUniqueId'] = externalUniqueId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The withdrawal type determines exactly what fields are required
         * @summary Initiate a new withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletWithdrawal} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsPost: async (tenantId: number, walletId: number, body?: NewEclipseWalletWithdrawal, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsPost.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsPost.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/withdrawals`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The Id is the withdrawal id field.
         * @summary Get a withdrawal by Id
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet: async (tenantId: number, walletId: number, withdrawalId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet.');
            }
            // verify required parameter 'withdrawalId' is not null or undefined
            if (withdrawalId === null || withdrawalId === undefined) {
                throw new RequiredError('withdrawalId','Required parameter withdrawalId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/withdrawals/{withdrawalId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"withdrawalId"}}`, encodeURIComponent(String(withdrawalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * CANCELLED is only supported and only if the withdrawal is PENDING and the downstream provider supports cancellation after submission (Supported by Paycorp). SUCCESSFUL and ERROR_PERM are for sandbox use only to simulate withdrawals. A withdrawal type that supports multi stage BUILDING and then submission (e.g. GLOBAL_CRYPTO) can have fields updated if it is still BUILDING. If the gateway now has sufficient information to process the withdrawal it will submit it and transition to PENDING, SUCCESSFUL etc
         * @summary Update wallet withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} withdrawalId 
         * @param {UpdatedWalletWithdrawal} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut: async (tenantId: number, walletId: number, withdrawalId: number, body?: UpdatedWalletWithdrawal, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut.');
            }
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut.');
            }
            // verify required parameter 'withdrawalId' is not null or undefined
            if (withdrawalId === null || withdrawalId === undefined) {
                throw new RequiredError('withdrawalId','Required parameter withdrawalId was null or undefined when calling tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut.');
            }
            const localVarPath = `/tenants/{tenantId}/wallets/{walletId}/withdrawals/{withdrawalId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"withdrawalId"}}`, encodeURIComponent(String(withdrawalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LWalletWithdrawalsApi - functional programming interface
 * @export
 */
export const LWalletWithdrawalsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Gets the fees that would be charged for a withdrawal
         * @summary Get the fees that would be charged for a withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} [amount] 
         * @param {WalletWithdrawalType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet(tenantId: number, walletId: number, amount?: number, type?: WalletWithdrawalType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Fee>> {
            const localVarAxiosArgs = await LWalletWithdrawalsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet(tenantId, walletId, amount, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets the wallets withdrawals based on certain criteria.
         * @summary Get a wallets withdrawals
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the withdrawals inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the withdrawals exclusive
         * @param {string} [externalUniqueId] The external unique id provided when the withdrawal was created. Passing this will result in 0 or 1 rows returned
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalUniqueId?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletWithdrawalResult>>> {
            const localVarAxiosArgs = await LWalletWithdrawalsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId, walletId, dateFromIncl, dateToExcl, externalUniqueId, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The withdrawal type determines exactly what fields are required
         * @summary Initiate a new withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletWithdrawal} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdWithdrawalsPost(tenantId: number, walletId: number, body?: NewEclipseWalletWithdrawal, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletWithdrawalResult>> {
            const localVarAxiosArgs = await LWalletWithdrawalsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsPost(tenantId, walletId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The Id is the withdrawal id field.
         * @summary Get a withdrawal by Id
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet(tenantId: number, walletId: number, withdrawalId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletWithdrawalResult>> {
            const localVarAxiosArgs = await LWalletWithdrawalsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet(tenantId, walletId, withdrawalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * CANCELLED is only supported and only if the withdrawal is PENDING and the downstream provider supports cancellation after submission (Supported by Paycorp). SUCCESSFUL and ERROR_PERM are for sandbox use only to simulate withdrawals. A withdrawal type that supports multi stage BUILDING and then submission (e.g. GLOBAL_CRYPTO) can have fields updated if it is still BUILDING. If the gateway now has sufficient information to process the withdrawal it will submit it and transition to PENDING, SUCCESSFUL etc
         * @summary Update wallet withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} withdrawalId 
         * @param {UpdatedWalletWithdrawal} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut(tenantId: number, walletId: number, withdrawalId: number, body?: UpdatedWalletWithdrawal, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletWithdrawalResult>> {
            const localVarAxiosArgs = await LWalletWithdrawalsApiAxiosParamCreator(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut(tenantId, walletId, withdrawalId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LWalletWithdrawalsApi - factory interface
 * @export
 */
export const LWalletWithdrawalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Gets the fees that would be charged for a withdrawal
         * @summary Get the fees that would be charged for a withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} [amount] 
         * @param {WalletWithdrawalType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet(tenantId: number, walletId: number, amount?: number, type?: WalletWithdrawalType, options?: any): AxiosPromise<Fee> {
            return LWalletWithdrawalsApiFp(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet(tenantId, walletId, amount, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the wallets withdrawals based on certain criteria.
         * @summary Get a wallets withdrawals
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {string} [dateFromIncl] fromDate, the date from where we want to get the withdrawals inclusive.
         * @param {string} [dateToExcl] toDate, the date upto when we want to get the withdrawals exclusive
         * @param {string} [externalUniqueId] The external unique id provided when the withdrawal was created. Passing this will result in 0 or 1 rows returned
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalUniqueId?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Array<WalletWithdrawalResult>> {
            return LWalletWithdrawalsApiFp(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId, walletId, dateFromIncl, dateToExcl, externalUniqueId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * The withdrawal type determines exactly what fields are required
         * @summary Initiate a new withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {NewEclipseWalletWithdrawal} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsPost(tenantId: number, walletId: number, body?: NewEclipseWalletWithdrawal, options?: any): AxiosPromise<WalletWithdrawalResult> {
            return LWalletWithdrawalsApiFp(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsPost(tenantId, walletId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * The Id is the withdrawal id field.
         * @summary Get a withdrawal by Id
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet(tenantId: number, walletId: number, withdrawalId: number, options?: any): AxiosPromise<WalletWithdrawalResult> {
            return LWalletWithdrawalsApiFp(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet(tenantId, walletId, withdrawalId, options).then((request) => request(axios, basePath));
        },
        /**
         * CANCELLED is only supported and only if the withdrawal is PENDING and the downstream provider supports cancellation after submission (Supported by Paycorp). SUCCESSFUL and ERROR_PERM are for sandbox use only to simulate withdrawals. A withdrawal type that supports multi stage BUILDING and then submission (e.g. GLOBAL_CRYPTO) can have fields updated if it is still BUILDING. If the gateway now has sufficient information to process the withdrawal it will submit it and transition to PENDING, SUCCESSFUL etc
         * @summary Update wallet withdrawal
         * @param {number} tenantId 
         * @param {number} walletId 
         * @param {number} withdrawalId 
         * @param {UpdatedWalletWithdrawal} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut(tenantId: number, walletId: number, withdrawalId: number, body?: UpdatedWalletWithdrawal, options?: any): AxiosPromise<WalletWithdrawalResult> {
            return LWalletWithdrawalsApiFp(configuration).tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut(tenantId, walletId, withdrawalId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LWalletWithdrawalsApi - object-oriented interface
 * @export
 * @class LWalletWithdrawalsApi
 * @extends {BaseAPI}
 */
export class LWalletWithdrawalsApi extends BaseAPI {
    /**
     * Gets the fees that would be charged for a withdrawal
     * @summary Get the fees that would be charged for a withdrawal
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {number} [amount] 
     * @param {WalletWithdrawalType} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LWalletWithdrawalsApi
     */
    public tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet(tenantId: number, walletId: number, amount?: number, type?: WalletWithdrawalType, options?: any) {
        return LWalletWithdrawalsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdWithdrawalsFeesGet(tenantId, walletId, amount, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets the wallets withdrawals based on certain criteria.
     * @summary Get a wallets withdrawals
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {string} [dateFromIncl] fromDate, the date from where we want to get the withdrawals inclusive.
     * @param {string} [dateToExcl] toDate, the date upto when we want to get the withdrawals exclusive
     * @param {string} [externalUniqueId] The external unique id provided when the withdrawal was created. Passing this will result in 0 or 1 rows returned
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LWalletWithdrawalsApi
     */
    public tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId: number, walletId: number, dateFromIncl?: string, dateToExcl?: string, externalUniqueId?: string, limit?: number, offset?: number, options?: any) {
        return LWalletWithdrawalsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdWithdrawalsGet(tenantId, walletId, dateFromIncl, dateToExcl, externalUniqueId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The withdrawal type determines exactly what fields are required
     * @summary Initiate a new withdrawal
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {NewEclipseWalletWithdrawal} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LWalletWithdrawalsApi
     */
    public tenantsTenantIdWalletsWalletIdWithdrawalsPost(tenantId: number, walletId: number, body?: NewEclipseWalletWithdrawal, options?: any) {
        return LWalletWithdrawalsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdWithdrawalsPost(tenantId, walletId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The Id is the withdrawal id field.
     * @summary Get a withdrawal by Id
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {number} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LWalletWithdrawalsApi
     */
    public tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet(tenantId: number, walletId: number, withdrawalId: number, options?: any) {
        return LWalletWithdrawalsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdGet(tenantId, walletId, withdrawalId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * CANCELLED is only supported and only if the withdrawal is PENDING and the downstream provider supports cancellation after submission (Supported by Paycorp). SUCCESSFUL and ERROR_PERM are for sandbox use only to simulate withdrawals. A withdrawal type that supports multi stage BUILDING and then submission (e.g. GLOBAL_CRYPTO) can have fields updated if it is still BUILDING. If the gateway now has sufficient information to process the withdrawal it will submit it and transition to PENDING, SUCCESSFUL etc
     * @summary Update wallet withdrawal
     * @param {number} tenantId 
     * @param {number} walletId 
     * @param {number} withdrawalId 
     * @param {UpdatedWalletWithdrawal} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LWalletWithdrawalsApi
     */
    public tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut(tenantId: number, walletId: number, withdrawalId: number, body?: UpdatedWalletWithdrawal, options?: any) {
        return LWalletWithdrawalsApiFp(this.configuration).tenantsTenantIdWalletsWalletIdWithdrawalsWithdrawalIdPut(tenantId, walletId, withdrawalId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
