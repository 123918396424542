import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor, secondaryTextColor } from "../../style.scss";
import { ReactComponent as CardCancelSuccess } from "../../assets/img/card_cancel_success.svg";
import { ReactComponent as WalletTransferSuccess } from "../../assets/img/wallet_success.svg";
import getSymbolFromCurrency from 'currency-symbol-map'
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function CancelCardSuccess(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const pageState = useLocation().state;
  const walletDetailData = pageState?.walletDetailData;
  const newWalletDetailData = pageState?.newWalletDetailData;
  const selectedTransferWallet = pageState?.selectedTransferWallet;
  const classes = useStyles();
  const withdrawalType = pageState?.withdrawalType;
  const appName = props?.appData?.template?.manifestSettings?.appName;

  const goToDashboard = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.cancelCardSuccessHandler(appData);

    if (withdrawalType && withdrawalType === 'NEW_CARD') {
      history.push({
        pathname: '/pages/wallets-detail',
        state: { walletDetailData: newWalletDetailData }
      });
    } else {
      history.push({
        pathname: '/pages/dashboard',
      });
    }
  }, [history, props, newWalletDetailData, withdrawalType])

  const handleSubmit = (event) => {
    event.preventDefault();
    goToDashboard();
  }

  React.useEffect(() => {
    window.onpopstate = (e) => {
      e.preventDefault();
      goToDashboard();
    };
  }, [ goToDashboard])

  return (
    <div>
      {(appName === 'Vleissentraal') ?
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <CardCancelSuccess style={{ marginTop: 0 }} className={classes.screenCenterPrimaryIcon}
                                   fill={primaryIconColor}
                                   stroke={primaryIconColor}/>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <h4 style={{ margin: 0 }}>
              <b className={classes.regularFontSize}>
                Your card was successfully cancelled.
              </b>
            </h4>
            {+walletDetailData?.availableBalance > 0?
              <p style={{ color:secondaryTextColor}}>
                {"Your card balance was transferred to your bank account. The payments will reflect in your account within 24 – 48 hour on weekdays."}
              </p>:null}
            <p style={{ color:secondaryTextColor}}>{'To order a new card call {000 000 000}.'}</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
            <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                    onClick={handleSubmit}>{'Done'}
            </Button>
          </GridItem>
        </GridContainer> :
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
            <GridContainer>
              {withdrawalType && withdrawalType !== '' ?
                <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                  {withdrawalType === 'NEW_CARD' ?
                    <CardCancelSuccess style={{ marginTop: 0 }} className={classes.screenCenterPrimaryIcon}
                                       fill={primaryIconColor}
                                       stroke={primaryIconColor}/>
                    : <WalletTransferSuccess style={{ marginTop: 0 }} className={classes.screenCenterPrimaryIcon}
                                             fill={primaryIconColor}
                                             stroke={primaryIconColor}/>}
                </GridItem>
                : <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                  <CardCancelSuccess style={{ marginTop: 0 }} className={classes.screenCenterPrimaryIcon}
                                     fill={primaryIconColor}
                                     stroke={primaryIconColor}/>
                </GridItem>}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <h4 style={{ margin: 0 }}>
              {withdrawalType && withdrawalType !== '' ?
                <b className={classes.regularFontSize}>
                  {withdrawalType === 'NEW_CARD' ? 'Your card was created successfully.' : 'Your card balance was transferred successfully.'}
                </b>
                : <b className={classes.regularFontSize}>
                  Your card was successfully cancelled.
                </b>}
            </h4>
            {withdrawalType && withdrawalType !== '' ?
              <p>
                {withdrawalType === 'NEW_CARD' ? 'Next you can top up your card and start making payments using your virtual card.' : `Your ${walletTitleText?.toLowerCase()} balance is:`}
              </p>
              : null}
            {withdrawalType && withdrawalType !== '' ?
              withdrawalType === 'NEW_CARD' ?
                null : <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}>
                  <b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+walletDetailData?.availableBalance + +(withdrawalType && withdrawalType === 'WALLET' ? selectedTransferWallet?.availableBalance : 0)).toFixed(2)}</b>
                </h2>
              : null}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
            <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                    onClick={handleSubmit}>
              {withdrawalType && withdrawalType === 'NEW_CARD' ? 'View My Card' : 'Done'}
            </Button>
          </GridItem>
        </GridContainer>}

    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  cancelCardSuccessHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CancelCardSuccess)