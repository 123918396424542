import React from "react";
// @material-ui/core
import { connect } from "react-redux";


export function VirtualCard(props) {
  const cardHolderName = `${props?.appData?.customer?.title ? props?.appData?.customer?.title : ''} ${props?.appData?.customer?.firstName ? props?.appData?.customer?.firstName : ''} ${props?.appData?.customer?.middleName ? props?.appData?.customer?.middleName : ''} ${props?.appData?.customer?.lastName ? props?.appData?.customer?.lastName : ''}`;
  const cardNumberFirstPart = (props?.viewWalletCardData) ? `${props?.walletCardData?.pan.substr(0, 4)}` : `****`;
  const cardNumberSecondPart = (props?.viewWalletCardData) ? `${props?.walletCardData?.pan.substr(4, 4)}` : `****`;
  const cardNumberThirdPart = (props?.viewWalletCardData) ? `${props?.walletCardData?.pan.substr(8, 4)}` : `****`;
  const cardNumberFourthPart = `${props?.walletCardData?.pan.substr(12, 4)}`;
  const cardNumber = `${cardNumberFirstPart} ${cardNumberSecondPart} ${cardNumberThirdPart} ${cardNumberFourthPart}`;
  const expiryMonth = `${(new Date(props?.walletCardData?.expires).getMonth()) + 1}`;
  const expiryYear = `${new Date(props?.walletCardData?.expires).getFullYear()}`;
  const expiryDate = (props?.viewWalletCardData) ? `${expiryMonth.padStart(2, 0)}/${expiryYear.substr(2, 2)}` : `**/**`;
  const cvv = (props?.viewWalletCardData) ? `${props?.walletCardData?.cvv}` : `***`;
  const cardType = props?.walletCardData?.cardType;
  const appName = props?.appData?.template?.manifestSettings?.appName;
  return (
    <div>
      <div className="card">
        <img style={{ width: '100%', height: '100%' }}
             src={`${window.location.origin}${process.env.PUBLIC_URL}/card_${props?.appData?.template?.manifestSettings?.brand}.png`}
             alt=""/>
        {props?.appData?.template?.manifestSettings?.brand==="coinvest"?
          <span className="cardType">{cardType === "VIRTUAL" ? "Virtual" : "Physical Card"}</span>
          :null}
      </div>
      <div className={`card-content ${(appName === 'Vleissentraal') ? "vleis-card ":""}`} >
        <h5>{cardHolderName}</h5>
        <h6>{cardNumber}</h6>
        <ul>
          <li>
            <span>VALID<br/>THRU</span>
            <strong>{expiryDate}</strong>
          </li>
          <li>
            <span>CVV2</span>
            <strong>{cvv}</strong>
          </li>
        </ul>
      </div>
    </div>)
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})
export default connect(mapStateToProps)(VirtualCard)