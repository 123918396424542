import React, { useCallback } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import WalletTypeMode from "../../wallet-type-mode.js";
import { CardStatus, WalletMode } from "eclipse-react-sdk/services/models";
import { DCustomerManagementApi, HWalletsApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import { primaryColor } from "../../style.scss";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { ReactComponent as CardIcon } from "../../assets/img/card.svg";
import { ReactComponent as WalletIcon } from "../../assets/img/wallet.svg";
import { ReactComponent as EFTIcon } from "../../assets/img/eft.svg";
import { ReactComponent as RetailerIcon } from "../../assets/img/retailer.svg";
import { ReactComponent as ATMIcon } from "../../assets/img/atm.svg";

const useStyles = makeStyles(themeStyle);

export function Topup(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = React.useState(null);
  const walletDetailData = useLocation().state?.walletDetailData;
  const [walletCards, setWalletCards] = React.useState([]);
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const walletTopUpOptions = props?.appData?.template?.pwaAppOptions?.walletOptions?.topUp?.options;
  const history = useHistory();
  const classes = useStyles();

  const [walletTypeMode, setWalletTypeMode] = React.useState(WalletMode.CLOSEDLOOPDIGITAL);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToWallet = () => {
    history.push({
      pathname: '/pages/wallets-detail',
      state: { walletDetailData }
    });
  }

  const walletTopUpEFT = () => {
    history.push({
      pathname: '/pages/topup-eft',
      state: { walletDetailData, fromScreen: 'TOPUP' }
    });
  }

  const walletTopUpRetailStore = () => {
    history.push({
      pathname: '/pages/topup-retail-store',
      state: { walletDetailData, fromScreen: 'TOPUP' }
    });
  }

  const walletTopUpATM = () => {
    history.push({
      pathname: '/pages/topup-atm',
      state: { walletDetailData, fromScreen: 'TOPUP' }
    });
  }

  const viaCardTransfer = useCallback((linkedBankCards) => {
    history.push({
      pathname: '/pages/via-card',
      state: { linkedBankCards, walletDetailData, fromScreen: 'TOPUP' }
    });
  }, [history, walletDetailData]);

  const bankCardNoLinked = useCallback(() => {
    history.push({
      pathname: '/pages/no-card-linked',
      state: { walletDetailData, fromScreen: 'TOPUP' }
    });
  }, [history, walletDetailData]);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const getLinkedBankCards = useCallback(() => {
    setLoading(true);
    const linkedBankCardsAPI = new DCustomerManagementApi();
    linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((linkedBankCardsAPIResponse) => {
      setLoading(false);
      if (linkedBankCardsAPIResponse.data && linkedBankCardsAPIResponse.data.length > 0) {
        linkedBankCardsAPIResponse.data = linkedBankCardsAPIResponse.data.filter((card=>card.status ===CardStatus?.ACTIVE));
        let filteredCards = linkedBankCardsAPIResponse.data;
        if (walletCards && walletCards.length > 0) {
          const searchedPan = walletCards[0].pan.substr(12, 4);
          filteredCards = linkedBankCardsAPIResponse.data.filter((cards) => cards?.last4Digits !== searchedPan);
        }
        if (filteredCards.length > 0) {
          viaCardTransfer(filteredCards);
        } else {
          bankCardNoLinked();
        }
      } else {
        bankCardNoLinked();
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, viaCardTransfer, bankCardNoLinked, walletCards]);

  const getWalletCards = useCallback(() => {
    setLoading(true);
    const walletCardsAPI = new HWalletsApi();
    walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(props?.appData?.auth?.tenantId, walletDetailData?.walletId).then((walletCardsAPIResponse) => {
      setLoading(false);
      if (walletCardsAPIResponse.data && walletCardsAPIResponse.data.length > 0) {
        setWalletCards(walletCardsAPIResponse.data);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletDetailData]);

  React.useEffect(() => {
    setWalletTypeMode(WalletTypeMode(props?.appData?.template, walletDetailData?.walletTypeId));
    if (walletTypeMode === WalletMode.PREPAIDCARD) {
      getWalletCards();
    }
  }, [props, walletDetailData, walletTypeMode, getWalletCards]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToWallet}/>
                <span className={classes.title}>Top-Up</span>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card style={{ margin: '0' }}>
                  <ButtonBase style={{ justifyContent: 'flex-start' }}>
                    <CardContent style={{ width: '100%', float: 'left' }}>
                      <h5 style={{
                        display: 'flex',
                        marginTop: '0px',
                        marginBottom: '0px'
                      }}>{(walletTypeMode === WalletMode.PREPAIDCARD) ? <CardIcon/> : <WalletIcon/>}&nbsp;&nbsp;
                        <b>{walletDetailData?.name}</b></h5>
                      <div className="bottom-content">
                        <ul>
                          <li>
                            <span>Unique Ref. ID</span>
                            <strong>{walletDetailData?.friendlyId}</strong>
                          </li>
                          <li>
                            <span>Balance</span>
                            <strong>{getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.currentBalance.toFixed(2)}</strong>
                          </li>
                          <li>
                            <span>Available</span>
                            <strong>{getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.availableBalance.toFixed(2)}</strong>
                          </li>
                        </ul>
                      </div>
                    </CardContent>
                  </ButtonBase>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>How would you like to top-up
            your {walletTitleText?.toLowerCase()}?</b></p>
        </GridItem>
        {walletTopUpOptions?.card ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                getLinkedBankCards()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><CardIcon/>&nbsp;&nbsp;
                    <b>Card</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
        {walletTopUpOptions?.eft ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                walletTopUpEFT()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><EFTIcon/>&nbsp;&nbsp;
                    <b>EFT</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
        {walletTopUpOptions?.cashRetailer ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                walletTopUpRetailStore()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><RetailerIcon/>&nbsp;&nbsp;
                    <b>Cash at retailer</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
        {walletTopUpOptions?.atm ?
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={() => {
                walletTopUpATM()
              }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}><ATMIcon/>&nbsp;&nbsp;
                    <b>ATM</b></h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>
          : null}
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(Topup)