import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { ReactComponent as AccountReady } from "../../assets/img/account_ready.svg"
import { primaryColor, primaryIconColor } from "../../style.scss";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { WalletStatus } from "eclipse-react-sdk/services/models";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(themeStyle);

export function RatifyKYCSuccess(props) {
  const createWallet = props?.appData?.template?.pwaAppOptions?.createWallet?.allow || 0;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const isCreateDefaultDigitalWallet = props?.appData?.template?.pwaAppOptions?.defaultCreateDigitalWallet;
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [walletGenerated, setWalletGenerated] = useState(false);
  const [alertData, setAlert] = useState(null);

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.ratifyKYCSuccessHandler(appData);
    history.push({
      pathname:'/pages/dashboard'
    });
  }, [props, history])

  React.useEffect(() => {
    window.onpopstate = (event) => {
      event.preventDefault();
        backToHome();
    };
  }, [history, backToHome]);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const createDefaultDigitalWallet = useCallback(() => {
    const customerWalletAPI = new DCustomerManagementApi();
    const createWalletBody = {
      externalUniqueId: uuidv4(),
      name: `My ${walletTitleText}`,
      description: `Default Created ${walletTitleText} Post KYC Success`,
      status: WalletStatus?.ACTIVE,
      walletTypeId: props?.appData?.template?.publicCloseLoopWalletTypeId
    }
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, createWalletBody).then(() => {
      setWalletGenerated(true);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletTitleText]);

  const getWallets = useCallback(() => {
    setLoading(true);
    const customerWalletAPI = new DCustomerManagementApi();
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((customerWalletAPIResponse) => {
      let digitalWalletList = [];
      if (customerWalletAPIResponse.data && customerWalletAPIResponse.data.length > 0) {
        const activeWallets = customerWalletAPIResponse?.data?.filter(wallet => wallet?.status !== WalletStatus?.CANCELLED) || [];
        digitalWalletList = activeWallets?.filter(wallet => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId);
      }
      if (digitalWalletList && digitalWalletList.length < 1 && isCreateDefaultDigitalWallet) {
        createDefaultDigitalWallet();
        return;
      }
      setWalletGenerated(true);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, createDefaultDigitalWallet, isCreateDefaultDigitalWallet]);

  React.useEffect(() => {
    if(!walletGenerated){
      getWallets();
    }
  }, [getWallets,walletGenerated])
  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <AccountReady className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                            stroke={primaryIconColor}/>
              <h1 className='auth-title' style={{ margin: '50px 0 0 0' }}>You're account <br/> is ready!</h1>
              {createWallet > 0 ?
                <p className={classes.regularFontSize}><b>Next you can add your {walletTitleText?.toLowerCase()} and
                  cards.</b></p>
                : <p className={classes.regularFontSize}><b>Next you can link your bank card and start paying QR codes and bills.</b></p>
              }
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={backToHome}
                      type="button">Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  ratifyKYCSuccessHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RatifyKYCSuccess)