import { progressStepsTextColor, progressStepsDefaultColor, progressStepsActiveColor, primaryColor, linkHoverFocusTextColor } from "../../../../style.scss"

const progressStepsStyle = {
  progressSteps: {
    width: "100%", 
    top: "32px", 
    right: "0",
    margin: '1em 0'
  },
  stepsTitle: {
    width: "calc(100% - 2em)", 
    textAlign: "right", 
    fontSize: "0.8em", 
    fontWeight: "500", 
    color: progressStepsTextColor,
    height: '2.5em',
    margin: '0 1em'
  },
  stepsText: {
    padding: "0 0.5em",
    float: 'right',
    lineHeight: '1.2em'
  },
  taxiIcon: {
    float: 'right'
  },
  backText: {
    padding: "0 0.5em",
    float: 'left',
    lineHeight: '1.35em'
  },
  backIcon: {
    float: 'left',
    marginTop: '-0.25em'
  },
  steps: {
    width: "100%"
  },
  stepper: {
    display: "inline-block", 
    margin: "0 1%", 
    borderRadius: "0.5em", 
    height: "0.25em", 
    top: "-0.25em", 
    position: "relative",
    background: progressStepsDefaultColor
  },
  activeStepper: {
    background: progressStepsActiveColor
  },
  backLinkColor: {
    color: primaryColor,
    fontWeight: '600',
    cursor: 'pointer',
    textAlign: 'left',
    position: 'absolute',
    marginLeft: '-0.5em',
    '&:hover': {
      color: linkHoverFocusTextColor,
    },
    '&:focus': {
      color: linkHoverFocusTextColor,
    }
  },
};
export default progressStepsStyle;
