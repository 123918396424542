import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { VerifyOTP } from "../../components/VerifyOTP/VerifyOTP";
import { AAuthenticationApi, DCustomerManagementApi } from "../../eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import { primaryColor } from "../../style.scss";
import AlertNotification from "../../components/Snackbar/AlertNotification";
import HttpRequestConfiguration from "../../http-request-configuration";

const useStyles = makeStyles(themeStyle);

export function AssistedCardVerifyOTP(props) {
  const mobileNumber = useLocation().state?.mobileNumber;
  const gCaptchaToken = useLocation().state?.gCaptcha;
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);
  const history = useHistory();
  const classes = useStyles();

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = () => {
    history.push({
      pathname: '/pages/assisted-card-register',
    });
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const getAddress = useCallback((assistedCustomerData) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(assistedCustomerData?.assistedUserToken);
    const addressAPI = new DCustomerManagementApi(httpRequestConfiguration);
    addressAPI.tenantsTenantIdCustomersCustomerIdAddressesGet(props?.appData?.auth?.tenantId, props?.appData?.assistedCustomer?.customerId).then((addressAPIResponse) => {
      if (addressAPIResponse.data && addressAPIResponse.data.length > 0) {
        history.push({
          pathname: '/pages/assisted-physical-card',
          state: { mobileNumber, flow: "assisted-physical-card" }
        });
      } else {
        history.push({ pathname: '/pages/save-user-information', state: { flow: "assisted-physical-card" } });
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [history, props, redirectToErrorScreen, mobileNumber]);

  const getAssistedUserToken = useCallback((otp, result) => {
    const loginAPI = new AAuthenticationApi();
    loginAPI.authenticationLoginPost({ identity: props?.appData?.assistedCustomer.identity, otp }).then((authAPIResponse) => {
      const auth = { assistedUserToken: authAPIResponse?.data?.headerValue };
      const assistedCustomer = { ...props?.appData?.assistedCustomer, ...auth };
      const appData = {
        ...props?.appData,
        assistedCustomer
      }
      if (result) {
        getAddress(assistedCustomer);
      }
      props.verifyOTPHandler(appData);
    }).catch(() => {
      redirectToErrorScreen(`/pages/dashboard`, `/pages/error`);
    });
  }, [props, redirectToErrorScreen, getAddress]);
  

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome} />
              <span className={classes.title}>Assisted Card Registration</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <VerifyOTP
        {...props}
        mobileNumber={mobileNumber}
        gCaptcha={gCaptchaToken}
        onComplete={getAssistedUserToken}
        flow={'assisted-physical-card'}
      />
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  verifyOTPHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedCardVerifyOTP)