import React, { useState, useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { TextField } from "@material-ui/core";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger.js";
import { SpinnerComponent } from "react-element-spinner";
import { WalletMode } from "eclipse-react-sdk/services/models";

import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import { primaryColor, primaryIconColor } from "../../style.scss";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { ReactComponent as Wallet } from "../../assets/img/jounrney_create_walet.svg";
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function CreateWallet(props) {
  const history = useHistory();
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const classes = useStyles();
  const [alertData, setAlert] = useState(null);
  const [isRatifiedSuccess, setRatify] = useState(false);

  const goToConfirmCreateWallet = () => {
    history.push({
      pathname: '/pages/confirm-create-wallet',
      state: inputs
    });
  };

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = () => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.createWalletHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }

  const doRatifyKYC = () => {
    if (props?.appData?.template?.ratifyTrialCount > 2) {
      history.push({
        pathname: `/pages/contact-support`
      });
    } else {
      history.push({
        pathname: `/pages/identity-document`
      });
    }
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ walletName: '', walletType: WalletMode.CLOSEDLOOPDIGITAL });
  const [errors, setErrors] = useState({});
  const getRatify = () => {
    setLoading(true);
    const ratifyAPI = new DCustomerManagementApi();
    ratifyAPI.tenantsTenantIdCustomersCustomerIdWalletTypesGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, 1, 0).then((ratifyAPIResponse) => {
      if (ratifyAPIResponse.data && ratifyAPIResponse.data.length < 1) {
        setLoading(false);
        showAlert(true, 'ERROR', `Ratify details not found!`);
      } else {
        let isRatifyFailed = 0;
        let isAllowedCloseLoopDigital = false;
        let isAllowedPrepaidCard = false;
        ratifyAPIResponse.data.forEach((ratifyAPIResponseData) => {
          if (ratifyAPIResponseData.walletTypeId === props?.appData?.template?.publicCardWalletTypeId) {
            if (!ratifyAPIResponseData?.allowed) {
              isRatifyFailed = isRatifyFailed + 1;
            }
            isAllowedPrepaidCard = ratifyAPIResponseData?.allowed;
          }
          if (ratifyAPIResponseData.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId) {
            if (!ratifyAPIResponseData?.allowed) {
              isRatifyFailed = isRatifyFailed + 1;
            }
            isAllowedCloseLoopDigital = ratifyAPIResponseData?.allowed;
          }
        });
        setRatify(isRatifyFailed <= 0);
        setLoading(false);
        if (isRatifiedSuccess){
          goToConfirmCreateWallet();
        } else {
          if(inputs.walletType === WalletMode.PREPAIDCARD && !isAllowedPrepaidCard) {
            doRatifyKYC();
          } else if (inputs.walletType === WalletMode.CLOSEDLOOPDIGITAL && !isAllowedCloseLoopDigital){
            doRatifyKYC();
          }else{
            goToConfirmCreateWallet();
          }
            

        }
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/user', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateCreateWalletForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      getRatify();
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name]= event.target.value;
    setInputs(inputs);
    const validationErrors = validateCreateWalletForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateCreateWalletForm(inputs);
    setErrors(validationErrors);
  }

  const validateCreateWalletForm = (inputs) => {
    const errors = {}
    if (!inputs.walletName) {
      errors.walletName = `${walletTitleText} Name required!`
    } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{3,}$/i.test(inputs.walletName)) {
      errors.walletName = `Invalid ${walletTitleText} Name`
    }

    if (!inputs.walletType) {
      errors.walletType = `${walletTitleText} Type required!`
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
              <span className={classes.title}>Create {walletTitleText?.toLowerCase()}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <Wallet className={classes.screenCenterPrimaryIcon} fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center'}}>
                <h4 style={{ margin:0}}><b className={classes.regularFontSize}>Give your {walletTitleText?.toLowerCase()} a name</b></h4>
                {errors.walletType && <Danger><small>{errors.walletType}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label={`Name your ${walletTitleText?.toLowerCase()}`}
                  variant="filled"
                  id="walletName"
                  type="text"
                  error={!!(errors.walletName)}
                  name="walletName"
                  value={inputs.walletName}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp} />
                {errors.walletName && <Danger><small>{errors.walletName}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!errors.walletName}>Next</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  createWalletHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateWallet)