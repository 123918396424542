import React, {useCallback} from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useLocation } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import AirtimeDataIcon from "../../../assets/img/airtime_data.svg";


const useStyles = makeStyles(themeStyle);

export function VASViewCategories(props) {
  const vasDetail = useLocation().state?.vasDetail;
  const vasType = useLocation().state?.vasType;
  const vasProducts = useLocation().state?.vasProducts;
  const selectedNetworkPartner = useLocation().state?.selectedNetworkPartner;
  const history = useHistory();
  const classes = useStyles();

  const backToVASEnterMobile = useCallback(() => {
    history.push({
      pathname: '/pages/vas-enter-mobile',
      state: { vasType, vasDetail, vasProducts, selectedNetworkPartner }
    });
  }, [history, vasType, vasDetail, vasProducts, selectedNetworkPartner]);

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToVASEnterMobile();
      }
    };
  }, [history, backToVASEnterMobile]);

  const redirectToBuyAirtimeData = (selectedVASProducts) => {
    history.push({
      pathname: '/pages/vas-buy-airtime-data',
      state: { vasType, vasDetail, vasProducts, selectedVASProducts, selectedNetworkPartner }
    });
  }

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToVASEnterMobile}></ArrowBackIosIcon>
                <span className={classes.title}>Buy {vasProducts?.partnerName} Airtime or Data</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.textCenter + ' m-b-0'}><b>Select the {vasProducts?.partnerName} Product you would like to buy</b></h4>
        </GridItem>
        {vasProducts?.products.map((product) => {
          return (<GridItem xs={12} sm={12} md={12} key={product?.title}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={event => { redirectToBuyAirtimeData({ title: product?.title, data: product?.data }) }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }} >
                    <div style={{ backgroundImage: `url(${product?.data[0]?.productImageUrl ? product?.data[0]?.productImageUrl : AirtimeDataIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', width: '2.5em' }}></div>
                    <b style={{ textAlign: 'left', margin: '0.5em 0.75em', lineHeight: '1.5em' }}>{product?.title}</b>
                  </h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>)
        })}
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(VASViewCategories)