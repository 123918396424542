import React, { useState, useCallback } from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import { TextField } from "@material-ui/core";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { SpinnerComponent } from "react-element-spinner";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import { primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { v4 as uuidv4 } from 'uuid';
import { PaymentType } from "../../../eclipse-react-sdk/services/models";
import { CreateVASInputControls, ValidateVASInputControls } from "../VASControls.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function VASAccountNumber(props) {
  const pageState = useLocation().state;
  const vasProducts = useLocation().state?.vasProducts;
  const selectedProduct = useLocation().state?.selectedProduct;
  const vasType = useLocation().state?.vasType;

  const history = useHistory();
  const classes = useStyles();
  const [alertData, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ ...CreateVASInputControls(selectedProduct?.additionalFields) });
  const [errors, setErrors] = useState({});

  const goToVASPayBill = (initiatePaymentData) => {
    history.push({
      pathname: '/pages/vas-pay-bill',
      state: { ...pageState, initiatePaymentData: initiatePaymentData, vasDetail: inputs }
    });
  };

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToVASPayBillCategories = useCallback(() => {
    history.push({
      pathname: '/pages/vas-pay-bill-categories',
      state: { ...pageState }
    });
  }, [history, pageState])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToVASPayBillCategories();
      }
    };
  }, [history, backToVASPayBillCategories])

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateVASAccountNumberForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      const paymentsAPI = new DCustomerManagementApi();

      const additionalFields = [];
      if (selectedProduct && selectedProduct.additionalFields && selectedProduct.additionalFields.length > 0) {
        selectedProduct.additionalFields.forEach(control => {
          additionalFields.push({ id: control?.id, value: inputs[control?.id] });
        });
      }

      let paymentInitiateRequestBody = {
        externalUniqueId: uuidv4(),
        description: selectedProduct?.productDescription,
        type: PaymentType.GLOBALVAS,
        currency: 'ZAR',
        paymentData: selectedProduct?.productId.includes('MENU') ? `${vasProducts?.partnerId}_${selectedProduct?.productId}` : `${vasProducts?.partnerId}_MENU-${selectedProduct?.productId}`,
        additionalFields: additionalFields
      }

      paymentsAPI.tenantsTenantIdCustomersCustomerIdPaymentsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, paymentInitiateRequestBody).then((paymentsAPIResponse) => {
        if (paymentsAPIResponse.data && paymentsAPIResponse.data?.paymentId && paymentsAPIResponse.data?.paymentId > 0) {
          goToVASPayBill(paymentsAPIResponse.data);
        } else {
          setLoading(false);
          showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateVASAccountNumberForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateVASAccountNumberForm();
    setErrors(validationErrors);
  }

  const validateVASAccountNumberForm = () => {
    let errors = {}
    errors = ValidateVASInputControls(inputs, selectedProduct?.additionalFields);
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToVASPayBillCategories}></ArrowBackIosIcon>
              <span className={classes.title}>{vasType === 'BUY' ? 'Buy' : ''} {selectedProduct?.productName}</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        {selectedProduct?.additionalFields.length > 0 ?
          <GridItem xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit}>
              {selectedProduct?.additionalFields?.map((control, index) => {
                return (
                  <GridContainer key={index}>
                    <GridItem xs={12} sm={12} md={12}>
                      <b className={classes.regularFontSize}>{control?.title}</b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <ValidationTextField
                        fullWidth
                        label={control?.title}
                        variant="filled"
                        id={control?.id}
                        type="text"
                        error={(errors[control?.id]) ? true : false}
                        name={control?.id}
                        value={inputs[control?.id]}
                        onChange={handleInputChange}
                        onKeyUp={handleInputKeyUp} />
                      {errors[control?.id] && <Danger><small>{errors[control?.id]}</small></Danger>}
                    </GridItem>
                  </GridContainer>
                )
              })}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={Object.keys(errors).length === 0 ? false : true}>Next</Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
          : null}
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(VASAccountNumber)