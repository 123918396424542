import React, { useState } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import getSymbolFromCurrency from 'currency-symbol-map'
import 'react-multi-carousel/lib/styles.css';
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.js";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import FormattedAmount from "../../../formattedAmount.js";
import { AmountTextField } from "../../../components/Amount/AmountTextField.js";
import { checkAmount } from "../../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function ViaCard(props) {
  const walletDetailData = useLocation().state?.walletDetailData;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
  const amountOptions = pwaAppOptions?.cashInOutLimits?.cashIn;

  const fromScreen = useLocation().state.fromScreen;
  const linkedBankCards = useLocation().state.linkedBankCards;

  const history = useHistory();
  const classes = useStyles();

  const [inputs, setInputs] = useState({ toWallet: walletDetailData?.walletId, bankCard: '', amount: '', reference: '' });
  const [errors, setErrors] = useState({});
  const [pastedAmount, setPastedAmount] = useState(0);

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData, 'PASTE');
    inputs['amount'] = formattedData;
    if (formattedData > 0) {
      setPastedAmount(inputs?.amount);
    }
    const validationErrors = validateTopupForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if(pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      }else{
        inputs['amount'] = FormattedAmount(event.target.value);
      }
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateTopupForm(inputs);
    setErrors(validationErrors);
  }

  const goToManageBankCards = () => {
    history.push({
      pathname: '/pages/manage-linked-cards',
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen, linkedBankCards: linkedBankCards }
    });
  };

  const linkNewBankCard = () => {
    history.push({
      pathname: '/pages/card-on-file',
      state: { walletDetailData: walletDetailData, fromScreen: 'TOPUP' }
    });
  };

  const handleInputKeyUp = (event) => {
    event.persist();
    if(event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateTopupForm(inputs);
    setErrors(validationErrors);
  };

  const validateTopupForm = (inputs) => {
    const errors = {}
    if (!inputs.toWallet) {
      errors.toWallet = `To ${walletTitleText?.toLowerCase()} required!`
    } 

    if (!inputs.bankCard) {
      errors.bankCard = 'Bank Card required!'
    }

    if (!inputs.amount) {
      errors.amount = 'Amount required!'
    } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
      errors.amount = 'Invalid amount!'
    } else if (checkAmount(amountOptions?.topUpViaCard, +inputs.amount)) {
      errors.amount = `${amountOptions?.topUpViaCard?.error}`;
    }

    if (!inputs.reference) {
      errors.reference = 'Payment reference required!'
    } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{3,}$/i.test(inputs.reference)) {
      errors.reference = 'Invalid payment reference!'
    }

    return errors
  }

  const backToTopup = () => {
    history.push({
      pathname: '/pages/topup',
      state: { walletDetailData: walletDetailData }
    });
  }

  const confirmTopup = (inputs) => {
    const selectedBankCard = linkedBankCards.filter(bankCard => bankCard.cardOnFileId === inputs.bankCard)[0];
    history.push({
      pathname: '/pages/confirm-via-card',
      state: { selectedBankCard: selectedBankCard, walletDetailData: walletDetailData, topupData: inputs, linkedBankCards: linkedBankCards }
    });
  }

  const handleTopupFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateTopupForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      confirmTopup(inputs);
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToTopup}></ArrowBackIosIcon>
              <span className={classes.title}>Top-up with a linked bank card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>Which card would you like to use?</b></p>
          </GridItem>
          <form onSubmit={handleTopupFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                fullWidth
                label="Select card"
                select
                variant="filled"
                id="bankCard"
                type="text"
                error={(errors.bankCard) ? true : false}
                name="bankCard"
                value={inputs.bankCard}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} >
                {linkedBankCards?.map((bankCard) => {
                  return (<MenuItem key={bankCard?.cardOnFileId} value={bankCard?.cardOnFileId}>{bankCard?.alias + ' - **' + bankCard?.last4Digits}</MenuItem>)
                })}
              </ValidationTextField>
              {errors.bankCard && <Danger><small>{errors.bankCard}</small></Danger>}
              <div className="external-links" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px' }}>
                <span onClick={linkNewBankCard} style={{ display: 'flex', margin: '0 10px 0 0' }} className={classes.linkColor + ' ' + classes.regularFontSize}><AddCircleOutlineOutlined />&nbsp;<b>Add a card</b></span>
                <span onClick={goToManageBankCards} style={{ display: 'flex', margin: '0 0 0 10px' }} className={classes.linkColor + ' ' + classes.regularFontSize}><PaymentIcon />&nbsp;<b>Manage my cards</b></span>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h6 className={classes.regularFontSize} style={{ margin: '10px 0', textTransform: 'capitalize' }}><b>To</b></h6>
              <ValidationTextField
                disabled
                fullWidth
                label={`To ${walletTitleText?.toLowerCase()}`}
                select
                variant="filled"
                id="toWallet"
                type="number"
                error={(errors.toWallet) ? true : false}
                name="toWallet"
                value={inputs.toWallet}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}>
                <MenuItem value={walletDetailData?.walletId}>{walletDetailData?.name}({getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.availableBalance.toFixed(2)})</MenuItem>
              </ValidationTextField>
              {errors.toWallet && <Danger><small>{errors.toWallet}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h6 className={classes.regularFontSize} style={{ margin: '10px 0', textTransform: 'capitalize' }}><b>How much do you want to top-up your {walletTitleText?.toLowerCase()}?</b></h6>
              <AmountTextField
                {...props?.appData}
                inputs={inputs}
                errors={errors}
                walletDetailData={walletDetailData}
                onPaste={handleInputAmountPaste}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                fullWidth
                label="Payment reference"
                variant="filled"
                id="reference"
                type="text"
                error={(errors.reference) ? true : false}
                name="reference"
                value={inputs.reference}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
              {errors.reference && <Danger><small>{errors.reference}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={(errors.toWallet || errors.bankCard || errors.amount || errors.reference) ? true : false}>Next</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ViaCard)