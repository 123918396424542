import React, { useCallback } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { ReactComponent as NoCardIcon } from "../../assets/img/visaIcon.svg"
import { secondaryTextColor } from "../../style.scss"
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function NoCardLinked(props) {
  const walletDetailData = useLocation().state?.walletDetailData;

  const history = useHistory();
  const classes = useStyles();

  const linkCard = () => {
    history.push({
      pathname: '/pages/card-on-file',
      state: { walletDetailData, fromScreen:'no-card-linked'}
    });
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.noCardLinkedHandler(appData);
    history.replace({
      pathname: '/pages/dashboard',
      fromScreen: 'NO_CARDS'
    });
  }, [history, props])

  React.useEffect(() => {
    return () => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIcon className={classes.linkColor} onClick={backToHome}/>
              <span className={classes.title}>Link a bank card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer> 
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', padding: "12px" }}>
              <NoCardIcon/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "3em"}}>
              <h5 className={classes.textCenter + ' m-t-0 m-b-0'}><b>You don’t have a linked bank card yet.</b></h5>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}
                 style={{ color: secondaryTextColor, fontWeight:"normal" }}>
                Securely link any bank card to pay QR codes.</p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                      onClick={linkCard}>Link my bank card</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  noCardLinkedHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NoCardLinked)