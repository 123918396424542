import React, { useState, useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.js";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { WalletTopupType } from "../../../eclipse-react-sdk/services/models";
import { KWalletTopupsApi } from "eclipse-react-sdk/services";
import { v4 as uuidv4 } from 'uuid';
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { primaryColor } from "../../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import FormattedAmount from "../../../formattedAmount.js";
import { AmountTextField } from "../../../components/Amount/AmountTextField.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function TopupRetailerAmount(props) {
  const depositAmountAtRetailerInstruction = props?.appData?.template?.depositAmountAtRetailerInstruction;
  const retailers = [{ code: WalletTopupType?.ZAPNPCASH, description: 'Pick n Pay' }];
  const walletDetailData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const [inputs, setInputs] = useState({ retailer: '', amount: '', idPassport: '' });
  const [errors, setErrors] = useState({});
  const [pastedAmount, setPastedAmount] = useState(0);

  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = React.useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const validateRetailerForm = (inputs) => {
    const errors = {}

    if (!inputs.amount) {
      errors.amount = 'Amount required!'
    } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
      errors.amount = 'Amount invalid!'
    }

    if (!inputs.retailer) {
      errors.retailer = 'Retailer required!'
    } else if (!/^[_A-Z]{3,}$/i.test(inputs.retailer)) {
      errors.retailer = 'Invalid Retailer!'
    }


    if (!inputs.idPassport) {
      errors.idPassport = 'ID/Passport number required!'
    } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{4,}$/i.test(inputs.idPassport)) {
      errors.idPassport = 'Invalid ID/Passport number!'
    }

    return errors
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    if(event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateRetailerForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData, 'PASTE');
    inputs['amount'] = formattedData;
    if (formattedData > 0) {
      setPastedAmount(inputs?.amount);
    }
    const validationErrors = validateRetailerForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if(pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      }else{
        inputs['amount'] = FormattedAmount(event.target.value);
      }
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateRetailerForm(inputs);
    setErrors(validationErrors);
  }

  const backToWalletTopUp = () => {
    history.push({
      pathname: `/pages/topup`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }

  const generateTopupRetailerToken = useCallback((topupRetailerTokenData) => {
    history.push({
      pathname: '/pages/topup-retail-token',
      state: { topupRetailerData: inputs, topupRetailerTokenData: topupRetailerTokenData, walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  },[history, fromScreen, inputs, walletDetailData]);

  const doTopupAtRetailer = useCallback(() => {
    setLoading(true);
    const walletTopupsAPI = new KWalletTopupsApi();
    const topupAtRetailerRequestBody = {
      externalUniqueId: uuidv4(),
      amount: inputs?.amount,
      type: WalletTopupType?.ZAPNPCASH
    }
    walletTopupsAPI.tenantsTenantIdWalletsWalletIdTopupsPost(props?.appData?.auth?.tenantId, walletDetailData?.walletId, topupAtRetailerRequestBody).then((walletTopupsAPIResponse) => {
      setLoading(false);
      if (walletTopupsAPIResponse.data && walletTopupsAPIResponse.data?.topupId && walletTopupsAPIResponse.data?.completionToken) {
        generateTopupRetailerToken(walletTopupsAPIResponse.data);
      } else {
        showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, inputs, redirectToErrorScreen, walletDetailData, generateTopupRetailerToken]);

  const handleEFTFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateRetailerForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      const errors = {};
      if (+inputs.amount < depositAmountAtRetailerInstruction?.min) {
        errors.amount = `The minimum amount is ${getSymbolFromCurrency(walletDetailData?.currency)}${depositAmountAtRetailerInstruction?.min.toFixed(2)}. Please increase your amount.`
        setErrors(errors);
      } else if (+inputs.amount > depositAmountAtRetailerInstruction?.max) {
        errors.amount = `The maximum amount is ${getSymbolFromCurrency(walletDetailData?.currency)}${depositAmountAtRetailerInstruction?.max.toFixed(2)}. Please decrease your amount.`
        setErrors(errors);
      } else {
        doTopupAtRetailer();
      }
    }
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletTopUp}/>
              <span className={classes.title}>Top-up at a retail store</span>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' m-b-0'}>
                <i>Top up your {walletTitleText.toLowerCase()} at Pick n Pay. You will need your valid ID/passport and the token number we will SMS to you.</i>
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.regularFontSize + ' m-b-0 m-t-0'}><b>How much would you like to top up?</b></p>
          </GridItem>
          <form onSubmit={handleEFTFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <AmountTextField
                {...props?.appData}
                inputs={inputs}
                errors={errors}
                walletDetailData={walletDetailData}
                onPaste={handleInputAmountPaste}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                fullWidth
                label="Select a retailer"
                select
                variant="filled"
                id="retailer"
                type="text"
                error={(errors.retailer) ? true : false}
                name="retailer"
                value={inputs.retailer}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}>
                {retailers?.map((retailer) => {
                  return (<MenuItem key={retailer?.code} value={retailer?.code}>{retailer?.description}</MenuItem>)
                })}
              </ValidationTextField>
              {errors.retailer && <Danger><small>{errors.retailer}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                inputProps={{ maxLength: 20 }}
                fullWidth
                label="Your ID/Passport number"
                variant="filled"
                id="idPassport"
                type="text"
                error={(errors.idPassport) ? true : false}
                name="idPassport"
                value={inputs.idPassport}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
              {errors.idPassport && <Danger><small>{errors.idPassport}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={(errors.retailer || errors.amount || errors.idPassport) ? true : false}>Continue</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
      { alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TopupRetailerAmount)
