import {
  infoColor,
  dangerColor,
  hexToRgb
} from "../../eclipse.js";
import {primaryColor} from "../../../../style.scss"

const formStyle = {
  root: {
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: infoColor[0],
      },
      '&.Mui-error': {
        color: infoColor[0]
      }
    },
    '& .MuiFilledInput-underline:before': {
      border: 'none'
    },
    '& .MuiFilledInput-underline:after': {
      border: 'none'
    },
    '& .MuiFilledInput-root': {
      background: 'none',
      border: '1px solid',
      borderColor: infoColor[0],
      borderRadius: '5px',
      '&:hover': {
        borderColor: primaryColor
      },
      '&.Mui-focused': {
        borderColor: primaryColor,
        background: 'rgba(' + hexToRgb('#fff') + ', 0.05)',
      },
      '&.Mui-error': {
        borderColor: dangerColor[0]
      },
    }
  },
};

export default formStyle;
