export const DEV = {
    BASE_API_URL: "https://eclipse-java-develop.ukheshe.rocks/eclipse-conductor/rest/v1",
    GRECAPTCHA: '6LcC1TsbAAAAAPMjIqd15RuM4JojfeCb_XBHstJW',
    BACKDRAFT_URL: "https://backdraft.ukheshe.rocks/rest/v1/endpoints?service=eclipse"
}

export const SANDBOX = {
    BASE_API_URL: "https://eclipse-java-sandbox.ukheshe.rocks/eclipse-conductor/rest/v1",
    GRECAPTCHA: '6LcC1TsbAAAAAPMjIqd15RuM4JojfeCb_XBHstJW',
    BACKDRAFT_URL: "https://backdraft.ukheshe.rocks/rest/v1/endpoints?service=eclipse"
}


//Going forward, QA will be connected with Dev-Back-end
export const QA = {
    BASE_API_URL: "https://eclipse-java-develop.ukheshe.rocks/eclipse-conductor/rest/v1",
    GRECAPTCHA: '6LcC1TsbAAAAAPMjIqd15RuM4JojfeCb_XBHstJW',
    BACKDRAFT_URL: "https://backdraft.ukheshe.rocks/rest/v1/endpoints?service=eclipse"
}

export const PROD = {
    BASE_API_URL: "https://ukheshe.live/eclipse-conductor/rest/v1",
    GRECAPTCHA: '6LcC1TsbAAAAAPMjIqd15RuM4JojfeCb_XBHstJW',
    BACKDRAFT_URL: "https://backdraft.ukheshe.rocks/rest/v1/endpoints?service=eclipse"
}

export function EnvConfig() {
    let envConfig = SANDBOX;
    switch (window.REACT_APP_ENV) {
        case 'prod':
            envConfig = PROD;
            break;
        case 'sandbox':
            envConfig = SANDBOX;
            break;
        case 'qa':
            envConfig = QA;
            break;
        default:
            envConfig = SANDBOX;
            break;
    }
    return envConfig;
}