import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import 'react-multi-carousel/lib/styles.css';
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import { primaryColor } from "../../style.scss";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../components/Typography/Danger.js";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { AAuthenticationApi, HWalletsApi } from "eclipse-react-sdk/services";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import ReturnMobileNumberWithISD from "../../views-auth/returnMobileNumberWithISD.js";
import FormattedAmount from "../../formattedAmount.js";
import { AmountTextField } from "../../components/Amount/AmountTextField.js";
import { checkAmount } from "../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function Transfer(props) {
  const walletDetailData = useLocation().state?.walletDetailData;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const defaultMobileNumberRegEx = props?.appData?.template?.defaultMobileNumberRegEx;
  const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
  const amountOptions = pwaAppOptions?.cashInOutLimits?.cashOut;
  
  const fromScreen = useLocation().state.fromScreen;
  const action = useLocation().state.action;
  const otherWallets = props?.appData?.wallets?.filter(wallet => wallet?.walletId !== walletDetailData?.walletId);
  const history = useHistory();
  const classes = useStyles();

  let [inputs, setInputs] = useState({
    fromWallet: walletDetailData?.walletId,
    toWallet: '',
    amount: '',
    reference: ''
  });
  const [errors, setErrors] = useState({});
  const [pastedAmount, setPastedAmount] = useState(0);
  const whatsAppDefaultISDCode = props?.appData?.template?.whatsAppDefaultISDCode;

  const returnMobileNumberWithISD = () => {
    return ReturnMobileNumberWithISD(whatsAppDefaultISDCode, inputs?.toWallet);
  }

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData, 'PASTE');
    inputs['amount'] = formattedData;
    if (formattedData > 0) {
      setPastedAmount(inputs?.amount);
    }
    const validationErrors = validateTransferForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if (pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      } else {
        inputs['amount'] = FormattedAmount(event.target.value);
      }
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateTransferForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    if(event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateTransferForm(inputs);
    setErrors(validationErrors);
  }

  const validateTransferForm = (inputs) => {
    const pattern = new RegExp(defaultMobileNumberRegEx);
    const errors = {}
    if (!inputs.fromWallet) {
      errors.fromWallet = `From ${walletTitleText.toLowerCase()} required!`
    } else if (!/^[0-9]{1,}$/i.test(inputs.fromWallet)) {
      errors.fromWallet = `Invalid from ${walletTitleText.toLowerCase()}!`
    }

    if (!inputs.toWallet) {
      errors.toWallet = `To ${walletTitleText.toLowerCase()} required!`
    } else if (action === 'TRANSFER' && !/^[0-9a-zA-Z]{1,}$/i.test(inputs.toWallet)) {
      errors.toWallet = `Invalid to ${walletTitleText.toLowerCase()}!`
    } else if (action !== 'TRANSFER' && !pattern.test(inputs.toWallet)) {
      errors.toWallet = `Invalid Phone number!`
    }

    if (!inputs.amount) {
      errors.amount = 'Amount required!'
    } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
      errors.amount = 'Invalid amount!'
    } else if (checkAmount(amountOptions?.transfer, +inputs.amount)) {
      errors.amount = `${amountOptions?.transfer?.error}`;
    } else if (inputs.amount > walletDetailData.availableBalance) {
      errors.amount = `Invalid amount! it must be maximum ${walletDetailData.availableBalance}`
    }

    if (!inputs.reference) {
      errors.reference = 'Payment reference required!'
    } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{3,}$/i.test(inputs.reference)) {
      errors.reference = 'Invalid payment reference!'
    }
    return errors
  }

  const backToWallet = useCallback(() => {
    history.push({
      pathname: fromScreen === "transact" ? '/pages/transact' : '/pages/wallets-detail',
      state: { walletDetailData }
    });
  }, [history, walletDetailData, fromScreen])

  const confirmTransfer = (transferData) => {
    history.push({
      pathname: action === 'TRANSFER' ? '/pages/confirm-transfer' : "/pages/confirm-send-money",
      state: { walletDetailData, transferData, fromScreen, action }
    });
  }

  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const getWalletDetails = (transferType, tenantHeader) => {
    setLoading(true);
    const httpRequestConfiguration = HttpRequestConfiguration(tenantHeader?.Authorization);
    const getWalletsAPI = new HWalletsApi(httpRequestConfiguration);
    const toWalletId = transferType === 'WALLET_ID' ? inputs?.toWallet : undefined;
    const toWalletFriendlyId = transferType === 'WALLET_FRIENDLY_ID' ? inputs?.toWallet : undefined;
    const toMobileNumber = transferType === 'MOBILE_NUMBER' ? returnMobileNumberWithISD() : undefined;
    getWalletsAPI.tenantsTenantIdWalletsGet(props?.appData?.auth?.tenantId, undefined, undefined, undefined, toMobileNumber, toWalletFriendlyId, toWalletId).then((walletAPIResponse) => {
      let closeLoopDigitalWallets = [];
      if (walletAPIResponse && walletAPIResponse?.data && walletAPIResponse?.data.length > 0) {
        if (transferType === 'MOBILE_NUMBER') {
          closeLoopDigitalWallets = walletAPIResponse?.data?.filter(wallet => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId) || [];
        } else {
          closeLoopDigitalWallets = walletAPIResponse?.data;
        }
      }
      if (closeLoopDigitalWallets.length > 0) {
        inputs = { ...inputs, transferType, toMobileNumber:inputs?.toWallet }
        inputs.toWallet = closeLoopDigitalWallets[0]?.walletId;
        inputs.selectedWallet = closeLoopDigitalWallets[0];
        confirmTransfer(inputs);
      } else {
        setLoading(false);
        showAlert(true, 'ERROR', `Recipient's ${walletTitleText.toLowerCase()} not found! Please try other recipient's ${walletTitleText.toLowerCase()}.`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const getTenantToken = (transferType) => {
    setLoading(true);
    const loginAPI = new AAuthenticationApi();
    const tenant = {}
    tenant['identity'] = props?.appData?.template?.tenantUsername;
    tenant['password'] = props?.appData?.template?.tenantPassword;

    loginAPI.authenticationLoginPost(tenant).then((authAPIResponse) => {
      const headers = { Authorization: authAPIResponse?.data?.headerValue };
      getWalletDetails(transferType, headers);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  };

  const handleTransferFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateTransferForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      let transferType;
      if (action === 'TRANSFER') {
        if (isNaN(+inputs?.toWallet)) {
          transferType = 'WALLET_FRIENDLY_ID';
        } else {
          transferType = 'WALLET_ID'
        }
      } else {
        transferType = 'MOBILE_NUMBER';
      }
      if (transferType === 'MOBILE_NUMBER' && inputs?.toWallet.includes(props?.appData?.customer?.phone1.substr(2))) {
        showAlert(true, 'ERROR', `Oops! You can't transfer money to your own phone number!`);
        return
      }
      if (transferType === 'WALLET_FRIENDLY_ID' && inputs?.toWallet === walletDetailData?.friendlyId) {
        showAlert(true, 'ERROR', `Oops! You can't transfer money to same ${walletTitleText.toLowerCase()} which you have selected as From ${walletTitleText.toLowerCase()}!`);
        return
      }
      if (transferType === 'WALLET_ID' && +inputs?.toWallet === walletDetailData?.walletId) {
        showAlert(true, 'ERROR', `Oops! You can't transfer money to same ${walletTitleText.toLowerCase()} which you have selected as From ${walletTitleText.toLowerCase()}!`);
        return
      }
      if (transferType !== 'WALLET_ID') {
        getTenantToken(transferType);
      } else {
        inputs.selectedWallet = otherWallets.find(wallet => wallet.walletId === +inputs?.toWallet)
        confirmTransfer(inputs);
      }
    }
  }
  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToWallet();
      }
    };
  }, [history, backToWallet])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWallet}/>
              <span className={classes.title}>
                {fromScreen === 'TOPUP' ? 'Topup ' : ''}
                {fromScreen === 'WITHDRAWAL' ? 'Withdrawal ' : ''}
                {action === "TRANSFER" ? `${walletTitleText} to ${walletTitleText.toLowerCase()} transfer` : "Send Money"}
              </span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleTransferFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <h6 className={classes.regularFontSize} style={{ margin: '0.5em 0', textTransform: 'inherit' }}>
                <b>From</b></h6>
              <h4 style={{ margin: '0.5em 0', textTransform: 'inherit' }}>
                <b>{`${walletDetailData.name} - ${walletDetailData.friendlyId}`}</b>
              </h4>
              <p className={"m-t-0"}>Available
                balance <b>{`  ${getSymbolFromCurrency(walletDetailData?.currency) + walletDetailData.availableBalance.toFixed(2)}`}</b>
              </p>
            </GridItem>
            {action === "TRANSFER" ?
              <GridItem xs={12} sm={12} md={12}>
                <h6 className={classes.regularFontSize} style={{ margin: '0.5em 0', textTransform: 'inherit' }}><b>
                  Select the recipient's {walletTitleText.toLowerCase()}</b></h6>
                <ValidationTextField
                  fullWidth
                  label={`Select ${walletTitleText.toLowerCase()}`}
                  select
                  variant="filled"
                  id="toWallet"
                  type="text"
                  error={!!(errors.toWallet)}
                  name="toWallet"
                  value={inputs.toWallet || ''}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}>
                  {otherWallets?.map((wallet) => {
                    return (<MenuItem key={wallet?.walletId}
                                      value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(walletDetailData?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                  })}
                </ValidationTextField>
                {errors.toWallet && <Danger><small>{errors.toWallet}</small></Danger>}
              </GridItem>
              :
              <GridItem xs={12} sm={12} md={12}>
                <h6 className={classes.regularFontSize} style={{ margin: '0.5em 0', textTransform: 'inherit' }}><b>Enter
                  the recipient's mobile number</b></h6>
                <ValidationTextField
                  fullWidth
                  label={"Recipient's mobile number"}
                  variant="filled"
                  id="toWallet"
                  type="text"
                  error={!!(errors.toWallet)}
                  name="toWallet"
                  value={inputs.toWallet || ''}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.toWallet && <Danger><small>{errors.toWallet}</small></Danger>}
              </GridItem>
            }
            <GridItem xs={12} sm={12} md={12}>
              <h6 className={classes.regularFontSize} style={{ margin: '10px 0', textTransform: 'inherit' }}><b>With how
                much would you like to transfer?</b></h6>
              <AmountTextField
                {...props?.appData}
                inputs={inputs}
                errors={errors}
                walletDetailData={walletDetailData}
                onPaste={handleInputAmountPaste}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                fullWidth
                label="Payment reference"
                variant="filled"
                id="reference"
                type="text"
                error={!!(errors.reference)}
                name="reference"
                value={inputs.reference}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}/>
              {errors.reference && <Danger><small>{errors.reference}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      disabled={!!(errors.fromWallet || errors.toWallet || errors.amount || errors.reference)}>Next</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  )
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(Transfer)