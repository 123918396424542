import React, { useState, useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useLocation } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import PayBillsIcon from "../../../assets/img/pay_bills.svg";
import { PVASFunctionalityApi } from "eclipse-react-sdk/services";
import { primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { SpinnerComponent } from "react-element-spinner";
import { loginToApp } from "../../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function VASPayBillCategories(props) {
  const vasType = useLocation().state?.vasType;
  const fromScreen = useLocation().state?.fromScreen;
  const vasConfig = props?.appData?.template?.vas;
  let [vasProducts, setVASProducts] = React.useState([]);
  let [vasTitle, setVASTitle] = React.useState(null);
  const [alertData, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.vasPayBillCategoriesHandler(appData);
    history.push({
      pathname: `/pages/${fromScreen ? fromScreen : 'dashboard'}`,
    });
  }, [history, props, fromScreen])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  const redirectToVASPayBillCategories = (selectedVASProducts) => {
    let productCategory = selectedVASProducts?.data[0].productCategory;
    const categoryHierarchy = productCategory.split('|');
    if (categoryHierarchy && categoryHierarchy.length > 1) {
      productCategory = categoryHierarchy[0];
    }
    if (categoryHierarchy.length === 1 && selectedVASProducts?.data.length === 1) {
      history.push({
        pathname: '/pages/vas-enter-account',
        state: { fromScreen: fromScreen, vasType: vasType, vasProducts: vasProducts, selectedVASProducts: selectedVASProducts, selectedProduct: selectedVASProducts?.data[0] }
      });
    } else {
      setVASTitle(productCategory);
      let products = [];
      if (categoryHierarchy && categoryHierarchy.length > 1) {
        products = getProductsDataCategoryWise(selectedVASProducts?.data, productCategory);
      } else {
        selectedVASProducts.data.forEach(product => {
          products.push({ title: product?.productName, data: [product] })
        });
      }
      if (products && products.length > 0) {
        vasProducts = {
          ...vasProducts,
          products: products
        }
        setVASProducts(vasProducts);
      }
    }
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const getProductsDataCategoryWise = useCallback((products, categoryMenu) => {
    const productsGroups = {};
    let sectionGroups = [];
    const convertedProducts = [];
    products.forEach((product) => {
      if (product?.productCategory.includes(categoryMenu)) {
        const categoryHierarchy = product?.productCategory.split('|');
        product.productCategory = product?.productCategory.replace(`${categoryMenu}|`, '');
        if (categoryHierarchy && categoryHierarchy.length > 0) {
          categoryHierarchy.splice(0, 1);
        }

        const categoryImageHierarchy = product?.productCategoryImageUrl.split('|');
        if (categoryImageHierarchy && categoryImageHierarchy.length > 0) {
          product.productCategoryImageUrl = product?.productCategoryImageUrl.replace(`${categoryImageHierarchy[0]}|`, '');
        }

        convertedProducts.push({
          ...product,
          productCategory: categoryHierarchy.length === 1 ? product?.productName : product?.productCategory,
          productCategoryImageUrl: categoryImageHierarchy.length === 1 ? product?.productImageUrl : product?.productCategoryImageUrl
        })
      }
    })

    convertedProducts.forEach((product) => {
      let productCategory = product?.productCategory;
      const categoryImageHierarchy = productCategory.split('|');
      if (categoryImageHierarchy && categoryImageHierarchy.length > 0) {
        productCategory = categoryImageHierarchy[0];
      }

      let productImageUrl = product?.productCategoryImageUrl;
      const productCategoryImageUrlHierarchy = productImageUrl.split('|');
      if (productCategoryImageUrlHierarchy && productCategoryImageUrlHierarchy.length > 0) {
        product.productImageUrl = productCategoryImageUrlHierarchy[0];
      }

      if (productCategory in productsGroups) {
        productsGroups[productCategory].push(product);
      } else {
        productsGroups[productCategory] = new Array(product);
      }
    });

    var productsGroupsKeys = Object.keys(productsGroups);
    productsGroupsKeys.forEach((productKey) => {
      sectionGroups.push(
        {
          title: productKey,
          data: productsGroups[productKey]
        }
      )
    });

    return sectionGroups;
  }, [])

  const getVASPayBillServices = useCallback(() => {
    setLoading(true);
    const vasAPI = new PVASFunctionalityApi();
    vasAPI.tenantsTenantIdVasCatalogsGet(props?.appData?.auth?.tenantId).then((vasAPIResponse) => {
      if (vasAPIResponse?.data && vasAPIResponse?.data.length > 0) {
        let partnerId = vasConfig?.partners?.masterpass?.id;;
        const partnerDetail = vasAPIResponse?.data.filter(partner => partner?.partnerId === partnerId);
        if (partnerDetail.length > 0 && partnerDetail[0].products && partnerDetail[0].products.length > 0) {
          const categoryMenu = vasType === 'BUY' ? vasConfig?.buyElectricityWaterMenu?.category : vasConfig?.payBillMenu?.category;
          partnerDetail[0].products = getProductsDataCategoryWise(partnerDetail[0].products, categoryMenu);
          if (partnerDetail[0].products && partnerDetail[0].products.length > 0) {
            setVASProducts(partnerDetail[0]);
            setLoading(false);
            return;
          }
        }
      }
      setLoading(false);
      showAlert(true, 'ERROR', `Value added services not found! Please try again.`);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, getProductsDataCategoryWise, vasConfig, vasType]);

  React.useEffect(() => {
    if (vasProducts.length < 1) {
      getVASPayBillServices();
    }
  }, [vasProducts, getVASPayBillServices]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome} />
                <span className={classes.title}>{vasType === 'BUY' ? (vasTitle ? vasTitle : vasConfig?.buyElectricityWaterMenu?.title) : (vasTitle ? vasTitle : vasConfig?.payBillMenu?.title)}</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.textCenter + ' m-b-0 m-t-0'}>
            <b>{vasType === 'BUY' ? 'What would you like to buy?' : 'Select a provider'}</b>
          </h4>
        </GridItem>
        {vasProducts?.products?.map((product, index) => {
          return (
            <GridItem xs={12} sm={12} md={12} key={index}>
              <Card style={{ margin: '0' }}>
                <ButtonBase onClick={event => { redirectToVASPayBillCategories(product) }} style={{ justifyContent: 'flex-start' }}>
                  <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }} >
                      <div style={{ backgroundImage: `url(${product?.data[0]?.productImageUrl ? product?.data[0]?.productImageUrl : PayBillsIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', width: '2.5em', minWidth: '2.5em' }}></div>
                      <b style={{ textAlign: 'left', margin: '0.5em 0.75em', lineHeight: '1.5em' }}>{product?.title}</b>
                    </h5>
                  </CardContent>
                </ButtonBase>
              </Card>
            </GridItem>
          )
        })}
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasPayBillCategoriesHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASPayBillCategories)