import React, { useCallback, useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { ReactComponent as CardCancelSuccess } from "../../assets/img/card_cancel_success.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import getSymbolFromCurrency from 'currency-symbol-map';
import TextField from '@material-ui/core/TextField';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import Danger from "../../components/Typography/Danger.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { SpinnerComponent } from "react-element-spinner";
import {
  CardOnFileStatus,
  CardStatus,
  EclipseStatusReason,
  WalletStatus,
  WalletWithdrawalType
} from "eclipse-react-sdk/services/models";
import {
  DCustomerManagementApi,
  HWalletsApi,
  ICardManagementApi,
  LWalletWithdrawalsApi,
  MWalletTransfersApi
} from "eclipse-react-sdk/services";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as ConfirmTransactionIcon } from "../../assets/img/journey_transfer.svg";

const ValidationTextField = withStyles(formStyle)(TextField);

const useStyles = makeStyles(themeStyle);

export function ConfirmCancelCard(props) {
  let customer = props?.appData?.customer;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const wallets = props?.appData?.wallets?.filter((wallet) => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId);
  const history = useHistory();
  const pageState = useLocation().state;
  const [errors, setErrors] = useState({});
  const walletDetailData = pageState?.walletDetailData;
  const cardOnFileCard = pageState?.cardOnFileCard;
  const eftData = pageState?.eftData;
  const bankDetailData = pageState?.bankDetailData;
  // const fromScreen = pageState?.fromScreen;
  const bankData = pageState?.bankData;
  const appName = pageState?.appName;
  const classes = useStyles();
  const [inputs, setInputs] = useState({ withdrawalType: '', toWallet: '' });
  const [alertData, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedTransferWallet, setSelectedTransferWallet] = useState(wallets[0]);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    if (event.target.name !== 'toWallet') {
      inputs.toWallet = '';
    }
    setInputs(inputs);
    const validationErrors = validateWalletForm();
    setErrors(validationErrors);
    setSelectedTransferWallet(inputs?.withdrawalType === 'WALLET' ? wallets?.filter(wallet => wallet?.walletId === inputs?.toWallet)[0] : wallets[0]);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateWalletForm();
    setErrors(validationErrors);
  }

  const goToSuccessCancelCard = useCallback((newWalletDetailData) => {
    history.push({
      pathname: '/pages/card-transfer-success',
      state: { ...pageState, ...inputs, selectedTransferWallet, newWalletDetailData }
    });
  }, [history, pageState, inputs, selectedTransferWallet])

  const backToCancelCard = useCallback(() => {
    history.push({
      pathname: '/pages/cancel-card',
      state: { ...pageState }
    });
  }, [history, pageState])

  React.useEffect(() => {
    window.onpopstate= (e) => {
      e.preventDefault();
      backToCancelCard();
    };
  }, [backToCancelCard])

  const validateWalletForm = () => {
    let errors = {}

    if (+walletDetailData?.availableBalance > 0) {
      if (!inputs?.withdrawalType || !/^[0-9a-zA-Z_]{1,}$/i.test(inputs?.withdrawalType)) {
        errors.withdrawalType = `Kindly select any one option.`;
        return errors;
      }

      if (inputs?.withdrawalType === 'WALLET') {
        if (!inputs?.toWallet) {
          errors.toWallet = `To ${walletTitleText?.toLowerCase()} required!`;
          return errors;
        } else if (!/^[0-9a-zA-Z-]{1,}$/i.test(inputs?.toWallet)) {
          errors.toWallet = `Invalid to ${walletTitleText?.toLowerCase()}!`;
          return errors;
        }
      }
    }
    return errors;
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history])

  const doTransferBackToNewCardWallet = useCallback((newWalletDetailData) => {
    const walletTransferAPI = new MWalletTransfersApi();
    const createTransferWalletBody = {
      amount: walletDetailData?.availableBalance,
      description: 'Transfer back from temp transfer wallet',
      externalUniqueId: uuidv4(),
      fromWalletId: selectedTransferWallet?.walletId,
      toWalletId: newWalletDetailData?.walletId
    }
    walletTransferAPI.tenantsTenantIdWalletsTransfersPost(props?.appData?.auth?.tenantId, createTransferWalletBody).then(() => {
      goToSuccessCancelCard(newWalletDetailData);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [goToSuccessCancelCard, props, redirectToErrorScreen, selectedTransferWallet, walletDetailData]);

  const registerNewWalletCardOnCardOnFile = useCallback((newWalletDetailData, newWalletCardDetailData) => {
    const expiryMonth = `${new Date(newWalletCardDetailData?.expires).getMonth()}`;
    const expiryYear = `${new Date(newWalletCardDetailData?.expires).getFullYear()}`;
    const expiryDate = `${expiryMonth.padStart(2, 0)}${expiryYear.substr(2, 2)}`;
    const registerWalletCardsAPI = new DCustomerManagementApi();
    const cardOnFileRequestBody = {
      alias: `${newWalletDetailData?.name}`,
      cardData: {
        accountType: '',
        cardOnFileId: uuidv4(),
        cardholderName: `${props?.appData?.customer?.title || ''} ${props?.appData?.customer?.firstName || ''} ${props?.appData?.customer?.middleName || ''} ${props?.appData?.customer?.lastName || ''}`,
        cvv: newWalletCardDetailData?.cvv,
        dob: props?.appData?.customer?.dateOfBirth,
        expiry: expiryDate,
        pan: newWalletCardDetailData?.pan
      },
      cardId: newWalletCardDetailData?.cardId,
    }
    registerWalletCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFilePost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, cardOnFileRequestBody).then(() => {
      doTransferBackToNewCardWallet(newWalletDetailData);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [doTransferBackToNewCardWallet, props, redirectToErrorScreen])

  const getNewWalletCards = useCallback((newWalletDetailData) => {
    const walletCardsAPI = new HWalletsApi();
    walletCardsAPI.tenantsTenantIdWalletsWalletIdCardsGet(props?.appData?.auth?.tenantId, newWalletDetailData?.walletId).then((walletCardsAPIResponse) => {
      if (walletCardsAPIResponse.data && walletCardsAPIResponse.data.length > 0) {
        registerNewWalletCardOnCardOnFile(newWalletDetailData, walletCardsAPIResponse.data[0]);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, registerNewWalletCardOnCardOnFile])

  const createNewWalletCard = useCallback(() => {
    const customerWalletAPI = new DCustomerManagementApi();
    const createWalletBody = {
      externalUniqueId: uuidv4(),
      name: walletDetailData?.name,
      description: 'Created new wallet via cancel card',
      status: 'ACTIVE',
      walletTypeId: props?.appData?.template?.publicCardWalletTypeId
    }
    customerWalletAPI.tenantsTenantIdCustomersCustomerIdWalletsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, createWalletBody).then((customerWalletAPIResponse) => {
      getNewWalletCards(customerWalletAPIResponse.data);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [getNewWalletCards, props, redirectToErrorScreen, walletDetailData])


  const updateCardOnFile = useCallback(() => {
    // Added this condition to overwrite the null CoF due to the mismatch of Card's PAN's last4digits and CoF's last4digits while card enabled/disabled
    // This CoF will be displayed as it is in Manage Card as well Payment Options drop-down.
    if (!cardOnFileCard) {
      if (inputs?.withdrawalType === 'NEW_CARD') {
        createNewWalletCard();
      } else {
        goToSuccessCancelCard(selectedTransferWallet);
      }
      return;
    }
    setLoading(true);
    const walletCardUpdateAPI = new DCustomerManagementApi();
    const walletCardUpdateRequestBody = {
      alias: cardOnFileCard.alias,
      status: CardOnFileStatus.SUSPENDED
    }
    walletCardUpdateAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileCardOnFileIdPut(props?.appData?.auth?.tenantId, customer.customerId, cardOnFileCard?.cardOnFileId, walletCardUpdateRequestBody).then(() => {
      setLoading(false);
      if (inputs?.withdrawalType === 'NEW_CARD') {
        createNewWalletCard();
      } else {
        goToSuccessCancelCard(selectedTransferWallet);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, customer, cardOnFileCard, createNewWalletCard, goToSuccessCancelCard, inputs, selectedTransferWallet]);

  const updatePrepaidWallet = useCallback(() => {
    const walletUpdateAPI = new HWalletsApi();
    const walletUpdateRequestBody = {
      status: WalletStatus?.CANCELLED
    }
    walletUpdateAPI.tenantsTenantIdWalletsWalletIdPut(props?.appData?.auth?.tenantId, walletDetailData?.walletId, walletUpdateRequestBody).then(() => {
      updateCardOnFile();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, walletDetailData, updateCardOnFile]);

  const updatePrepaidWalletCard = useCallback(() => {
    const walletCardUpdateAPI = new ICardManagementApi();
    const walletCardUpdateRequestBody = {
      status: CardStatus?.CANCELLED,
      reason: pageState?.reasonType === EclipseStatusReason?.FRAUD ? EclipseStatusReason?.FRAUD : EclipseStatusReason?.RETURNED
    }
    walletCardUpdateAPI.tenantsTenantIdCardsCardIdPut(props?.appData?.auth?.tenantId, walletDetailData?.walletCards[0]?.cardId, walletCardUpdateRequestBody).then(() => {
      updatePrepaidWallet();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, updatePrepaidWallet, walletDetailData, pageState]);

  const doWalletTransfer = () => {
    if (inputs?.withdrawalType === 'NEW_CARD') {
      if (!selectedTransferWallet) {
        showAlert(true, 'ERROR', `Oops! Digital wallet not found to do temporary transfer . Kindly contact support.`);
        return;
      }
    }
    const walletTransferAPI = new MWalletTransfersApi();
    const createTransferWalletBody = {
      amount: walletDetailData?.availableBalance,
      description: 'Transfer to Cancel Card',
      externalUniqueId: uuidv4(),
      fromWalletId: walletDetailData?.walletId,
      toWalletId: selectedTransferWallet?.walletId
    }
    walletTransferAPI.tenantsTenantIdWalletsTransfersPost(props?.appData?.auth?.tenantId, createTransferWalletBody).then(() => {
      updatePrepaidWalletCard();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const doEFT = () => {
    setLoading(true);
    const eftWithdrawalsAPI = new LWalletWithdrawalsApi();
    const createEFTBody = {
      accountName: bankData?.accountName,
      accountNumber: bankData?.accountNo,
      amount: eftData?.amount,
      bank: bankDetailData?.name,
      branchCode: bankData?.bankCode,
      description: bankData?.reference,
      reference: bankData?.reference ? bankData?.reference : "Card balance transfer",
      externalUniqueId: uuidv4(),
      type: WalletWithdrawalType.ZANEDBANKEFT
    }
    eftWithdrawalsAPI.tenantsTenantIdWalletsWalletIdWithdrawalsPost(props?.appData?.auth?.tenantId, eftData?.eftWallet, createEFTBody).then(() => {
      setLoading(false);
      updatePrepaidWalletCard();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateWalletForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      if (+walletDetailData?.availableBalance > 0) {
        if (appName === 'Vleissentraal') {
          doEFT();
        } else {
          doWalletTransfer();
        }
      } else {
        updatePrepaidWalletCard();
      }
    }
  }
  const handleEftSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (+walletDetailData?.availableBalance > 0) {
      doEFT();
    } else {
      updatePrepaidWalletCard();
    }
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToCancelCard} />
              <span className={classes.title}>Cancel my card</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {(appName === 'Vleissentraal') ? <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
              <ConfirmTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-b-0 m-t-0'}><b>Your about to make an EFT</b></h4>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Amount</b></p>
              <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}>
                <b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+eftData?.amount).toFixed(2)}</b></h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Bank account
                details</b></p>
              <p
                className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{bankData?.accountName}</p>
              <p
                className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{`${bankDetailData?.name} (${bankData?.bankCode})`}</p>
              <p
                className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{`Acc no. ${bankData?.accountNo}`}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Reference</b></p>
              <p
                className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{bankData?.reference}</p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent"
                onClick={backToCancelCard}>Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button"
                onClick={handleEftSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer> : <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <CardCancelSuccess style={{ marginTop: 0 }} className={classes.screenCenterPrimaryIcon}
                fill={primaryIconColor}
                stroke={primaryIconColor} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <h4 style={{ margin: 0 }}>
                  <b className={classes.regularFontSize}>
                    You are about to cancel your card
                  </b>
                </h4>
                <p>
                  Your card balance is:
                </p>
                <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}>
                  <b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+walletDetailData?.availableBalance).toFixed(2)}</b>
                </h2>
              </GridItem>
              {wallets && wallets.length > 0 && +walletDetailData?.availableBalance > 0 ?
                <GridItem xs={12} sm={12} md={12}>
                  <h4 style={{ margin: 0 }}>
                    <b className={classes.regularFontSize}>
                      What would you like to do?
                    </b>
                  </h4>
                  <RadioGroup name="withdrawalType" id="withdrawalType" value={inputs?.withdrawalType}
                    onChange={handleInputChange}>
                    <FormControlLabel value="NEW_CARD" control={<Radio />} label="Create a new virtual card" />
                    <FormControlLabel value="WALLET" control={<Radio />}
                      label={`Withdrawal my ${walletTitleText?.toLowerCase()} balance`} />
                  </RadioGroup>
                  {errors?.withdrawalType && <Danger><small>{errors?.withdrawalType}</small></Danger>}
                </GridItem>
                : null}
              {inputs?.withdrawalType === 'WALLET' ?
                <GridItem xs={12} sm={12} md={12}>
                  <h6 className={classes.regularFontSize} style={{ margin: '10px 0', textTransform: 'inherit' }}>
                    <b>Which {walletTitleText?.toLowerCase()} would you like to transfer?</b></h6>
                  <ValidationTextField
                    fullWidth
                    label={`To ${walletTitleText?.toLowerCase()}`}
                    select
                    variant="filled"
                    id="toWallet"
                    type="text"
                    error={!!(errors?.toWallet)}
                    name="toWallet"
                    value={inputs?.toWallet}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp}>
                    {wallets && wallets.length > 0 ?
                      wallets?.map((wallet) => {
                        return (<MenuItem key={wallet?.walletId}
                          value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                      }) : null
                    }
                  </ValidationTextField>
                  {errors?.toWallet && <Danger><small>{errors?.toWallet}</small></Danger>}
                </GridItem>
                : null}
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                  disabled={Object.keys(errors).length > 0}>Cancel my card</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>}

      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ConfirmCancelCard)