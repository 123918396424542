import { WalletMode } from "eclipse-react-sdk/services/models";

export default function WalletTypeMode(themeTemplate, walletTypeId) {
    if(themeTemplate && walletTypeId && themeTemplate?.publicCardWalletTypeId && themeTemplate?.publicCardWalletTypeId === walletTypeId){
        return WalletMode.PREPAIDCARD;
    }
    if(themeTemplate && walletTypeId && themeTemplate?.publicCloseLoopWalletTypeId && themeTemplate?.publicCloseLoopWalletTypeId === walletTypeId){
        return WalletMode.CLOSEDLOOPDIGITAL;
    }
    return null;
}