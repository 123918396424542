import React, { useCallback, useEffect } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import {
  primaryColor
} from "../../style.scss";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Danger from "../../components/Typography/Danger";
import { loginToApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Checkbox from '@material-ui/core/Checkbox';
import { load } from 'recaptcha-v3'
import { EnvConfig } from "../../environment.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { AAuthenticationApi, DCustomerManagementApi } from "eclipse-react-sdk/services";
import ReturnMobileNumberWithISD from "../../views-auth/returnMobileNumberWithISD.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function AssistedSignUp(props) {
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = useState({ mobileNumber: '' });
  const [errors, setErrors] = useState({});
  const [tenantToken, setTenantToken] = useState({});
  const [acceptTC, setAcceptTC] = React.useState(false);
  const [marketingCheck, setMarketingCheck] = React.useState(false);
  const [alertData, setAlert] = useState(null);
  const termsAndConditionsLink = props?.appData?.template?.defaultPWATandCLink;
  const defaultMobileNumberRegEx = props?.appData?.template?.defaultMobileNumberRegEx;
  const whatsAppDefaultISDCode = props?.appData?.template?.whatsAppDefaultISDCode;
  const tenantIdentity = props?.appData?.template?.tenantUsername;
  const tenantPassword = props?.appData?.template?.tenantPassword;
  const tenantId = props?.appData?.template?.tenantId;

  const returnMobileNumberWithISD = () => {
    return ReturnMobileNumberWithISD(whatsAppDefaultISDCode, inputs?.mobileNumber);
  }

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const handleSignupFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateSignupForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors && acceptTC) {
      setLoading(true);
      const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.Authorization);
      const customerAPI = new DCustomerManagementApi(httpRequestConfiguration);
      customerAPI.tenantsTenantIdCustomersGet(props?.appData?.auth?.tenantId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, returnMobileNumberWithISD()).then( async (getCustomerAPIResponse) => {
        let isRatified = false;
        if(getCustomerAPIResponse.data.length > 0) {
          isRatified = await getRatify(props?.appData?.auth?.tenantId, getCustomerAPIResponse.data[0].customerId);
        }
        if (getCustomerAPIResponse.data.length === 0 || (getCustomerAPIResponse.data.length > 0 && !isRatified)) {
          generateOTP();
          const appData = {
            ...props?.appData,
            reKYCCustomer: getCustomerAPIResponse.data
          }
          props.signUpHandler(appData);
        } else {
          showAlert(true, 'ERROR', `The mobile number has already been used!`);
          setLoading(false);
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });

    }
  }

  const getRatify = async (tenantId, customerId) => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.Authorization);
    const ratifyAPI = new DCustomerManagementApi(httpRequestConfiguration);
    return ratifyAPI.tenantsTenantIdCustomersCustomerIdWalletTypesGet(tenantId, customerId, 1, 0).then((ratifyAPIResponse) => {
      if (ratifyAPIResponse.data && ratifyAPIResponse.data.length > 0) {
        let isRatifyFailed = 0;
        ratifyAPIResponse.data.forEach((ratifyAPIResponseData) => {
          if (!ratifyAPIResponseData?.allowed) {
            isRatifyFailed = isRatifyFailed + 1;
          }
        });
        if (isRatifyFailed <= 0) {
          return true;
        } else {
          return false;
        }
      }
    }).catch((error) => {
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  };

  const generateOTP = () => {
    const httpRequestConfiguration = HttpRequestConfiguration(tenantToken?.tenantToken);
    const generateOTPAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
    load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
      reCaptcha.execute('OTP_VERIFICATION').then((token) => {
        if (token) {
          const headers = { gCaptcha: token };
          httpRequestConfiguration.baseOptions = { headers };
          const generateOTPData = {
            phone: returnMobileNumberWithISD(),
            tenantId
          }
          generateOTPAPI.globalVerificationsPost(generateOTPData).then(() => {
            history.push({
              pathname: '/pages/assisted-signup-verify-otp',
              state: { gCaptcha: token, mobileNumber: returnMobileNumberWithISD(), tenantToken }
            });
          }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
              showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
              redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
          });
        } else {
          showAlert(true, 'ERROR', `Please try again!`);
        }
      })
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateSignupForm(inputs);
    setErrors(validationErrors);
  }

  const changeAcceptTC = (event) => {
    setAcceptTC(event.target.checked);
  }
  const changeMarketingCheck = (event) => {
    setMarketingCheck(event.target.checked);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateSignupForm(inputs);
    setErrors(validationErrors);
  }

  const validateSignupForm = (inputs) => {
    //Email errors
    const errors = {};
    const pattern = new RegExp(defaultMobileNumberRegEx);
    if (!inputs.mobileNumber) {
      errors.mobileNumber = 'Mobile number required!'
    } else if (!pattern.test(inputs.mobileNumber)) {
      errors.mobileNumber = 'Mobile number is invalid!'
    }
    return errors
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.signUpHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  React.useEffect(() => {
    return () => {
      window.onpopstate = () => {
        backToHome();
      }
    };
  }, [backToHome])

  const getTenantToken = useCallback(() => {
    const loginAPI = new AAuthenticationApi(HttpRequestConfiguration(" "));
    const loginBody = {
      identity: tenantIdentity,
      password: tenantPassword
    }
    loginAPI.authenticationLoginPost(loginBody).then((authAPIResponse) => {
      const auth = { Authorization: authAPIResponse?.data?.headerValue };
      setTenantToken(auth);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen(`/pages/dashboard`, `error`);
      }
    });
  }, [redirectToErrorScreen, tenantIdentity, tenantPassword]);

  useEffect(() => {
    getTenantToken();
  }, [getTenantToken])


  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome} />
              <span className={classes.title}>Sign up a customer</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          <form autoComplete="off" onSubmit={handleSignupFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1>Help sign up a <br/> new customer</h1>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  inputProps={{ inputMode: 'tel' }}
                  fullWidth
                  label="What is the  customer's mobile number?"
                  variant="filled"
                  id="mobileNumber"
                  type="text"
                  error={!!(errors.mobileNumber)}
                  name="mobileNumber"
                  value={inputs.mobileNumber}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp} />
                {errors.mobileNumber && <Danger><small>{errors.mobileNumber}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className={'m-b-10'} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={acceptTC}
                    onChange={changeAcceptTC}
                    style={{ paddingLeft: '0' }}
                  />
                  <p className={classes.regularFontSize + ' m-t-0 m-b-0'} style={{ lineHeight: '20px' }}>Confirm the customer accepts the <a className={classes.linkColor} href={termsAndConditionsLink} target={"_blank"} rel={"noopener noreferrer"}>terms and conditions.</a> </p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={marketingCheck}
                    onChange={changeMarketingCheck}
                    style={{ paddingLeft: '0' }}
                  />
                  <p className={classes.regularFontSize + ' m-t-0 m-b-0'} style={{ lineHeight: '20px' }}>Confirm the customer wants to receive marketing and product information.</p>
                </div>
              </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem xs={12} m={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors.mobileNumber || !acceptTC)}>Let's go</Button>
                <p className={classes.regularFontSize + ' ' + classes.textCenter}><span className={classes.linkColor} onClick={backToHome} >Cancel</span> </p>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  signUpHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssistedSignUp)
