import React, {useState} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {useHistory} from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import {primaryColor} from "../../style.scss";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../components/Typography/Danger.js";
import {SpinnerComponent} from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import {HWalletsApi} from "eclipse-react-sdk/services";
import {QRCodeType} from "../../eclipse-react-sdk/services/models";
import {v4 as uuidv4} from 'uuid';
import FormattedAmount from "../../formattedAmount.js";
import { AmountTextField } from "../../components/Amount/AmountTextField.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function GetPaidAmount(props) {
    const wallets = props?.appData?.wallets?.filter((wallet) => (wallet.status === 'ACTIVE' && ((wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId && props?.appData?.template?.pwaAppOptions?.allowGetPaidByQR.includes("WALLETS")) || (wallet?.walletTypeId === props?.appData?.template?.publicCardWalletTypeId && props?.appData?.template?.pwaAppOptions?.allowGetPaidByQR.includes("CARDS")))));
    const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
    const history = useHistory();
    const classes = useStyles();

    const [inputs, setInputs] = useState({receiveWallet: '', amount: '', reference: ''});
    const [errors, setErrors] = useState({});
    const [pastedAmount, setPastedAmount] = useState(0);

    const [isLoading, setLoading] = useState(false);
    const [alertData, setAlert] = useState(null);

    const showAlert = (alertDisplay, alertType, message) => {
        setAlert({isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message})
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const handleInputAmountPaste = (event) => {
        event.persist();
        let pastedData = (event.clipboardData || window.clipboardData).getData('text');
        let formattedData = FormattedAmount(pastedData, 'PASTE');
        inputs['amount'] = formattedData;
        if (formattedData > 0) {
            setPastedAmount(inputs?.amount);
        }
        const validationErrors = validateQRForm(inputs);
        setErrors(validationErrors);
      }

    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'amount') {
            if(pastedAmount > 0) {
                inputs['amount'] = pastedAmount;
                setPastedAmount(0);
            }else{
                inputs['amount'] = FormattedAmount(event.target.value);
            }
        } else {
            inputs[event.target.name] = event.target.value;
        }
        setInputs(inputs);
        const validationErrors = validateQRForm(inputs);
        setErrors(validationErrors);
    }

    const handleInputKeyUp = (event) => {
        event.persist();
        if(event.key === ',' && event.target.name === 'amount') {
            inputs['amount'] = FormattedAmount(event.target.value);
            setInputs(inputs);
        }
        const validationErrors = validateQRForm(inputs);
        setErrors(validationErrors);
    }

    const validateQRForm = (inputs) => {
        const errors = {}
        if (!inputs.receiveWallet) {
            errors.receiveWallet = `Receive ${walletTitleText?.toLowerCase()} required!`
        } else if (!/^[0-9]{1,}$/i.test(inputs.receiveWallet)) {
            errors.receiveWallet = `Invalid receive ${walletTitleText?.toLowerCase()}!`
        }

        if (!inputs.amount) {
            errors.amount = 'Amount required!'
        } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
            errors.amount = 'Invalid amount! Amount must be 0 or greater than 0.'
        }

        if (!inputs.reference) {
            errors.reference = 'Payment reference required!'
        } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{4,}$/i.test(inputs.reference)) {
            errors.reference = 'Invalid payment reference!'
        }

        return errors
    }

    const backToTransact = () => {
        history.push('/pages/transact');
    }

    const redirectToDisplayQR = (generatedQRData) => {
        const selectedWallet = props?.appData?.wallets?.filter(wallet => wallet?.walletId === inputs.receiveWallet)[0];
        history.push({
            pathname: '/pages/qr-display',
            state: {generatedQRData: generatedQRData, walletDetailData: selectedWallet}
        });
    }

    const redirectToErrorScreen = (fromScreen, toScreen) => {
        history.push({
            pathname: toScreen,
            state: {fromScreen: fromScreen}
        });
    }

    const generateQR = (inputs) => {
        setLoading(true);
        const qrGenerateAPI = new HWalletsApi();
        const createQRTransferWalletBody = {
            amount: inputs.amount,
            reference: inputs.reference,
            description: `PWA:${uuidv4()}`,
            type: QRCodeType.ZAMASTERPASS,
            merchantName: props?.appData?.customer?.firstName
        }
        qrGenerateAPI.tenantsTenantIdWalletsWalletIdQrCodesPost(props?.appData?.auth?.tenantId, inputs.receiveWallet, createQRTransferWalletBody).then((qrGenerateAPIResponse) => {
            setLoading(false);
            redirectToDisplayQR(qrGenerateAPIResponse?.data);
            ;
        }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
                redirectToErrorScreen('/pages/qr-generate', '/pages/error');
            }
        });
    }

    const handleQRFormSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateQRForm(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
        setErrors(validationErrors);
        if (noErrors) {
            generateQR(inputs);
        }
    }

    return (
        <div>
            <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{display: 'flex'}}>
                            <ArrowBackIosIcon className={classes.linkColor} onClick={backToTransact}></ArrowBackIosIcon>
                            <span className={classes.title}>Get paid with a QR Code</span>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>Select a {walletTitleText.toLowerCase()} to
                            receive the payment</b></p>
                    </GridItem>
                    <form onSubmit={handleQRFormSubmit}>
                        <GridItem xs={12} sm={12} md={12}>
                            <ValidationTextField
                                fullWidth
                                label={`Select ${walletTitleText.toLowerCase()}`}
                                select
                                variant="filled"
                                id="receiveWallet"
                                type="number"
                                error={(errors.receiveWallet) ? true : false}
                                name="receiveWallet"
                                value={inputs.receiveWallet}
                                onChange={handleInputChange}
                                onKeyUp={handleInputKeyUp}>
                                {
                                    wallets && wallets.length > 0 ?
                                        wallets?.map((wallet) => {
                                            return (<MenuItem key={wallet?.walletId}
                                                              value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                                        }) :
                                        <MenuItem key={""} value={""}>{`No ${walletTitleText?.toLowerCase()} found`}</MenuItem>
                                }
                            </ValidationTextField>
                            {errors.receiveWallet && <Danger><small>{errors.receiveWallet}</small></Danger>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.regularFontSize}
                                style={{margin: '10px 0', textTransform: 'capitalize'}}><b>Enter the amount owed</b>
                            </h6>
                            <AmountTextField
                                {...props?.appData}
                                inputs={inputs}
                                errors={errors}
                                onPaste={handleInputAmountPaste}
                                onChange={handleInputChange}
                                onKeyUp={handleInputKeyUp} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <ValidationTextField
                                inputProps={{maxLength: 20}}
                                fullWidth
                                label="Payment reference"
                                variant="filled"
                                id="reference"
                                type="text"
                                error={!!(errors.reference)}
                                name="reference"
                                value={inputs.reference}
                                onChange={handleInputChange}
                                onKeyUp={handleInputKeyUp}/>
                            {errors.reference && <Danger><small>{errors.reference}</small></Danger>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                                    disabled={(errors.receiveWallet || errors.amount || errors.reference) ? true : false}>Generate
                                QR code</Button>
                        </GridItem>
                    </form>
                </GridItem>
            </GridContainer>
            {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
        </div>
    )
}


const mapStateToProps = state => ({
    appData: state.applicationData.appData
})

export default connect(mapStateToProps)(GetPaidAmount)
