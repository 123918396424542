import React, { useState,useCallback } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory,useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { ReactComponent as SetPasswordIcon } from '../../assets/img/icon_enter_password.svg'
import { primaryIconColor } from "../../style.scss";
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ChangeCreatePasswordPIN(props) {
  //console.log("ChangeCreatePasswordPIN Props :: ", props?.appData);
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const passwordPINControlName = passwordPINText.toLowerCase();
  const passwordPINInputControlGridLength = 12 / inputControls;
  const isForPaymentPinChange = useLocation().state?.isForPaymentPinChange;

  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(passwordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});

  const backToChangePassword = useCallback(() => {
    if(isForPaymentPinChange){
      history.push({
        pathname: `/pages/change-payment-pin`,
        state:{isForPaymentPinChange}
      });
    }else{
      history.push({
        pathname: `/pages/change-password`,
        state:{isForPaymentPinChange}
      });
    }
  }, [history,isForPaymentPinChange]);

  React.useEffect(() => {
     window.onpopstate = () => {
        backToChangePassword();
    };
  }, [history, backToChangePassword])

  const redirectToConfirmPassword = (newPasswordPIN) => {
    if(isForPaymentPinChange){
      history.push({
        pathname: `/pages/change-confirm-payment-pin`,
        state: { newPasswordPIN: newPasswordPIN,isForPaymentPinChange}
      });
    }else{
      history.push({
        pathname: `/pages/change-confirm-password`,
        state: { newPasswordPIN: newPasswordPIN,isForPaymentPinChange}
      });
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateChangeCreatePasswordPINForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateChangeCreatePasswordPINForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateChangeCreatePasswordPINForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateChangeCreatePasswordPINForm = () => {
    //Email errors
    return ValidateInputControls(inputs, passwordPINControlName, inputControls, passwordPINText, pinPasswordLength)
  }

  const handleChangeCreatePasswordPINFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateChangeCreatePasswordPINForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      redirectToConfirmPassword(ReturnInputControlsConcatValues(inputs, passwordPINControlName, inputControls));
    } 
  }


  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToChangePassword}/>
                <span className={classes.title}>{isForPaymentPinChange?"Reset payment PIN":"Reset my "+passwordPINText}</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + ' m-t-0 m-b-0'} style={{ fontSize: '20px' }}><b> Set your new {isForPaymentPinChange?"payment PIN":passwordPINText}</b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <SetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={handleChangeCreatePasswordPINFormSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                      <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '10px' }}><strong>Create your {isForPaymentPinChange?"payment PIN":passwordPINText}.</strong></p>
                    </GridItem>
                    {ReturnInputControlsMap(passwordPINControlName, inputControls)?.map((control, index, array) => {
                      return (
                        <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength} custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                          <ValidationTextField
                            inputProps={{ maxLength: inputControls > 1 ? 1 : 50, inputMode: pinAsPassword ? 'numeric' : '' }}
                            className={inputControls > 1 ? passwordClasses.oneDigitPassword : ''}
                            label={inputControls > 1 ? '' : ' ' + isForPaymentPinChange?"Create your New payment PIN":"Create your New "+passwordPINText}
                            autoComplete="new-password"
                            fullWidth
                            variant="filled"
                            id={control}
                            type="password"
                            error={!!(errors[passwordPINControlName])}
                            name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                            value={inputs[control] || ""}
                            onChange={handleInputChange}
                            onKeyDown={handleOnKeyDown}
                            onKeyUp={handleInputKeyUp} />
                        </GridItem>
                      )
                    })
                    }
                    <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                      {errors[passwordPINControlName] && <Danger><small>{errors[passwordPINControlName]}</small></Danger>}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors[passwordPINControlName]) || !ReturnInputControlsConcatValues(inputs, passwordPINControlName, inputControls) }>Next</Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ChangeCreatePasswordPIN)