import React, { useCallback } from "react";

import { useHistory } from "react-router-dom";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const useStyles = makeStyles(themeStyle);

export function PayBillPayAt(props) {
 
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])


  return (
    <div >
    <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
            <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome} />
            <span className={classes.title}>Go back home</span>
            </GridItem>
        </GridContainer>
        </GridItem>
    </GridContainer>
      
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
    <iframe src="https://payat.io/qr/home" title="payAtPage" width="100%" style={{height:'calc(100vh - 300px)',border:'none'}} />
    </GridItem>
    
    </GridContainer>
    </div>
  );
}



export default PayBillPayAt;