import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { ReactComponent as WalletSuccess } from "../../assets/img/jounrney_create_walet_success.svg";
import { primaryIconColor } from "../../style.scss";

const useStyles = makeStyles(themeStyle);

export function SuccessCreateWallet(props) {
  const history = useHistory();
  const classes = useStyles();
  const createdWalletDetailData = useLocation().state?.createdWalletDetailData;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;

  const backToWalletDetail = () => {
    props.appData.isRefresh = true;
    props.successCreateWalletHandler({...props?.appData});
    history.push({
      pathname: '/pages/wallets-detail',
      state: { walletDetailData: createdWalletDetailData }
    });
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.successCreateWalletHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history,backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <WalletSuccess className={classes.screenCenterPrimaryIcon} fill={primaryIconColor} stroke={primaryIconColor} />
              <p className={classes.title + ' ' + classes.textCenter}><b>We successfully created your {walletTitleText.toLowerCase()}!</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}><b>Your {walletTitleText?.toLowerCase()} is ready. Next you can top-up your {walletTitleText?.toLowerCase()} to start transacting.</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToWalletDetail}>View my {walletTitleText?.toLowerCase()}</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successCreateWalletHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessCreateWallet)