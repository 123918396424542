import React, { useCallback, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { grayColor } from "../../../assets/jss/eclipse.js";
import { loginToApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { SpinnerComponent } from "react-element-spinner";
// import HttpRequestConfiguration from "../../../http-request-configuration.js";
import { primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { DocumentType } from "eclipse-react-sdk/services/models";
import { DCustomerManagementApi } from "../../../eclipse-react-sdk/services";

const useStyles = makeStyles(themeStyle);

export function UploadDocument(props) {
  // console.log("Upload Id Document props :: ", props);
  const selectedDocument = useLocation().state?.selectedDoc;
  const userIdentity = useLocation().state?.userIdentity;
  const userOTP = useLocation().state?.userOTP;
  const [idDocument, setIdDocument] = useState(useLocation().state?.idDocument);
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const selfieDocument = useLocation().state?.selfieDocument;
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const [isLoading, setLoading] = useState(false);

  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const classes = useStyles();
  const history = useHistory();

  const uploadNewIdDocument = (idDocument) => {//httpRequestConfiguration,
    // goToSelfieDocumentUpload();
    const newDocumentAPI = new DCustomerManagementApi();//httpRequestConfiguration
    newDocumentAPI.tenantsTenantIdCustomersCustomerIdDocumentsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, idDocument, true).then((newDocumentAPIResponse) => {
      setIdDocument(newDocumentAPIResponse.data);
      setLoading(false);
      goToSelfieDocumentUpload();
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/identity-document', '/pages/error');
      }
    });
  }


  const goToUploadIdDocument = () => {
    setLoading(true);
    const dataImagePart1 = idDocumentImageData.split(';')[0];
    const dataImagePart2 = idDocumentImageData.split(';')[1];
    const newIdDocument = {
      base64EncodedDocument: dataImagePart2.split(',')[1],
      documentType: DocumentType.COMPARISONNATIONALIDENTITY,
      mediaType: dataImagePart1.split(':')[1]
    }
    uploadNewIdDocument(newIdDocument);//httpRequestConfiguration,
  }

  const goToSelfieDocumentUpload = () => {
    history.push({
      pathname: `/auth/selfie-initial`,
      state: {
        userIdentity,
        userOTP,
        selectedDoc: selectedDocument,
        idDocument,
        selfieDocument,
        idDocumentImageData,
        selfieDocumentImageData
      },
    });
  }

  const goBack = useCallback(() => {
    history.push({
      pathname: '/auth/scan-document',
      state: {
        userIdentity,
        userOTP,
      },
    });
  }, [history, userIdentity, userOTP])

  React.useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        goBack();
      }
    };
  }, [history, goBack])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' m-b-0'}><strong>Upload your document.</strong></p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <img src={idDocumentImageData} alt="" style={{
                    padding: '10px',
                    border: '1px solid',
                    borderColor: grayColor[12],
                    boxSizing: 'border-box',
                    float: 'left',
                    width: '100%',
                    borderRadius: '5px'
                  }}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="external-links"
                       style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span onClick={goBack} style={{ display: 'flex', margin: '10px 0' }}
                          className={classes.linkColor + ' ' + classes.regularFontSize}><DeleteOutlineIcon/>&nbsp;<b>Delete and upload another image</b></span>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                      onClick={goToUploadIdDocument}>Upload my document</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  uploadDocumentHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument)
