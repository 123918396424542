import React, { useState, useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.js";
import { primaryColor } from "../../../style.scss";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { SpinnerComponent } from "react-element-spinner";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import { CreateVASInputControls, ReturnVASAirtimeDataInputControls, ValidateVASInputControls } from "../VASControls.js";
import FormattedAmount from "../../../formattedAmount.js";
import { CardStatus } from "../../../eclipse-react-sdk/services/models";
import { AmountTextField } from "../../../components/Amount/AmountTextField.js";
import { checkAmount } from "../../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function VASBuyAirtimeData(props) {
    const vasConfig = props?.appData?.template?.vas;
    const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
    const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
    const amountOptions = pwaAppOptions?.cashInOutLimits?.vas;
    const pageState = useLocation().state;
    const vasDetail = useLocation().state?.vasDetail;
    const vasType = useLocation().state?.vasType;
    const payWithWallets = vasType === 'BUYELECTRICITY' ? props?.appData?.template?.vas?.payElectricityWithWallets : props?.appData?.template?.vas?.payAirtimeDataWithWallets;
    const vasProducts = useLocation().state?.vasProducts;
    const selectedVASProducts = useLocation().state?.selectedVASProducts;
    const wallets = props?.appData?.wallets?.filter((wallet) => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId);

    const [subProducts, setSubProducts] = React.useState([]);
    const [selectedSubProducts, setSelectedSubProducts] = React.useState(null);
    const [linkedBankCards, setLinkedBankCards] = React.useState([]);
    const [isGetLinkBankCard, setGetLinkBankCard] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const defaultPaymentProcess = wallets && payWithWallets ? 'WALLETS' : 'CARDS';
    const [paymentProcess, setPaymentProcess] = React.useState(defaultPaymentProcess);
    const [subProductType, setSubProductType] = React.useState('ALL');

    const [additionalFields, setAdditionalFields] = useState(selectedVASProducts?.title.toLowerCase() === vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() ? selectedVASProducts?.data[0].additionalFields : []);
    const existedControls = ReturnVASAirtimeDataInputControls(additionalFields);
    const history = useHistory();
    const classes = useStyles();

    const defaultAmountBundle = '';
    const [inputs, setInputs] = useState({ fromWalletCard: '', amount: defaultAmountBundle, ...CreateVASInputControls(additionalFields, existedControls) });
    const [errors, setErrors] = useState({});
    const [alertData, setAlert] = React.useState(null);
    const [pastedAmount, setPastedAmount] = useState(0);

    const getProductsDataCategoryWise = useCallback((products) => {
        const productsGroups = {};
        const sectionGroups = [];
        const convertedProducts = [];
        products.forEach((product) => {
            if (product?.productTypeName.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase()) {
                convertedProducts.push({
                    ...product,
                    productSubCategory: product?.productSubCategory ? product?.productSubCategory : 'ALL'
                })
            }
        })

        convertedProducts.forEach((product) => {
            const productSubCategory = product.productSubCategory;
            if (productSubCategory in productsGroups) {
                productsGroups[productSubCategory].push(product);
            } else {
                productsGroups[productSubCategory] = new Array(product);
            }
        });

        var productsGroupsKeys = Object.keys(productsGroups);
        productsGroupsKeys.forEach((productKey) => {
            sectionGroups.push(
                {
                    title: productKey,
                    data: productsGroups[productKey]
                }
            )
        });

        return sectionGroups;
    }, [vasConfig])

    const onChangeRadio = (event) => {
        setAdditionalFields([]);
        setPaymentProcess(event.target.value);
        setInputs({ fromWalletCard: '', amount: inputs?.amount, ...CreateVASInputControls(additionalFields, existedControls) });
        setErrors({});
    };

    const onChangeSubProductRadio = (event) => {
        setSubProductType(event.target.value);
        setAdditionalFields([]);
        setInputs({ fromWalletCard: inputs?.fromWalletCard, amount: defaultAmountBundle, ...CreateVASInputControls(additionalFields, existedControls) });
        setErrors({});
        const products = subProducts.filter((product) => product?.title === event.target.value);
        setSelectedSubProducts(products.length > 0 ? products[0] : []);
    };

    const showAlert = (alertDisplay, alertType, message) => {
        setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
        history.push({
            pathname: toScreen,
            state: { fromScreen: fromScreen }
        });
    }, [history]);

    const handleInputAmountPaste = (event) => {
        event.persist();
        let pastedData = (event.clipboardData || window.clipboardData).getData('text');
        let formattedData = FormattedAmount(pastedData, 'PASTE');
        inputs['amount'] = formattedData;
        if (formattedData > 0) {
            setPastedAmount(inputs?.amount);
        }
        const validationErrors = validateVASForm(inputs);
        setErrors(validationErrors);
    }

    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'amount') {
            if(pastedAmount > 0) {
                inputs['amount'] = pastedAmount;
                setPastedAmount(0);
            }else{
                inputs['amount'] = FormattedAmount(event.target.value);
            }
        } else {
            inputs[event.target.name] = event.target.value;
        }
        if (event.target.name === 'amount' && selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase()) {
            const selectedProduct = selectedSubProducts?.data?.filter(product => product?.productId === inputs?.amount)[0];
            setAdditionalFields(selectedProduct?.additionalFields);
            setInputs({ fromWalletCard: inputs?.fromWalletCard, amount: inputs?.amount, ...CreateVASInputControls(additionalFields, existedControls) });
        } else {
            setInputs(inputs);
        }
        const validationErrors = validateVASForm();
        setErrors(validationErrors);
    }

    const handleInputKeyUp = (event) => {
        event.persist();
        if(event.key === ',' && event.target.name === 'amount') {
            inputs['amount'] = FormattedAmount(event.target.value);
            setInputs(inputs);
        }
        const validationErrors = validateVASForm();
        setErrors(validationErrors);
    }

    const validateVASForm = () => {
        let errors = {}
        if (selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase()) {
            if (!inputs?.amount) {
                errors.amount = 'Bundle required!';
                return errors;
            } else if (!/^[0-9A-Za-z-]{1,}$/i.test(inputs?.amount)) {
                errors.amount = 'Invalid bundle!';
                return errors;
            }
        } else {
            const amountControl = additionalFields.filter(control => control?.id === 'amount');
            if (amountControl && amountControl.length > 0) {
                amountControl.forEach((control) => {
                    const pattern = new RegExp(control?.regex);
                    if (!pattern.test(inputs[control?.id])) {
                        errors[control?.id] = `${control?.regexErrorMessage ? control?.regexErrorMessage : `Invalid ${control?.title}!`}!`
                        return errors;
                    }
                });
            } else {

                if (!inputs?.amount) {
                    errors.amount = 'Amount required!'
                    return errors;
                } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs?.amount)) {
                    errors.amount = 'Invalid amount!'
                    return errors;
                } else if (checkAmount(amountOptions?.buyAirTimeData, +inputs.amount)) {
                    errors.amount = `${amountOptions?.buyAirTimeData.error}`
                    return errors;
                }
            }

        }

        if (Object.keys(errors).length < 0) {
            errors = ValidateVASInputControls(inputs, additionalFields, existedControls);
            if (Object.keys(errors).length > 0) {
                return errors;
            }
        }

        if (!inputs?.fromWalletCard) {
            errors.fromWalletCard = paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()} required!` : 'From card required!'
            return errors;
        } else if (!/^[0-9a-zA-Z-]{1,}$/i.test(inputs?.fromWalletCard)) {
            errors.fromWalletCard = paymentProcess === 'WALLETS' ? `Invalid from ${walletTitleText?.toLowerCase()}!` : 'Invalid from card!'
            return errors;
        }else{
            if(inputs?.fromWalletCard && paymentProcess === 'WALLETS'){
                const selectedWallet = props?.appData?.wallets?.find(wallet => wallet?.walletId === inputs?.fromWalletCard);
                if(selectedWallet && (selectedWallet?.availableBalance < 1 || selectedWallet?.availableBalance < inputs?.amount)){
                    errors.fromWalletCard = "Insufficient balance!";
                    return errors;
                }
            }
        }
        return errors;
    }

    const backToVASCategory = () => {
        history.push({
            pathname: `/pages/${ vasType === 'BUYELECTRICITY' ? 'vas-enter-mobile' : 'vas-view-categories' }`,
            state: { ...pageState }
        });
    }

    const confirmToVASPay = () => {
        const selectedWallet = props?.appData?.wallets?.filter(wallet => wallet?.walletId === inputs?.fromWalletCard)[0];
        const selectedCard = linkedBankCards?.filter(card => card?.cardOnFileId === inputs?.fromWalletCard)[0];
        let selectedProduct = { fixedPriceIncl: inputs?.amount };
        if (selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase()) {
            selectedProduct = selectedSubProducts?.data?.filter(product => product?.productId === inputs?.amount)[0];
        }

        const updatedAdditionalFields = [];
        additionalFields.forEach(control => {
            if (inputs[control?.id]) {
                control = { ...control, value: inputs[control?.id] };
            }
            updatedAdditionalFields.push(control);
        });

        if (selectedVASProducts?.title.toLowerCase() === vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase()) {
            const amountIndex = updatedAdditionalFields.findIndex(control => { return control?.id === 'amount' });
            if (amountIndex < 0) {
                updatedAdditionalFields.push({ id: 'amount', value: inputs?.amount });
            } else {
                updatedAdditionalFields[amountIndex].value = inputs?.amount;
            }
        }

        const msisdnIndex = updatedAdditionalFields.findIndex(control => { return control?.id === 'msisdn' });
        if (msisdnIndex < 0) {
            updatedAdditionalFields.push({ id: 'msisdn', value: vasDetail?.mobileNumber });
        } else {
            updatedAdditionalFields[msisdnIndex].value = vasDetail?.mobileNumber;
        }


        selectedProduct = {
            ...selectedProduct,
            additionalFields: updatedAdditionalFields
        }

        history.push({
            pathname: '/pages/vas-payment-confirmation',
            state: {
                ...pageState,
                selectedProduct: selectedProduct,
                walletDetailData: selectedWallet,
                cardDetailData: selectedCard,
                paymentProcess: paymentProcess,
                paymentDetails: inputs,
                vasType: vasType
            }
        });
    }

    const handleVASFormSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateVASForm();
        const noErrors = Object.keys(validationErrors).length === 0;
        setErrors(validationErrors);
        if (noErrors) {
            confirmToVASPay();
        }
    }

    const getLinkedBankCards = useCallback(() => {
        setLoading(true);
        const linkedBankCardsAPI = new DCustomerManagementApi();
        linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((linkedBankCardsAPIResponse) => {
            setLoading(false);
            if (linkedBankCardsAPIResponse?.data && linkedBankCardsAPIResponse?.data.length > 0) {
                linkedBankCardsAPIResponse.data = linkedBankCardsAPIResponse.data.filter((card=>card.status ===CardStatus?.ACTIVE))
                setLinkedBankCards(linkedBankCardsAPIResponse.data);
            } else {
                setLinkedBankCards([{ cardOnFileId: '', alias: '', last4Digits: '' }]);
            }
            setGetLinkBankCard(true);
        }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
                redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
        });
    }, [props, redirectToErrorScreen]);

    const goToManageBankCards = () => {
        history.push({
            pathname: '/pages/manage-linked-cards',
            state: { fromScreen: vasType, linkedBankCards: linkedBankCards, ...pageState }
        });
    };

    const linkNewBankCard = () => {
        history.push({
            pathname: '/pages/card-on-file',
            state: { fromScreen: vasType, ...pageState }
        });
    };

    React.useEffect(() => {
        if (linkedBankCards.length < 1 && !isGetLinkBankCard) {
            getLinkedBankCards();
        }

        if (selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() && subProducts.length < 1) {
            setSubProducts(getProductsDataCategoryWise(selectedVASProducts?.data));
        }

        if (selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() && subProducts.length > 0 && !selectedSubProducts) {
            setSelectedSubProducts(subProducts[0]);
            setSubProductType(subProducts[0]?.title);
        }

        if (paymentProcess === 'CARDS' && linkedBankCards.length === 1) {
            inputs.fromWalletCard = linkedBankCards[0]?.cardOnFileId;
            setInputs(inputs);
        }

    }, [getProductsDataCategoryWise, vasConfig, inputs, paymentProcess, linkedBankCards, getLinkedBankCards, isGetLinkBankCard, subProducts, selectedVASProducts, selectedSubProducts]);

    return (
        <div>
            <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                            <ArrowBackIosIcon className={classes.linkColor} onClick={backToVASCategory} />
                            <span
                                className={classes.title}>Buy {vasProducts?.partnerName} {vasType === 'BUYELECTRICITY' ? '' : selectedVASProducts?.title}</span>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={handleVASFormSubmit}>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.regularFontSize}
                                style={{ margin: '10px 0', textTransform: 'inherit' }}>
                                <b>{selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() ? 'Select a bundle' : 'Enter the amount'}</b>
                            </h6>
                        </GridItem>
                        {selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() && subProducts && subProducts.length > 1 ?
                            <GridItem xs={12} sm={12} md={12}>
                                <RadioGroup aria-label="subProductType" name="subProductType" value={subProductType}
                                    onChange={onChangeSubProductRadio}>
                                    {subProducts?.map((subProduct) => {
                                        return (<FormControlLabel key={subProduct?.title} value={subProduct?.title}
                                            color={primaryColor}
                                            control={<Radio className={classes.Radio} />}
                                            label={subProduct?.title} />)
                                    })}
                                </RadioGroup>
                            </GridItem>
                            : null}
                        {subProducts && subProducts.length > 0 && selectedSubProducts && selectedSubProducts?.data && selectedSubProducts?.data.length > 0 && selectedVASProducts?.title && selectedVASProducts?.title.toLowerCase() !== vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() ?
                            <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
                                <ValidationTextField
                                    fullWidth
                                    label="Select the bundle"
                                    select
                                    variant="filled"
                                    id="amount"
                                    type="text"
                                    error={!!(errors.amount)}
                                    name="amount"
                                    value={inputs?.amount || ""}
                                    onPaste={handleInputAmountPaste}
                                    onChange={handleInputChange}
                                    onKeyUp={handleInputKeyUp}>
                                    {selectedSubProducts?.data?.map((product) => {
                                        return (<MenuItem key={product?.productId}
                                            value={product?.productId}>{product?.productName}{product?.fixedPriceIncl ? ` - ${getSymbolFromCurrency(product?.currency || 'ZAR')}${product?.fixedPriceIncl.toFixed(2)}` : ''}</MenuItem>)
                                    })
                                    }
                                </ValidationTextField>
                                {errors.amount && <Danger><small>{errors.amount}</small></Danger>}
                            </GridItem>
                            : null}

                        {selectedVASProducts?.title && selectedVASProducts?.title.toLowerCase() === vasConfig?.buyAirtimeDataMenu?.airtime.toLowerCase() ?
                            <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
                                <AmountTextField
                                    {...props?.appData}
                                    inputs={inputs}
                                    errors={errors}
                                    onPaste={handleInputAmountPaste}
                                    onChange={handleInputChange}
                                    onKeyUp={handleInputKeyUp} />
                            </GridItem>
                            : null}
                        {additionalFields?.map((control) => {
                            return (
                                existedControls.indexOf(control?.id) < 0 ?
                                    <GridItem xs={12} sm={12} md={12} key={control?.id}>
                                        <ValidationTextField
                                            fullWidth
                                            label={control?.title}
                                            variant="filled"
                                            id={control?.id}
                                            type="text"
                                            error={!!(errors[control?.id])}
                                            name={control?.id}
                                            value={inputs[control?.id] || ""}
                                            onChange={handleInputChange}
                                            onKeyUp={handleInputKeyUp} />
                                        {errors[control?.id] && <Danger><small>{errors[control?.id]}</small></Danger>}
                                    </GridItem>
                                    : null
                            )
                        })}
                        <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
                            <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>How would you
                                like to pay?</b></p>
                        </GridItem>
                        {wallets && wallets.length > 0 && payWithWallets && linkedBankCards && linkedBankCards.length > 0 ?
                            <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
                                <RadioGroup aria-label="transferType" name="transferType" value={paymentProcess}
                                    onChange={onChangeRadio}>
                                    <FormControlLabel value="WALLETS" control={<Radio />} label={`My ${walletTitleText?.toLowerCase()}`} />
                                    <FormControlLabel value="CARDS" color={primaryColor}
                                        control={<Radio className={classes.Radio} />} label="Linked card" />
                                </RadioGroup>
                            </GridItem>
                            : null}

                        <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
                            <ValidationTextField
                                fullWidth
                                label={paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()}` : "From card"}
                                select
                                variant="filled"
                                id="fromWalletCard"
                                type={paymentProcess === 'WALLETS' ? "number" : "text"}
                                error={!!(errors.fromWalletCard)}
                                name="fromWalletCard"
                                value={inputs?.fromWalletCard}
                                onChange={handleInputChange}
                                onKeyUp={handleInputKeyUp}>
                                {paymentProcess === 'WALLETS' ?
                                    wallets && wallets.length > 0 ?
                                        wallets?.map((wallet) => {
                                            return (<MenuItem key={wallet?.walletId}
                                                value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                                        }) : <MenuItem key={""} value={""}>{`No ${walletTitleText?.toLowerCase()} found`}</MenuItem> :
                                    linkedBankCards?.map((bankCard) => {
                                        return (<MenuItem key={bankCard?.cardOnFileId}
                                            value={bankCard?.cardOnFileId}>{bankCard?.alias !== '' && bankCard?.last4Digits !== '' ? bankCard?.alias + ' - **' + bankCard?.last4Digits : 'No card found'}</MenuItem>)
                                    })
                                }
                            </ValidationTextField>
                            {errors.fromWalletCard && <Danger><small>{errors.fromWalletCard}</small></Danger>}
                            <div className="external-links" style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginTop: '10px'
                            }}>
                                <span onClick={linkNewBankCard} style={{ display: 'flex', margin: '0 10px 0 0' }}
                                    className={classes.linkColor + ' ' + classes.regularFontSize}><AddCircleOutlineOutlined />&nbsp;
                                    <b>Add a card</b></span>
                                <span onClick={goToManageBankCards} style={{ display: 'flex', margin: '0 0 0 10px' }}
                                    className={classes.linkColor + ' ' + classes.regularFontSize}><PaymentIcon />&nbsp;
                                    <b>Manage my cards</b></span>
                            </div>
                        </GridItem>


                        <GridItem xs={12} sm={12} md={12}>
                            <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                                disabled={Object.keys(errors).length !== 0}>Pay
                                now</Button>
                        </GridItem>
                    </form>
                </GridItem>
            </GridContainer>
            {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
        </div>
    )
}


const mapStateToProps = state => ({
    appData: state.applicationData.appData
})

export default connect(mapStateToProps)(VASBuyAirtimeData)
