import React from "react";
import { ReactComponent as LeftArrowIcon } from "../../assets/img/LeftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/img/RightArrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = (props) => {
  const logoList = props?.logoList;
  const options = props?.options;

  const ArrowButton = (props) => {
    const { className, style, onClick, icon } = props;
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        {icon}
      </div>
    );
  };

  const sliderOptions = {
    infinite: true,
    slidesToShow: logoList?.length <= 2 ? 1 : 2,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <ArrowButton icon={<RightArrowIcon />} />,
    prevArrow: <ArrowButton icon={<LeftArrowIcon />} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: logoList?.length <= 2 ? 1 : 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
    ...options,
  };
  
  return (
    <Slider {...sliderOptions}>
      {logoList && logoList?.length !== 0 ? logoList?.map((logoData, index) => (
        <img key={"logo" + index} src={logoData?.logo} alt={logoData?.title || "logo"} />
      )): null}
    </Slider> 
  );
};

export default LogoSlider;
