import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss";
import {ReactComponent as SuccessTransactionIcon} from '../../assets/img/success_transfer_wallet.svg'
import { loginToApp } from "../../redux-services/actions/actions.js";
import { FormatCurrency } from "../../currency-formatter.js";


const useStyles = makeStyles(themeStyle);

export function SuccessPayAmount(props) {
  const walletDetailData = useLocation().state?.walletDetailData;
  const manifestSettings = props?.appData?.template?.manifestSettings
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const successPayData = useLocation().state?.successPayData;

  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.successPayAmountHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  React.useEffect(() => {
    return() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToHome();
      }
    }
  }, [history,backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <SuccessTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0'}><b>Payment successful!</b></h4>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Your ({manifestSettings?.brand} {walletTitleText?.toLowerCase()}-{successPayData?.fromWallet}) balance is</b></p>
              <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>{FormatCurrency((walletDetailData?.currentBalance - successPayData?.amount), walletDetailData?.currency)}</b></h2>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>Available balance <b>{FormatCurrency((walletDetailData?.availableBalance - successPayData?.amount), walletDetailData?.currency)}</b></p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToHome}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successPayAmountHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayAmount)