import React, { useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardContent from '@material-ui/core/CardContent';
import { useHistory, useLocation } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CellCIcon from "../../../assets/img/cellc.png";
import MTNIcon from "../../../assets/img/mtn.png";
import TelkomIcon from "../../../assets/img/telkom.png";
import VodacomIcon from "../../../assets/img/vodacom.png";


import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { loginToApp } from "../../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function VASNetworkPartners(props) {
  const vasPartners = props?.appData?.template?.vas?.partners;
  const vasNetworkPartners = useLocation().state?.networkPartners;
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.vasNetworkPartnersHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props])

  const redirectToBuyAirtimeData = (selectedNetworkPartner) => {
    history.push({
      pathname: '/pages/vas-enter-mobile',
      state: { vasType: 'AIRTIMEDATA', selectedNetworkPartner: selectedNetworkPartner }
    });
  }

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
                <span className={classes.title}>Buy Airtime or Data</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.textCenter + ' m-b-0'}><b>Select a provider</b></h4>
        </GridItem>
        {vasNetworkPartners.map((networkPartner, index) => {
          return (<GridItem xs={12} sm={12} md={12} key={index}>
            <Card style={{ margin: '0' }}>
              <ButtonBase onClick={event => { redirectToBuyAirtimeData(networkPartner) }} style={{ justifyContent: 'flex-start' }}>
                <CardContent style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                  <h5 style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }} >
                    <div style={{ backgroundImage: `url(${networkPartner?.id === vasPartners?.cellc?.id ? CellCIcon : networkPartner?.id === vasPartners?.mtn?.id ? MTNIcon : networkPartner?.id === vasPartners?.telkom?.id ? TelkomIcon : networkPartner?.id === vasPartners?.vodacom?.id ? VodacomIcon : ''})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', width: '2.5em' }}></div>
                    <b style={{ textAlign: 'left', margin: '0.5em 0.75em', lineHeight: '1.5em' }}>{networkPartner?.name}</b>
                  </h5>
                </CardContent>
              </ButtonBase>
            </Card>
          </GridItem>)
        })}
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasNetworkPartnersHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASNetworkPartners)