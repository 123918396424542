import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../components/Typography/Danger.js";
import FormattedAmount from "../../formattedAmount.js";
import AmountTextField from "../../components/Amount/AmountTextField.js";
import { checkAmount } from "../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function EFTOutAmount(props) {
  const wallets = props?.appData?.wallets;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
  const amountOptions = pwaAppOptions?.cashInOutLimits?.cashOut;
  const walletData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;

  const history = useHistory();
  const classes = useStyles();

  const [walletDetailData, setWalletDetailData] = useState(walletData ? walletData : null);
  const [inputs, setInputs] = useState({ eftWallet: walletData ? walletData?.walletId : '', amount: '', reference: '' });
  const [errors, setErrors] = useState({});
  const [pastedAmount, setPastedAmount] = useState(0);

  const validateEFTForm = (inputs) => {
    const errors = {}
    if (!inputs.eftWallet) {
      errors.eftWallet = `${walletTitleText} required!`
    } else if (!/^[0-9]{1,}$/i.test(inputs.eftWallet)) {
      errors.eftWallet = `Invalid ${walletTitleText.toLowerCase()}!`
    }

    if (!inputs.amount) {
      errors.amount = 'Amount required!'
    } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
      errors.amount = 'Amount invalid!'
    } else if (inputs.amount && !walletDetailData) {
      errors.amount = `Please select ${walletTitleText} to complete the transaction.`
    } else if (checkAmount(amountOptions?.eftOut, +inputs.amount)) {
      errors.amount = `${amountOptions?.eftOut?.error}`;
    }

    if (!inputs.reference) {
      errors.reference = 'Payment reference required!'
    } else if (!/^[A-Za-z0-9]+[ A-Za-z0-9]{4,}$/i.test(inputs.reference)) {
      errors.reference = 'Invalid payment reference!'
    }

    return errors
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    if (event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateEFTForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData, 'PASTE');
    inputs['amount'] = formattedData;
    if (formattedData > 0) {
      setPastedAmount(inputs?.amount);
    }
    const validationErrors = validateEFTForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if (pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      } else {
        inputs['amount'] = FormattedAmount(event.target.value);
      }
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateEFTForm(inputs);
    setErrors(validationErrors);
    if (inputs && inputs?.eftWallet && inputs?.eftWallet > 0) {
      const selectedWallet = props?.appData?.wallets?.filter(wallet => wallet?.walletId === inputs.eftWallet)[0];
      setWalletDetailData(selectedWallet);
    }
  }

  const backToTransactOrWalletDetail = () => {
    history.push({
      pathname: `/pages/${(fromScreen && fromScreen === 'wallets-detail') ? fromScreen : 'transact'}`,
      state: { walletDetailData: (fromScreen && fromScreen === 'wallets-detail') ? walletDetailData : null }
    });
  }

  const redirectToEFTBank = (eftData) => {
    history.push({
      pathname: '/pages/eft-bank',
      state: { eftData: eftData, walletDetailData: walletDetailData, fromScreen: (fromScreen && fromScreen === 'wallets-detail') ? fromScreen : 'transact' }
    });
  }

  const handleEFTFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateEFTForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      const errors = {};
      if (checkAmount(amountOptions?.eftOut, +inputs.amount)) {
        errors.amount = `${amountOptions?.eftOut?.error}`;
        setErrors(errors);
      } else if (+inputs.amount > walletDetailData?.availableBalance) {
        errors.amount = 'Your available balance is too low to complete the transaction. Please enter a smaller amount.';
        setErrors(errors);
      } else {
        redirectToEFTBank(inputs);
      }
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToTransactOrWalletDetail} />
              <span className={classes.title}>EFT amount</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}><b>Which {walletTitleText?.toLowerCase()} would you like to use?</b></p>
          </GridItem>
          <form onSubmit={handleEFTFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                disabled={!!walletData}
                fullWidth
                label={"Select " + walletTitleText?.toLowerCase()}
                select
                variant="filled"
                id="eftWallet"
                type="number"
                error={!!(errors.eftWallet)}
                name="eftWallet"
                value={inputs.eftWallet}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}>
                {
                  wallets && wallets.length > 0 ?
                    wallets?.map((wallet) => {
                      return (<MenuItem key={wallet?.walletId}
                        value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                    }) :
                    <MenuItem key={""} value={""}>{`No ${walletTitleText?.toLowerCase()} found`}</MenuItem>
                }
              </ValidationTextField>
              {errors.eftWallet && <Danger><small>{errors.eftWallet}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h6 className={classes.regularFontSize} style={{ margin: '10px 0', textTransform: 'capitalize' }}><b>How much would you like to EFT?</b></h6>
              <AmountTextField
                {...props?.appData}
                inputs={inputs}
                errors={errors}
                walletDetailData={walletData}
                onPaste={handleInputAmountPaste}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ValidationTextField
                inputProps={{ maxLength: 20 }}
                fullWidth
                label="Payment reference"
                variant="filled"
                id="reference"
                type="text"
                error={!!(errors.reference)}
                name="reference"
                value={inputs.reference}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
              {errors.reference && <Danger><small>{errors.reference}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors.eftWallet || errors.amount || errors.reference)}>Continue</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(EFTOutAmount)
