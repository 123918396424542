import React, { useState, useCallback } from "react";
// @material-ui/core
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.js";
import { primaryColor } from "../../../style.scss";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { SpinnerComponent } from "react-element-spinner";
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import { CreateVASInputControls, ReturnVASAirtimeDataInputControls } from "../VASControls.js";
import { CardStatus } from "../../../eclipse-react-sdk/services/models";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function VASBuyKganyaProducts(props) {
    const vasConfig = props?.appData?.template?.vas;
    const payWithWallets = props?.appData?.template?.vas?.payCollectionWithWallets;
    const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
    const pageState = useLocation().state;
    const selectedVASProducts = useLocation().state?.selectedVASProducts;
    const wallets = props?.appData?.wallets?.filter((wallet) => wallet?.walletTypeId === props?.appData?.template?.publicCloseLoopWalletTypeId);

    const [linkedBankCards, setLinkedBankCards] = React.useState([]);
    const [isGetLinkBankCard, setGetLinkBankCard] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const defaultPaymentProcess = wallets && payWithWallets ? 'WALLETS' : 'CARDS';
    const [paymentProcess] = React.useState(defaultPaymentProcess);

    const [additionalFields] = useState(selectedVASProducts?.data[0].additionalFields);
    const existedControls = ReturnVASAirtimeDataInputControls(additionalFields);
    const history = useHistory();
    const classes = useStyles();

    const [inputs, setInputs] = useState({ fromWalletCard: '', amount: '', ...CreateVASInputControls(additionalFields, existedControls) });
    const [errors, setErrors] = useState({});
    const [alertData, setAlert] = React.useState(null);

    const showAlert = (alertDisplay, alertType, message) => {
        setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
        history.push({
            pathname: toScreen,
            state: { fromScreen: fromScreen }
        });
    }, [history]);

    const handleInputChange = (event) => {
        event.persist();
        inputs[event.target.name] = event.target.value;
        setInputs(inputs);
        const validationErrors = validateVASForm();
        setErrors(validationErrors);
    }

    const handleInputKeyUp = (event) => {
        event.persist();
        const validationErrors = validateVASForm();
        setErrors(validationErrors);
    }

    const validateVASForm = () => {
        let errors = {}
        if (!inputs?.fromWalletCard) {
            errors.fromWalletCard = paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()} required!` : 'Select a card required!'
            return errors;
        } else if (!/^[0-9a-zA-Z-]{1,}$/i.test(inputs?.fromWalletCard)) {
            errors.fromWalletCard = paymentProcess === 'WALLETS' ? `Invalid from ${walletTitleText?.toLowerCase()}!` : 'Invalid card!'
            return errors;
        }
        return errors;
    }

    const backToVASViewProducts = () => {
        history.push({
            pathname: '/pages/vas-view-products',
            state: { ...pageState }
        });
    }

    const confirmToVASPay = () => {
        
        const selectedWallet = props?.appData?.wallets?.filter(wallet => wallet?.walletId === inputs?.fromWalletCard)[0];
        const selectedCard = linkedBankCards?.filter(card => card?.cardOnFileId === inputs?.fromWalletCard)[0];
        let selectedProduct = { fixedPriceIncl: inputs?.amount, additionalFields: additionalFields };

        history.push({
            pathname: '/pages/vas-payment-confirmation',
            state: {
                ...pageState,
                selectedProduct: selectedProduct,
                walletDetailData: selectedWallet,
                cardDetailData: selectedCard,
                paymentProcess: paymentProcess,
                paymentDetails: inputs,
                vasType: 'COLLECTIONS'
            }
        });
    }

    const handleVASFormSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateVASForm();
        const noErrors = Object.keys(validationErrors).length === 0;
        setErrors(validationErrors);
        if (noErrors) {
            confirmToVASPay();
        }
    }

    const getLinkedBankCards = useCallback(() => {
        setLoading(true);
        const linkedBankCardsAPI = new DCustomerManagementApi();
        linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((linkedBankCardsAPIResponse) => {
            setLoading(false);
            if (linkedBankCardsAPIResponse?.data && linkedBankCardsAPIResponse?.data.length > 0) {
                linkedBankCardsAPIResponse.data = linkedBankCardsAPIResponse.data.filter((card=>card.status ===CardStatus?.ACTIVE))
                setLinkedBankCards(linkedBankCardsAPIResponse.data);
            } else {
                setLinkedBankCards([{ cardOnFileId: '', alias: '', last4Digits: '' }]);
            }
            setGetLinkBankCard(true);
        }).catch((error) => {
            setLoading(false);
            if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
            } else {
                redirectToErrorScreen('/pages/dashboard', '/pages/error');
            }
        });
    }, [props, redirectToErrorScreen]);

    const goToManageBankCards = () => {
        history.push({
            pathname: '/pages/manage-linked-cards',
            state: { fromScreen: 'COLLECTIONS', linkedBankCards: linkedBankCards, ...pageState }
        });
    };

    const linkNewBankCard = () => {
        history.push({
            pathname: '/pages/card-on-file',
            state: { fromScreen: 'COLLECTIONS', ...pageState }
        });
    };

    React.useEffect(() => {
        if (linkedBankCards.length < 1 && !isGetLinkBankCard) {
            getLinkedBankCards();
        }

        if (paymentProcess === 'CARDS' && linkedBankCards.length === 1) {
            inputs.fromWalletCard = linkedBankCards[0]?.cardOnFileId;
        }

        inputs.amount = selectedVASProducts.data[0].fixedPriceIncl;
        setInputs(inputs);

    }, [vasConfig, inputs, paymentProcess, linkedBankCards, getLinkedBankCards, isGetLinkBankCard, selectedVASProducts]);

    return (
        <div>
            <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                            <ArrowBackIosIcon className={classes.linkColor} onClick={backToVASViewProducts} />
                            <span
                                className={classes.title}>Collections</span>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={handleVASFormSubmit}>
                        <GridItem xs={12} sm={12} md={12}>
                            <p className={classes.regularFontSize + ' ' + classes.textLeft + ' m-b-0'}>
                                <b>Which card would you like to use?</b></p>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} custom-padding="p-t-0">
                            <ValidationTextField
                                fullWidth
                                label={paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()}` : "Select a card"}
                                select
                                variant="filled"
                                id="fromWalletCard"
                                type={paymentProcess === 'WALLETS' ? "number" : "text"}
                                error={!!(errors.fromWalletCard)}
                                name="fromWalletCard"
                                value={inputs?.fromWalletCard}
                                onChange={handleInputChange}
                                onKeyUp={handleInputKeyUp}>
                                {paymentProcess === 'WALLETS' ?
                                    wallets && wallets.length > 0 ?
                                        wallets?.map((wallet) => {
                                            return (<MenuItem key={wallet?.walletId}
                                                value={wallet?.walletId}>{wallet?.name}({getSymbolFromCurrency(wallet?.currency)} {wallet?.availableBalance.toFixed(2)})</MenuItem>)
                                        }) : <MenuItem key={""} value={""}>{`No ${walletTitleText?.toLowerCase()} found`}</MenuItem> :
                                    linkedBankCards?.map((bankCard) => {
                                        return (<MenuItem key={bankCard?.cardOnFileId}
                                            value={bankCard?.cardOnFileId}>{bankCard?.alias !== '' && bankCard?.last4Digits !== '' ? bankCard?.alias + ' - **' + bankCard?.last4Digits : 'No card found'}</MenuItem>)
                                    })
                                }
                            </ValidationTextField>
                            {errors.fromWalletCard && <Danger><small>{errors.fromWalletCard}</small></Danger>}
                            <div className="external-links" style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginTop: '10px'
                            }}>
                                <span onClick={linkNewBankCard} style={{ display: 'flex', margin: '0 10px 0 0' }}
                                    className={classes.linkColor + ' ' + classes.regularFontSize}><AddCircleOutlineOutlined />&nbsp;
                                    <b>Add a card</b></span>
                                <span onClick={goToManageBankCards} style={{ display: 'flex', margin: '0 0 0 10px' }}
                                    className={classes.linkColor + ' ' + classes.regularFontSize}><PaymentIcon />&nbsp;
                                    <b>Manage my cards</b></span>
                            </div>
                        </GridItem>


                        <GridItem xs={12} sm={12} md={12}>
                            <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                                disabled={Object.keys(errors).length !== 0}>Pay
                                now</Button>
                        </GridItem>
                    </form>
                </GridItem>
            </GridContainer>
            {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
        </div>
    )
}


const mapStateToProps = state => ({
    appData: state.applicationData.appData
})

export default connect(mapStateToProps)(VASBuyKganyaProducts)
