import React, { useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";
import {
  AuthQueryParamWithTemplateId,
  AuthRouteWithoutTemplateId,
} from "../../default-route.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function CreatePassword(props) {
  //console.log("CreatePassword Props :: ", props?.appData);
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.passwordPINText;
  const passwordPINControlName = passwordPINText?.toLowerCase();
  const passwordPINInputControlGridLength = 12 / inputControls;
  
  const userIdentity = useLocation().state?.userIdentity;
  const userId = useLocation().state?.userId;
  const tenantId = useLocation().state?.tenantId;
  const tenantTokenData = useLocation().state?.tenantTokenData;

  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(passwordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});

  const redirectToConfirmPassword = (newPasswordPIN) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('confirm-password')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { userId: userId, userIdentity: userIdentity, tenantId: tenantId, tenantTokenData: tenantTokenData, newPasswordPIN: newPasswordPIN }
    });
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen: fromScreen }
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateCreatePasswordForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateCreatePasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateCreatePasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateCreatePasswordForm = () => {
    //Email errors
    return ValidateInputControls(inputs, passwordPINControlName, inputControls, passwordPINText, pinPasswordLength)
  }

  const handleCreatePasswordFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateCreatePasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      if (userIdentity) {
        redirectToConfirmPassword(ReturnInputControlsConcatValues(inputs, passwordPINControlName, inputControls));
      } else {
        redirectToErrorScreen(`/auth/signup`, `error`);
      }
    }
  }

  React.useEffect(() => {
    window.onpopstate = (event) => {
      event.preventDefault();
      window.location.replace(`${window.location.origin}${process.env.PUBLIC_URL}/auth/signup${AuthQueryParamWithTemplateId()}`);
    };
  }, [])

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1>Let's keep <br /> you safe!</h1>
              </GridItem>
            </GridContainer>
            <form onSubmit={handleCreatePasswordFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '16px' }}><strong>Create your {passwordPINText}.</strong></p>
                </GridItem>
                {ReturnInputControlsMap(passwordPINControlName, inputControls)?.map((control, index, array) => {
                  return (
                    <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength} custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                      <ValidationTextField
                        inputProps={{ maxLength: inputControls > 1 ? 1 : 50, inputMode: pinAsPassword ? 'numeric' : '' }}
                        autoComplete="new-password"
                        className={[inputControls > 1 ? passwordClasses.oneDigitPassword : '', inputControls === 4 ? ' fourDigitInput' : ''].join(' ')}
                        label={inputControls > 1 ? '' : 'Create your ' + passwordPINText}
                        fullWidth
                        variant="filled"
                        id={control}
                        type="password"
                        error={!!(errors[passwordPINControlName])}
                        name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                        value={inputs[control] || ""}
                        onChange={handleInputChange}
                        onKeyDown={handleOnKeyDown}
                        onKeyUp={handleInputKeyUp} />
                    </GridItem>
                  )
                })
                }
                <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                  {errors[passwordPINControlName] && <Danger><small>{errors[passwordPINControlName]}</small></Danger>}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors[passwordPINControlName]) || !ReturnInputControlsConcatValues(inputs, passwordPINControlName, inputControls)}>Next</Button>
                </GridItem>
              </GridContainer>
              <br /><br />
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  createPasswordHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword)