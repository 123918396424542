import React, { useCallback, useState } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../../style.scss";
import { ReactComponent as SuccessTransactionIcon } from '../../../assets/img/success_transfer_wallet.svg'
import { loginToApp } from "../../../redux-services/actions/actions.js";
import { SpinnerComponent } from "react-element-spinner";
import { KWalletTopupsApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles(themeStyle);

const delay = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function SuccessViaCard(props) {
  const manifestSettings = props?.appData?.template?.manifestSettings;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const walletDetailData = props?.appData?.walletDetailData;
  const [topupData, setTopupData] = useState(props?.appData?.topupData);
  const [requestCount, setRequestCount] = useState(0);

  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(true);
  const [alertData, setAlert] = React.useState(null);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const handleSubmit = () => {
    props.appData.walletDetailData = null;
    props.appData.fromScreen = null;
    props.appData.topupData = null;
    props.appData.isRefresh = true;
    props.successViaCardHandler({ ...props?.appData });
    history.push({
      pathname: '/pages/dashboard',
      state: { walletDetailData: walletDetailData }
    });
  }

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const getPaymentStatus = useCallback((topupData) => {
    if (requestCount > 9 || topupData?.status !== 'PENDING') {
      if (requestCount > 9) {
        const updatedTopupData = {
          ...topupData,
          status: 'TIMEDOUT'
        }
        setTopupData(updatedTopupData);
      }
      setLoading(false);
      return;
    }
    if (requestCount < 10 && topupData && topupData?.toWallet && topupData?.topupId && topupData?.status === 'PENDING') {
      const walletTopupsAPI = new KWalletTopupsApi();
      walletTopupsAPI.tenantsTenantIdWalletsWalletIdTopupsTopupIdGet(props?.appData?.auth?.tenantId, topupData?.toWallet, topupData?.topupId).then(async (walletTopupsAPIResponse) => {
        if (walletTopupsAPIResponse.data && walletTopupsAPIResponse.data?.topupId && walletTopupsAPIResponse.data?.status) {
          if (walletTopupsAPIResponse.data?.status === 'PENDING') {
            await delay(3000);
            setRequestCount(requestCount + 1);
          } else {
            const updatedTopupData = {
              ...topupData,
              ...walletTopupsAPIResponse?.data
            }
            setTopupData(updatedTopupData);
          }
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    } else {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    }
  }, [props, redirectToErrorScreen, requestCount]);

  React.useEffect(() => {
    if (requestCount < 9) {
      getPaymentStatus(topupData);
    }
  }, [getPaymentStatus, topupData, requestCount]);

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              {topupData?.status === 'SUCCESSFUL' ? <SuccessTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} /> : null}
              {topupData?.status === 'PENDING' ? <HourglassEmptyIcon fill={primaryIconColor} stroke={primaryIconColor} /> : null}
              {topupData?.status !== 'SUCCESSFUL' && topupData?.status !== 'PENDING' ? <ErrorOutline fill={primaryIconColor} stroke={primaryIconColor} /> : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0'}>
                <b>
                  {topupData?.status === 'SUCCESSFUL' ? 'Topup successful!' : null}
                  {topupData?.status === 'PENDING' ? 'Pleas wait, we are processing...' : null}
                  {topupData?.status === 'TIMEDOUT' ? 'We are experiencing an issue while processing your payment. Please try again later.' : null}
                  {topupData?.status !== 'SUCCESSFUL' && topupData?.status !== 'PENDING' && topupData?.status !== 'TIMEDOUT' ? 'Oops! Topup failed!' : null}
                </b>
              </h4>
              {topupData?.status === 'SUCCESSFUL' ?
                <div>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Your ({manifestSettings?.brand?.toLowerCase()} {walletTitleText?.toLowerCase()}-{topupData?.toWallet}) balance is</b></p>
                  <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+walletDetailData?.currentBalance + +topupData?.amount).toFixed(2)}</b></h2>
                  <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>Available balance <b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+walletDetailData?.availableBalance + +topupData?.amount).toFixed(2)}</b></p>
                </div>
                : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>
                {topupData?.status === 'SUCCESSFUL' ? 'Done' : null}
                {topupData?.status === 'PENDING' ? 'Wait...' : null}
                {topupData?.status !== 'SUCCESSFUL' && topupData?.status !== 'PENDING' ? 'Back' : null}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  successViaCardHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessViaCard)