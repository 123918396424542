import {
  defaultFont,
  container,
  grayColor,
  hexToRgb
} from "../../eclipse.js";
import {primaryColor, primaryFooterBackgroundColor} from "../../../../style.scss"

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px",
    textAlign: "center"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    fontSize: "14px",
    float: "right!important"
  },
  footer: {
    bottom: "0",
    // borderTop: "1px solid rgba(" + hexToRgb(grayColor[11]) + ", 0.5)",
    padding: "15px 0",
    ...defaultFont
  },
  container,
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  "@media (max-width: 1200px)": {
    footer: {
      position: "fixed",
      width: "100%",
      zIndex: "9999",
      backgroundColor: primaryFooterBackgroundColor,
      borderTop: "1px solid rgba(" + hexToRgb(grayColor[11]) + ", 0.5)",
    },
  },
};
export default footerStyle;
