import { ReturnQueryParamTemplateId, ReturnStoredTemplateId, ReturnStoredUserId } from "./get-template-hostname";

let appStateData = window.localStorage.getItem('appstate');
const DefaultAuthRoute = () => {
    let route = `/auth/signup`;
    const userId = ReturnStoredUserId();
    if (userId) {
        // let appStateData = window.localStorage.getItem('appstate');
        if (appStateData) {
            appStateData = atob(appStateData);
            appStateData = JSON.parse(appStateData);
            // console.log("appStateData",appStateData);
            if(appStateData.auth){
                route = `/pages/dashboard`;
            }
        }else {
            route = `/auth/login`;
        }
    }

    const queryParamsTemplateId = ReturnQueryParamTemplateId();
    if (queryParamsTemplateId) {
        //console.log("QueryParamsTemplateId :: ", queryParamsTemplateId);
        window.localStorage.setItem('templateId', queryParamsTemplateId);
        route = `${route}?templateId=${queryParamsTemplateId}`;
    }else{
        if (!appStateData) {
        window.localStorage.removeItem('templateId');
        }
    }
    return route;
}

const AuthQueryParamWithTemplateId = () => {
    const templateId = ReturnStoredTemplateId();
    if (templateId) {
        return `?templateId=${templateId}`;
    }
    return null;
}

const AuthRouteWithTemplateId = (defaultRoute) => {
    let route = `/auth/${defaultRoute}`;
    let queryParams = AuthQueryParamWithTemplateId();
    if(queryParams){
        route = `${route}${queryParams}`;
    }
    return route;
}

const AuthRouteWithoutTemplateId = (defaultRoute) => {
    let route = '/auth/signup';
    if (defaultRoute) {
        route = `/auth/${defaultRoute}`;
    }
    return route;
}

export {
    DefaultAuthRoute,
    AuthRouteWithTemplateId,
    AuthRouteWithoutTemplateId,
    AuthQueryParamWithTemplateId
}