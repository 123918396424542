import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import {useHistory} from "react-router-dom";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import {primaryIconColor} from "../../style.scss"
import {ReactComponent as EnterPin} from "../../assets/img/icon_enter_password.svg";


const useStyles = makeStyles(themeStyle);

export function SuccessResetPhysicalCardPIN() {
    const history = useHistory();
    const classes = useStyles();

    const goToWalletDetails = () => {
        history.push({
            pathname: '/pages/wallet-details',
        });
    };
    return (
        <div>
            <div className="wallet-list">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <EnterPin className={classes.screenCenterPrimaryIcon} fill={primaryIconColor}
                                          stroke={primaryIconColor}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'}
                                   style={{marginBottom: '30px', fontSize: '20px'}}>
                                    <b>New PIN set successfully.</b>
                                </p>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button className={classes.buttonRadius} size="lg" block color="primary"
                                        onClick={goToWalletDetails}>Done</Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>

        </div>
    );
}

const mapStateToProps = state => ({
    appData: state.applicationData.appData
})

export default connect(mapStateToProps)(SuccessResetPhysicalCardPIN)