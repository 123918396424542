import React, { useCallback, useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { ReactComponent as ResetPasswordIcon } from '../../assets/img/icon_reset_passcode.svg'
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from 'react-element-spinner';
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { load } from 'recaptcha-v3'
import { EnvConfig } from "../../environment.js";
import {
  CreateNewInputControls,
  FocusInNextControl,
  FocusInPrevControl,
  ReturnChangedControlValue,
  ReturnInputControlsConcatValues,
  ReturnInputControlsMap,
  ReturnKeyDownControlValue,
  ValidateInputControls
} from "../../password-pin.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ResetPassword(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const otpControlName = 'otp';
  const otpControlText = 'OTP';
  const userIdentity = useLocation().state?.userIdentity;
  const verifyOTPLength = props?.appData?.template?.pwaAppOptions?.verifyOTPLength;
  const verifyOTPControlGridLength = 12 / verifyOTPLength;

  const scannedQRData = useLocation().state?.scannedQRData;
  const paymentDetails = useLocation().state?.paymentDetails;
  const cardDetailData = useLocation().state?.cardDetailData;
  const fromScreen = useLocation().state?.fromScreen;

  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(otpControlName, verifyOTPLength) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const goToConfirmPaymentPin = useCallback(() => {
    history.push({
      pathname: '/pages/confirm-payqr-pin',
      state: { scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }, [history, scannedQRData, fromScreen, paymentDetails, cardDetailData]);

  const goToUserProfile = useCallback(() => {
    history.push({
      pathname: '/pages/change-payment-pin',
      state: { isForPaymentPinChange: true }
    });
  }, [history])

  const goToCreateNewPassword = (userOTP) => {
    history.push({
      pathname: '/pages/create-payment-pin',
      state: { userIdentity, userOTP, scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }

  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, verifyOTPLength);
    setInputs(inputControlValue);
    const validationErrors = validateResetPasswordForm();
    setErrors(validationErrors);
    const otpValue = ReturnInputControlsConcatValues(inputs, otpControlName, verifyOTPLength)
    if (otpValue.length === verifyOTPLength) {
      handleResetPasswordFormSubmit()
    }
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateResetPasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, verifyOTPLength);
        setInputs(inputControlValue);
        const validationErrors = validateResetPasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const validateResetPasswordForm = () => {
    //Email errors
    return ValidateInputControls(inputs, otpControlName, verifyOTPLength, otpControlText, verifyOTPLength)
  }

  const handleResetPasswordFormSubmit = () => {
    const validationErrors = validateResetPasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      goToCreateNewPassword(`${ReturnInputControlsConcatValues(inputs, otpControlName, verifyOTPLength)}`);
    }
  }

  const generateOTP = (event) => {
    event.preventDefault();
    if (userIdentity) {
      setLoading(true);
      load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
        reCaptcha.execute('OTP_VERIFICATION').then((token) => {
          if (token) {
            const headers = { gCaptcha: token };
            const httpRequestConfiguration = HttpRequestConfiguration();
            const resetPasswordAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
            httpRequestConfiguration.baseOptions = { headers };
            resetPasswordAPI.globalIdentitiesIdentityPasswordChangeInitPost(`${identityPrefix}PAYMENTPIN-${userIdentity}`).then(() => {
              setLoading(false);
            }).catch((error) => {
              setLoading(false);
              if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
              } else {
                redirectToErrorScreen(`/pages/dashboard`, `error`);
              }
            });
          } else {
            showAlert(true, 'ERROR', `Please try again!`);
          }
        })
      });
    } else {
      redirectToErrorScreen(`/auth/dashboard`, `error`);
    }
  }

  React.useEffect(() => {
    if(fromScreen === 'USERPROFILE'){
      window.onpopstate = (event) => {
        event.preventDefault();
        console.log("hereee")
        if (fromScreen !== 'USERPROFILE') {
          goToConfirmPaymentPin();
        } else {
          goToUserProfile();
        }
      }
    } else {
      return() => {
        window.onpopstate = (event) => {
          event.preventDefault();
          console.log("hereee")
          if (fromScreen !== 'USERPROFILE') {
            goToConfirmPaymentPin();
          } else {
            goToUserProfile();
          }
        }
      }
    }
    
  }, [fromScreen, goToConfirmPaymentPin, goToUserProfile])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={fromScreen !== 'USERPROFILE' ? goToConfirmPaymentPin : goToUserProfile} />
                <span className={classes.title}>Forgot payment PIN</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + ' m-t-0 m-b-0'} style={{ fontSize: '20px' }}><b>Reset your payment PIN</b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <ResetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize + ' ' + classes.textLeft + ' ' + classes.textLeft + ' m-b-0 m-t-0'}><b>Please enter the OTP PIN sent to you via SMS to +27 ** *** {userIdentity.substring(userIdentity.length - 4, userIdentity.length)}.</b></p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={handleResetPasswordFormSubmit}>
                  <GridContainer>
                    {ReturnInputControlsMap(otpControlName, verifyOTPLength)?.map((control) => {
                      return (
                        <GridItem key={control} xs={verifyOTPControlGridLength} sm={verifyOTPControlGridLength} md={verifyOTPControlGridLength} custom-padding={passwordClasses.customPadding}>
                          <ValidationTextField
                            inputProps={{ maxLength: verifyOTPLength > 1 ? 1 : 50, inputMode: 'numeric' }}
                            className={verifyOTPLength > 1 ? passwordClasses.oneDigitPassword : ''}
                            label={verifyOTPLength > 1 ? '' : otpControlText}
                            fullWidth
                            variant="filled"
                            id={control}
                            type="password"
                            error={!!(errors[otpControlName])}
                            name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                            value={inputs[control] || ""}
                            onChange={handleInputChange}
                            onKeyDown={handleOnKeyDown}
                            onKeyUp={handleInputKeyUp} />
                        </GridItem>
                      )
                    })
                    }
                    <GridItem xs={12} sm={12} md={12} className='p-t-0' style={{ paddingTop: '0 !important' }}>
                      {errors[otpControlName] && <Danger><small>{errors[otpControlName]}</small></Danger>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p className={classes.regularFontSize + ' ' + classes.textCenter} style={{ marginTop: '60px', fontWeight: '400' }}>Didn't receive the SMS? <span className={classes.linkColor} onClick={generateOTP}>Resend PIN</span> </p>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ResetPassword)