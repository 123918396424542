import React, {useCallback} from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor,primaryTextColor } from "../../style.scss";
import { ReactComponent as SuccessTransactionIcon } from '../../assets/img/success_transfer_wallet.svg'
import { ReactComponent as PaymentFailed } from '../../assets/img/payment_failed.svg'
import { grayColor } from "../../assets/jss/eclipse.js";
import moment from "moment";
const useStyles = makeStyles(themeStyle);

export function TransactionReceipt(props) {
  //console.log("TransactionReceipt Props :: ", props?.appData);
  const transactionDetail = useLocation().state?.transactionDetail;
  //console.log(transactionDetail);

  const history = useHistory();
  const classes = useStyles();

  const backToTransactionHistory = useCallback(() => {
    history.push({
      pathname: `/pages/transaction-history`
    });
  },[history])

  React.useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      window.onpopstate = () => {
        backToTransactionHistory();
      }
    };
  }, [history,backToTransactionHistory])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToTransactionHistory}/>
              <span className={classes.title}>Receipt</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
          {transactionDetail?.status === 'SUCCESSFUL' ?
            <SuccessTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} /> :
            <PaymentFailed fill={primaryIconColor} stroke={primaryIconColor} />
          }
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <p style={{ color: primaryTextColor }} className={classes.textCenter + ' m-t-0'}>
            <b>
              {transactionDetail?.status === 'SUCCESSFUL' ?
                'Payment successful!' :
                'Payment failed!'
              }
            </b>
          </p>
          <p className={classes.textCenter + ' m-b-0 m-t-0'}>
            <b>{transactionDetail?.merchantName}</b>
          </p>
          <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'} style={{ fontWeight: '400' }}>
          <b>  {moment(transactionDetail?.created).format("MM/DD/yyyy HH:mm:ss")}</b>
          </p>
          {transactionDetail?.retrievalReferenceNumber ?
          <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'} style={{ fontWeight: '400' }}>
            <b>Reference no. {transactionDetail?.retrievalReferenceNumber}</b>
          </p> : null }
          <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'} style={{ fontWeight: '400',paddingBottom:'5px' }}>
            <b> Amount</b>
          </p>
          <p className={ classes.textCenter + ' m-b-0 m-t-0'}>
            <b style={{ fontSize :'35px', color: primaryColor }}>{getSymbolFromCurrency(transactionDetail?.currency)}{(Math.abs(transactionDetail?.amount)).toFixed(2)}</b>
          </p>
        </GridItem>
        {/* {transactionDetail?.status === 'SUCCESSFUL' ?
          <GridItem xs={12} sm={12} md={12}>
            <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'} style={{ fontWeight: '400' }}>
              Voucher
            </p>
            <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>
            <b>{transactionDetail?.paymentData.replace('MENU-','')}</b>
            </p>
          </GridItem>
          : null} */}
        <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px', borderBottomWidth: 'thin', borderBottomStyle: 'solid', borderBottomColor: grayColor[1] }}>
          <p className={classes.regularFontSize + " "+classes.textCenter}><b>Transaction Details</b></p>
        </GridItem>
      </GridContainer>
      {transactionDetail?.merchantId?
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
            <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
              Merchant ID
            </p>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px' }}>
            <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right',wordBreak: 'break-all' }}>
              <b>{transactionDetail?.merchantId}</b>
            </p>
          </GridItem>
        </GridContainer>
      :null}
      <GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px',paddingTop: '0px'}}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
            Card Number
          </p>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px',paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
            <b>**** **** **** {transactionDetail?.paymentInstrumentInfo?.cardLast4}</b>
          </p>
        </GridItem>
      </GridContainer>
     <GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
            Card Type
          </p>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
            <b>{transactionDetail?.paymentInstrumentInfo?.cardType ? transactionDetail?.paymentInstrumentInfo?.cardType : "VISA" }</b>
          </p>
        </GridItem>
      </GridContainer>
      {transactionDetail?.authCode?  <GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
            Auth Code
          </p>
        </GridItem>
       <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
            <b>{transactionDetail?.authCode}</b>
          </p>
        </GridItem>
      </GridContainer>:null}
      {transactionDetail?.authMethod?
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
                Auth Method
              </p>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
                <b>{transactionDetail?.authMethod}</b>
              </p>
            </GridItem>
          </GridContainer>
      :null}
      {transactionDetail?.ica?<GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
            ICA
          </p>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
            <b>{transactionDetail?.ica}</b>
          </p>
        </GridItem>
      </GridContainer>:null}
      {transactionDetail?.meterNumber?<GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
            Meter Number
          </p>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
            <b>{transactionDetail?.meterNumber}</b>
          </p>
        </GridItem>
      </GridContainer>:null}
      {transactionDetail?.token?<GridContainer>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'left', fontWeight: '400' }}>
            You will receive token on
          </p>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
          <p className={classes.regularFontSize + ' m-b-0 m-t-0'} style={{ textAlign: 'right' }}>
            <b>{transactionDetail?.token}</b>
          </p>
        </GridItem>
      </GridContainer>:null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
          <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToTransactionHistory}>Done</Button>
        </GridItem>
      </GridContainer >
    </div >
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReceipt)