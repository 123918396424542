import React, { useState, useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from "../../../components/CustomButtons/Button.js";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { WalletWithdrawalType } from "../../../eclipse-react-sdk/services/models";
import { LWalletWithdrawalsApi } from "eclipse-react-sdk/services";
import { v4 as uuidv4 } from 'uuid';
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { primaryColor } from "../../../style.scss";
import { SpinnerComponent } from "react-element-spinner";
import FormattedAmount from "../../../formattedAmount.js";
import { AmountTextField } from "../../../components/Amount/AmountTextField.js";
import { checkAmount } from "../../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function WithdrawalRetailerAmount(props) {
  const walletDetailData = useLocation().state?.walletDetailData;
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
  const amountOptions = pwaAppOptions?.cashInOutLimits?.cashOut;

  const fromScreen = useLocation().state?.fromScreen;

  const history = useHistory();
  const classes = useStyles();

  const [inputs, setInputs] = useState({ withdrawalWallet: walletDetailData?.walletId, amount: '' });
  const [errors, setErrors] = useState({});
  const [pastedAmount, setPastedAmount] = useState(0);

  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = React.useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const validateRetailerForm = (inputs) => {
    const errors = {}

    if (!inputs.withdrawalWallet) {
      errors.withdrawalWallet = `${walletTitleText} required!`
    } else if (!/^[0-9]{1,}$/i.test(inputs.withdrawalWallet)) {
      errors.withdrawalWallet = `Invalid ${walletTitleText.toLowerCase()}!`
    }

    if (!inputs.amount) {
      errors.amount = 'Amount required!'
    } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
      errors.amount = 'Amount invalid!'
    } else if (!Number.isInteger(+inputs.amount)) {
      errors.amount = `The amount can’t have cents, only a Rand value.`;
    } else if (checkAmount(amountOptions?.retailer, +inputs.amount)) {
      errors.amount = `${amountOptions?.retailer?.error}`;
    }

    return errors
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    if (event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateRetailerForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData, 'PASTE');
    inputs['amount'] = formattedData;
    if (formattedData > 0) {
      setPastedAmount(inputs?.amount);
    }
    const validationErrors = validateRetailerForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if (pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      } else {
        inputs['amount'] = FormattedAmount(event.target.value);
      }
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateRetailerForm(inputs);
    setErrors(validationErrors);
  }

  const backToWalletWithdrawal = () => {
    history.push({
      pathname: `/pages/withdrawal`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }

  const generateWithdrawalRetailerToken = useCallback((withdrawalRetailerTokenData) => {
    history.push({
      pathname: '/pages/withdrawal-retail-token',
      state: { withdrawalRetailerData: inputs, withdrawalRetailerTokenData: withdrawalRetailerTokenData, walletDetailData: walletDetailData, fromScreen: fromScreen }
    });
  }, [history, fromScreen, inputs, walletDetailData]);

  const doWithdrawalAtRetailer = useCallback(() => {
    setLoading(true);
    const walletWithdrawalsAPI = new LWalletWithdrawalsApi();
    const withdrawalAtRetailerRequestBody = {
      externalUniqueId: uuidv4(),
      amount: inputs?.amount,
      type: WalletWithdrawalType.ZAPNPCASH
    }
    walletWithdrawalsAPI.tenantsTenantIdWalletsWalletIdWithdrawalsPost(props?.appData?.auth?.tenantId, walletDetailData?.walletId, withdrawalAtRetailerRequestBody).then((walletWithdrawalsAPIResponse) => {
      setLoading(false);
      if (walletWithdrawalsAPIResponse.data && walletWithdrawalsAPIResponse.data?.withdrawalId && walletWithdrawalsAPIResponse.data?.token) {
        generateWithdrawalRetailerToken(walletWithdrawalsAPIResponse.data);
      } else {
        showAlert(true, 'ERROR', `Something went wrong. Please try again!`);
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, inputs, redirectToErrorScreen, walletDetailData, generateWithdrawalRetailerToken]);

  const handleEFTFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateRetailerForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      const errors = {};
      if (checkAmount(amountOptions?.retailer, +inputs.amount)) {
        errors.amount = `${amountOptions?.retailer?.error}`;
        setErrors(errors);
      } else if (+inputs.amount > walletDetailData?.availableBalance) {
        errors.amount = `The maximum amount is ${getSymbolFromCurrency(walletDetailData?.currency)}${walletDetailData?.availableBalance.toFixed(2)}. Please decrease your amount.`
        setErrors(errors);
      } else if (!Number.isInteger(+inputs.amount)) {
        errors.amount = `The amount can’t have cents, only a Rand value.`;
        setErrors(errors);
      } else {
        doWithdrawalAtRetailer();
      }
    }
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToWalletWithdrawal} />
              <span className={classes.title}>Withdrawal at a retailer</span>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' m-b-0'}>
                <i>Collect your money at Pick n Pay. You will need your valid ID/Passport and the token number we will SMS to you.</i>
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={handleEFTFormSubmit}>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'}><b>Which {walletTitleText.toLowerCase()} would you like to use?</b></p>
              <ValidationTextField
                disabled
                fullWidth
                label={`Select a ${walletTitleText.toLowerCase()}`}
                select
                variant="filled"
                id="withdrawalWallet"
                type="number"
                error={(errors.withdrawalWallet) ? true : false}
                name="withdrawalWallet"
                value={inputs.withdrawalWallet}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}>
                <MenuItem value={walletDetailData?.walletId}>{walletDetailData?.name}({getSymbolFromCurrency(walletDetailData?.currency)} {walletDetailData?.availableBalance.toFixed(2)})</MenuItem>
              </ValidationTextField>
              {errors.withdrawalWallet && <Danger><small>{errors.withdrawalWallet}</small></Danger>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' m-b-0 m-t-0'}><b>How much would you like to withdraw?</b></p>
              <AmountTextField
                {...props?.appData}
                inputs={inputs}
                errors={errors}
                walletDetailData={walletDetailData}
                onPaste={handleInputAmountPaste}
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={(errors.withdrawalWallet || errors.amount) ? true : false}>Continue</Button>
            </GridItem>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(WithdrawalRetailerAmount)
