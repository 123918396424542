import React, { useCallback, useState } from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import TextField from '@material-ui/core/TextField';
import Button from "../../components/CustomButtons/Button.js";
import { cardShadowColor, primaryColor, secondaryTextColor } from "../../style.scss";
import { ReactComponent as ConfirmTransactionIcon } from '../../assets/img/journey_transfer.svg'
import { ReactComponent as InfoIcon } from '../../assets/img/info.svg'
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import Danger from "../../components/Typography/Danger.js";
import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import {
  CreateVASInputControls,
  ReturnPartialPaymentAmountAllowed,
  ValidateVASInputControls
} from "../VAS/VASControls.js";
import FormattedAmount from "../../formattedAmount.js";
import { CardStatus } from "../../eclipse-react-sdk/services/models";
import { AmountTextField } from "../../components/Amount/AmountTextField.js";
import { CardContent, MenuItem } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import { AmountDisplay } from "../../components/Amount/AmountDisplay.js";
import { loginToApp } from "../../redux-services/actions/actions.js";
import { FormatCurrency } from "../../currency-formatter.js";
import { SpinnerComponent } from "react-element-spinner";
import AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import ReturnCardType from "../../components/PaymentOptions/ReturnCardType";
import Dialog from "@material-ui/core/Dialog";
import { whiteColor } from "../../assets/jss/eclipse";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { checkAmount } from "../../utils.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function PayAmount(props) {
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText
  const pwaAppOptions = props?.appData?.template?.pwaAppOptions;
  const amountOptions = pwaAppOptions?.cashInOutLimits;
  const scannedQRData = useLocation().state?.scannedQRData;
  const wallets = props?.appData?.wallets;
  const fromScreen = useLocation().state?.fromScreen;

  const [linkedBankCards, setLinkedBankCards] = React.useState([]);
  const [isGetLinkBankCard, setGetLinkBankCard] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [tipType, setTipType] = React.useState('AMOUNT');
  const defaultPaymentProcess = scannedQRData && scannedQRData?.walletId > 0 && wallets ? 'WALLETS' : 'CARDS';
  const [paymentProcess] = React.useState(defaultPaymentProcess);
  const isPartialPaymentAmountAllowed = ReturnPartialPaymentAmountAllowed(scannedQRData?.partialPaymentAllowed);

  const history = useHistory();
  const classes = useStyles();

  const defaultAmount = (scannedQRData?.amount && scannedQRData?.amount > 0) ? scannedQRData?.amount : 0;
  const [inputs, setInputs] = useState({
    fromWalletCard: '',
    toWallet: scannedQRData?.walletId,
    amount: defaultAmount > 0 ? defaultAmount : '', ...CreateVASInputControls(scannedQRData?.additionalFields)
  });
  const [errors, setErrors] = useState({});
  const [alertData, setAlert] = React.useState(null);
  const [pastedAmount, setPastedAmount] = useState(0);
  const [pastedTipAmount, setPastedTipAmount] = useState(0);
  const [openInfoDialog, setOpenInfo] = useState(false);

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const handleInputAmountPaste = (event) => {
    event.persist();
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
    let formattedData = FormattedAmount(pastedData);
    if (event.target.name === 'amount') {
      inputs['amount'] = formattedData;
      if (formattedData > 0) {
        setPastedAmount(inputs?.amount);
      }
    }
    if (event.target.name === 'tipAmount') {
      inputs['tipAmount'] = formattedData;
      if (formattedData > 0) {
        setPastedTipAmount(inputs?.tipAmount);
      }
    }
    const validationErrors = validateQRForm(inputs);
    setErrors(validationErrors);
  }

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'amount') {
      if (pastedAmount > 0) {
        inputs['amount'] = pastedAmount;
        setPastedAmount(0);
      } else {
        inputs['amount'] = FormattedAmount(event.target.value);
      }
      calculateTip(tipType, inputs?.amount);
    } else if (event.target.name === 'tipAmount') {
      if (pastedTipAmount > 0) {
        inputs['tipAmount'] = pastedTipAmount;
        setPastedTipAmount(0);
      } else {
        inputs['tipAmount'] = FormattedAmount(event.target.value);
      }
    } else if (event.target.name === 'fromWalletCard' && !event.target.value) {
      inputs[event.target.name] = linkedBankCards[0].cardOnFileId;
    } else {
      inputs[event.target.name] = event.target.value;
    }
    setInputs(inputs);
    const validationErrors = validateQRForm();
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    if (event.key === ',' && event.target.name === 'amount') {
      inputs['amount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    if (event.key === ',' && event.target.name === 'tipAmount') {
      inputs['tipAmount'] = FormattedAmount(event.target.value);
      setInputs(inputs);
    }
    const validationErrors = validateQRForm();
    setErrors(validationErrors);
  }

  const validateQRForm = () => {
    let errors = {};
    if (isPartialPaymentAmountAllowed) {
      const amountValidations = JSON.parse(scannedQRData?.amountOption);
      if (amountValidations) {
        if (inputs.amount < +amountValidations?.min) {
          errors.amount = `Invalid Amount! It must be minimum R${amountValidations?.min}!`;
          return errors;
        }
        if (inputs.amount > +amountValidations?.max) {
          errors.amount = `Invalid Amount! It must be maximum R${amountValidations?.max}!`;
          return errors;
        }
      }
    }

    if (errors && !errors.amount) {
      if (!inputs.amount) {
        errors.amount = 'Amount required!';
        return errors;
      } else if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d{2})?$/i.test(inputs.amount)) {
        errors.amount = 'Invalid amount!'
        return errors;
      } else if (checkAmount(amountOptions?.scanToPayQR, +inputs.amount)) {
        errors.amount = `${amountOptions?.scanToPayQR?.error}`;
        return errors;
      }
    }

    errors = ValidateVASInputControls(inputs, scannedQRData?.additionalFields);
    if (Object.keys(errors).length > 0) {
      return errors;
    }

    if (!inputs.fromWalletCard) {
      errors.fromWalletCard = paymentProcess === 'WALLETS' ? `From ${walletTitleText?.toLowerCase()} required!` : 'From card required!'
      return errors;
    } else if (!/^[0-9a-zA-Z-]{1,}$/i.test(inputs.fromWalletCard)) {
      errors.fromWalletCard = paymentProcess === 'WALLETS' ? `Invalid from ${walletTitleText?.toLowerCase()}!` : 'Invalid from card!'
      return errors;
    }

    return errors;
  }

  const goToManageBankCards = () => {
    history.push({
      pathname: '/pages/manage-linked-cards',
      state: { fromScreen: 'QRSCAN', scannedQRData, linkedBankCards }
    });
  };

  const linkNewBankCard = () => {
    history.push({
      pathname: '/pages/card-on-file',
      state: { fromScreen: 'QRSCAN', scannedQRData }
    });
  };

  const backToScan = useCallback(() => {
    history.push({
      pathname: '/pages/pay-qr',
      state: { fromScreen }
    });
  }, [history, fromScreen])

  const confirmToQRPay = (inputs) => {
    const selectedWallet = props?.appData?.wallets?.filter(wallet => wallet?.walletId === inputs.fromWalletCard)[0];
    const selectedCard = linkedBankCards?.filter(card => card?.cardOnFileId === inputs.fromWalletCard)[0];
    history.push({
      pathname: '/pages/confirm-pay-amount',
      state: {
        scannedQRData,
        paymentDetails: inputs,
        walletDetailData: selectedWallet,
        cardDetailData: selectedCard,
        paymentProcess
      }
    });
  }

  const handleQRFormSubmit = (event) => {
    event.preventDefault();
    calculateTip(tipType, inputs?.amount);
    const validationErrors = validateQRForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      confirmToQRPay(inputs);
    }
  }

  const calculateTip = (calculatePer, inputsAmount) => {
    setTipType(calculatePer);
    if (calculatePer === 'AMOUNT') {
      inputs['tipAmount'] = (inputs['tipAmount'] && (inputs['tipAmount'] > 0) && (tipType === calculatePer)) ? inputs['tipAmount'] : 0.00;
    } else {
      inputs['tipAmount'] = ((inputsAmount * (+calculatePer)) / 100).toFixed(2);
    }
    setInputs(inputs);
  }

  const getLinkedBankCards = useCallback(() => {
    setLoading(true);
    const linkedBankCardsAPI = new DCustomerManagementApi();
    linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId).then((linkedBankCardsAPIResponse) => {
      setLoading(false);
      if (linkedBankCardsAPIResponse?.data && linkedBankCardsAPIResponse?.data.length > 0) {
        if(scannedQRData?.acceptedPaymentMechanisms?.includes('VISAQR')){
          linkedBankCardsAPIResponse.data = linkedBankCardsAPIResponse.data.filter((card => (card.status === CardStatus?.ACTIVE && ReturnCardType(card?.bin) === "visa" && card.schemeToken && card?.schemeTokenStatus === CardStatus?.ACTIVE)))
        }
        setLinkedBankCards(linkedBankCardsAPIResponse.data);
      }
      setGetLinkBankCard(true);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [props, redirectToErrorScreen, scannedQRData]);

  const closeDialog = () => {
    setOpenInfo(false);
    inputs.fromWalletCard = linkedBankCards[0]?.cardOnFileId;
    delete errors.fromWalletCard;
    setInputs(inputs);
    setErrors(errors);
  };

  React.useEffect(() => {
    if (linkedBankCards.length < 1 && !isGetLinkBankCard) {
      getLinkedBankCards();
    }
    if (paymentProcess === 'CARDS' && linkedBankCards.length === 1) {
      inputs.fromWalletCard = linkedBankCards[0]?.cardOnFileId;
      setInputs(inputs);
    }

  }, [inputs, paymentProcess, linkedBankCards, getLinkedBankCards, isGetLinkBankCard]);

  React.useEffect(() => {
    return() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        history.push({
          pathname: '/pages/pay-qr',
          state: { fromScreen: 'dashboard' }
        });
      }
    }
  }, [history,fromScreen])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIcon className={classes.linkColor} onClick={backToScan}/>
              <span className={classes.title}>Pay a QR Code</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form autoComplete="off" onSubmit={handleQRFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                <ConfirmTransactionIcon/>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.textCenter+" m-t-0"}>
                  <strong>You are about to pay<br />{scannedQRData?.merchantName}</strong>
                </h4>
              </GridItem>
              {defaultAmount === 0 || (defaultAmount > 0 && isPartialPaymentAmountAllowed) ?
                <GridItem xs={12} sm={12} md={12}>
                  <p style={{
                    color: secondaryTextColor,
                    fontWeight: '500',
                    marginBottom: '0.25em',
                    marginTop: '0'
                  }}>With how much would you like to pay?</p>
                  <AmountTextField
                    {...props?.appData}
                    controlName={'amount'}
                    controlLable={'Amount'}
                    disabled={!(defaultAmount === 0 || (defaultAmount > 0 && isPartialPaymentAmountAllowed))}
                    inputs={inputs}
                    errors={errors}
                    onPaste={handleInputAmountPaste}
                    onChange={handleInputChange}
                    onKeyUp={handleInputKeyUp}/>
                </GridItem>
                : <AmountDisplay amount={(+inputs?.amount + (inputs?.tipAmount ? +inputs?.tipAmount : 0))}/>}
              {scannedQRData?.additionalFields?.map((control, index) => {
                return (
                  <GridItem xs={12} sm={12} md={12} key={index}>
                    {control?.id === 'tipAmount' ? <>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <p style={{
                              textAlign: 'center',
                              color: secondaryTextColor,
                              fontWeight: '500',
                              marginBottom: '0.25em',
                              marginTop: '0'
                            }}>Would you like to add a tip?</p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0' }}>
                            <Button className={classes.buttonRadius} style={{ borderRadius: '2em', width: '22%' }}
                                    size="sm" color={tipType === '10' ? 'primary' : 'transparent'} onClick={() => {
                              calculateTip('10', inputs?.amount)
                            }}>10%</Button>
                            <Button className={classes.buttonRadius} style={{ borderRadius: '2em', width: '22%' }}
                                    size="sm" color={tipType === '15' ? 'primary' : 'transparent'} onClick={() => {
                              calculateTip('15', inputs?.amount)
                            }}>15%</Button>
                            <Button className={classes.buttonRadius} style={{ borderRadius: '2em', width: '22%' }}
                                    size="sm" color={tipType === '20' ? 'primary' : 'transparent'} onClick={() => {
                              calculateTip('20', inputs?.amount)
                            }}>20%</Button>
                            <Button className={classes.buttonRadius} style={{ borderRadius: '2em', width: '30%' }}
                                    size="sm" color={tipType === 'AMOUNT' ? 'primary' : 'transparent'} onClick={() => {
                              calculateTip('AMOUNT', 0.00)
                            }}>Own</Button>
                          </GridItem>
                        </GridContainer>
                        {tipType === 'AMOUNT' ?
                          <GridItem xs={12} sm={12} md={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
                            <AmountTextField
                              {...props?.appData}
                              controlName={control?.id}
                              controlLable={control?.title}
                              inputs={inputs}
                              errors={errors}
                              onPaste={handleInputAmountPaste}
                              onChange={handleInputChange}
                              onKeyUp={handleInputKeyUp}/>
                            {errors[control?.id] && <Danger><small>{errors[control?.id]}</small></Danger>}
                          </GridItem>
                          : null}

                        <Card style={{ marginTop: '1em' }}>
                          <CardContent style={{ padding: '0px' }}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'nowrap',
                              justifyContent: 'space-between',
                              width: 'calc(100% - 2.5em)',
                              padding: '0em 1.25em 1em',
                              borderBottom: '1px solid ' + cardShadowColor
                            }}>
                              <b style={{ marginTop: '1em', color: secondaryTextColor, fontWeight: '500' }}>Tip</b>
                              <b style={{
                                color: primaryColor,
                                marginTop: '1em',
                                fontWeight: '700',
                                fontSize: '14px'
                              }}>{FormatCurrency((inputs?.tipAmount ? +inputs['tipAmount'] : 0), props?.walletDetailData?.currency)}</b>
                            </div>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'nowrap',
                              justifyContent: 'space-between',
                              width: 'calc(100% - 2.5em)',
                              padding: '0em 1.25em 1em'
                            }}>
                              <b style={{ marginTop: '0.75em', color: secondaryTextColor, fontWeight: '500' }}>Total</b>
                              <b style={{
                                color: primaryColor,
                                marginTop: '0.5em',
                                fontWeight: '700',
                                fontSize: '18px'
                              }}>{FormatCurrency((+inputs?.amount + +(inputs['tipAmount'] ? inputs['tipAmount'] : '0.00')), props?.walletDetailData?.currency)}</b>
                            </div>
                          </CardContent>
                        </Card>
                      </> :
                      <>
                        <ValidationTextField
                          fullWidth
                          label={control?.title}
                          variant="filled"
                          id={control?.id}
                          type="text"
                          error={!!(errors[control?.id])}
                          name={control?.id}
                          value={inputs[control?.id]}
                          onChange={handleInputChange}
                          onKeyUp={handleInputKeyUp}/>
                        {errors[control?.id] && <Danger><small>{errors[control?.id]}</small></Danger>}
                      </>
                    }
                  </GridItem>)
              })}

              <GridItem xs={12} sm={12} md={12}>
                <p style={{ color: secondaryTextColor, fontWeight: '500', marginBottom: '0.25em', marginTop: '0' }}>How
                  would you like to pay?</p>
                <ValidationTextField
                  fullWidth
                  label={"Select a card"}
                  select
                  variant="filled"
                  id="fromWalletCard"
                  type={"text"}
                  error={!!(errors.fromWalletCard)}
                  name="fromWalletCard"
                  value={inputs.fromWalletCard}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}>
                  {linkedBankCards?.map((bankCard) => {
                    return (<MenuItem key={bankCard?.cardOnFileId}
                                      value={bankCard?.cardOnFileId}>{bankCard?.alias !== '' && bankCard?.last4Digits !== '' ? bankCard?.alias + ' *** *** ' + bankCard?.last4Digits : 'No card found'}</MenuItem>)
                  })
                  }
                  <MenuItem
                    key={"cardInfo"}
                    value={""}
                    onClick={() => {
                      setOpenInfo(true)
                    }}
                  >
                    <p style={{display: 'flex',alignItems: 'center', lineHeight: '1'}}>
                      <InfoIcon />
                      <span className={classes.linkColor} style={{marginLeft:15}}>{"Not seeing your card?"}
                      </span></p>
                  </MenuItem>
                </ValidationTextField>
                {errors.fromWalletCard && <Danger><small>{errors.fromWalletCard}</small></Danger>}
                {paymentProcess === 'CARDS' ?
                  <div className="external-links"
                       style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px' }}>
                    <span onClick={linkNewBankCard} style={{ display: 'flex', margin: '0 10px 0 0' }}
                          className={classes.linkColor + ' ' + classes.regularFontSize}><AddCircleOutlineOutlined/>&nbsp;
                      <b>Add a card</b></span>
                    <span onClick={goToManageBankCards} style={{ display: 'flex', margin: '0 0 0 10px' }}
                          className={classes.linkColor + ' ' + classes.regularFontSize}><PaymentIcon/>&nbsp;<b>Manage my cards</b></span>
                  </div>
                  : null}
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                        disabled={(Object.keys(errors).length !== 0) || (paymentProcess === 'CARDS' && linkedBankCards && linkedBankCards.length < 1) || (inputs?.amount <= 0) || (inputs?.fromWalletCard === '')}>Pay
                  now</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {
        alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null
      }
      <Dialog open={openInfoDialog}>
        <IconButton aria-label="close"
                    style={{ position: "absolute", right: 0, background: whiteColor, padding: 5 }}
                    onClick={closeDialog}>
          <CloseIcon/>
        </IconButton>
        <div style={{ float: 'left', width: '100%', padding: '20px', boxSizing: 'border-box' }}>
          <p className={classes.regularFontSize} style={{ fontWeight:'normal'}}>
            If you are paying a Visa QR code, only Visa cards that are authorised for Visa QR payments will be available
            to be selected to make the payment.
          </p>
        </div>
      </Dialog>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  payAmountHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PayAmount)
