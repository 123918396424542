import React, { useCallback } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss"
import { ReactComponent as SuccessResetPassword } from '../../assets/img/icon_reset_passcode_sucess.svg'
import { logoutFromApp } from "../../redux-services/actions/actions";


const useStyles = makeStyles(themeStyle);

export function SuccessPassword(props) {
  const scannedQRData = useLocation().state?.scannedQRData;
  const paymentDetails = useLocation().state?.paymentDetails;
  const cardDetailData = useLocation().state?.cardDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const history = useHistory();
  const classes = useStyles();

  const goToConfirmPaymentPin = useCallback(() => {
    history.push({
      pathname: '/pages/confirm-payqr-pin',
      state: { scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }, [history, scannedQRData, fromScreen, paymentDetails, cardDetailData]);

  const goToUserProfile = useCallback(() => {
    history.push({
      pathname: '/pages/user',
    });
  }, [history])

  const backToParent = useCallback(() => {
    if (fromScreen === 'USERPROFILE') {
      goToUserProfile();
    } else {
      goToConfirmPaymentPin()
    }
  }, [fromScreen, goToUserProfile, goToConfirmPaymentPin])

  React.useEffect(() => {
    return() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToParent();
      }
    }
  }, [history, backToParent])

  return (
    <div>
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', margin: '60px 0 0px' }}>
                <SuccessResetPassword fill={primaryIconColor} stroke={primaryIconColor} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-t-0'} style={{ marginBottom: '30px', fontSize: '20px' }}><b>Your new payment PIN was set successfully.</b></p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" onClick={backToParent}>Done</Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  logoutFromAppHandler: data => dispatch(logoutFromApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPassword)