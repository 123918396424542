import React, {useCallback, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Button from "../../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import { grayColor } from "../../../assets/jss/eclipse.js";
import { loginToApp } from "../../../redux-services/actions/actions";
import { connect } from "react-redux";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { DCustomerManagementApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
// import HttpRequestConfiguration from "../../../http-request-configuration.js";
import { primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { DocumentType, RatifyType } from "eclipse-react-sdk/services/models";
import { AuthQueryParamWithTemplateId, AuthRouteWithoutTemplateId } from "../../../default-route";

const useStyles = makeStyles(themeStyle);

export function UploadPhoto(props) {
  // console.log("Upload Selfie Document props :: ", props);
  const selectedDocument = useLocation().state?.selectedDoc;
  // const ratifyRules = props?.appData?.template?.ratifyRules;
  const customer = props?.appData?.customer;
  console.log("customer :: ", customer);
  // const ratifyKeys = selectedDocument === '1' || selectedDocument === '3' ? [props?.appData?.template?.ratify[1], props?.appData?.template?.ratify[4], props?.appData?.template?.ratify[5], props?.appData?.template?.ratify[8], props?.appData?.template?.ratify[6]] : [props?.appData?.template?.ratify[0], props?.appData?.template?.ratify[2], props?.appData?.template?.ratify[3], props?.appData?.template?.ratify[7], props?.appData?.template?.ratify[6]];
  const ratifyKeys = ["isComparisonSelfieCorrect"];
  if(customer?.nationalIdentityNumber){
    ratifyKeys.push("isComparisonNationalIdentityCorrect")
  }else if(customer?.passportNumber){
    ratifyKeys.push("isComparisonPassportCorrect")
  }
  console.log("ratifyKeys :: ", ratifyKeys);
  const idDocument = useLocation().state?.idDocument;
  const idDocumentImageData = useLocation().state?.idDocumentImageData;
  const [selfieDocument, setSelfieDocument] = useState(useLocation().state?.selfieDocument);
  const selfieDocumentImageData = useLocation().state?.selfieDocumentImageData;
  const userIdentity = useLocation().state?.userIdentity;
  const userOTP = useLocation().state?.userOTP;
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const goBack = useCallback(() => {
    history.push({
      pathname: '/auth/scan-selfie',
      state: {
        userIdentity,
        userOTP,
        selectedDoc: selectedDocument,
        idDocument,
        selfieDocument,
        idDocumentImageData,
        selfieDocumentImageData
      },
    });
  }, [history, userIdentity, userOTP, selectedDocument, selfieDocumentImageData, idDocumentImageData, selfieDocument, idDocument]);

  React.useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        goBack();
      }
    };
  }, [history, goBack]);

  const goToCreateNewPassword = () => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId('create-new-password')}`,
      search: AuthQueryParamWithTemplateId(),
      state: { userIdentity, userOTP }
    });
  }
  const backToScanSelfie = () => {
    history.push({
      pathname: `/auth/scan-selfie`,
      state: { selectedDoc: selectedDocument, idDocument: idDocument, selfieDocument: selfieDocument, idDocumentImageData: idDocumentImageData, selfieDocumentImageData: null },
    });
  }

  const goToFailedRatifyKYC = (ratifyProcessData) => {
    history.push({
      pathname: `/auth/ratify-kyc-error`,
      state: { selectedDoc: selectedDocument, ratifyProcessData: ratifyProcessData }
    });
  }

  const doRatifyProcess = (httpRequestConfiguration) => {
    setLoading(true);
    const doRatifyProcessAPI = new DCustomerManagementApi();//httpRequestConfiguration
    const requestBody = {
      type: RatifyType.COMPARISON
    }
    doRatifyProcessAPI.tenantsTenantIdCustomersCustomerIdRatifyPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, requestBody).then((doRatifyProcessAPIResponse) => {
      let isRatifyFailed = 0;
      let ratifiedFailedResponse = {};
      ratifyKeys.forEach(ratifyKey => {
        ratifiedFailedResponse[ratifyKey] = doRatifyProcessAPIResponse.data[ratifyKey]['passed'];
        if (!doRatifyProcessAPIResponse.data[ratifyKey]['passed']) {
          isRatifyFailed = isRatifyFailed + 1;
        }
      });
      setLoading(false);
      console.log("isRatifyFailed :: ", isRatifyFailed);
      if (isRatifyFailed > 0) {
        goToFailedRatifyKYC(ratifiedFailedResponse)
      } else {
        goToCreateNewPassword();
      }
    }).catch((error) => {
      console.error("error :: ", error);
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/auth/login', '/pages/error');
      }
    });
  }

  const uploadNewSelfieDocument = (selfieDocument) => {
    //httpRequestConfiguration,
    const newDocumentAPI = new DCustomerManagementApi();//httpRequestConfiguration
    newDocumentAPI.tenantsTenantIdCustomersCustomerIdDocumentsPost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, selfieDocument, true).then((newDocumentAPIResponse) => {
      setSelfieDocument(newDocumentAPIResponse.data);
      setLoading(false);
      doRatifyProcess()
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/auth/identity-document', '/pages/error');
      }
    });
  }


  const goToUploadSelfieDocument = () => {
    setLoading(true);
    const dataImagePart1 = selfieDocumentImageData.split(';')[0];
    const dataImagePart2 = selfieDocumentImageData.split(';')[1];
    const newSelfieDocument = {
      base64EncodedDocument: dataImagePart2.split(',')[1],
      documentType: DocumentType.COMPARISONFACIALPHOTO,
      mediaType: dataImagePart1.split(':')[1]
    }
    uploadNewSelfieDocument(newSelfieDocument);//httpRequestConfiguration,
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p className={classes.regularFontSize + ' m-b-0'}><strong>Upload your selfie.</strong></p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <img src={selfieDocumentImageData} alt="" style={{ padding: '0px', border: '1px solid', borderColor: grayColor[12], boxSizing: 'border-box', float: 'left', width: '100%', borderRadius: '5px' }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="external-links" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span onClick={backToScanSelfie} style={{ display: 'flex', margin: '10px 0' }} className={classes.linkColor + ' ' + classes.regularFontSize}><DeleteOutlineIcon />&nbsp;<b>Delete and upload another image</b></span>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" onClick={goToUploadSelfieDocument}>Upload my selfie</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  uploadPhotoHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto)
