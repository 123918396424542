import React, {useCallback} from "react";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryIconColor } from "../../style.scss";
import {ReactComponent as PendingTransactionIcon} from '../../assets/img/transaction_pending.svg'
import { loginToApp } from "../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);

export function EFTSuccess(props) {
  const history = useHistory();
  const classes = useStyles();

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.eftSuccessHandler(appData);

    history.push({
      pathname: '/pages/dashboard',
    });
  },[history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history,backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <PendingTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-t-0'}><b>Payment pending.</b></h4>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}>
                The payments will reflect in the recipients account within 24 - 48 hour on weekdays if the transaction is successful. Check your transaction history for updates.
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToHome}>Done</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  eftSuccessHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EFTSuccess)