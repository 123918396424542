import React, { useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map'
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { ReactComponent as ConfirmTransactionIcon } from '../../assets/img/journey_transfer.svg'
import { v4 as uuidv4 } from 'uuid';
// import HttpRequestConfiguration from "../../http-request-configuration.js";
import { LWalletWithdrawalsApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { WalletWithdrawalType } from "../../eclipse-react-sdk/services/models";


const useStyles = makeStyles(themeStyle);

export function EFTConfirm(props) {
  //console.log("EFTConfirm Props :: ", props?.appData);
  const eftData = useLocation().state?.eftData;
  const walletDetailData = useLocation().state?.walletDetailData;
  const bankDetailData = useLocation().state?.bankDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const bankData = useLocation().state?.bankData;

  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const goToSuccessEFT = (successEFTData) => {
    history.push({
      pathname: '/pages/eft-success',
      state: { walletDetailData: walletDetailData, bankData: bankData, bankDetailData: bankDetailData, fromScreen: fromScreen, eftData: eftData, successEFTData: successEFTData }
    });
  };

  const backToEFTBank = () => {
    history.push({
      pathname: `/pages/eft-bank`,
      state: { walletDetailData: walletDetailData, fromScreen: fromScreen, eftData: eftData }
    });
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }

  const doEFT = () => {
    setLoading(true);
    // const httpRequestConfiguration = HttpRequestConfiguration(props?.appData?.auth?.headerValue);
    const eftWithdrawalsAPI = new LWalletWithdrawalsApi();//httpRequestConfiguration
    const createEFTBody = {
      accountName: bankData?.accountName,
      accountNumber: bankData?.accountNo,
      amount: eftData?.amount,
      bank: bankDetailData?.name,
      branchCode: bankData?.bankCode,
      description: eftData?.reference,
      reference: eftData?.reference,
      externalUniqueId: uuidv4(),
      type: WalletWithdrawalType.ZANEDBANKEFT
    }
    eftWithdrawalsAPI.tenantsTenantIdWalletsWalletIdWithdrawalsPost(props?.appData?.auth?.tenantId, eftData?.eftWallet, createEFTBody).then((eftWithdrawalsAPIResponse) => {
      setLoading(false);
      goToSuccessEFT(eftWithdrawalsAPIResponse?.data);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    doEFT();
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToEFTBank}></ArrowBackIosIcon>
              <span className={classes.title}>Confirm EFT</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '0px' }}>
              <ConfirmTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h4 className={classes.textCenter + ' m-b-0 m-t-0'}><b>Your about to make and EFT</b></h4>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Amount</b></p>
              <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>{getSymbolFromCurrency(walletDetailData?.currency)}{(+eftData?.amount).toFixed(2)}</b></h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Description</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{eftData?.reference}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '0px' }}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}><b>Bank account details</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{bankData?.accountName}</p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{`${bankDetailData?.name} (${bankData?.bankCode})`}</p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>{`Acc no. ${bankData?.accountNo}`}</p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={backToEFTBank} >Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '0px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(EFTConfirm)