import getSymbolFromCurrency from "currency-symbol-map";

const FormatCurrency = (amount, currency = "ZAR") => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });
  return `${amount < 0 ? '-' : ""}${getSymbolFromCurrency(currency)}${formatter.format(Number(amount?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0].replace("-", ""))).replace(currency, "").trim()}`;
};

export {
  FormatCurrency,
}