import axios from "axios";
import { AAuthenticationApi } from "eclipse-react-sdk/services";
import { EclipseTenantPosition } from "eclipse-react-sdk/services/models";
import { AuthRouteWithTemplateId } from "./default-route";
import { EnvConfig } from "./environment";
import JWTDecode from "./jwt-decode";
import { loginToApp, logoutFromApp } from "./redux-services/actions/actions";

const urlsToIgnoreJwt = () => {
  return '/login|/public.tenant.pwa|/renew|/password-change-init|/backdraft.ukheshe.rocks|/recaptcha|/verifications'
}

const returnUserPositions = (existedAuthToken) => {
  let userPositions = [];
  if (existedAuthToken) {
    const decodedToken = JWTDecode(existedAuthToken.split(' ')[1]);
    if (decodedToken?.pos && decodedToken?.pos.length > 0) {
      decodedToken.pos.forEach(userPosition => {
        userPositions.push(userPosition?.p);
      });
    }
  }
  return userPositions;
}

const returnLevel10User = (existedAuthToken) => {
  //To Get User Positions from Auth Token
  const userPositions = returnUserPositions(existedAuthToken);
  if (userPositions.length > 0) {
    return userPositions.includes(EclipseTenantPosition?.ONBOARDING);
  }
  return false;
}

const doLogout = (store) => {
  store.dispatch(logoutFromApp(null));
  window.location.href = AuthRouteWithTemplateId('login');
}

const renewToken = (originalRequest, existedAuthJWT, store) => {
  const loginAPI = new AAuthenticationApi();

  return loginAPI.authenticationRenewPost(existedAuthJWT).then((authAPIResponse) => {
    const getState = store.getState();
    getState.applicationData.appData.auth = authAPIResponse?.data;
    store.dispatch(loginToApp(getState.applicationData.appData));
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: authAPIResponse?.data?.headerValue
    };
    return originalRequest;
  }).catch((error) => {
    doLogout(store);
    return Promise.reject(error);
  });
}

export default function HttpInterceptor(store) {

  axios.interceptors.request.use(async (request) => {

    //To ignore back-draft request
    if (!request.url.includes('https://backdraft.ukheshe.rocks')) {
      request.url = `${EnvConfig().BASE_API_URL}${request.url}`;
    }
    const getState = store.getState();

    //check Level 10 User
    const isLevel10User = returnLevel10User(request?.headers?.Authorization);

    //To check any auth state exists in application state to set header in request
    const existedAuthJWT = getState?.applicationData?.appData?.auth?.headerValue;
    if (!isLevel10User && existedAuthJWT) {
      request.headers = {
        ...request.headers,
        Authorization: request?.headers?.Authorization ? request?.headers?.Authorization : existedAuthJWT
      };
    }

    //To ignore non-authorize request API urls
    if (request.url.search(urlsToIgnoreJwt()) > 0) {
      return request;
    }

    let getSessionExpiresEpoch = getState?.applicationData?.appData?.auth?.expiresEpochSecs;
    getSessionExpiresEpoch = getSessionExpiresEpoch ? getSessionExpiresEpoch : 0;
    if (getSessionExpiresEpoch > 0) {
      //To check any auth session exists in application state to do actual request or renew auth token
      const currentEpoch = Math.floor(new Date().getTime() / 1000);
      const activeSessionTimeDiff = getSessionExpiresEpoch - currentEpoch;
      if (activeSessionTimeDiff <= 0) {
        return await renewToken(request, existedAuthJWT, store);
      }
      return request;
    } else {
      //To execute request with default auth header specifically it will call while Sign-up and Reset password process
      return request;
    }
  });

  axios.interceptors.response.use((response) => {
    return response;
  },
    async (error) => {
      const request = error.config;

      if (error?.response) {
        if (error?.response?.status === 401 && error?.response?.data && error?.response?.data?.length > 0 && error?.response?.data[0]?.description === "JWT has expired" && !request._retry) {
          request._retry = true;
          const getState = store.getState();
          //check Level 5 User
          const isLevel10User = returnLevel10User(request?.headers?.Authorization);
          //To check any auth state exists in application state to set header in request
          const existedAuthJWT = getState?.applicationData?.appData?.auth?.headerValue;
          if (!isLevel10User && existedAuthJWT) {
            request.headers = {
              ...request.headers,
              Authorization: request?.headers?.Authorization ? request?.headers?.Authorization : existedAuthJWT
            };
            // Do something, call renewToken() request for example;
            // return a request
            return await renewToken(request, existedAuthJWT, store);
          }
        }
      }
      return Promise.reject(error);
    });
}