import React, { useState } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { primaryColor } from "../../style.scss";
import Danger from "../../components/Typography/Danger";
import { loginToApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import HttpRequestConfiguration from "../../http-request-configuration.js";
import { SpinnerComponent } from 'react-element-spinner';
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Checkbox from '@material-ui/core/Checkbox';
import { load } from 'recaptcha-v3'
import { EnvConfig } from "../../environment.js";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import {
  AuthQueryParamWithTemplateId,
  AuthRouteWithoutTemplateId,
  AuthRouteWithTemplateId
} from "../../default-route.js";
import ReturnMobileNumberWithISD from "../returnMobileNumberWithISD.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function SignUp(props) {
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = useState({ mobileNumber: '' });
  const [errors, setErrors] = useState({});
  const fromScreen = useLocation().state?.fromScreen;
  const [acceptTC, setAcceptTC] = React.useState();
  const [alertData, setAlert] = useState(null);
  const template = props?.appData?.template;
  const tenantId = template?.tenantId;
  const termsAndConditionsLink = template?.defaultPWATandCLink;
  const defaultMobileNumberRegEx = template?.defaultMobileNumberRegEx;
  const whatsAppDefaultISDCode = template?.whatsAppDefaultISDCode;
  const landingTemplate = template?.landingTemplate || "Default";

  const returnMobileNumberWithISD = () => {
    return ReturnMobileNumberWithISD(whatsAppDefaultISDCode, inputs?.mobileNumber);
  }

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const loginRedirect = () => {
    history.push(`${AuthRouteWithTemplateId('login')}`);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: `${AuthRouteWithoutTemplateId(toScreen)}`,
      search: AuthQueryParamWithTemplateId(),
      state: { fromScreen },
    });
  }


  const handleSignupFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateSignupForm(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors && acceptTC) {
      setLoading(true);
      const httpRequestConfiguration = HttpRequestConfiguration();
      const generateOTPAPI = new ZGlobalFunctionalityApi(httpRequestConfiguration);
      load(EnvConfig().GRECAPTCHA, { autoHideBadge: true }).then((reCaptcha) => {
        reCaptcha.execute('OTP_VERIFICATION').then((token) => {
          if (token) {
            const headers = { gCaptcha: token };
            httpRequestConfiguration.baseOptions = { headers };
            const generateOTPData = {
              phone: returnMobileNumberWithISD(),
              tenantId
            }
            generateOTPAPI.globalVerificationsPost(generateOTPData).then(() => {
              history.push({
                pathname: `${AuthRouteWithoutTemplateId('verify-otp')}`,
                search: AuthQueryParamWithTemplateId(),
                state: { gCaptcha: token, mobileNumber: returnMobileNumberWithISD(), fromScreen }
              });
            }).catch((error) => {
              setLoading(false);
              if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
                showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
              } else {
                redirectToErrorScreen(`/auth/signup`, `error`);
              }
            });
          } else {
            showAlert(true, 'ERROR', `Please try again!`);
          }
        })
      });

    }
  }
  

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateSignupForm(inputs);
    setErrors(validationErrors);
  }

  const changeAcceptTC = (event) => {
    setAcceptTC(event.target.checked);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateSignupForm(inputs);
    setErrors(validationErrors);
  }

  const validateSignupForm = (inputs) => {
    const errors = {};
    const pattern = new RegExp(defaultMobileNumberRegEx);
    if (!inputs.mobileNumber) {
      errors.mobileNumber = 'Mobile number required!'
    } else if (!pattern.test(inputs.mobileNumber)) {
      errors.mobileNumber = 'Mobile number is invalid!'
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form autoComplete="off" onSubmit={handleSignupFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h1>{landingTemplate === "PAYAT" ?  "Hey there!" : "Hey there!"}</h1>
                 <p className={classes.regularFontSize}>
                  <strong>
                    Let's sign you up.
                  </strong></p> 
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  inputProps={{ inputMode: 'tel' }}
                  fullWidth
                  label="What is your mobile number?"
                  variant="filled"
                  id="mobileNumber"
                  type="text"
                  error={!!(errors.mobileNumber)}
                  name="mobileNumber"
                  value={inputs.mobileNumber}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp}/>
                {errors.mobileNumber && <Danger><small>{errors.mobileNumber}</small></Danger>}
              </GridItem>
              
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Checkbox
                      checked={acceptTC}
                      onChange={changeAcceptTC}
                      style={{ marginLeft: '-9px', marginTop: '-9px' }}
                    />
                    <p className={classes.regularFontSize + ' m-t-0 m-b-0'} style={{ lineHeight: '20px', fontWeight: 400 }}>I accept that Pay@ may use my information provided to send me important updates and I also accept the  <a
                        className={classes.linkColor} href={termsAndConditionsLink} target={"_blank"}
                        rel={"noopener noreferrer"}>Pay@ Processing Policy.</a></p>
                  </div>
                </GridItem>
              <GridItem xs={12} m={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                        disabled={!!(errors.mobileNumber || !acceptTC)}>{"Let's go"}</Button>
                {/* {(fromScreen !== "CHANGE_IDENTITY" && landingTemplate !== "PAYAT") ? */}
                  <p className={classes.regularFontSize + ' ' + classes.textCenter}>Already have an account? <span
                    className={classes.linkColor} onClick={loginRedirect}>Log in</span></p> 
                    {/* : null} */}
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  signUpHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
