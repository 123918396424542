/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EclipseVasPartner } from '../models';
import { EclipseVasPartnerCatalog } from '../models';
import { ExceptionData } from '../models';
/**
 * PVASFunctionalityApi - axios parameter creator
 * @export
 */
export const PVASFunctionalityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of the partners and products per partner. Listed partners are country specific e.g tenant in RSA only gets the catalogs of RSA partners
         * @summary Get list of vas partner and their products
         * @param {number} tenantId 
         * @param {string} [catalogQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdVasCatalogsGet: async (tenantId: number, catalogQuery?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdVasCatalogsGet.');
            }
            const localVarPath = `/tenants/{tenantId}/vas/catalogs`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            if (catalogQuery !== undefined) {
                localVarQueryParameter['catalogQuery'] = catalogQuery;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * You can only get the names of the mobile networks of the vas partners associated with eclipse only.
         * @summary Get a mobile network information as vas partner through  mobile number
         * @param {number} tenantId 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdVasMsisdnGet: async (tenantId: number, msisdn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            if (tenantId === null || tenantId === undefined) {
                throw new RequiredError('tenantId','Required parameter tenantId was null or undefined when calling tenantsTenantIdVasMsisdnGet.');
            }
            // verify required parameter 'msisdn' is not null or undefined
            if (msisdn === null || msisdn === undefined) {
                throw new RequiredError('msisdn','Required parameter msisdn was null or undefined when calling tenantsTenantIdVasMsisdnGet.');
            }
            const localVarPath = `/tenants/{tenantId}/vas/{msisdn}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAuthorisation required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PVASFunctionalityApi - functional programming interface
 * @export
 */
export const PVASFunctionalityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get list of the partners and products per partner. Listed partners are country specific e.g tenant in RSA only gets the catalogs of RSA partners
         * @summary Get list of vas partner and their products
         * @param {number} tenantId 
         * @param {string} [catalogQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdVasCatalogsGet(tenantId: number, catalogQuery?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseVasPartnerCatalog>>> {
            const localVarAxiosArgs = await PVASFunctionalityApiAxiosParamCreator(configuration).tenantsTenantIdVasCatalogsGet(tenantId, catalogQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * You can only get the names of the mobile networks of the vas partners associated with eclipse only.
         * @summary Get a mobile network information as vas partner through  mobile number
         * @param {number} tenantId 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tenantsTenantIdVasMsisdnGet(tenantId: number, msisdn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EclipseVasPartner>>> {
            const localVarAxiosArgs = await PVASFunctionalityApiAxiosParamCreator(configuration).tenantsTenantIdVasMsisdnGet(tenantId, msisdn, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PVASFunctionalityApi - factory interface
 * @export
 */
export const PVASFunctionalityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get list of the partners and products per partner. Listed partners are country specific e.g tenant in RSA only gets the catalogs of RSA partners
         * @summary Get list of vas partner and their products
         * @param {number} tenantId 
         * @param {string} [catalogQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdVasCatalogsGet(tenantId: number, catalogQuery?: string, options?: any): AxiosPromise<Array<EclipseVasPartnerCatalog>> {
            return PVASFunctionalityApiFp(configuration).tenantsTenantIdVasCatalogsGet(tenantId, catalogQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * You can only get the names of the mobile networks of the vas partners associated with eclipse only.
         * @summary Get a mobile network information as vas partner through  mobile number
         * @param {number} tenantId 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tenantsTenantIdVasMsisdnGet(tenantId: number, msisdn: string, options?: any): AxiosPromise<Array<EclipseVasPartner>> {
            return PVASFunctionalityApiFp(configuration).tenantsTenantIdVasMsisdnGet(tenantId, msisdn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PVASFunctionalityApi - object-oriented interface
 * @export
 * @class PVASFunctionalityApi
 * @extends {BaseAPI}
 */
export class PVASFunctionalityApi extends BaseAPI {
    /**
     * Get list of the partners and products per partner. Listed partners are country specific e.g tenant in RSA only gets the catalogs of RSA partners
     * @summary Get list of vas partner and their products
     * @param {number} tenantId 
     * @param {string} [catalogQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVASFunctionalityApi
     */
    public tenantsTenantIdVasCatalogsGet(tenantId: number, catalogQuery?: string, options?: any) {
        return PVASFunctionalityApiFp(this.configuration).tenantsTenantIdVasCatalogsGet(tenantId, catalogQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * You can only get the names of the mobile networks of the vas partners associated with eclipse only.
     * @summary Get a mobile network information as vas partner through  mobile number
     * @param {number} tenantId 
     * @param {string} msisdn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVASFunctionalityApi
     */
    public tenantsTenantIdVasMsisdnGet(tenantId: number, msisdn: string, options?: any) {
        return PVASFunctionalityApiFp(this.configuration).tenantsTenantIdVasMsisdnGet(tenantId, msisdn, options).then((request) => request(this.axios, this.basePath));
    }
}
