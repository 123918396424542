import React, { useState } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { ReactComponent as ConfirmTransactionIcon } from "../../assets/img/confirm_transfer_wallet.svg"
import { v4 as uuidv4 } from 'uuid';
import { MWalletTransfersApi } from "eclipse-react-sdk/services";
import { SpinnerComponent } from "react-element-spinner";
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import getSymbolFromCurrency from "currency-symbol-map";
import { loginToApp } from "../../redux-services/actions/actions";

const useStyles = makeStyles(themeStyle);

export function ConfirmTransfer(props) {
  const pageState = useLocation()?.state;
  const walletDetailData = pageState?.walletDetailData;
  const transferData = pageState?.transferData;
  const fromScreen = pageState?.fromScreen;
  const action = pageState?.action;
  const selectedWallet = transferData?.selectedWallet
  const toMobileNumber = transferData?.toMobileNumber
  const walletTitleText = props?.appData?.template?.pwaAppOptions?.walletTitleText;
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = () => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.walletTransferHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
      state: { walletDetailData, fromScreen, action }
    });
  };
  const backToTransfer = () => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.walletTransferHandler(appData);
    history.push({
      pathname: '/pages/transfer',
      state: { walletDetailData, fromScreen, action }
    });
  };

  const goToSuccessTransfer = (successTransferData) => {
    history.push({
      pathname: action==='TRANSFER'?'/pages/success-transfer':'/pages/success-send-money',
      state: { walletDetailData, fromScreen, successTransferData, action }
    });
  };

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const doWalletTransfer = () => {
    setLoading(true);
    const walletTransferAPI = new MWalletTransfersApi();
    const createTransferWalletBody = {
      amount: transferData.amount,
      description: transferData.reference,
      externalUniqueId: uuidv4(),
      fromWalletId: transferData.fromWallet,
      toWalletId: transferData.toWallet
    }
    walletTransferAPI.tenantsTenantIdWalletsTransfersPost(props?.appData?.auth?.tenantId, createTransferWalletBody).then(() => {
      setLoading(false);
      goToSuccessTransfer(transferData);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    doWalletTransfer();
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToTransfer}/>
              <span className={classes.title}>
                {fromScreen === 'TOPUP' ? 'Topup' : ''}
                {fromScreen === 'WITHDRAWAL' ? 'Withdrawal' : ''}
                {action==="TRANSFER"?`${walletTitleText} to ${walletTitleText.toLowerCase()} transfer`:"Send Money"}
              </span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <ConfirmTransactionIcon fill={primaryIconColor} stroke={primaryIconColor} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0'}><b>Your about to transfer</b></p>
              <h2 style={{ color: primaryIconColor, margin: '0' }} className={classes.textCenter}><b>{getSymbolFromCurrency(walletDetailData?.currency)}{transferData.amount}</b></h2>
              <p className={classes.regularFontSize + ' ' + classes.textCenter + ' m-b-0 m-t-0'}>
                {(action==="TRANSFER")?
                    <b>from {walletDetailData?.name.toUpperCase()} to a recipient's {selectedWallet.name?.toUpperCase()}.</b>:
                  <b>from {walletDetailData?.name.toUpperCase()} to a recipient {toMobileNumber}.</b>
              }
              </p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="transparent" onClick={backToHome} >Cancel</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: '40px' }}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={handleSubmit}>Continue</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  )
}


const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  walletTransferHandler: data => dispatch(loginToApp(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmTransfer)