import {
  // drawerWidth,
  transition,
  container
} from "../../eclipse.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "4.8em",
    padding: "15px 0 70px",
    minHeight: "auto",
    // backgroundColor: whiteColor,
    // border: "1px solid"
  },
  "@media (max-width: 1201px)": {
    content: {
      padding: "15px 0",
    },
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
