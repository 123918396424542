import React from "react";
// @material-ui/core
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button.js";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import { WifiOffOutlined } from "@material-ui/icons";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(themeStyle);

export function OfflineError(props) {
  //console.log("OfflineError props :: ", props);
  const history = useHistory();
  const classes = useStyles();
  const appData = useLocation();
  //console.log("ErrorScreen appData ::: ", appData);
  const backToScreen = () => {
    history.push(appData.state.fromScreen);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <WifiOffOutlined className={classes.screenCenterDangerIcon} />
              <p className={classes.title + ' ' + classes.textCenter}><b>It looks like you’re offline!</b></p>
              <p className={classes.regularFontSize + ' ' + classes.textCenter}>Please check your internet connection.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button className={classes.buttonRadius} size="lg" block color="primary" type="button" onClick={backToScreen}>Please try again</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(OfflineError)