import React, { useState, useCallback } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "../../../components/CustomButtons/Button.js";
import formStyle from "../../../assets/jss/eclipse/components/formStyle.js";
import { TextField } from "@material-ui/core";
import themeStyle from "../../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../../components/Typography/Danger.js";
import { SpinnerComponent } from "react-element-spinner";
import { PVASFunctionalityApi } from "eclipse-react-sdk/services";
import { primaryColor } from "../../../style.scss";
import AlertNotification from "../../../components/Snackbar/AlertNotification.js";
import { loginToApp } from "../../../redux-services/actions/actions.js";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);

export function VASEnterIDNumber(props) {
  const vasConfig = props?.appData?.template?.vas;  
  const history = useHistory();
  const classes = useStyles();
  const selectedNetworkPartner = useLocation().state?.selectedNetworkPartner;
  const [alertData, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ mobileNumber: props?.appData?.customer?.phone1, mobileType: 'SELF', IDNumber: '' });
  const [errors, setErrors] = useState({});

  const goToSelectVAS = (vasProducts) => {
    history.push({
      pathname: '/pages/vas-view-products',
      state: { vasDetail: inputs, vasProducts: vasProducts, selectedNetworkPartner: selectedNetworkPartner }
    });
  };

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.vasEnterIdNumberHandler(appData);
    history.push('/pages/dashboard');
  }, [history, props])

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome]);

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen: fromScreen }
    });
  }, [history]);

  const getProductsDataCategoryWise = (products) => {
    const productsGroups = {};
    const sectionGroups = [];
    const convertedProducts = [];
    products.forEach((product) => {
      product.additionalFields[0].value = props?.appData?.customer?.phone1;
      convertedProducts.push({
        ...product,
        productCategory: product?.productTypeName,
        productImageUrl: product?.productImageUrl ? product?.productImageUrl : null,
        productSubCategory: product?.productSubCategory ? product?.productSubCategory : 'ALL'
      })
    })

    convertedProducts.forEach((product) => {
      const productCategory = product.productCategory;
      if (productCategory in productsGroups) {
        productsGroups[productCategory].push(product);
      } else {
        productsGroups[productCategory] = new Array(product);
      }
    });

    var productsGroupsKeys = Object.keys(productsGroups);
    productsGroupsKeys.forEach((productKey) => {
      sectionGroups.push(
        {
          title: productsGroups[productKey][0].productName,
          data: productsGroups[productKey]
        }
      )
    });

    return sectionGroups;
  }

  const getCatalogs = (partnerData) => {
    setLoading(true);
    const vasAPI = new PVASFunctionalityApi();
    const catalogQuery = partnerData?.partnerId+'_'+inputs.mobileNumber+'_'+inputs.IDNumber;
    vasAPI.tenantsTenantIdVasCatalogsGet(props?.appData.auth.tenantId, catalogQuery).then((vasAPIResponse) => {
      if (vasAPIResponse?.data && vasAPIResponse?.data.length > 0) {
        let partnerId = partnerData?.partnerId;
        if (partnerData?.partnerId === vasConfig?.partners?.kganya?.id) {
          const kganyaPartnerDetail = vasAPIResponse?.data.filter(partner => partner?.partnerId === vasConfig?.partners?.kganya?.id);
          if (kganyaPartnerDetail.length > 0 && kganyaPartnerDetail[0].products && kganyaPartnerDetail[0].products.length > 0) {
            const products = kganyaPartnerDetail[0].products;
            if (products.length > 0) {
              kganyaPartnerDetail[0].partnerName = vasConfig?.partners?.kganya?.name;
              kganyaPartnerDetail[0].products = getProductsDataCategoryWise(products);
              goToSelectVAS(kganyaPartnerDetail[0]);
              return;
            }
          }
        } else {
          const partnerDetail = vasAPIResponse?.data.filter(partner => partner?.partnerId === partnerId);
          if (partnerDetail.length > 0 && partnerDetail[0].products && partnerDetail[0].products.length > 0) {
            partnerDetail[0].products = getProductsDataCategoryWise(partnerDetail[0].products);
            goToSelectVAS(partnerDetail[0]);
            return;
          }
        }
        setLoading(false);
        redirectToErrorScreen('/pages/vas-enter-idnumber', '/pages/info');
      } else {
        setLoading(false);
        redirectToErrorScreen('/pages/vas-enter-idnumber', '/pages/info');
      }
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/vas-enter-idnumber', '/pages/error');
      }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateIDNumber(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      setLoading(true);
      getCatalogs({"partnerId":vasConfig?.partners?.kganya?.id,"partnerName":vasConfig?.partners?.kganya?.name});
    }
  }

  const handleInputChange = (event) => {
    event.persist();
    inputs[event.target.name] = event.target.value;
    setInputs(inputs);
    const validationErrors = validateIDNumber(inputs);
    setErrors(validationErrors);
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateIDNumber(inputs);
    setErrors(validationErrors);
  }

  const validateIDNumber = (inputs) => {
    const errors = {};
    if (!inputs.IDNumber) {
      errors.IDNumber = 'ID number required!'
    }
    return errors
  }

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}></ArrowBackIosIcon>
              <span className={classes.title}>Collections</span>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <b className={classes.regularFontSize}>Enter your ID number</b>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ValidationTextField
                  fullWidth
                  label="ID number"
                  variant="filled"
                  id="IDNumber"
                  type="text"
                  error={(errors.IDNumber) ? true : false}
                  name="IDNumber"
                  value={inputs.IDNumber || ""}
                  onChange={handleInputChange}
                  onKeyUp={handleInputKeyUp} />
                {errors.IDNumber && <Danger><small>{errors.IDNumber}</small></Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={errors.IDNumber ? true : false}>Next</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  vasEnterIdNumberHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(VASEnterIDNumber)