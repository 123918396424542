import {
  dangerColor,
  successColor
} from "../../eclipse.js";
import {primaryColor, primaryTextColor, gradientFromColor, gradientToColor, buttonHoverFocusTextColor, linkHoverFocusTextColor, controlLabelColor, disabledCardBackgroundColor} from "../../../../style.scss"

const themeStyle = {
  buttonRadius: {
    fontWeight: '600',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: primaryColor,
      color: buttonHoverFocusTextColor
    },
    '&:focus': {
      backgroundColor: primaryColor,
      color: buttonHoverFocusTextColor
    }
  },
  title: {
    fontWeight: '400',
    fontSize: '1em',
    lineHeight: '1.6em',
    color: primaryTextColor,
  },
  linkColor: {
    color: primaryColor,
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      color: linkHoverFocusTextColor,
    },
    '&:focus': {
      color: linkHoverFocusTextColor,
    }
  },
  formControl: {
    margin: "1em 0.5em",
  },
  button: {
    margin: "0",
  },
  regularFontSize: {
    fontSize: '0.9em',
  },
  textCenter: {
    textAlign: 'center'
  },
  textRight: {
    textAlign: 'right'
  },
  screenCenterPrimaryIcon: {
    fontSize: '5em',
    color: primaryColor,
    textAlign: 'center',
    width: '100%',
    margin: '0.5em 0 0'
  },
  screenCenterDangerIcon: {
    fontSize: '5em',
    color: dangerColor[0],
    textAlign: 'center',
    width: '100%',
    margin: '0.5em 0 0'
  },
  labelText: {
    fontSize: '0.9em',
    color: controlLabelColor
  },
  gradientCurve: {
    background: `linear-gradient(241.63deg, ${gradientToColor} 36.01%, ${gradientFromColor} 100%)`
  },
  iconSuccess: {
    color: successColor[0],
    paddingTop: '0.1em',
    fontSize: '2em'
  },
  iconDanger: {
    color: dangerColor[0],
    paddingTop: '0.1em',
    fontSize: '2em'
  },
  disabledCard: {
    backgroundColor: disabledCardBackgroundColor,
    cursor: "not-allowed"
  }
};

export default themeStyle;
