import React, { useCallback } from "react";
// @material-ui/core
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { useHistory, useLocation } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import Button from "../../components/CustomButtons/Button.js";
import { connect } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle.js";
import Danger from "../../components/Typography/Danger";
import formStyle from "../../assets/jss/eclipse/components/formStyle.js";
import { ReactComponent as SetPasswordIcon } from '../../assets/img/icon_enter_password.svg'
import { ZGlobalFunctionalityApi } from "eclipse-react-sdk/services/apis/zglobal-functionality-api";
import { primaryColor, primaryIconColor } from "../../style.scss";
import { SpinnerComponent } from 'react-element-spinner';
import AlertNotification from "../../components/Snackbar/AlertNotification.js";
import { CreateNewInputControls, FocusInNextControl, FocusInPrevControl, ReturnChangedControlValue, ReturnInputControlsConcatValues, ReturnInputControlsMap, ReturnKeyDownControlValue, ValidateInputControls } from "../../password-pin.js";

const passwordStyle = {
  oneDigitPassword: {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        padding: '0.8em',
        textAlign: 'center',
        fontWeight: '500'
      }
    }
  },
  customPadding: {
    paddingTop: "0em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
  },
  firstControlCustomPadding: {
    paddingLeft: "1em",
    paddingRight: "0.15em"
  },
  secondLastCustomPadding: {
    paddingLeft: "0.5em",
    paddingRight: "0.75em"
  },
  lastControlCustomPadding: {
    paddingRight: "1em",
    paddingLeft: "0.15em",
  }
}

const passwordStyles = makeStyles(passwordStyle);
const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);


export function ConfirmNewPassword(props) {
  const identityPrefix = props?.appData?.template?.identityPrefix;
  const pinAsPassword = props?.appData?.template?.pwaAppOptions?.pinAsPassword;
  const pinPasswordLength = props?.appData?.template?.pwaAppOptions?.pinPasswordLength;
  const inputControls = pinAsPassword ? pinPasswordLength : 1;
  const passwordPINText = props?.appData?.template?.pwaAppOptions?.paymentPINText;
  const confirmPasswordPINControlName = `confirm${passwordPINText?.toLowerCase()?.replace(/ /g, '')}`;
  const passwordPINInputControlGridLength = 12 / inputControls;
  const userIdentity = useLocation().state?.userIdentity;
  const userOTP = useLocation().state?.userOTP;
  const newPasswordPIN = useLocation().state?.newPasswordPIN;

  const scannedQRData = useLocation().state?.scannedQRData;
  const paymentDetails = useLocation().state?.paymentDetails;
  const cardDetailData = useLocation().state?.cardDetailData;
  const fromScreen = useLocation().state?.fromScreen;

  const history = useHistory();
  const classes = useStyles();
  const passwordClasses = passwordStyles();

  const [inputs, setInputs] = useState({ ...CreateNewInputControls(confirmPasswordPINControlName, inputControls) });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = useState(null);

  const showAlert = (alertDisplay, alertType, message) => {
    setAlert({ isAlertDisplay: alertDisplay, alertType: alertType, alertMessage: message })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = (fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }

  const backToCreateNewPassword = useCallback(() => {
    history.push({
      pathname: '/pages/create-payment-pin',
      state: { userIdentity: userIdentity, userOTP: userOTP, scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }, [history, userIdentity, userOTP, scannedQRData, fromScreen, paymentDetails, cardDetailData])

  const successPassword = () => {
    history.push({
      pathname: '/pages/success-payment-pin',
      state: { scannedQRData, fromScreen, paymentDetails, cardDetailData }
    });
  }


  const handleInputChange = (event) => {
    event.persist();
    const inputControlValue = ReturnChangedControlValue(inputs, event.target.name, event.target.value, inputControls);
    setInputs(inputControlValue);
    const validationErrors = validateConfirmNewPasswordForm();
    setErrors(validationErrors);
  }

  const handleOnKeyDown = (event) => {
    event.persist();
    switch (event?.key) {
      case 'Backspace':
      case 'Delete': {
        const inputControlValue = ReturnKeyDownControlValue(inputs, event.target.name, event.target.value, inputControls);
        setInputs(inputControlValue);
        const validationErrors = validateConfirmNewPasswordForm();
        setErrors(validationErrors);
        break;
      }
      case 'ArrowLeft': {
        FocusInPrevControl(event.target.name, event.target.value);
        break;
      }
      case 'ArrowRight': {
        FocusInNextControl(event.target.name, event.target.value);
        break;
      }
      default:
        break;
    }
  }

  const handleInputKeyUp = (event) => {
    event.persist();
    const validationErrors = validateConfirmNewPasswordForm();
    setErrors(validationErrors);
  }

  const validateConfirmNewPasswordForm = () => {
    //Email errors
    let errors = ValidateInputControls(inputs, confirmPasswordPINControlName, inputControls, `Confirm ${passwordPINText}`, pinPasswordLength);
    if (errors[confirmPasswordPINControlName]) {
      return errors;
    }

    if (!errors[confirmPasswordPINControlName] && newPasswordPIN !== ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)) {
      errors[confirmPasswordPINControlName] = `New ${passwordPINText} and Confirm ${passwordPINText} are not matching!`;
      return errors;
    }
    return errors
  }

  const handleConfirmNewPasswordFormSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateConfirmNewPasswordForm();
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      if (userIdentity && userOTP && newPasswordPIN) {
        setLoading(true);
        const changePasswordAPI = new ZGlobalFunctionalityApi();//httpRequestConfiguration
        const changePasswordBody = {
          hash: userOTP,
          password: ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls)
        }
        changePasswordAPI.globalIdentitiesIdentityPasswordChangePost(`${identityPrefix}PAYMENTPIN-${userIdentity}`, changePasswordBody).then(() => {
          setLoading(false);
          successPassword();
        }).catch((error) => {
          setLoading(false);
          if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
            showAlert(true, 'ERROR', `${error.response.data[0].code} :: ${error.response.data[0].description}`);
          } else {
            redirectToErrorScreen(`/pages/dashboard`, `error`);
          }
        });
      } else {
        redirectToErrorScreen(`/pages/dashboard`, `error`);
      }
    }
  }

  React.useEffect(() => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToCreateNewPassword();
      }
  }, [backToCreateNewPassword])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor} />
      <div className="wallet-list">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <ArrowBackIosIcon className={classes.linkColor} onClick={backToCreateNewPassword} />
                <span className={classes.title}>Forgot payment PIN</span>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p className={classes.textCenter + ' m-t-0 m-b-0'} style={{ fontSize: '20px' }}><b>Confirm your PIN.</b></p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
                <SetPasswordIcon fill={primaryIconColor} stroke={primaryIconColor} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={handleConfirmNewPasswordFormSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                      <p className={classes.regularFontSize} style={{ marginTop: '0px', marginBottom: '16px' }}><strong>Confirm your payment PIN</strong></p>
                    </GridItem>
                    {ReturnInputControlsMap(confirmPasswordPINControlName, inputControls)?.map((control, index, array) => {
                      return (
                        <GridItem key={control} xs={passwordPINInputControlGridLength} sm={passwordPINInputControlGridLength} md={passwordPINInputControlGridLength} custom-padding={inputControls > 1 ? passwordClasses.customPadding + ' ' + (index === 0 ? passwordClasses.firstControlCustomPadding : '') + ' ' + (index === array.length - 1 ? passwordClasses.lastControlCustomPadding : '') : ''}>
                          <ValidationTextField
                            inputProps={{ maxLength: inputControls > 1 ? 1 : 50, inputMode: pinAsPassword ? 'numeric' : '' }}
                            className={[inputControls > 1 ? passwordClasses.oneDigitPassword : '', inputControls === 4 ? ' fourDigitInput' : ''].join(' ')}
                            label={inputControls > 1 ? '' : 'Confirm your New ' + passwordPINText}
                            fullWidth
                            variant="filled"
                            id={control}
                            type="password"
                            error={!!(errors[confirmPasswordPINControlName])}
                            name={control.substring(0, control.length - 1) + `-${control.substring(control.length - 1, control.length)}`}
                            value={inputs[control] || ""}
                            onChange={handleInputChange}
                            onKeyDown={handleOnKeyDown}
                            onKeyUp={handleInputKeyUp} />
                        </GridItem>
                      )
                    })
                    }
                    <GridItem xs={12} sm={12} md={12} style={{ paddingTop: '0px' }}>
                      {errors[confirmPasswordPINControlName] && <Danger><small>{errors[confirmPasswordPINControlName]}</small></Danger>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit" disabled={!!(errors[confirmPasswordPINControlName] || !ReturnInputControlsConcatValues(inputs, confirmPasswordPINControlName, inputControls))}>Next</Button>
                      <p className={classes.regularFontSize + ' ' + classes.textCenter} style={{ fontWeight: '400' }}>Can't remember your PIN? <span className={classes.linkColor} onClick={backToCreateNewPassword} >Go back</span> </p>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData} /> : null}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

export default connect(mapStateToProps)(ConfirmNewPassword)