/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AuthenticationChallenge } from '../models';
import { EclipseAuthCredentials } from '../models';
import { Jwt } from '../models';
import { PlainJwt } from '../models';
import { StepUpRequest } from '../models';
/**
 * AAuthenticationApi - axios parameter creator
 * @export
 */
export const AAuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Takes a current JWT along with additional step-up data that allows the JWT to be stepped up and enable additional power for doing things that required stepped-up authentication
         * @summary Update a JSON Web Token by providing additional authentication information to step-up the power of the JWT
         * @param {StepUpRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationJwtPut: async (body?: StepUpRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/jwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The server will generate some random data and encrypt it using the public key of the user identity (with RSA/ECB/OAEPWithSHA-1AndMGF1Padding) and base64 encode the result and return it. The client then has until the expiry time to call the /login endpoint and provide a SHA256 hash of the challenge data and SHA256 hash of the decrypted result. The decryption should be done using the corresponding private key which only the tenant would have. If clientChallenge is provided then the server will base64 decode it and decrypt it using the Eclipse private key and return the SHA256 of the result in field base64EncodedClientChallengeResponse.
         * @summary Get a PKI challenge for use on /login, with an associated challenge to the server to prove its identity
         * @param {string} [clientChallenge] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginChallengesGet: async (clientChallenge?: string, identity?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/login-challenges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientChallenge !== undefined) {
                localVarQueryParameter['clientChallenge'] = clientChallenge;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is used to log in with an identity and password. The resulting Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire need to be renewed. Any request with an expired token will return an HTTP 401 error and should be renewed. Best practice is to store the expiry date and renew before the token expires. Catching expiry errors and then deciding to renew and retry the request is often error prone.
         * @summary Create a JSON Web Token by passing an identity and password
         * @param {EclipseAuthCredentials} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginPost: async (body?: EclipseAuthCredentials, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The token will no longer work for subsequent requests and cannot be renewed.
         * @summary Invalidate the token provided in the Authorization header or body
         * @param {PlainJwt} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogoutPost: async (body?: PlainJwt, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * This is used to renew a token. The resulting new Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire and need to be renewed again. You only need to pass the expired JWT. Auth credentials are not needed to renew a token
         * @summary Renew a JSON Web Token by providing the current token  in the Authorization header or body
         * @param {PlainJwt} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRenewPost: async (body?: PlainJwt, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/renew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AAuthenticationApi - functional programming interface
 * @export
 */
export const AAuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Takes a current JWT along with additional step-up data that allows the JWT to be stepped up and enable additional power for doing things that required stepped-up authentication
         * @summary Update a JSON Web Token by providing additional authentication information to step-up the power of the JWT
         * @param {StepUpRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationJwtPut(body?: StepUpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
            const localVarAxiosArgs = await AAuthenticationApiAxiosParamCreator(configuration).authenticationJwtPut(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The server will generate some random data and encrypt it using the public key of the user identity (with RSA/ECB/OAEPWithSHA-1AndMGF1Padding) and base64 encode the result and return it. The client then has until the expiry time to call the /login endpoint and provide a SHA256 hash of the challenge data and SHA256 hash of the decrypted result. The decryption should be done using the corresponding private key which only the tenant would have. If clientChallenge is provided then the server will base64 decode it and decrypt it using the Eclipse private key and return the SHA256 of the result in field base64EncodedClientChallengeResponse.
         * @summary Get a PKI challenge for use on /login, with an associated challenge to the server to prove its identity
         * @param {string} [clientChallenge] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginChallengesGet(clientChallenge?: string, identity?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationChallenge>> {
            const localVarAxiosArgs = await AAuthenticationApiAxiosParamCreator(configuration).authenticationLoginChallengesGet(clientChallenge, identity, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is used to log in with an identity and password. The resulting Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire need to be renewed. Any request with an expired token will return an HTTP 401 error and should be renewed. Best practice is to store the expiry date and renew before the token expires. Catching expiry errors and then deciding to renew and retry the request is often error prone.
         * @summary Create a JSON Web Token by passing an identity and password
         * @param {EclipseAuthCredentials} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginPost(body?: EclipseAuthCredentials, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
            const localVarAxiosArgs = await AAuthenticationApiAxiosParamCreator(configuration).authenticationLoginPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The token will no longer work for subsequent requests and cannot be renewed.
         * @summary Invalidate the token provided in the Authorization header or body
         * @param {PlainJwt} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLogoutPost(body?: PlainJwt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AAuthenticationApiAxiosParamCreator(configuration).authenticationLogoutPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * This is used to renew a token. The resulting new Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire and need to be renewed again. You only need to pass the expired JWT. Auth credentials are not needed to renew a token
         * @summary Renew a JSON Web Token by providing the current token  in the Authorization header or body
         * @param {PlainJwt} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRenewPost(body?: PlainJwt, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
            const localVarAxiosArgs = await AAuthenticationApiAxiosParamCreator(configuration).authenticationRenewPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AAuthenticationApi - factory interface
 * @export
 */
export const AAuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Takes a current JWT along with additional step-up data that allows the JWT to be stepped up and enable additional power for doing things that required stepped-up authentication
         * @summary Update a JSON Web Token by providing additional authentication information to step-up the power of the JWT
         * @param {StepUpRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationJwtPut(body?: StepUpRequest, options?: any): AxiosPromise<Jwt> {
            return AAuthenticationApiFp(configuration).authenticationJwtPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * The server will generate some random data and encrypt it using the public key of the user identity (with RSA/ECB/OAEPWithSHA-1AndMGF1Padding) and base64 encode the result and return it. The client then has until the expiry time to call the /login endpoint and provide a SHA256 hash of the challenge data and SHA256 hash of the decrypted result. The decryption should be done using the corresponding private key which only the tenant would have. If clientChallenge is provided then the server will base64 decode it and decrypt it using the Eclipse private key and return the SHA256 of the result in field base64EncodedClientChallengeResponse.
         * @summary Get a PKI challenge for use on /login, with an associated challenge to the server to prove its identity
         * @param {string} [clientChallenge] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginChallengesGet(clientChallenge?: string, identity?: string, options?: any): AxiosPromise<AuthenticationChallenge> {
            return AAuthenticationApiFp(configuration).authenticationLoginChallengesGet(clientChallenge, identity, options).then((request) => request(axios, basePath));
        },
        /**
         * This is used to log in with an identity and password. The resulting Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire need to be renewed. Any request with an expired token will return an HTTP 401 error and should be renewed. Best practice is to store the expiry date and renew before the token expires. Catching expiry errors and then deciding to renew and retry the request is often error prone.
         * @summary Create a JSON Web Token by passing an identity and password
         * @param {EclipseAuthCredentials} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginPost(body?: EclipseAuthCredentials, options?: any): AxiosPromise<Jwt> {
            return AAuthenticationApiFp(configuration).authenticationLoginPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * The token will no longer work for subsequent requests and cannot be renewed.
         * @summary Invalidate the token provided in the Authorization header or body
         * @param {PlainJwt} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogoutPost(body?: PlainJwt, options?: any): AxiosPromise<void> {
            return AAuthenticationApiFp(configuration).authenticationLogoutPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * This is used to renew a token. The resulting new Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire and need to be renewed again. You only need to pass the expired JWT. Auth credentials are not needed to renew a token
         * @summary Renew a JSON Web Token by providing the current token  in the Authorization header or body
         * @param {PlainJwt} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRenewPost(body?: PlainJwt, options?: any): AxiosPromise<Jwt> {
            return AAuthenticationApiFp(configuration).authenticationRenewPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AAuthenticationApi - object-oriented interface
 * @export
 * @class AAuthenticationApi
 * @extends {BaseAPI}
 */
export class AAuthenticationApi extends BaseAPI {
    /**
     * Takes a current JWT along with additional step-up data that allows the JWT to be stepped up and enable additional power for doing things that required stepped-up authentication
     * @summary Update a JSON Web Token by providing additional authentication information to step-up the power of the JWT
     * @param {StepUpRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AAuthenticationApi
     */
    public authenticationJwtPut(body?: StepUpRequest, options?: any) {
        return AAuthenticationApiFp(this.configuration).authenticationJwtPut(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The server will generate some random data and encrypt it using the public key of the user identity (with RSA/ECB/OAEPWithSHA-1AndMGF1Padding) and base64 encode the result and return it. The client then has until the expiry time to call the /login endpoint and provide a SHA256 hash of the challenge data and SHA256 hash of the decrypted result. The decryption should be done using the corresponding private key which only the tenant would have. If clientChallenge is provided then the server will base64 decode it and decrypt it using the Eclipse private key and return the SHA256 of the result in field base64EncodedClientChallengeResponse.
     * @summary Get a PKI challenge for use on /login, with an associated challenge to the server to prove its identity
     * @param {string} [clientChallenge] 
     * @param {string} [identity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AAuthenticationApi
     */
    public authenticationLoginChallengesGet(clientChallenge?: string, identity?: string, options?: any) {
        return AAuthenticationApiFp(this.configuration).authenticationLoginChallengesGet(clientChallenge, identity, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is used to log in with an identity and password. The resulting Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire need to be renewed. Any request with an expired token will return an HTTP 401 error and should be renewed. Best practice is to store the expiry date and renew before the token expires. Catching expiry errors and then deciding to renew and retry the request is often error prone.
     * @summary Create a JSON Web Token by passing an identity and password
     * @param {EclipseAuthCredentials} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AAuthenticationApi
     */
    public authenticationLoginPost(body?: EclipseAuthCredentials, options?: any) {
        return AAuthenticationApiFp(this.configuration).authenticationLoginPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The token will no longer work for subsequent requests and cannot be renewed.
     * @summary Invalidate the token provided in the Authorization header or body
     * @param {PlainJwt} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AAuthenticationApi
     */
    public authenticationLogoutPost(body?: PlainJwt, options?: any) {
        return AAuthenticationApiFp(this.configuration).authenticationLogoutPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This is used to renew a token. The resulting new Jwt specifies the header name and header value that should be passed on all subsequent requests to identity the caller. Tokens expire and need to be renewed again. You only need to pass the expired JWT. Auth credentials are not needed to renew a token
     * @summary Renew a JSON Web Token by providing the current token  in the Authorization header or body
     * @param {PlainJwt} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AAuthenticationApi
     */
    public authenticationRenewPost(body?: PlainJwt, options?: any) {
        return AAuthenticationApiFp(this.configuration).authenticationRenewPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
