/*!

=========================================================
* Eclipse PWA - v1.9.0
=========================================================

* Product Page: https://www.ukheshe.com/product/eclipse
* Copyright 2020 Ukheshe Technology (https://www.ukheshe.com)
* Licensed under MIT (https://ukheshe.comtimofficial/eclipse/blob/master/LICENSE.md)

* Coded by Ukheshe Technology

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import LoginPage from "./views-auth/Login/Login.js";
// core components/views for Pages layout
import DashboardPage from "./views/Dashboard/Dashboard.js";
import UserProfilePage from "./views/UserProfile/UserProfile.js";
import ChangePasswordPINPage from "./views/ChangePasswordPIN/ChangePasswordPIN.js";
import ChangeCreatePasswordPINPage from "./views/ChangePasswordPIN/ChangeCreatePasswordPIN.js";
import ChangeConfirmPasswordPINPage from "./views/ChangePasswordPIN/ChangeConfirmPasswordPIN.js";
import ChangeSuccessPasswordPINPage from "./views/ChangePasswordPIN/ChangeSuccessPasswordPIN.js";
import CreateWalletPage from "./views/CreateWallet/CreateWallet.js";
import ConfirmCreateWalletPage from "./views/CreateWallet/ConfirmCreateWallet.js";
import SuccessCreateWalletPage from "./views/CreateWallet/SuccessCreateWallet.js";
import WalletDetailPage from "./views/WalletDetail/WalletDetail.js";
import TopupPage from "./views/Topup/Topup.js";
import WithdrawalPage from "./views/Withdrawal/Withdrawal.js";
import Error from "@material-ui/icons/Error";
import WifiOffOutlined from "@material-ui/icons/WifiOffOutlined";
import GenericErrorPage from "./components/ErrorScreens/GenericError.js";
import InfoPage from "./components/ErrorScreens/Info.js";
import OfflineErrorPage from "./components/ErrorScreens/OfflineError.js";
import TransferPage from "./views/Transfer/Transfer.js";
import ConfirmTransferPage from "./views/Transfer/ConfirmTransfer.js";
import SuccessTransferPage from "./views/Transfer/SuccessTransfer.js";
import TransactPage from "./views/Transact/Transact.js";
import VASEnterMobileNumberPage from "./views/VAS/AirtimeDataBundles/VASEnterMobileNumber.js";
import VASEnterIdNumberPage from "./views/VAS/collections/VASEnterIdNumber.js";
import VASViewProductsPage from "./views/VAS/collections/VASViewProducts.js";
import VASBuyKganyaProductsPage from "./views/VAS/collections/VASBuyKganyaProducts.js";
import VASViewCategoriesPage from "./views/VAS/AirtimeDataBundles/VASViewCategories.js";
import VASBuyAirtimeDataPage from "./views/VAS/AirtimeDataBundles/VASBuyAirtimeData.js";
import VASPayBillCategoriesPage from "./views/VAS/PayBill/VASPayBillCategories.js";
import VASAccountNumberPage from "./views/VAS/PayBill/VASAccountNumber.js";
import VASPayBillPage from "./views/VAS/PayBill/VASPayBill.js";
import VASConfirmPayAmountPage from "./views/VAS/VASConfirmPayAmount.js";
import VASPaymentStatusPage from "./views/VAS/VASPaymentStatus.js";
import ScanPayQRPage from "./views/ScanPayQR/ScanPayQR.js";
import PayAmountPage from "./views/ScanPayQR/PayAmount.js";
import ConfirmPayAmountPage from "./views/ScanPayQR/ConfirmPayAmount.js";
import SuccessPayAmountPage from "./views/ScanPayQR/SuccessPayAmount.js";
import SuccessCardPaymentPage from "./views/ScanPayQR/SuccessCardPayment.js";
import TransactionHistoryPage from "./views/TransactionHistory/TransactionHistory.js";
import TransactionReceiptPage from "./views/TransactionHistory/TransactionReceipt.js";
import NoCardLinkedPage from "./views/LinkCard/NoCard.js";
import SuccessLinkCardPage from "./views/LinkCard/SuccessLinkCard.js";
import CardOnFilePage from "./views/LinkCard/CardOnFile.js";
import ViaCardPage from "./views/Topup/ViaCard/ViaCard.js";
import ConfirmViaCardPage from "./views/Topup/ViaCard/ConfirmViaCard.js";
import SuccessViaCardPage from "./views/Topup/ViaCard/SuccessViaCard.js";
import ManageLinkedCardPage from "./views/LinkCard/ManageLinkedCard.js";
import IdentityDocumentsPage from "./views/OnBoarding/IdentityDocuments.js";
import DocumentInitialPage from "./views/OnBoarding/DocumentSection/DocumentInitial.js";
import ScanDocumentPage from "./views/OnBoarding/DocumentSection/ScanDocument.js";
import UploadDocumentPage from "./views/OnBoarding/DocumentSection/UploadDocument.js";
import PhotoInitialPage from "./views/OnBoarding/PhotoSection/PhotoInitial.js";
import ScanPhotoPage from "./views/OnBoarding/PhotoSection/ScanPhoto.js";
import UploadPhotoPage from "./views/OnBoarding/PhotoSection/UploadPhoto.js";
import PersonalInformationPage from "./views/OnBoarding/PersonalInfoSection/PersonalInformation.js";
import RatifyKYCSuccessPage from "./views/OnBoarding/RatifyKYCSuccess.js";
import RatifyKYCErrorPage from "./views/OnBoarding/RatifyKYCError.js";
import ContactSupportPage from "./views/OnBoarding/ContactSupport.js";
import GetPaidAmountPage from "./views/GetPaidByQR/GetPaidAmount.js";
import DisplayQRPage from "./views/GetPaidByQR/DisplayQR.js";
import SuccessGetPaidAmountPage from "./views/GetPaidByQR/SuccessGetPaidAmount.js";
import EFTOutAmountPage from "./views/EFTOut/EFTOutAmount.js";
import EFTBankPage from "./views/EFTOut/EFTBank.js";
import EFTConfirmPage from "./views/EFTOut/EFTConfirm.js";
import EFTSuccessPage from "./views/EFTOut/EFTSuccess.js";
import TopupEFTPage from "./views/Topup/EFT/TopupEFT.js";
import TopupRetailerAmountPage from "./views/Topup/RetailStore/TopupRetailerAmount.js";
import TopupRetailerTokenPage from "./views/Topup/RetailStore/TopupRetailerToken.js";
import WithdrawalRetailerAmountPage from "./views/Withdrawal/RetailStore/WithdrawalRetailerAmount.js";
import WithdrawalRetailerTokenPage from "./views/Withdrawal/RetailStore/WithdrawalRetailerToken.js";
import WithdrawalATMAmountPage from "./views/Withdrawal/ATM/WithdrawalATMAmount.js";
import WithdrawalATMTokenPage from "./views/Withdrawal/ATM/WithdrawalATMToken.js";
import TopupATMPage from "./views/Topup/ATM/TopupATM.js";
import RegisterPhysicalCard from "./views/PhysicalCard/PhysicalCardRegister";
import ScanPhysicalCardQR from "./components/PhysicalCard/ScanPhysicalCardQR";
import RegisterSuccessPhysicalCard from "./views/PhysicalCard/SuccessPhysicalCardRegister";
import ResetPhysicalCardPin from "./views/PhysicalCard/ResetPhysicalCardPin";
import SuccessResetPhysicalCardPIN from "./views/PhysicalCard/SuccessResetPhysicalCardPIN";
import ResetPhysicalCardNewPIN from "./views/PhysicalCard/ResetPhysicalCardNewPIN";
import AddPhysicalCard from "./views/PhysicalCard/AddPhysicalCard";
import SuccessAccountDeactivatePage from "./views/UserProfile/SuccessAccountDeactivate.js";
import VASBuyAirtimeDataElectricity from "./views/VAS/AirtimeDataBundles/VASBuyAirtimeDataElectricity.js";
import VASNetworkPartnersPage from "./views/VAS/AirtimeDataBundles/VASNetworkPartners.js";
import CreateVirtualCardPage from "./views/VirtualCard/CreateVirtualCard.js";
import ConfirmCreateVirtualCardWalletPage from "./views/VirtualCard/ConfirmCreateVirtualCardWallet.js";
import SuccessCreateVirtualCardPage from "./views/VirtualCard/SuccessCreateVirtualCard.js";
import AddPersonalInformationPage from "./views/VirtualCard/AddPersonalInformation.js";
import CancelCardReasonPage from "./views/CancelCard/CancelCardReason.js";
import ConfirmCancelCardPage from "./views/CancelCard/ConfirmCancelCard.js";
import CancelCardSuccessPage from "./views/CancelCard/CancelCardSuccess.js";
import PermanentAddressPage from "./views/OnBoarding/PersonalInfoSection/PermanentAddress.js";
import AssistedSignUpPage from "./views/AssistedSignup/AssistedSignUp.js";
import AssistedSignupVerifyOTPPage from "./views/AssistedSignup/AssistedSignupVerifyOTP.js";
import AssistedSignupCreateUsernamePage from "./views/AssistedSignup/AssistedSignupCreateUsername.js";
import AssistedSignupAccountReadyPage from "./views/AssistedSignup/AccountReady.js";
import SaveUserInformationPage  from "./views/AssistedPhysicalCard/SaveUserInformation.js";
import PhysicalCardRegisterPage  from "./views/AssistedPhysicalCard/PhysicalCardRegister.js";
import AssistedSignupSuccessPage  from "./views/AssistedSignup/AssistedSignupSuccess.js";
import CreateNewPinPage  from "./components/PhysicalCard/CreateNewPin.js";
import ConfirmNewPinPage  from "./components/PhysicalCard/ConfirmNewPin.js";
import ResetPinVerifyOtpPage  from "./views/PhysicalCard/ResetPinVerifyOTP.js";
import AssistedCardRegisterPage  from "./views/AssistedPhysicalCard/AssistedCardRegister.js";
import AssistedCardVerifyPage  from "./views/AssistedPhysicalCard/AssistedCardVerifyOTP";
import AssistedCardSuccessPage  from "./views/AssistedPhysicalCard/AssistedCardRegisterSuccess.js";
import AddPhysicalCardPersonalInformationPage  from "./views/PhysicalCard/AddPhysicalCardPersonalInformation.js";
import ConfirmPaymentPinPage  from "./views/ScanPayQR/ConfirmPaymentPin.js";
import QrErrorPage  from "./views/ScanPayQR/QrError.js";
import PayBillPayAt  from "./views/VAS/PayBill/PayBillPayAt.js";
import ResetPasswordPage from "./views/ForgotPaymentPIN/ResetPassword.js";
import CreateNewPasswordPage from "./views/ForgotPaymentPIN/CreateNewPassword.js";
import ConfirmNewPasswordPage from "./views/ForgotPaymentPIN/ConfirmNewPassword.js";
import SuccessPasswordPage from "./views/ForgotPaymentPIN/SuccessPassword.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    id: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/pages",
    mainScreen: true
  },
  {
    path: "/user",
    name: "User Profile",
    icon: Person,
    component: UserProfilePage,
    layout: "/pages",
    mainScreen: true,
    noTitle: true
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: Person,
    component: ChangePasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-create-password",
    name: "Change Create Password",
    icon: Person,
    component: ChangeCreatePasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-confirm-password",
    name: "Change Confirm Password",
    icon: Person,
    component: ChangeConfirmPasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-success-password",
    name: "Change Success Password",
    icon: Person,
    component: ChangeSuccessPasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/transaction-history",
    name: "Transaction History",
    icon: Person,
    component: TransactionHistoryPage,
    layout: "/pages",
    mainScreen: true,
    noTitle: true
  },
  {
    path: "/transaction-receipt",
    name: "Transaction Receipt",
    icon: Person,
    component: TransactionReceiptPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/create-wallet",
    name: "Create Wallet",
    icon: AccountBalanceWallet,
    component: CreateWalletPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/create-virtual-card",
    name: "Create Wallet",
    icon: AccountBalanceWallet,
    component: CreateVirtualCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/cancel-card",
    name: "Cancel Card",
    icon: AccountBalanceWallet,
    component: CancelCardReasonPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-cancel-card",
    name: "Confirm Cancel Card",
    icon: AccountBalanceWallet,
    component: ConfirmCancelCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/card-transfer-success",
    name: "Card Transfer Success",
    icon: AccountBalanceWallet,
    component: CancelCardSuccessPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/add-personal-information",
    name: "Add Personal Information",
    icon: AccountBalanceWallet,
    component: AddPersonalInformationPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/save-user-information",
    name: "Save User Information",
    icon: AccountBalanceWallet,
    component: SaveUserInformationPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-create-wallet",
    name: "Confirm Create Wallet",
    icon: AccountBalanceWallet,
    component: ConfirmCreateWalletPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-create-virtual-card",
    name: "Confirm Create Virtual Card",
    icon: AccountBalanceWallet,
    component: ConfirmCreateVirtualCardWalletPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-create-wallet",
    name: "Success Create Wallet",
    icon: AccountBalanceWallet,
    component: SuccessCreateWalletPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-create-virtual-card",
    name: "Success Create Virtual Card",
    icon: AccountBalanceWallet,
    component: SuccessCreateVirtualCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/error",
    name: "Error",
    icon: Error,
    component: GenericErrorPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/info",
    name: "Info",
    icon: Error,
    component: InfoPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/offline-error",
    name: "Offline Error",
    icon: WifiOffOutlined,
    component: OfflineErrorPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/wallets-detail",
    name: "My Wallet",
    id: "Wallet",
    icon: AccountBalanceWallet,
    component: WalletDetailPage,
    layout: "/pages",
    mainScreen: true
  },
  {
    path: "/topup",
    name: "Top-Up",
    id: "Topup",
    icon: AccountBalanceWallet,
    component: TopupPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    id: "Withdrawal",
    icon: AccountBalanceWallet,
    component: WithdrawalPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/transfer",
    name: "transfer",
    icon: AccountBalanceWallet,
    component: TransferPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/send-money",
    name: "Send Money",
    icon: AccountBalanceWallet,
    component: TransferPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-transfer",
    name: "Confirm Transfer",
    icon: AccountBalanceWallet,
    component: ConfirmTransferPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-send-money",
    name: "Confirm Send Money",
    icon: AccountBalanceWallet,
    component: ConfirmTransferPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-transfer",
    name: "Success Transfer",
    icon: AccountBalanceWallet,
    component: SuccessTransferPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-send-money",
    name: "Success Send Money",
    icon: AccountBalanceWallet,
    component: SuccessTransferPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/transact",
    name: "Transact",
    icon: AccountBalanceWallet,
    component: TransactPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/pay-qr",
    name: "Pay a QR",
    icon: AccountBalanceWallet,
    component: ScanPayQRPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/pay-amount",
    name: "Pay amount",
    icon: AccountBalanceWallet,
    component: PayAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-pay-amount",
    name: "Confirm pay amount",
    icon: AccountBalanceWallet,
    component: ConfirmPayAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-pay-amount",
    name: "success pay amount",
    icon: AccountBalanceWallet,
    component: SuccessPayAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-card-payment",
    name: "Success Card Payment",
    icon: AccountBalanceWallet,
    component: SuccessCardPaymentPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/no-card-linked",
    name: "No card linked",
    icon: AccountBalanceWallet,
    component: NoCardLinkedPage,
    layout: "/pages",
    mainScreen: true,
    noTitle: true
  },
  {
    path: "/success-link-card",
    name: "Success Link card",
    icon: AccountBalanceWallet,
    component: SuccessLinkCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/card-on-file",
    name: "Card on file",
    icon: AccountBalanceWallet,
    component: CardOnFilePage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/via-card",
    name: "Via Card",
    icon: AccountBalanceWallet,
    component: ViaCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-via-card",
    name: "Confirm Via Card",
    icon: AccountBalanceWallet,
    component: ConfirmViaCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-via-card",
    name: "Success Via Card",
    icon: AccountBalanceWallet,
    component: SuccessViaCardPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/manage-linked-cards",
    name: "Manage Linked Cards",
    icon: AccountBalanceWallet,
    component: ManageLinkedCardPage,
    layout: "/pages",
    mainScreen: true,
    noTitle: true
  },
  {
    path: "/login",
    name: "Logout",
    icon: ExitToApp,
    component: LoginPage,
    layout: "/auth",
    mainScreen: false
  },
  {
    path: "/identity-document",
    name: "Identity Document",
    icon: Person,
    component: IdentityDocumentsPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/document-initial",
    name: "Document Initial",
    icon: Person,
    component: DocumentInitialPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/scan-document",
    name: "Scan Document",
    icon: Person,
    component: ScanDocumentPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/upload-document",
    name: "Upload Document",
    icon: Person,
    component: UploadDocumentPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/selfie-initial",
    name: "Selfie Initial",
    icon: Person,
    component: PhotoInitialPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/scan-selfie",
    name: "Scan Selfie",
    icon: Person,
    component: ScanPhotoPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/upload-selfie",
    name: "Upload Selfie",
    icon: Person,
    component: UploadPhotoPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/personal-information",
    name: "Personal Information",
    icon: Person,
    component: PersonalInformationPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/permanent-address",
    name: "Personal Address",
    icon: Person,
    component: PermanentAddressPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/ratify-kyc-success",
    name: "Ratify KYC Successful",
    icon: Person,
    component: RatifyKYCSuccessPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/ratify-kyc-error",
    name: "Ratify KYC Error",
    icon: Person,
    component: RatifyKYCErrorPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/contact-support",
    name: "Contact Support",
    icon: Person,
    component: ContactSupportPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/qr-generate",
    name: "Generate QR",
    icon: Person,
    component: GetPaidAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/qr-display",
    name: "Display QR",
    icon: Person,
    component: DisplayQRPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/qr-paid-status",
    name: "QR Paid Success",
    icon: Person,
    component: SuccessGetPaidAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/eft-out",
    name: "EFT Out",
    icon: Person,
    component: EFTOutAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/eft-bank",
    name: "EFT Bank",
    icon: Person,
    component: EFTBankPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/eft-confirm",
    name: "EFT Confirm",
    icon: Person,
    component: EFTConfirmPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/eft-success",
    name: "EFT Success",
    icon: Person,
    component: EFTSuccessPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/topup-eft",
    name: "Topup EFT",
    icon: Person,
    component: TopupEFTPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/topup-retail-store",
    name: "Topup at a retailer",
    icon: Person,
    component: TopupRetailerAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/topup-retail-token",
    name: "Topup at a retailer",
    icon: Person,
    component: TopupRetailerTokenPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/topup-atm",
    name: "Topup ATM",
    icon: Person,
    component: TopupATMPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/withdrawal-retail-store",
    name: "Withdrawal at a retailer",
    icon: Person,
    component: WithdrawalRetailerAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/withdrawal-retail-token",
    name: "Withdrawal at a retailer",
    icon: Person,
    component: WithdrawalRetailerTokenPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/withdrawal-atm",
    name: "Withdrawal at a ATM",
    icon: Person,
    component: WithdrawalATMAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/withdrawal-atm-token",
    name: "Withdrawal at a ATM",
    icon: Person,
    component: WithdrawalATMTokenPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-airtime-data-electricity",
    name: "VAS - Airtime Data Electricity",
    icon: AccountBalanceWallet,
    component: VASBuyAirtimeDataElectricity,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-network-partners",
    name: "VAS - Network Partners",
    icon: AccountBalanceWallet,
    component: VASNetworkPartnersPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-enter-mobile",
    name: "VAS - Enter mobile",
    icon: AccountBalanceWallet,
    component: VASEnterMobileNumberPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-view-categories",
    name: "VAS - Categories",
    icon: AccountBalanceWallet,
    component: VASViewCategoriesPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-buy-airtime-data",
    name: "VAS - Buy",
    icon: AccountBalanceWallet,
    component: VASBuyAirtimeDataPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-payment-confirmation",
    name: "VAS - Payment Confirmation",
    icon: AccountBalanceWallet,
    component: VASConfirmPayAmountPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-payment-status",
    name: "VAS - Payment Status",
    icon: AccountBalanceWallet,
    component: VASPaymentStatusPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-pay-bill-Categories",
    name: "VAS - Pay Bill Categories",
    icon: AccountBalanceWallet,
    component: VASPayBillCategoriesPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-enter-account",
    name: "VAS - Enter Account",
    icon: AccountBalanceWallet,
    component: VASAccountNumberPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-pay-bill",
    name: "VAS - Pay Bill",
    icon: AccountBalanceWallet,
    component: VASPayBillPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-enter-idnumber",
    name: "VAS - Enter ID Number",
    icon: AccountBalanceWallet,
    component: VASEnterIdNumberPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-view-products",
    name: "VAS - Products",
    icon: AccountBalanceWallet,
    component: VASViewProductsPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/vas-buy-kganya-products",
    name: "VAS - Buy",
    icon: AccountBalanceWallet,
    component: VASBuyKganyaProductsPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-signup",
    name: "Assisted Signup",
    icon: AccountBalanceWallet,
    component: AssistedSignUpPage,
    layout: "/pages",
    mainScreen: false
  },

  {
    path: "/assisted-signup-verify-otp",
    name: "Assisted Signup verify otp",
    icon: AccountBalanceWallet,
    component: AssistedSignupVerifyOTPPage,
    layout: "/pages",
    mainScreen: false
  },

  {
    path: "/assisted-signup-create-username",
    name: "Assisted Signup create username",
    icon: AccountBalanceWallet,
    component: AssistedSignupCreateUsernamePage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-signup-account-ready",
    name: "Assisted Signup account ready",
    icon: AccountBalanceWallet,
    component: AssistedSignupAccountReadyPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-signup-success",
    name: "Assisted Signup Success",
    icon: AccountBalanceWallet,
    component: AssistedSignupSuccessPage,
    layout: "/pages",
    mainScreen: false
  },

  /**
   * Masud Shaikh
   * Date:29/09/2021
   * Routes added for Register physical card flow
   *Starts=========
   **/

  {
    path: "/manage-my-physical-card",
    name: "Mange Physical Card",
    icon: AccountBalanceWallet,
    component: AddPhysicalCard,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-card-register",
    name: "Assisted Card Register",
    icon: AccountBalanceWallet,
    component: AssistedCardRegisterPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-card-verify-otp",
    name: "Assisted Card verify otp",
    icon: AccountBalanceWallet,
    component: AssistedCardVerifyPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-physical-card",
    name: "Add Physical Card",
    icon: AccountBalanceWallet,
    component: PhysicalCardRegisterPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/register-my-physical-card",
    name: "Register Physical Card",
    icon: AccountBalanceWallet,
    component: RegisterPhysicalCard,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/add-physical-card-personal-info",
    name: "Add physical card personal info",
    icon: AccountBalanceWallet,
    component: AddPhysicalCardPersonalInformationPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/new-physical-card-pin",
    name: "Set Physical Card PIN",
    icon: AccountBalanceWallet,
    component: CreateNewPinPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-physical-card-pin",
    name: "Confirm Physical Card PIN",
    icon: AccountBalanceWallet,
    component: ConfirmNewPinPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/scan-card-qr",
    name: "Scan Card QR",
    icon: AccountBalanceWallet,
    component: ScanPhysicalCardQR,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-card-registered",
    name: "Success Card Registered",
    icon: AccountBalanceWallet,
    component: RegisterSuccessPhysicalCard,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/assisted-card-success",
    name: "Assisted Card Success",
    icon: AccountBalanceWallet,
    component: AssistedCardSuccessPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/reset-pin-verify-otp",
    name: "Verify OTP",
    icon: AccountBalanceWallet,
    component: ResetPinVerifyOtpPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/reset-physical-card-pin",
    name: "Success Card PIN reset",
    icon: AccountBalanceWallet,
    component: ResetPhysicalCardPin,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/reset-physical-card-new-pin",
    name: "Success Card PIN reset",
    icon: AccountBalanceWallet,
    component: ResetPhysicalCardNewPIN,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-card-reset-pin",
    name: "Success Card PIN reset",
    icon: AccountBalanceWallet,
    component: SuccessResetPhysicalCardPIN,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-account-deactivate",
    name: "Success account deactivate",
    icon: AccountBalanceWallet,
    component: SuccessAccountDeactivatePage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-payqr-pin",
    name: "Confirm Pay QR PIN",
    icon: AccountBalanceWallet,
    component: ConfirmPaymentPinPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/qr-error",
    name: "QR payment error",
    icon: AccountBalanceWallet,
    component: QrErrorPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-payment-pin",
    name: "Change Payment Pin",
    icon: Person,
    component: ChangePasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-create-payment-pin",
    name: "Change Create Payment Pin",
    icon: Person,
    component: ChangeCreatePasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-confirm-payment-pin",
    name: "Change Confirm Payment pin",
    icon: Person,
    component: ChangeConfirmPasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/change-success-payment-pin",
    name: "Change Success Payment Pin",
    icon: Person,
    component: ChangeSuccessPasswordPINPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/pay-bill",
    name: "Pay Bill Pay At",
    icon: Person,
    component: PayBillPayAt,
    layout: "/pages",
    mainScreen: true,
    noTitle:true,
    id: "PayBill",
  },
  {
    path: "/reset-payment-pin",
    name: "Reset Payment PIN",
    icon: Person,
    component: ResetPasswordPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/create-payment-pin",
    name: "Create Payment PIN",
    icon: Person,
    component: CreateNewPasswordPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/confirm-payment-pin",
    name: "Confirm Payment PIN",
    icon: Person,
    component: ConfirmNewPasswordPage,
    layout: "/pages",
    mainScreen: false
  },
  {
    path: "/success-payment-pin",
    name: "Success Payment PIN",
    icon: Person,
    component: SuccessPasswordPage,
    layout: "/pages",
    mainScreen: false
  },
  /**
   * Masud Shaikh
   * Date:29/09/2021
   * Routes added for Register physical card flow
   *Ends=========
   **/
];

export default dashboardRoutes;
