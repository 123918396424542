import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { loginToApp } from "../../redux-services/actions/actions";
import { PhysicalCardForm } from "../../components/PhysicalCard/PhysicalCardRegister";
import GridItem from "../../components/Grid/GridItem";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GridContainer from "../../components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle";
import { ICardManagementApi } from "../../eclipse-react-sdk/services";
import HttpRequestConfiguration from "../../http-request-configuration";

const useStyles = makeStyles(themeStyle);

export function PhysicalCardRegister(props) {
  const history = useHistory();
  const classes = useStyles();
  const scannedQRData = useLocation().state?.scannedQRData || "";
  const flow= useLocation()?.state?.flow;
  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.physicalCardRegisterHandler(appData);
    history.push({
      pathname: '/pages/dashboard',
    });
  }, [history, props]);

  const goToScanCardQr = () => {
    history.push({
      pathname: '/pages/scan-card-qr',
      state: { fromScreen: 'assisted-physical-card', journey: "Add a physical card",flow }
    });
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const sendPinSMS = (cardDetailData) => {
    const httpRequestConfiguration = HttpRequestConfiguration(((flow === "assisted-physical-card" || flow === "assisted-signup") ? props?.appData?.assistedCustomer?.assistedUserToken : null));
    const cardManger = new ICardManagementApi(httpRequestConfiguration);
    cardManger.tenantsTenantIdCardsCardIdPinSetsPost(props?.appData?.auth?.tenantId, cardDetailData.cardId, {
      pinDeliveryMechanism: "SMS"
    }).then((result) => {
      if (result) {
        history.push({
          pathname:'/pages/assisted-card-success',
          state: { fromScreen: 'assisted-physical-card', cardDetailData,flow }
        });
      }
    }).catch(() => {
      redirectToErrorScreen('/pages/dashboard', '/pages/error');
    });
  }

  const goToSuccessCreateCard = (cardDetailData) => {
    if (cardDetailData) {
      sendPinSMS(cardDetailData)
    }
  }

  React.useEffect(() => {
    return () => {
      if (history.action === "POP") {
        backToHome();
      }
    };
  }, [history, backToHome])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ display: 'flex' }}>
          <ArrowBackIosIcon className={classes.linkColor} onClick={backToHome}/>
          <span className={classes.title}>{flow==="assisted-physical-card"?"Assisted Card Registration":"Add a physical card"}</span>
        </GridItem>
      </GridContainer>
      <PhysicalCardForm
        {...props}
        scannedQRData={scannedQRData}
        goToScanCardQr={goToScanCardQr}
        onComplete={goToSuccessCreateCard}
        flow={'assisted-physical-card'}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
});

const mapDispatchToProps = dispatch => ({
  physicalCardRegisterHandler: data => dispatch(loginToApp(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicalCardRegister)