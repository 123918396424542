import { LOGIN, LOGOUT } from "../constants";

const initialState = {
    appData: null
}


export default function applicationData(state = initialState, action) {

    switch (action.type) {
        case LOGIN:
            // //console.log('Reducer :: ', action.data);
            return {
                ...state,
                appData: action.data
            }
        case LOGOUT:
            // //console.log('Logout Reducer :: ', action.data);
            return  {
                ...state,
                appData: action.data
            }
        default:
            return state
    }
}