/* tslint:disable */
/* eslint-disable */
/**
 * Eclipse ReST API
 * This is a RESTful web service using JSON as the data transfer model. The service covers all the tenant facing functionality in Eclipse
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * The high level mode of the wallet type. PREPAID_CARD:Card on MPTS with ledger on MPTS, CLOSED_LOOP_DIGITAL:Digital wallet without ability to pay offnet, OPEN_LOOP_DIGITAL:Digital wallet which can pay offnet, SYSTEM:System wallets where the balance is not owned by the tenant or the amount therein is not real money, CRYPTO:Crypto currency wallet backed by a crypto exchange, DEBIT_CARD:Card on card rails but balance and ledger sits on Eclipse and does all auth processing
 * @export
 * @enum {string}
 */
export enum WalletMode {
    CLOSEDLOOPDIGITAL = 'CLOSED_LOOP_DIGITAL',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    DEBITRECEIVEONLYCARD = 'DEBIT_RECEIVEONLY_CARD',
    IPSL = 'IPSL',
    OPENLOOPDIGITAL = 'OPEN_LOOP_DIGITAL',
    PREPAIDCARD = 'PREPAID_CARD',
    PREPAIDRECEIVEONLYCARD = 'PREPAID_RECEIVEONLY_CARD',
    SYSTEM = 'SYSTEM',
    VALR = 'VALR'
}

