import React, { useCallback, useState } from "react";
import { loginToApp } from "../../redux-services/actions/actions";
import { connect } from "react-redux";
import Card from "react-credit-cards";
import Button from "../../components/CustomButtons/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import themeStyle from "../../assets/jss/eclipse/components/themeStyle";
import { ExpiryDateValidation, formatCVV, formatExpirationDate, formatName, luhn, PostBody } from "./CardUtils";
import { DCustomerManagementApi, ZGlobalFunctionalityApi } from "../../eclipse-react-sdk/services";
import "react-credit-cards/es/styles-compiled.css";
import GridItem from "../../components/Grid/GridItem";
import formStyle from "../../assets/jss/eclipse/components/formStyle";
import TextField from "@material-ui/core/TextField";
import GridContainer from "../../components/Grid/GridContainer";
import { InputAdornment, Link } from "@material-ui/core";
import { ReactComponent as VerifiedCheckIcon } from "../../assets/img/verifiedCheck.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import Danger from "../../components/Typography/Danger";
import { primaryColor, secondaryTextColor } from "../../style.scss";
import AlertNotification from "../../components/Snackbar/AlertNotification";
import ProgressSteps from "../../components/ProgressSteps/ProgressSteps.js";
import { SpinnerComponent } from "react-element-spinner";

const useStyles = makeStyles(themeStyle);
const ValidationTextField = withStyles(formStyle)(TextField);
const binMap = new Map();

export function CardOnFile(props) {
  const tenantId = props?.appData?.auth?.tenantId;
  const customer = props?.appData?.customer;
  const classes = useStyles();
  const defaultCardDetails = {
    alias: "",
    number: "",
    name: "",
    expiry: "",
    cvv: "",
    dob: "",
    issuer: "",
    focused: "",
    formData: null,
    isName: false,
    isCvv: false,
    isExpiry: false
  };
  const pageState = useLocation().state;
  const walletDetailData = useLocation().state?.walletDetailData;
  const fromScreen = useLocation().state?.fromScreen;
  const [cardDetails, setCardDetails] = useState(defaultCardDetails);
  const [isCardValid, setIsCardValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [alertData, setAlert] = React.useState(null);
  const history = useHistory();

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIsCardValid(isValid);
      handleValidateCard({ ...cardDetails, issuer });
    } else {
      setIsCardValid(false);
      setErrors({ ...errors, number: "Please add the valid card number" });
    }
  };

  const showAlert = (isAlertDisplay, alertType, alertMessage) => {
    setAlert({ isAlertDisplay, alertType, alertMessage })
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  }

  const redirectToErrorScreen = useCallback((fromScreen, toScreen) => {
    history.push({
      pathname: toScreen,
      state: { fromScreen }
    });
  }, [history]);

  const handleValidateCard = (cardDetails) => {
    setLoading(true);
    const bin = cardDetails.number.replace(/\s/g, "").slice(0, 9);
    const customerLinkedBankCardsAPI = new ZGlobalFunctionalityApi();
    customerLinkedBankCardsAPI.globalBinLookupBinGet(bin).then((response) => {
      if (response.data.displayName) {
        setCardDetails({ ...cardDetails})
      }
      binMap.set(bin, response);
      getLinkedBankCards(cardDetails);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  };

  const getLinkedBankCards = useCallback((cardDetails) => {
    const linkedBankCardsAPI = new DCustomerManagementApi();
    linkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFileGet(tenantId, customer?.customerId).then((response) => {
      const last4Digits = cardDetails.number.slice(cardDetails.number.length - 4);
      const bin = cardDetails.number.slice(0, 6);
      if (response?.data?.length > 0) {
        response.data = response.data.filter((card => (card.last4Digits === last4Digits && card.bin === bin)));
        if (response.data.length > 0) {
          showAlert(true, 'ERROR', `Card has already been added`);
          setErrors({ ...errors, number: "Card has already been added" });
        }
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
        showAlert(true, 'ERROR', `${error.response.data[0].description}`);
      } else {
        redirectToErrorScreen('/pages/dashboard', '/pages/error');
      }
    });
  }, [tenantId, customer, redirectToErrorScreen, errors]);

  const handleInputFocus = (event) => {
    const { target } = event
    setCardDetails({ ...cardDetails, focused: target.name });
  };

  const handleInputChange = (event) => {
    const validationErrors = { ...errors }
    const { target } = event
    if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvv") {
      target.value = formatCVV(target.value);
    } else if (target.name === "name") {
      target.value = formatName(target.value);
    } else if (target.name === "number") {
      target.value = target.value.replace(/[^0-9]/g, '');
      if (luhn(target.value) === false) {
        validationErrors.number = ("Invalid card number");
      } else {
        delete validationErrors.number;
      }
    }
    if (!target.value) {
      validationErrors[target.name] = `This field is required`;
    } else {
      delete validationErrors[target.name];
    }
    const errs = validateField(event);
    setErrors({ ...validationErrors, ...errs });
    setBtnDisabled(true);
    setCardDetails({ ...cardDetails, [target.name]: target.value });
  };

  const goBack = () => {
    if (fromScreen === 'AIRTIMEDATA' || fromScreen === 'BUYAIRTIMEDATA' || fromScreen === 'BUYELECTRICITY') {
      history.push({
        pathname: '/pages/vas-buy-airtime-data',
        state: { ...pageState }
      });
    } else if (fromScreen === 'COLLECTIONS') {
      history.push({
        pathname: '/pages/vas-buy-kganya-products',
        state: { ...pageState }
      });
    } else if (fromScreen === 'PAYBILL') {
      history.push({
        pathname: '/pages/vas-pay-bill',
        state: { ...pageState }
      });
    } else if (fromScreen === 'QRSCAN') {
      history.push({
        pathname: '/pages/pay-amount',
        state: { ...pageState }
      });
    } else if (fromScreen === 'TOPUP' && walletDetailData) {
      history.push({
        pathname: '/pages/topup',
        state: { walletDetailData: walletDetailData }
      });
    } else if (fromScreen === 'SIGN_UP_PAGE' || fromScreen === 'dashboard') {
      const appData = {
        ...props?.appData,
        isRefresh: true
      }
      props.cardOnFileHandler(appData);
      history.push({
        pathname: '/pages/dashboard'
      });
    } else {
      history.push({
        pathname: '/pages/manage-linked-cards',
        state: { walletDetailData }
      });
    }
  };

  const goToSuccessLinkedCard = () => {
    if (fromScreen === 'SIGN_UP_PAGE') {
      history.push({
        pathname: '/pages/ratify-kyc-success',
        state: { ...pageState }
      });
    } else {
      history.push({
        pathname: '/pages/success-link-card',
        state: { ...pageState }
      });
    }
  }

  const validateField = (event) => {
    const validationErrors = { ...errors }
    if (event.target.value === "") {
      validationErrors[event.target.name] = `Field value is required`;
    } else if (event.target.name === "name" || event.target.name === "alias") {
      const nameRegex = new RegExp("^[a-zA-Z ]*$");
      if (event.target.value.length < 4) {
        validationErrors[event.target.name] = `Invalid value! Its length should be minimum 4.`;
      } else if (event.target.value.length > 50) {
        validationErrors[event.target.name] = `Invalid value! Its length should be maximum 50.`;
      } else if (!event.target.value.match(nameRegex)) {
        validationErrors[event.target.name] = `Field value is invalid!`;
      } else {
        delete validationErrors[event.target.name];
      }
    } else if (event.target.name === "expiry" && !new ExpiryDateValidation().validate(event.target.value)) {
      validationErrors[event.target.name] = `Invalid expiry date!`;
    } else {
      delete validationErrors[event.target.name];
    }

    return validationErrors
  }

  const validateForm = (inputs) => {
    const validationErrors = { ...errors }
    for (const [key, value] of Object.entries(inputs)) {
      if (value === "") {
        validationErrors[key] = `This field is required`;
        if (key === "number") {
          validationErrors[key] = `Bank card number is required`;
          break;
        }
        if (key === "cvv" && !cardDetails.isCvv) {
          delete validationErrors[key];
        }
      } else if (key === "expiry" && !new ExpiryDateValidation().validate(cardDetails.expiry)) {
        validationErrors[key] = `Invalid expiry date!`;
      } else {
        delete validationErrors[key];
      }
    }
    return validationErrors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    const noErrors = Object.keys(validationErrors).length === 0;
    if (noErrors) {
      setLoading(true);
      setCardDetails({ ...cardDetails, ...formData });
      let postRequest = new PostBody(cardDetails.expiry, cardDetails.cvv, cardDetails.number, cardDetails.name, cardDetails.dob, props?.appData?.auth?.tenantId);
      const requestBody = { alias: cardDetails.alias ? cardDetails.alias : cardDetails.name, "cardData": postRequest }
      const customerLinkedBankCardsAPI = new DCustomerManagementApi();
      customerLinkedBankCardsAPI.tenantsTenantIdCustomersCustomerIdCardsOnFilePost(props?.appData?.auth?.tenantId, props?.appData?.customer?.customerId, requestBody)
        .then(() => {
          goToSuccessLinkedCard();
        }).catch((error) => {
        setLoading(false);
        if (error instanceof Error && error.response && error.response.data && error.response.data.length > 0 && error.response.data[0].description) {
          showAlert(true, 'ERROR', `${error.response.data[0].description}`);
        } else {
          redirectToErrorScreen('/pages/dashboard', '/pages/error');
        }
      });
    }
  };

  const backToHome = useCallback(() => {
    const appData = {
      ...props?.appData,
      isRefresh: true
    }
    props.cardOnFileHandler(appData);
    if(fromScreen==='manage-linked-cards'){
      history.push({
        pathname: '/pages/manage-linked-cards',
      });
    }else if(fromScreen==='no-card-linked'){
      history.push({
        pathname: '/pages/no-card-linked',
      });
    }else if(fromScreen==="QRSCAN"){
      history.push({
        pathname: '/pages/pay-amount',
        state:{...pageState,fromScreen:'card'}
      });
    }else{
      history.push({
        pathname: '/pages/dashboard',
      });
    }
  }, [history, pageState,props, fromScreen]);

  const goToSuccess = () => {
    history.push({
      pathname: '/pages/ratify-kyc-success',
      state: { ...pageState }
    });
  }

  React.useEffect(() => {
    return () => {
      window.onpopstate = (event) => {
        event.preventDefault();
        backToHome();
      };
    };
  }, [history, backToHome]);

  React.useEffect(() => {
    if (cardDetails.name === "" || cardDetails.expiry === "" || cardDetails.alias === "")  {
      setBtnDisabled(false);
    }
  }, [cardDetails])

  return (
    <div>
      <SpinnerComponent loading={isLoading} position="global" color={primaryColor}/>
      <GridContainer>
        {(fromScreen !== 'SIGN_UP_PAGE') ?
          <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', marginBottom: 20, paddingTop: '30px' }}>
            <ArrowBackIcon className={classes.linkColor} onClick={goBack}/>
            <span className={classes.title}>Link a bank card</span>
          </GridItem> : null}
        {(fromScreen === 'SIGN_UP_PAGE') ?
          <>
            <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', marginTop: '15px' }}>
              <ProgressSteps currentStep="2" totalSteps="2" isShowStepper={true}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <h1 className={classes.textCenter}>Link your bank card</h1>
              <p className={classes.textCenter} style={{ color: secondaryTextColor }}>Don't want to use cash? Link your
                bank card here to pay for your taxi ride by scanning a QR code in the Taxi.</p>
            </GridItem>
          </> : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card
            number={cardDetails.number}
            name={cardDetails.name}
            expiry={cardDetails.expiry}
            cvc={cardDetails.cvv}
            dob={cardDetails.dob}
            focused={cardDetails.focused}
            callback={handleCallback}
          />
        </GridItem>
      </GridContainer>
      <form name="cardData" onSubmit={handleSubmit}>
        <p className={classes.textCenter+" m-b-0"}><b>Add your card details</b></p>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ValidationTextField
              fullWidth
              variant="filled"
              type="tel"
              id="cardNumber"
              name="number"
              error={!!(errors.number)}
              className="form-control"
              label={"Card Number"}
              pattern="[\d| ]{12,20}"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              inputProps={{ maxLength: 20 }}
              InputProps={{
                endAdornment:
                  <InputAdornment
                    style={{ marginTop: 0 }}
                    className={"currencyPrefix"}
                    position="start">
                    {isCardValid ? <VerifiedCheckIcon/> : ""}
                  </InputAdornment>,
              }}
            />
            {errors?.number && <Danger><small>{errors?.number}</small></Danger>}
          </GridItem>
        </GridContainer>
        {isCardValid ? <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ValidationTextField
              fullWidth
              variant="filled"
              type="text"
              name="name"
              error={!!(errors.name)}
              className="form-control"
              label={"Name on card"}
              minLength="4"
              pattern="[A-Za-z0-9 _]{4,50}$"
              title="Cardholder Name must be in between 4 to 50 characters"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              disabled={!isCardValid}
            />
            {errors?.name && <Danger><small>{errors?.name}</small></Danger>}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <ValidationTextField
              fullWidth
              variant="filled"
              type="tel"
              name="expiry"
              error={!!(errors.expiry)}
              className="form-control"
              label={"Valid Thru (MMYY)"}
              pattern="\d\d/\d\d"
              minLength="5"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              disabled={!isCardValid}
            />
            {errors?.expiry && <Danger><small>{errors?.expiry}</small></Danger>}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <ValidationTextField
              fullWidth
              variant="filled"
              type="text"
              name="alias"
              error={!!(errors.alias)}
              className="form-control"
              label={"Name this card"}
              pattern="\d{3,4}"
              minLength="5"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              disabled={!isCardValid}
            />
            {errors?.alias && <Danger><small>{errors?.alias}</small></Danger>}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button className={classes.buttonRadius} size="lg" block color="primary" type="submit"
                    disabled={Object.keys(errors).length > 0 || !btnDisabled}>Link my bank card
            </Button>
            {(fromScreen === 'SIGN_UP_PAGE') ?
              <p className={classes.textCenter}><Link className={classes.linkColor} onClick={goToSuccess}><b>I'll do it
                later</b></Link></p>
              : null}
          </GridItem>
        </GridContainer>:null}
      </form>
      {alertData?.isAlertDisplay ? <AlertNotification alertData={alertData}/> : null}
    </div>
  )
}

const mapStateToProps = state => ({
  appData: state.applicationData.appData
})

const mapDispatchToProps = dispatch => ({
  cardOnFileHandler: data => dispatch(loginToApp(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CardOnFile)